import { useEffect, useMemo, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import PageSection from "../../Layout/PageSection/PageSection";
import BackButton from "../../common/BackButton";
import ViewUserDetailsHeader from "./ViewUserDetailsHeader";
import ViewDetailTabs from "./ViewDetailTabs";
import ViewUserDetailsAddBtn from "./ViewUserDetailsAddBtn";
import ViewDetailsModals from "./ViewDetailsModals";
import Tabs from "../../common/Tabs/Tabs";
import useTabs from "../../../hooks/useTabs";

import { viewUserDetailTabs } from "../helper";
import { GetUserById } from "../../../services/UserService";
import { getAllSkillsOptions, getUserSkills } from "../../../store/slices/employment";

const initialDetailsState = {
  userDetails: {},
};

const modalInitialState = {
  skills: false,
  uploadedDocuments: false,
};

/**
 * Generates the function comment for the given function body.
 *
 * @return {undefined}
 */
const ViewUserDetails = () => {
  const navigate = useNavigate();
  const [searchParams, _] = useSearchParams();
  const { activeTab, onClickActiveTabHandler } = useTabs("skills");
  const [modals, setModals] = useState(modalInitialState);
  const [details, setDetails] = useState(initialDetailsState);
  const dispatch = useDispatch();

  useEffect(() => {
    GetUserById(searchParams.get("userId")).then(res => {
      setDetails(prevD => ({ ...prevD, userDetails: res.data }));
    });
    dispatch(getUserSkills(searchParams.get("userId")));
    dispatch(getAllSkillsOptions());
  }, []);

  const toggleOpenModal = function (modal, state) {
    setModals(prevM => ({ ...prevM, [modal]: state }));
  };

  const onClickGoBack = () => {
    setDetails(initialDetailsState);
    navigate(-1);
  };

  return (
    <>
      <PageSection
        showIcon={false}
        button={
          <ViewUserDetailsAddBtn
            onClick={toggleOpenModal.bind(null, activeTab, true)}
            activeTab={activeTab}
          />
        }
        heading={
          <Heading userName={details.userDetails.firstName} onClickGoBack={onClickGoBack} />
        }>
        <div className="flex flex-col gap-6">
          <ViewUserDetailsHeader userDetails={details.userDetails} />

          <hr className="bg-red !m-0 border-t-1 border-t-gray-200 " />

          <div>
            <Tabs
              activeTab={activeTab}
              tabs={viewUserDetailTabs}
              onClickActiveTabHandler={onClickActiveTabHandler}
            />

            <ViewDetailTabs activeTab={activeTab} />
          </div>
        </div>
      </PageSection>

      <ViewDetailsModals
        user={details.userDetails}
        toggleOpenModal={toggleOpenModal}
        modals={modals}
      />
    </>
  );
};

export default ViewUserDetails;

const Heading = function ({ userName, onClickGoBack }) {
  return (
    <>
      <div className="flex">
        <BackButton
          styles={{ textClassName: "text-gray-700", iconColor: "text-gray-700" }}
          onClick={onClickGoBack}
          text=""
        />
        <span>{userName}'s Details</span>
      </div>
    </>
  );
};
