import { useRef, useState } from "react";
import { AddEmploymentTypeBody } from "./AddEmploymentTypeBody";
import ModalComponent from "../../../components/common/Modal/Modal";
import { CreateEmploymentTypes, GetEmploymentTypes } from "../../../services/UserService";
import { showToast } from "../../../components/common/Toast";
import { createAssociation } from "../../../services/LeaveService";
import PlusSvg from "../../../media/PlusSvg";

export const AddEmploymentTypeModal = ({ pageNo, pageSize, setRenderEmploymentType }) => {
  const formRef = useRef(null);
  const [toggleModal, setToggleModal] = useState(false);

  const [employmentTypesInfo, setEmploymentTypesInfo] = useState();
  const [leaveTypesInfo, setLeaveTypesInfo] = useState([]);

  const renderAddEmploymentTypeModal = JSON.parse(
    sessionStorage.getItem("renderAddEmploymentType")
  );

  const handleAddEmploymentType = e => {
    e.preventDefault();
    const { name, value } = e.target;

    setEmploymentTypesInfo(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleLeaveTypes = (e, typeId, typeName) => {
    e.preventDefault();
    const { name, value } = e.target;

    const leaveTypeIndex = leaveTypesInfo.findIndex(leaveType => leaveType.leaveTypeId === typeId);

    if (leaveTypeIndex !== -1) {
      // If the leaveType with the typeId already exists, update its property
      setLeaveTypesInfo(prevLeaveTypesInfo =>
        prevLeaveTypesInfo.map((leaveType, index) =>
          index === leaveTypeIndex
            ? {
                ...leaveType,
                [name]: value,
              }
            : leaveType
        )
      );
    } else {
      // If the leaveType with the typeId doesn't exist, add a new object to the array
      setLeaveTypesInfo(prevLeaveTypesInfo => [
        ...prevLeaveTypesInfo,
        {
          leaveTypeId: typeId,
          leaveTypeName: typeName,
          [name]: value,
        },
      ]);
    }
  };

  const Submit = async e => {
    e.preventDefault();

    //before sending data to the database check if employmentTypesInfo.name is empty
    if (!employmentTypesInfo?.name || employmentTypesInfo.name.trim() === "") {
      showToast("warn", "Employment type name is empty!");
      return;
    }

    const createEmploymentResponse = await CreateEmploymentTypes(employmentTypesInfo);

    if (
      createEmploymentResponse.data?.message === "Success!" &&
      createEmploymentResponse.data?.statusCode === 200
    ) {
      setRenderEmploymentType(true);
      setToggleModal(false);
      formRef.current.reset();

      const response = await GetEmploymentTypes(pageNo, pageSize);
      const allEmploymentTypes = response?.data?.data;
      // Get the created employment type ID from the response which is basically at the end of an array
      const employmentTypeId = allEmploymentTypes[allEmploymentTypes.length - 1].id;

      // Preparing the associations data with the obtained employmentTypeId to pass as an argument to
      // the createAssociation API
      const associationsData = {
        employmentTypeId: employmentTypeId,
        employementTypeName: employmentTypesInfo.name,
        leaveTypes: leaveTypesInfo,
      };

      // Calling the createAssociation API with the associations data
      const createAssociationResponse = await createAssociation(associationsData);

      if (
        createAssociationResponse.data?.message === "Success!" &&
        createAssociationResponse.data?.statusCode === 200
      ) {
        showToast("success", "Employment type created successfully");
      }
    }

    //clear previous values on success/error
    setEmploymentTypesInfo({});
    setLeaveTypesInfo([]);
  };

  return (
    <div>
      <div onClick={() => setToggleModal(true)}>
        <PlusSvg className="w-9 h-9" />
      </div>

      <ModalComponent
        show={toggleModal}
        headerClassName="modal-header-title mb-4"
        heading="Add Employment Type"
        onClose={() => setToggleModal(false)}
        body={
          <AddEmploymentTypeBody
            pageNo={pageNo}
            pageSize={pageSize}
            renderAddEmploymentTypeModal={renderAddEmploymentTypeModal}
            handleAddEmploymentType={handleAddEmploymentType}
            handleLeaveTypes={handleLeaveTypes}
            handleSubmit={Submit}
            formRef={formRef}
          />
        }
      />
    </div>
  );
};
