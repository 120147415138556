import { Avatar } from "flowbite-react";
import { MdOutlineCelebration } from "react-icons/md";

const WorkAnniversary = ({workAnniversaries}) => {

  return (
    <div>
      <div className="w-full h-96 bg-white rounded-lg">
        <div className="flex items-center justify-between p-5 pb-3">
          <h5 className="text-xl font-bold leading-none text-gray-700 dark:text-white">
            Work Anniversary
          </h5>
          <p>Today</p>
        </div>
        <div>
          <hr className="h-px my-2 bg-gray-200 border-0 dark:bg-gray-700"></hr>
        </div>
        {workAnniversaries.length === 0 && (
        <div className="flex flex-col items-center relative">
          <MdOutlineCelebration className="absolute top-10" size={"10rem"} color="#BFE2D2" />
          <p className="text-center absolute top-60 text-gray-500 dark:text-gray-400">No work anniversary today</p>
        </div>
        )}
        {workAnniversaries.length > 0 && (
        <div className="pl-3 pr-5 pb-5 flow-root scrollbar-hide" style={{ maxHeight: "calc(100% - 90px)" }}>
          <ul role="list" className=" divide-gray-200 dark:divide-gray-700">
            {workAnniversaries.map(workAnniversary => 
            <li key={workAnniversary.id} className="py-2 sm:py-2">
              <div className="flex items-center">
                <div className="flex-shrink-0">
                  <Avatar
                    rounded={true}
                    placeholderInitials={`${workAnniversary.firstName && workAnniversary.firstName[0].toUpperCase()}${workAnniversary.lastName && workAnniversary.lastName[0].toUpperCase()}`}
                    className="mx-2 z-auto cursor-pointer"
                    img={workAnniversary.image}
                  />
                </div>
                <div className="flex-1 min-w-0 ms-4">
                  <p className="text-sm font-medium text-gray-900 truncate dark:text-white">
                    {workAnniversary.firstName + " " + workAnniversary.lastName}
                  </p>
                  <p className="text-sm text-gray-500 truncate dark:text-gray-400">
                    {workAnniversary.email}
                  </p>
                </div>
                <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                  {workAnniversary.tenure + " " + "Complete"} 
                </div>
              </div>
            </li>
            )}
          </ul>
        </div>
        )}
      </div>
    </div>
  );
};

export default WorkAnniversary;
