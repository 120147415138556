import { NavLink } from "react-router-dom";
import { TiUser } from "react-icons/ti";
import { TbLockAccess } from "react-icons/tb";
import { BiSolidComponent } from "react-icons/bi";
import { SiKnowledgebase } from "react-icons/si";
import { CgOrganisation } from "react-icons/cg";
import { SiBrandfolder } from "react-icons/si";
import { AiOutlineException } from "react-icons/ai";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  toggleAttendanceModal,
  toggleGlobalSettingsModal,
} from "../../../store/slices/settingsSlice";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import ControlPanelSettings from "../../Settings/controlPanelSettings/ControlPanelSettings";

/**
 * Renders a component representing a Settings modal.
 *
 * @return {TSX.Element} A React component.
 */
const Settings = () => {
  const dispatch = useAppDispatch();

  const settings = useAppSelector(state => state.settings);

  //Function to toggle different settings list
  const handleToggleSettings = settingsOption => {
    if (settingsOption === "attendanceSettings") {
      dispatch(toggleAttendanceModal(true));
    }

    if (settingsOption === "globalSettings") {
      dispatch(toggleGlobalSettingsModal(true));
    }
  };
  return (
    <>
      <div
        id="mega-menu"
        className="grid absolute right-12 top-16 z-10 w-full bg-white border border-gray-100 shadow-md dark:border-gray-700 lg:rounded-lg lg:w-auto lg:grid-cols-3 dark:bg-gray-700">
        <div className="p-2 text-gray-900 bg-white lg:rounded-lg dark:text-white lg:col-span-2 dark:bg-gray-800">
          <ul>
            <li>
              <NavLink
                to="/settings/users"
                key="/settings/users"
                className="flex items-center p-3 rounded-lg hover:bg-gray-50 dark:hover:bg-gray-700">
                <div className="p-2 mr-4 bg-white rounded-lg shadow dark:bg-gray-700">
                  <TiUser size={"1.3rem"} />
                  {/* <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M7 3a1 1 0 000 2h6a1 1 0 100-2H7zM4 7a1 1 0 011-1h10a1 1 0 110 2H5a1 1 0 01-1-1zM2 11a2 2 0 012-2h12a2 2 0 012 2v4a2 2 0 01-2 2H4a2 2 0 01-2-2v-4z"></path></svg> */}
                </div>
                <div>
                  <div className="font-semibold">User management</div>
                  <div className="text-sm font-light text-gray-500 dark:text-gray-400">
                    Add users, assign roles
                  </div>
                </div>
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/settings/roles"
                key="/settings/roles"
                className="flex items-center p-3 rounded-lg hover:bg-gray-50 dark:hover:bg-gray-700">
                <div className="p-2 mr-4 bg-white rounded-lg shadow dark:bg-gray-700">
                  <TbLockAccess size={"1.3rem"} strokeWidth={"0.15rem"} />
                  {/* <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M7 3a1 1 0 000 2h6a1 1 0 100-2H7zM4 7a1 1 0 011-1h10a1 1 0 110 2H5a1 1 0 01-1-1zM2 11a2 2 0 012-2h12a2 2 0 012 2v4a2 2 0 01-2 2H4a2 2 0 01-2-2v-4z"></path></svg> */}
                </div>
                <div>
                  <div className="font-semibold">Roles</div>
                  <div className="text-sm font-light text-gray-500 dark:text-gray-400">
                    Add/update roles
                  </div>
                </div>
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/settings/payroll-components/salary"
                key="/settings/payroll-components/salary"
                className="flex items-center p-3 rounded-lg hover:bg-gray-50 dark:hover:bg-gray-700">
                <div className="p-2 mr-4 bg-white rounded-lg shadow dark:bg-gray-700">
                  <BiSolidComponent size={"1.3rem"} />
                  {/* <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M5 3a1 1 0 000 2c5.523 0 10 4.477 10 10a1 1 0 102 0C17 8.373 11.627 3 5 3z"></path><path d="M4 9a1 1 0 011-1 7 7 0 017 7 1 1 0 11-2 0 5 5 0 00-5-5 1 1 0 01-1-1zM3 15a2 2 0 114 0 2 2 0 01-4 0z"></path></svg> */}
                </div>
                <div>
                  <div className="font-semibold">Payslip components</div>
                  <div className="text-sm font-light text-gray-500 dark:text-gray-400">
                    Salary and statutory components
                  </div>
                </div>
              </NavLink>
            </li>
            <li>
              <NavLink
                to="settings/skills-management"
                key="settings/skills-management"
                className="flex items-center p-3 rounded-lg hover:bg-gray-50 dark:hover:bg-gray-700">
                <div className="p-2 mr-4 bg-white rounded-lg shadow dark:bg-gray-700">
                  <SiKnowledgebase size={"1.3rem"} />
                  {/* <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z" clip-rule="evenodd"></path></svg> */}
                </div>
                <div>
                  <div className="font-semibold">Skills management</div>
                  <div className="text-sm font-light text-gray-500 dark:text-gray-400">
                    Add/update skills
                  </div>
                </div>
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/settings/organisation-details"
                key="/settings/organisation-details"
                className="flex items-center p-3 rounded-lg hover:bg-gray-50 dark:hover:bg-gray-700">
                <div className="p-2 mr-4 bg-white rounded-lg shadow dark:bg-gray-700">
                  <CgOrganisation size={"1.3rem"} />
                  {/* <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M12.395 2.553a1 1 0 00-1.45-.385c-.345.23-.614.558-.822.88-.214.33-.403.713-.57 1.116-.334.804-.614 1.768-.84 2.734a31.365 31.365 0 00-.613 3.58 2.64 2.64 0 01-.945-1.067c-.328-.68-.398-1.534-.398-2.654A1 1 0 005.05 6.05 6.981 6.981 0 003 11a7 7 0 1011.95-4.95c-.592-.591-.98-.985-1.348-1.467-.363-.476-.724-1.063-1.207-2.03zM12.12 15.12A3 3 0 017 13s.879.5 2.5.5c0-1 .5-4 1.25-4.5.5 1 .786 1.293 1.371 1.879A2.99 2.99 0 0113 13a2.99 2.99 0 01-.879 2.121z" clip-rule="evenodd"></path></svg> */}
                </div>
                <div>
                  <div className="font-semibold">Organisation details</div>
                  <div className="text-sm font-light text-gray-500 dark:text-gray-400">
                    Organisation details and branding
                  </div>
                </div>
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/settings/logo-branding"
                key="/settings/logo-branding"
                className="flex items-center p-3 rounded-lg hover:bg-gray-50 dark:hover:bg-gray-700">
                <div className="p-2 mr-4 bg-white rounded-lg shadow dark:bg-gray-700">
                  <SiBrandfolder size={"1.3rem"} />
                  {/* <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M12.395 2.553a1 1 0 00-1.45-.385c-.345.23-.614.558-.822.88-.214.33-.403.713-.57 1.116-.334.804-.614 1.768-.84 2.734a31.365 31.365 0 00-.613 3.58 2.64 2.64 0 01-.945-1.067c-.328-.68-.398-1.534-.398-2.654A1 1 0 005.05 6.05 6.981 6.981 0 003 11a7 7 0 1011.95-4.95c-.592-.591-.98-.985-1.348-1.467-.363-.476-.724-1.063-1.207-2.03zM12.12 15.12A3 3 0 017 13s.879.5 2.5.5c0-1 .5-4 1.25-4.5.5 1 .786 1.293 1.371 1.879A2.99 2.99 0 0113 13a2.99 2.99 0 01-.879 2.121z" clip-rule="evenodd"></path></svg> */}
                </div>
                <div>
                  <div className="font-semibold">Logo & branding</div>
                  <div className="text-sm font-light text-gray-500 dark:text-gray-400">
                    Make this platform your own
                  </div>
                </div>
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/settings/exception-logs"
                key="/settings/exception-logs"
                className="flex items-center p-3 rounded-lg hover:bg-gray-50 dark:hover:bg-gray-700">
                <div className="p-2 mr-4 bg-white rounded-lg shadow dark:bg-gray-700">
                  <AiOutlineException size={"1.3rem"} strokeWidth={"3rem"} />
                  {/* <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M7 9a2 2 0 012-2h6a2 2 0 012 2v6a2 2 0 01-2 2H9a2 2 0 01-2-2V9z"></path><path d="M5 3a2 2 0 00-2 2v6a2 2 0 002 2V5h8a2 2 0 00-2-2H5z"></path></svg> */}
                </div>
                <div>
                  <div className="font-semibold">Exception logs</div>
                  <div className="text-sm font-light text-gray-500 dark:text-gray-400">
                    Exceptions logs created if there is any error
                  </div>
                </div>
              </NavLink>
            </li>
          </ul>
        </div>
        <div className="py-5 px-5 bg-gray-50 lg:rounded-lg lg:col-span-1 dark:bg-gray-700">
          <h3 className="mb-4 text-sm font-semibold text-gray-900 dark:text-white">
            Browse settings
          </h3>
          <ul className="space-y-4 text-sm text-gray-500 dark:text-gray-400">
            <li>
              <a
                href="#"
                className="hover:text-primary-600 dark:hover:text-primary-500"
                onClick={handleToggleSettings.bind(null, "attendanceSettings")}>
                Attendance settings
              </a>
            </li>
            <li>
              <a
                href="#"
                className="hover:text-primary-600 dark:hover:text-primary-500"
                onClick={handleToggleSettings.bind(null, "globalSettings")}>
                Global settings
              </a>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Settings;
