import { Button, Radio } from "flowbite-react";
import React, { useState, useRef, useMemo, useCallback, useEffect } from "react";
import ModalComponent from "../../common/Modal/Modal";
import styles from "./AddNewLeaveType.module.css";
import ModalButtons from "../../common/Modal/ModalButtons";
import useTabs from "../../../hooks/useTabs";
import Tabs from "../../common/Tabs/Tabs";
import {
  checkMandatoryFields,
  initialEntitlementCardDetails,
  leaveTypeColors,
  leaveTypeImageIcons,
  leaveTypeTabs,
  newLeaveTypeTabDetails,
} from "../helper";
import LeaveTypeEntitlementDetails from "./LeaveTypeEntitlementDetails";
import LeaveTypeApplicableDetails from "./LeaveTypeApplicableDetails";
import { BsPlus } from "react-icons/bs";
import { v4 as uuidv4 } from "uuid";
import { AddLeaveType, UpdateLeaveType } from "../../../services/LeaveService";
import moment from "moment";
import { tw_styles } from "../helper";
import { FiInfo } from "react-icons/fi";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { STATUS_CODES } from "../../../constants/Constant";
import { showToast } from "../../common/Toast";
import MandatorySign from "../../common/Input/MandatorySign";
import { FaRegEye } from "react-icons/fa";
import { LEAVE_TYPES_MESSAGE } from "../../../constants/Messages";
import SidebarComponent from "../../common/Sidebar/Sidebar";
import InputFields from "../../common/Input/InputFields";
import { EffectiveAfterUnitDetails } from "../../../enum/LeaveTypePolicy";

let leaveTypeClass = "twAddLeaveTypeModal";
let btnContainerClass = `${styles.leave_type_submit_buttons}`;
const shadowStyle = {
  boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px",
};

const AddNewLeaveType = ({
  onCloseModal,
  toggleAddLeaveType,
  setToggleAddLeaveType,
  entitlementCardDetails,
  setEntitlementCardDetails,
  selectedApplicableOptions,
  setSelectedApplicableOptions,
  leaveTypeFormDetails,
  isUpdatingLeaveType,
  setLeaveTypeFormDetails,
  isChange,
  setIsChange,
  selectedImageIconAndColor,
  setSelectedImageIconAndColor,
  leaveTypeIconAndColorToggle,
  setLeaveTypeIconAndColorToggle,
  resetState,
  applicableDetails,
  setApplicableDetails,
  activeItem,
  setActiveItem,
  disableSubmitBtn,
  setDisableSubmitBtn,
}) => {
  //Formatting date
  let startValidityDate = moment(leaveTypeFormDetails?.validityStart).format("YYYY-MM-DD");
  let endValidityDate = moment(leaveTypeFormDetails?.validityEnd).format("YYYY-MM-DD");

  //State to handle tabs (entitlement and applicable)
  const { activeTab, onClickActiveTabHandler } = useTabs("entitlement");

  let count = useRef(entitlementCardDetails.length - 1);

  //use effect hook to check the mandatory fields
  useEffect(() => {
    let timeout;

    //toggle submit button
    timeout = setTimeout(() => {
      const isEmpty = checkMandatoryFields(leaveTypeFormDetails);
      setDisableSubmitBtn(!isEmpty);
    }, 100);

    return () => clearTimeout(timeout);
  }, [leaveTypeFormDetails]);

  //use effect hook to calculate the days
  useEffect(() => {
    if (entitlementCardDetails.length >= 1) {
      const effectiveAfterDays = [];

      entitlementCardDetails.forEach((item, index) => {
        let days;

        // Convert the value to days based on the unit
        if (item.formDetails.others.effectiveAfterUnit === EffectiveAfterUnitDetails.YEAR) {
          days = moment.duration(item.formDetails.others.effectiveAfter, "years").asDays();
        } else if (item.formDetails.others.effectiveAfterUnit === EffectiveAfterUnitDetails.MONTH) {
          days = moment.duration(item.formDetails.others.effectiveAfter, "months").asDays();
        } else if (item.formDetails.others.effectiveAfterUnit === EffectiveAfterUnitDetails.DAY) {
          days = item.formDetails.others.effectiveAfter;
        } else {
          return;
        }

        // Only store valid days in the array
        if (!isNaN(days)) {
          effectiveAfterDays.push(days);
        }
      });

      // Check for duplicate values in the days array
      const uniqueDaysArray = [...new Set(effectiveAfterDays)];
      const isDuplicatePresent = uniqueDaysArray.length !== effectiveAfterDays.length;

      if (isDuplicatePresent) {
        showToast("warn", "Two or more policies 'effective after' cannot start at the same time.");
      }
    }
  }, [entitlementCardDetails]);

  //Function to add new policy card
  const handleAddNewPolicy = () => {
    count.current = count.current + 1;
    const obj = structuredClone(initialEntitlementCardDetails);
    obj.formDetails.others.effectiveAfter = count.current;
    const newPolicy = {
      id: 0,
      uid: uuidv4(),
      toggleDetails: { ...obj.toggleDetails },
      formDetails: { ...obj.formDetails },
    };
    const newEntitlementCardDetails = [...entitlementCardDetails, newPolicy];
    setEntitlementCardDetails(newEntitlementCardDetails);
  };

  //Function to delete entitlement card
  const onDeleteEntitlementCard = uid => {
    const newEntitlementCardDetails = entitlementCardDetails.filter((item, index) => {
      return item.uid !== uid;
    });

    setEntitlementCardDetails(newEntitlementCardDetails);
  };

  //Function to change the leave type details
  const onChangeLeaveTypeDetails = event => {

    const { name, checked, type } = event.target;
    const value = type === "checkbox" ? checked : event.target.value;
    if (name === "isPaid") {
      setLeaveTypeFormDetails({ ...leaveTypeFormDetails, [name]: value === "true" ? true : false });
    } else {
      // setLeaveTypeFormDetails({ ...leaveTypeFormDetails, [name]: value });
      setLeaveTypeFormDetails(prevLTFD => {
        return {
          ...prevLTFD,
          [name]: value,
        };
      });
    }
  };

  //Function to submit leave type details
  const submitLeaveTypeDetails = async () => {
    //change object keys name as present in the database
    //leave type policy entitlement details

    let entitlementDetails = entitlementCardDetails.map(item => {
      const { toggleDetails, formDetails } = item;
      const { others, accrualCheckBox, resetCheckBox } = formDetails;
      return {
        ...accrualCheckBox,
        ...resetCheckBox,
        ...others,
        effectiveAfterDateType: +others?.effectiveAfterDateType,
        isAccrualEnabled: toggleDetails?.accrualCheckBox?.isChecked,
        isResetEnabled: toggleDetails?.resetCheckBox?.isChecked,
        accrualMonth: accrualCheckBox?.accrualInterval === 2 ? 0 : +accrualCheckBox?.accrualMonth,
        resetMonth: resetCheckBox?.resetTimePeriod === 2 ? +0 : +resetCheckBox?.resetMonth,
      };
    });

    //leave type applicable details
    let selectedOptions = [];
    for (let key in selectedApplicableOptions) {
      selectedOptions = [...selectedOptions, ...selectedApplicableOptions[key].selected];
    }

    //leave type form details
    let leaveTypeDetails = {
      name: leaveTypeFormDetails?.name,
      shortCode: leaveTypeFormDetails?.shortCode === "" ? null : leaveTypeFormDetails?.shortCode,
      colorCode: selectedImageIconAndColor?.selectedIconColor?.color,
      icon: selectedImageIconAndColor?.selectedImageIcon?.iconName,
      isPaid: leaveTypeFormDetails?.isPaid,
      description: leaveTypeFormDetails?.description,
      validityStart: leaveTypeFormDetails?.validityStart,
      validityEnd:
        leaveTypeFormDetails?.validityEnd !== "" ? leaveTypeFormDetails?.validityEnd : null,
      leavePolicies: entitlementDetails,
      leaveTypeFieldValues: selectedOptions,
      allowNegativeLeaves: leaveTypeFormDetails?.allowNegativeLeaves,
    };

    //Submitting leave type details to backend and resetting the values to default value.

    if (!leaveTypeDetails?.name) {
      return showToast("error", "Please provide a name for this leave type !");
    }
    if (!leaveTypeDetails?.validityStart) {
      return showToast("error", "Please specify the effective date !");
    }

    entitlementCardDetails.forEach(item => {
      if (item?.formDetails?.effectiveAfter === "" || item?.formDetails?.effectiveAfter === null) {
        showToast("error", "Invalid Input");
        return;
      }
    });

    const response = await AddLeaveType(leaveTypeDetails);
    if (response?.data?.statusCode === STATUS_CODES.CONFLICT_ERROR) {
      return showToast("error", LEAVE_TYPES_MESSAGE.SHORT_CODE_ERROR);
    }
    if (response?.data?.statusCode === STATUS_CODES.SUCCESS) {
      showToast("success", "Leave type is submitted successfully");
      //reset the state after submitting the form
      resetState();
      setIsChange(!isChange);
    } else {
      showToast("error", "Error");
    }
  };

  const handleUpdateSubmit = async () => {
    //change object keys name as present in the database
    //leave type policy entitlement details
    let entitlementDetails = entitlementCardDetails.map(item => {
      const { toggleDetails, formDetails } = item;
      const { others, accrualCheckBox, resetCheckBox } = formDetails;
      return {
        ...accrualCheckBox,
        ...resetCheckBox,
        ...others,
        id: item?.id,
        effectiveAfterDateType: +others.effectiveAfterDateType,
        isAccrualEnabled: toggleDetails?.accrualCheckBox?.isChecked,
        isResetEnabled: toggleDetails?.resetCheckBox?.isChecked,
        accrualMonth: accrualCheckBox?.accrualInterval === 2 ? 0 : +accrualCheckBox?.accrualMonth,
        resetMonth: resetCheckBox?.resetTimePeriod === 2 ? +0 : +resetCheckBox?.resetMonth,
      };
    });

    //leave type applicable details
    let selectedOptions = selectedApplicableOptions["Employment Type"]?.selected || [];

    //leave type form details
    let leaveTypeDetails = {
      id: leaveTypeFormDetails?.id,
      name: leaveTypeFormDetails?.name,
      colorCode: selectedImageIconAndColor?.selectedIconColor?.color,
      icon: selectedImageIconAndColor?.selectedImageIcon?.iconName,
      shortCode: leaveTypeFormDetails?.shortCode,
      isPaid: leaveTypeFormDetails?.isPaid,
      description: leaveTypeFormDetails?.description,
      validityStart: leaveTypeFormDetails?.validityStart,
      validityEnd:
        leaveTypeFormDetails?.validityEnd !== "" ? leaveTypeFormDetails?.validityEnd : null,
      leavePolicies: entitlementDetails,
      leaveTypeFieldValues: selectedOptions,
      allowNegativeLeaves: leaveTypeFormDetails?.allowNegativeLeaves,
    };

    //Submitting leave type details to backend and resetting the values to default value.

    if (!leaveTypeDetails?.name) {
      return showToast("error", "Please provide a name for this leave type !");
    }
    if (!leaveTypeDetails?.validityStart) {
      return showToast("error", "Please specify the effective date !");
    }

    const response = await UpdateLeaveType(leaveTypeDetails);
    if (response?.data?.statusCode === 409) {
      return showToast("error", LEAVE_TYPES_MESSAGE.SHORT_CODE_ERROR);
    }

    if (response?.data?.statusCode === STATUS_CODES.SUCCESS) {
      showToast("success", "Leave type is edited successfully");
      resetState();
      setIsChange(!isChange);
    }
  };

  /* Icons and colors section*/
  //Function for toggling leave type image icon and icon color
  const onToggleImageIconAndIconColor = toggleOption => {
    if (toggleOption === "toggleImageIcon") {
      setLeaveTypeIconAndColorToggle(prevLTIACT => {
        return {
          ...prevLTIACT,
          isImageIconToggle: !prevLTIACT.isImageIconToggle,
          isColorToggle: false,
        };
      });
      return;
    }

    if (toggleOption === "toggleIconColor") {
      setLeaveTypeIconAndColorToggle(prevLTIACT => {
        return {
          ...prevLTIACT,
          isColorToggle: !prevLTIACT.isColorToggle,
          isImageIconToggle: false,
        };
      });
      return;
    }
  };

  // Function to change and save leave type image icon
  const onSaveImageIcon = itemId => {
    const currentImage = leaveTypeImageIcons.filter(icon => {
      return icon.id === itemId;
    });
    setSelectedImageIconAndColor(prevSIIC => {
      return {
        ...prevSIIC,
        selectedImageIcon: currentImage[0],
      };
    });
    setLeaveTypeIconAndColorToggle(prevLTIACT => {
      return {
        ...prevLTIACT,
        isImageIconToggle: false,
      };
    });
  };

  // Function to change and save leave type icon color
  const onSaveIconColors = colorId => {
    const currentColor = leaveTypeColors.filter(color => {
      return color.id === colorId;
    });
    setSelectedImageIconAndColor(prevSIIC => {
      return {
        ...prevSIIC,
        selectedIconColor: currentColor[0],
      };
    });
    setLeaveTypeIconAndColorToggle(prevLTIACT => {
      return {
        ...prevLTIACT,
        isColorToggle: false,
      };
    });
  };

  //Function to set active sidebar item.
  const onClickSideBarItem = item => {
    setActiveItem(item);
  };

  //memoized functions and objects
  const memoizedActiveItem = useMemo(() => activeItem, [activeItem]);
  const memoizedDisableSubmitBtn = useMemo(() => disableSubmitBtn, [disableSubmitBtn]);
  const memoizedOnClickSideBarItem = useCallback(onClickSideBarItem, []);

  return (
    <>
      <ModalComponent
        position={"center"}
        show={toggleAddLeaveType}
        size="6xl"
        onClose={resetState}
        showFooter={false}
        heading={isUpdatingLeaveType ? `Edit Leave Type` : `New Leave Type`}
        bodyClassName="flex gap-4 mt-2">
        {/* modal body */}

        <SidebarComponent
          options={leaveTypeTabs}
          activeItem={memoizedActiveItem}
          onClickSideBarItem={memoizedOnClickSideBarItem}
          isEditing={isUpdatingLeaveType ? true : false}
          disabled={memoizedDisableSubmitBtn}
          handleSubmit={isUpdatingLeaveType ? handleUpdateSubmit : submitLeaveTypeDetails}
        />
        {/* Vertical Line border */}
        <div className="border h-[97%]"></div>

        <div className="w-[100%] h-[96.3%] overflow-auto">
          {/* General Information section */}
          {activeItem === "generalInformation" && (
            <>
              <div className="first-content-container grid grid-cols-[1fr_0.5fr] w-full pt-3 pb-3">
                <div className="">
                  <div className=" flex h-[2rem] ">
                    <div className="w-[35%] text-right pr-5 flex items-center justify-end relative ">
                      Name
                      <MandatorySign className={"absolute right-3 -top-0"} />
                    </div>
                    <div className=" w-[54%]">
                      <InputFields
                        InputField={{
                          type: "text",
                          name: "name",
                          placeholder: "Enter leave type name",
                          required: true,
                        }}
                        inputClassName={`w-full h-full ${tw_styles}`}
                        value={leaveTypeFormDetails.name}
                        onChange={onChangeLeaveTypeDetails}
                        parentClassName={"h-full"}
                      />
                    </div>
                    {/*color options  */}
                    <div className=" w-8 h-full ml-0 flex justify-center items-center relative">
                      <div
                        className={`border w-[57%] h-[57%] rounded-sm`}
                        style={{
                          backgroundColor: selectedImageIconAndColor?.selectedIconColor?.color,
                        }}
                        onClick={onToggleImageIconAndIconColor.bind(null, "toggleIconColor")}></div>

                      {leaveTypeIconAndColorToggle.isColorToggle && (
                        <div className="border absolute top-8 -left-[0.03rem] w-[9.8rem] min-h-[5rem] flex flex-wrap  gap-2 z-10 p-2 bg-slate-50">
                          {leaveTypeColors.map((item, index) => {
                            return (
                              <div
                                key={index}
                                className={`border w-4 h-4 rounded-sm cursor-pointer`}
                                style={{ backgroundColor: item?.color }}
                                onClick={onSaveIconColors.bind(null, item?.id)}></div>
                            );
                          })}
                        </div>
                      )}
                    </div>
                  </div>
                  {/* icons */}
                  <div className=" flex mt-4 h-[2rem]">
                    <div className="w-[35%] text-right pr-5 flex items-center justify-end">
                      Image
                    </div>
                    <div className="w-[54%] relative">
                      <div
                        className={`w-[2rem] h-full cursor-pointer border border-blue-500 ${tw_styles} !p-0 !m-0 flex justify-center items-center`}
                        onClick={onToggleImageIconAndIconColor.bind(null, "toggleImageIcon")}>
                        {selectedImageIconAndColor?.selectedImageIcon?.iconComponent}
                      </div>
                      <div className="">
                        {leaveTypeIconAndColorToggle.isImageIconToggle && (
                          <div className="border w-full min-h-[5.6rem] absolute bg-slate-50 z-10 flex gap-2 justify-between box-border flex-wrap p-2">
                            {leaveTypeImageIcons.map(item => {
                              return (
                                <button
                                  onClick={onSaveImageIcon.bind(null, item?.id)}
                                  className="p-[0.4rem] border w-fit h-fit box-border cursor-pointer">
                                  {item.iconComponent}
                                </button>
                              );
                            })}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className=" flex mt-4 h-[2rem]">
                    <div className="w-[35%] text-right pr-5 flex items-center justify-end relative">
                      Code
                      <MandatorySign className={"absolute right-3 -top-0"} />
                    </div>

                    <div className="relative flex gap-1">
                      <div className="w-[54%] relative">
                        <InputFields
                          InputField={{
                            type: "text",
                            name: "shortCode",
                            required: true,
                          }}
                          inputClassName={`w-[3.5rem] h-full ${tw_styles}`}
                          value={leaveTypeFormDetails?.shortCode}
                          onChange={onChangeLeaveTypeDetails}
                          otherProps={{
                            maxLength: 3,
                          }}
                          parentClassName={"h-full"}
                        />
                      </div>
                      <div className="flex justify-center items-center ml-3">
                        <FiInfo
                          size={25}
                          color="#c0c1c4"
                          className="cursor-pointer"
                          data-tooltip-id="short-code"
                          data-tooltip-html="Code must be unique !"
                          data-tooltip-place="right-start"
                        />
                        <ReactTooltip id="short-code" />
                      </div>
                    </div>
                  </div>
                  <div className=" flex mt-4 h-[2rem]">
                    <div className="w-[35%] text-right pr-5 flex items-center justify-end">
                      Type
                    </div>
                    <div className="w-[54%] ">
                      <select
                        id=""
                        className={`w-full h-full pt-0 pb-0 ${tw_styles}`}
                        name="isPaid"
                        value={leaveTypeFormDetails?.isPaid}
                        onChange={onChangeLeaveTypeDetails}>
                        <option value={true}>Paid</option>
                        <option value={false}>Unpaid</option>
                      </select>
                    </div>
                  </div>
                  <div className=" flex mt-4 ">
                    <div className="w-[35%] text-right pr-5 flex items-center justify-end">
                      Description
                    </div>
                    <div className="w-[54%]">
                      <textarea
                        id="description"
                        name="description"
                        value={leaveTypeFormDetails?.description}
                        rows="5"
                        cols="33"
                        className={`w-full ${tw_styles}`}
                        onChange={onChangeLeaveTypeDetails}></textarea>
                    </div>
                  </div>
                  <div className=" flex mt-4 h-[2rem]">
                    <div className="w-[35%] text-right pr-5 flex items-center justify-end relative">
                      Validity
                      <MandatorySign className={"absolute right-3 -top-0"} />
                    </div>
                    <div className="w-[54%] flex justify-between gap-4 relative">
                      <InputFields
                        InputField={{
                          type: "date",
                          name: "validityStart",
                        }}
                        inputClassName={`w-[40%] h-full ${tw_styles}`}
                        value={startValidityDate}
                        onChange={onChangeLeaveTypeDetails}
                        disabled={isUpdatingLeaveType}
                        parentClassName={"h-full"}
                      />
                      <InputFields
                        InputField={{
                          type: "date",
                          name: "validityEnd",
                        }}
                        inputClassName={`w-[44%] h-full ${tw_styles}`}
                        value={endValidityDate}
                        onChange={onChangeLeaveTypeDetails}
                        parentClassName={"h-full"}
                      />
                    </div>
                    <div className="flex justify-center items-center ml-2">
                      <FiInfo
                        size={25}
                        color="#c0c1c4"
                        className="cursor-pointer"
                        data-tooltip-id="effective-date"
                        data-tooltip-html="<div>Report will be generated from the effective date. Once </br> added, effective date cannot be edited</div>"
                        data-tooltip-place="right-start"
                      />
                      <ReactTooltip id="effective-date" />
                    </div>
                  </div>
                </div>
                <div className=""></div>
              </div>
            </>
          )}

          {/* Entitlement or Policies */}
          {activeItem === "policyDetails" && (
            <>
              <div className="entitlement-details-container">
                {/* entitlement details component */}
                <div>
                  {entitlementCardDetails?.map((item, index) => {
                    return (
                      <LeaveTypeEntitlementDetails
                        key={index}
                        entitlementCardDetailsIndex={index}
                        entitlementCardDetailsObject={item}
                        setEntitlementCardDetails={setEntitlementCardDetails}
                        onDeleteEntitlementCard={onDeleteEntitlementCard}
                      />
                    );
                  })}
                </div>
                {/* button to submit */}
                <div className="add-new-policy-button px-0 py-4 border mx-1 mb-8 mt-3 flex justify-center items-center bg-white hover:bg-slate-100">
                  <div className=" w-fit flex cursor-pointer" onClick={handleAddNewPolicy}>
                    <span>
                      <BsPlus size={"27"} />
                    </span>
                    <span>Add new policy</span>
                  </div>
                </div>
              </div>
            </>
          )}

          {/* Applicable details */}
          {activeItem === "applicableDetails" && (
            <>
              <LeaveTypeApplicableDetails
                setSelectedApplicableOptions={setSelectedApplicableOptions}
                selectedApplicableOptions={selectedApplicableOptions}
                activeItem={activeItem}
                isUpdatingLeaveType={isUpdatingLeaveType}
                applicableDetails={applicableDetails}
                setApplicableDetails={setApplicableDetails}
              />
            </>
          )}

          {/* Restrictions */}
          {activeItem === "restrictions" && (
            <div className="flex items-center mb-2 bg-gray-100 px-4 py-3 rounded-lg">
              <div className="flex items-center w-full h-full gap-2">
                <input
                  onChange={onChangeLeaveTypeDetails}
                  checked={leaveTypeFormDetails.allowNegativeLeaves}
                  id="negative-leave-checkbox"
                  type="checkbox"
                  value="allowNegativeLeaves"
                  name="allowNegativeLeaves"
                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600 "
                />
                <label
                  htmlFor="negative-leave-checkbox"
                  className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                  Allow Negative Balance
                  <p className="text-xs text-gray-600 font-normal">
                  Allows applying for leave even when it exceeds the current balance.
                  </p>
                </label>
              </div>
            </div>
            )}
          </div>
      </ModalComponent>
    </>
  );
};

export default AddNewLeaveType;
