import React from "react";
import { INPUT_FIELDS_CLASS } from "../../../constants/Constant";
import { capitalizeFirstLetter, checkNullUndefined } from "../helper";
import MandatorySign from "./MandatorySign";
import InfoIconSvg from "../../../media/InfoIconSvg";

/**
 * Generate the input fields for a form.
 *
 * @param {{inputArr?: array | undefined, onChange: function, helperText?: string, inputClassName: string | undefined, labelClassName: string | undefined, parentClassName: string | undefined, InputField: {id: string, label: string, type: string, name: string, placeholder: string, required: boolean}, value: string, otherProps: object | undefined, dir: string | undefined}} props - the components props
 */
const InputField = ({
  inputArr,
  onChange,
  inputClassName,
  labelClassName,
  parentClassName,
  InputField,
  value,
  icon,
  otherProps,
  dir,
  helperText,
  disabled,
  minDate,
}) => {
  if (!inputArr || inputArr.length === 0) {
    return (
      <div className={icon ? `${parentClassName} tc-input-with-icon` : `${parentClassName} tc-input`}>
        {InputField.label && (
          <label
            htmlFor={InputField.id}
            className={`${labelClassName} block mb-2 text-sm font-medium text-gray-900 dark:text-white cursor-pointer`}>
            {capitalizeFirstLetter(InputField.label)}
            {InputField.required && <MandatorySign />}
          </label>
        )}
        {icon}
        <input
          type={InputField.type}
          name={InputField.name}
          id={InputField.id}
          onChange={onChange}
          autoComplete="off"
          className={inputClassName ? INPUT_FIELDS_CLASS(inputClassName) : INPUT_FIELDS_CLASS()}
          placeholder={checkNullUndefined(InputField.placeholder)}
          required={InputField.required}
          value={value ?? ""}
          disabled={disabled}
          {...otherProps}
          min={minDate}
          dir={dir}
        />
        {helperText && (
          <span className="text-sm text-gray-500 flex items-start gap-2 mt-2">
            <InfoIconSvg className="w-4 h-4 fill-current mt-[.2rem]" />
            {helperText}
          </span>
        )}
      </div>
    );
  }

  //multiple input fields
  const InputFieldsArr = inputArr.map(input => {
    return (
      <div
        key={input.id}
        className={`${input.id === "company-name" ? "col-span-2" : "w-full"} ${parentClassName}`}>
        {input.label && (
          <label
            htmlFor={input.id}
            className={`${labelClassName} block mb-2 text-sm font-medium text-gray-900 dark:text-white`}>
            {capitalizeFirstLetter(input.label)}
            {input.required && <MandatorySign />}
          </label>
        )}
        <input
          type={input.type}
          name={input.name}
          id={input.id}
          onChange={onChange}
          className={inputClassName ? INPUT_FIELDS_CLASS(inputClassName) : INPUT_FIELDS_CLASS()}
          placeholder={checkNullUndefined(input.placeholder)}
          required={input.required}
          min={input.minDate}
          autoComplete="off"
          {...input.otherProps}
          dir={dir}
        />

        {input.helperText && (
          <span className="text-sm text-gray-500 flex items-start gap-2 mt-2">
            <InfoIconSvg className="w-4 h-4 fill-current" />
            {input.helperText}
          </span>
        )}
      </div>
    );
  });

  return InputFieldsArr;
};

/**
 * Generate the input fields for a form.
 *
 * @param {array?} inputArr - an array of input fields objects pass this if you want to render multiple input fields
 * @param {function} onChange - the function to call when the input value changes
 * @param {string?} inputClassName - the class name for the input fields
 * @param {string?} labelClassName - the class name for the labels
 * @param {string?} parentClassName - the class name for the parent container
 * @param {{id: string, label: string, type: string, name: string, placeholder: string, required: boolean}} InputField - the input field object, pass this if you want to render only one input field and label
 * @param {string} value - the value of the input field
 * @param {object?} otherProps - additional props for the input field
 * @param {string} dir - the direction of the input field
 * @param {boolean} disabled - true or false value to make input disable or able
 * @param {string} helperText - helper text to display
 * @return {array} an array of input fields
 */
const InputFields = React.memo(InputField);

export default InputFields;
