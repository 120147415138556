import { memo } from "react";

/**
 * Creates a table row component with the given properties.
 *
 * @param {object} otherRowProps - The additional row properties
 * @param {string} className - The class name for the row
 * @param {string} dataset - The date attribute of row
 * @param {node} children - The child elements of the row
 * @return {JSX.Element} The table row component
 */
const TableRows = ({ otherRowProps, className, children, dataset = "" }) => {
  return (
    <tr
      {...otherRowProps}
      data-row={dataset}
      className={`${className} border-b dark:border-gray-600 hover:bg-gray-100 dark:hover:bg-gray-700`}>
      {children}
    </tr>
  );
};

export default memo(TableRows);
