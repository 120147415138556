import SelectRejectAll from "../../components/common/SelectRejectAll/SelectRejectAll";
import SelectComponent from "../Settings/controlPanelSettings/SelectComponent/SelectComponent";

import { Role } from "../../enum/Role";
import { HOLIDAY_STATUS } from "../../enum/HolidayStatus";

//create dropdown years from `currentYear-1 to currentYear+5`
const emptyArr = new Array(6).fill(undefined);
const currentYear = new Date().getFullYear();
const fromYear = emptyArr.map((_, i) => currentYear - i);
const toYear = currentYear + 1;
const YEARS = [toYear, ...fromYear];

/**
 * Filter holiday option and publish and unpublish option
 *
 * @param {{activeItem: string, handleUserChange: function, user: object, isAdmin: boolean, setUser: function, userData: object, empType: string, empTypes: array}} props - An object containing the function's props.
 * @param {Array} props.checkedItems - An array of checked items.
 * @param {Object} props.user - The user object.
 * @param {Function} props.onChangeFilterHandler - The event handler for changing the filter.
 * @param {Function} props.handleStatus - The event handler for handling the status.
 * @param {string} props.holidayFilterYear - The year of the holiday filter.
 * @return {JSX.Element} The JSX element representing the component.
 */
const FilterHolidaysOption = ({
  checkedItems,
  user,
  onChangeFilterHandler,
  handleStatus,
  holidayFilterYear,
}) => {
  return (
    <div className="flex flex-col-reverse sm:flex sm:flex-row sm:justify-end">
      {checkedItems.length > 0 && +user?.roleId === Role.Administrator && (
        <div className="flex mr-3 gap-2 sm:self-end">
          <SelectRejectAll
            _class="cursor-pointer flex items-center relative mt-2"
            textClass="text-[green]"
            text="Publish"
            onClick={handleStatus.bind(null, HOLIDAY_STATUS.Publish)}
          />
          <SelectRejectAll
            _class="cursor-pointer flex items-center relative mt-2"
            textClass="text-[red]"
            text="Unpublish"
            onClick={handleStatus.bind(null, HOLIDAY_STATUS.Unpublish)}
          />
        </div>
      )}

      {/* Filter */}
      <SelectComponent
        onChangeHandler={onChangeFilterHandler}
        optionsArr={YEARS}
        defaultValue={holidayFilterYear}
       
      />
    </div>
  );
};

export default FilterHolidaysOption;
