import React, { useState, useEffect } from "react";
import { Bar } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";
import { getAttendances } from "../../../services/AttendanceService";
import {
  endOfMonth,
  format,
  parse,
  startOfToday,
  startOfMonth,
} from "date-fns";
import { absentData, presentData, holidayData } from "./helper";
import { STATUS_CODES } from "../../../constants/Constant";

Chart.register(...registerables);

const AttendanceGraph = ({ user }) => {
  const [selectedMonth, setSelectedMonth] = useState(format(startOfMonth(new Date()), "MMMM"));
  const [attendances, setAttendances] = useState([]);
  const [monthInfo, setMonthInfo] = useState({
    startDate: format(startOfMonth(new Date()), "yyyy-MM-dd HH:mm:ss.SSSSSS"),
    endDate: format(endOfMonth(new Date()), "yyyy-MM-dd HH:mm:ss.SSSSSS")
  });
  const employeeId = user.id;

  const handleChange = (event) => {
    const monthSelected = event.target.value;
    const parsedMonth = parse(monthSelected, "MMMM", new Date());
    setSelectedMonth(monthSelected);
    setMonthInfo({
      startDate: format(startOfMonth(new Date(parsedMonth)), "yyyy-MM-dd HH:mm:ss.SSSSSS"),
      endDate: format(endOfMonth(new Date(parsedMonth)), "yyyy-MM-dd HH:mm:ss.SSSSSS")
    })
  };

  useEffect(() => {
    getAttendances(monthInfo.startDate, monthInfo.endDate, employeeId).then(res => {
      if (res.statusCode === STATUS_CODES.SUCCESS) {
        setAttendances(res.data);
      }
    })
  }, [monthInfo]);

  const data = {
    labels: Array.from({ length: 31 }, (_, i) => (i + 1).toString()), // Labels for each day of the month
    datasets: [
      {
        label: "Present",
        data: presentData(attendances),
        backgroundColor: ["rgb(96, 177, 88)"],
        borderWidth: 1,
        borderRadius: 10,
        barPercentage: 0.5
      },
      {
        label: "Holiday",
        data: holidayData(attendances),
        backgroundColor: ["rgb(227, 160, 8)"],
        borderWidth: 1,
        borderRadius: 10,
        barPercentage: 0.5
      },
      {
        label: "Absent",
        data: absentData(attendances),
        backgroundColor: ["rgb(255, 105, 97)"],
        borderWidth: 1,
        borderRadius: 10,
        barPercentage: 0.5
      },
    ],
  };

  const config = {
    type: "bar",
    data: data,
    options: {
      responsive: true,
    },
  };

  const options = {
    maintainAspectRatio: false,
    scales: {
      x: {
        grid: {
          display: false,
        },
        stacked: true,
      },
      y: {
        ticks: {
          stepSize: 5,
        },
        grid: {
          display: false,
        },
        stacked: true,
        beginAtZero: true,
      },
    }
  };

  const months = [
    'January', 'February', 'March', 'April',
    'May', 'June', 'July', 'August',
    'September', 'October', 'November', 'December',
  ];

  return (
    <div className="w-full h-96 p-5 pb-3 bg-white rounded-lg bg-whiterounded-lg">
      <div className="flex justify-between">
        <div>
          <p className="text-xl font-bold text-gray-700">Your Timing For This month</p>
        </div>
        <div className="flex w-44">
          <select
            className="block appearance-none w-full bg-white border border-gray-300 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            value={selectedMonth}
            onChange={handleChange}>
            <option value="0" disabled>
              Select a month
            </option>
            {months.map((month, index) => (
              <option key={index} value={month}>
                {month} {format(startOfToday(), "yyyy")}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div className="h-72">
        <Bar data={data} options={options} config={config} />
      </div>
    </div>
  );
};

export default AttendanceGraph;
