import { axiosInstance as axios } from "../context/AuthContext";

const paymentBaseUrl = `${process.env.REACT_APP_BASE_URL}/Payments`;

//Creating payment request for Razorpay
export const CreatePayment = async (paymentInfo) => {
  const response = await axios({
    url: `${paymentBaseUrl}/create`,
    method: "POST",
    data: JSON.stringify(paymentInfo),
  }).then((response) => {
    return response.data;
  });
  return response;
};

//Verifying Razorpay Payment

export const VerifyPayment = async (paymentResponse) => {
  const response = await axios({
    url: `${paymentBaseUrl}/verify`,
    method: "POST",
    data: JSON.stringify(paymentResponse),
  }).then((response) => {
    return response.data;
  });
  return response;
};
