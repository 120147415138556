import { createSlice } from "@reduxjs/toolkit";
import { deleteUserFile, getAllUserFiles } from "./../../services/FileUploadService";
import toast from "react-hot-toast";

/**
 * The initial state of the profileSlice
 * @type {Object}
 * @property {Object} tabs - The tabs object
 * @property {Object} data - The data object
 */
const initialState = {
  tabs: {
    id: "tabs",
    activeTab: "general",
  },
  data: {
    /**
     * These documents will be rendered in user profile-document tab
     * Document object structure {file: string, fileType: string, fileName: string, docType: string}
     */
    documents: [],
  },
  password: {
    updatePassword: false,
  },
};

const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    setActiveTab: (state, action) => {
      state.tabs.activeTab = action.payload;
    },

    resetActiveTab: state => {
      state.tabs.activeTab = "general";
    },

    setAllDocuments: (state, action) => {
      state.data.documents = action.payload;
    },

    setSingleDocument: (state, action) => {
      state.data.documents = [action.payload, ...state.data.documents];
    },

    toggleUpdatePassword: state => {
      state.password.updatePassword = !state.password.updatePassword;
    },

    // updateDocument: (state, action) => {
    //   const docs = state.data.documents;
    //   const newFilteredDocs = docs.map(doc =>
    //     doc.id === action.payload.id ? action.payload : doc
    //   );
    //   state.data.documents = newFilteredDocs;
    // },

    deleteDocument: (state, action) => {
      const docs = state.data.documents;
      const { userId, fileType } = action.payload;
      const newFilteredDocs = docs.filter(
        doc => doc.userId === userId && doc.fileType !== fileType
      );
      state.data.documents = newFilteredDocs;
    },
  },
});

const getUserFiles = function (userId) {
  return async dispatch => {
    const data = await getAllUserFiles(userId);
    dispatch(profileSlice.actions.setAllDocuments(data));
  };
};

const deleteFile = function (data) {
  return async dispatch => {
    dispatch(profileSlice.actions.deleteDocument(data));
    const res = await deleteUserFile(data);
    if (res.statusCode === 200) {
      toast.success("File deleted successfully");
    }
  };
};

// export const uploadDoc = function (data) {
//   return async dispatch => {
//     dispatch(profileSlice.actions.setSingleDocument(data));
//     const res = await UploadUserDocs(data);
//   };
// };

export { deleteFile, getUserFiles };
export const { setActiveTab, resetActiveTab, deleteDocument, toggleUpdatePassword } =
  profileSlice.actions;
export default profileSlice.reducer;
