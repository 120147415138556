import { createContext, useState } from "react";

export const InvoiceContext = createContext();

export const InvoiceContextProvider = ({ children }) => {
  const [isUpdateInvoiceList, setIsUpdateInvoiceList] = useState(false);
  return (
    <InvoiceContext.Provider
      value={{
        isUpdateInvoiceList,
        setIsUpdateInvoiceList,
      }}
    >
      {children}
    </InvoiceContext.Provider>
  );
};
