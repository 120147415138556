import { useMemo } from "react";
import Table from "../../common/Table/Table";

import InvoiceItem from "../InvoiceItem";
import InvoiceItemSmallScreen from "../InvoiceItemSmallScreen";

import { TABLE_HEADINGS } from "../helper";

const ItemTable = ({
  invoiceItems,
  setAllAmounts,
  setFormValues,
  allAmounts,
  radioButtonValue,
}) => {

  const tableHeading = TABLE_HEADINGS.filter(heading => {
    if (radioButtonValue === 'igst' && !(heading === 'cgst' || heading === 'sgst')) {
      return heading
    } else if (radioButtonValue !== 'igst' && heading !== 'igst') {
      return heading
    }
  });

  const tableRows = invoiceItems.map((el, i) => {
    return (
      <InvoiceItem
        value={el}
        key={i}
        invoiceItems={invoiceItems}
        setAllAmounts={setAllAmounts}
        setFormValues={setFormValues}
        allAmounts={allAmounts}
        radioValue={radioButtonValue}
      />
    );
  });

  const memoizedTableHeading = useMemo(() => tableHeading, [tableHeading]);
  const memoizedTableRows = useMemo(() => tableRows, [tableRows]);

  return (
    <>
      <Table
        col={memoizedTableHeading}
        headClass="text-white"
        tableClass='hidden md:table w-full text-sm text-left '
        thClassName='!px-0'
      >
        <tbody className="px-0 mt-4 bg-[hsl(218,100%,98%)]">
          {memoizedTableRows}
        </tbody>
      </Table>

      {/* small screen-invoices */}
      <div className="small-screen-container md:hidden">
        {/* small-screen-heading */}
        <div className="small-screen-header w-full h-14 bg-thynkwebPrimary-800 text-white rounded-lg pt-3 pl-3 text-lg">
          Item
        </div>
        {/* small-screen-content */}
        {
          invoiceItems.map((el, i) => {
            return (
              <InvoiceItemSmallScreen
                value={el}
                key={i}
                invoiceItems={invoiceItems}
                setAllAmounts={setAllAmounts}
                setFormValues={setFormValues}
                allAmounts={allAmounts}
                radioValue={radioButtonValue}
                ind={i}
              />
            );
          })
        }
      </div>
    </>
  );
};

export default ItemTable;
