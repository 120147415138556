import { useDispatch, useSelector } from "react-redux";
import { useCallback, useRef } from "react";

import NoNotification from "./NoNotification";
import NotificationItem from "./NotificationItem";

import { setPageNo } from "../../../store/slices/pagination";
import { updateNotificationOnScroll } from "../../../store/slices/NotificationSlice";

/**
 * Generate the notification list component.
 *
 * @param {array} notifications - An array of notification objects.
 * @return {JSX.Element} - The rendered notification list component.
 */
const NotificationList = ({ notifications, totalRecords }) => {
  const observer = useRef();
  const { pageNo } = useSelector(state => state.pagination);
  const dispatch = useDispatch();

  const lastElem = useCallback(node => {
    if (notifications.length === 0) return;
    const hasMore = totalRecords !== notifications.length;

    if (observer.current) observer.current.disconnect();

    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && hasMore) {
        const newPageNo = pageNo + 1;
        dispatch(setPageNo(newPageNo));
        dispatch(updateNotificationOnScroll(pageNo));
      }
    });

    if (node) observer.current.observe(node);
  });

  return (
    <>
      {notifications.length === 0 && <NoNotification />}
      {notifications.length > 0 &&
        notifications.map((notification, index) => {
          if (index + 1 === notifications.length)
            return <NotificationItem ref={lastElem} key={index} notification={notification} />;
          else return <NotificationItem key={index} notification={notification} />;
        })}
    </>
  );
};

export default NotificationList;
