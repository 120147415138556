const SkillsSvg = ({ className }) => {
  return (
    <svg
      className={className}
      fill="currentColor"
      // style={{ enableBackground: "new 0 0 512 512" }}
      version="1.1"
      viewBox="0 0 512 512"
      xmlSpace="preserve"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink">
      <g id="C_-_78_x2C__Skills_x2C__Abilities_x2C__employee_x2C__human_x2C__man_x2C__people">
        <g>
          <path d="M433.219,179.645c-7.981,0-14.475,6.454-14.475,14.387c0,7.93,6.493,14.381,14.475,14.381c7.979,0,14.47-6.452,14.47-14.381C447.688,186.099,441.197,179.645,433.219,179.645z" />
          <path d="M433.219,437.069c-7.981,0-14.475,6.451-14.475,14.381s6.493,14.382,14.475,14.382c7.979,0,14.47-6.452,14.47-14.382S441.197,437.069,433.219,437.069z" />
          <path d="M214.446,198.717H106.432c-23.225,0-42.12,18.795-42.12,41.897v53.607h28.953v-33.453c0-2.588,2.099-4.685,4.686-4.685c2.588,0,4.686,2.097,4.686,4.685v33.453h115.155v-33.453c0-2.588,2.098-4.685,4.686-4.685c2.588,0,4.685,2.097,4.685,4.685v33.453h28.944v-54.064C256.105,217.307,237.417,198.717,214.446,198.717z" />
          <path d="M433.219,313.124c-7.981,0-14.475,6.452-14.475,14.383c0,7.932,6.493,14.385,14.475,14.385c7.979,0,14.47-6.453,14.47-14.385C447.688,319.576,441.197,313.124,433.219,313.124z" />
          <path d="M433.219,84.469c7.979,0,14.47-6.452,14.47-14.382c0-7.93-6.491-14.383-14.47-14.383c-7.981,0-14.475,6.453-14.475,14.383C418.744,78.018,425.237,84.469,433.219,84.469z" />
          <path d="M160.212,151.128c29.664,0,53.797-23.541,53.797-52.477c0-28.938-24.133-52.482-53.797-52.482c-29.667,0-53.804,23.544-53.804,52.482C106.408,127.587,130.544,151.128,160.212,151.128z" />
          <path d="M102.636,456.302h52.891V346.577c0-2.588,2.099-4.686,4.686-4.686c2.588,0,4.686,2.098,4.686,4.686v109.725h52.893V303.593H102.636V456.302z" />
          <path d="M375.742,74.772c2.588,0,4.685-2.097,4.685-4.686c0-2.588-2.097-4.686-4.685-4.686h-47.895c-2.588,0-4.686,2.098-4.686,4.686v119.259h-33.629c-2.587,0-4.686,2.098-4.686,4.686s2.099,4.686,4.686,4.686h33.629v124.104h-33.629c-2.587,0-4.686,2.098-4.686,4.686s2.099,4.686,4.686,4.686h33.629V451.45c0,2.588,2.098,4.686,4.686,4.686h38.314c2.588,0,4.686-2.098,4.686-4.686s-2.098-4.686-4.686-4.686h-33.629V332.192h43.209c2.588,0,4.685-2.098,4.685-4.686s-2.097-4.686-4.685-4.686h-43.209V198.717h43.209c2.588,0,4.685-2.098,4.685-4.686s-2.097-4.686-4.685-4.686h-43.209V74.772H375.742z" />
        </g>
      </g>
      <g id="Layer_1" />
    </svg>
  );
};

export default SkillsSvg;
