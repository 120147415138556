import { axiosInstance as axios } from "../context/AuthContext";

const baseUrl = process.env.REACT_APP_BASE_URL;
const PayrollBaseUrl = `${baseUrl}/Payroll`;

//Get salary components
export const GetSalaryComponents = async () => {
  const response = await axios.get(`${PayrollBaseUrl}/SalaryComponents`);
  return response;
};

export const GetSpecificSalaryComponents = async type => {
  const response = await axios.get(`${PayrollBaseUrl}/SalaryComponents/${type}`);
  return response;
};

export const AddSalaryComponents = async bodyData => {
  const response = await axios.post(`${PayrollBaseUrl}/SalaryComponents`, bodyData);
  return response;
};

export const UpdateStatus = async (id, status) => {
  const response = await axios.patch(`${PayrollBaseUrl}/SalaryComponents/${id}?status=${status}`);
  return response;
};

export const GetSalaryComponentById = async id => {
  const response = await axios.get(`${PayrollBaseUrl}/SalaryComponents/${id}`);
  return response;
};

export const UpdateSalaryComponents = async bodyData => {
  const response = await axios.put(`${PayrollBaseUrl}/SalaryComponents`, bodyData);
  return response;
};

export const DeleteSalaryComponent = async id => {
  const response = await axios.delete(`${PayrollBaseUrl}/SalaryComponents/${id}`);
  return response;
};

export const runPayroll = async () => {
  const response = await axios.post(`${PayrollBaseUrl}/Run`).then(response => {
    return response?.data;
  });
  return response;
};

export const getPayrollById = async payrollId => {
  const response = await axios.get(`${PayrollBaseUrl}/${payrollId}`).then(response => {
    return response?.data;
  });
  return response;
};

export const approvePayroll = async payrollId => {
  const response = await axios.post(`${PayrollBaseUrl}/Approve/${payrollId}`).then(response => {
    return response?.data;
  });
  return response;
};

export const recordPayments = async recordPayment_body => {
  const response = await axios
    .post(`${PayrollBaseUrl}/RecordPayments`, recordPayment_body)
    .then(response => {
      return response?.data;
    });
  return response;
};

export const getEarnings = async isPayrollRun => {
  const response = await axios
    .get(`${PayrollBaseUrl}/SalaryComponents/Earnings?isPayrollRun=${isPayrollRun}`)
    .then(response => {
      return response?.data;
    });
  return response;
};

export const getDeductions = async isDropdown => {
  const response = await axios
    .get(`${PayrollBaseUrl}/SalaryComponents/Deductions?isPayrollRun=${isDropdown}`)
    .then(response => {
      return response?.data;
    });
  return response;
};

export const getPayrollInsights = async () => {
  const response = await axios.get(`${PayrollBaseUrl}/Insights`).then(response => {
    return response?.data;
  });
  return response;
};

export const updatePayslipComponents = async bodyData => {
  const response = await axios
    .put(`${PayrollBaseUrl}/payslips/components`, bodyData)
    .then(response => {
      return response?.data;
    });
  return response;
};

// Delete Payroll
export const deletePayroll = async payrollId => {
  const response = await axios.delete(`${PayrollBaseUrl}/${payrollId}`);
  return response;
};

/**
 * Sends a payslip to a user.
 *
 * @param {string} userId - The ID of the user.
 * @param {string} payrollId - The ID of the payroll.
 * @param {string} clientURI - The URI of the client.
 * @return {Promise} A promise that resolves when the payslip has been sent.
 */
export const sendPayslip = async function (userId, payrollId, clientURI) {
  const res = await axios.post(
    `${PayrollBaseUrl}/payslips/send?payrollId=${payrollId}&userId=${userId}&clientURI=${clientURI}`
  );
  return res?.data;
};

//################################# PAYROLL HISTORY ################################

/**
 * Retrieves the payroll history with the specified page size and page number.
 *
 * @param {number} pageSize - The number of payroll records to retrieve per page.
 * @param {number} pageNumber - The page number of the payroll records to retrieve.
 * @return {Promise<Object[]>} An array of payroll records.
 */
export const getPayrollHistory = async (pageSize, pageNumber) => {
  const res = await axios.get(
    `${PayrollBaseUrl}/histories?pageSize=${pageSize}&pageNumber=${pageNumber}`
  );
  return res?.data;
};

export const rejectPayrollApprovalById = async function (payrollId) {
  const res = await axios.post(
    `${PayrollBaseUrl}/Reject/${payrollId}`
  );
  return res?.data;
};

export const resetPayslipById = async function (payslipId) {
  const res = await axios.post(
    `${PayrollBaseUrl}/payslips/reset/${payslipId}`
  );
  return res?.data;
}


export const withHoldPayslip = async function (payslipId) {
  const res = await axios.post(
    `${PayrollBaseUrl}/payslips/${payslipId}/withhold`
  )
  return res?.data;
}

export const revertWithholdPayslip = async function (payslipId) {
  const res = await axios.post(
    `${PayrollBaseUrl}/payslips/${payslipId}/revertWithhold`
  )
  return res?.data;
}

export const getWithheldPayslips = async function (employeeId) {
  const res = await axios.get(
    `${PayrollBaseUrl}/payslips/withhold?employeeId=${employeeId}`
  )
  return res?.data;
}

export const addSalaryReleaseEarningToPayslip = async function (salaryReleaseDto) {
  const res = await axios.post(
    `${PayrollBaseUrl}/payslips/addSalaryRelease`, salaryReleaseDto
  );
  return res?.data;
}