import { memo } from "react";
import { capitalizeFirstLetter } from "../../common/helper";
import { v4 as uuidv4 } from "uuid";

/**
 *
 * @param {Object} ReactPropTypes React props
 * @param {array[]} props.arr An array of string/number or both param0
 * @param {array[]} props.capFirstLetter Capitalize the first letter of every word in a string for Option Label and Value
 * @param {Object} props.optionsAdditionsProps Additional attributes
 * @param {string} props.placeholder Placeholder value for select element
 * @param {boolean} props.showPlaceholder set to true if you want to show placeholder
 * @returns options array
 */

function Options({
  optionsArr,
  capFirstLetter = false,
  optionsAdditionsProps,
  placeholder,
  showPlaceholder,
}) {
  return (
    <>
      {showPlaceholder && <option hidden>{placeholder}</option>}
      {optionsArr.map(option => (
        <option
          key={uuidv4()}
          //only specific to invoices
          hidden={option === "Select Country" || option === "Select State / Provinces"}
          {...optionsAdditionsProps}
          value={capFirstLetter ? capitalizeFirstLetter(option) : option}>
          {capFirstLetter ? capitalizeFirstLetter(option) : option}
        </option>
      ))}
    </>
  );
}
const MemoizedOptions = memo(Options);
export default MemoizedOptions;
