import { useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Label } from "flowbite-react";
import { useSelector } from "react-redux";

import MultiSelect from "../../common/MultiSelect/MultiSelect";
import InputFields from "../../common/Input/InputFields";
import ModalButtons from "../../common/Modal/ModalButtons";

import { skillInputFields as InputFieldOptions } from "../helper";

/**
 * Renders the SkillsComponent and handles the logic for adding or updating a skill.
 *
 * @param {Object} props - The props object containing the following properties:
 *   - isEditing: A boolean indicating whether the component is in editing mode.
 *   - selectedSkill: An array of selected skills.
 *   - selectedSkillDetails: An object containing details of the selected skill.
 *   - onSubmit: A function to handle the submit action.
 *   - onCancel: A function to handle the cancel action.
 * @return {JSX.Element} The rendered SkillsComponent.
 */
const SkillsComponent = ({
  isEditing,
  selectedSkill,
  selectedSkillDetails,
  onSubmit,
  onCancel,
}) => {
  const [skillInputFields, setSkillInputFields] = useState(InputFieldOptions);
  const [reactSelectValue, setReactSelectValue] = useState({});
  const [searchParams, _] = useSearchParams();
  const { skills } = useSelector(state => state.employment.user);

  useEffect(() => {
    //fetch user skills
    if (isEditing) {
      setSkillInputFields(selectedSkill);
      setReactSelectValue(selectedSkillDetails);
    }
  }, [selectedSkill?.length]);

  const addSkillHandler = SelectedOption => {
    if (SelectedOption.length > 1) return toast.error("Only one skill can be added at a time");
    setReactSelectValue(SelectedOption);
  };

  const onChangeHandler = function (e) {
    const { name, value } = e.target;

    if (name === "strength" && +value > 5) return toast.error("Strength should be less than 5");
    setSkillInputFields(prev => {
      return prev.map(field => {
        if (field.name === name) {
          return { ...field, value };
        }
        return field;
      });
    });
  };

  const onSubmitHandler = e => {
    e.preventDefault();
    setReactSelectValue({});

    //clear the value in both input objects
    setSkillInputFields(prevSIF => {
      return prevSIF.map(field => {
        return { ...field, value: "" };
      });
    });
    const modifiedObject = {
      userId: searchParams.get("userId"),
      name: reactSelectValue.label,
      skillId: reactSelectValue.id,
      strength: skillInputFields[0].value,
      lastWorkedOn: new Date(`${skillInputFields[1].value}`).toISOString().replace("Z", ""),
    };
    onSubmit(modifiedObject);
  };

  const onCancelHandler = () => {
    setReactSelectValue({});

    //clear the value in both input objects
    setSkillInputFields(prevSIF => {
      return prevSIF.map(field => {
        return { ...field, value: "" };
      });
    });
    onCancel();
  };

  return (
    <div className="sm:grid sm:grid-cols-2 sm:gap-6 flex flex-col mt-4 gap-4">
      <div>
        <Label htmlFor="addSkills">{isEditing ? "Update Skill" : "Add Skill"}</Label>
        <MultiSelect
          onChange={addSkillHandler}
          isMulti={false}
          id="addSkills"
          isDisabled={isEditing}
          value={reactSelectValue}
          defaultValue={isEditing ? selectedSkillDetails : ""}
          options={skills.options}
        />
      </div>

      {skillInputFields.map(field => {
        return (
          <InputFields
            key={field?.id}
            onChange={onChangeHandler}
            inputClassName="w-full"
            InputField={field}
            helperText={field?.name === "strength" && "A number between 0 and 5"}
            value={field?.value}
          />
        );
      })}

      <ModalButtons
        btnCont="row-start-3 row-end-4 col-start-1 col-end-3"
        cancelBtnText="Cancel"
        actionBtnText={isEditing ? "Update Skill" : "Add Skill"}
        onClickAction={onSubmitHandler}
        onClickCancel={onCancelHandler}
      />
    </div>
  );
};

export default SkillsComponent;
