import { Select } from 'flowbite-react';
import { BsPlusSquareFill } from 'react-icons/bs';
import { RiPencilFill } from 'react-icons/ri';

import EditAddress from '../../addresses/EditAddress';
import FormContainer from "./FormContainer";
import FormInput from '../../common/FormInput';
import Label from '../../common/Label';

import { radioPrimaryClass } from '../../../utils/helper';

const ShippedFrom = ({
  shippingDetails,
  setToggleStates,
  selectedBusinessAddress,
  closeEditAddressModal,
  businessAddresses,
  setAddressAsBusiness,
  onShippingDetailsChange,
}) => {
  const openShippedFromModal = () =>
    setToggleStates(prevTS => ({
      ...prevTS,
      toggleShippingModal: true,
      shippingIsClient: false,
    }));

  return (
    <FormContainer>
      <div className="flex justify-between">
        <p className="sm:text-[1rem] sm:font-semibold lg:font-normal lg:text-2xl underline decoration-dotted decoration-3 underline-offset-8">
          Shipped From
        </p>
        {!shippingDetails.sameAsBusinessAddress && (
          <BsPlusSquareFill
            onClick={openShippedFromModal}
            size="30px"
            fill="#cdc733"
            className={`cursor-pointer`}
          />
        )}
      </div>

      <div className="flex p-1 items-center gap-3 mt-4">
        <FormInput
          type="checkbox"
          onChange={setAddressAsBusiness}
          value={shippingDetails.sameAsBusinessAddress}
          otherProps={{
            checked: shippingDetails.sameAsBusinessAddress,
          }}
          className={`w-4 h-4 rounded cursor-pointer ${radioPrimaryClass()}`}
          id="same-as-business"
        />

        <Label
          value={"Same as Business address"}
          htmlFor={"same-as-business"}
          className={"cursor-pointer no-underline"}
        />
      </div>
      {!shippingDetails.sameAsBusinessAddress && (
        <Select
          name="shippedFrom"
          className="my-4 rounded-md w-full border-[1px] border-[#b4c2d5] bg-[#f7faff] cursor-pointer"
          onChange={onShippingDetailsChange}
          value={shippingDetails.shippedFrom}>
          <option
            defaultValue="select country"
            hidden={true}>
            -Select Shipped from address-
          </option>

          {businessAddresses.map(address => {
            return (
              <option
                key={address.id}
                value={address.id}>
                {address.businessName}
              </option>
            );
          })}
        </Select>
      )}
      {(shippingDetails.sameAsBusinessAddress || shippingDetails.shippedFrom != 0) && (
        <div className="rounded-md pb-2 min-h-auto min-[768px]:max-[1030px]:min-h-[13.5rem] border-[1px] border-[#b4c2d5] bg-[white] mt-4">
          <div className="flex justify-between items-center text-xl text-green-900">
            <div className="px-2 py-3 text-xl">Address details</div>
            <div className="mt-1 flex justify-between items-center">
              {!shippingDetails.sameAsBusinessAddress && (
                <>
                  <RiPencilFill
                    className="-mr-4"
                    fill="#cdc733"
                  />
                  <EditAddress
                    isClient={false}
                    onClose={closeEditAddressModal}
                    oldAddress={selectedBusinessAddress}
                  />
                </>
              )}
            </div>
          </div>

          <hr className="h-px bg-[#b4c2d5] border-0 dark:bg-gray-700" />

          <div className="flex flex-col gap-2 p-4 text-gray-400 min-[0px]:max-[374px]:text-[0.7rem] min-[375px]:max-[500px]:text-[0.85rem] min-[768px]:max-[1030px]:text-[0.75rem] ">
            <div className="flex">
              <span className="text-[#647282] w-[30%] min-[768px]:max-[1050px]:w-[25%] min-[0px]:max-[374px]:w-[55%]">
                Address:{" "}
              </span>
              <span className="text-black pl-4 break-words w-[70%]">
                {" "}
                {selectedBusinessAddress?.address}{" "}
              </span>
            </div>
            <div className="flex">
              <span className="text-[#647282] w-[30%] min-[768px]:max-[1050px]:w-[25%] min-[0px]:max-[374px]:w-[55%]">
                Country:{" "}
              </span>
              <span className="text-black pl-4 "> {selectedBusinessAddress?.country} </span>
            </div>
            <div className="flex">
              <span className="text-[#647282] w-[30%] min-[768px]:max-[1050px]:w-[25%] min-[0px]:max-[374px]:w-[55%]">
                State:
              </span>
              <span className="text-black pl-4 "> {selectedBusinessAddress?.state} </span>
            </div>
            <div className="flex">
              <span className="text-[#647282] w-[30%] min-[768px]:max-[1050px]:w-[25%] min-[0px]:max-[374px]:w-[55%]">
                City:
              </span>
              <span className="text-black pl-4 "> {selectedBusinessAddress?.city} </span>
            </div>
            <div className="flex">
              <span className="text-[#647282] w-[30%] min-[768px]:max-[1050px]:w-[25%] min-[0px]:max-[374px]:w-[55%]">
                PostalCode:{" "}
              </span>
              <span className="text-black pl-4 ">
                {" "}
                {selectedBusinessAddress?.postalCode || ""}{" "}
              </span>
            </div>
            <div className="flex">
              <span className="text-[#647282] w-[30%] min-[768px]:max-[1050px]:w-[25%] min-[0px]:max-[374px]:w-[55%]">
                GSTIN:
              </span>
              <span className="text-black pl-4 "> {selectedBusinessAddress?.gstin} </span>
            </div>
          </div>
        </div>
      )}
    </FormContainer>
  );
};

export default ShippedFrom;
