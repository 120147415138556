import React, { useEffect, useState } from 'react';
import { formattedDate } from '../../../utils/common';
import { GetUserById } from '../../../services/UserService';
import { Avatar } from 'flowbite-react';
import { IoMdLogOut } from "react-icons/io";

const EmployeesOnLeave = ({employeesOnLeave}) => {
  var [employeesOnLeave, setEmployeesOnLeave] = useState(employeesOnLeave);

  useEffect(() => {
    const fetchUsersInfo = async () => {
      const updatedEmployeesOnLeave = [];
      for (const employeeOnLeave of employeesOnLeave) {
        const userId = employeeOnLeave.id;
        try {
          const res = await GetUserById(userId);
          const userData = res.data;
          const updatedEmployeeOnLeave = {
            ...employeeOnLeave,
            image: userData.imageBytes && `data:image/jpeg;base64,${userData.imageBytes}`
          };
          updatedEmployeesOnLeave.push(updatedEmployeeOnLeave);
        } catch (error) {
          console.error(`Error fetching user data for user ID ${userId}:`, error);
        }
      }
      setEmployeesOnLeave(updatedEmployeesOnLeave);
    };
    fetchUsersInfo();
  }, []);
  
  return (
  <div>
    <div className="w-full h-96 bg-white rounded-lg">
      <div className="flex items-center justify-between p-5 pb-3">
        <h5 className="text-xl font-bold leading-none text-gray-700 dark:text-white">
          People On Leave
        </h5>
        <p>This week</p>
      </div>
      <div>
        <hr className="h-px my-2 bg-gray-200 border-0 dark:bg-gray-700"></hr>
      </div>
      {employeesOnLeave.length === 0 && (
        <div className="flex flex-col items-center relative">
          <IoMdLogOut size={"9rem"} className='absolute top-10' color='#BFE2D2' />
          <p className="text-center absolute top-60 text-gray-500 dark:text-gray-400">No planned leaves for this week</p>
        </div>
      )} 
      {employeesOnLeave.length > 0 && (
        <div className="pl-3 pr-5 pb-5 flow-root">
          <ul role="list" className="divide-gray-200 dark:divide-gray-700">
            {employeesOnLeave.map(employeeOnLeave => {
              return(
                <li key={employeeOnLeave.id} className="py-2 sm:py-2">
                  <div className="flex items-center">
                    <div className="flex-shrink-0">
                      <Avatar
                          rounded={true}
                          placeholderInitials={`${employeeOnLeave.firstName && employeeOnLeave.firstName[0].toUpperCase()}${employeeOnLeave.lastName && employeeOnLeave.lastName[0].toUpperCase()}`}
                          className="mx-2 z-auto cursor-pointer"
                          img={employeeOnLeave.image}
                      />
                    </div>
                    <div className="flex-1 min-w-0 ms-4">
                      <p className="text-sm font-medium text-gray-900 truncate dark:text-white">
                        {`${employeeOnLeave.firstName} ${employeeOnLeave.lastName}`}
                      </p>
                      <p className="text-sm text-gray-500 truncate dark:text-gray-400">
                        {employeeOnLeave.email}
                      </p>
                    </div>
                    <div className="inline-flex items-center text-base font-semibold text-gray-600 dark:text-white">
                      {formattedDate(employeeOnLeave.date)}
                    </div>
                  </div>
                </li>
              )}
            )}
          </ul>
        </div>
      )}
    </div>
  </div>
  );
}

export default EmployeesOnLeave;
