import { useState, useId } from "react";

import ModalComponent from "../common/Modal/Modal";

import { AssignUserRole } from "../../services/UserService";
import { createUID } from "./helper";
import { btnPrimary } from "../../utils/helper";
import { useAppDispatch, useAppSelector } from "../../store/store";
import { setRefetchUsers } from "../../store/slices/userManagementSlice";

export const RoleEmployee = ({ userData, toggleRole, setToggleRole }) => {
  const dispatch = useAppDispatch();
  const umState = useAppSelector(state => state.userManagement);

  const UID = useId();

  // To display the error message
  const [errorMessage, setErrorMessage] = useState(false);

  // Setting the role initial
  const [role, setRole] = useState(umState.user.roleId);

  // To handle the role change, we need to set the role to the new role
  const handleRole = e => {
    setRole(e.target.value);
    setErrorMessage(false);
  };

  // function to assign role to user
  const assign = e => {
    e.preventDefault();

    // Assign role to user
    // userData.roleId = role;
    AssignUserRole(umState.user.id, role).then(res => {
      if (res.data.statusCode === 200) {
        dispatch(setRefetchUsers(!umState.refetchUsers))
        setToggleRole(false);
      } else {
        // If user role is already assigned then show error message
        setErrorMessage(true);
      }
    });
  };
  return (
    <>
      <ModalComponent
        show={toggleRole}
        onClose={setToggleRole.bind(null, false)}
        showFooter={false}
        size="xl"
        heading="Role Assign">
        <form className="flex flex-col px-4 mx-auto max-w-2xl lg:py-4 m-auto overflow-x-hidden overflow-y-auto">
          <div className="grid gap-4 mb-4 md:gap-6 md:grid-cols-2 sm:mb-8">
            {/* Name */}
            <div>
              <label
                htmlFor="user-name"
                className="inline-flex items-center mb-2 text-sm font-medium text-gray-900 dark:text-white">
                Name
              </label>
              <div
                className=" font-bold text-xl"
                id="user-name">{`${umState.user.firstName} ${umState.user.lastName}`}</div>
            </div>
            {/* Role Dropdown */}
            <div>
              <label
                htmlFor={createUID(UID, "role-type")}
                className="inline-flex items-center mb-2 text-sm font-medium text-gray-900 dark:text-white">
                Role
              </label>
              <select
                id={createUID(UID, "role-type")}
                name="roleId"
                defaultValue={umState.user.roleId}
                onChange={handleRole}
                required=""
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-thynkwebPrimary-500 focus:border-thynkwebPrimary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-thynkwebPrimary-500 dark:focus:border-thynkwebPrimary-500">
                <option hidden>Select Role</option>
                <option value={1}>Administrator</option>
                <option value={2}>Moderator</option>
                <option value={3}>Member</option>
              </select>
            </div>
          </div>

          {/* Button to add user */}
          <div className=" flex flex-row-reverse items-center">
            {errorMessage ? (
              <button
                disabled
                className={`inline-flex  px-5 py-2.5 text-sm font-medium text-center text-white bg-gray-500 rounded-lg focus:ring-4  dark:focus:ring-thynkwebPrimary-900`}>
                Assign
              </button>
            ) : (
              <button
                type="submit"
                onClick={e => assign(e)}
                className={`${btnPrimary()} rounded-lg text-white inline-flex  px-5 py-2.5 text-sm font-medium text-center`}>
                Assign
              </button>
            )}
            {errorMessage && <span className=" text-red-800 mr-6">* Role already assigned</span>}
          </div>
        </form>
      </ModalComponent>
    </>
  );
};
