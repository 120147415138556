import { Outlet } from "react-router-dom";

import { SidebarAdministration } from "../components/Control Panel/SidebarAdministration";

const ControlPanel = () => {
  return (
    <div className="flex">
      <SidebarAdministration />

      <div className="h-full w-full">
        {/* All the different route will be shown here */}
        {/* Outlet is given by react router dom  */}
        <Outlet />
      </div>
    </div>
  );
};

export default ControlPanel;
