import { axiosInstance as axios } from "../context/AuthContext";

const baseUrl = process.env.REACT_APP_BASE_URL;
const attendanceBaseUrl = `${baseUrl}/Attendances`;

/**
 * Retrieves attendances data based on the specified start and end dates and employee ID.
 *
 * @param {Date} startDate - the start date for the attendance data
 * @param {Date} endDate - the end date for the attendance data
 * @param {number} employeeId - the ID of the employee for whom the attendance data is retrieved
 * @return {Promise} the attendance data retrieved from the server
 */
export const getAttendances = async (startDate, endDate, employeeId) => {
  const response = await axios.get(
    `${attendanceBaseUrl}?startDate=${startDate}&endDate=${endDate}&employeeId=${employeeId}`
  );
  return response?.data;
};

/**
 * Retrieves attendance settings.
 * @return {Promise} the attendance settings data retrieved from the server
 */
export const getAttendanceSettings = async () => {
  const response = await axios.get(`${attendanceBaseUrl}/settings`);
  return response?.data;
};

/**
 * Update attendance settings.
 * @param {object} bodyData - the data to be sent in the request body
 * @return {Promise} the updated response returned from the server
 */
export const updateAttendanceSettings = async bodyData => {
  const response = await axios.put(`${attendanceBaseUrl}/settings`, bodyData);
  return response?.data;
};

/**
 * Retrieves the active time logs from the attendance base URL.
 *
 * @return {Object} The data returned from the active time logs API endpoint.
 */
export const timeLogsActive = async () => {
  const response = await axios.get(`${attendanceBaseUrl}/timelogs/active`);
  return response?.data;
};

/**
 * Starts the timer by sending a POST request to the attendance API endpoint.
 *
 * @return {Promise<any>} The response data from the API.
 */
export const startTimer = async formData => {
  const response = await axios.post(`${attendanceBaseUrl}/startTimer`, formData);
  return response?.data;
};

/**
 * Asynchronously stops the timer by sending a POST request to the attendance base URL.
 *
 * @return {Promise} The data returned from the POST request.
 */
export const stopTimer = async () => {
  const response = await axios.post(`${attendanceBaseUrl}/stopTimer`);
  return response?.data;
};

export const deleteTimeLog = async timeLogId => {
  const response = await axios.delete(`${attendanceBaseUrl}/timelogs/${timeLogId}`);
  return response?.data;
};

export const attendanceStatusUpdate = async(attendanceIdCsv=" ", status=" ")=>{
  const response = await axios.post(`${attendanceBaseUrl}/status?attendanceIdCsv=${attendanceIdCsv}&status=${status}`);
  return response;
}

export const attendancesTimelogs = async timelogs =>{
  const response = await axios.post(`${attendanceBaseUrl}/timelogs`, timelogs);
  return response;
}