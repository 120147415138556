import React from "react";

/**
 * Renders a TextArea component with the specified properties.
 *
 * @param {{id: string, name: string, label: string, value: string, onChange: function, placeholder: string, styles: object}} props - The properties for the TextArea component
 * @param {object} props - The properties for the TextArea component
 * @return {JSX.Element} The rendered TextArea component
 */
const TextArea = ({ id, name, label, value, onChange, placeholder, styles }) => {
  return (
    <div className={styles.textAreaContainer}>
      <label
        htmlFor={id}
        className={`${styles.textAreaLabelClassName} block mb-2 text-sm font-medium text-gray-900 dark:text-white`}>
        {label}
      </label>
      <textarea
        id={id}
        name={name}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-thynkwebPrimary-600 focus:border-thynkwebPrimary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-thynkwebPrimary-500 dark:focus:border-thynkwebPrimary-500 ${styles.textAreaInputClassName}`}
      />
    </div>
  );
};

export default TextArea;
