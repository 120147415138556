import { memo } from "react";
import TableRows from "../common/Table/TableRows";
import TableData from "../common/Table/TableData";
import { cn } from "../../utils/common";

/**
 *
 * @param {{isTable: boolean, tableRowClass?: string, tableDataClass?: string, message: string, itemsArr: Array, colSpan: number}} props - The component props
 */
const Noitemfound = function NoItemFound({
  isTable,
  tableRowClass,
  tableDataClass,
  message,
  itemsArr,
  colSpan,
}) {
  return (
    <>
      {/* only render tr if isTable is true and itemsArr length is equal to zero(0) */}
      {isTable && itemsArr?.length === 0 && (
        <TableRows className={cn(`${tableRowClass} border-b-0`)}>
          <TableData
            className={cn(`${tableDataClass} tracking-wide text-center border-0 text-[#6b7280]`)}
            otherProps={{ colSpan: colSpan ? colSpan : "3" }}>
            {message}
          </TableData>
        </TableRows>
      )}

      {/* Can be added more message for different elements */}
      {!isTable && (
        <div>
          <p className="tracking-wide text-center border-0 text-[#6b7280]">{message && message}</p>
        </div>
      )}
    </>
  );
};

/**
 * Renders a message when no items are found.
 *
 * @param {boolean} isTable - Flag indicating whether a table is being rendered.
 * @param {string?} tableRowClass - The CSS class for the table row.
 * @param {string?} tableDataClass - The CSS class for the table data.
 * @param {string} message - The message to display.
 * @param {Array} itemsArr - The array of items.
 * @param {number|string} colSpan - The number of columns to span the table row value must be equal to number of cols in the table to avoid issues.
 * @return {ReactElement} The rendered message.
 */
const MemoizedNoItemFound = memo(Noitemfound);

export default MemoizedNoItemFound;
