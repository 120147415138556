import { Checkbox } from "../../hooks/useCheckbox.jsx";
import { SortSVG } from "../../media/SortSVG.js";
import { HEADINGS } from "./helper";

const EmployeeListTableHeading = props => {
  const {
    activeSortColumn,
    ascendingOrder,
    sortTableData,
    // toggleSAelectAll,
    // selectAllRestrictedUsersCheckbox,
  } = props;

  // update selected column & order
  function updateColumn(event) {
    const dataId = event.currentTarget.dataset.id;
    let updatedOrder = 1;
    // same column is clicked
    if (Number(dataId) + 1 === activeSortColumn) {
      updatedOrder = ascendingOrder === 1 ? 0 : 1;
    } else {
      updatedOrder = 1;
    }

    sortTableData(updatedOrder, Number(dataId) + 1);
  }

  return (
    <thead className="text-xs uppercase">
      <tr>
        {HEADINGS.map((value, i) => {
          if (value === "user")
            return (
              <th key={i} scope="col" className="px-6 pl-4 py-3 border flex gap-2">
                {/* {activeTab === 1 && (
                  <>
                    <input
                      id="checkbox-all-search"
                      type="checkbox"
                      checked={selectAllRestrictedUsersCheckbox}
                      onChange={toggleSAelectAll}
                      class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                  </>
                )} */}
                {Number(i) + 1 !== 3 && ( // skip phone number
                  <div className="flex items-center">
                    {value}
                    <button onClick={updateColumn} data-id={i}>
                      <SortSVG />
                    </button>
                  </div>
                )}
                {Number(i) + 1 === 3 && value}
              </th>
            );
          else
            return (
              <th key={i} scope="col" className="px-3 py-3 border">
                {Number(i) + 1 !== 3 && ( // skip phone number
                  <div className="flex items-center">
                    {value}
                    <button onClick={updateColumn} data-id={i}>
                      <SortSVG />
                    </button>
                  </div>
                )}
                {Number(i) + 1 === 3 && value}
              </th>
            );
        })}
        <th scope="col" className="px-4 py-3">
          <span>Actions</span>
        </th>
      </tr>
    </thead>
  );
};

export default EmployeeListTableHeading;
