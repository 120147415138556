import { AiOutlineFundProjectionScreen } from "react-icons/ai";
import { MdBusinessCenter, MdOutlineBusinessCenter, MdOutlineRocketLaunch, MdOutlineScheduleSend } from "react-icons/md";
import { HiOutlineUsers } from "react-icons/hi2";
import { TbClock, TbFileInvoice, TbReportMoney } from "react-icons/tb";
import { AiOutlineSafetyCertificate } from "react-icons/ai";
import { GiReceiveMoney } from "react-icons/gi";
import { FaFileInvoice } from "react-icons/fa6";
import { FaUserFriends } from "react-icons/fa";
import { IoMdLogOut } from "react-icons/io";

export const UserAttendanceOptions = [
  {
    txt: "Attendance",
    url: "attendance",
    icon: <TbClock strokeWidth={"0.14rem"} />,
  },
  {
    txt: "Approvals",
    url: "approvals",
    icon: <AiOutlineSafetyCertificate strokeWidth={"1rem"} />,
  },
];

export const adminAttendanceOptions = [
  {
    txt: "Attendance",
    url: "my-attendance",
    icon: <TbClock strokeWidth={"0.14rem"} />,
  },
  {
    txt: "Approvals",
    url: "approvals",
    icon: <AiOutlineSafetyCertificate strokeWidth={"1rem"} />,
  },
  {
    txt: "Jobs",
    url: "jobs",
    icon: <MdOutlineRocketLaunch />,
  },
  {
    txt: "Projects",
    url: "projects",
    icon: <AiOutlineFundProjectionScreen strokeWidth={"1rem"} />,
  }
];

export const payrollOptions = [
  {
    txt: "Payroll",
    url: "payroll-run",
    icon: <MdOutlineScheduleSend size={"1.7rem"} />,
  },
  {
    txt: "History",
    url: "payroll-history",
    icon: <TbReportMoney size={"1.7rem"} />,
  },
];


export const invoiceOptions = [
  {
    txt: "Invoices",
    url: "allInvoices",
    icon: <TbFileInvoice size={"1.7rem"} />,
  },
  {
    txt: "Billed By",
    url: "businesses",
    icon: <MdOutlineBusinessCenter size={"1.7rem"} />,
  },
  {
    txt: "Billed To",
    url: "clients",
    icon: <HiOutlineUsers strokeWidth={"0.13rem"} />,
  },
];

export const salaryComponentOptions = [

  {
    txt: "Salary Components",
    url: "salary",
    icon: <IoMdLogOut size={"1.4rem"} />
  },
  {
    txt: "Statutory Components",
    url: "statutory",
    icon: <IoMdLogOut size={"1.4rem"} />
  }
]