import { Tooltip } from "react-tooltip";
import { FaExclamationTriangle, FaExclamationCircle, FaInfoCircle } from "react-icons/fa";

const iconTypes = {
  warning: <FaExclamationTriangle color="orange" />,
  error: <FaExclamationCircle color="red" />,
  info: <FaInfoCircle color="blue" />,
};

const TWTooltip = ({ text, children, icon }) => {
  const tooltipId = `tooltip-${Math.random().toString(36).substr(2, 9)}`;
  
  return (
    <div data-tooltip-html={text} data-tooltip-id={tooltipId} style={{ display: "inline-block", cursor: "pointer" }}>
      {children || iconTypes[icon] || <FaInfoCircle color="gray" />}
      <Tooltip className="multiline-tooltip" id={tooltipId} place="top" effect="solid" />
    </div>
  );
};

export default TWTooltip;
