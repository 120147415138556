import { createSlice } from "@reduxjs/toolkit";
import { UserDto, newUser } from "../../dtos/UserDto";
import { RegimeType } from "../../enum/RegimeType";
import { userManagementTabs } from "../../components/EmployeeManagement/helper";


interface UserManagementState {
    id: string,

    allUsers: {
        users: UserDto[],
        records: number,
        totalRecords: number
    },
    refetchUsers: boolean,
    selectedEmployeeListTab: number,

    toggleImportModal: boolean,
    isDownloadingCsvSample: boolean,
    csvFileName: string,
    csvImportSummary: any,

    isRestricted: boolean,

    isAddingRestrictedUser: boolean,
    isEditingUser: boolean,
    toggleUserModal: boolean,
    selectedUserModalItem: string,

    user: UserDto,
    salaryStructure: any,
    employmentTypeWithOptions: any,

    latestEmployeeNumber: string,
    isValidEmployeeNumber: boolean,
    renderEmployeeNumber: boolean,

    disableSubmitButton: boolean,

    toggleDeleteUserModal: boolean,
    toggleDeactivateUserModal: boolean,
    toggleReactivateUserModal: boolean,

}

const initialState: UserManagementState = {
    id: "userManagement",

    allUsers: {
        users: [],
        records: 0,
        totalRecords: 0,
    },
    refetchUsers: false,
    selectedEmployeeListTab: userManagementTabs.active,

    toggleImportModal: false,
    isDownloadingCsvSample: false,
    csvFileName: null,
    csvImportSummary: null,
    isRestricted: false,

    isAddingRestrictedUser: false,
    isEditingUser: false,
    toggleUserModal: false,
    selectedUserModalItem: "generalInformation",

    user: newUser,
    
    salaryStructure: {
        tenantEarnings: [],
        tenantPreTaxDeductions: [],

        annualCTC: 0,
        regimeType: RegimeType.New.toString(),

        salaryStructureResponse: {
            isAvailable: false,
            annualCTC: 0,
            monthlyCTC: 0,
            monthlyGross: 0,
            annualGross: 0,
            monthlyTDS: 0,
            annualTDS: 0,
            earnings: [],
            statutoryComponents: []
        },

        // User entered values with relevant data
        earningAssociationValues: [],
        deductionAssociationValues: [],
    },

    employmentTypeWithOptions: null,

    latestEmployeeNumber: "",
    isValidEmployeeNumber: true,
    renderEmployeeNumber: false,

    disableSubmitButton: true,
    
    toggleDeleteUserModal: false,
    toggleDeactivateUserModal: false,
    toggleReactivateUserModal: false,
};


const userManagementSlice = createSlice({
    name: 'userManagement',
    initialState,
    reducers: {
        setAllUsers: (state, action) => {
            state.allUsers = action.payload
        },
        setRefetchUsers: (state, action) => {
            state.refetchUsers = action.payload
        },
        setSelectedEmployeeListTab: (state, action) => {
            state.selectedEmployeeListTab = action.payload
        },
        
        setToggleImportModal: (state, action) => {
            state.toggleImportModal = action.payload
        },
        setIsDownloadingCsvSample: (state, action) => {
            state.isDownloadingCsvSample = action.payload
        },
        setCsvFileName: (state, action) => {
            state.csvFileName = action.payload
        },
        setCsvImportSummary: (state, action) => {
            state.csvImportSummary = action.payload
        },

        setIsRestricted: (state, action) => {
            state.isRestricted = action.payload
        },
        
        setIsAddingRestrictedUser: (state, action) => {
            state.isAddingRestrictedUser = action.payload
        },
        setIsEditingUser: (state, action) => {
            state.isEditingUser = action.payload
        },
        setToggleUserModal: (state, action) => {
            state.toggleUserModal = action.payload
        },
        setSelectedUserModalItem: (state, action) => {
            state.selectedUserModalItem = action.payload
        },
        setUser: (state, action) => {
            state.user = action.payload
        },
        setEmploymentTypeWithOptions: (state, action) => {
            state.employmentTypeWithOptions = action.payload
        },
        setLatestEmployeeNumber: (state, action) => {
            state.latestEmployeeNumber = action.payload
        },
        setIsValidEmployeeNumber: (state, action) => {
            state.isValidEmployeeNumber = action.payload
        },
        setRenderEmployeeNumber: (state, action) => {
            state.renderEmployeeNumber = action.payload
        },

        setDisableSubmitButton: (state, action) => {
            state.disableSubmitButton = action.payload
        },

        setToggleDeleteUserModal: (state, action) => {
            state.toggleDeleteUserModal = action.payload
        },
        setToggleDeactivateUserModal: (state, action) => {
            state.toggleDeactivateUserModal = action.payload
        },
        setToggleReactivateUserModal: (state, action) => {
            state.toggleReactivateUserModal = action.payload
        },
        resetUser: (state) => {
            state.user = newUser
        },
        setUserManagement: (state, action) => {
            return action.payload;
        },
        resetUserManagement: () => initialState,
        setForceEsiCalc: (state, action) => {
          state.user.forceESICalc = action.payload
        }
    }
});

export const {
    setAllUsers,
    setRefetchUsers,
    setSelectedEmployeeListTab,

    setToggleImportModal,
    setIsDownloadingCsvSample,
    setCsvFileName,
    setCsvImportSummary,

    setIsRestricted,
    
    setIsAddingRestrictedUser,
    setIsEditingUser,
    setToggleUserModal,
    setSelectedUserModalItem,
    setUser,
    setEmploymentTypeWithOptions,
    setLatestEmployeeNumber,
    setIsValidEmployeeNumber,
    setRenderEmployeeNumber,
    setDisableSubmitButton,
    setForceEsiCalc,
    
    setToggleDeleteUserModal,
    setToggleDeactivateUserModal,
    setToggleReactivateUserModal,

    resetUser,
    setUserManagement,
    resetUserManagement
} = userManagementSlice.actions;

export{ initialState as initialUserManagementState }

export default userManagementSlice.reducer;
