import React from "react";
import { formatDate } from "../../utils/helper";
import { Role } from "../../enum/Role";
import EditIconSvg from "../../media/EditIconSvg";
import TrashBinIconSvg from "../../media/TrashBinIconSvg";
import {isEqual, isBefore} from "date-fns";

function setColor(holidayDate) {
  const currentDate = new Date();
  const currentDateWithoutTime = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate()
  );

  const holidayDateObj = new Date(holidayDate);
  const holidayDateWithoutTime = new Date(
    holidayDateObj.getFullYear(),
    holidayDateObj.getMonth(),
    holidayDateObj.getDate()
  );

  if (isBefore(holidayDateWithoutTime, currentDateWithoutTime)) {
    // Date is in the past
    return {
      bg: "bg-red-400",
    };
  } else if (isEqual(holidayDateWithoutTime, currentDateWithoutTime)) {
    // Date is the current date
    return {
      bg: "bg-green-400",
    };
  } else {
    // Date is in the future
    return {
      bg: "bg-yellow-400",
    };
  }
}

function HolidayListItem({ holiday, user, editHoliday, deleteHoliday }) {
  const { bg } = setColor(holiday.holidayDate);

  return (
    <div className="relative pl-8 sm:pl-32 py-6 group">
      <div
        className={`flex flex-col sm:flex-row items-start mb-1 group-last:before:hidden before:absolute before:left-2 sm:before:left-0 before:h-full before:px-px before:bg-slate-300 sm:before:ml-[7.1rem] before:self-start before:-translate-x-1/2 before:translate-y-3 after:absolute after:left-2 sm:after:left-0 after:w-2 after:h-2 
         after:bg-indigo-500 after:border-4 after:box-content after:border-slate-100 after:rounded-full sm:after:ml-[7.1rem] after:-translate-x-1/2 after:translate-y-1.5`}>
        {" "}
        <div
          className={`sm:absolute left-0 translate-y-0.5 inline-flex items-center justify-center text-xs font-semibold uppercase w-24 h-6 mb-3 sm:mb-0 rounded-full 
          ${bg} text-white`}>
          {formatDate(holiday.holidayDate)}
        </div>
        <div className="text-xl font-bold text-slate-900 ml-1">{holiday.eventTitle}</div>
      </div>
      <div className="ml-6">{holiday.description}</div>

      <div className="text-slate-500 flex  items-center mb-4 ml-1">
        {holiday?.isFlexi ? "Flexi Holiday" : "Holiday"}

        {+user?.roleId === Role.Administrator && (
          <td className="px-4 py-2 whitespace-nowrap flex item-center gap-[.5rem]">
            <div onClick={editHoliday.bind(null, holiday?.id)}>
              <EditIconSvg />
            </div>
            <div onClick={deleteHoliday.bind(null, holiday)}>
              <TrashBinIconSvg />
            </div>
          </td>
        )}
      </div>
    </div>
  );
}

export default HolidayListItem;
