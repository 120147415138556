const ReadingDocsSvg = ({ className }) => {
  return (
    <svg
      className={className}
      viewBox="0 0 1000 1000"
      xmlns="http://www.w3.org/2000/svg">
      <path d="m191 378.71a200.14 200.14 0 0 0 47.26 11.4c5.72.66 11.62 1.05 17.12-.63 6.58-2 11.93-6.79 16.6-11.85 15.56-16.87 25.72-37.9 35.65-58.59 1.47-3.06 3-6.61 1.63-9.73-.94-2.2-3.07-3.61-5.09-4.89l-7.83-5a11.27 11.27 0 0 0 -4.66-2.05c-4.15-.45-7.26 3.54-9.64 7a144 144 0 0 1 -12.24 15.39c-4.2 4.59-8.81 9-14.42 11.68-6.37 3.05-13.61 3.71-20.68 3.78a140.65 140.65 0 0 1 -37.64-4.71" />
      <path d="m190.76 379.52a196.79 196.79 0 0 0 30.15 8.92q7.68 1.59 15.46 2.58c5 .65 10.17 1.2 15.24.5 9.53-1.33 16.7-7.87 22.84-14.81a155.21 155.21 0 0 0 16.86-23.48c5-8.38 9.4-17.09 13.66-25.85q1.59-3.25 3.15-6.53a29.21 29.21 0 0 0 2.61-6.53 8.77 8.77 0 0 0 -.73-6.32 13.52 13.52 0 0 0 -4.56-4.27c-2.19-1.43-4.41-2.81-6.61-4.21-2-1.26-4-2.85-6.39-3.3-4.12-.78-7.41 2.13-9.77 5.15-3.07 3.95-5.8 8.13-9 12a101.92 101.92 0 0 1 -10.17 10.93 34.44 34.44 0 0 1 -12.13 7.39 49.56 49.56 0 0 1 -14.47 2.18 137 137 0 0 1 -31.74-2.9c-2.6-.54-5.18-1.12-7.75-1.77a1.35 1.35 0 0 0 -.72 2.6 136.17 136.17 0 0 0 30.3 4.61c9.47.39 19.62.37 28.42-3.62 8.43-3.81 14.89-11.33 20.62-18.36 3-3.69 5.59-7.65 8.5-11.4a12.91 12.91 0 0 1 4.31-3.91c2.08-1 4.08-.31 6 .82 2.19 1.32 4.32 2.74 6.48 4.11a30.33 30.33 0 0 1 5.51 3.87c3.67 3.68.56 8.86-1.27 12.67-4.1 8.52-8.24 17-12.84 25.3a174.46 174.46 0 0 1 -15.34 23.55c-5.43 6.89-11.67 14.38-19.88 18-4.58 2-9.62 2.27-14.54 2a148.35 148.35 0 0 1 -15.14-1.89 208 208 0 0 1 -29.41-7.13q-3.6-1.17-7.15-2.47c-1-.37-1.44 1.24-.45 1.62z" />
      <path d="m39.75 738.48c-2.4 18.26-4.7 36.54-6.77 54.85s-3.98 36.45-5.76 54.67q-1.34 13.76-2.54 27.51c-.19 2.23-.39 4.46-.58 6.69a21.57 21.57 0 0 0 -.1 5.8 9.58 9.58 0 0 0 5.16 6.76c2.22 1.07 4.51 1 6.88 1l9.8.07 26.32.21 9 .07c2.57 0 5.3.18 7.64-1a5.72 5.72 0 0 0 2.11-7.85 24.8 24.8 0 0 0 -3.25-4.32 45.42 45.42 0 0 0 -4-4.08 45.92 45.92 0 0 0 -9.05-6.35c-1.61-.92-3.42-1.51-4.95-2.51-1.82-1.16-2.12-3-1.69-5a39.87 39.87 0 0 1 1.89-5.48c.77-2 1.53-4.1 2.28-6.15q2.2-6.08 4.25-12.19 4.14-12.36 7.64-24.91a515.26 515.26 0 0 0 11.4-50.64 517.94 517.94 0 0 0 6.28-51.63q.45-6.35.73-12.68c.07-1.51-2.27-1.51-2.33 0-.79 17-2.41 34.05-4.89 50.93s-5.77 33.52-9.9 50-9 32.6-14.7 48.51c-1.4 3.91-3 7.76-4.33 11.68-1.09 3.19-1.41 6.89 1.58 9.15a30.71 30.71 0 0 0 4.84 2.49 44.65 44.65 0 0 1 4.72 2.79 43.75 43.75 0 0 1 8.31 7.36c1.73 2 5.76 6.33 2.24 8.59-1.46.94-3.41.92-5.08.92-1.91 0-3.81 0-5.72-.06l-24.59-.21-18.76-.16c-4.31 0-7.86-3.19-7.61-7.68.51-9 1.57-18 2.41-26.94 1.66-17.85 3.37-35.69 5.25-53.49s3.87-35.77 6-53.64l1.56-13.08c.13-1.06-1.51-1-1.65 0z" />
      <path d="m229.48 773.17q11.36 38.33 22.74 76.65l11.44 38.3c.7 2.33 1.23 5.26 3.5 6.62s5.61 1 8.16 1.09q9.92.36 19.83.21t19.82-.81c2.24-.15 5.08-.07 6.77-1.83s.78-4.48-.1-6.5c-3.51-8.08-11.41-12.95-17.83-18.52a33.65 33.65 0 0 1 -8.35-9.86c-2.75-5.39-3.25-11.87-4-17.78-1.61-12.56-2.46-25.2-3.19-37.84-1.44-25.12-2.36-50.43-7.77-75.09q-1-4.62-2.27-9.18a1 1 0 0 0 -2 .55c6.19 23.86 7.78 48.52 9.14 73.05.67 12.25 1.3 24.51 2.46 36.73.57 6.08 1.28 12.15 2.18 18.19.79 5.27 1.87 10.54 4.9 15 4.79 7.11 12.41 11.47 18.47 17.34 2.92 2.84 5.84 6.33 6.86 10.37.82 3.22-3.75 2.88-5.78 3-3.13.2-6.27.37-9.41.49q-9.66.39-19.33.32-4.83 0-9.67-.18c-1.56 0-3.13-.1-4.7-.17-1.27-.06-2.74-.1-3.65-1.13a8.5 8.5 0 0 1 -1.42-3.29l-1.44-4.79-2.84-9.51-5.56-18.42q-11.22-37.2-22.58-74.36l-2.76-9.08a.82.82 0 0 0 -1.58.43z" />
      <path
        d="m84.55 422.09c-9.45 23.09-18.65 46.91-19.26 71.91-.37 14.75 2.3 29.48 1.5 44.22-.79 15.59-5.54 30.7-9.79 45.78a933.65 933.65 0 0 0 -30.25 154.21c-.4 3.62-.76 7.4.44 10.84 1.49 4.28 5.14 7.39 8.74 10.12 32 24.26 72.53 35.67 112.65 36.43s79.91-8.6 117.51-22.6c9.66-3.6 20.32-8.68 23.32-18.55 1.74-5.72.45-11.88-.85-17.72q-10.43-46.91-20.86-93.82c-4.26-19.17-8.53-38.36-13.49-57.36a805.49 805.49 0 0 0 -60.08-159"
        fill="#18c4ff"
      />
      <path
        d="m211.81 220.32a42.09 42.09 0 0 0 12.59 15.51c1.81 1.37 4 3.54 2.81 5.5a9.79 9.79 0 0 1 -1.32 1.42c-1.86 2.11-.94 5.37 0 8 2 5.65 3.45 12.22.41 17.39s-9.34 7-15.19 7.61c-16.1 1.63-33.74-4.94-41.8-19-3.77-6.57-5.24-14.27-5.22-21.84 0-5.64.9-11.43 3.76-16.3 5.15-8.77 16.16-13 26.29-12 6.94.65 15.33 7.13 17.68 13.69"
        fill="#fff"
      />
      <path d="m170 256.43a44.42 44.42 0 0 1 -4.67-19.27c-.22-6.33.47-13 3.93-18.51a23.71 23.71 0 0 1 12.56-9.42c4.89-1.64 10.75-2.35 15.71-.65a23.57 23.57 0 0 1 13.57 12.18c.4.91 1.87.26 1.59-.67-1.75-5.71-7-10.35-12.24-12.81s-11.39-2.38-17-1a27.22 27.22 0 0 0 -14.57 8.89c-4.32 5.33-5.78 12.17-5.92 18.89a42.67 42.67 0 0 0 5.73 23.09.76.76 0 0 0 1.31-.76z" />
      <path d="m211.15 220.71a39.44 39.44 0 0 0 7.94 12c1.68 1.7 3.57 3.08 5.35 4.64.74.65 1.88 1.64 1.93 2.71s-1.41 1.84-1.91 2.67c-2.35 3.88.74 9.06 1.67 12.93 1.11 4.62 1.27 9.92-2.13 13.66s-8.46 4.76-13.14 5.61c-1 .18-.74 1.74.24 1.72 4.5-.14 9.28-1.36 12.88-4.17 4-3.11 5.55-7.9 5.16-12.83a34.33 34.33 0 0 0 -1.61-7.63c-.7-2.2-2-4.76-1.45-7.11.29-1.28 1.36-1.82 2-2.85a3.52 3.52 0 0 0 .39-3.09c-.72-2.3-3-3.68-4.72-5.13a46.08 46.08 0 0 1 -11.31-13.91c-.47-.89-1.7-.11-1.32.77z" />
      <path d="m213.12 232.13a3.73 3.73 0 0 0 -.64.73 2.53 2.53 0 0 0 -.38 1.12 1.88 1.88 0 0 0 .24 1.09 1.6 1.6 0 0 0 .16.23 1.81 1.81 0 0 0 .18.21 2.08 2.08 0 0 0 .71.43 1.26 1.26 0 0 0 .61 0 1.2 1.2 0 0 0 .5-.29 2.6 2.6 0 0 0 .26-.32.83.83 0 0 0 .07-.13 2.18 2.18 0 0 0 .22-.83.38.38 0 0 0 0-.15 1.93 1.93 0 0 0 -.06-.42 1.54 1.54 0 0 0 -.24-.54 1.15 1.15 0 0 0 -.43-.42l-.28-.12a1.15 1.15 0 0 0 -1 .21 1.11 1.11 0 0 0 -.45.78 1.21 1.21 0 0 0 .33 1l-.19-.24a.6.6 0 0 1 .09.15l-.12-.28a.76.76 0 0 1 .06.2v-.31a.74.74 0 0 1 0 .21v-.31a.76.76 0 0 1 -.06.2l.12-.28a.46.46 0 0 1 -.09.15l.18-.23 1.11-.29h-.08l.27.12a.67.67 0 0 1 -.14-.09l.22.18a.57.57 0 0 1 -.15-.16l.17.23a2 2 0 0 1 -.18-.32l.11.26a2.29 2.29 0 0 1 -.08-.28v.28a2.28 2.28 0 0 1 0-.37v.26a5 5 0 0 1 .26-.94.67.67 0 0 0 0-.5.71.71 0 0 0 -.31-.37.8.8 0 0 0 -.94.12z" />
      <path d="m208.25 231.51h.15-.47c.55.19 1.08-.27 1.55-.51l.58-.27c.22-.1-.22.09 0 0l.29-.1a14 14 0 0 1 2.29-.59l-.43.05c.27 0 .5 0 .79-.06l-.43.05-.42-.05-.37-.16a1.52 1.52 0 0 0 2.22-.9 1.78 1.78 0 0 0 -.81-1.95 2.06 2.06 0 0 0 -2 .06 8.73 8.73 0 0 0 -1.42.82 10 10 0 0 0 -.82.64l-.06.05c.16-.12.16-.12 0 0l-.24.19-.3.26a8.24 8.24 0 0 0 -.62.56c-.09.09-.19.18-.28.28a1 1 0 0 0 -.27.45v.25c0-.14 0-.16 0-.06a.8.8 0 0 0 .21.79.82.82 0 0 0 .78.2z" />
      <path d="m205.81 210.64a19.92 19.92 0 0 1 -3.8 14.94c-1.73 2.26-3.94 4.14-5.47 6.53s-2.27 5.63-.77 8a11.58 11.58 0 0 0 -2.24-3.38 4.26 4.26 0 0 0 -3.7-1.27 4.09 4.09 0 0 0 -1.82 1c-2.29 2-2.59 5.61-1.29 8.34a13.32 13.32 0 0 0 6.62 5.93 62.16 62.16 0 0 1 -4.21 12 20.83 20.83 0 0 1 -8.51 9.2 5.85 5.85 0 0 1 -2.81.8 6.18 6.18 0 0 1 -2.25-.61c-18.12-7.72-32.23-27-21.32-46.43 5.72-10.18 16.42-17.79 27.76-20.4a34 34 0 0 1 10.49-.82 52.08 52.08 0 0 1 14.7 3.86 29 29 0 0 1 9.32 5.29 1.7 1.7 0 0 1 .52.73c.17.72-.62 1.29-1.32 1.52a7.47 7.47 0 0 1 -6.89-1.27c-.81-.65-1.87-1.54-2.75-1" />
      <path d="m205 210.85c.83 6-1.47 11.49-5.48 15.89-3.39 3.71-7.84 8.7-4.78 14 .74 1.28 2.64.15 2-1.16-1.25-2.44-3-5.08-6-5.25-2.75-.17-4.9 2.24-5.61 4.7-1.65 5.72 2.88 10.41 7.63 12.69l-.53-1.3c-1.6 5.74-3.25 11.82-7.25 16.42-1.6 1.85-4.17 4.38-6.75 4.65-1.28.13-2.62-.68-3.74-1.21s-2.43-1.22-3.6-1.9c-8.28-4.83-15.65-12.43-18.24-21.86-6-21.61 17.57-41.11 37.68-40.87 6.88.09 14 2.48 20.17 5.51a23.29 23.29 0 0 1 4.11 2.51 7.65 7.65 0 0 0 1 .84c.21.25.26.27.13.07s-.17-.19-.35 0c-.77 0-1.49.35-2.31.3a7 7 0 0 1 -3.87-1.64c-1.14-.85-2.36-1.35-3.71-.66-1.16.59-.34 2.64.9 2.13.64-.26 1.32.59 1.8 1a8.37 8.37 0 0 0 1.64 1 8.63 8.63 0 0 0 4.39.77 5.24 5.24 0 0 0 3.51-1.38 2.36 2.36 0 0 0 -.3-3.39c-2.24-2.29-5.38-3.75-8.28-5a70.32 70.32 0 0 0 -9.94-3.43 37.45 37.45 0 0 0 -20.49.52 46.55 46.55 0 0 0 -15.46 8.33c-8.88 7.21-15.4 18.28-13.88 30 1.43 11 9 20.13 18.06 26a45.34 45.34 0 0 0 8.17 4.27c3.41 1.31 6.59-.69 9.12-2.92 5.66-5 7.81-12.48 9.74-19.48a1.17 1.17 0 0 0 -.53-1.3c-3.44-1.64-7-4.48-6.83-8.68.08-1.75 1.06-4.12 3-4.45 2.39-.4 3.8 2.4 4.65 4.14l2-1.16c-1.39-2.48-.27-5.45 1.25-7.59 1.64-2.31 3.84-4.16 5.41-6.52a21.51 21.51 0 0 0 3.15-15c-.15-1-1.67-.57-1.53.42z" />
      <path d="m190.7 242.2h.07c-.11.05-.14.06-.06 0h.15-.2.24-.2a1.05 1.05 0 0 1 .24.06c.14 0-.16-.09 0 0l.1.06c.06 0 0 0-.05 0a.27.27 0 0 0 .08.07l.17.19c.07.07-.1-.15 0 0l.07.11.14.23c.09.16.17.32.25.49l-.08-.19a3.09 3.09 0 0 1 .2.72v-.21a2.18 2.18 0 0 1 0 .51v-.22s0 .06 0 .09l1-.57.2.08-.11-.06.17.13-.11-.1.13.16a.54.54 0 0 1 -.07-.13l.08.2a.53.53 0 0 1 0-.17v.22a.38.38 0 0 1 0-.15v.22a.7.7 0 0 1 0-.14l-.08.2a.53.53 0 0 1 .06-.1l-.14.17a.86.86 0 0 0 -.72-1.46.91.91 0 0 0 -.63.41 1.41 1.41 0 0 0 -.16 1.2 1.44 1.44 0 0 0 .94.95.83.83 0 0 0 1-.57 3 3 0 0 0 -.14-1.53 5 5 0 0 0 -.62-1.17 2.63 2.63 0 0 0 -1.2-1 1.76 1.76 0 0 0 -1.47.13.75.75 0 0 0 -.27 1 .77.77 0 0 0 1 .27z" />
      <path d="m174.86 261.37c2.72 0 2.72-4.21 0-4.21s-2.71 4.21 0 4.21z" />
      <path d="m196 238.7 11.49-4.45 5.53-2.14c1-.38 2-.79 3-1.15a2.93 2.93 0 0 1 1.17-.32c.5.12.61.66.75 1.07 1 2.91 1.47 7.38-2.35 8.41-4 1.06-5.43-3.16-6-6.15-.33-1.6-2.77-.93-2.45.67.65 3.22 1.82 6.57 5.24 7.71a6.58 6.58 0 0 0 8-3.65 8.64 8.64 0 0 0 .44-4.85c-.22-1.46-.61-3.45-1.64-4.58-1.26-1.38-2.95-.78-4.43-.16l-5.88 2.49-13.44 5.67c-.81.34-.23 1.75.6 1.43z" />
      <path d="m221.37 230a2.49 2.49 0 0 0 -.28-.15 1.76 1.76 0 0 0 -.3-.1 1.83 1.83 0 0 0 -.66 0 2 2 0 0 0 -.86.25 2.31 2.31 0 0 0 -.66.66.63.63 0 0 0 -.12.37.72.72 0 0 0 0 .41 1 1 0 0 0 .46.61 1.08 1.08 0 0 0 .79.1 1 1 0 0 0 .6-.47l.09-.13-.16.2a1.19 1.19 0 0 1 .2-.19l-.2.15.31-.24-.2.15.27-.16-.22.1a1.58 1.58 0 0 1 .31-.09h-.23a1.41 1.41 0 0 1 .29 0h-.22.16a.8.8 0 0 0 .94-.46.78.78 0 0 0 0-.52.9.9 0 0 0 -.37-.47z" />
      <path d="m153.48 227.28c3.57-8.73 6.28-19 1.74-27.28a21.73 21.73 0 0 0 -12-9.6 38.5 38.5 0 0 0 -15.51-1.66c-8.48.61-17.21 3.17-23.2 9.21-3.35 3.39-5.65 7.68-8.78 11.28a32 32 0 0 1 -10.6 8c-3.64 1.69-7.57 2.68-11.19 4.4s-7.08 4.36-8.47 8.12c-2.21 6 1.56 12.7 6.52 16.75 8.36 6.83 20 8.77 30.65 6.67s20.19-7.86 28.35-14.94a70.65 70.65 0 0 1 7.79-6.36 26 26 0 0 1 12.14-4.06" />
      <path d="m154.18 227.69c3.28-7.37 6-15.78 4-23.89-2-7.78-8.57-13.11-16.07-15.3-8.06-2.34-17.43-1.75-25.41.7a32.91 32.91 0 0 0 -10.56 5.31c-3.33 2.56-5.76 5.88-8.18 9.27a37.16 37.16 0 0 1 -8.75 9.22c-3.64 2.56-7.71 3.94-11.84 5.46-3.8 1.39-7.62 3.08-10.46 6.05a12.77 12.77 0 0 0 -3.64 10.34c.92 7.89 7.54 13.89 14.37 17.1a40.84 40.84 0 0 0 23.11 3.17 56.06 56.06 0 0 0 22.85-9.25 93.68 93.68 0 0 0 10.07-7.87c3.32-3 6.69-5.76 10.94-7.26a26.36 26.36 0 0 1 6.32-1.45c1.85-.18 1.91-3 0-2.93a27 27 0 0 0 -11.45 3.28c-3.71 2-6.73 5-9.92 7.75a66.1 66.1 0 0 1 -19.88 12.11 42.15 42.15 0 0 1 -22.17 2.22c-6.93-1.3-14.23-4.66-18.32-10.64-1.93-2.81-3.3-6.35-2.44-9.79s3.67-5.89 6.67-7.55c3.69-2 7.84-3.06 11.69-4.74a33.4 33.4 0 0 0 9.73-6.51c2.91-2.79 5.09-6.16 7.45-9.39a28.33 28.33 0 0 1 8.14-7.78c6.55-4 14.58-5.33 22.15-5.2s15.93 2.58 20.53 9.15 3.5 14.94 1.44 22.22c-.53 1.89-1.16 3.76-1.85 5.61-.31.85 1.11 1.45 1.48.62z" />
      <path
        d="m156 218.86a33.44 33.44 0 0 0 -3.94 4.46 17.58 17.58 0 0 0 -1.54 2.38c-.23.43-.44.87-.64 1.31a4 4 0 0 0 -.53 1.85 1.32 1.32 0 0 0 2.22.92 3.81 3.81 0 0 0 .87-1.51c.09-.22.32-.73.48-1.07.41-.86.78-1.73 1.22-2.58a27.92 27.92 0 0 1 3-4.63.8.8 0 0 0 -1.13-1.13z"
        fill="#18c4ff"
      />
      <path d="m210.79 274.68a94.66 94.66 0 0 0 -6.53 9.83 66.29 66.29 0 0 0 -5.21 10.49 1.22 1.22 0 0 0 2.22.94c1.73-3.47 3.17-7.06 5-10.49s3.79-6.75 5.89-10c.57-.88-.77-1.6-1.37-.8z" />
      <path d="m166.75 286.28q3.62-3.78 7.17-7.63c1.17-1.27 2.34-2.53 3.46-3.83 1-1.12 2.5-2.53 2.52-4.11a1.1 1.1 0 0 0 -1.85-.76 12.16 12.16 0 0 0 -1.29 1.79c-.47.62-1 1.2-1.5 1.79-1.09 1.28-2.13 2.59-3.19 3.89q-3.19 3.9-6.45 7.74c-.65.77.41 1.87 1.13 1.12z" />
      <path d="m204.5 292.74a107.14 107.14 0 0 0 -31.1-7.74c-11.65-1.11-22.2 1.06-31 9.26-14.17 13.25-23.49 33.88-28.38 52.31-1.89 7.14-3.22 14.55-6.71 21.06-3.85 7.21-10.08 12.81-16.18 18.25l-34.42 30.68a5.81 5.81 0 0 0 -1.71 2.17c-.49 1.35.08 2.85.69 4.15 7.4 15.7 21.65 27.68 37.89 33.81s34.27 6.67 51.26 3.14a8.26 8.26 0 0 0 3.3-1.21 8.53 8.53 0 0 0 2.52-4.19q3.25-9 6.19-18.14a120.9 120.9 0 0 0 2.73 18.71c.3 1.3.72 2.74 1.89 3.39a5 5 0 0 0 3.19.21q23.91-3.86 47.61-8.83c-2-15.15-13.81-28.13-13.7-43.41.06-7.71 3.19-15 6.05-22.18a354 354 0 0 0 13.44-40.8c2.09-7.93 3.91-16.13 2.86-24.26a35.38 35.38 0 0 0 -13.49-23.29" />
      <path d="m204.91 292a111.62 111.62 0 0 0 -18.36-6 88.14 88.14 0 0 0 -18.36-2.52 40.21 40.21 0 0 0 -15.43 2.62 39.08 39.08 0 0 0 -13.2 9c-7.86 7.91-13.83 17.63-18.48 27.7a143.8 143.8 0 0 0 -6.06 15.5c-1.81 5.55-3.1 11.21-4.6 16.84-1.59 6-3.74 11.64-7.47 16.63-3.48 4.65-7.85 8.57-12.17 12.43q-15.5 13.83-31 27.63l-3.78 3.37a7.2 7.2 0 0 0 -2.61 3.55c-.65 2.65 1.21 5.54 2.42 7.77a63.9 63.9 0 0 0 9.19 12.83 73.37 73.37 0 0 0 26.3 18.14 87.09 87.09 0 0 0 36.38 6c3.17-.12 6.34-.4 9.49-.82a69.11 69.11 0 0 0 9.16-1.61c2.92-.8 4.44-2.79 5.49-5.51 1.18-3.07 2.22-6.2 3.28-9.31s2.14-6.36 3.17-9.56l-3.06-.41a125.8 125.8 0 0 0 2 15.34c.4 2.08.64 4.54 1.73 6.41s3.12 2.46 5.24 2.18c5.16-.65 10.31-1.68 15.44-2.58s10.37-1.87 15.55-2.86q8.73-1.68 17.44-3.51a1.56 1.56 0 0 0 1.07-1.88c-1.34-9.9-6.6-18.56-10.23-27.7a42.15 42.15 0 0 1 -3.41-14.13c-.19-5.69 1.45-11.19 3.42-16.46 2-5.46 4.3-10.83 6.3-16.31s3.89-11.12 5.63-16.75 3.36-11.32 4.78-17.05a68.47 68.47 0 0 0 2.49-17.54 36.69 36.69 0 0 0 -14.66-28.62c-1.23-.94-2.43 1.15-1.21 2.08a34.42 34.42 0 0 1 12.87 25.11c.31 6-.8 11.92-2.22 17.71s-3 11.26-4.7 16.83c-3.4 11.09-7.58 21.83-11.73 32.65-2 5.22-3.73 10.63-3.94 16.25a38.53 38.53 0 0 0 2.4 14.1c3.57 10.3 9.84 19.69 11.33 30.65l1.07-1.88q-14.16 3-28.38 5.53-7.05 1.26-14.12 2.44l-3.42.56a9.62 9.62 0 0 1 -3.32.35c-1.65-.37-1.81-3.67-2.08-5a120.49 120.49 0 0 1 -2.19-15.94 1.56 1.56 0 0 0 -3.05-.41c-2 6.19-4.05 12.39-6.33 18.49a5.85 5.85 0 0 1 -2 3.12 11 11 0 0 1 -3.93 1.1q-4.47.87-9 1.34a90 90 0 0 1 -35.83-3.27 71.58 71.58 0 0 1 -27-15.37 63.53 63.53 0 0 1 -10-11.52 59.78 59.78 0 0 1 -3.74-6.43c-.54-1.09-1.47-2.54-1.25-3.81s2.2-2.57 3.15-3.42l29.43-26.29c8.61-7.7 17.57-15.23 21.87-26.28 2.14-5.5 3.26-11.33 4.77-17a144.65 144.65 0 0 1 4.86-14.94 109.2 109.2 0 0 1 15.69-28.33 55.09 55.09 0 0 1 11.18-11.23 33.82 33.82 0 0 1 13.82-5.57 56.77 56.77 0 0 1 16.31 0 105.64 105.64 0 0 1 18.17 3.92c3 .91 5.89 2 8.77 3.13.85.35 1.48-1.13.63-1.49z" />
      <path d="m374.73 280.56 5.34-4c1.52-1.13 3.09-2.92 5.11-2.45a15.25 15.25 0 0 1 10.6 10 5 5 0 0 1 .22 3.18 25 25 0 0 1 -1.35 2.93c-1.92 4-4.12 8.07-5.66 12.27-.42 1.13 1.33 1.81 1.91.81 2.08-3.57 3.65-7.51 5.4-11.25.75-1.61 1.86-3.35 2.1-5.13a9.39 9.39 0 0 0 -1-4.76 17.39 17.39 0 0 0 -5.48-7.05 16.16 16.16 0 0 0 -3.79-2.2 8.55 8.55 0 0 0 -4.56-.91c-1.72.34-3.15 1.91-4.47 3l-5.35 4.33c-.69.56.24 1.79 1 1.25z" />
      <path d="m393.26 248.82c-2-1-3.59 1.39-4.68 2.73a25.1 25.1 0 0 0 -3.81 6 17.92 17.92 0 0 0 -1.32 7.31 28 28 0 0 0 .4 3.77 21.61 21.61 0 0 0 .9 4.24c.4 1.1 2.21 1 2.16-.29-.08-2.14-.7-4.28-.86-6.43a18.22 18.22 0 0 1 .42-6.2 19.52 19.52 0 0 1 2.32-5.09c.47-.76 1-1.5 1.51-2.23.34-.46 1.57-2.61 2.34-2.33a.81.81 0 0 0 .62-1.48z" />
      <path d="m401.93 243.08a26.2 26.2 0 0 0 -6.23 4.42 25 25 0 0 0 -2.56 2.88 11.82 11.82 0 0 0 -2 3.37c-.29.92 1 1.77 1.68 1 .78-.95 1.33-2.06 2-3.06a26.1 26.1 0 0 1 2.27-2.75 24.4 24.4 0 0 1 5.62-4.45.8.8 0 0 0 -.81-1.39z" />
      <path d="m403.44 254.45.35-.09h.16l.51-.07h.17.68.2c.09 0 .09 0 0 0l.36.07a4.84 4.84 0 0 1 .65.19l.15.05.33.16a4.28 4.28 0 0 1 .57.37l.27.18.09.07.09.08a.93.93 0 0 0 1.32-1.31 5.18 5.18 0 0 0 -3-1.56 6.31 6.31 0 0 0 -1.68-.06 4.52 4.52 0 0 0 -1.78.59.75.75 0 0 0 .58 1.37z" />
      <path d="m409 260.52c-.06.08-.07.09 0 0a.23.23 0 0 1 .11 0c.09 0 .08 0 0 0h.21c.11 0 .39.09.51.12l.56.2.29.12.2.08.66.29 1.29.65a1 1 0 0 0 1.28-.34.94.94 0 0 0 -.34-1.28 17.16 17.16 0 0 0 -3.24-1.38 2.25 2.25 0 0 0 -2.77.7.81.81 0 0 0 .29 1.09.82.82 0 0 0 1.08-.29z" />
      <path d="m413.4 266.36c1.46.84 1 3 .36 4.22a6.09 6.09 0 0 1 -1.72 2c-.21.15-.78.62-1 .46s-.11-1.17-.08-1.48a1.16 1.16 0 0 0 -2.15-.58 26.63 26.63 0 0 1 -5.1 7.25 26.1 26.1 0 0 1 -3.31 2.82c-1.22.87-2.7 1.51-3.82 2.49-1 .85.07 2.34 1.2 2.07a12.28 12.28 0 0 0 4.07-2.37 29.39 29.39 0 0 0 3.64-3.23 28.78 28.78 0 0 0 5.31-7.87l-2.14-.58c-.13 1.59.38 3.81 2.4 3.79 1.63 0 3.17-1.61 4-2.86 1.52-2.26 2-6.3-1.07-7.49-.82-.31-1.32 1-.59 1.4z" />
      <path d="m193.76 289.38 82.84 28.62c9.7 3.35 20 6.74 30 4.35 4.79-1.14 9.17-3.56 13.4-6.08a260 260 0 0 0 42.5-31.48c2.69-2.46 5.37-5 8.61-6.66s7.17-2.36 10.52-.95a14.86 14.86 0 0 1 4.58 3.37c4.49 4.54 7.82 10.89 6.64 17.17-.73 3.92-3.11 7.3-5.43 10.55-11 15.44-22.16 31-36.19 43.8s-31.33 22.74-50.23 24.71a119.82 119.82 0 0 1 -16.3.29c-17.21-.58-67.85-6.28-84.51-10.59s.6-7.36-12.4-18.64" />
      <path d="m193.55 290.11q28.53 10.11 57.11 20.06c9.43 3.29 18.84 6.64 28.3 9.84 8.78 3 18.24 5.62 27.53 3.57a44.24 44.24 0 0 0 12.19-5.16c4-2.3 7.95-4.75 11.82-7.29a262.7 262.7 0 0 0 22.16-16.23q5.4-4.41 10.56-9.1c3.27-3 6.63-6.3 11-7.6a10.67 10.67 0 0 1 6.35-.12 12.86 12.86 0 0 1 5.09 3.66 21.78 21.78 0 0 1 6 11c.79 4.45-.92 8.43-3.32 12.09s-5.16 7.3-7.77 10.91c-5.42 7.47-11 14.85-17 21.83-11.33 13.06-24.59 24.74-40.5 31.86a72.67 72.67 0 0 1 -24.91 6.32 153.41 153.41 0 0 1 -20.16-.35c-12.15-.81-24.27-2.12-36.35-3.59-11.84-1.45-23.75-2.94-35.44-5.35-2.11-.46-4.21-.89-6.21-1.46a23.15 23.15 0 0 1 -4.28-1.51 4.71 4.71 0 0 1 -1.72-1.29c-.14-.23-.14-.09-.18-.53 0 .3 0 0 0-.13a4.71 4.71 0 0 1 0-.53c0 .29 0-.07 0-.17l.09-.44c.08-.36.17-.71.25-1.07a10.37 10.37 0 0 0 -.1-5.5 17 17 0 0 0 -5.23-7.06c-1.44-1.28-3.57.84-2.12 2.12 2.05 1.8 4.3 4 4.82 6.76a10.74 10.74 0 0 1 -.42 4.25 4.85 4.85 0 0 0 .93 4.42c1.7 1.88 4.64 2.63 7 3.26 4.6 1.23 9.33 2 14 2.8 6.21 1 12.44 1.93 18.67 2.75 13 1.73 26.13 3.2 39.24 4.32 14.11 1.21 28.56 2.19 42.35-1.73 16.75-4.75 31.41-15 43.6-27.22a211 211 0 0 0 18.17-21.11c2.85-3.72 5.61-7.49 8.35-11.28s5.53-7.36 7.9-11.27a18.07 18.07 0 0 0 2.89-12.09 22.88 22.88 0 0 0 -5.33-11.1c-2.66-3.22-6-6.06-10.28-6.41s-8.51 1.69-11.89 4.29c-3.64 2.8-6.89 6.11-10.36 9.11s-7.27 6.11-11 9a259.74 259.74 0 0 1 -23.35 16.1 70.39 70.39 0 0 1 -12.45 6.54 31.29 31.29 0 0 1 -14.11 1.5 70.38 70.38 0 0 1 -14.29-3.44c-4.81-1.57-9.57-3.28-14.35-4.91-19.56-6.68-39.09-13.43-58.67-20l-14.13-4.79c-.94-.31-1.32 1.15-.41 1.47z" />
      <path d="m31.14 883.07a90.17 90.17 0 0 0 23.44-.36q2.82-.42 5.61-1c1.53-.33 3-.64 4-1.94a37.34 37.34 0 0 1 2.48-3.62 10.7 10.7 0 0 1 1.73-1.5 9.83 9.83 0 0 1 1.12-.68c.36-.2 1-.22.68-.58l-.37-.88v.1l.91-1.19h-.13c-1.28.38-1.1 2.53.32 2.41h.14a1.26 1.26 0 0 0 1.19-.91c.22-1.55-1.21-2.28-2.54-1.83a11.5 11.5 0 0 0 -4.27 2.77 19.1 19.1 0 0 0 -2.8 4.2c-1 1.74-3.32 1.8-5.13 2.16a89.45 89.45 0 0 1 -26.43 1.24c-1-.1-1 1.5 0 1.61z" />
      <path d="m273.9 883.68a87 87 0 0 0 10.08-.24 49.51 49.51 0 0 0 9.47-1.38c2.52-.74 3.62-3.11 4.91-5.2s2.84-4.45 5.29-5.61a1.09 1.09 0 0 0 .22-1.7 1.49 1.49 0 0 0 -2.08 0c-.93.74.28 2.29 1.27 1.65h-.12l-.75-.31.15.15.22-1.69a12.36 12.36 0 0 0 -4.95 4.7c-.68 1-1.28 2.08-1.92 3.13s-1.37 2.44-2.78 2.89a46.58 46.58 0 0 1 -9.17 1.52 85.16 85.16 0 0 1 -9.84.44.81.81 0 0 0 0 1.61z" />
      <path
        d="m111.82 615.76a159.31 159.31 0 0 0 43.09-25.44c3.72-3.08 7.27-6.35 10.68-9.77a.85.85 0 0 0 -1.2-1.2 159.54 159.54 0 0 1 -40 29q-6.36 3.23-13 5.86c-1 .39-.54 2 .44 1.58z"
        fill="#fff"
      />
      <path
        d="m122.13 633.5a213 213 0 0 0 41.27-26.75c.86-.72-.38-1.94-1.23-1.24a219.1 219.1 0 0 1 -40.81 26.68c-.88.44-.11 1.74.77 1.31z"
        fill="#fff"
      />
      <path d="m289.73 299.14 3.46-3.82c.92-1 2-2.63 3.36-3.08s3.41.59 4.72 1.21a23.47 23.47 0 0 1 3.71 2.19 22.85 22.85 0 0 1 3.32 2.9c.88.92 2.55 2.36 2.19 3.76a31.16 31.16 0 0 1 -1.91 4.69c-.72 1.63-1.52 3.24-2.27 4.86-.55 1.21 1.18 2.27 1.81 1.06 1-1.9 1.86-3.88 2.71-5.85.71-1.67 2-3.82 1.82-5.68-.17-1.47-1.44-2.74-2.38-3.8a25.49 25.49 0 0 0 -3.94-3.54 24.06 24.06 0 0 0 -4.66-2.7 9.27 9.27 0 0 0 -4.92-1.16c-1.76.26-2.91 1.6-4 2.89-1.39 1.64-2.77 3.29-4.16 4.94-.66.78.43 1.91 1.14 1.13z" />
      <path d="m296.33 289a31.53 31.53 0 0 0 -.8-4.71 7.89 7.89 0 0 1 .59-4.62 10 10 0 0 1 2.42-3.93 10.28 10.28 0 0 1 4-1.7 37.83 37.83 0 0 1 4.61-1c1-.14 2.91-.53 3.21.8s-.91 2.29-1.81 2.94c-1.38 1-2.82 1.93-4.23 2.89a1.08 1.08 0 0 0 .82 2c.9-.13 1.22 1.43 1.36 2s.3 1.21-.19 1.59c-.81.63.09 1.81.93 1.6 1.83-.44 1.59-2.63 1.24-4-.47-1.94-1.76-3.54-3.91-3.25l.83 2c1.34-.91 2.7-1.79 4-2.72a7.2 7.2 0 0 0 2.76-3 3.84 3.84 0 0 0 -.19-3.52 3.78 3.78 0 0 0 -3.29-1.52 20.59 20.59 0 0 0 -4.27.64 30.91 30.91 0 0 0 -4.45 1.25 8 8 0 0 0 -3.54 2.38 14.27 14.27 0 0 0 -2 3.7 9.82 9.82 0 0 0 -.79 5c.24 1.78 1 3.47 1.06 5.27 0 1 1.57 1 1.54 0z" />
      <path d="m317.35 291a8.9 8.9 0 0 1 -1.67 5.17 8 8 0 0 1 -1.83 1.83 8.08 8.08 0 0 1 -1.19.73 7.11 7.11 0 0 0 -1.42.74 1 1 0 0 0 .74 1.77 5.92 5.92 0 0 0 3.08-1.68 11.2 11.2 0 0 0 2.11-2.44 10.29 10.29 0 0 0 1.65-6.12.74.74 0 0 0 -1.47 0z" />
      <path
        d="m211.66 375.18c2.84-3.28 7-4.49 11.24-4.63 5-.17 10.06.76 15 1.44 9.7 1.34 19.35 3.12 29 4.94 1 .19 1.45-1.28.43-1.55a230.91 230.91 0 0 0 -23.76-4.83c-4-.61-8-1.15-12.08-1.58s-7.91-.79-11.81 0a16 16 0 0 0 -9.12 5.09c-.65.77.45 1.9 1.13 1.12z"
        fill="#18c4ff"
      />
      <path d="m402.58 242.7c-.89-19.62 5.52-38.69 14.24-56a242.17 242.17 0 0 1 14.28-24.7c5-7.48 10.67-14.71 17.94-20.08 8-5.92 18.34-8.26 25.53-15.33a1.21 1.21 0 0 0 -.24-1.87c-15.72-11.69-34.75-20-54.5-20.81a71.35 71.35 0 0 0 -14.45.83 82.07 82.07 0 0 0 -14.67 4.08c-5 1.8-10.22 3.67-15.64 3.57s-10.33-2-15.44-3.19c-4.69-1-9.55-1.37-14.13.34a26.65 26.65 0 0 0 -10.17 7.2c-6.34 7-10.33 15.91-14.14 24.39a253.35 253.35 0 0 0 -21.31 124.53q.33 4 .8 8c.18 1.58 2.68 1.6 2.49 0a250.77 250.77 0 0 1 16.66-122.96q3-7.38 6.45-14.55a105.5 105.5 0 0 1 6.56-12.05c4.69-7.14 11.41-13.58 20.51-13.26 5.19.18 10.13 2.11 15.15 3.21a31.71 31.71 0 0 0 15-.24c5.11-1.31 9.94-3.48 15-4.94a64.86 64.86 0 0 1 14-2.39c19.37-1.18 38.59 5.7 54.66 16.17 2 1.32 4 2.7 5.92 4.13l-.24-1.87c-6.55 6.44-15.9 8.71-23.38 13.8s-13.43 12-18.55 19.38a221.13 221.13 0 0 0 -14.62 24.68 156.34 156.34 0 0 0 -11.29 27.48 95.84 95.84 0 0 0 -4 32.45c.06 1 1.63 1 1.59 0z" />
      <path d="m309 378.8c6.17 23.9 14.17 48.07 29.54 67.78 13.9 17.84 33.39 30.2 54.56 37.71 50.31 17.84 104 8 155.66 3.85a378.3 378.3 0 0 1 43.76-1.39c12.54.44 25.86 2.33 35 11.79 15.58 16.18 13.25 41.79 1.25 59.15-15 21.74-40.6 32.17-64.6 40.65-12.75 4.51-25.51 8.9-37.89 14.38a300.89 300.89 0 0 0 -35.42 18.62c-40.55 24.9-83.48 62.16-90.37 112-3.1 22.46 1.48 46.24 16 64.08 14.39 17.63 36.22 27.17 57.82 32.54 26.33 6.54 54.59 9 81.51 5.3 24.41-3.4 47.51-12.84 68.54-25.51 42.43-25.57 75.83-62.61 112.55-95.26s80.09-64.05 130.77-67.49c25.11-1.72 50.43 5.25 65.62 26.52 7 9.82 11.61 21.09 15.45 32.45 4.39 13 8.15 26.15 12.13 39.24l12.79 42 1.33-1.74q-80.44 3.75-160.88 7.39c-15.23.7-30.45 1.47-45.68 2l1.42 1.42c-1-17-2-35.42-11.5-50.14-7.8-12.12-21.52-21.25-36.36-19.32-1.45.19-1.47 2.51 0 2.32 14.66-1.91 28 7.92 35 20.1 8.21 14.12 9.07 31.14 10 47a1.46 1.46 0 0 0 1.43 1.43c53.59-3 107.26-5 160.88-7.44q22.83-1.05 45.68-2a1.4 1.4 0 0 0 1.33-1.75l-13.08-43.27c-4-13.17-7.7-26.45-12.17-39.47-7.36-21.4-18.39-42.55-39.78-52.64-21.68-10.24-47.33-8.71-70-3-23.44 5.95-45.18 17.27-65 30.89-41 28.1-74 65.65-112 97.43-19 15.91-39.37 30.53-61.92 41a168.91 168.91 0 0 1 -77.78 15.53c-46.28-1.63-107.3-15-118.95-67.66-11.42-51.58 26.07-95.94 64.49-124.76a298.79 298.79 0 0 1 70.79-39.53c24.72-9.62 51.4-16 72.89-32.31 17.12-13 30.52-32.37 28.74-54.68-.77-9.6-4.11-19.08-10.36-26.5-7.38-8.76-18.12-12.69-29.24-14-14-1.7-28.5-.93-42.55 0-14.65.94-29.24 2.55-43.84 4.07-27 2.8-54.16 5.5-81.24 2.8-23.12-2.3-46.46-8.27-66.52-20.29-21.88-13.11-36.88-33.44-46.59-56.75a274.83 274.83 0 0 1 -11.48-35 .88.88 0 0 0 -1.7.47z" />
      <path d="m710.75 746.42c43.33-41.19 91.65-83.68 151-90.48 20.88-2.4 43.44.36 60 13.29 18.29 14.27 26 37.92 32.73 60.12l20.52 67.44-206.56 9.43c-.89-15.57-1.92-31.65-9-45.54s-22-25-37.42-22.76" />
      <path d="m711.37 747c18-16.7 36.2-33.2 56-47.73 19.26-14.14 40.14-26.35 62.82-34.07 22.12-7.53 47.31-11.42 70.1-4.6a60.68 60.68 0 0 1 28.8 18.43c7.41 8.6 12.45 19 16.45 29.58 4.5 11.89 7.93 24.18 11.63 36.34q6.36 20.88 12.82 41.73l3.24 10.56 1.77-2.24q-40.31 2-80.62 3.84c-26.88 1.25-53.75 2.61-80.63 3.91-15.1.73-30.21 1.5-45.31 2.19l1.33 1.33c-.78-13.58-1.59-27.56-6.59-40.37-4.32-11.09-12-21-22.94-26.22a32 32 0 0 0 -18.54-2.83c-1.47.21-.85 2.44.61 2.24 10.94-1.55 21.72 4.49 28.89 12.31 8.37 9.12 12.11 20.95 13.95 33 1.11 7.25 1.53 14.58 2 21.89a1.36 1.36 0 0 0 1.34 1.34c26.87-1.23 53.75-2.32 80.62-3.51s53.75-2.27 80.62-3.48q22.67-1 45.32-2a1.85 1.85 0 0 0 1.77-2.32q-6.51-21.66-13-43.32c-3.87-12.94-7.57-26-11.92-38.76-3.76-11.09-8.27-22.12-15-31.78a64.08 64.08 0 0 0 -26.69-22.55c-22.46-9.88-48.65-7.85-71.79-1.5-23.49 6.43-45.22 18.35-64.91 32.5-20.26 14.55-38.87 31.3-56.83 48.57l-6.55 6.33c-.82.8.42 2 1.25 1.25z" />
      <path d="m493.2 492c-4.47 18.24-21.7 28.75-37.14 37.07-19.47 10.5-39.24 20.45-58.42 31.48s-37.81 23.05-54.71 37.32a208.31 208.31 0 0 0 -39.53 43.82c-10.86 16.42-18.82 34.57-22.27 54-.41 2.33-.72 4.65-.91 7-.1 1.25 2 1.55 2.21.3.87-4.81 1.79-9.57 3-14.29a147.1 147.1 0 0 1 4.46-14.06 150.7 150.7 0 0 1 12.86-26.19 201.22 201.22 0 0 1 38.38-45c16.3-14.47 34.54-26.61 53.28-37.66 19.2-11.31 39-21.51 58.65-32 15.43-8.28 33.18-17.9 40-35.11a31.86 31.86 0 0 0 1.74-6.14c.18-1.05-1.36-1.51-1.62-.45z" />
      <path d="m290.46 827.31c16.54 9.17 34.72 14.72 53.16 18.55 18.66 3.89 37.7 6 56.68 7.62a647.19 647.19 0 0 0 64.7 2.44 591.65 591.65 0 0 0 66.64-4.84c20.83-2.73 41.69-7.3 61.09-15.51q3.59-1.53 7.14-3.15c1.19-.54.34-2.59-.91-2.14a323.84 323.84 0 0 1 -60.26 16 542.3 542.3 0 0 1 -65.49 6 596.31 596.31 0 0 1 -65.63-1.44c-18.76-1.4-37.56-2.76-56.14-5.82s-37.06-7.82-54.07-16c-2.07-1-4.1-2-6.11-3.12-.9-.49-1.7.88-.81 1.37z" />
      <path d="m405.56 278.88a268.22 268.22 0 0 0 9.13 61.45 281.55 281.55 0 0 0 22.61 58 257.44 257.44 0 0 0 16.05 26.67 128 128 0 0 0 20.18 23.57c13.85 12.27 30.79 20.28 48.37 25.64 18.13 5.51 37 8.44 55.75 10.76l6.86.83a1.58 1.58 0 0 0 1.57-1.57 1.61 1.61 0 0 0 -1.57-1.57c-18.43-2.36-36.94-4.78-54.94-9.53-17.51-4.61-34.69-11.55-49.08-22.74-16-12.47-27.41-30-37.31-47.45a279.77 279.77 0 0 1 -24.11-56.35 293.31 293.31 0 0 1 -11.41-60.31q-.32-3.72-.53-7.44c-.06-1-1.6-1-1.57 0z" />
      <path d="m413 787.38c35.5 9.35 68.81-11.89 95.28-33.1 14.32-11.47 28.27-23.34 43.7-33.34a322.83 322.83 0 0 1 47.1-25.05 401.94 401.94 0 0 1 51.26-18 353.66 353.66 0 0 1 42.18-9.38c28.49-4.35 57.39-6 86.14-7.57 27.93-1.47 55.91-2.07 83.81-3.9 2.7-.18 5.39-.46 8.09-.61 1.48-.08 1.49-2.4 0-2.32-12.18.66-24.43.7-36.62 1.17-14.56.56-29.12 1.12-43.68 1.79-29.38 1.36-58.83 3-88 6.76a385.19 385.19 0 0 0 -89.26 22.89 343.24 343.24 0 0 0 -47.55 22.53 322.13 322.13 0 0 0 -43.67 30.75c-27.23 22.39-57.37 49.46-95.13 47.91a64.93 64.93 0 0 1 -13.22-1.95.75.75 0 0 0 -.4 1.44z" />
      <g fill="#18c4ff">
        <path d="m332.79 158.84a74.53 74.53 0 0 0 15.56-3.4c3-1 9.6-2.91 7.61-7.44a3 3 0 0 0 -4.29-1.31 9.33 9.33 0 0 0 -4 4.8c-1.37 3.23-1.57 8.5 2.17 10.29 2.31 1.1 4.94-.07 7-1.18a22.58 22.58 0 0 0 6.29-5.08l-1.81-1.06a5.46 5.46 0 0 0 1.53 6 5.55 5.55 0 0 0 6.4.36c1.49-.87 2.79-.38 4.4-.11s3 .44 4.56.56a55.93 55.93 0 0 0 9.77-.06c1.43-.13 1.44-2.38 0-2.25a62.9 62.9 0 0 1 -16-.81 4.91 4.91 0 0 0 -2.89.22c-1 .44-1.77 1.15-2.92 1.11a3.2 3.2 0 0 1 -2.78-4.49 1.09 1.09 0 0 0 -1.81-1.06 20.76 20.76 0 0 1 -5.12 4.36c-1.62 1-4.33 2.62-6.15 1.3-1.5-1.08-1.68-3.38-1.43-5a9.51 9.51 0 0 1 2.76-5.49c.71-.66 2.25-1.49 2.65-.08a2.21 2.21 0 0 1 -.88 2.22c-1.63 1.4-4.19 2-6.2 2.7a73.4 73.4 0 0 1 -14.42 3.32c-1 .12-1 1.7 0 1.58z" />
        <path d="m333.85 186.32c9.83.32 19.68.83 29.48 1.7a54.89 54.89 0 0 1 7.34 1c1.81.41 3.83 1.27 5.66.56a6.35 6.35 0 0 0 1.8-1.28c.56-.48.74-.87 1.36-.21a6.05 6.05 0 0 0 .81.87 3.34 3.34 0 0 0 4.16-.2c.82-.64-.26-2.05-1.12-1.45-1.93 1.36-2.49-1.63-4.33-1.59-1.69 0-2.4 2-4 2.21a9 9 0 0 1 -2.9-.46c-1.09-.24-2.19-.44-3.29-.6-2.21-.34-4.44-.54-6.66-.71-9.42-.73-18.85-1.2-28.29-1.46a.81.81 0 0 0 0 1.61z" />
        <path d="m390.6 190.49a.83.83 0 0 0 0-1.66.83.83 0 0 0 0 1.66z" />
        <path d="m328.07 216.6 13 1.49c1.7.19 3.59.61 5.23-.09a3.37 3.37 0 0 0 2.17-3.29c0-.64-.93-1.2-1.41-.59a3.74 3.74 0 0 0 .24 4.94c1.59 1.64 4.09 1.48 5.92.39a2.57 2.57 0 0 1 1.81-.59 11.25 11.25 0 0 1 1.93.73 18.55 18.55 0 0 0 3.88.91 18.84 18.84 0 0 0 8.3-.84c1.12-.38.64-2.17-.5-1.79a17.33 17.33 0 0 1 -6.7.91 24.73 24.73 0 0 1 -6.06-1.53 3.34 3.34 0 0 0 -2.27 0c-.79.29-1.41.9-2.21 1.16-2 .66-4.45-1.16-3-3.27l-1.51-.19c.44 3.16-7.42 1.45-8.92 1.29l-9.89-1c-.91-.09-.89 1.31 0 1.42z" />
        <path d="m383.19 222.3.19.15.23.1h.05.16.17.28.05a1.39 1.39 0 0 0 .26-.1l.08-.05.21-.16s0 0 0 0a1.66 1.66 0 0 0 .16-.21 1.6 1.6 0 0 0 .1-.23v-.07-.25-.05a1.32 1.32 0 0 0 -.05-.33 2.14 2.14 0 0 0 -.1-.22.05.05 0 0 0 0 0 1.66 1.66 0 0 0 -.16-.21l-.21-.17-.14-.06a.76.76 0 0 0 -.16-.06h-.28-.03-.28-.05l-.23.1a1.54 1.54 0 0 0 -.22.17l-.15.2a.64.64 0 0 0 -.1.33.73.73 0 0 0 0 .36.62.62 0 0 0 .16.3.68.68 0 0 0 .25.23l.22.09a.76.76 0 0 0 .47 0l.22-.09a.89.89 0 0 0 .32-.32l-.14.18.05-.06-.18.14h.07l-.21.09h.09-.24.12-.24.12l-.21-.09h.09l-.18-.14.06.07-.14-.18a.25.25 0 0 1 0 .07l-.09-.21a.14.14 0 0 1 0 .07v-.24.07-.23a.19.19 0 0 1 0 .07l.09-.21a.25.25 0 0 1 0 .07l.14-.18a.46.46 0 0 1 -.07.07l.18-.14a.27.27 0 0 1 -.1.06l.21-.09a.45.45 0 0 1 -.11 0h.24-.16.24-.08l.21.09h-.06l.18.14a1 1 0 0 0 -.64-.27.72.72 0 0 0 -.35.08.7.7 0 0 0 -.29.19l-.14.18a.9.9 0 0 0 -.12.46v.24a1 1 0 0 0 .23.4z" />
        <path d="m327.23 248.22c3.65-.56 7.17.73 10.68 1.54a17.4 17.4 0 0 0 9.17.1 15.18 15.18 0 0 0 10.41-11.93 1 1 0 0 0 -1.89-.52c-1.36 3.94-2.49 9.33 1.93 11.83a4.73 4.73 0 0 0 4.92-.16c1.57-1 2.61-2.87 4.39-3.5l-1.22-1.58a3 3 0 0 0 3.57 4.25c1-.35 1.19-.55 1.83.43a15 15 0 0 0 1.06 1.72c.92 1.08 2.35 1.8 3.64.9a1 1 0 0 0 -1.05-1.79c-.74.34-1.48-1.48-1.76-1.93a4.2 4.2 0 0 0 -1.73-1.69 2.78 2.78 0 0 0 -2.34.13c-.45.19-.74.46-1.17.09a.78.78 0 0 1 -.18-1 1.09 1.09 0 0 0 -1.22-1.58 9 9 0 0 0 -3.3 2.32c-1 1-2.16 2.26-3.7 1.86a4.32 4.32 0 0 1 -2.86-3.81 14.46 14.46 0 0 1 1.08-5.94l-1.92-.26a13.2 13.2 0 0 1 -10.48 10.86c-3.1.59-6.25-.29-9.26-1a22.2 22.2 0 0 0 -9-.72c-.93.16-.53 1.54.38 1.4z" />
        <path d="m342.78 268.86 7.88-1.05a.94.94 0 0 0 .91-.91c0-.44-.42-1-.91-.9l-7.88 1.05a.93.93 0 0 0 -.9.9.91.91 0 0 0 .9.91z" />
        <path d="m340.89 387.49a49.11 49.11 0 0 0 21.21 0c4.8-1.06 11.78-3.38 11.55-9.46a5.76 5.76 0 0 0 -3.24-5.29c-2.41-1-5 .3-6.48 2.28-1.89 2.55-1.82 6-1.56 9.05.21 2.36.77 5.09 2.89 6.48 2.88 1.89 7.15.18 10.06-.86a40.29 40.29 0 0 0 10-5.34l-1.56-1.2a3.92 3.92 0 0 0 1.49 4.19c1.51 1.19 3.47 1.18 5.28.9 4.7-.72 9.37-1.85 14-2.83 1.21-.25.69-2-.5-1.83-3.48.64-7 1.27-10.44 1.94a22.39 22.39 0 0 1 -5.06.78c-1.35-.06-3.24-.92-2.74-2.58.25-.84-.79-1.76-1.56-1.2a38.66 38.66 0 0 1 -14.23 6.48c-1.21.27-2.63.5-3.72-.26a4.7 4.7 0 0 1 -1.63-2.93 17.33 17.33 0 0 1 -.2-7.24c.47-2.14 2.34-4.86 4.9-4.19 1.82.47 2.53 2.66 2.38 4.35-.45 4.92-7.15 6.41-11 7.23a47.24 47.24 0 0 1 -19.42-.06c-1-.22-1.44 1.33-.42 1.55z" />
        <path d="m354.06 415.47a327.54 327.54 0 0 0 47.07-2.11c1.06-.12 1.06-1.8 0-1.67a327.64 327.64 0 0 1 -47.07 2.25.77.77 0 0 0 0 1.53z" />
        <path d="m367 437.68a147.09 147.09 0 0 0 46.48-4.4.82.82 0 0 0 -.44-1.57 145.8 145.8 0 0 1 -46 4.49c-1-.06-.95 1.42 0 1.48z" />
        <path d="m401.86 460.54h16.21a.83.83 0 0 0 0-1.65h-16.21a.8.8 0 0 0 0 1.59z" />
        <path d="m492.1 533.09c4.47-1.9 9-3.82 13.9-4.51 3.51-.5 10.44-.86 11.67 3.61.47 1.67-.4 3.62-2.38 3.23-2.33-.46-.84-2.69.33-3.53a13.28 13.28 0 0 1 5.88-2.44c1.89-.22 4.14.52 4.78 2.49l1.94-.82c-.44-.65.87-1.12 1.36-1.29a9.57 9.57 0 0 1 3.16-.22c2.49 0 5 .12 7.48.27a145.71 145.71 0 0 1 14.58 1.61 155.32 155.32 0 0 1 28.46 7.51c1.19.43 1.67-1.43.52-1.88a147.41 147.41 0 0 0 -51-9.67c-1.92 0-3.79 0-5.42 1.15-1.2.86-1.85 2.29-.93 3.62.64.93 2.34.39 1.94-.82a5.85 5.85 0 0 0 -4-3.76 9.59 9.59 0 0 0 -6.58.84c-1.87.83-4.08 1.91-5.16 3.76a3.35 3.35 0 0 0 .8 4.46 4.23 4.23 0 0 0 4.5.16 4.44 4.44 0 0 0 1.75-4.65c-.9-4.9-6.79-5.78-10.89-5.62-6.14.24-11.88 2.71-17.43 5.13-1 .41-.13 1.79.81 1.39z" />
        <path d="m450.48 555.35a136.35 136.35 0 0 1 60.37 9.65 133.63 133.63 0 0 1 15.84 7.68.82.82 0 0 0 .83-1.41 137.71 137.71 0 0 0 -59.22-17.58 134.14 134.14 0 0 0 -17.82.06c-1 .08-1 1.69 0 1.62z" />
        <path d="m530.34 584.78-3.15-.78a.85.85 0 1 0 -.45 1.64l3.15.79a.86.86 0 1 0 .45-1.65z" />
        <path d="m406.14 579.51c13.3 2 28 4.48 37.77 14.59l.36-1.39a5.16 5.16 0 0 1 -5.75-1.15l-.19 1.45c3.86-2.37 8.19 1 11.26 3.17s7 4.44 10.86 3l-1.2-.68c.24 1.3.49 2.6.73 3.91s2 .67 1.81-.5-.43-2.63-.65-3.94a1 1 0 0 0 -1.21-.68c-4.4 1.66-8.3-2.2-11.71-4.4-3.15-2-7.35-3.71-10.82-1.45a.92.92 0 0 0 -.18 1.44 7 7 0 0 0 7.69 1.37.83.83 0 0 0 .17-1.3c-10.05-10.28-24.93-12.8-38.54-14.84-.93-.14-1.34 1.28-.4 1.42z" />
        <path d="m463.12 622a.94.94 0 0 0 0-1.87.94.94 0 0 0 0 1.87z" />
        <path d="m374 610.72a109.57 109.57 0 0 1 19.31 11.12c1.22.88 2.44 1.92 4 2.11s3.05-.62 4.45-1.25l11.24-5.05-1.37-1-3.38 13.87a1 1 0 0 0 1.21 1.2 17.21 17.21 0 0 0 8.66-4.19l-1.69-.44a8.09 8.09 0 0 1 1 3.74c-.1 1-.91 2.18-2 1.91a1 1 0 0 0 -.55 2 70.83 70.83 0 0 1 12.53 5.88 1 1 0 0 0 1-1.69 60.27 60.27 0 0 0 -13-6.17l-.55 2a3.71 3.71 0 0 0 4.1-2c1.09-2 .29-4.28-.52-6.2a1 1 0 0 0 -1.69-.45 15.33 15.33 0 0 1 -7.75 3.73l1.2 1.21 3.29-13.9a.94.94 0 0 0 -1.37-1.05l-10.12 4.55c-1.63.75-3.61 2.13-5.43 1.28a29.9 29.9 0 0 1 -3.86-2.59 109.73 109.73 0 0 0 -17.93-9.87c-.85-.37-1.6.87-.74 1.25z" />
        <path d="m335 641.48a389.3 389.3 0 0 0 48.84 19.13q7.11 2.24 14.3 4.2a.79.79 0 0 0 .42-1.53 403.25 403.25 0 0 1 -49.16-17q-6.89-2.89-13.66-6c-.85-.4-1.59.85-.74 1.25z" />
        <path d="m316.4 679.52c5.35 4.26 11 8.72 17.79 10.31a18 18 0 0 0 15.47-3.32 12.14 12.14 0 0 0 4.36-7.43c.11-.69-.59-1.47-1.3-1a11.69 11.69 0 0 0 -4.16 14.37c1.3 2.81 3.91 4.06 6.83 2.81s4.69-4.73 8-5.29l-1.29-1a14.47 14.47 0 0 0 17.65 15.25c1.23-.27.7-2.1-.52-1.87a12.6 12.6 0 0 1 -15.14-13.35 1 1 0 0 0 -1.28-1c-2.45.43-4.12 2.26-5.88 3.86a7.11 7.11 0 0 1 -3.38 1.91c-1.53.25-2.52-.74-3.16-2a10 10 0 0 1 3.22-12.17l-1.32-.76c-.87 6.46-8 9.93-14 9.89-8-.06-14.9-5.61-20.85-10.3-.77-.61-1.87.47-1.09 1.09z" />
        <path d="m304.34 737.26a176.72 176.72 0 0 0 55.54 11.85c1.07 0 1.07-1.6 0-1.65a178.9 178.9 0 0 1 -55.15-11.63c-.89-.34-1.27 1.08-.39 1.43z" />
        <path d="m315.5 775.56a42.58 42.58 0 0 0 12.83 2.4.82.82 0 0 0 0-1.63 41.25 41.25 0 0 1 -12.39-2.33c-1-.35-1.42 1.23-.44 1.57z" />
      </g>
      <path d="m215.28 257.09s.1-.23 0 0a1 1 0 0 1 .07-.15c0-.11.11-.21.17-.32l.2-.31.09-.13c-.12.18 0 0 0 0a3.58 3.58 0 0 1 .51-.52l.12-.11.09-.07c-.12.08 0 0 .06-.05l.17-.11c.2-.13.42-.25.63-.36l.15-.07c.14-.06.07 0 0 0 .13 0 .25-.1.38-.14s.45-.12.67-.18a3.58 3.58 0 0 1 .35-.09 1.25 1.25 0 0 0 .19 0 .94.94 0 1 0 0-1.88 4.09 4.09 0 0 0 -1.8.22 5.9 5.9 0 0 0 -1.74 1 5.71 5.71 0 0 0 -1.89 3 .81.81 0 0 0 .56 1 .83.83 0 0 0 1-.57z" />
    </svg>
  );
};

export default ReadingDocsSvg;
