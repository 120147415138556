import React from "react";
import InputFields from "../../../components/common/Input/InputFields";
import { BsInfoCircleFill } from "react-icons/bs";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { Button } from "flowbite-react";
import { btnPrimary } from "../../../utils/helper";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import {
  resetStatutoryEpfDetails,
  setStatutoryEpfDetails,
  setStatutoryToggleDetails,
} from "../../../store/slices/statutorySlice";
import {
  updateStatutoryComponent,
} from "../../../services/StatutoryService";
import { STATUS_CODES } from "../../../constants/Constant";
import { showToast } from "../../../components/common/Toast";
import { STATUTORY_MESSAGE } from "../../../constants/Messages";
import { StatutoryComponentType } from "../../../enum/StatutoryComponentType";

const EpfComponent = ({ onCloseEpfModal }) => {
  const dispatch = useAppDispatch();
  const statutory = useAppSelector(state => state.statutory);

  const { statutoryEpfDetails, statutoryToggleDetails } = statutory;

  //Function to get form values for epf component
  const handleChangeEpfDetails = e => {
    const { name, value, type, checked } = e.target;
    dispatch(
      setStatutoryEpfDetails({
        ...statutoryEpfDetails,
        [name]: type === "checkbox" ? checked : value,
      })
    );
  };

  //Function to submit epf details to server.
  const handleSubmitEpfDetails = async () => {
    const updatedStatutoryDetails = {
      ...statutoryEpfDetails,
      employeeContributionRate: +statutoryEpfDetails.employeeContributionRate,
      employeeContributionLimit: +statutoryEpfDetails.employeeContributionLimit,
      employerContributionRate: +statutoryEpfDetails.employerContributionRate,
      employerContributionLimit: +statutoryEpfDetails.employerContributionLimit,
      statutoryComponentType: +StatutoryComponentType.EPF,
    };

    const response = await updateStatutoryComponent(updatedStatutoryDetails);
    if (response?.data?.statusCode === STATUS_CODES.SUCCESS) {
      showToast("success", STATUTORY_MESSAGE.EPF_SUCCESS);
      dispatch(
        setStatutoryToggleDetails({
          ...statutoryToggleDetails,
          toggleStatutoryModal: false,
          isEpfEnabled: true,
        })
      );
      dispatch(resetStatutoryEpfDetails());
    }
  };

  //Function to update epf component
  const handleUpdateEpfComponent = async () => {
    const updatedStatutoryDetails = {
      ...statutoryEpfDetails,
      employeeContributionRate: +statutoryEpfDetails.employeeContributionRate,
      employeeContributionLimit: +statutoryEpfDetails.employeeContributionLimit,
      employerContributionRate: +statutoryEpfDetails.employerContributionRate,
      employerContributionLimit: +statutoryEpfDetails.employerContributionLimit,
    };

    if (updatedStatutoryDetails?.statutoryComponentType === StatutoryComponentType.EPF) {
      const response = await updateStatutoryComponent(updatedStatutoryDetails);
      if (response?.data?.statusCode === STATUS_CODES.SUCCESS) {
        showToast("success", STATUTORY_MESSAGE.EPF_SUCCESS);
        dispatch(
          setStatutoryToggleDetails({
            ...statutoryToggleDetails,
            toggleUpdateStatutoryModal: false,
            isEpfEnabled: true,
          })
        );
      }
    }
  };

  return (
    <div className="">
      {/* first container */}
      <div className="epf-number-container  flex gap-8 mt-4">
        <div className=" min-h-8 w-[40%]">
          <p className="mb-2">EPF Number</p>
          <InputFields
            InputField={{
              type: "text",
              placeholder: "EPF Number",
              name: "number",
            }}
            inputClassName="w-full"
            onChange={handleChangeEpfDetails}
            value={statutoryEpfDetails.number}
          />
        </div>
        <div className=" min-h-8 w-[25%]">
          <div className="flex">
            <p className="mb-2">Deduction Cycle</p>
            <BsInfoCircleFill
              size={13}
              color="#c0c1c4"
              className="cursor-pointer ml-1 mt-[0.4rem] "
              data-tooltip-id="deduction cycle"
              data-tooltip-html={`<div>
                    Provident Fund (PF)</br> contributions for each month</br>should be deposited to the</br>Employee Provident Fund</br>Organization (EPFO) within</br>the 15th of the following</br>month</div>`}
              data-tooltip-place="top-middle"
            />
            <ReactTooltip id="deduction cycle" />
          </div>
          <InputFields
            InputField={{
              type: "text",
              placeholder: "Monthly",
              name: "monthly",
            }}
            disabled={true}
            inputClassName="w-full"
          />
        </div>
      </div>
      {/* second container */}
      <div className="employee-contribution-rate-container mt-8  ">
        <div className="w-[60%]">
          <p>Employee Contribution Rate</p>
          <InputFields
            InputField={{
              type: "number",
              name: "employeeContributionRate",
            }}
            inputClassName="w-full mt-2"
            value={statutoryEpfDetails.employeeContributionRate}
            onChange={handleChangeEpfDetails}
          />
        </div>
        <div className="w-[60%] mt-4">
          <p>Employee Contribution Limit</p>
          <InputFields
            InputField={{
              type: "number",
              name: "employeeContributionLimit",
            }}
            inputClassName="w-full mt-2"
            value={statutoryEpfDetails.employeeContributionLimit}
            onChange={handleChangeEpfDetails}
          />
        </div>
      </div>
      {/* Third container */}
      <div className="employee-contribution-rate-container-2 mt-8 ">
        <div className="w-[60%]">
          <div className=" flex justify-between">
            <p className="">Employer Contribution Rate </p>
            {/* <p className="text-blue-700 cursor-pointer relative">
              View Splitup
              
            </p> */}
          </div>

          <InputFields
            InputField={{
              type: "number",
              name: "employerContributionRate",
            }}
            inputClassName="w-[100%] mt-2"
            value={statutoryEpfDetails.employerContributionRate}
            onChange={handleChangeEpfDetails}
          />
        </div>
        <div className="w-[60%] mt-4">
          <p>Employer Contribution Limit</p>
          <InputFields
            InputField={{
              type: "number",
              name: "employerContributionLimit",
            }}
            inputClassName="w-full mt-2"
            value={statutoryEpfDetails.employerContributionLimit}
            onChange={handleChangeEpfDetails}
          />
        </div>
      </div>
      {/* Fourth container */}
      <div className="employers-contribution-checkbox-container mt-8 w-full">
        <div className="flex items-center">
          <input
            checked={statutoryEpfDetails.includeEmployersContributionInCtc}
            id="includeEmployersContributionInCtc-checkbox"
            type="checkbox"
            name="includeEmployersContributionInCtc"
            onChange={handleChangeEpfDetails}
            className="w-[1rem] h-[1rem] text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
          />
          <label
            for="includeEmployersContributionInCtc-checkbox"
            className="ms-2 text-sm text-gray-900 dark:text-gray-300 -mt-[0.2rem]">
            Include employer's contribution in the CTC
          </label>
        </div>
      </div>

      {/* Fifth container */}
      <div className="pf-configuration mt-8 ">
        <h1 className="font-medium">PF Configuration when LOP Applied</h1>
        <div className="flex mb-4">
          <div className="">
            <input
              id="proRatePfWage-checkbox"
              type="checkbox"
              checked={statutoryEpfDetails.proRatePfWage}
              onChange={handleChangeEpfDetails}
              name="proRatePfWage"
              className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
            />
          </div>
          <div className="">
            <label
              for="proRatePfWage-checkbox"
              className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300  w-full">
              Pro-rate Restricted PF Wage
            </label>
            <p className="ms-2 text-sm text-gray-900 dark:text-gray-300">
              PF contribution will be pro-rated based on the number of days worked by the employee.
            </p>
          </div>
        </div>
        <div className="flex mb-4">
          <div className="">
            <input
              id="considerAllSalaryComponents-checkbox"
              type="checkbox"
              checked={statutoryEpfDetails.considerAllSalaryComponents}
              onChange={handleChangeEpfDetails}
              name="considerAllSalaryComponents"
              className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
            />
          </div>
          <div className="">
            <label
              for="considerAllSalaryComponents-checkbox"
              className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300  w-full">
              Consider all applicable salary components if PF wage is less than ₹15,000 after Loss
              of Pay
            </label>
            <p className="ms-2 text-sm text-gray-900 dark:text-gray-300">
              PF wage will be computed using the salary earned in that particular month (based on
              LOP) rather than the actual amount mentioned in the salary structure.
            </p>
          </div>
        </div>
      </div>

      <hr className="h-px mt-6 bg-gray-200 border-0 dark:bg-gray-700" />
      <div className=" mt-3">
        <div className=" w-[100%]  flex gap-4">
          <Button
            type="submit"
            className={btnPrimary()}
            form="add-modal"
            onClick={
              statutoryToggleDetails.toggleUpdateStatutoryModal
                ? handleUpdateEpfComponent
                : handleSubmitEpfDetails
            }>
            {statutoryToggleDetails.toggleUpdateStatutoryModal ? "Save" : "Enable"}
          </Button>
          <Button
            type="submit"
            className={btnPrimary()}
            form="add-statutory-modal"
            onClick={() => onCloseEpfModal()}>
            Cancel
          </Button>
        </div>
      </div>
    </div>
  );
};

export default EpfComponent;
