import DeleteModal from "../../../components/common/Modal/DeleteModal";
import { showToast } from "../../../components/common/Toast";
import { ANNOUCEMENT_MESSAGES } from "../../../constants/Messages";
import { deleteAnnouncementById } from "../../../services/CommonService";

const DeleteAnnouncement = ({
  show,
  onClose,
  announcementInfo,
  renderAnnouncement,
  isActionDeleteBtn,
  setLoading,
}) => {
  const { announcementId, announcementName } = announcementInfo;
  const onDeleteAnnouncement = async () => {
    const { toast, toastId } = showToast("loading", ANNOUCEMENT_MESSAGES.LOADING_DELETE);
    await deleteAnnouncementById(announcementId);
    showToast("success", `${announcementName} deleted successfully`);
    toast.dismiss(toastId);

    setLoading(true);
    renderAnnouncement();
    onClose();
    setLoading(false);
  };

  const modalConfigs = {size:"xl", show, onClose, showFooter: false };
  const modalButtonConfigs = { isActionDeleteBtn };
  const message = `Are you sure you want to delete ${announcementName} ?`;

  return (
    <DeleteModal
      modalConfigs={modalConfigs}
      heading="Delete Announcement"
      onDelete={onDeleteAnnouncement}
      onCancel={onClose}
      modalButtonConfigs={modalButtonConfigs}
      message={message}
    />
  );
};

export default DeleteAnnouncement;
