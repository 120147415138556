import { Button } from "flowbite-react";
import { deleteHolidayById } from "../../services/CommonService";
import ModalComponent from "../common/Modal/Modal";
import moment from "moment";

const DeleteHoliday = ({
  toggleDeleteHoliday,
  setToggleDeleteHoliday,
  selectedHoliday,
  setIsChange,
  setSelectedCheckedbox,
}) => {
  const formattedDate = moment(`${selectedHoliday?.holidayDate}Z`).format("YYYY-MM-DD");
  //Submitting Delete Holiday
  const onSubmitDeleteHoliday = async () => {
    await deleteHolidayById(selectedHoliday?.id);

    //remove deleted holiday from checked items as well
    setSelectedCheckedbox(prevSC => {
      const filteredIds = prevSC.checkedItems.filter(id => {
        return id !== selectedHoliday.id.toString();
      });

      return {
        ...prevSC,
        checkedItems: filteredIds,
      };
    });

    setToggleDeleteHoliday(false);
    setIsChange(true);
  };

  return (
    <ModalComponent
      show={toggleDeleteHoliday}
      onClose={setToggleDeleteHoliday.bind(null, false)}
      showFooter={false}
      heading="Delete Holiday">
      <div className="text-center mt-4">
        <h3 className="text-lg font-normal text-gray-900 dark:text-gray-400">
          Are you sure you want to delete "{selectedHoliday?.eventTitle}" ?
          <span className="text-gray-400 dark:text-gray-400"> ({formattedDate})</span>
        </h3>
        <div className="flex justify-end mt-4 gap-4">
          <Button color="failure" onClick={onSubmitDeleteHoliday}>
            Yes, I'm sure
          </Button>
          <Button
            color="gray"
            onClick={() => {
              setToggleDeleteHoliday(false);
            }}>
            No, cancel
          </Button>
        </div>
      </div>
    </ModalComponent>
  );
};

export default DeleteHoliday;
