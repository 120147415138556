export const ThynkWebLogoSvg = ({ w = 30, h = 30 }) => {
  return (
    <svg
      preserveAspectRatio="xMidYMid meet"
      data-bbox="33 20 126 160"
      viewBox="33 20 126 160"
      height={h}
      width={w}
      xmlns="http://www.w3.org/2000/svg"
      data-type="color"
      role="presentation"
      aria-hidden="true"
    >
      <g>
        <path
          fill="#114880"
          d="M124.336 168.999c0 6.076-4.927 11.001-11.004 11.001s-11.004-4.925-11.004-11.001 4.927-11.001 11.004-11.001 11.004 4.925 11.004 11.001z"
          data-color="1"
        ></path>
        <path
          fill="#114880"
          d="M159 134.341c0 6.076-4.927 11.001-11.004 11.001s-11.004-4.925-11.004-11.001 4.927-11.001 11.004-11.001S159 128.265 159 134.341z"
          data-color="1"
        ></path>
        <path
          fill="#114880"
          d="M124.336 137.114c0 6.076-4.927 11.001-11.004 11.001s-11.004-4.925-11.004-11.001 4.927-11.001 11.004-11.001 11.004 4.925 11.004 11.001z"
          data-color="1"
        ></path>
        <path
          fill="#114880"
          d="M159 65.026c0 6.076-4.927 11.001-11.004 11.001s-11.004-4.925-11.004-11.001 4.927-11.001 11.004-11.001S159 58.95 159 65.026z"
          data-color="1"
        ></path>
        <path
          fill="#114880"
          d="M159 31.001c0 6.076-4.927 11.001-11.004 11.001s-11.004-4.925-11.004-11.001S141.919 20 147.996 20 159 24.925 159 31.001z"
          data-color="1"
        ></path>
        <path
          fill="#114880"
          d="M89.672 134.341c0 6.076-4.927 11.001-11.004 11.001s-11.004-4.925-11.004-11.001 4.927-11.001 11.004-11.001 11.004 4.925 11.004 11.001z"
          data-color="1"
        ></path>
        <path
          fill="#114880"
          d="M89.672 99.683c0 6.076-4.927 11.001-11.004 11.001s-11.004-4.925-11.004-11.001 4.927-11.001 11.004-11.001 11.004 4.925 11.004 11.001z"
          data-color="1"
        ></path>
        <path
          fill="#114880"
          d="M55.008 99.683c0 6.076-4.927 11.001-11.004 11.001S33 105.759 33 99.683s4.927-11.001 11.004-11.001 11.004 4.925 11.004 11.001z"
          data-color="1"
        ></path>
        <path
          fill="#114880"
          d="M124.336 99.683c0 6.076-4.927 11.001-11.004 11.001s-11.004-4.925-11.004-11.001 4.927-11.001 11.004-11.001 11.004 4.925 11.004 11.001z"
          data-color="1"
        ></path>
        <path
          fill="#114880"
          d="M124.336 67.482c0 6.076-4.927 11.001-11.004 11.001s-11.004-4.925-11.004-11.001 4.927-11.001 11.004-11.001 11.004 4.925 11.004 11.001z"
          data-color="1"
        ></path>
      </g>
    </svg>
  );
};
