/**
 * Renders table data based on the input data, class name, and children.
 *
 * @param {{className: string, children: ReactNode, data: [{tableData: string}]|string | undefined}} props - the props for the table data
 * @param {[{tableData: string}]|string | undefined} data - the input data for rendering table data
 * @param {string} className - the class name for styling the table data
 * @param {ReactNode} children - the children to be rendered within the table data
 * @param {Object} otherProps - the other props to be passed to the table data
 * @return {ReactNode} the rendered table data
 */
const TableData = ({ data, className, children, otherProps }) => {
  if (data && Array.isArray(data)) {
    const tableDataArray = data?.map(d => (
      <td className={`${className} px-4 py-2 whitespace-nowrap`}>{d.tableData}</td>
    ));
    return <>{tableDataArray}</>;
  }

  return (
    <td {...otherProps} className={`${className} px-4 py-2 whitespace-nowrap`}>
      {!data ? children : data}
    </td>
  );
};

export default TableData;
