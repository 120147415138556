export const DeductionType = {
  PostTax: 0,
  IncomeTax: 1,
  LOP: 2,
  PreTax: 3
};

export const DeductionTypeLabels = {
  [DeductionType.PostTax]: 'Post-Tax Deduction',
  [DeductionType.IncomeTax]: 'Income Tax',
  [DeductionType.LOP]: 'Loss of Pay',
  [DeductionType.PreTax]: 'Pre-Tax Deduction',
};
