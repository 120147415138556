import { createSlice } from "@reduxjs/toolkit";

/**
 * The initial state of the profileSlice
 * @type {Object}
 * @property {Object} tabs - The tabs object
 * @property {Object} data - The data object
 */
const initialState = {
  toggle: false,
  organizationFields: {},
};

const sideBarSlice = createSlice({
  name: "sidebar",
  initialState,
  reducers: {
    setSideBarState: (state, action) => {
      let obj = action.payload;
      for (let key in obj) {
        state[key] = obj[key];
      }
    },
    setOrganizationFields: (state, action) => {
      let field = action.payload;
      state.organizationFields = {
        ...state.organizationFields,
        ...field,
      };
    },
  },
});

export const { setSideBarState, setOrganizationFields } = sideBarSlice.actions;
export default sideBarSlice.reducer;
