export const CalculationType = {
    // Disabled: 0,
    PercentageOfCTC: 1,
    PercentageOfBasic: 2,
    FlatAmount: 3,
    CustomFormula: 4,
    PercentageOfGross: 5
}

export const calculationTypeLabels = {
  [CalculationType.PercentageOfCTC]: 'Percentage of CTC',
  [CalculationType.PercentageOfBasic]: 'Percentage of Basic',
  [CalculationType.FlatAmount]: 'Flat Amount',
  [CalculationType.CustomFormula]: 'Custom Formula',
  [CalculationType.PercentageOfGross]: 'Percentage of Gross'
};
