const InputBoxContainer = ({ className, children, props }) => {
  return (
    <>
      <div
        {...props}
        className={`${className} rounded-md p-4 min-h-[18rem] min-[768px]:max-[1030px]:min-h-[13.5rem] border-[1px] border-[#b4c2d5] bg-[white] mt-4`}>
        {children}
      </div>
    </>
  );
};

export default InputBoxContainer;
