import React, { memo, useRef, useEffect } from "react";
import { DateRange } from "react-date-range";
import { formattedDate } from "../../../utils/common";
import { LeftArrowSvg } from "../../../media/LeftArrowSvg";
import { RightArrowSvg } from "../../../media/RightArrowSvg";
// Please read comment for using this component
/* 
0 for dateRangePicker
1 for normal datePicker
*/
const DatePicker = ({
  options = null,
  _propsState = null,
  _propsFunc = null,
}) => {
  const { type, showRangeBtns, _position } = options;
  if (type === 0) {
    return (
      // All DateRangePicker code will be inside this component
      <DateRangePicker
        propsState={_propsState}
        propsFunc={_propsFunc}
        showRangeBtns={showRangeBtns}
        _position={_position}
      />
    );
  } else if (type === 1) {
    <NormalDatePicker />;
  }
  // rest of the different pickers can be write down here
  return null;
};

export default memo(DatePicker);

/* eslint-disable react/display-name */
const DateRangePicker = memo(
  ({ propsState, propsFunc, showRangeBtns, _position }) => {
    const { dateRange, isOpen } = propsState; // your passed props state
    const {
      handleDateRange,
      handleDateRangePicker,
      onDatePickerClickOutside,
      onFocusDateRangePickerHandler,
      handleForward,
      handleBackward,
    } = propsFunc; // your passed function in props will be destructured here
    const dateRangeRef = useRef(null); // this ref is used for closing date range picker dropdown when we click on outside

    let position = "";
    if (_position) {
      position = _position;
    }

    let _class = "grid gap-2 grid-cols-[.7fr_.7fr]";
    if (showRangeBtns) {
      _class = "grid gap-2 grid-cols-[.1fr_.7fr_.7fr_.1fr]";
    }

    useEffect(() => {
      document.addEventListener("click", handleDateRangePickerClickOutside); // this function is used for closing drop down when clicked outside from dropdown
      return () =>
        document.removeEventListener(
          "click",
          handleDateRangePickerClickOutside
        );
    }, [
      isOpen,
      dateRange[0].startDate.toISOString(),
      dateRange[0].endDate.toISOString(),
    ]);

    const handleDateRangePickerClickOutside = (event) => {
      if (
        dateRangeRef.current &&
        !dateRangeRef.current.contains(event.target)
      ) {
        if (isOpen) {
          onDatePickerClickOutside();
        }
      }
    };

    return (
      <>
        <div ref={dateRangeRef} className={`${position}`}>
          <section className={_class}>
            {showRangeBtns === true && (
              <div className="flex gap-[7px] items-end pb-[11px]">
                <LeftArrowSvg onClick={handleBackward} />
              </div>
            )}
            <RangeText
              _props={{
                id: "fromdate",
                value: formattedDate(dateRange[0].startDate),
                placeholder: "From Date",
                name: "fromDate",
                txt: "From Date",
                showRangeBtns: showRangeBtns,
              }}
              handleDateRangePicker={handleDateRangePicker}
              onFocusDateRangePickerHandler={onFocusDateRangePickerHandler}
            />
            <RangeText
              _props={{
                id: "todate",
                value: formattedDate(dateRange[0].endDate),
                placeholder: "To Date",
                name: "toDate",
                txt: "To Date",
                showRangeBtns: showRangeBtns,
              }}
              handleDateRangePicker={handleDateRangePicker}
              onFocusDateRangePickerHandler={onFocusDateRangePickerHandler}
            />
            {showRangeBtns === true && (
              <div className="flex gap-[7px] items-end pb-[11px]">
                <RightArrowSvg onClick={handleForward} />
              </div>
            )}
          </section>
          {isOpen && (
            <div
              className={`absolute rounded-md z-[1] ${
                _position ? "right-[0rem]" : ""
              }`}
              data-visible
            >
              <DateRange
                editableDateInputs={true}
                onChange={handleDateRange}
                moveRangeOnFirstSelection={false}
                ranges={dateRange} // if we are passing state from props then use props state else use default local state
                className="p-[.5rem] rounded-md"
                showSelectionPreview={true}
                months={2}
                direction="horizontal"
              />
            </div>
          )}
        </div>
      </>
    );
  }
);

const RangeText = memo(
  ({ _props, handleDateRangePicker, onFocusDateRangePickerHandler }) => {
    const { id, txt, name, placeholder, value } = _props;

    return (
      <>
        <div className="relative">
          <label
            htmlFor={id}
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            {txt} <span className="text-red-700">*</span>
          </label>
          <div onClick={onFocusDateRangePickerHandler}>
            <input
              type="text"
              name={name}
              id={id}
              className="bg-gray-50 cursor-pointer border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-thynkwebPrimary-600 focus:border-thynkwebPrimary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-thynkwebPrimary-500 dark:focus:border-thynkwebPrimary-500"
              placeholder={placeholder}
              required
              value={value}
              onChange={handleDateRangePicker}
            />
            <svg
              className="w-[14px] h-[14px] text-gray-800 dark:text-white absolute top-[41px] right-[11px]"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 20 20"
              onClick={onFocusDateRangePickerHandler}
            >
              <path
                fill="currentColor"
                d="M6 1a1 1 0 0 0-2 0h2ZM4 4a1 1 0 0 0 2 0H4Zm7-3a1 1 0 1 0-2 0h2ZM9 4a1 1 0 1 0 2 0H9Zm7-3a1 1 0 1 0-2 0h2Zm-2 3a1 1 0 1 0 2 0h-2ZM1 6a1 1 0 0 0 0 2V6Zm18 2a1 1 0 1 0 0-2v2ZM5 11v-1H4v1h1Zm0 .01H4v1h1v-1Zm.01 0v1h1v-1h-1Zm0-.01h1v-1h-1v1ZM10 11v-1H9v1h1Zm0 .01H9v1h1v-1Zm.01 0v1h1v-1h-1Zm0-.01h1v-1h-1v1ZM10 15v-1H9v1h1Zm0 .01H9v1h1v-1Zm.01 0v1h1v-1h-1Zm0-.01h1v-1h-1v1ZM15 15v-1h-1v1h1Zm0 .01h-1v1h1v-1Zm.01 0v1h1v-1h-1Zm0-.01h1v-1h-1v1ZM15 11v-1h-1v1h1Zm0 .01h-1v1h1v-1Zm.01 0v1h1v-1h-1Zm0-.01h1v-1h-1v1ZM5 15v-1H4v1h1Zm0 .01H4v1h1v-1Zm.01 0v1h1v-1h-1Zm0-.01h1v-1h-1v1ZM2 4h16V2H2v2Zm16 0h2a2 2 0 0 0-2-2v2Zm0 0v14h2V4h-2Zm0 14v2a2 2 0 0 0 2-2h-2Zm0 0H2v2h16v-2ZM2 18H0a2 2 0 0 0 2 2v-2Zm0 0V4H0v14h2ZM2 4V2a2 2 0 0 0-2 2h2Zm2-3v3h2V1H4Zm5 0v3h2V1H9Zm5 0v3h2V1h-2ZM1 8h18V6H1v2Zm3 3v.01h2V11H4Zm1 1.01h.01v-2H5v2Zm1.01-1V11h-2v.01h2Zm-1-1.01H5v2h.01v-2ZM9 11v.01h2V11H9Zm1 1.01h.01v-2H10v2Zm1.01-1V11h-2v.01h2Zm-1-1.01H10v2h.01v-2ZM9 15v.01h2V15H9Zm1 1.01h.01v-2H10v2Zm1.01-1V15h-2v.01h2Zm-1-1.01H10v2h.01v-2ZM14 15v.01h2V15h-2Zm1 1.01h.01v-2H15v2Zm1.01-1V15h-2v.01h2Zm-1-1.01H15v2h.01v-2ZM14 11v.01h2V11h-2Zm1 1.01h.01v-2H15v2Zm1.01-1V11h-2v.01h2Zm-1-1.01H15v2h.01v-2ZM4 15v.01h2V15H4Zm1 1.01h.01v-2H5v2Zm1.01-1V15h-2v.01h2Zm-1-1.01H5v2h.01v-2Z"
              />
            </svg>
          </div>

          
        </div>
      </>
    );
  }
);

const NormalDatePicker = memo(() => {
  return <>Picker</>;
});
