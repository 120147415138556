import { useSearchParams } from "react-router-dom";
import { useContext, useEffect, useMemo, useState, useRef } from "react";
import AsyncSelect from "react-select/async";
import moment from "moment";

import NotFound from "./NotFound";
import PaySlipDetails from "./PaySlipDetails";
import PageSection from "../Layout/PageSection/PageSection";
import InputFields from "../common/Input/InputFields";
import DownloadBtn from "./DownloadBtn";
import { Label } from "flowbite-react";

import { customStyles } from "../Settings/helper/helper";
import { downloadPaySlip, getPaySlip } from "../../services/PaySlipService";
import { GetUserById, GetUserByParam } from "../../services/UserService";
import { AuthContext } from "../../context/AuthContext";
import debounce from "lodash.debounce";
import { Role } from "./../../enum/Role";
import { CiMoneyCheck1 } from "react-icons/ci";

const MONTH_INPUT = {
  id: "month",
  label: "Month:",
  type: "month",
  name: "month",
  placeholder: "Select Month",
  required: false,
};

const date = new Date();
const currentMonth = moment(`${date}`).format("YYYY-MM");

/**
 * Generate the function comment for the given function body.
 *
 * @return {undefined}
 */
export const PaySlips = () => {
  const [searchParam, setSearchParams] = useSearchParams();
  // Getting the login user id from the local storage
  const { user } = useContext(AuthContext);
  const [userData, setUserData] = useState();

  // To handle the input from the async react select for user
  const [inputValue, setInputValue] = useState("");
  const [selectedUserID, setSelectedUserID] = useState(user?.id);
  const [selectedInputValue, setSelectedInputValue] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(currentMonth);

  // To show 404 message if in screen is payslip did not found
  const [showPaySlip404, setShowPaySlip404] = useState(false);
  const [paySlipData, setPaySlipData] = useState();
  const [showPaySlip, setShowPaySlip] = useState(false);
  const [pdfInfo, setPdfInfo] = useState({
    pdfBlob: null,
    error: null,
  });

  const memoizedUserData = useMemo(() => userData, [userData?.id]);
  const memoizedPaySlipData = useMemo(() => paySlipData, [paySlipData]);
  const memoizedMonths = useMemo(() => selectedMonth, [selectedMonth]);

  useEffect(() => {
    const month = searchParam.get("month");
    const year = searchParam.get("year");

    if (selectedMonth && !month && !year) {
      const [year, month] = selectedMonth.split("-");
      fetchUserById(selectedUserID);
      setSelectedMonth(selectedMonth);
      fetchPaySlip(selectedUserID, month, year);
      setSelectedInputValue(user);
    }
  }, []);

  useEffect(() => {
    const month = searchParam.get("month");
    const year = searchParam.get("year");

    const YEAR_MONTH = `${year}-${month - 1 > 9 ? month : `0${month}`}`;

    if (month && year) {
      setSelectedMonth(YEAR_MONTH);
      fetchPaySlip(user.id, month, year);
    }
  }, [searchParam.get("month"), searchParam.get("year")]);

  // Fetching the paySlip on selected month
  const fetchPaySlip = async function (id, month, year) {
    const res = await getPaySlip(id, month, year);

    if (!res) {
      setShowPaySlip404(true);
      setShowPaySlip(false);
    }
    else {
      setPaySlipData(res.data);
      setShowPaySlip(true);
      setShowPaySlip404(false);
      const resDownload = await downloadPaySlip(id, month, year);
      setPdfInfo(resDownload);
    }
  };

  // Function to fetch the user by id
  const fetchUserById = async id => {
    const res = await GetUserById(id);
    setUserData(res?.data);
  };

  // Function to handle the selected month selection
  const handleMonthChange = async e => {
    const selectedMonth = e.target.value;

    const [year, month] = selectedMonth.split("-");
    setSelectedMonth(selectedMonth);

    //if user is selected then fetch payslip
    if (Object.keys(selectedInputValue).length === 0) return;
    await fetchPaySlip(selectedUserID, month, year);
  };

  // To handle the change in the user-dropdown select
  const handleSelectedInputUser = async value => {
    //if there's no value is selected simply return
    if (!value) return;

    const [year, month] = selectedMonth.split("-");
    await fetchUserById(value?.id);
    await fetchPaySlip(value?.id, month, year);
    setSelectedInputValue(value);
    setSelectedUserID(value?.id);
  };

  // Function to handle the promise of the async select to show values in dropdown
  const debouncePromiseOptions = useRef(
    debounce((inputValue, callback) => {
      if (inputValue && inputValue.trim() !== "") {
        GetUserByParam(inputValue).then(res => {
          callback(res.data);
          return res.data;
        });
      } else {
        callback([]);
      }
    }, 300)
  ).current;

  return (
    <PageSection
      showIcon={true}
      icon={<CiMoneyCheck1 size={"2rem"} />}
      heading="Payslips">
      <div className="bg-white rounded">
        <div className="p-6 space-y-3 md:space-y-0">
          {/* Input box to select month  */}
          <div
            style={{ gridTemplateColumns: "repeat(auto-fit, minmax(15rem, 1fr))" }}
            className="grid gap-4 mb-4 w-[100%] sm:w-[60%] sm:mx-auto lg:mx-0 sm:items-center md:gap-6 sm:mb-8">
            <InputFields
              InputField={MONTH_INPUT}
              onChange={handleMonthChange}
              value={selectedMonth}
              inputClassName="block w-full p-2.5"
            />

            {user?.roleId === Role.Administrator && (
              <div>
                <Label
                  htmlFor="username"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                  Select User:
                </Label>

                <AsyncSelect
                  cacheOptions
                  defaultOptions
                  id="username"
                  value={selectedInputValue || ""}
                  getOptionLabel={e => `${e.firstName} ${e.lastName}`}
                  getOptionValue={e => e.id}
                  menuIsOpen={!!inputValue}
                  placeholder="Enter the employee's name"
                  loadOptions={debouncePromiseOptions}
                  onChange={handleSelectedInputUser}
                  onInputChange={setInputValue}
                  noOptionsMessage={() => (inputValue ? "No Employee Found" : null)}
                  filterOption={() => true}
                  styles={customStyles()}
                />
              </div>
            )}
          </div>

          {/* To make download file name will be in format = Payslip_Admin_last_March-2023 */}

          {showPaySlip && pdfInfo.pdfBlob && (
            <DownloadBtn
              userData={userData}
              selectedMonth={selectedMonth}
              selectedUserID={selectedUserID}
              showPaySlip={showPaySlip}
              pdfData={pdfInfo}
            />
          )}
        </div>
        <hr />

        <div className="overflow-x-auto h-[40rem] w-[90%] mx-auto mt-10">
          {/* Display the payslip if user payslip for selected month exist  */}
          {showPaySlip && (
            <PaySlipDetails
              userData={memoizedUserData}
              paySlipData={memoizedPaySlipData}
              selectedMonth={memoizedMonths}
              pdfData={pdfInfo}
            />
          )}
          {/* Display Error message if payslip for selected month doesn't exist */}
          {showPaySlip404 && <NotFound />}
        </div>
      </div>
    </PageSection>
  );
};
