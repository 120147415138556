import { axiosInstance as axios } from "../context/AuthContext";

const invoiceBaseUrl = `${process.env.REACT_APP_BASE_URL}/Invoices`;

export const getInvoices = async (pageNo, pageSize) => {
  const response = await axios
    .get(`${invoiceBaseUrl}?pageNumber=${pageNo}&pageSize=${pageSize}`)
    .then(response => {
      return response.data;
    });
  return response;
};

export const getInvoiceById = async id => {
  const response = await axios.get(`${invoiceBaseUrl}/${id}`).then(response => {
    return response.data;
  });
  return response;
};

//Getting invoice number
export const getInvoiceNumber = async () => {
  const response = await axios.get(`${invoiceBaseUrl}/Analytics`).then(response => {
    return response.data;
  });
  return response;
};

//Validate Invoice Number
export const validateInvoiceNumber = async (invoiceNumber, invoiceId) => {
  if (invoiceNumber && invoiceId) {
    const res = await axios.get(
      `${invoiceBaseUrl}/validate/${invoiceNumber}?invoiceId=${invoiceId}`
    );
    return res.data;
  }
  const res = await axios.get(`${invoiceBaseUrl}/validate/${invoiceNumber}`);
  return res.data;
};

export const deleteInvoice = async id => {
  const response = await axios.delete(`${invoiceBaseUrl}?id=${id}`);
  return response.data;
};

//creating invoice
export const addInvoice = async invoiceData => {
  return await axios({
    url: invoiceBaseUrl,
    method: "POST",
    data: JSON.stringify(invoiceData),
  });
};

//Edit data
export const updateInvoices = async invoiceData => {
  const response = await axios.put(`${invoiceBaseUrl}`, invoiceData);
};

//Delete invoice items by invoiceItemId
export const deleteInvoiceItemsById = async id => {
  const response = await axios.delete(`${invoiceBaseUrl}/invoiceitem/${id}`).then(response => {
    return response;
  });
  return response;
};

//Download invoice by invoice id
export const downloadInvoice = async (id, businessName, clientName, invoiceNumber) => {
  await axios({
    url: `${invoiceBaseUrl}/${id}/download`,
    // Blob is a ways which convert the response data make the file downloadable
    responseType: "blob",
  }).then(response => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute(
      "download",
      `invoice-${invoiceNumber}-${businessName.toLowerCase().split(" ").join("-")}-${clientName
        .toLowerCase()
        .split(" ")
        .join("-")}.pdf`
    );
    document.body.appendChild(link);
    link.click();
  });
};

//Create Header field name for the invoice
export const addCustomField = async customField => {
  const response = await axios.post(`${invoiceBaseUrl}/customfields`, customField);
  return response.data;
};

//Get custom fields
export const getCustomFields = async (groupId, invoiceId) => {
  let res;
  if (invoiceId)
    res = await axios.get(
      `${invoiceBaseUrl}/customfields?groupId=${groupId}&invoiceId=${invoiceId}`
    );
  else res = await axios.get(`${invoiceBaseUrl}/customfields?groupId=${groupId}`);
  return res.data;
};

//Update custom fields
export const updateCustomFields = async (fieldDetails, isHeader = false) => {
  const user = JSON.parse(sessionStorage.getItem("user"));

  //add fetch method for header update to avoid showing loading spinner when header updates
  if (isHeader) {
    await fetch(`${invoiceBaseUrl}/customfields`, {
      method: "PUT",
      body: JSON.stringify(fieldDetails),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user?.token}`,
      },
    });
    return;
  }

  await axios.put(`${invoiceBaseUrl}/customfields`, fieldDetails);
};

/**
 * Deletes custom fields based on the provided field ID and invoice ID.
 *
 * @param {number} fieldId - The ID of the custom field to be deleted.
 * @return {Promise<any>} A promise that resolves with the response data from the server.
 */
export const deleteCustomFields = async fieldId => {
  const deleteURL = `${invoiceBaseUrl}/customfields?fieldId=${fieldId}`;
  let res;
  if (fieldId) res = await axios.delete(`${deleteURL}`);
  return res?.data;
};

//Custom fields association services
export const deleteAssociatedCustomField = async function (associatedId, invoiceId) {
  const deleteURL = `${invoiceBaseUrl}/customfields/associations?associationId=${associatedId}&invoiceId=${invoiceId}`;
  const res = await axios.delete(deleteURL);
  return res.data;
};

export const updateAssociatedCustomField = async fieldDetails => {
  const res = await axios.put(`${invoiceBaseUrl}/customfields/associations`, fieldDetails);
  return res.data;
};
