export const AttendanceSettings = {
  FirstCheckInLastCheckOut: 1,
  EveryValidCheckInCheckOut: 2,
};

export const MinHoursRequiredMode = {
  StrictMode: 1,
  LenientMode: 2,
};

export const MinHoursInputType = {
  ManualInput: 1,
  ShiftHours: 2,
};

export const RegularizationEntryType = {
  CreateNewEntry: 1,
  ReplaceExistingEntry: 2,
};
