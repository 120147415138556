import { useEffect, useState } from "react";
import moment from "moment";

import ModalComponent from "./../common/Modal/Modal";
import ModalButtons from "../common/Modal/ModalButtons";
import Radio from "../common/Radio/Radio";
import { showToast } from "../common/Toast";

import { getHolidayById, updateHolidays } from "../../services/CommonService";
import { holidayColors, holidayIcons, tw_styles } from "./helper";

const initialState = {
  eventTitle: "",
  startDate: "",
  endDate: "",
  description: "",
  status: "",
  holidayDate: "",
  isFlexi: false,
};

const UpdateHoliday = ({
  holidayId,
  setToggleUpdateHoliday,
  toggleUpdateHoliday,
  isUpdateHolidayId,
  setIsUpdateHolidayId,
  setIsChange,
  setSelectedCheckedbox,
}) => {
  const [holidayDetails, setHolidayDetails] = useState(initialState);
  const formattedDate = moment(`${holidayDetails?.holidayDate}Z`).format("YYYY-MM-DD");

  //State for toggling holiday icon and color
  const [holidayIconAndIconColorToggle, setHolidayIconAndIconColorToggle] = useState({
    isIconToggle: false,
    isIconColorToggle: false,
  });

  //State for selecting and storing values for holiday icon and color
  const [selectedHolidayIconAndIconColor, setSelectedHolidayIconAndIconColor] = useState({
    selectedIcon: {},
    selectedIconColor: {},
  });

  //Getting Holiday details by Id
  const GetHolidayDetailsById = id => {
    getHolidayById(id).then(res => {
      const { data } = res?.data;
      const holidayDetails = {
        ...data,
      };
      const { icon, color } = data;
      const iconDetails = holidayIcons.filter(iconObject => {
        if (iconObject.iconName === icon) {
          return iconObject;
        }
      });
      setSelectedHolidayIconAndIconColor(prevSHIAIC => {
        return {
          ...prevSHIAIC,
          selectedIcon: iconDetails[0],
          selectedIconColor: {
            ...prevSHIAIC.selectedIconColor,
            color: color,
          },
        };
      });

      setHolidayDetails(holidayDetails);
      setIsUpdateHolidayId(false);
    });
  };

  useEffect(() => {
    if (holidayId) {
      GetHolidayDetailsById(holidayId);
    }
  }, [isUpdateHolidayId]);

  //Getting form values for updating Holidays
  const onChangeUpdateHolidays = e => {
    let { name, value } = e.target;

    setHolidayDetails(prevHD => {
      if (name === "isFlexi") value = !prevHD.isFlexi;
      return { ...prevHD, [name]: value };
    });
  };

  const onModalCloseHandler = () => {
    setSelectedCheckedbox({
      allChecked: false,
      checkedItems: [],
    });

    setToggleUpdateHoliday(false);
    setHolidayDetails(initialState);
  };

  //  Submitting update Holidays
  const onSubmitUpdateHolidays = async event => {
    event.preventDefault();

    if (
      holidayDetails.eventTitle.trim() === "" ||
      holidayDetails.startDate === "" ||
      holidayDetails.endDate === ""
    ) {
      return showToast("warn", "All fields are Mandatory!");
    }

    const date = new Date(); // Assuming you want to include the current date

    const { toastId, toast } = showToast("loading", "Updating Holidays...");

    // Assuming `updateHolidays` returns a Promise
    await updateHolidays({
      ...holidayDetails,
      date,
      status: +holidayDetails.status,
      icon: selectedHolidayIconAndIconColor.selectedIcon.iconName,
      colorCode: selectedHolidayIconAndIconColor.selectedIconColor.color,
    });

    toast.dismiss(toastId);
    showToast("success", "Holidays Updated Successfully!");

    // Closes the modal, resets state, and signals a change.
    onModalCloseHandler();
    setIsChange(true);
  };

  const onChangeRadioHandler = e => {
    const { value } = e.target;
    if (value === "flexiHoliday") {
      setHolidayDetails(prevHD => {
        return {
          ...prevHD,
          isFlexi: true,
        };
      });
    } else {
      setHolidayDetails(prevHD => {
        return {
          ...prevHD,
          isFlexi: false,
        };
      });
    }
  };

  // Holiday icon and icon color section
  //Toggling icon and icon color
  const onToggleIconAndIconColor = toggleOption => {
    if (toggleOption === "toggleIcon") {
      setHolidayIconAndIconColorToggle(prevHIAICT => {
        return {
          ...prevHIAICT,
          isIconToggle: !prevHIAICT.isIconToggle,
          isIconColorToggle: false,
        };
      });
      return;
    }
    if (toggleOption === "toggleIconColor") {
      setHolidayIconAndIconColorToggle(prevHIAICT => {
        return {
          ...prevHIAICT,
          isIconColorToggle: !prevHIAICT.isIconColorToggle,
          isIconToggle: false,
        };
      });
    }
  };

  //Storing icon and iconColors
  const onSubmitIconAndIconColor = (selectedItem, selectedItemId) => {
    if (selectedItem === "icon") {
      const currentIcon = holidayIcons.filter(icon => {
        return selectedItemId === icon.id;
      });
      setSelectedHolidayIconAndIconColor(prevSHIAIC => {
        return {
          ...prevSHIAIC,
          selectedIcon: currentIcon[0],
        };
      });
      setHolidayIconAndIconColorToggle(prevHIAICT => {
        return {
          ...prevHIAICT,
          isIconToggle: false,
        };
      });
      return;
    }

    if (selectedItem === "iconColor") {
      const currentColor = holidayColors.filter(color => {
        return selectedItemId === color.id;
      });

      setSelectedHolidayIconAndIconColor(prevSHIAIC => {
        return {
          ...prevSHIAIC,
          selectedIconColor: currentColor[0],
        };
      });

      setHolidayIconAndIconColorToggle(prevHIAICT => {
        return {
          ...prevHIAICT,
          isIconColorToggle: false,
        };
      });
      return;
    }
  };

  return (
    <ModalComponent
      show={toggleUpdateHoliday}
      heading="Edit Holiday"
      showFooter={false}
      onClose={onModalCloseHandler}>
      <form className="mx-auto max-w-2xl p-4 m-auto overflow-x-hidden overflow-y-auto">
        <div className="grid gap-4 mb-4 md:gap-6 md:grid-cols-2 sm:mb-8">
          {/* Event Title */}
          <div>
            <label
              htmlFor="event-title"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
              Holiday Name <span className="text-red-700">*</span>
            </label>

            <input
              type="text"
              name="eventTitle"
              id="event-title"
              value={holidayDetails?.eventTitle || ""}
              onChange={onChangeUpdateHolidays}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-thynkwebPrimary-600 focus:border-thynkwebPrimary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-thynkwebPrimary-500 dark:focus:border-thynkwebPrimary-500"
              placeholder="Enter Name"
              required={true}
            />
          </div>

          {/* Holiday Icons and color */}
          <div className=" relative">
            <div>
              <label
                htmlFor="holidayIcon"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                Holiday Icon <span className="text-red-700"></span>
              </label>
            </div>
            {/* icon and iconColor section*/}
            <div className="icon-iconColor-container  md:h-[60%] h-[70%] flex relative">
              {/* icon section*/}
              <div className="icon-container h-full">
                <div
                  className={` w-[2.5rem] h-full cursor-pointer ${tw_styles} !p-0 !m-0 flex justify-center items-center`}
                  onClick={() => {
                    onToggleIconAndIconColor("toggleIcon");
                  }}>
                  {selectedHolidayIconAndIconColor.selectedIcon?.iconComponent}
                </div>
                {holidayIconAndIconColorToggle.isIconToggle && (
                  <div className="border w-full min-h-[5.6rem] absolute bg-slate-50 z-10 flex gap-2 flex-wrap justify-between box-border p-2 ">
                    {holidayIcons.map(item => {
                      return (
                        <span
                          key={item?.id}
                          onClick={onSubmitIconAndIconColor.bind(null, "icon", item?.id)}
                          className={`p-[0.4rem] border w-fit h-fit box-border cursor-pointer hover:bg-slate-300 ${
                            selectedHolidayIconAndIconColor.selectedIcon?.id === item?.id &&
                            "bg-slate-300"
                          }`}>
                          {item?.iconComponent}
                        </span>
                      );
                    })}
                  </div>
                )}
              </div>
              {/* iconColor section*/}
              <div className="color-container flex justify-center items-center">
                <div className=" w-8 h-8 ml-0 flex justify-center items-center relative">
                  <div
                    className={`border w-[57%] h-[57%] rounded-sm`}
                    style={{
                      backgroundColor: selectedHolidayIconAndIconColor?.selectedIconColor.color,
                    }}
                    onClick={onToggleIconAndIconColor.bind(null, "toggleIconColor")}></div>

                  {holidayIconAndIconColorToggle.isIconColorToggle && (
                    <div className="border absolute top-8 left-[0.45rem] w-[9.8rem] min-h-[5rem] flex flex-wrap  gap-2 z-10 p-2 bg-slate-50">
                      {holidayColors.map(item => {
                        return (
                          <div
                            key={item?.id}
                            className={`border w-4 h-4 rounded-sm cursor-pointer`}
                            style={{ backgroundColor: item?.color }}
                            onClick={onSubmitIconAndIconColor.bind(
                              null,
                              "iconColor",
                              item?.id
                            )}></div>
                        );
                      })}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          {/* Holiday Date */}
          <div>
            <label
              htmlFor="holiday-date"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
              Holiday Date <span className="text-red-700">*</span>
            </label>
            <input
              type="date"
              name="holidayDate"
              id="holiday-date"
              value={formattedDate || ""}
              onChange={onChangeUpdateHolidays}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-thynkwebPrimary-600 focus:border-thynkwebPrimary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-thynkwebPrimary-500 dark:focus:border-thynkwebPrimary-500"
              placeholder="Enter leaves allowed in year"
              required
            />
          </div>

          {/* Holiday Description */}
          <div>
            <label
              htmlFor="description"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
              Description
            </label>
            <textarea
              type="text"
              name="description"
              id="description"
              value={holidayDetails?.description || ""}
              onChange={onChangeUpdateHolidays}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-thynkwebPrimary-600 focus:border-thynkwebPrimary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-thynkwebPrimary-500 dark:focus:border-thynkwebPrimary-500"
              placeholder="Enter description"
              required
            />
          </div>

          {/* Holiday Status */}
          <div>
            <label
              htmlFor="status"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
              Holiday Status <span className="text-red-700">*</span>
            </label>
            <select
              name="status"
              id="status"
              value={holidayDetails?.status || ""}
              onChange={onChangeUpdateHolidays}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-thynkwebPrimary-600 focus:border-thynkwebPrimary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-thynkwebPrimary-500 dark:focus:border-thynkwebPrimary-500">
              <option value={1}>Published</option>
              <option value={2}>Unpublished</option>
            </select>
          </div>
        </div>

        <fieldset className="flex items-center gap-3">
          <Radio
            id="normal"
            name="radioBtn"
            value="normalHoliday"
            checked={!holidayDetails.isFlexi}
            onChange={onChangeRadioHandler}
            parentClass="!gap-2"
            label="Normal Holiday"
            labelClass="!cursor-pointer !font-medium"
          />
          <Radio
            id="flexiHoliday"
            name="radioBtn"
            value="flexiHoliday"
            checked={holidayDetails.isFlexi}
            onChange={onChangeRadioHandler}
            parentClass="!gap-2"
            label="Flexi Holiday"
            labelClass="!cursor-pointer !font-medium"
          />
        </fieldset>

        {/* Button to add Holiday */}
        <ModalButtons
          isActionDeleteBtn={false}
          onClickAction={onSubmitUpdateHolidays}
          onClickCancel={onModalCloseHandler}
          actionBtnText="Update"
          cancelBtnText="Cancel"
        />
      </form>
    </ModalComponent>
  );
};

export default UpdateHoliday;
