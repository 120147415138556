import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    id: "statutory",
    statutoryToggleDetails: {
        //toggle details for EPF component
        isEpfEnabled: false,
        epfToggle: false,
        toggleStatutoryModal: false,
        toggleEpfDeleteModal: false,
        toggleUpdateStatutoryModal: false,

        //toggle details for ESI component
        isEsiEnabled: false,
        toggleEsiModal: false,
        toggleUpdateEsiModal: false,
        toggleEsiDeleteModal: false,

        //toggle details for ProfessionalTax component
        isProfessionalTaxEnabled: false,
        toggleProfessionalTaxModal: false,
        toggleUpdateProfessionalTaxModal: false,
        toggleProfessionalTaxDeleteModal: false


    },

    statutoryEpfDetails: {
        number: "",
        employeeContributionRate: 12,
        employeeContributionLimit: "",
        employerContributionRate: 12,
        employerContributionLimit: "",
        includeEmployersContributionInCtc: true,
        proRatePfWage: false,
        considerAllSalaryComponents: true
    },

    statutoryEsiDetails: {
        number: "",
        employeeContributionRate: 0.75,
        employerContributionRate: 3.25,
        includeEmployersContributionInCtc: false
    },
    statutoryProfessionalTaxDetails: {
        employeeContributionRate: 0
    }

}

const statutorySlice = createSlice({
    name: "statutory",
    initialState,
    reducers: {
        setStatutoryToggleDetails: (state, action) => {
            state.statutoryToggleDetails = action.payload
        },

        setStatutoryEpfDetails: (state, action) => {
            state.statutoryEpfDetails = action.payload
        },

        setStatutoryEsiDetails: (state, action) => {
            state.statutoryEsiDetails = action.payload
        },
        setStatutoryProfessionalTaxDetails: (state, action) => {
            state.statutoryProfessionalTaxDetails = action.payload
        },

        resetStatutoryEpfDetails: (state) => {
            state.statutoryEpfDetails = initialState.statutoryEpfDetails;
        },
        resetStatutoryEsiDetails: (state) => {
            state.statutoryEsiDetails = initialState.statutoryEsiDetails
        },
        resetStatutoryProfessionalTaxDetails: (state) => {
            state.statutoryProfessionalTaxDetails = initialState.statutoryProfessionalTaxDetails
        },
        resetStatutoryState: () => initialState
    }
})


export const { setStatutoryToggleDetails, setStatutoryEpfDetails, setStatutoryEsiDetails, setStatutoryProfessionalTaxDetails, resetStatutoryEpfDetails, resetStatutoryState, resetStatutoryEsiDetails, resetStatutoryProfessionalTaxDetails } = statutorySlice.actions

export default statutorySlice.reducer