import React from "react";
import ModalButtons from "./ModalButtons";
import { cn } from "../../../utils/common";
import ModalComponent from "./Modal";
import { HiOutlineExclamationCircle } from "react-icons/hi";

/**
 * Renders a delete modal with the given configurations and message.
 * @param {{show: boolean, size: string, onClose: function, showFooter: boolean, otherProps: Object, footer: ReactNode, styles: {parentClass?: string, messageContClass?: string, iconClass?: string, messageClass?: string}}} props - The configurations for the modal
 *
 * @param {object} modalConfigs - The configurations for the modal
 * @param {Object} modalConfigs.otherProps - The other props for the delete modal are same as the tailwind modal components props.
 * @param {object} styles - The styles for the modal
 * @param {string} message - The message to be displayed in the modal
 * @param {function} onDelete - The function to be called when delete is confirmed
 * @param {function} onCancel - The function to be called when delete is canceled
 * @param {ReactNode} children - The child components to be rendered in the modal
 * @param {boolean} showIcon - Indicates whether to show an icon in the modal
 * @param {object} modalButtonConfigs - The configurations for the modal buttons
 * @return {JSX.Element} The delete modal component
 */
const DeleteModal = ({
  modalConfigs,
  styles,
  heading,
  message,
  onDelete,
  onCancel,
  children,
  showIcon,
  modalButtonConfigs,
}) => {
  const { show, size, onClose, showFooter, otherProps, footer } = modalConfigs;

  return (
    <ModalComponent
      show={show}
      size={size}
      bodyClassName={styles?.modalBodyClassName}
      className={styles?.modalClassName}
      showFooter={showFooter}
      footer={footer}
      onClose={onClose}
      heading={heading}
      otherProps={otherProps}>
      <div className={`${styles?.deleteModalContainer} text-center mt-4`}>
        {showIcon && (
          <HiOutlineExclamationCircle
            className={`mx-auto mb-4 h-14 w-14 text-gray-400 dark:text-gray-200 ${styles?.icon}`}
          />
        )}
        {children && children}
        {!children && (
          <>
            <h3
              className={`mb-5 text-lg font-normal text-gray-500 dark:text-gray-400 ${styles?.heading}`}>
              {message ? message : "Are you sure you want to delete this?"}
            </h3>
            <ModalButtons
              btnCont={cn("justify-end")}
              actionBtnText={"Yes, I'm sure"}
              cancelBtnText={"No, cancel"}
              onClickAction={onDelete}
              onClickCancel={onCancel}
              {...modalButtonConfigs}
            />
          </>
        )}
      </div>
    </ModalComponent>
  );
};

export default DeleteModal;
