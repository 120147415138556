const Label = ({ htmlFor, className, value }) => {
  return (
    <>
      <label
        className={`${className} underline decoration-dotted decoration-3 decoration-gray-400 underline-offset-8`}
        htmlFor={htmlFor}
        
      >
        {value}
      </label>
    </>
  );
};

export default Label;
