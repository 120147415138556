export const payrollState = {
  res: {},
  rows: [],
  previewModal: false,
  approveModal: false,
  recordModal: false,
  payslipModal: false,
  previewRowId: null,
  previewRowObj: {},
  changeApprove_Recordpayment: false,
  recordPaymentBody: {},
  payslipComponents: [],
  payslipDeductions: [],
  currentPayrollStatus: null,
  paidProgress: null,
  unpaidProgress: null,
  selectedEarningOptions: [],
  selectedDeductionOptions: [],
  summaryGrossSalaries: [],
  summaryNetSalaries: [],
  refetchPayroll: false,
  showReleasePayslipModal: false,
  withHeldPayslips: [],
  selectedWithHeldPayslipIds: [],
  currentPayslipId: 0,
};
