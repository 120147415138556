import { BsGear, BsFillGearFill } from "react-icons/bs";
const GearSvg = ({ size, className, color, fill }) => {
  return (
    <>
      {fill && (
        <BsFillGearFill
          size={size}
          className={className}
          color={color}
          fill="currentColor"
        />
      )}
      {!fill && (
        <BsGear
          size={size}
          className={className}
          color={color}
        />
      )}
    </>
  );
};

export default GearSvg;
