
const InfoIconSvg = ({className}) => {
  return (
    <svg
      version="1.1"
      x="0"
      y="0"
      width="16.000000pt"
      height="16.000000pt"
      viewBox="0 0 512 512"
      className={`${className} text-sm align-text-bottom`}
    >
      <path d="M256 3.7C116.7 3.7 3.8 116.7 3.8 256S116.7 508.3 256 508.3c139.3 0 252.2-112.9 252.2-252.3C508.2 116.7 395.3 3.7 256 3.7zm-4.9 400.4c-36.4 12.8-66.3-1.9-60.3-36.8s40.5-109.7 45.4-123.9c4.9-14.1-4.5-18-14.6-12.3-5.8 3.4-14.5 10.1-21.9 16.7-2.1-4.2-5-8.9-7.1-13.5 12.1-12.2 32.4-28.5 56.5-34.4 28.7-7.1 76.7 4.2 56.1 59.1-14.7 39.1-25.1 66.1-31.7 86.2-6.6 20.1 1.2 24.3 12.7 16.5 9-6.1 18.5-14.5 25.5-20.9 3.2 5.3 4.3 6.9 7.5 13-12.3 12.5-44.1 41.7-68.1 50.3zm75.3-241.9c-16.5 14-40.9 13.7-54.6-.7-13.7-14.4-11.4-37.5 5.1-51.5s40.9-13.7 54.6.7c13.7 14.3 11.4 37.4-5.1 51.5z"></path>
    </svg>
  );
};

export default InfoIconSvg;
