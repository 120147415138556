import React, { useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import { Role } from "../../enum/Role";
import LeftMenu from "../../components/Layout/LeftMenu/LeftMenu";
import { Outlet } from "react-router";
import { salaryComponentOptions } from "../../constants/SidebarOptions";

const SalaryComponentManagement = () => {
  //Getting user from context API
  const { user } = useContext(AuthContext);

  const isAdmin = user?.roleId === Role.Administrator;
  const isModerator = user?.roleId === Role.Moderator;
  return (
    <div className="flex">
      <div>{(isAdmin || isModerator) && <LeftMenu sidebarListItem={salaryComponentOptions} />}</div>
      <div className="w-full h-full">
        <Outlet />
      </div>
    </div>
  );
};

export default SalaryComponentManagement;
