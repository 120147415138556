import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { Avatar } from "flowbite-react";
import Notification from "../Notification/Notification";
import Settings from "../Settings/Settings";

import { ThynkWebLogoSvg } from "../../../media/ThynkWebLogoSvg";
import { AuthContext } from "../../../context/AuthContext";
import NotificationSvg from "./../../../media/NotificationSvg";
import {
  fetchNotificationCounts,
  fetchNotifications,
  markNotificationAsRead,
} from "../../../store/slices/NotificationSlice";
import { GetTenants } from "../../../services/OrganizationDetailsService";
import { STATUS_CODES } from "../../../constants/Constant";
import { Role } from "../../../../src/enum/Role";
import { setBrandDetails, setLogoDetails } from "../../../store/slices/logoBrandingSlice";
import GearSvg from "../../../media/GearSvg";
import { IoMdSettings } from "react-icons/io";
import { GiHamburgerMenu } from "react-icons/gi";
import { retrieveTenants } from "../../../services/AccountService";
import { setSettingState } from "../../../store/slices/settingsSlice";

/**
 * Renders the AuthHeader component.
 *
 * @param {object} openSidebarHandler - The handler for opening the sidebar.
 * @param {object} closeSidebarHandler - The handler for closing the sidebar.
 * @param {object} toggleSidebar - The function for toggling the sidebar.
 * @param {object} userInfoById - The user information by ID.
 * @return {JSX.Element} The rendered AuthHeader component.
 */
const AuthHeader = ({ openSidebarHandler, closeSidebarHandler, toggleSidebar, userInfoById }) => {
  const { user } = useContext(AuthContext);
  const isOnline = useSelector(state => state.onlineStatus.isOnline);
  const [isNotificationVisible, setIsNotificationVisible] = useState(false);
  const [showSettings, setShowSettings] = useState(false);
  const { notificationCount } = useSelector(state => state.notification);
  const { pageNo } = useSelector(state => state.pagination);
  const dispatch = useDispatch();
  const logoBranding = useSelector(state => state.logoBranding);

  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [clickedInsideMenu, setClickedInsideMenu] = useState(false);


    const handleClickOutsideMenu = () => {
      if (!clickedInsideMenu) {
        setIsMobileMenuOpen(false); 
      }
      setClickedInsideMenu(false); 
    };

  const toggleSettings = (value) => {
    setShowSettings(!showSettings, value);
  };


  const isAdmin = user?.roleId === Role.Administrator;
  const isModerator = user?.roleId === Role.Moderator;

   useEffect(() => {
    retrieveTenants()
      .then(res => {
        if(res.status === STATUS_CODES.SUCCESS) {
        const tenants = res.data.data;
        dispatch(setSettingState({ tenants }));

         user?.tenants.forEach(userTenant => {
           if (userTenant.userRole == Role.Administrator || userTenant.userRole == Role.Moderator) {
             dispatch(setSettingState({ hasAdminAccess: true }));
             return;
           }
         });
        }
        else{
          // Handle error when response status is not successful
          console.error(`Failed to retrieve tenants. Status code: ${res.status}`);
          // You can set an error state or display an error message here.
        }
      })
      .catch(e => {
        console.error(e);
      });
  }, []);


  useEffect(() => {
    dispatch(fetchNotificationCounts());
    dispatch(fetchNotifications(pageNo));
    geHeaderFontAndBackgroundColor();
  }, []);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(prevState => !prevState);
  };


  useEffect(() => {
    const toggleHandler = e => {
      const sidebar = e.target.closest("[data-sidebar]");
      const notification = e.target.closest("[data-type='notification']");
      const settings = e.target.closest("[data-type='settings']");

      if (!sidebar && notification) closeSidebarHandler();
      if (!notification && sidebar) toggleNotification(false);
      if (!settings && sidebar) setShowSettings(false);
      if (!sidebar && !notification && !settings) {
        toggleNotification(false);
        setShowSettings(false);
        closeSidebarHandler();
        return;
      }

      if (sidebar) {
        const dataset = sidebar.dataset.sidebar;
        if (
          dataset !== "openBtn" &&
          dataset !== "sidebar-item" &&
          dataset !== "sidebar" &&
          dataset !== "logout" &&
          dataset !== "closebtn" &&
          dataset !== "notification" &&
          dataset !== "humburger"
        ) {
          closeSidebarHandler();
          return;
        }

        if (dataset === "sidebar-item" || dataset === "logout" || dataset === "closebtn")
          return closeSidebarHandler();
        if (dataset === "openBtn") return openSidebarHandler();
      }

      if (notification) {
        setIsNotificationVisible(prevINV => !prevINV);
        setShowSettings(false);
        dispatch(markNotificationAsRead(notificationCount));
        return;
      }

      if (settings) {
        setIsNotificationVisible(false);
        setShowSettings(!showSettings);
        return;
      }
    };

    document.addEventListener("click", toggleHandler);

    return () => document.removeEventListener("click", toggleHandler);

  }, [toggleSidebar, notificationCount, showSettings, isMobileMenuOpen]);

  const toggleNotification = value => {
    setIsNotificationVisible(value);
  };

  //Getting header background color and font color
  const geHeaderFontAndBackgroundColor = async () => {
    const response = await GetTenants();
    if (response?.data?.statusCode === STATUS_CODES.SUCCESS) {
      const base64Details = response?.data?.data?.logoBase64;
      let imageSrc;
      if (base64Details) {
        imageSrc = `data:image/jpeg;base64,${base64Details}`;
      } else {
        imageSrc = null;
      }
      dispatch(
        setBrandDetails({
          headerBgColor: response?.data?.data?.headerBgColor,
          isHeaderFontLight: response?.data?.data?.isHeaderFontLight,
        })
      );
      dispatch(
        setLogoDetails({
          logoUrl: imageSrc,
        })
      );
    }
  };

  return (
    <>
      <header className="header__height  shadow-sm dark:bg-slate-900 sticky top-0 left-0 right-0 z-20">
        <nav
          className="relative w-full h-full flex items-center justify-between pl-3 !py-0 !pr-0"
          style={{ backgroundColor: logoBranding?.brandDetails?.headerBgColor }}>
          <div className="flex items-center gap-5">
            {logoBranding?.logoDetails?.logoUrl ? (
              <>
                <Link className="flex flex-row h-[3rem] inline-block" to="/">
                  <img
                    src={logoBranding?.logoDetails?.logoUrl}
                    alt="logo"
                    className="logo h-full object-cover"
                  />
                </Link>
              </>
            ) : (
              <>
                <Link className="flex flex-row" to="/">
                  <ThynkWebLogoSvg />
                  <span
                    className="self-center ml-3 whitespace-nowrap text-xl font-semibold dark:text-white hidden sm:flex"
                    style={{
                      color:
                        logoBranding?.brandDetails?.isHeaderFontLight === true
                          ? "#ffffff"
                          : "#000000",
                    }}>
                    Thynk Corp
                  </span>
                </Link>
              </>
            )}

            <div className="sm:flex gap-5">
              <Link
                className="font-semibold tracking-wide"
                to="/"
                style={{
                  color:
                    logoBranding?.brandDetails?.isHeaderFontLight === true ? "#ffffff" : "#000000",
                }}>
                Dashboard
              </Link>
              {(isAdmin || isModerator) && (
                <>
                  <Link
                    className="font-semibold tracking-wide"
                    to="/invoices/allInvoices"
                    style={{
                      color:
                        logoBranding?.brandDetails?.isHeaderFontLight === true
                          ? "#ffffff"
                          : "#000000",
                    }}>
                    Invoices
                  </Link>
                  <Link
                    className="font-semibold tracking-wide"
                    to="/payroll/payroll-run"
                    style={{
                      color:
                        logoBranding?.brandDetails?.isHeaderFontLight === true
                          ? "#ffffff"
                          : "#000000",
                    }}>
                    Payroll
                  </Link>
                </>
              )}
            </div>
          </div>

          <div className="flex md:order-2 justify-around items-center gap-4" id="navbar-default">
            <button
              data-type="notification"
              className={`px-2 py-[.5rem] rounded-md relative transition-colors duration-300 hover:${logoBranding?.brandDetails?.isHeaderFontLight === true ? "bg-gray-600" : "bg-white"}`}>
              <NotificationSvg
                size="24"
                color={
                  logoBranding?.brandDetails?.isHeaderFontLight === true ? "#ffffff" : "#000000"
                }
              />
              {notificationCount > 0 && (
                <div className="grid place-items-center absolute bg-[red] w-4 h-4 rounded-full top-1 right-[0.2rem] text-[.6rem] font-semibold tracking-wide text-white">
                  {notificationCount}
                </div>
              )}
            </button>
            {(isAdmin || isModerator) && (
              <button data-type="settings" className="tc-settings">
                <IoMdSettings size={"1.4rem"} />
              </button>
            )}
            <div data-sidebar="openBtn">
              <Avatar
                statusPosition="top-right"
                status={isOnline ? "online" : "offline"}
                rounded={true}
                placeholderInitials={`${user?.firstName && user?.firstName[0].toUpperCase()}${
                  user?.lastName && user?.lastName[0].toUpperCase()
                }`}
                className=" mx-2 z-auto cursor-pointer"
                img={
                  userInfoById?.imageBytes && `data:image/jpeg;base64,${userInfoById?.imageBytes}`
                }
              />
            </div>

            {/* hamburger menu */}
            <div
              onClick={toggleMobileMenu}
              data-type="humburger"
              data-collapse-toggle="navbar-sticky"
              className="inline-flex items-center w-10 h-10 justify-center text-md text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600 cursor-pointer"
              aria-controls="navbar-sticky"
              aria-expanded="false">
              <GiHamburgerMenu size={"1.4rem"} />
            </div>
          </div>
        </nav>

        {isNotificationVisible && <Notification />}
        {showSettings && <Settings />}

        {isMobileMenuOpen && (
          <div
            className="bg-white absolute top-14 left-0 right-0 z-30"
            onClick={() => setClickedInsideMenu(true)}>
            <ul className="flex flex-col p-4 md:p-0 mt-4 font-medium border border-gray-100 rounded-lg bg-gray-50 md:space-x-8 rtl:space-x-reverse md:flex-row md:mt-0 md:border-0 md:bg-white dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">
              <li>
                <Link
                  className="font-semibold tracking-wide block py-2 px-3 text-white rounded md:bg-transparent hover:bg-gray-200"
                  to="/"
                  style={{
                    color: logoBranding?.brandDetails?.isHeaderFontLight ? "#ffffff" : "#000000",
                  }}>
                  Dashboard
                </Link>
              </li>
              {(isAdmin || isModerator) && (
                <>
                  <li>
                    <Link
                      className="font-semibold tracking-wide block py-2 px-3 text-gray-900 rounded hover:bg-gray-200 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700"
                      to="/invoices/allInvoices"
                      style={{
                        color: logoBranding?.brandDetails?.isHeaderFontLight
                          ? "#ffffff"
                          : "#000000",
                      }}>
                      Invoices
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="font-semibold tracking-wide block py-2 px-3 text-gray-900 rounded hover:bg-gray-200 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700"
                      to="/payroll/payroll-run"
                      style={{
                        color: logoBranding?.brandDetails?.isHeaderFontLight
                          ? "#ffffff"
                          : "#000000",
                      }}>
                      Payroll
                    </Link>
                  </li>
                </>
              )}
            </ul>
          </div>
        )}
      </header>
    </>
  );
};

export default AuthHeader;
