import { useState } from "react";

/**
 * Generates a custom hook that manages the state of an active tab, Supposed to be use with the Tabs component.
 * `To create a custom handler setActiveTab can be use to set the active tab value`
 *
 * @param {string | number} initialValue - The initial value of the active tab.
 * @return {object} An object containing the active tab value, a function to set the active tab value, and a click event handler for the active tab.
 */
const useTabs = initialValue => {
  const [activeTab, setActiveTab] = useState(initialValue);

  const onClickActiveTabHandler = e => {
    const elem = e.target.closest("[data-tab]");
    if (!elem) return;
    const dataset = elem.dataset.tab;
    setActiveTab(dataset);
  };

  return {
    activeTab,
    setActiveTab,
    onClickActiveTabHandler,
  };
};

export default useTabs;
