import { FiMinusCircle } from "react-icons/fi";
const MinusSvg = ({ size, className, color }) => (
  <FiMinusCircle
    size={size}
    className={className}
    color={color}
  />
);

export default MinusSvg;
