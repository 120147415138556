import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";

import TableHeader from "../../../common/Table/TableHeader";
import ModalComponent from "../../../common/Modal/Modal";
import DocumentRows from "../DocumentRows";
import InfoIconSvg from "../../../../media/InfoIconSvg";

import { DocumentTableHeader } from "../../helper";
import { deleteFile, getUserFiles } from "../../../../store/slices/profileSlice";
import { base64ToFile } from "../../../../utils/helper";
import { FileTypesLabel } from "../../../../enum/FileTypes";
import ModalButtons from "../../../common/Modal/ModalButtons";

export const initialSelectedDoc = {
  userId: null,
  fileType: "",
  imageBytes: "",
  file: "",
};
/**
 * Renders the UploadedDocuments component.
 *
 * @returns {JSX.Element} The rendered UploadedDocuments component.
 */
const UploadedDocuments = () => {
  const [selectedDoc, setSelectedDoc] = useState(initialSelectedDoc);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);

  const { documents } = useSelector(state => state.profile.data);
  const dispatch = useDispatch();
  const [searchParams, _] = useSearchParams();

  //get all docs for a user
  useEffect(() => {
    dispatch(getUserFiles(searchParams.get("userId")));
  }, []);

  useEffect(() => {
    if (documents.length !== 0) {
      //select the first document in the array to show the document in preview section
      const file = base64ToFile(
        documents[0].imageBytes,
        FileTypesLabel[documents[0].fileType],
        documents[0].fileExt
      );

      setSelectedDoc({
        userId: documents[0].userId,
        fileType: documents[0].fileType,
        imageBytes: documents[0].imageBytes,
        file,
      });
    }

    if (documents.length === 0) setSelectedDoc(initialSelectedDoc);
  }, [documents.length]);

  const onClickSetDocTypeHandler = e => {
    const elem = e.target.closest("[data-doc]");
    if (!elem) return;
    const dataset = elem.dataset.doc;
    const doc = documents[dataset];
    //change the base64 string to file object to be able to preview the file in preview section.
    const file = base64ToFile(doc.imageBytes, FileTypesLabel[doc.fileType], doc.fileExt);

    setSelectedDoc({
      userId: doc.userId,
      fileType: doc.fileType,
      imageBytes: doc.imageBytes,
      file,
    });
    setIsDeleteModalVisible(true);
  };

  const onClickDeleteFileHandler = () => {
    dispatch(deleteFile({ userId: selectedDoc.userId, fileType: selectedDoc.fileType }));
    setIsDeleteModalVisible(false);
    setSelectedDoc(initialSelectedDoc);
  };

  return (
    <>
      <table
        onClick={onClickSetDocTypeHandler}
        className={`${documents.length === 0 ? "h-[10rem]" : ""} w-full`}>
        <TableHeader cols={DocumentTableHeader} />
        <tbody>
          {documents.length === 0 && (
            <tr>
              <td
                colSpan={7}
                className="tracking-wide text-center border-0 py-5 font-semibold text-[#6b7280]">
                No Documents added!
              </td>
            </tr>
          )}
          {documents.length > 0 &&
            documents.map((doc, i) => (
              <DocumentRows
                key={doc.fileType}
                index={i}
                name={FileTypesLabel[doc.fileType]}
                fileExt={doc.fileExt}
                uploadedDate={new Date().getFullYear()}
              />
            ))}
        </tbody>
      </table>
      <ModalComponent
        showFooter={false}
        heading="Delete Document"
        bodyClassName="px-4 mt-4"
        size="2xl"
        show={isDeleteModalVisible}
        onClose={setIsDeleteModalVisible.bind(null, false)}>
        <h4 className="flex items-center justify-center gap-2 mx-auto w-[80%] bg-red-200 py-2 text-red-800 font-semibold my-6 rounded-md">
          <InfoIconSvg className="!fill-current w-4 h-4" />
          <span>To update this document, please delete this one first.</span>
        </h4>
        <div className="md:mx-auto w-[80%] h-[30rem] border border-gray-200">
          {selectedDoc.file?.type?.split("/")[1] === "pdf" && (
            <embed
              src={`data:application/pdf;base64,${selectedDoc.imageBytes}#view=FitH`}
              width="100%"
              height="100%"
            />
          )}
          {selectedDoc.file?.type?.split("/")[1] !== "pdf" && (
            <img
              src={`data:image/jpeg;base64,${selectedDoc.imageBytes}`}
              className="h-full w-full block object-contain"
              alt={selectedDoc.docType}
            />
          )}
        </div>

        <ModalButtons
          btnCont="!pr-3"
          actionBtnText="Delete Document"
          isActionDeleteBtn={true}
          cancelBtnText="Cancel"
          onClickAction={onClickDeleteFileHandler}
          onClickCancel={setIsDeleteModalVisible.bind(null, false)}
        />
      </ModalComponent>
    </>
  );
};

export default UploadedDocuments;
