import { createSlice } from "@reduxjs/toolkit";
import { addSkill, deleteSkill, updateSkill } from "../../services/SkillsService";
import { showToast } from "../../components/common/Toast";
import { getAllSkills } from "./../../services/SkillsService";

const skills = {
  id: "skills management",
  //{id: number, name: string}
  allSkills: [],
  filteredSkills: [],
  filteredSkillValue: "",
};

const initialState = {
  skills,
};

export const AddSkills = createSlice({
  name: "addSkills",
  initialState,
  reducers: {
    getSkills: (state, action) => {
      const options = action.payload;
      state.skills.allSkills = [...options];
    },

    addSkills: (state, action) => {
      state.skills.allSkills = [...state.skills.allSkills, ...action.payload];
    },

    updateSkill: (state, action) => {
      state.skills.allSkills = state.skills.allSkills.map(skill => {
        if (skill.id === action.payload.id) skill.name = action.payload.name;
        return skill;
      });
      if (state.skills.filteredSkillValue) {
        state.skills.filteredSkillValue = "";
        state.skills.filteredSkills = [];
      }
    },

    deleteSkill: (state, action) => {
      state.skills.allSkills = state.skills.allSkills.filter(skill => skill.id !== action.payload);
      if (state.skills.filteredSkillValue) {
        state.skills.filteredSkillValue = "";
        state.skills.filteredSkills = [];
      }
    },

    filteredSkills: (state, action) => {
      if (action.payload.trim() === "") {
        state.skills.filteredSkillValue = "";
        state.skills.filteredSkills = [];
        return;
      }
      const filteredSkills = state.skills.allSkills.filter(skill =>
        skill.name.toLowerCase().includes(action.payload.toLowerCase())
      );
      state.skills.filteredSkillValue = action.payload;
      state.skills.filteredSkills = [...filteredSkills];
    },

    resetState: state => {
      state.skills = initialState;
    },
  },
});

/**
 * Retrieves all skills.
 *
 * @return {function} A function that dispatches an action to get all skills.
 */
const getSkillsList = function () {
  return async dispatch => {
    getAllSkills().then(res => {
      if (res.statusCode === 200) {
        dispatch(AddSkills.actions.getSkills(res?.data));
        return;
      }
      if (res.statusCode !== 200) {
        showToast("error", "Something went wrong");
      }
    });
  };
};

/**
 * Generates a function comment for the given function body in a markdown code block with the correct language syntax.
 *
 * @param {string} skills - A comma-separated string of skills.
 * @return {Promise<function>} A function that dispatches an action and adds the skills to the state.
 */
const addSkills = function (skills) {
  return async dispatch => {
    const skillsArr = skills.split(",").map(skill => ({ id: 0, name: skill.trim() }));
    const res = await addSkill(skillsArr);

    if (res.statusCode === 200) {
      dispatch(AddSkills.actions.addSkills(res.data));
      const message = skillsArr.length > 1 ? "skills" : "skill";
      showToast("success", `${message} added successfully`);
      return;
    } //duplicate value
    else if (res.statusCode === 406) {
      showToast("error", "A skill already exist in database.");
      return;
    } else {
      showToast("error", "Something went wrong");
    }
  };
};

/**
 * Updates a skill in the database.
 *
 * @param {object} skill - The skill to update.
 * @return {function} A function that dispatches an action to update the skill and displays a toast message.
 */
const updateSkills = function (skill) {
  return async dispatch => {
    const res = await updateSkill(skill);
    if (res.statusCode === 200) {
      dispatch(AddSkills.actions.updateSkill(res.data));
      showToast("success", `skill updated successfully`);
      return;
    } //duplicate value
    else if (res.statusCode === 406) {
      showToast("error", "A skill already exist in database.");
      return;
    } else {
      showToast("error", "Something went wrong");
    }
  };
};

const deleteSelectedSkill = function (id) {
  return async dispatch => {
    const res = await deleteSkill(id);
    if (res.statusCode === 200) {
      dispatch(AddSkills.actions.deleteSkill(id));
      showToast("success", `skill deleted successfully`);
      return;
    } else {
      showToast("error", res.message);
    }
  };
};

export { getSkillsList, addSkills, updateSkills, deleteSelectedSkill };
export const { setSkillsOptions, filteredSkills, resetState } = AddSkills.actions;
export default AddSkills.reducer;
