import { useContext } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { AuthContext, axiosInstance } from "../context/AuthContext";
import { useStore } from "../context/createFastContext";

import LogoutSvg from "./../media/LogoutSvg";
import { resetNotifications } from "../store/slices/NotificationSlice";

export const Logout = ({ className, svgClassName }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [_, setStore] = useStore(s => s);

  const { setUser, setUserAuth, init } = useContext(AuthContext);

  const handleLogout = () => {
    sessionStorage.removeItem("user");
    axiosInstance.interceptors.request.eject(init.current);
    init.current = null;
    setUser(null);
    setUserAuth(false);

    //hide the header on logout
    setStore({
      orgCompletionStatus: {
        isCompleted: false,
      },
      isSidebarActive: false,
    });
    navigate("/");
    dispatch(resetNotifications());
  };

  return (
    <button data-sidebar="logout" onClick={handleLogout} className={`${className} text-[0.9rem]`}>
      Logout
    </button>
  );
};
