import React from "react";
import { MdOutlineEdit } from "react-icons/md";
import { RiDeleteBin6Line } from "react-icons/ri";
import DeleteModal from "../../../components/common/Modal/DeleteModal";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import {
  resetStatutoryEsiDetails,
  setStatutoryToggleDetails,
} from "../../../store/slices/statutorySlice";
import { disableStatutoryComponent } from "../../../services/StatutoryService";
import { STATUS_CODES } from "../../../constants/Constant";
import { showToast } from "../../../components/common/Toast";
import { STATUTORY_MESSAGE } from "../../../constants/Messages";

const EsiDetails = () => {
  const dispatch = useAppDispatch();
  const statutory = useAppSelector(state => state.statutory);

  const { statutoryToggleDetails, statutoryEsiDetails } = statutory;

  //Function to open the delete ESI Modal.
  const onOpenDeleteEsiModal = () => {
    dispatch(
      setStatutoryToggleDetails({
        ...statutoryToggleDetails,
        toggleEsiDeleteModal: true,
      })
    );
  };

  //Function to close delete ESI Modal.
  const onCloseDeleteEsiModal = () => {
    dispatch(
      setStatutoryToggleDetails({
        ...statutoryToggleDetails,
        toggleEsiDeleteModal: false,
      })
    );
  };

  const modalConfigs = {
    show: statutoryToggleDetails.toggleEsiDeleteModal,
    size: "2xl",
    showFooter: false,
    onClose: onCloseDeleteEsiModal,
    bodyClassName: "gap-4 mt-2",
  };

  //Function to open the update ESI Modal.
  const onOpenUpdateEsiModal = () => {
    dispatch(
      setStatutoryToggleDetails({
        ...statutoryToggleDetails,
        toggleUpdateEsiModal: true,
        isEsiEnabled: false,
      })
    );
  };

  //Function to delete or disable esi.
  const onDeleteEsiComponentDetails = async () => {
    if (statutoryEsiDetails?.id) {
      const response = await disableStatutoryComponent(statutoryEsiDetails?.id);
      if (response?.data?.statusCode === STATUS_CODES.SUCCESS) {
        showToast("success", STATUTORY_MESSAGE.ESI_DELETE);
        dispatch(
          setStatutoryToggleDetails({
            ...statutoryToggleDetails,
            toggleEsiDeleteModal: false,
            isEsiEnabled: false,
          })
        );
        dispatch(resetStatutoryEsiDetails());
      }
    }
  };
  return (
    <>
      <div className=" min-h-[20rem]">
        {/* First heading container */}
        <div className="heading-container mt-4 flex gap-2">
          <h1 className="text-xl font-medium">Employees' State Insurance</h1>
          <button
            className="border w-8 h-8 rounded-[50%] flex justify-center items-center bg-gray-200 cursor-pointer"
            onClick={onOpenUpdateEsiModal}>
            <MdOutlineEdit size={23} />
          </button>
        </div>

        {/* 2nd epf number container */}
        <div className="epf-container mt-4  flex">
          <p className="epf-number-container w-[20%]">ESI Number</p>
          <p className="epf-number-value ">{statutoryEsiDetails?.number}</p>
        </div>

        {/* 3rd epf number container */}
        <div className="epf-container mt-4 flex">
          <p className="epf-number-container w-[20%]">Deduction Cycle</p>
          <p className="epf-number-value ">Monthly</p>
        </div>

        {/* 4th epf number container */}
        <div className="epf-container mt-4  flex">
          <p className="epf-number-container  w-[20%]">
            Employees' Contribution
          </p>
          <p className="epf-number-value ">
            {statutoryEsiDetails?.employeeContributionRate}% of Gross Pay
          </p>
        </div>

        {/* 5th epf number container */}
        <div className="epf-container mt-4  flex">
          <p className="epf-number-container  w-[20%]">
            Employer's Contribution
          </p>
          <p className="epf-number-value ">
            {statutoryEsiDetails?.employerContributionRate}% of Gross Pay
          </p>
        </div>

        <hr className="h-px mt-6 bg-gray-200 border-0 dark:bg-gray-700" />

        {/* 10th Delete or Disable EPF container */}
        <div className="delete-or-disable-epf-container mt-4 ">
          <button
            className="cursor-pointer flex justify-center items-center text-[#aabee6] hover:text-[#5b7fc7]"
            onClick={onOpenDeleteEsiModal}>
            <p className="flex justify-center items-center">
              <RiDeleteBin6Line size={20} />
            </p>
            <p className="flex justify-center items-center">Disable ESI</p>
          </button>
        </div>
      </div>
      <DeleteModal
        modalConfigs={modalConfigs}
        onDelete={onDeleteEsiComponentDetails}
        onCancel={onCloseDeleteEsiModal}
        showIcon={true}
        message={
          "If your organisation has 10 or more employees, it is necessary that you register for the ESI scheme. Are you sure you want to disable ESI for this organisation?"
        }
      />
    </>
  );
};

export default EsiDetails;
