import PageSection from "../../components/Layout/PageSection/PageSection";
import FlowBiteTabs from "./../../components/common/Tabs/FlowBiteTabs";

import { payrollOptions } from "../../constants/SidebarOptions";
import LeftMenu from "../../components/Layout/LeftMenu/LeftMenu";
import { Outlet } from "react-router";

const Payroll = () => {
  return (
    <>
    <div className="flex">
      <LeftMenu sidebarListItem={payrollOptions} />

      <div className="my-leaves-right-list-section h-full w-full relative">
        <Outlet />
      </div>
      </div>
    </>
  );
};

export default Payroll;
