const FormContainer = ({ children, className }) => {
  return (
    <div className={`${className} w-full sm:w-full md:w-[50%] px-3 py-3 pb-5 bg-[#f7faff]`}>
      <div>
        <div className="flex justify-between"></div>
        {children}
      </div>
    </div>
  );
};

export default FormContainer;
