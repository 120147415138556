import { useDispatch } from "react-redux";

import { Button } from "flowbite-react";
import ModalComponent from "../../../common/Modal/Modal";

import { modalsEnum } from "../../helper";
import { deleteSelectedSkill } from "../../../../store/slices/addSkillSlice";
import InfoIconSvg from "../../../../media/InfoIconSvg";

/**
 * Renders a delete modal component.
 *
 * @param {{toggleModal: Function, modals: Object, selectedSkill: Object, setSelectedSkill: Function}} props
 * @param {Function} toggleModal - function to toggle the modal
 * @param {Object} modals - object containing modal states
 * @param {Object} selectedSkill - object representing the selected skill
 * @param {Function} setSelectedSkill - function to set the selected skill
 * @return {JSX.Element} the delete modal component
 */
const DeleteSkillModal = ({ toggleModal, modals, selectedSkill, setSelectedSkill }) => {
  const dispatch = useDispatch();

  const onClickDeleteHandler = function () {
    dispatch(deleteSelectedSkill(selectedSkill.id));
    toggleModal(modalsEnum.deleteModal);
    setSelectedSkill({ id: null, name: "" });
  };

  const onClickCancelHandler = function () {
    toggleModal(modalsEnum.deleteModal);
    setSelectedSkill({ id: null, name: "" });
  };

  return (
    <ModalComponent
      show={modals.deleteModal}
      heading="Delete Skill"
      showFooter={false}
      bodyClassName="px-6"
      size="2xl"
      onClose={toggleModal.bind(null, modalsEnum.deleteModal)}>
      <div>
        <h5 className="font-semibold text-gray-700 my-5 text-center">
          Are you sure you want to delete this skill "{selectedSkill.name}"?
        </h5>
      </div>

      <div className="flex items-center justify-center gap-2 my-4">
        <InfoIconSvg className="w-3 h-3 fill-gray-500" />
        <span className="text-gray-700 text-sm">
          Warning: if this skill is associated with any users, it'll be removed for those users.
        </span>
      </div>

      <div className="flex justify-end items-center gap-4">
        <Button onClick={onClickDeleteHandler} color="failure">
          Yes, i am sure
        </Button>
        <Button onClick={onClickCancelHandler} color="gray">
          No, cancel
        </Button>
      </div>
    </ModalComponent>
  );
};

export default DeleteSkillModal;
