import { axiosInstance as axios } from "../context/AuthContext";

// Setting the the base url for the service
const userBaseUrl = `${process.env.REACT_APP_BASE_URL}/payslip`;

// Function to show user data by month
export const getPaySlip = async (id, month, year) => {
  return await axios
    .get(`${userBaseUrl}?month=${month}&year=${year}&userId=${id}`)
    .then(response => {
      return response;
    });
};

// Function to download payslip by month
export const downloadPaySlip = async (id, month, year, name) => {
  const res = await axios({
    url: `${userBaseUrl}/download?month=${month}&year=${year}&userId=${id}`,
    method: "GET",
    // Blob is a ways which convert the response data make the file download-able
    responseType: "blob",
  });

  if (!res)
    return {
      pdfBlob: null,
      error: 404,
    };

  const data = await res.data;
  const blob = new Blob([data], { type: "application/pdf" });
  return {
    pdfBlob: blob,
    error: null,
  };
};
