import { useEffect, useMemo, useState } from "react";
import { TextInput, Label, FileInput, Select, Avatar, Button } from "flowbite-react";
import MandatorySign from "../../../../../common/Input/MandatorySign";

import { Currency, Industries } from "../../../../helper";

/**
 * Renders the basic information section of the form.
 *
 * @param {{isClient: boolean, onChange: Function, handleFileChange: Function, fileData: string, details: object, activeItem: string, onDeleteLogoHandler: Function}} props - The properties object for BasicInfo.
 * @param {boolean} isClient - Flag indicating if the user is a client.
 * @param {function} onChange - The handler function for onChange event.
 * @param {function} handleFileChange - The handler function for file change event.
 * @param {string} fileData - The fileData object containing the URL and the file details of the uploaded logo.
 * @param {Object} details - The details object containing logoBytes and name.
 * @param {string} activeItem - The active item in the form.
 * @param {function} onDeleteLogoHandler - The handler function for deleting the logo.
 * @return {React.Element} The rendered basic information section of the form.
 */
const BasicInfo = ({
  isClient,
  onChange,
  handleFileChange,
  fileData,
  details,
  activeItem,
  onDeleteLogoHandler,
}) => {
  const [disabledDeleteBtn, setDisabledDeleteBtn] = useState(true);
  const isActive = activeItem === "basicInfo";
  const name = isClient ? "Client" : "Business";

  useEffect(() => {
    if (!details?.logoBytes || !fileData?.url) {
      setDisabledDeleteBtn(true);
    }
    if (details?.logoBytes || fileData?.url) {
      setDisabledDeleteBtn(false);
    }
  }, [details?.logoBytes, fileData?.url]);

  const memoizedIndustries = useMemo(
    () =>
      Industries.map((value, i) => (
        <option key={i} value={value}>
          {value}
        </option>
      )),
    [Industries.length]
  );

  const memoizedCurrencies = useMemo(
    () =>
      Currency.map((value, i) => (
        <option key={i} value={value}>
          {value}
        </option>
      )),
    [Currency.length]
  );

  const deleteClientPicHandler = function () {
    onDeleteLogoHandler(details?.id);
    setDisabledDeleteBtn(false);
  };

  return (
    <>
      {isActive && (
        <div className="grid gap-4 mb-7 md:grid-cols-2 md:auto-rows-min sm:grid-cols-1 pt-4">
          <div id="fileUpload" className="col-span-2 mb-2">
            <div className="mb-2 block">
              <h5 className="font-semibold">{`Upload ${name} Logo`}</h5>
            </div>

            <div className="flex gap-10 mt-4">
              {details.logoBytes && (
                <Avatar
                  img={`data:image/jpeg;base64,${details?.logoBytes}`}
                  rounded
                  alt="Uploaded Business Logo"
                  size="lg"
                />
              )}

              {fileData?.url && (
                <Avatar img={fileData?.url} rounded alt="Uploaded Business Logo" size="lg" />
              )}

              {!fileData?.url && !details.logoBytes && (
                <Avatar img={""} rounded alt="Uploaded Business Logo" size="lg" />
              )}

              <div>
                <div className="w-full">
                  <FileInput
                    id="fileUpload"
                    type="file"
                    name="clientLogo"
                    accept=".png, .jpeg, .jpg"
                    onChange={handleFileChange}
                    helperText="JPG or PNG, Dimensions 1080x1080px and file size upto 20MB"
                  />
                </div>
                <Button
                  onClick={deleteClientPicHandler}
                  className="mt-3"
                  disabled={disabledDeleteBtn}
                  color="failure">
                  Delete
                </Button>
              </div>
            </div>
          </div>

          <div>
            <div className="mb-2 block">
              <Label htmlFor="client's business name" value={`${name} Name`} />
              <MandatorySign />
            </div>
            <TextInput
              id="client's business name"
              type="text"
              name="name"
              value={details.name || ""}
              placeholder="Business Name ( Required )"
              required={true}
              shadow={true}
              onChange={onChange}
            />
          </div>

          <div>
            <div className="mb-2 block">
              <Label htmlFor="industry" value="Industry" />
            </div>
            <Select
              id="industry"
              name="industry"
              value={details?.industry || ""}
              onChange={onChange}>
              <option value="select an industry" hidden={true}>
                -Select an Industry-
              </option>
              {memoizedIndustries}
            </Select>
          </div>

          <div>
            <div className="mb-2 block">
              <Label htmlFor="currency" value="Currency" />
            </div>
            <Select
              id="currency"
              name="currency"
              value={details?.currency || ""}
              onChange={onChange}>
              <option value="select an currency">-Select a Currency-</option>

              {memoizedCurrencies}
            </Select>
          </div>
        </div>
      )}
    </>
  );
};

export default BasicInfo;
