import Radio from "../../common/Radio/Radio";

const TaxButtons = ({ formValues, setFormValues }) => {
  const onChangeRadioHandler = e => {
    setFormValues(prevFV => ({
      ...prevFV,
      radioButtonValue: e.target.value,
    }));
  };

  return (
    <div className="pt-1  ml-auto pb-5">
      <fieldset
        className="flex gap-4 mt-2"
        id="radio ">
        <div className="flex items-center gap-2">
          <Radio
            id="igst"
            name="GST"
            value="igst"
            checked={formValues.radioButtonValue === "igst"}
            onChange={onChangeRadioHandler}
            parentClass="!gap-2"
            label="IGST"
            labelClass="!cursor-pointer !font-medium"
          />
        </div>
        <div className="flex items-center gap-2">
          <Radio
            id="cgst"
            name="GST"
            value="cgst&sgst"
            checked={formValues.radioButtonValue !== "igst"}
            onChange={onChangeRadioHandler}
            parentClass="!gap-2"
            label="CGST & SGST"
            labelClass="!cursor-pointer !font-medium"
          />
        </div>
      </fieldset>
    </div>
  );
};

export default TaxButtons;
