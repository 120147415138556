import { useState } from "react";
import { RxCross2 } from "react-icons/rx";
import {
  addCustomField,
  updateAssociatedCustomField,
  updateCustomFields,
} from "../../services/InvoiceService";
import { InvoiceCustomField } from "../../enum/InvoiceCustomField";

const CustomField = ({
  fieldValueDetails,
  onDeleteHeaderField,
  getHeaderFieldFormValues,
  setFormValues,
  headerField,
  isUpdating,
}) => {
  const { fieldName, fieldValue, id } = fieldValueDetails;

  const [headerFieldsChanged, setHeaderFieldsChanged] = useState(false);

  // Getting values form input
  const onChangeCustomField = event => {
    const formDetails = {
      ...fieldValueDetails,
      [event.target.name]: event.target.value,
    };

    getHeaderFieldFormValues(formDetails, headerField, setFormValues, isUpdating);
    setHeaderFieldsChanged(true);
  };

  const onBlurCustomField = event => {
    if (!event.target.value || event.target.value.trim() === "") return;

    //Update custom field if id and tenantId exist
    if (id) {
      const fieldValue = {
        ...fieldValueDetails,
        fieldName: event.target.value,
      };
      if (headerFieldsChanged)
        updateCustomFields(fieldValue).then(() => setHeaderFieldsChanged(false));
      return;
    }

    if (fieldValueDetails.invoiceId) {
      const fieldValue = {
        ...fieldValueDetails,
        fieldName: event.target.value,
      };
      if (headerFieldsChanged)
        updateAssociatedCustomField(fieldValue).then(() => setHeaderFieldsChanged(false));
      return;
    }

    if (!id) {
      //Create new custom field if id does not exist
      const fieldValue = {
        fieldName: event.target.value,
        groupId: InvoiceCustomField.HeaderFields,
      };

      if (headerFieldsChanged) {
        addCustomField(fieldValue).then(res => {
          //Add the id received from the server
          const updatedField = {
            ...fieldValueDetails,
            id: res.data.id,
          };

          getHeaderFieldFormValues(updatedField, headerField, setFormValues, isUpdating);
          setHeaderFieldsChanged(false);
        });
      }
    }
  };
  return (
    <div className="flex gap-1 mb-1">
      <div className="w-[45%]">
        <input
          type="text"
          name="fieldName"
          value={fieldName || ""}
          onChange={onChangeCustomField}
          onBlur={onBlurCustomField}
          autoFocus={true}
          className="border-b border-t-0 border-r-0 border-l-0 border-b-gray-400 focus:border-b focus:border-b-gray-900 hover:border-b-gray-900 focus:ring-0 pl-0 w-full"
          placeholder="Field Name"
        />
      </div>
      <div className="w-[45%] relative">
        <div>
          <input
            type="text"
            name="fieldValue"
            value={fieldValue || ""}
            onChange={onChangeCustomField}
            className="border-b border-t-0 border-r-0 border-l-0 border-b-gray-400 focus:border-b focus:border-b-gray-900 hover:border-b-gray-900 focus:ring-0 pl-0 w-full"
            placeholder="Value"
          />
        </div>
        <div
          className="pt-[0.6rem] w-fit absolute top-0 md:-right-6 -right-5"
          onClick={onDeleteHeaderField}>
          <RxCross2 size={25} color="#cdc733" className="cursor-pointer" />
        </div>
      </div>
    </div>
  );
};

export default CustomField;
