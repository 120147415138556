import React from "react";
import { Footer } from "../components/common/Footer";

const CancellationAndRefundPolicy = () => {
  return (
    <div>
      <div className="mt-12 border border-red-700 min-h-[10rem] border-opacity-0">
        <section className="m-auto text-[2rem] text-center font-semibold">
          Cancellation And Refund Policy
        </section>
        <section className="border border-blue-700 p-7 border-opacity-0">
          <div className="text-[1.5rem]">Currency of Payment and taxes</div>
          <div className="mt-6  ">
            If you have a billing address in India, you will be charged in
            Indian Rupees (INR), and the Goods and Services Tax (GST) will be
            applied separately at the prevailing tax rates. If you have a
            billing address outside India, you will be charged in US Dollars
            (US$). No taxes will be levied on US Dollar payments, but any
            foreign transaction charges for converting non-US$ currency payments
            to US$ currency payments will be borne by you.
          </div>
        </section>
        <section className="border border-blue-700 p-7 border-opacity-0">
          <div className="text-[1.5rem]">
            Payment options & payment gateways
          </div>
          <div className="mt-6  ">
            <p>
              If your billing address is in India, you will be redirected to the
              Razorpay (“Payment solution provider”) payment gateway. You do not
              need to create an account with Razorpay for making payments
              through their gateway. For one-time payments, Razorpay accepts Net
              Banking, e-wallets, as well as credit and debit cards issued in
              India. For auto-renewal subscription with recurring billing (see
              the section below on Auto-renewal for more details), Razorpay only
              accepts credit cards and does store your payment information.
            </p>
            <p className="mt-3">
              If your billing address is outside India, you will be redirected
              to the PayPal (“Payment Solution Provider”) payment gateway.
              PayPal accepts credit and debit cards. For one-time payments,
              Paypal supports guest checkouts. For auto-renewal subscriptions
              with recurring billing, PayPal requires you to create/already have
              a PayPal account and does store your payment information.
            </p>
          </div>
        </section>
        <section className="border border-blue-700 p-7 border-opacity-0">
          <div className="text-[1.5rem]">
            Paid Subscription Plans - Single-User License
          </div>
          <div className="mt-6  ">
            As per the Thynk Corp, a Single-User License is an authorization
            that allows the licensee the right to install the Software on any
            number of computers, as long as that same licensee is the only user.
            Further, the licensee will only be able to run one instance of the
            Application at any given time.
          </div>
        </section>
        <section className="border border-blue-700 p-7 border-opacity-0">
          <div className="text-[1.5rem]">Auto-renewal of Single-User plans</div>
          <div className="mt-6  ">
            You are able to control whether your monthly/annual payment plan
            automatically renews every month/year. If auto-renew is set to on
            (default is “on”), then you are giving permission to the Payment
            Solution Provider to automatically charge your credit/debit card
            every month (for the monthly subscription plan) or every year (for
            the annual plan). You can change the auto-renewal settings in your
            User Profile section under My Subscription after logging into your
            Trezi account on the Website.
          </div>
        </section>
        <section className="border border-blue-700 p-7 border-opacity-0">
          <div className="text-[1.5rem]">
            Paid Subscription Plans - Enterprise/Team
          </div>
          <div className="mt-6  ">
            Under Enterprise/Team Plans, we offer discounted pricing plans for
            companies interested in purchasing 5 or more licenses or looking for
            enterprise licensing. If you would like to inquire about or purchase
            Enterprise/Team subscriptions, please contact us via email at
            <span className="text-blue-700"> support@thynkweb.com</span>
          </div>
        </section>
        <section className="border border-blue-700 p-7 border-opacity-0">
          <div className="text-[1.5rem]">Software Cancellation and Refunds</div>
          <div className="mt-6  ">
            There is no cancellation and refunds available on the monthly
            licenses. On annual licenses, no cancellations and refunds are
            available after 14 days of use of the software.
          </div>
        </section>
        <section className="border border-blue-700 p-7 border-opacity-0">
          <div className="text-[1.5rem]">Software Support</div>
          <div className="mt-6">
            <p>
              Software Support shall comprise primarily of electronic and online
              support to you in order to help you locate, and on your own,
              identify and correct problems with the Software.
            </p>
            <p className="mt-6">
              Email support is available by sending an email to
              <span className="text-blue-600"> support@thynkweb.com</span>,
              outlining your support issues and/or requests, and this email ID
              will be monitored during office business hours, which shall
              ordinarily be from 10.00am through 6.00pm Indian Standard Time,
              Monday through Friday. 10:00AM to 6:00PM. Best efforts will be
              made to respond as soon as possible, but in any event no more than
              five (5) working days from the date the email was received.
            </p>
            <p className="mt-6">
              Software support is also provided via a online service desk
              accessible from the Website and the Launcher, that includes:
            </p>
            <ul className="list-disc p-7 mt-6">
              <li>FAQs</li>
              <li>Knowledge base of articles & training videos</li>
              <li>Bug reporting facility</li>
              <li>Feature request facility</li>
              <li>Feedback facility</li>
            </ul>
            <section className="border border-blue-700 p-2 border-opacity-0">
              <div className="text-[1.5rem]">How to Contact Us?</div>
              <div className="mt-6  ">
                <p>
                  If you have any questions or concerns about this Cookie
                  Notice, please contact us by any of the following methods:
                </p>
                <ul className="list-disc p-4 mt-6">
                  <li>
                    By email:{" "}
                    <span className="text-blue-700">support@thynkweb.com</span>
                  </li>
                  <li>
                    434A , 4TH Floor, Spaze Edge, Tower – B, Sector - 47, Sohna
                    Road Gurugram - 122018, Haryana.
                  </li>
                </ul>
              </div>
            </section>
          </div>
        </section>
      </div>
      <footer className="mt-5">
        <Footer />
      </footer>
    </div>
  );
};

export default CancellationAndRefundPolicy;
