import { useState } from "react";

import { initialLeaveInfo } from "./helper";
import ModalComponent from "../../components/common/Modal/Modal";
import { ApplyLeave } from "../../services/LeaveService";
import { ApplyLeaveModalBody } from "./ApplyLeaveModalBody";
import { Button } from "flowbite-react";
import { GoPlus } from "react-icons/go";
import { showToast } from "../../components/common/Toast";
import { ADD_NEW_LEAVE_MESSAGES } from "../../constants/Messages";

export const ApplyLeaveModal = ({ pageNo, pageSize, setRenderLeaveManagement }) => {
  const [leavesInfo, setLeavesInfo] = useState(initialLeaveInfo);
  const [toggleModal, setToggleModal] = useState(false);
  const [isFlexi, setIsFlexi] = useState(false);

  //universal set input state handler
  const handleApplyLeave = e => {
    e.preventDefault();
    const { name, value } = e.target;

    if (name === "startDate")
      setLeavesInfo(prevLI => ({
        ...prevLI,
        [name]: value,
        endDate: value,
      }));
    else
      setLeavesInfo(prevLI => ({
        ...prevLI,
        [name]: value,
      }));
  };

  const onCloseModalHandler = () => {
    //not setting setRenderLeaveManagement to true since user didn't submit the form
    setLeavesInfo(initialLeaveInfo);
    setToggleModal(false);
    setIsFlexi(false);
  };

  //Handler for leave type dropdown
  const handleSelectChange = (_, selectedOption) => {
    if (isFlexi) {
      setLeavesInfo(prevLI => ({
        ...prevLI,
        startDate: "",
        endDate: "",
      }));
    }
    if (!selectedOption.isFlexi) {
      setIsFlexi(false);
      setLeavesInfo(prevLI => ({
        ...prevLI,
        leaveTypeId: selectedOption.value,
        leaveTypeName: selectedOption.label,
      }));
    } else {
      setIsFlexi(true);
      setLeavesInfo(prevLI => {
        return {
          ...prevLI,
          date: "",
          leaveTypeId: selectedOption.value,
          leaveTypeName: selectedOption.label,
        };
      });
    }
  };

  const Submit = e => {
    const { leaveTypeId, date } = leavesInfo;
    e.preventDefault();

    if (!isFlexi) {
      if (leavesInfo.startDate > leavesInfo.endDate)
        return showToast("error", ADD_NEW_LEAVE_MESSAGES.END_DATE_ERROR_MESSAGE);
      if (leavesInfo.startDate === "" || leavesInfo.endDate === "")
        return showToast("error", ADD_NEW_LEAVE_MESSAGES.EMPTY_DATE_MESSAGE);
    } else if (isFlexi && date === "")
      return showToast("error", ADD_NEW_LEAVE_MESSAGES.EMPTY_DATE_MESSAGE);
    if (!leavesInfo.leaveTypeId)
      return showToast("error", ADD_NEW_LEAVE_MESSAGES.EMPTY_LEAVE_TYPE_ERROR);

    //send data to backend
    const data = {
      leaveTypeId: leaveTypeId,
      startDate: isFlexi ? date : leavesInfo.startDate,
      endDate: isFlexi ? date : leavesInfo.endDate,
      reason: leavesInfo.reason,
      startFirstHalf: leavesInfo.startFirstHalf,
      startSecondHalf: leavesInfo.startSecondHalf,
      endFirstHalf: leavesInfo.endFirstHalf,
      endSecondHalf: leavesInfo.endSecondHalf,
      leaveTypeName: leavesInfo.leaveTypeName,
      approve: leavesInfo.approve,
    };

    ApplyLeave(data)
      .then(res => {
        if (res.statusCode === 400) return showToast("error", res.message);
        if (res.statusCode === 200) {
          setRenderLeaveManagement(true);
          onCloseModalHandler();
          showToast("success", ADD_NEW_LEAVE_MESSAGES.SUCCESS);
        }
      })
      .catch(error => {
        console.error(error);
      });
  };

  return (
    <div>
      <div onClick={() => setToggleModal(true)} id="leave">
        <Button className="tc-btn-dark">
          <GoPlus size={"1rem"} />
          &nbsp;&nbsp;Apply for Leave
        </Button>
      </div>

      <ModalComponent
        show={toggleModal}
        headerClassName="modal-header-title mb-4"
        showFooter={false}
        heading="Apply for Leave"
        onClose={onCloseModalHandler}>
        <ApplyLeaveModalBody
          pageNo={pageNo}
          pageSize={pageSize}
          leavesInfo={leavesInfo}
          handleApplyLeave={handleApplyLeave}
          handleSubmit={Submit}
          handleSelectChange={handleSelectChange}
          setLeavesInfo={setLeavesInfo}
          onCloseModalHandler={onCloseModalHandler}
          isFlexi={isFlexi}
        />
      </ModalComponent>
    </div>
  );
};
