import React from "react";
import DeleteModal from "../common/Modal/DeleteModal";
import { DeleteUser } from "../../services/UserService";
import { STATUS_CODES } from "../../constants/Constant";
import { showToast } from "../common/Toast";
import { useAppDispatch, useAppSelector } from "../../store/store";
import { resetUser, resetUserManagement, setRefetchUsers, setToggleDeleteUserModal } from "../../store/slices/userManagementSlice";

const DeleteEmployee = ({}) => {

  const umState = useAppSelector(state => state.userManagement);
  const dispatch = useAppDispatch();
  
  const modalConfigs = {
    show: umState.toggleDeleteUserModal,
    size: "md",
    showFooter: false,
  };
  const styles = {
    icon: "text-red-500",
  };

  //Function to delete user
  const onDeleteUser = async () => {
      const response = await DeleteUser(umState.user.id);
      if (response.status === STATUS_CODES.SUCCESS) {
        showToast("success", "User deleted successfully");
        resetState();
      } else {
        showToast("error", "Error");
      }
  };

  const resetState = () => {
    dispatch(setToggleDeleteUserModal(false));
    dispatch(resetUser());
    dispatch(setRefetchUsers(!umState.refetchUsers));
  }
  
  return (
    <div>
      <DeleteModal
        modalConfigs={modalConfigs}
        showIcon={true}
        onCancel={resetState}
        onDelete={onDeleteUser}
        styles={styles}
      />
    </div>
  );
};

export default DeleteEmployee;
