import React from "react";
import ModalComponent from "../../common/Modal/Modal";
import { btnPrimary } from "../../../utils/helper";
import AddProjectInputs from "./AddProjectInputs";

/**
 * Renders a form for adding or editing project details.
 *
 * @param {Object} toggleProjectDetails - the details of the project toggling
 * @param {Function} resetStates - function to reset the states
 * @param {Function} onSubmitUpdateProjectDetails - function to submit updated project details
 * @param {Function} onSubmitProjectDetails - function to submit project details
 * @param {Function} onChangeProjectDetails - function to handle changes in project details
 * @param {Object} projectFormDetails - the details of the project form
 * @return {JSX.Element} the rendered form component
 */

let tw_styles =
  "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-thynkwebPrimary-600 focus:border-thynkwebPrimary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-thynkwebPrimary-500 dark:focus:border-thynkwebPrimary-500";

const AddProject = ({
  toggleProjectDetails,
  resetStates,
  onSubmitUpdateProjectDetails,
  onSubmitProjectDetails,
  onChangeProjectDetails,
  projectFormDetails,
  debouncedPromiseOptionsClient,
  handleAssignUserChange,
  handleClientChange,
  handleProjectHeadChange,
  handleProjectManagerChange,
  handleDebouncedPromiseOptionsProject,
}) => {
  return (
    <>
      <ModalComponent
        show={toggleProjectDetails.toggleProjectModal}
        size="2xl"
        bodyClassName="gap-4 mt-2"
        heading={toggleProjectDetails.isUpdatingProjectDetails ? "Edit Project" : "Add Project"}
        showFooter={false}
        onClose={resetStates}>
        <div>
          <div className="grid gap-4 mb-4 md:gap-6 md:grid-cols-2 sm:mb-8">
            <AddProjectInputs
              projectFormDetails={projectFormDetails}
              onChangeProjectDetails={onChangeProjectDetails}
              debouncedPromiseOptionsClient={debouncedPromiseOptionsClient}
              handleAssignUserChange={handleAssignUserChange}
              handleClientChange={handleClientChange}
              handleProjectHeadChange={handleProjectHeadChange}
              handleProjectManagerChange={handleProjectManagerChange}
              handleDebouncedPromiseOptionsProject={handleDebouncedPromiseOptionsProject}
            />
          </div>

          <div className="mt-6 flex gap-2 justify-end">
            <div>
              <button
                type="button"
                onClick={
                  toggleProjectDetails?.isUpdatingProjectDetails
                    ? onSubmitUpdateProjectDetails
                    : onSubmitProjectDetails
                }
                disabled={toggleProjectDetails.isDisabled}
                className={`${
                  toggleProjectDetails.isDisabled
                    ? "bg-gray-300 text-gray-600"
                    : `${btnPrimary()} text-white`
                } !px-4 !py-2 rounded-md tracking-wide font-semibold flex items-center gap-2`}>
                {" "}
                {toggleProjectDetails?.isUpdatingProjectDetails ? "Update" : "Submit"}
              </button>
            </div>
            <div>
              <button onClick={resetStates} type="button" className={`${tw_styles}`}>
                Cancel
              </button>
            </div>
          </div>
        </div>
      </ModalComponent>
    </>
  );
};

export default AddProject;
