import toast from "react-hot-toast";

/**
 * Displays a toast message using the specified method and message, with optional configuration.
 *
 * @param {string} method - The method for displaying the toast message ("error", "warn", "warning", "info")
 * @param {string} message - The message to be displayed in the toast
 * @param {object} toastConfig - Optional configuration for the toast message, these are the react-hot-toast configurations
 * @return {object} An object containing the ID of the toast and the toast itself
 */
export const showToast = (method = "error", message, toastConfig) => {
  const type_array = ["error", "warn", "warning", "info"];
  const _method = type_array.includes(method) ? "error" : method;
  const duration = _method === "error" ? 3000 : 2000;
  const toastId = toast[_method](message, {
    duration: duration,
    ...toastConfig,
  });
  return {
    toastId,
    toast,
  };
};
