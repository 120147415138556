import { useEffect, memo, useContext, useState } from "react";
import { Avatar } from "flowbite-react";
import { useStore } from "../../context/createFastContext";

import { AuthContext } from "../../context/AuthContext";
import { showToast } from "../../components/common/Toast";
import ModalComponent from "../../components/common/Modal/Modal";
import InputFields from "../../components/common/Input/InputFields";

import EditIconSvg from "../../media/EditIconSvg";
import { GetTenants, UploadOrganizationLogo } from "../../services/OrganizationDetailsService";
import { updatableFields, financialDetailsInputFieldOptions, updateData } from "./configs";
import { btnPrimary } from "../../utils/helper";

const OrganizationDetails = () => {
  const [organizationState, setStore] = useStore(s => s.organizationState);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { user } = useContext(AuthContext);

  useEffect(() => {
    GetTenants().then(res => {
      setStore({organizationState
        : {
          ...organizationState,
          tenant: res.data.data,
        },
      });
    });
  }, [organizationState.reFetchTenant]);

  const handleChange = e => {
    setStore({
      organizationState: {
        ...organizationState,
        tenant: {
          ...organizationState.tenant,
          [e.target.name]: e.target.value,
        },
      },
    });
  };

  const handleLogo = e => {
    const formData = new FormData();
    formData.append("logo", e.target.files[0]);
    UploadOrganizationLogo(formData, user).then(res => {
      if (res.data.statusCode === 200) {
        showToast("success", "Logo updated");
        setStore({
          organizationState: {
            ...organizationState,
            reFetchTenant: !organizationState.reFetchTenant,
          },
        });
      }
    });
  };

  const updateDetails = async e => {
    e.preventDefault();
    updateData(organizationState.tenant, organizationState, setStore);
    resetState();
  };

  const resetState = () => {
    setIsModalOpen(false);
  };

  const imageUrl = `data:image/jpeg;base64,${organizationState.tenant?.logoBase64}`;

  return (
    <>
      <section className="bg-gray-100 dark:bg-gray-900 py-3 sm:py-5">
        <div className="px-4 mx-auto">
          <div className="mx-[auto] grid grid-cols-1 px-4 pt-6 bg-slate-50">
            <div className="col-span-1">
              <div className="p-4 mb-4 bg-white rounded-lg shadow sm:p-6 dark:bg-gray-800">
                <div className="flex space-x-4">
                  <div className="w-full">
                    <label
                      className="block w-[10rem] h-[10rem] mx-auto cursor-pointer"
                      htmlFor="logo">
                      {organizationState.tenant?.logoBase64 && (
                        <img
                          className="w-full h-full rounded-full object-cover"
                          alt={organizationState.tenant?.tenantName}
                          size="xl"
                          src={imageUrl}
                        />
                      )}
                      {!organizationState.tenant?.logoBase64 && (
                        <Avatar
                          size="xl"
                          rounded
                        />
                      )}
                      <input
                        type="file"
                        id="logo"
                        name="logo"
                        data-heading="logoBase64"
                        hidden={true}
                        onChange={handleLogo}
                      />
                    </label>
                    <h2
                      data-heading={updatableFields[4]}
                      className="min-h-[10px] text-xl mt-4 text-center font-bold dark:text-white">
                      {organizationState.tenant?.tenantName}
                    </h2>
                  </div>
                </div>
                <div className="sm:flex xl:block xl:space-y-4">
                  <div className="sm:flex-1">
                    <address className="text-sm font-medium text-gray-500 dark:text-gray-400 not-italic">
                      <div className="mt-4">Address</div>
                      <p
                        data-heading={updatableFields[0]}
                        className="min-h-[10px] text-sm font-medium text-gray-900 dark:text-white">
                        {organizationState.tenant?.address}
                      </p>
                    </address>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-span-2">
              <div className="p-4 mb-4 bg-white rounded-lg shadow sm:p-6 xl:p-8 dark:bg-gray-800">
                <div className="flow-root">
                  <h3 className="mb-4 text-xl font-bold flex gap-4 dark:text-white">
                    <span>Financial Details</span>
                    <span onClick={setIsModalOpen.bind(null, true)}>
                      <EditIconSvg />
                    </span>
                  </h3>
                  <dl className="grid grid-cols-3 gap-x-4 gap-y-8 ">
                    {financialDetailsInputFieldOptions.map((item, index) => {
                      if (index === 1 || index === 2) return;
                      return (
                        <div key={item.options.id}>
                          <dt className="text-sm font-medium text-gray-500 dark:text-gray-400">
                            {item.heading}
                          </dt>
                          <dd
                            className={`min-h-[10px] text-sm font-semibold text-gray-900 dark:text-white`}
                            data-heading={item.options.name}>
                            {organizationState.tenant !== null &&
                              organizationState.tenant[item.options.name]}
                          </dd>
                        </div>
                      );
                    })}
                  </dl>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <ModalComponent
        heading="Update Organization Details"
        show={isModalOpen}
        onClose={resetState}
        bodyClassName="px-8"
        showFooter={false}>
        <form className="flex flex-col">
          <div className="grid grid-cols-2 gap-6 mt-4">
            {financialDetailsInputFieldOptions.map(item => {
              const InputField = {
                ...item.options,
                label: item.heading,
                required: false,
              };

              return (
                <InputFields
                  key={InputField.id}
                  value={organizationState?.tenant?.[item.options.name]}
                  InputField={InputField}
                  onChange={handleChange}
                  parentClassName="w-full"
                  inputClassName="w-full"
                />
              );
            })}
          </div>
          <button
            onClick={updateDetails}
            className={`${btnPrimary()} mt-6 self-end rounded-md px-6 py-3 ml-auto text-sm tracking-wide font-[500] text-white`}>
            Edit Details
          </button>
        </form>
      </ModalComponent>
    </>
  );
};
export default memo(OrganizationDetails);
