import { useSelector } from "react-redux";

import NotificationList from "./NotificationList.jsx";

/**
 * Renders a component representing a notification.
 *
 * @return {JSX.Element} A React component.
 */
const Notification = () => {
  const { allNotifications, totalRecords } = useSelector(state => state.notification);

  return (
    <div
      className={`absolute top-16 -bottom-full right-20 bg-white shadow-md w-full sm:w-[20rem] md:w-[25rem] h-fit rounded-md`}>
      <h2 className="font-semibold tracking-wide text-center border-b border-gray-200 py-2">
        Notifications
      </h2>
      <ul className="!p-0 !m-0 flex flex-col max-h-[20rem] overflow-y-scroll">
        <NotificationList notifications={allNotifications} totalRecords={totalRecords} />
      </ul>
    </div>
  );
};

export default Notification;
