import InModalMessage from "../../common/Modal/InModalMessage";

/**
 * Renders the CV/Resume of a user.
 *
 * @param {object} fileDetails - The details of the file, including imageBytes.
 * @param {function} onClickDeleteHandler - The function to be called when the delete button is clicked.
 * @return {JSX} The rendered CV/Resume component.
 */
const ViewUserShowCV = ({ fileDetails, onClickDeleteHandler }) => {
  return (
    <>
      <>
        <InModalMessage styles={{parentClass: '!w-[95%]'}} message="To update the CV/Resume. Please delete this one first.">
          <button
            className="text-red-800 underline cursor-pointer hover:text-red-900 focus:text-red-900"
            onClick={onClickDeleteHandler}>
            Click here to delete.
          </button>
        </InModalMessage>

        <embed
          src={`data:application/pdf;base64,${fileDetails.imageBytes}#view=FitH&toolbar=0`}
          width="95%"
          height="85%"
          className="mx-auto"
        />
      </>
    </>
  );
};

export default ViewUserShowCV;
