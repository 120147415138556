import { createSlice } from "@reduxjs/toolkit";
import { getAssociatedLeavesService } from "../../services/LeaveService";
import { STATUS_CODES } from "../../constants/Constant";
import { showToast } from "../../components/common/Toast";

const initialState = {
  leavesDropdown: [],
};

const leaves = createSlice({
  name: "leaves",
  initialState,
  reducers: {
    setLeaveDropdown: (state, action) => {
      state.leavesDropdown = action.payload;
    },
  },
});

const getAssociatedLeaves = employeeId => {
  return async dispatch => {
    const res = await getAssociatedLeavesService(employeeId);
    if (res.data.statusCode === STATUS_CODES.SUCCESS) {
      const associatedLeaves = [];
      res.data.data.forEach(leave => {
        if (leave.leaveType) associatedLeaves.push(leave.leaveType);
      });
      dispatch(leaves.actions.setLeaveDropdown(associatedLeaves));
    } else {
      showToast("error", res.data.message);
    }
  };
};

export const {} = leaves.actions;
export { getAssociatedLeaves };
export default leaves.reducer;
