// Importing axios instance from AuthContext
import { axiosInstance as axios } from "../context/AuthContext";

// Creating the base URL for projects using the REACT_APP_BASE_URL from environment variables
const jobBaseUrl = `${process.env.REACT_APP_BASE_URL}/Jobs`;

// Creating the new job
export const getJob = async (pageNo, pageSize) => {
  const queryParams = `?pageNumber=${pageNo}&pageSize=${pageSize}`;
  const response = await axios.get(`${jobBaseUrl}${queryParams}`);
  return response;
};

// Get job by Id
export const getJobById = async jobId => {
  const response = await axios.get(`${jobBaseUrl}/${jobId}`);
  return response;
};

//get Job Assigned
export const jobsAssign = async () => {
  const response = await axios.get(`${jobBaseUrl}/assigned`);
  return response;
};

// Add new job
export const addJob = async jobData => {
  return await axios({
    url: jobBaseUrl,
    method: "POST",
    data: JSON.stringify(jobData),
  });
};

// Update an existing job
export const updateJob = async jobData => {
  const response = await axios({
    url: `${jobBaseUrl}`,
    method: "PUT",
    data: JSON.stringify(jobData),
  });
  return response;
};

// Update job status
export const updateStatus = async jobStatus => {
  const response = await axios({
    url: `${jobBaseUrl}/status`,
    method: "PUT",
    data: JSON.stringify(jobStatus),
  });
  return response;
};

// Update job assign
export const updateJobAssign = async jobAssign => {
  const response = await axios({
    url: `${jobBaseUrl}/assign`,
    method: "PUT",
    data: JSON.stringify(jobAssign),
  });
  return response;
};

// Delete a job
export const deleteJobById = async jobDataId => {
  const response = await axios.delete(`${jobBaseUrl}/${jobDataId}`);
  return response;
};
