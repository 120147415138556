// Importing axios instance from AuthContext
import { axiosInstance as axios } from "../context/AuthContext";

// Creating the base URL for projects using the REACT_APP_BASE_URL from environment variables
const projectBaseUrl = `${process.env.REACT_APP_BASE_URL}/Projects`;

// Function to get a list of projects with pagination
export const getProjects = async (pageNo, pageSize, searchParam = "") => {
  const response = await axios.get(
    `${projectBaseUrl}?pageNumber=${pageNo}&pageSize=${pageSize}&searchParam=${searchParam}`
  );
  return response?.data;
};

// Function to get a project by its ID
export const getProjectsById = async id => {
  const response = await axios.get(`${projectBaseUrl}/${id}`);
  return response.data;
};

// Function to add a new project
export const addProject = async projectData => {
  return await axios({
    url: projectBaseUrl,
    method: "POST",
    data: JSON.stringify(projectData),
  });
};

// Function to update an existing project
export const updateProject = async projectData => {
  const response = await axios({
    url: `${projectBaseUrl}`,
    method: "PUT",
    data: JSON.stringify(projectData),
  });
  return response;
};

// Function to delete a project by its ID
export const deleteProjectById = async projectDataId => {
  const response = await axios.delete(`${projectBaseUrl}/${projectDataId}`);
  return response;
};

// Function to delete project association by ID
export const deleteProjectAssociationById = async projectAssocationId => {
  const response = await axios.delete(`${projectBaseUrl}/${projectAssocationId}/users`);
  return response;
};

//Function to get all the assigned users of a project
export const getAssignedUsers = async projectId => {
  const response = await axios.get(`${projectBaseUrl}/${projectId}/assignedEmployees`);
  return response.data;
};
