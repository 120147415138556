import { useRef, useCallback, useContext } from "react";
import { useStore } from "../../context/createFastContext";
import { Outlet } from "react-router-dom";

//toast notification only need to be import in top level parent
import ToastNotification from "./../../components/common/Toast/ToastNotification";
import AuthHeader from "../../components/Layout/Header/AuthHeader";
import Sidebar from "../../components/Layout/Sidebar/Sidebar";

import { AuthContext } from "../../context/AuthContext";
import AddOrganisationModal from "./AddOrganisationModal";

const RootLayout = () => {
  const [isSidebarActive, setStore] = useStore(s => s.isSidebarActive);
  const [initialContextState, _] = useStore(s => s);
  const sidebarRef = useRef(null);
  const { user, userAuth } = useContext(AuthContext);

  const openSidebar = useCallback(() => {
    setStore({
      isSidebarActive: true,
    });
  }, []);

  const closeSidebar = useCallback(() => {
    let timeout;
    timeout && clearTimeout(timeout);

    if (sidebarRef.current) {
      sidebarRef.current.animate([{ transform: "translateX(100%)", opacity: 0 }], {
        duration: 300,
        fill: "forwards",
      });
    }

    timeout = setTimeout(() => {
      setStore({
        isSidebarActive: false,
      });
    }, 400);
  }, []);

  return (
    <>
      {userAuth && (
        <AuthHeader
          openSidebarHandler={openSidebar}
          closeSidebarHandler={closeSidebar}
          toggleSidebar={isSidebarActive}
          userInfoById={initialContextState?.userInfoById}
        />
      )}
      {isSidebarActive && (
        <Sidebar
          ref={sidebarRef}
          user={user}
          userInfoById={initialContextState?.userInfoById}
          toggleSidebar={isSidebarActive}
        />
      )}
      <AddOrganisationModal />
      <Outlet />
      <ToastNotification />
    </>
  );
};

export default RootLayout;
