import React from "react";
import Table from "../../common/Table/Table";
import TableHeader from "../../common/Table/TableHeader";
import TableRows from "../../common/Table/TableRows";
import TableData from "../../common/Table/TableData";
import { myLeavesTableHeading } from "../helper";
import { approx_time_show } from "../../../utils/common";
import { formatDate } from "../../../utils/helper";
import {
  approxTime_Badge,
  backgroundColor,
  currentPage,
  defaultClassName,
  dotClassName,
  isFirstHalf_SecondHalf,
  onPageChange,
  options,
  pagesize,
  setDefaultValue,
  setIsDefaultValue,
  totalPages,
} from "../../../pages/LeaveManagement/helper";
import SelectWithDropdown from "../../common/SelectWithDropdown/SelectWithDropdown";
import { LeaveStatus } from "../../../enum/LeaveStatus";
import {
  ReportingUsers,
  proTip,
  statusOptions,
  statusOptionsForNonAdmin,
  statusOptionsForUser,
} from "../../../pages/LeaveManagement/LeaveCommon";
import ProtipSvg from "../../../media/ProtipSvg";
import { Role } from "../../../enum/Role";
import { Tooltip } from "flowbite-react";
import ProfileSvg from "../../../media/ProfileSvg";

const AddLeaveTypeLists = ({
  leaves,
  dateTime,
  roleId,
  userId,
  handleSelectChange,
  radioSelection,
}) => {
  return (
    <Table>
      <TableHeader cols={myLeavesTableHeading} />

      <tbody className={leaves.length === 0 ? "h-[8rem]" : "auto"}>
        {leaves.length > 0 &&
          leaves?.map(user => {
            const updatedUser = {
              ...user,
              timeDifference: approx_time_show(user.dateCreated),
            };

            return (
              <>
                <TableRows key={user?.id}>
                  <TableData>{user?.user?.firstName + " " + user?.user?.lastName}</TableData>
                  <TableData className="">
                    {formatDate(user.leaveDate)} {isFirstHalf_SecondHalf(user)}
                  </TableData>
                  <TableData className="">{user?.leaveTypeName}</TableData>
                  <TableData
                    data-tooltip-id={"approx-time" + user?.id}
                    data-tooltip-content={dateTime(user?.dateCreated, true)}
                    className="px-4 py-2 whitespace-nowrap hover: cursor-help">
                    {approxTime_Badge(updatedUser)}
                    <Tooltip id={"approx-time" + user?.id} cursor="help" place="bottom" />
                  </TableData>
                  <TableData
                    id={"notes" + user?.id}
                    data-tooltip-content={user?.reason}
                    data-tooltip-id={"notes" + user?.id}
                    className="px-4 py-2 whitespace-nowrap hover: cursor-help">
                    {user.reason && user.reason.length > 10
                      ? `${user.reason.slice(0, 14)}...`
                      : user.reason}
                    <Tooltip id={"notes" + user?.id} cursor="help" place="bottom" />
                  </TableData>

                  <TableData className="tc-dropdown px-4 py-2 whitespace-nowrap flex items-center">
                    <SelectWithDropdown
                      roundedDot={true}
                      backgroundColor={backgroundColor(user, LeaveStatus)}
                      className={defaultClassName(user, LeaveStatus)}
                      dotClassName={dotClassName(user, LeaveStatus)}
                      size="sm"
                      options={options(
                        roleId,
                        userId,
                        user,
                        statusOptions(user, LeaveStatus),
                        statusOptionsForNonAdmin(user, LeaveStatus),
                        statusOptionsForUser(user, LeaveStatus)
                      )}
                      hideMenu={
                        options(
                          roleId,
                          userId,
                          user,
                          statusOptions(user, LeaveStatus),
                          statusOptionsForNonAdmin(user, LeaveStatus),
                          statusOptionsForUser(user, LeaveStatus)
                        ).length === 0
                      }
                      defaultValue={setDefaultValue(user, LeaveStatus)}
                      isDefaultValue={setIsDefaultValue(user, LeaveStatus)}
                      handleChange={handleSelectChange}
                      otherOptions={user?.id}
                    />
                  </TableData>
                </TableRows>
              </>
            );
          })}
      </tbody>
    </Table>
  );
};

export default AddLeaveTypeLists;
