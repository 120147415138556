import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { userRegister } from "../../services/UserService";
import { useNavigate } from "react-router-dom";
import { checkMandatoryFields } from "../../components/EmployeeManagement/helper";
import { showToast } from "../../components/common/Toast";
import { REGISTER_INPUT_FIELDS } from "../../constants/Constant";
import InputFields from "../../components/common/Input/InputFields";
import { trimValues } from "../../utils/helper";
import { initialState } from "./helper";

const Register = () => {
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState("");
  const [newUser, setNewUser] = useState(initialState);
  const [loading, setLoading] = useState(false);

  //Getting values from input fields
  const onChangeHandler = e => {
    const { name, value } = e.target;

    if (name === "phoneNumber" && value.length > 10) showToast("warn", "Invalid Mobile Number");

    setNewUser(prevNU => {
      if (name === "email") {
        return {
          ...prevNU,
          [name]: value.toLowerCase().trim(),
        };
      }

      return {
        ...prevNU,
        [name]: value,
      };
    });
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (newUser.password === newUser.confirmPassword || newUser.confirmPassword === "")
        return setErrorMessage("");
      if (
        newUser.password !== newUser.confirmPassword ||
        newUser.password.length !== newUser.confirmPassword.length
      )
        return setErrorMessage("Password mismatched");
    }, 500);

    return () => clearTimeout(timeout);
  }, [newUser.password, newUser.confirmPassword]);

  //Submitting the from details
  const onSubmitRegisterForm = event => {
    event.preventDefault();

    const isValid = checkMandatoryFields(newUser, false);
    const isPasswordSame =
      newUser.password.length !== newUser.confirmPassword.length ||
      newUser.password !== newUser.confirmPassword;

    if (!isValid) return showToast("warn", "All fields are Mandatory");
    if (newUser.password.length < 6)
      return showToast("warn", "Password should contain at least 6 characters.");
    if (isPasswordSame) return showToast("warn", "Password does not match");
    if (newUser.phoneNumber.length > 10) return showToast("warn", "Invalid Mobile Number");

    //No need to trim password
    const valuesToTrim = {
      firstName: newUser.firstName,
      lastName: newUser.lastName,
      email: newUser.email,
      phoneNumber: newUser.phoneNumber,
      companyName: newUser.companyName,
    };

    const trimmedInputValues = trimValues(valuesToTrim);

    setLoading(true);
    userRegister({
      ...trimmedInputValues,
      password: newUser.password,
      clientURI: `${location.origin}/verify-user`,
    })
      .then(res => {
        if (res.data.statusCode === 300) {
          setErrorMessage(res.data.message);
          showToast("warn", res.data.message);
          setLoading(false);
          return;
        }

        if (res.data.statusCode !== 200) {
          setErrorMessage(res.data.message);
          setLoading(false);
          return;
        }

        showToast("success", "User Registered Successfully");
        setNewUser(initialState);
        setLoading(false);
        navigate("/login");
      })
      .catch(() => setLoading(false));
  };

  return (
    <section className="bg-gray-50 dark:bg-gray-900 min-h-[100vh]">
      <div className=" mx-auto max-w-2xl lg:py-4 m-auto overflow-x-hidden overflow-y-auto border border-teal-800 border-opacity-0 pt-[1.2rem]">
        <form className=" border border-red-600 border-opacity-0 space-y-4 md:space-y-6 px-7 py-4 bg-white rounded-lg shadow dark:border dark:bg-gray-800 dark:border-gray-700 mt-10 md:mt-10 ">
          <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
            Create an account
          </h1>
          <div className="grid gap-4 mb-4 md:gap-6 md:grid-cols-2 sm:mb-8">
            <InputFields
              inputArr={REGISTER_INPUT_FIELDS}
              inputClassName="w-full"
              onChange={onChangeHandler}
            />
          </div>

          {/* Error message */}

          <div
            className={`${
              errorMessage ? "opacity-100" : "opacity-0"
            } !mt-3 !mb-2 border border-red-700 w-full text-[14px] text-red-700 border-opacity-0 flex gap-1 pointer-events-none`}>
            <span>&#9888; {errorMessage ? errorMessage : "Error"}</span>
          </div>

          {/* Button to add user */}
          <button
            onClick={onSubmitRegisterForm}
            type="submit"
            disabled={loading}
            className={`w-full px-5 !mt-0 py-2.5 text-sm font-medium text-center text-white ${
              loading
                ? "bg-gray-400 hover:bg-gray-500 focus:bg-gray-500 dark:focus:ring-bg-gray-700 focus:ring-bg-gray-700"
                : " bg-thynkwebPrimary-700  dark:focus:ring-thynkwebPrimary-900 hover:bg-thynkwebPrimary-800  focus:ring-thynkwebPrimary-200"
            } rounded-lg focus:ring-4 cursor-pointer`}>
            {loading ? "Creating account..." : "Create an account"}
          </button>

          <p className="text-sm font-light text-gray-500 dark:text-gray-400 mt-2 text-center">
            Already have an account?{" "}
            <Link to={"/login"} className="font-medium text-[#2563eb]">
              Login here
            </Link>
          </p>
        </form>
      </div>
    </section>
  );
};

export default Register;
