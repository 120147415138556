/**
 * @namespace checkNullUndefined checks if a arguement is null or undefined
 * @param {(string|number)} value
 * @returns if `value` exist `value` else empty string
 */

import { parseISO, isEqual } from "date-fns";


export const checkNullUndefined = value => {
  return value ? value : "";
};


//capitalize first letter of every word in a string
export const capitalizeFirstLetter = str => {
  return str
    .split(" ")
    .map(word => word.toString().charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};


