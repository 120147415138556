import { RiPencilFill } from "react-icons/ri";
import EditBusiness from "./components/businesses/EditBusiness";
import { useState } from "react";

const BusinessDetails = ({
  toggleStates,
  updateToggleState,
  onHandleBusinesses,
  businesses,
  selectedBusiness,
  businessesFor,
  onHandleClients,
  clients,
  selectedClientBusiness,
  isClient,
}) => {
  const [toggleModalEdit, setToggleModalEdit] = useState(false);
  const {
    isSelectedBusiness,
    businessDetailsToggle,
    isSelectedClient,
    clientDetailsToggle,
    isChange,
  } = toggleStates;

  const onClickModalEdit = () => {
    setToggleModalEdit(true);
  };
  const onCloseModalEdit = () => {
    setToggleModalEdit(false);
  };

  return (
    <div>
      <div>
        {businessesFor === "business" && (
          <>
            <div className="mt-2">
              <select
                className="rounded-md w-full border-[1px] border-[#b4c2d5] bg-[#f7faff] cursor-pointer"
                onChange={e => onHandleBusinesses(e.target.value)}>
                <option
                  defaultValue="select business"
                  hidden={true}>
                  {selectedBusiness?.name ? selectedBusiness?.name : "Select Business"}
                </option>
                {businesses.map(ele => (
                  <option
                    key={ele.id}
                    value={ele.id}
                    className="cursor-pointer border border-red-600">
                    {ele.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="rounded-md pb-2 min-h-[18rem] min-[768px]:max-[1030px]:min-h-[13.5rem] border-[1px] border-[#b4c2d5] bg-[white] mt-4">
              <div className="flex items-center justify-between">
                <div className="px-2 py-3 text-[1rem] lg:text-xl">Business details</div>
                <div
                  onClick={onClickModalEdit}
                  className="flex justify-start gap-1 mr-6 mt-1 text-green-900 text-xl cursor-pointer ">
                  {selectedBusiness && isSelectedBusiness && (
                    <div
                      className="flex items-center gap-1 justify-start"
                      onClick={() => updateToggleState("businessDetailsToggle", true)}>
                      <RiPencilFill fill="#cdc733" />

                      <EditBusiness
                        show={toggleModalEdit}
                        onClose={onCloseModalEdit}
                        businessDetailsProps={selectedBusiness}
                        businessDetailsToggle={businessDetailsToggle}
                        isChange={isChange}
                        updateToggleState={updateToggleState}
                        selectedBusiness={selectedBusiness}
                        isClient={isClient}
                      />
                    </div>
                  )}
                </div>
              </div>
              <hr className="h-px bg-[#b4c2d5] border-0 dark:bg-gray-700"></hr>

              <div className=" mt-0 min-[0px]:max-[374px]:text-[0.7rem] min-[375px]:max-[500px]:text-[0.85rem] min-[768px]:max-[1030px]:text-[0.75rem] ">
                {!selectedBusiness?.name && (
                  <div className="text-center text-[1rem] lg:text-xl px-4 pt-[2rem] italic">
                    Select a business from the dropdowns
                    <br /> or <br />
                    click the '+' icon above to create a new business.
                  </div>
                )}
                {selectedBusiness?.name && (
                  <div className=" flex mt-2 ">
                    <span className=" text-[#647282] pl-[0.7rem] w-[30%] min-[768px]:max-[1050px]:w-[25%] min-[0px]:max-[374px]:w-[55%]">
                      Business Name
                    </span>
                    <span>{selectedBusiness?.name}</span>
                  </div>
                )}

                {selectedBusiness?.address && (
                  <div className=" flex mt-2">
                    <span className=" text-[#647282] pl-[0.7rem] w-[30%] min-[0px]:max-[374px]:w-[55%] min-[768px]:max-[1050px]:w-[25%]">
                      Address
                    </span>
                    <span className="w-[70%] break-words">{selectedBusiness?.address}</span>
                  </div>
                )}
                {selectedBusiness?.gstin && (
                  <div className=" flex mt-2 ">
                    <div className="text-[#647282] pl-[0.7rem]  w-[30%] min-[0px]:max-[374px]:w-[55%]  min-[768px]:max-[1050px]:w-[25%]">
                      GSTIN
                    </div>
                    <div>{selectedBusiness?.gstin}</div>
                  </div>
                )}
                {selectedBusiness?.pan && (
                  <div className=" flex mt-2 ">
                    <div className=" text-[#647282] pl-[0.7rem] w-[30%] min-[0px]:max-[374px]:w-[55%]  min-[768px]:max-[1050px]:w-[25%]">
                      PAN
                    </div>
                    <div>{selectedBusiness?.pan}</div>
                  </div>
                )}
                {selectedBusiness?.email && (
                  <div className=" flex mt-2 ">
                    <div className="text-[#647282] pl-[0.7rem] w-[30%] min-[0px]:max-[374px]:w-[55%]  min-[768px]:max-[1050px]:w-[25%]">
                      Email
                    </div>
                    <div>{selectedBusiness?.email}</div>
                  </div>
                )}
                {selectedBusiness?.phoneNumber && (
                  <div className=" flex mt-2">
                    <div className="text-[#647282] pl-[0.7rem] w-[30%] min-[0px]:max-[374px]:w-[55%]  min-[768px]:max-[1050px]:w-[25%]">
                      Phone
                    </div>
                    <div>{selectedBusiness?.phoneNumber}</div>
                  </div>
                )}
              </div>
            </div>
          </>
        )}
      </div>
      <div>
        {businessesFor === "clients" && (
          <>
            <div className="mt-2">
              <select
                className="rounded-md w-full  border-[1px] border-[#b4c2d5] bg-[#f7faff] cursor-pointer"
                onChange={onHandleClients}>
                <option
                  defaultValue="select client"
                  hidden={true}>
                  {/* Select Client */}
                  {selectedClientBusiness?.name ? selectedClientBusiness?.name : "Select Client"}
                </option>
                {clients.map(ele => (
                  <option
                    key={ele.id}
                    value={ele.id}>
                    {ele.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="rounded-md pb-2 border-[1px] border-[#b4c2d5] min-h-[18rem] min-[768px]:max-[1030px]:min-h-[13.5rem] bg-[white] mt-4">
              <div className="flex items-center justify-between">
                <div className="px-2 py-3 text-[1rem] lg:text-xl">Client details</div>
                <div
                  onClick={onClickModalEdit}
                  className="flex justify-start gap-1 mr-6 mt-1 text-green-900 text-xl cursor-pointer">
                  {isSelectedClient && (
                    <div
                      className="flex items-center gap-1 justify-start h-3"
                      onClick={() => updateToggleState("clientDetailsToggle", true)}>
                      <RiPencilFill fill="#cdc733 " />
                      <EditBusiness
                        show={toggleModalEdit}
                        onClose={onCloseModalEdit}
                        clientDetailsProps={selectedClientBusiness}
                        clientDetailsToggle={clientDetailsToggle}
                        isChange={isChange}
                        updateToggleState={updateToggleState}
                        isClient={isClient}
                      />
                    </div>
                  )}
                </div>
              </div>
              <hr className="h-px bg-[#b4c2d5] border-0 dark:bg-gray-700"></hr>

              <div className="mt-0 min-[0px]:max-[374px]:text-[0.7rem] min-[375px]:max-[500px]:text-[0.85rem] min-[768px]:max-[1030px]:text-[0.75rem] ">
                {!selectedClientBusiness?.name ? (
                  <span className="text-center text-[1rem] lg:text-xl pt-[2rem] block px-4 italic">
                    Select a client from the dropdowns
                    <br />
                    or
                    <br />
                    click the '+' icon above to create a new client.
                  </span>
                ) : (
                  <div className="flex mt-2">
                    <span className="text-[#647282] pl-[0.7rem] w-[30%] min-[0px]:max-[374px]:w-[55%] min-[768px]:max-[1050px]:w-[25%]">
                      Client Name
                    </span>
                    <span>{selectedClientBusiness?.name}</span>
                  </div>
                )}
                {selectedClientBusiness?.address && (
                  <div className="flex mt-2">
                    <span className="text-[#647282] pl-[0.7rem] w-[30%] min-[0px]:max-[374px]:w-[55%] min-[768px]:max-[1050px]:w-[25%]">
                      Address
                    </span>
                    <span className="breaks-words w-[70%]">{selectedClientBusiness?.address}</span>
                  </div>
                )}
                {selectedClientBusiness?.gstin && (
                  <div className="flex mt-2">
                    <span className="text-[#647282] pl-[0.7rem]  w-[30%] min-[0px]:max-[374px]:w-[55%] min-[768px]:max-[1050px]:w-[25%]">
                      GSTIN
                    </span>
                    <span>{selectedClientBusiness?.gstin}</span>
                  </div>
                )}
                {selectedClientBusiness?.pan && (
                  <div className="flex mt-2">
                    <span className="text-[#647282] pl-[0.7rem] w-[30%] min-[0px]:max-[374px]:w-[55%] min-[768px]:max-[1050px]:w-[25%]">
                      PAN
                    </span>
                    <span>{selectedClientBusiness?.pan}</span>
                  </div>
                )}
                {selectedClientBusiness?.email && (
                  <div className="flex mt-2">
                    <span className=" text-[#647282] pl-[0.7rem] w-[30%] min-[0px]:max-[374px]:w-[55%] min-[768px]:max-[1050px]:w-[25%]">
                      Email
                    </span>
                    <span>{selectedClientBusiness?.email}</span>
                  </div>
                )}
                {selectedClientBusiness?.phoneNumber && (
                  <div className="flex mt-2">
                    <span className="text-[#647282] pl-[0.7rem] w-[30%] min-[0px]:max-[374px]:w-[55%] min-[768px]:max-[1050px]:w-[25%]">
                      Phone
                    </span>
                    <span>{selectedClientBusiness?.phoneNumber}</span>
                  </div>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default BusinessDetails;
