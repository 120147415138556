import { useMemo } from "react";

const commonStyles =
  "text-md font-[500] !text-#000  bg-white px-4 py-2 transition-colors duration-300 cursor-pointer block relative";
const activeStyle = `${commonStyles} !bg-[#dbeafe] after:content-[''] after:absolute after:right-0 after:left-0 after:top-[94.5%] overflow-hidden after:bottom-0 after:bg-[#3a71c4] text-[#3a71c4] rounded-md after-w-full after:h-[2px]`;

const Tabs = ({ tabs, activeTab, onClickActiveTabHandler }) => {
  const memoizedTabs = useMemo(
    () =>
      tabs.map(tab => (
        <span
          key={tab.tab}
          data-tab={tab.tab}
          className={tab.tab === activeTab ? activeStyle : commonStyles}>
          {tab.name}
        </span>
      )),
    [activeTab, tabs.length]
  );

  return (
    <div className="flex" onClick={onClickActiveTabHandler}>
      {memoizedTabs}
    </div>
  );
};

export default Tabs;
