import React, { useContext, useEffect, useState } from "react";
import { ThynkWebLogoSvg } from "../media/ThynkWebLogoSvg";
import { Button } from "flowbite-react";
import { btnPrimary } from "../utils/helper";
import { useNavigate, useParams } from "react-router";
import { Link, useSearchParams } from "react-router-dom";
import { verifyUserDetails } from "../services/AccountService";
import toast, { Toaster } from "react-hot-toast";
import { AuthContext } from "../context/AuthContext";
import { showToast } from "../components/common/Toast";
import { VERIFY_USER_MESSAGES } from "../constants/Messages";
import jwtDecode from "jwt-decode";
import InputFields from "../components/common/Input/InputFields";

const initialState = {
  email: "",
  password: "",
};

const INPUT_FIELDS_CLASS =
  "bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-thynkwebPrimary-600 focus:border-thynkwebPrimary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-thynkwebPrimary-500 dark:focus:border-thynkwebPrimary-500";

const VerifyUser = () => {
  //State to store user initial details
  const [userDetails, setUserDetails] = useState(initialState);

  const navigate = useNavigate();

  //State to manage the token expiry
  const [tokenExpired, setTokenExpired] = useState(false);

  //Storing user details from session storage
  const { setUser } = useContext(AuthContext);

  //getting users token and email from url
  const { token: pathToken, email: pathEmail } = useParams();

  //Get token & email from url query params
  const [searchParams] = useSearchParams();
  const queryToken = searchParams.get("token");
  const queryEmail = searchParams.get("email");

  //Determine correct token/email
  const token = queryToken || pathToken;
  const email = queryEmail || pathEmail;

  //Checking if token is expired whenever user visits verify password page.
  useEffect(() => {
    if (token && isTokenExpired(token)) {
      showToast("error", VERIFY_USER_MESSAGES.EXPIRED_TOKEN);
      setTokenExpired(true);
    } else {
      setTokenExpired(false);
    }
  }, []);

  //Function to check if token is expired or not
  const isTokenExpired = token => {
    const decodedToken = jwtDecode(token);
    if (decodedToken && decodedToken.exp) {
      //Converting expiration time to milliseconds
      const expirationTime = decodedToken.exp * 1000;

      //Getting current time(UTC) in milliseconds
      const currentTime = Date.now();
      return currentTime > expirationTime;
    }
    return true;
  };

  //Function to get user form values
  const onChangeUserVerifyDetails = event => {
    const { name, value } = event.target;
    setUserDetails(prevUD => {
      return {
        ...prevUD,
        [name]: value,
      };
    });
  };

  //Function to submit users verification details
  const onSubmitUserDetails = async () => {
    const userInfo = { ...userDetails, token: token, email: email };

    if (userInfo.password.trim() === "") {
      showToast("error", VERIFY_USER_MESSAGES.PASSWORD_ERROR);
      return;
    }

    const response = await verifyUserDetails(userInfo);

    if (response.status >= 200 && response.status < 300) {
      const userData = response?.data?.data;
      sessionStorage.setItem("user", JSON.stringify(userData));
      setUser(userData);
      showToast("success", VERIFY_USER_MESSAGES.SUCCESS);
      navigate("/");
    } else if (response.status >= 400 && response.status < 500) {
      showToast("error", VERIFY_USER_MESSAGES.FAIL);
    } else if (response.status >= 500 && response.status < 600) {
      showToast("error", VERIFY_USER_MESSAGES.FAIL);
    }
  };
  return (
    <>
      <div className="verify-password-container  w-full m-0 box-border h-screen flex">
        <div className="verify-password-sub-container w-full h-[65%] m-1 mt-[10%] grid grid-cols-[1fr_1fr] grid-rows-1 p-1 gap-1">
          <div className=" h-full p-1">
            <div className="  h-full">
              <img
                width={"100%"}
                height={"100%"}
                className="w-full h-full object-fill"
                src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/hero/mobile-app.svg"
                alt=""
              />
            </div>
          </div>
          <div className=" h-full flex flex-col justify-between">
            <div className="logo-container  min-h-[20%] flex justify-start items-center">
              <div className=" w-fit">
                <ThynkWebLogoSvg w={60} h={60} />
              </div>
            </div>
            <div className="text-container  min-h-[25%] flex flex-col justify-between">
              <h2 className="font-bold text-[18px] mt-1">Verify Email Address</h2>
              <div className="">
                <p className="">
                  Enter your ThynkWeb account password to verify your email address
                </p>
                <p className="font-semibold -mt-1">{email}</p>
              </div>
            </div>
            <div className="button-container min-h-[20%]">
              <div className=" w-[50%]">
                <InputFields
                  InputField={{
                    id: "new-password",
                    type: "password",
                    name: "password",
                    placeholder: "••••••••",
                    required: true,
                  }}
                  className={INPUT_FIELDS_CLASS}
                  onChange={onChangeUserVerifyDetails}
                  disabled={tokenExpired}
                  value={userDetails.password}
                />
              </div>
              <span className="text-[#2563eb] hover:underline dark:text-[#2563eb] text-[0.95rem] cursor-pointer">
                {!tokenExpired && <Link to={"/forgot-password"}>Forgot Password?</Link>}
              </span>
            </div>
            <div className="verify-button-container  min-h-[19%] flex flex-col justify-between">
              <p className="text-[13px]">
                By clicking Verify, you agree to the{" "}
                <span className="text-[#2563eb] cursor-pointer">Terms of Service</span> and{" "}
                <span className="text-[#2563eb] cursor-pointer">Privacy Policy</span>
              </p>
              <Button
                className={`${btnPrimary()} w-[20%] !py-0`}
                onClick={onSubmitUserDetails}
                disabled={tokenExpired}>
                Verify
              </Button>
            </div>
          </div>
        </div>
      </div>
      <Toaster />
    </>
  );
};

export default VerifyUser;
