import { useEffect, useState } from "react";
import { getLeaveTypes } from "../../../services/LeaveService";

export const AddEmploymentTypeBody = ({
  pageNo,
  pageSize,
  renderAddEmploymentTypeModal,
  handleAddEmploymentType,
  handleLeaveTypes,
  handleSubmit,
  formRef,
}) => {
  const [leaveTypes, setLeaveTypes] = useState([]);

  const getAllLeaveTypes = async () => {
    const response = await getLeaveTypes(pageNo, pageSize);

    const allLeaveTypes = response?.data;
    setLeaveTypes(allLeaveTypes);
    sessionStorage.removeItem("renderAddEmploymentType");
  };

  useEffect(() => {
    getAllLeaveTypes();
  }, [renderAddEmploymentTypeModal]);

  return (
    <section className="bg-white mt-4 dark:bg-gray-900">
      <div className="-mb-12 px-4 mx-auto max-w-2xl lg:py-4 m-auto overflow-x-hidden overflow-y-auto">
        <form ref={formRef}>
          <div className="grid gap-4 mb-4 md:gap-6 md:grid-cols-2 sm:mb-8">
            {/* Employment Type Name*/}
            <div>
              <label
                htmlFor="employment-type-name"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                Employment Type Name
              </label>

              <input
                type="text"
                name="name"
                id="employment-type-name"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-thynkwebPrimary-600 focus:border-thynkwebPrimary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-thynkwebPrimary-500 dark:focus:border-thynkwebPrimary-500"
                placeholder="Employment Type Name"
                onChange={handleAddEmploymentType}
              />
            </div>
          </div>

          {/* Employment Type Description */}
          <div className="mt-4">
            <label
              htmlFor="employment-type-description"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
              Description
            </label>

            <textarea
              type="text"
              name="description"
              rows="3"
              id="employment-type-description"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-thynkwebPrimary-600 focus:border-thynkwebPrimary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-thynkwebPrimary-500 dark:focus:border-thynkwebPrimary-500"
              placeholder="Employment type description"
              onChange={handleAddEmploymentType}
            />
          </div>

          <div className="mt-6">
            <label
              htmlFor="leave-types"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
              Leave Types :
            </label>
          </div>
          <div className="grid gap-4 mt-2 mb-4 md:gap-6 md:grid-cols-2 sm:mb-8">
            {leaveTypes?.map((type, i) => {
              return (
                <div key={i}>
                  <label
                    htmlFor={`leave-type-${i}`}
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                    {type?.name}{" "}
                    <span className="mb-2 text-sm font-medium text-gray-500 ">
                      <i>(leaves allowed in a year)</i>
                    </span>
                  </label>

                  <input
                    type="number"
                    name="leavesAllowedInYear"
                    id={`leave-type-${i}`}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-thynkwebPrimary-600 focus:border-thynkwebPrimary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-thynkwebPrimary-500 dark:focus:border-thynkwebPrimary-500"
                    placeholder={`${type?.name} (leaves allowed in year)`}
                    onChange={e => handleLeaveTypes(e, type?.id, type?.name)}
                  />
                </div>
              );
            })}
          </div>

          {/* Button to Add Employment Type */}
          <div className="mb-2 flex justify-end">
            <button
              type="submit"
              onClick={handleSubmit}
              className="px-5 py-2.5 mt-8 text-sm font-medium text-center text-white bg-thynkwebPrimary-700 rounded-lg focus:ring-4 focus:ring-thynkwebPrimary-200 dark:focus:ring-thynkwebPrimary-900 hover:bg-thynkwebPrimary-800">
              Add
            </button>
          </div>
        </form>
      </div>
    </section>
  );
};
