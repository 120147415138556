import { useState, useEffect, useMemo } from "react";
import { Button } from "flowbite-react";

import { showToast } from "../../common/Toast";
import FormInput from "../../common/FormInput";
import StateSelect from "../components/StateSelect";
import { AddNewAddress } from "../../../services/AddressService";
import ModalComponent from "./../../common/Modal/Modal";
import SelectComponent from "./../../Settings/controlPanelSettings/SelectComponent/SelectComponent";

import { addressModal } from "../helper";
import { btnPrimary } from "../../../utils/helper";
import useCountriesAPI from "../../../hooks/useCountriesAPI";

const defaultClass =
  "w-full mb-4 p-2 capitalize border-t-0 border-l-0 border-r-0 border-b focus:ring-0 border-b-gray-400 focus:border-b focus:border-b-gray-900 hover:border-b-gray-900";

const AddAddress = ({ show, setToggleStates, isClient }) => {
  const [newAddress, setNewAddress] = useState(addressModal);
  const { countries } = useCountriesAPI();

  const handleAddressChange = event => {
    let { name, value } = event.target;

    setNewAddress(prev => {
      return {
        ...prev,
        [name]: value,
        isClient: isClient,
      };
    });
  };

  const resetStates = () => {
    setToggleStates(prevTS => ({
      ...prevTS,
      toggleShippingModal: !prevTS.toggleShippingModal,
    }));
    setNewAddress(addressModal);
  };

  const saveNewAddress = e => {
    e.preventDefault();

    AddNewAddress(newAddress).then(res => {
      if (res.statusCode == 200) {
        showToast("success", "New address added successfully");
        resetStates();
      }
    });
  };

  return (
    <ModalComponent
      show={show}
      onClose={resetStates}
      showFooter={false}
      heading="Add Address">
      <form>
        <FormInput
          className={defaultClass}
          placeholder={"Business name"}
          name="businessName"
          value={newAddress?.businessName}
          onChange={handleAddressChange}
        />
        <SelectComponent
          onChangeHandler={handleAddressChange}
          optionsArr={useMemo(() => ["Select Country", ...countries], [countries])}
          defaultValue={newAddress.country ? newAddress.country : "Select country"}
          capFirstLetter={true}
          nameAttr="country"
          selectClass="mb-4"
        />

        <FormInput
          className={defaultClass}
          value={newAddress?.address}
          name="address"
          placeholder={"Address (Optional)"}
          onChange={handleAddressChange}
        />
        <FormInput
          name="city"
          value={newAddress?.city}
          placeholder={"City (Optional)"}
          className={`!w-[49%] p-2 mb-4 mr-[1%] ${defaultClass}`}
          onChange={handleAddressChange}
        />
        <FormInput
          className={`!w-[49%] p-2 mb-4 ${defaultClass}`}
          name="postalCode"
          value={newAddress?.postalCode}
          onChange={handleAddressChange}
          placeholder={"Postal Code / Zip code"}
        />

        {newAddress?.country.toLowerCase() != "india" ? (
          <FormInput
            className={defaultClass}
            name="state"
            placeholder={"State (Optional)"}
            value={newAddress?.state}
            key={"state-select-input"}
            onChange={handleAddressChange}
          />
        ) : (
          <StateSelect
            className="my-4 mb-4"
            value={newAddress?.state}
            name="state"
            onChange={handleAddressChange}
          />
        )}

        <FormInput
          name="gstin"
          value={newAddress?.gstin}
          className={defaultClass}
          onChange={handleAddressChange}
          placeholder={"Enter your GSTIN here(optional)"}
        />
        <div>
          <Button
            size="sm"
            onClick={saveNewAddress}
            className={`${btnPrimary()} ml-auto mr-3 mt-4`}>
            Add Address
          </Button>
        </div>
      </form>
    </ModalComponent>
  );
};

export default AddAddress;
