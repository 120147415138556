import { radioPrimaryClass } from "../../utils/helper";

/**
 * Renders the ApplyLeaveNormal component.
 *
 * @param {{leavesInfo: Object, handleFirst_Second_Checkbox: Function, handleApplyLeave: Function}} props - the props
 * @param {Object} leavesInfo - the leaves information
 * @param {Function} handleFirst_Second_Checkbox - the handler for the first and second half checkbox
 * @param {Function} handleApplyLeave - the handler for applying leave
 * @return {JSX.Element} the ApplyLeaveNormal component
 */
const ApplyLeaveNormal = ({ leavesInfo, handleFirst_Second_Checkbox, handleApplyLeave }) => {
  return (
    <>
      {/* Start Date */}
      <div>
        <label
          htmlFor="startdate"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
          Start Date <span className="text-red-700">*</span>
        </label>

        {/* First half and Second half checkbox */}
        <div className="flex items-center -ml-4 mb-1">
          {/* First Half checkbox */}
          <input
            id="StartDate-First-Half-checkbox"
            type="checkbox"
            name="StartFirstHalf"
            checked={leavesInfo.startFirstHalf}
            onChange={handleFirst_Second_Checkbox}
            className={`rounded ml-4 w-3.5 h-3.5 ${radioPrimaryClass}`}
          />
          <label
            htmlFor="StartDate-First-Half-checkbox"
            className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
            First Half
          </label>

          {/* Second Half checkbox */}
          <input
            id="StartDate-Second-Half-checkbox"
            type="checkbox"
            name="StartSecondHalf"
            checked={leavesInfo.startSecondHalf}
            onChange={handleFirst_Second_Checkbox}
            className={`rounded ml-4 w-3.5 h-3.5 ${radioPrimaryClass}`}
          />
          <label
            htmlFor="StartDate-Second-Half-checkbox"
            className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
            Second Half
          </label>
        </div>

        <input
          type="Date"
          name="startDate"
          id="startdate"
          value={leavesInfo.startDate}
          onChange={handleApplyLeave}
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-thynkwebPrimary-600 focus:border-thynkwebPrimary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-thynkwebPrimary-500 dark:focus:border-thynkwebPrimary-500"
          placeholder="Start Date"
          required
        />
      </div>

      {/* End Date */}
      <div>
        <label
          htmlFor="enddate"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
          End Date <span className="text-red-700">*</span>
        </label>

        {/* First half and Second half checkbox */}
        <div className="flex items-center -ml-4 mb-1">
          {/* First Half checkbox */}
          <input
            id="EndDate-First-Half-checkbox"
            type="checkbox"
            name="EndFirstHalf"
            checked={leavesInfo.endFirstHalf}
            onChange={handleFirst_Second_Checkbox}
            className={`rounded ml-4 w-3.5 h-3.5 ${radioPrimaryClass}`}
          />
          <label
            htmlFor="EndDate-First-Half-checkbox"
            className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
            First Half
          </label>

          {/* Second Half checkbox */}
          <input
            id="EndDate-Second-Half-checkbox"
            type="checkbox"
            name="EndSecondHalf"
            checked={leavesInfo.endSecondHalf}
            onChange={handleFirst_Second_Checkbox}
            className={`rounded ml-4 w-3.5 h-3.5 ${radioPrimaryClass}`}
          />
          <label
            htmlFor="EndDate-Second-Half-checkbox"
            className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
            Second Half
          </label>
        </div>

        <input
          type="Date"
          name="endDate"
          id="enddate"
          value={leavesInfo.endDate}
          onChange={handleApplyLeave}
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-thynkwebPrimary-600 focus:border-thynkwebPrimary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-thynkwebPrimary-500 dark:focus:border-thynkwebPrimary-500"
          placeholder="End Date"
          required
        />
      </div>
    </>
  );
};

export default ApplyLeaveNormal;
