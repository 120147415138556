import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  pageSize: 12,
  pageNo: 1,
  totalPages: 1,
};

const paginationSlice = createSlice({
  name: "pagination",
  initialState,
  reducers: {
    /**
     * Sets the page size in the state.
     *
     * @param {object} state - The current state.
     * @param {object} action - The action object containing the payload.
     * @param {number} action.payload - The page size to set.
     */
    setPageSize: (state, action) => {
      state.pageSize = action.payload;
    },

    /**
     * Set the page number in the given state object.
     *
     * @param {Object} state - The state object.
     * @param {Object} action - The action object.
     * @param {number} action.payload - The page number to set.
     */
    setPageNo: (state, action) => {
      state.pageNo = action.payload;
    },

    /**
     * Sets the total number of pages based on the given payload and the current page size.
     *
     * @param {Object} state - The current state object.
     * @param {Object} action - The action object containing the payload.
     * @param {number} action.payload - The total no of pages.
     */
    setTotalPages: (state, action) => {
      state.totalPages = Math.ceil(+action.payload / +state.pageSize);
    },
  },
});

export const { setPageNo, setPageSize, setTotalPages } = paginationSlice.actions;

export default paginationSlice.reducer;
