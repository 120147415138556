import { memo } from "react";
import { CardItem } from "./CardItem";

export const DetailCard = memo(props => {
  const { head = "Enter heading...", data = [] } = props;
  return (
    <>
      <div className="p-4 mb-4 bg-white rounded-lg shadow sm:p-6 xl:p-8 dark:bg-gray-800">
        <h3 className="mb-4 text-xl font-bold dark:text-white">{head}</h3>
        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
          {data.map(item => {
            return <CardItem value={item.value} key={item.id} subHead={item.subHead} />;
          })}
        </dl>
      </div>
    </>
  );
});
