import { useSearchParams } from "react-router-dom";

import ManageEmployees from "../components/EmployeeManagement/ViewDetails/ManageEmployees";
import ViewUserDetails from "../components/EmployeeManagement/ViewDetails/ViewUserDetails";

export const EmployeeManagement = () => {
  const [searchParams, _] = useSearchParams();
  return (
    <>
      {!searchParams.get("userId") && <ManageEmployees />}
      {searchParams.get("userId") !== null && <ViewUserDetails />}
    </>
  );
};
