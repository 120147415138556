import { useEffect, useRef, useState } from "react";

import ModalComponent from "../../../common/Modal/Modal";
import { showToast } from "../../../common/Toast";
import SidebarComponent from "../../../common/Sidebar/Sidebar";
import Tabs from "./Sidebar/Tabs/Tabs";

import { DEFAULT_FILE_SIZE_LIMIT_IN_BYTES } from "../../../../constants/Constant";
import {
  addBusinessLogo,
  deleteBusinessLogo,
  editBusinesses,
} from "../../../../services/BusinessService";
import { createAddressStr } from "../../helper";
import { sidebarTabs } from "../../helper";
import { trimValues } from "../../../../utils/helper";
import { ADD_BUSINESS_MESSAGES } from "../../../../constants/Messages";

const initialData = {
  clientLogo: null,
  logoBytes: null,
  name: "",
  address: "",
};

const initialFileDataState = {
  url: null,
  fileData: null,
};

/**
 * Renders the EditBusiness component.
 *
 * @param {object} businessData - the business data
 * @param {object} businessDetailsProps - the business details props
 * @param {boolean} businessDetailsToggle - the business details toggle
 * @param {object} clientDetailsProps - the client details props
 * @param {boolean} clientDetailsToggle - the client details toggle
 * @param {boolean} businessDataToggle - the business data toggle
 * @param {function} updateToggleState - the function to update the toggle state
 * @param {boolean} isChange - indicates if there is a change
 * @param {boolean} isClient - indicates if it is a client
 * @return {JSX.Element} the EditBusiness component
 */
const EditBusiness = ({
  businessData,
  businessDetailsProps,
  businessDetailsToggle,
  clientDetailsProps,
  clientDetailsToggle,
  businessDataToggle,
  updateToggleState,
  isChange,
  isClient,
}) => {
  const [fileData, setFileData] = useState(initialFileDataState);
  const [business, setBusiness] = useState(initialData);
  const [toggleEditBusiness, setToggleEditBusiness] = useState(false);
  const [activeItem, setActiveItem] = useState("basicInfo");
  const formRef = useRef(null);
  const [prevUploadedLogo, setPrevUploadedLogo] = useState(null);

  useEffect(() => {
    //Getting data from different pages where user clicks on the Edit business or client button

    if (businessDataToggle) {
      setBusiness(businessData);
    } else if (businessDetailsToggle) {
      setBusiness(businessDetailsProps);
    } else if (clientDetailsToggle) {
      setBusiness(clientDetailsProps);
    }
  }, [
    businessDataToggle,
    businessDetailsToggle,
    businessData,
    businessDetailsProps,
    clientDetailsToggle,
    clientDetailsProps,
  ]);

  const handleBusinessData = e => {
    const { name, value } = e.target;

    if (name === "ifsc") {
      const uppercaseValue = value.toUpperCase();

      setBusiness({
        ...business,
        [name]: uppercaseValue,
      });
    } else {
      setBusiness({
        ...business,
        [name]: value,
      });
    }
  };

  const handleFileChange = event => {
    const selectedFile = event.target.files[0];
    const fileSizeLimit = DEFAULT_FILE_SIZE_LIMIT_IN_BYTES;

    if (
      selectedFile &&
      (selectedFile.type === "image/png" ||
        selectedFile.type === "image/jpeg" ||
        selectedFile.type === "image/jpg")
    ) {
      const link = URL.createObjectURL(selectedFile);
      const isFileValid = selectedFile.size <= fileSizeLimit;

      if (isFileValid) {
        setFileData({ url: link, fileData: selectedFile });

        if (business.logoBytes) {
          setPrevUploadedLogo(business.logoBytes);

          //change the logo bytes to null
          setBusiness(prevB => ({ ...prevB, logoBytes: null }));

          showToast("success", ADD_BUSINESS_MESSAGES.LOGO_CHANGES_NOT_SAVED, {
            duration: 3000,
          });
        }
      }
      if (!isFileValid) {
        setFileData(initialFileDataState);
        showToast(
          "info",
          "Please select an image that is 1080x1080px or smaller and up to 5MB in size.",
          "top-center",
          2000
        );
      }
    } else {
      setFileData(initialFileDataState);
      formRef.current.reset();
      showToast("info", "Please select a PNG or JPEG/JPG file.", "top-center");

      //if user does not select the file again then set the previously uploaded file again
      if (prevUploadedLogo) setBusiness(prevB => ({ ...prevB, logoBytes: prevUploadedLogo }));
    }
  };

  //submitting business details
  const onSubmitEditHandler = async e => {
    e.preventDefault();

    if (business.name === undefined)
      return showToast("info", "Business Name Required", "top-center");

    try {
      const address = createAddressStr(business);
      const trimmedObj = trimValues({ ...business, address });

      const updatedDetails = {
        ...trimmedObj,
        address,
        postalCode: trimmedObj?.postalCode ? +trimmedObj.postalCode : null,
      };

      const editBusinessPromise = editBusinesses(updatedDetails);

      const editLogoPromise =
        business.id && business.id > 0 && fileData.fileData
          ? addBusinessLogo(business.id, fileData.fileData)
          : Promise.resolve();
      await Promise.allSettled([editBusinessPromise, editLogoPromise]);

      onCloseModalHandler();
      showToast("success", `Edited ${isClient ? "Billed To" : "Billed By"} successfully`);
    } catch (error) {
      console.error("Error occurred:", error);
    }
  };

  const onCloseModalHandler = () => {
    setFileData(initialFileDataState);
    updateToggleState("isChange", !isChange);
    setActiveItem("basicInfo");
    setBusiness(initialData);
    setToggleEditBusiness(false);
    formRef.current.reset();
  };

  const onDeleteLogoHandler = async id => {
    /***** NOTE -this will run for both cases when user has uploaded a logo and trying to change the logo (we set the logoBytes to null)
    and and also when user has not uploaded a logo ****/
    if (!business.logoBytes) {
      //if user has not upload any image then set the fileData state to initial state
      setFileData(initialFileDataState);

      //if user has uploaded a logo before and it's saved in the database then assign that logo again to the logoBytes
      //else it'll set the logoBytes to null
      setBusiness(prevB => ({
        ...prevB,
        logoBytes: prevUploadedLogo,
      }));
      setPrevUploadedLogo(null);
      showToast("success", ADD_BUSINESS_MESSAGES.REMOVE_UNSAVED_LOGO);
      return;
    }

    //when user has uploaded a logo and wants to delete it
    const res = await deleteBusinessLogo(id);
    if (res.data.statusCode !== 200) showToast("error", res.data.message, "top-center");

    if (res.data.statusCode === 200) {
      showToast("success", ADD_BUSINESS_MESSAGES.LOGO_DELETE);
      formRef.current.reset();

      //set the logoBytes key to null
      setBusiness(prevB => ({ ...prevB, logoBytes: null }));
      if (fileData.url) setFileData(initialFileDataState);
    }
  };

  return (
    <div>
      <button className="text-[1rem] lg:text-xl" onClick={() => setToggleEditBusiness(true)}>
        Edit
      </button>
      <ModalComponent
        heading="Edit Business"
        onClose={onCloseModalHandler}
        show={toggleEditBusiness}
        size="4xl"
        showFooter={false}>
        <form ref={formRef} className="flex gap-4" encType="multipart/form-data">
          <SidebarComponent
            options={sidebarTabs}
            activeItem={activeItem}
            disabled={business?.name?.trim() === ""}
            handleSubmit={onSubmitEditHandler}
            isEditing={false}
            onClickSideBarItem={setActiveItem}
          />

          <div className="border mx-2"></div>

          <Tabs
            isClient={isClient}
            handleFileChange={handleFileChange}
            onInputChange={handleBusinessData}
            details={business}
            activeItem={activeItem}
            fileData={fileData}
            onDeleteLogoHandler={onDeleteLogoHandler}
          />
        </form>
      </ModalComponent>
    </div>
  );
};

export default EditBusiness;
