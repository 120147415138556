import { salaryState } from "../pages/SalaryComponents/salaryState";
import { organizationState } from "../pages/OrganizationDetails/organizationState";
import { attendanceState } from "../pages/Attendance Management/globalState";
import { multipleFormsState } from "../pages/MultiStepForm/multipleFormsState";
import { payrollState } from "../components/Payroll/payrollState";

const initialContextStates = {
  headerState: {
    isCheckedInCheckedOut: null,
  },

  editTime: {
    checkinTime: {
      hours: null,
      minutes: null,
      ampm: null,
    },
    checkoutTime: {
      hours: null,
      minutes: null,
      ampm: null,
    },
  },
  attendanceState: attendanceState,
  salaryState: salaryState,
  organizationState: organizationState,
  multipleFormsState: multipleFormsState,
  isSidebarActive: false,
  userInfoById: {},
  orgCompletionStatus: {
    isCompleted: false,
    status: {},
  },
  payrollState: payrollState,
};

export default initialContextStates;
