import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import ShippingDetails from "../../components/invoices/ShippingDetails";
import Total from "../../components/invoices/components/Total";
import ItemTable from "../../components/invoices/components/ItemTable";
import InputFields from "../../components/common/Input/InputFields";
import InvoiceHeader from "../../components/invoices/components/InvoiceHeader";
import InvoiceDate from "../../components/invoices/components/InvoiceDate";
import TaxButtons from "../../components/invoices/components/TaxButtons";
import Billing from "../../components/invoices/components/Billing";
import Legal from "../../components/invoices/components/Legal";
import SubmitButton from "../../components/invoices/components/SubmitButton";
import AddNewItem from "../../components/invoices/components/AddNewItem";
import { Footer } from "../../components/common/Footer";

import {
  toggleInitialState,
  allAmountInitialState,
  initialFormValues,
  firstInvoiceItem,
  getCurrentDate,
  addShippingAddress,
} from "../../components/invoices/helper";
import { getBusinesses} from "../../services/BusinessService";
import { radioPrimaryClass } from "../../utils/helper";
import {
  fetchCustomFields,
  getAllTAndC,
  getAllNotes,
} from "../../components/invoices/customFieldHelper";
import { DEFAULT_PAGE_NO, DEFAULT_PAGE_SIZE } from "../../constants/Constant";
import { getInvoiceNumber } from "../../services/InvoiceService";
import { setLoadingFirstTC } from "../../store/slices/TCSlice";

//temp variable for api call
const isClient = false;

const CreateInvoice = () => {
  const dispatch = useDispatch();
  const { isLoadingFirstTC } = useSelector(state => state.TC);

  //all the toggle states of form
  const [toggleStates, setToggleStates] = useState(toggleInitialState);
  const [date, setDate] = useState({
    futureDate: "",
    currentDate: "",
  });
  const [billed, setBilled] = useState({
    billedToId: "",
    billedById: "",
  });
  const [formValues, setFormValues] = useState(initialFormValues);
  const [allAmounts, setAllAmounts] = useState(allAmountInitialState);

  useEffect(() => {
    const { selectedBusiness } = formValues;
    if (selectedBusiness) {
      const base64Data = selectedBusiness?.logoBytes;
      const imageSrc = `data:image/jpeg;base64,${base64Data}`;
      setFormValues(prevFV => ({ ...prevFV, logoUrl: base64Data ? imageSrc : "" }));
    }
  }, [formValues.selectedBusiness]);

  useEffect(() => {
    const { businesses } = formValues;
    //billedById is empty string when user create invoice and if database has businesses array
    //then show first item as selected value
    if (billed.billedById === "" && businesses.length > 0) {
      const firstBusiness = businesses[0];
      setFormValues(prevFV => ({ ...prevFV, selectedBusiness: firstBusiness }));
      updateToggleState("isSelectedBusiness", true);
      setBilled(prevB => ({ ...prevB, billedById: firstBusiness?.id }));
    }
  }, [formValues.businesses.length > 0]);

  useEffect(() => {
    //fetch getInvoiceNumber, fetchCustomFields, getAllTAndCin parallel
    Promise.allSettled([
      getInvoiceNum(),
      fetchCustomFields(setFormValues),
      getAllTAndC(setFormValues, updateToggleState),
      getAllNotes(setFormValues, updateToggleState),
    ]);

    //first item in item table
    firstInvoiceItem(formValues.invoiceItems, setAllAmounts, setFormValues);
    //get Current date
    getCurrentDate(setDate);
  }, []);

  useEffect(() => {
    Promise.allSettled([fetchBusinessUserNames(), fetchBusinessClientNames()]);
  }, [toggleStates.isChange, toggleStates.isSelectedBusiness]);

  useEffect(() => {
    //hide terms and condition if there's no item in the array on delete
    if (formValues.TAndC.length === 0 && toggleStates.isTermsAndConditionsVisible) {
      updateToggleState("isTermsAndConditionsVisible", false);
    }

    //show terms and condition if there's item in the array on delete
    if (formValues.TAndC.length > 0 && isLoadingFirstTC) dispatch(setLoadingFirstTC(false));
  }, [formValues.TAndC.length]);

  //update state instead of calling setToggleStates
  const updateToggleState = (key, value) => {
    setToggleStates(prevS => ({ ...prevS, [key]: value }));
  };

  //fetch business
  const fetchBusinessUserNames = () => {
    getBusinesses(DEFAULT_PAGE_NO, 100, isClient).then(res => {
      //selecting the particular business by businessID
      const businessById = res.data.find(ele => ele.id === +billed.billedById);

      setFormValues(prevFV => ({
        ...prevFV,
        businesses: res.data,
        selectedBusiness: businessById,
      }));
    });
  };

  //Fetching clients
  const fetchBusinessClientNames = () => {
    getBusinesses(DEFAULT_PAGE_NO, 100, !isClient).then(res => {
      const clientById = res.data.find(ele => ele.id === +billed.billedToId);
      setFormValues(prevFV => ({
        ...prevFV,
        clients: res.data,
        selectedClientBusiness: clientById,
      }));
    });
  };

  //Getting Invoice Number
  const getInvoiceNum = () => {
    getInvoiceNumber().then(res => {
      setFormValues(prevFV => ({ ...prevFV, invoiceNumberDetails: res.data }));
    });
  };

  const toggleShippingDetails = () => {
    if (!toggleStates.showShippingDetails) {
      setFormValues(prevFV => ({
        ...prevFV,
        shippingDetails: {
          ...prevFV.shippingDetails,
          shippedFrom: 0,
          shippedTo: 0,
        },
      }));
    }
    setToggleStates(prevTS => ({
      ...prevTS,
      showShippingDetails: !prevTS.showShippingDetails,
    }));
  };

  return (
    <>
      <div className="invoice-container w-4/5 shadow-xl px-8 mx-auto mt-6 mb-4">
        <InvoiceHeader
          setFormValues={setFormValues}
          updateToggleState={updateToggleState}
          formValues={formValues}
          toggleStates={toggleStates}
        />

        {/* invoice-date-div */}
        <InvoiceDate
          setFormValues={setFormValues}
          formValues={formValues}
          toggleStates={toggleStates}
          setDate={setDate}
          date={date}
          updateToggleState={updateToggleState}
        />

        {/* billed_by and billed_to section */}
        <Billing
          formValues={formValues}
          setFormValues={setFormValues}
          toggleStates={toggleStates}
          setToggleStates={setToggleStates}
          updateToggleState={updateToggleState}
          setBilled={setBilled}
          billedById={billed.billedById}
        />

        {/* shipping-details-ui */}

        <InputFields
          parentClassName="flex items-center gap-3 mt-5 flex-row-reverse w-fit items-end"
          InputField={addShippingAddress}
          value={toggleStates.showShippingDetails}
          inputClassName={`${radioPrimaryClass()} flex !rounded`}
          labelClassName="!text-base !mb-0"
          onChange={toggleShippingDetails}
        />

        {toggleStates.showShippingDetails && (
          <ShippingDetails
            formValues={formValues}
            toggleStates={toggleStates}
            setFormValues={setFormValues}
            setToggleStates={setToggleStates}
          />
        )}

        {/* tax-details-ui */}
        <div className="border border-[red] border-opacity-0 mt-2">
          <TaxButtons formValues={formValues} setFormValues={setFormValues} />

          <ItemTable
            invoiceItems={formValues.invoiceItems}
            setAllAmounts={setAllAmounts}
            setFormValues={setFormValues}
            allAmounts={allAmounts}
            radioButtonValue={formValues.radioButtonValue}
          />

          {/* Add new line button */}
          <AddNewItem
            invoiceItems={formValues.invoiceItems}
            setAllAmounts={setAllAmounts}
            setFormValues={setFormValues}
          />

          {/* showing final data */}
          <Total allAmounts={allAmounts} radioButtonValue={formValues.radioButtonValue} />

          {/* Terms And Condition and Notes */}
          <Legal
            setFormValues={setFormValues}
            toggleStates={toggleStates}
            formValues={formValues}
            updateToggleState={updateToggleState}
          />

          {/* save & continue button */}
          <SubmitButton
            setFormValues={setFormValues}
            setToggleState={setToggleStates}
            setAllAmounts={setAllAmounts}
            date={date}
            billed={billed}
            allAmounts={allAmounts}
            formValues={formValues}
            toggleStates={toggleStates}
          />
        </div>
      </div>
    </>
  );
};

export default CreateInvoice;
