import { memo } from "react";
import { NavLink } from "react-router-dom";

/**
 * @namespace SidebarItems create sidebar links with react router dom Link component
 * @param {Object} props
 * @param {[{name: string, link: string, icon: string}]} props.items array of objects which contains links name and path
 * @param {string} props.className list tag class
 * @returns list items array
 */
const SidebarItems = memo(function SidebarItems({ items, className, isActiveClass }) {
  const isActive = ({ isActive }) => (isActive ? isActiveClass : className);

  return (
    <>
      {items.map(item => (
        <NavLink key={item.link} data-sidebar="sidebar-item" className={isActive} to={item.link}>
          <div>{item.icon}</div>
          <span className="text-[0.9rem]">{item.name}</span>
        </NavLink>
      ))}
    </>
  );
});

export default SidebarItems;
