import React, { memo } from "react";

const SelectRejectAll = ({ textClass = "", text = "", _class = "", icon = null, onClick }) => {
  return (
    <div className={_class}>
      <a
        onClick={onClick}
        className={textClass}>
        {text}
      </a>
      {icon && icon}
    </div>
  );
};

export default memo(SelectRejectAll);
