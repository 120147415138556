import { useEffect, useState } from "react";
import { RxCross2 } from "react-icons/rx";
import { btnPrimary } from "../../utils/helper";
import { DEFAULT_PAGE_NO, DEFAULT_PAGE_SIZE } from "../../constants/Constant";
import { GetUsers } from "../../services/UserService";
import AsyncSelect from "react-select/async";
import { debouncedPromiseOptions } from "../../utils/common";

const CustomizeBalanceFilter = ({
  isOpen,
  closeDropdown,
  clearFilter,
  setEmployee,
  employeeId,
  onSubmitFilterHandler,
}) => {
  const [pageNo, setPageNo] = useState(DEFAULT_PAGE_NO);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [employees, setEmployees] = useState([]);

  const options = debouncedPromiseOptions((inputValue, callback) => {
    GetUsers(DEFAULT_PAGE_SIZE, DEFAULT_PAGE_NO, 1, 1, "", "", "", 1, "", "", inputValue)
      .then(response => {
        const users = response.data;
        let structuredData = users.map(user => {
          return {
            label: `${user.firstName} ${user.lastName}`,
            value: user.id,
          };
        });
        callback(structuredData);
      })
      .catch(e => {
        callback([]);
        console.error(e);
      });
  });

  useEffect(() => {
    const closeCustomizeBalanceFilter = function (event) {
      const selectOption = event.target.closest(".css-d7l1ni-option") ? true : false;

      if (selectOption) return;
      const element = event.target.closest("[data-id]");

      if (!element) return closeDropdown();

      const dataSet = element.dataset.id;
      if (dataSet.toLowerCase() === "openCustomizeBalanceFilter") return;
    };
    document.addEventListener("click", closeCustomizeBalanceFilter);
    return () => {
      document.removeEventListener("click", closeCustomizeBalanceFilter);
    };
  }, []);

  // Fetching all the employee
  const fetchUsers = (pageSize, pageNo) => {
    GetUsers(pageSize, pageNo).then(response => {
      setEmployees(response.data);
    });
  };

  useEffect(() => {
    fetchUsers(pageSize, pageNo);
  }, [pageSize, pageNo]);

  const onChangeDropdownHandler = function (selectedOption) {
    setEmployee(selectedOption);
  };

  const setSelectedValue = function () {
    const selectedEmp = employees.find(employee => +employee.id === +employeeId);
    if (selectedEmp?.firstName) return selectedEmp?.firstName;
    else {
      setEmployee("");
      return "All Employees";
    }
  };

  return (
    <div
      className="overflow-hidden top-24 right-6 absolute bg-white px-4 py-2 rounded-md shadow-md z-10 w-max"
      data-id="customizeBalanceFilter">
      <form
        onSubmit={onSubmitFilterHandler}
        method="get"
        id="customizeBalanceFilter"
        aria-labelledby="dropdownDefault"
        className={`customizeBalanceFilter ${isOpen ? "show" : ""}`}>
        <div className="flex justify-between">
          <div>
            <h1 className="text-lg font-bold">Filter</h1>
          </div>
          <button id="closeBtn" type="button" onClick={closeDropdown}>
            <RxCross2 />
          </button>
        </div>

        <div className="py-2 font-light border-t border-gray-200 dark:border-gray-600"></div>
        <ul className="h-auto overflow-x-auto space-y-2 text-sm">
          <div className="flex gap-2">
            <div className="h-40">
              <h6 className="block mt-2 mb-2 text-sm font-medium text-gray-900 dark:text-white">
                Employee
              </h6>
              <div className="h-3 [&_ul]:!h-[10rem] [&_ul]:!overflow-auto [&_.css-b62m3t-container]:!w-[10rem]">
                <AsyncSelect
                  cacheOptions
                  defaultOptions
                  isMulti={false}
                  id="customize-balance"
                  placeholder="Employees"
                  loadOptions={options}
                  onChange={onChangeDropdownHandler}
                />
              </div>
            </div>
          </div>
        </ul>

        <div className="flex justify-end items-center">
          <div className="m-2">
            <button
              type="button"
              onClick={clearFilter}
              className=" px-3 py-2.5 text-sm font-medium text-center bg-gray-200 text-black rounded-lg bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none hover:bg-gray-400">
              Clear
            </button>
          </div>
          <div className="m-2">
            <button
              type="submit"
              className={`${btnPrimary()} !px-4 !py-2 rounded-md text-white tracking-wide font-semibold flex items-center gap-2`}>
              Apply
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default CustomizeBalanceFilter;
