import { v4 as uuidv4 } from "uuid";
import { Checkbox } from "../../hooks/useCheckbox";
import {
  add,
  eachDayOfInterval,
  endOfMonth,
  format,
  isSameDay,
  parse,
  parseISO,
  startOfToday,
} from "date-fns";
import { useState } from "react";
import HolidayListItem from "./HolidayListItem";
import Calendar from "../common/Calendar/Calendar";
import { holidayIndicatorConditions } from "../../utils/indicatorConditions";

/**
 * @namespace HolidayList creates table row with checkboxes
 * @param {{holidaysList: [{id: number, eventTitle: string, holidayDate: Date, status: number}], user: {roleId:  number}, editHoliday: function, deleteHoliday: function, isSelectedHandler: function, checkedHolidays: string[]}} props - An object containing the function's props.
 * @param {[{id: number, eventTitle: string, holidayDate: Date, status: number}]} holidaysList Array of Objects
 * @param {{roleId:  number}} user - Logged In user
 * @param {function} editHoliday - Takes id, id will be taken from holidaysList if passed
 * @param {function} deleteHoliday - Takes id, id will be taken from holidaysList if passed
 * @param {function} isSelectedHandler - onChange event handler for checkbox
 * @param {string[]} checkedHolidays - check holiday ids
 * @returns table row with checkboxes
 */

export default function HolidayList({ holidaysList, user, editHoliday, deleteHoliday }) {
  const [showAllData, setShowAllData] = useState(true);

  let today = startOfToday();
  let [selectedDay, setSelectedDay] = useState(today);
  let [currentMonth, setCurrentMonth] = useState(format(today, "MMM-yyyy"));
  let firstDayCurrentMonth = parse(currentMonth, "MMM-yyyy", new Date());

  let days = eachDayOfInterval({
    start: firstDayCurrentMonth,
    end: endOfMonth(firstDayCurrentMonth),
  });

  function previousMonth() {
    let firstDayNextMonth = add(firstDayCurrentMonth, { months: -1 });
    setCurrentMonth(format(firstDayNextMonth, "MMM-yyyy"));
  }

  function nextMonth() {
    let firstDayNextMonth = add(firstDayCurrentMonth, { months: 1 });
    setCurrentMonth(format(firstDayNextMonth, "MMM-yyyy"));
  }

  let selectedDayHolidays = holidaysList.filter(holidays =>
    isSameDay(parseISO(holidays.holidayDate), selectedDay)
  );

  const CalendarClearButton = () => {
    setShowAllData(true);
    setSelectedDay(today); // Set selected day to today
  };

  return (
    <>
      <div className="">
        <div className="">
          <div className="pt-5">
            <div className="max-w-xl px-4 mx-auto sm:px-7 md:max-w-4xl md:px-6">
              <div className="md:grid md:grid-cols-2 md:divide-x md:divide-gray-200">
                <section className="mt-0 md:mt-0 md:pl-14">
                  <ol className="mt-4 space-y-1 text-sm leading-6 text-gray-500">
                    {showAllData ? (
                      <div>
                        <h2 className="font-semibold text-gray-900 mb-4">All Holidays Lists</h2>
                        {holidaysList?.map(holiday => (
                          <HolidayListItem
                            key={holiday.id}
                            holiday={holiday}
                            user={user}
                            editHoliday={editHoliday}
                            deleteHoliday={deleteHoliday}
                          />
                        ))}
                      </div>
                    ) : (
                      <div>
                        <h2 className="font-semibold text-gray-900 mb-4">Holidays</h2>
                        {selectedDayHolidays.length > 0 ? (
                          selectedDayHolidays.map(holiday => (
                            <SelectedDayHolidays DaysHolidays={holiday} key={HolidayList.id} />
                          ))
                        ) : (
                          <p>No holiday found on the selected date</p>
                        )}
                      </div>
                    )}
                  </ol>
                </section>

                <div className="md:pr-14 pl-10 max-h-full">
                  <Calendar
                    currentMonth={firstDayCurrentMonth}
                    previousMonth={previousMonth}
                    nextMonth={nextMonth}
                    days={days}
                    colStartClasses={colStartClasses}
                    selectedDay={selectedDay}
                    setSelectedDay={setSelectedDay}
                    showAllData={showAllData}
                    setShowAllData={setShowAllData}
                    events={holidaysList.map((item)=>{
                      return {
                        date: item.holidayDate
                      }
                    })}
                    indicatorConditions={holidayIndicatorConditions}
                  />

                  {!showAllData && (
                    <button
                      type="button"
                      onClick={CalendarClearButton}
                      disabled={showAllData}
                      className={`text-gray-800 mb-3 mt-2 ${
                        showAllData
                          ? "bg-gray-300"
                          : "bg-gray-700 text-white border-gray-600 hover:bg-gray-700 hover:border-gray-600 focus:ring-gray-700"
                      } focus:outline-none focus:ring-gray-300 font-medium rounded-full text-sm px-5 py-2.5 me-2 mb-2 dark:bg-gray-500 dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700`}>
                      Clear
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function SelectedDayHolidays({ DaysHolidays }) {
  let holidayDate = parseISO(DaysHolidays.holidayDate);

  return (
    <li className="flex items-center px-0 py-2 space-x-4 group rounded-xl focus-within:bg-gray-100 hover:bg-gray-100">
      <time dateTime={DaysHolidays.holidayDate}>{format(holidayDate, "dd MMM YYY")}</time>{" "}
      <div className="w-5 h-5 bg-green-400 rounded-full"></div>
      <div className="flex-auto">
        <p className="text-gray-900">{DaysHolidays.eventTitle}</p>
        <p className="mt-0.5"></p>
      </div>
    </li>
  );
}

let colStartClasses = [
  "",
  "col-start-2",
  "col-start-3",
  "col-start-4",
  "col-start-5",
  "col-start-6",
  "col-start-7",
];
