import { Fragment } from "react";
import moment from "moment";

import { PAYROLL_HISTORY_TABLE_HEADER } from "../../constants/Constant";
import TableData from "./../common/Table/TableData";
import { PayrollStatus } from "../../enum/PayrollStatus";

/**
 * Renders a list of payroll history.
 *
 * @param {{payrollHistory: object}} props - The component props.
 * @param {object} payrollHistory - The payroll history data.
 * @return {ReactNode} The rendered payroll history list.
 */
const PayrollHistoryList = ({ payrollHistory }) => {
  return (
    <>
      {(!payrollHistory || payrollHistory?.length === 0) && (
        <tr className="border-0 border-opacity-0">
          <td
            colSpan={PAYROLL_HISTORY_TABLE_HEADER.length}
            className="tracking-wide pt-8 text-center border-0 text-[#6b7280]">
            No Payroll ran yet!
          </td>
        </tr>
      )}
      {payrollHistory?.length > 0 &&
        payrollHistory?.map(payroll => {
          const startDate = moment(payroll.payStartDate).format("DD/MM/YYYY");
          const endDate = moment(payroll.payEndDate).format("DD/MM/YYYY");
          const payDate = moment(payroll.payDate).format("DD/MM/YYYY");
          
          return (
            <Fragment key={payroll.id}>
              <tr
                data-id={payroll.id}
                className="hover:bg-gray-100 focus:bg-gray-100 transition-colors duration-300 !cursor-pointer">
                <TableData data={`${payDate}`} />
                <TableData data="Regular" />
                <TableData data={`${startDate} - ${endDate}`} />
                <TableData
                  className={`${payroll.status === PayrollStatus.PaymentCompleted ? "text-green-500" : "text-red-500"}`}
                  data={payroll.status === PayrollStatus.PaymentCompleted ? "Paid" : "Unpaid"}
                />
              </tr>
            </Fragment>
          );
        })}
    </>
  );
};

export default PayrollHistoryList;
