import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Pagination } from "flowbite-react";
import { Select } from "flowbite-react";

import { setPageNo, setPageSize, setTotalPages } from "../store/slices/pagination";

import { DEFAULT_PAGE_SIZE } from "../constants/Constant";

/**
 * Generates a custom hook for pagination.
 *
 * @param {boolean} isCustomOnChange - A flag indicating whether the custom onChange event handler is used.
 * @param {boolean} totalRecords - total no records to calculate total no. of pages
 * @returns {{setPageSizeHandler: Function, onPageChange: Function}} - An object containing the functions for pagination.
 *                    If `isCustomOnChange` is true, it returns:
 *                    - `onPageChange` (function): A function to handle page change.
 *                    Otherwise, it returns:
 *                    - `setPageSizeHandler` (function): A function to handle setting page size.
 *                    - `onPageChange` (function): A function to handle page change.
 */
const usePagination = (isCustomOnChange, totalRecords) => {
  const dispatch = useDispatch();

  const { pageSize, pageNo } = useSelector(state => state.pagination);

  useEffect(() => {
    calculateTotalPages(totalRecords, pageSize);
  }, [totalRecords, pageSize]);

  /**
   * Set the page size/No of rows to show in a table.
   *
   * @param {Event} e - The event object.
   */
  const setPageSizeHandler = e => {
    const { value } = e.target;
    const totalPages = Math.ceil(totalRecords / value);
    dispatch(setPageSize(+value));
    const newPageNo = Math.min(pageNo, totalPages); // Determine the new page number
    dispatch(setPageNo(newPageNo));
    dispatch(setTotalPages(totalPages));
  };

  /**
   * A function to handle page change.
   * It highlight the active page
   *
   * @param {number} pageNo - The page number to set.
   */
  const onPageChange = page => {
    dispatch(setPageNo(page));
  };

  /**
   * A function to calculate the total number of pages.
   *
   * @param {number} totalNoOfRecords - The total number of records.
   */
  const calculateTotalPages = totalNoOfRecords => {
    dispatch(setTotalPages(totalNoOfRecords));
  };

  if (isCustomOnChange) {
    return {
      onPageChange,
    };
  }

  return {
    setPageSizeHandler,
    onPageChange,
  };
};

export default usePagination;

/**
 * Renders a pagination component with options to change the number of rows per page and navigate through different pages.
 *
 * @param {{totalRecords: number, onChange: function, showIcons?: boolean, onPageChange: function, layout?: string}} props - The properties for the pagination component.
 * @param {function} onChange - Event handler(`setPageSizeHandler`) for when the number of rows per page is changed.
 * @param {number} totalRecords - The total number of records in the database.
 * @param {function} onPageChange - Event handler(`onPageChange`) for when a new page is selected, it gets a `page` argument from flowbite
 * @param {string?} layout - The layout configuration for the pagination component.
 * @param {boolean?} showIcons - Whether to show icon or not
 * @return {JSX.Element} The rendered pagination component.
 */
export const PaginationComponent = ({
  onChange,
  totalRecords,
  onPageChange,
  layout,
  showIcons,
}) => {
  //pageNo is currentPage page
  const { pageSize, pageNo, totalPages } = useSelector(state => state.pagination);

  useEffect(() => {
    // Checking if there are no items left on the current page then moving to the previous page
    if ((pageNo - 1) * pageSize >= totalRecords && pageNo > 1) {
      onPageChange(pageNo - 1);
    }
  }, [totalRecords]);

  return (
    <>
      {(totalPages > 1 || totalRecords > 12) && (
        <nav
          className="flex flex-col items-start justify-between p-4 space-y-3 md:flex-row md:items-center md:space-y-0"
          aria-label="Table navigation">
          <div className="flex items-center space-x-3">
            <label htmlFor="rows" className="text-xs font-normal text-gray-500 dark:text-gray-400">
              Rows per page
            </label>
            <Select
              id="rows"
              onChange={onChange}
              value={pageSize}
              className="text-gray-900 text-sm rounded-lg focus:ring-thynkwebPrimary-500 focus:border-thynkwebPrimary-500 block py-1.5 pl-3.5 pr-6 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-thynkwebPrimary-500 dark:focus:border-thynkwebPrimary-500">
              <option value={12}>12</option>
              <option value={25}>25</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
              <option value={200}>200</option>
            </Select>
          </div>

          <Pagination
            currentPage={pageNo}
            onPageChange={page => {
              onPageChange(page);
            }}
            showIcons={showIcons}
            totalPages={totalPages}
            layout={layout}
          />
        </nav>
      )}
    </>
  );
};
