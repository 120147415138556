import { ResignationStatus } from "../../../../enum/ResignationStatus";
import InputFields from "../../../common/Input/InputFields";
import MandatorySign from "../../../common/Input/MandatorySign";
import ModalButtons from "../../../common/Modal/ModalButtons";
import SelectWithDropdown from "../../../common/SelectWithDropdown/SelectWithDropdown";

import { addResignationInputFields as InputFieldOptions } from "../../helper";
import { useState } from "react";

/**
 * Add or update the resignation for a employee
 *
 * @param {Object} props - The props object containing the following properties:
 *   - onSubmit: A function to handle the submit action.
 *   - onCancel: A function to handle the cancel action.
 *   - resignationFields: Object Containg the Resignation Fiels data 
 *   - updateResignationInputs : function to Updates the states of resignationFields
 * @return {JSX.Element} The rendered Resignations component.
 */
const ManageResignations = ({ onSubmit, onCancel, resignationFields, updateResignationInputs }) => {
  const [approvelStatus, setApprovelStatus] = useState(resignationFields.approvelStatus);

  // clear input values
  const updateInputValues = e => {
    updateResignationInputs(e.target.name, e.target.value)
    setApprovelStatus(3)
  };

  const onChangeHandler = function (e) {
    updateInputValues(e);
  };

  const onSubmitHandler = e => {
    e.preventDefault();
    updateInputValues(e);
    onSubmit();
  };

  const onCancelHandler = e => {
    updateInputValues(e);
    onCancel();
  };

  const onChangeDropdownHandler = function (_, selectedOption) {
    setApprovelStatus(selectedOption.value);
  };

  return (
    <div className="sm:grid sm:grid-cols-2 sm:gap-6 flex flex-col mt-4 gap-4">
      {InputFieldOptions.map(field => {
        return (
          <InputFields
            key={field?.id}
            onChange={onChangeHandler}
            inputClassName="w-full"
            InputField={field}
            value={resignationFields[field.name]}
          />
        );
      })}

      {/* Approval Status */}
      <div className="flex">
        <div className="flex flex-col">
          <label
            htmlFor="approvedStatus"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white cursor-pointer">
            Approval Status <MandatorySign />
          </label>
          <SelectWithDropdown
            options={Object.keys(ResignationStatus).map(status => ({
              value: status,
              label: ResignationStatus[status],
              itemClassName: "w-[13.5rem]",
            }))}
            defaultValue={ResignationStatus[approvelStatus]}
            isDefaultValue={true}
            size="sm"
            id="approvedStatus"
            handleChange={onChangeDropdownHandler}
            backgroundColor="bg-gray-50 border border-gray-300 rounded-lg h-[2.7rem]"
            className="w-[10rem] -pl-[1rem]"
          />
        </div>
      </div>

      <ModalButtons
        btnCont="row-start-3 row-end-4 col-start-1 col-end-3"
        cancelBtnText="Cancel"
        actionBtnText={"Apply"}
        onClickAction={onSubmitHandler}
        onClickCancel={onCancelHandler}
      />
    </div>
  );
};

export default ManageResignations;
