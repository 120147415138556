import AddBusiness from "./businesses/AddBusiness";
import BusinessDetails from "../BusinessDetails";
import { BsPlusSquareFill } from "react-icons/bs";

const Billing = ({
  toggleStates,
  setToggleStates,
  setFormValues,
  formValues,
  updateToggleState,
  setBilled,
  billedById,
}) => {
  const openBilledByModal = () => {
    setToggleStates(prevTS => ({
      ...prevTS,
      billedByModal: true,
      isClient: false,
    }));
  };

  const openBilledToModal = () => {
    setToggleStates(prevTS => ({
      ...prevTS,
      billedToModal: true,
      isClient: true,
    }));
  };

  //handling businesses
  const onHandleBusinesses = id => {
    const businessById = formValues.businesses.find(ele => {
      return ele.id === +id;
    });

    updateToggleState("isSelectedBusiness", true);
    setBilled(prevB => ({ ...prevB, billedById: id }));
    setFormValues(prevFV => ({ ...prevFV, selectedBusiness: businessById }));
    setToggleStates(prevTS => ({ ...prevTS, isSelectedBusiness: true }));
  };

  //Handling Clients
  const onHandleClients = e => {
    const clientById = formValues.clients.find(ele => {
      return ele.id === +e.target.value;
    });
    updateToggleState("isSelectedClient", true);
    setBilled(prevB => ({ ...prevB, billedToId: e.target.value }));
    setFormValues(prevFV => ({ ...prevFV, selectedClientBusiness: clientById }));
    setToggleStates(prevTS => ({ ...prevTS, isSelectedClient: true }));
  };

  return (
    <section className="md:flex gap-2 md:items-center md:justify-between mt-2">
      <div className="w-full md:w-[50%] px-3 py-3 pb-5 bg-[#f7faff]">
        <div>
          <div className="flex justify-between border border-[#b4c2d5] border-opacity-0">
            <div className="flex item-center mb-2">
              <p className="text-[1rem] lg:text-2xl underline decoration-dotted decoration-3 underline-offset-8">
                Billed By
              </p>
              <span className="pt-1 pl-3 text-[.9rem] lg:text-lg">(Your Details)</span>
            </div>
            {/* button for modal */}
            <AddBusiness
              show={toggleStates.billedByModal}
              modal="billedByModal"
              setToggleStates={setToggleStates}
              isClient={toggleStates.isClient}
            />
            <div
              onClick={openBilledByModal}
              className="cursor-pointer">
              <BsPlusSquareFill
                size="30px"
                fill="#cdc733"
              />
            </div>
          </div>

          {/*business content*/}

          <BusinessDetails
            businessesFor="business"
            toggleStates={toggleStates}
            updateToggleState={updateToggleState}
            onHandleBusinesses={onHandleBusinesses}
            businesses={formValues.businesses}
            setFormValues={setFormValues}
            selectedBusiness={formValues.selectedBusiness}
            billedById={billedById}
          />
        </div>
      </div>
      <div className="w-full sm:basis-[50%] px-3 py-3 pb-5 bg-[#f7faff]">
        <div>
          <div className="flex justify-between">
            <div className="flex item-center mb-2">
              <p className="text-[1rem] lg:text-2xl underline decoration-dotted decoration-3 underline-offset-8">
                Billed To
              </p>
              <span className="pt-1 pl-3 text-[.9rem] lg:text-lg">(Client Details)</span>
            </div>
            {/* button for modal */}
            <AddBusiness
              show={toggleStates.billedToModal}
              modal="billedToModal"
              setToggleStates={setToggleStates}
              isClient={toggleStates.isClient}
            />
            <div
              onClick={openBilledToModal}
              className="cursor-pointer">
              <BsPlusSquareFill
                size="30px"
                fill="#cdc733 "
              />
            </div>
          </div>
          {/* client content*/}
          <BusinessDetails
            businessesFor="clients"
            toggleStates={toggleStates}
            updateToggleState={updateToggleState}
            onHandleClients={onHandleClients}
            clients={formValues.clients}
            selectedClientBusiness={formValues.selectedClientBusiness}
            isClient={toggleStates.isClient}
          />
        </div>
      </div>
    </section>
  );
};

export default Billing;
