import { forwardRef, memo } from "react";
import { TimeLogApprovalStatus } from "../../enum/AttendanceStatus";
import { attendanceStatusUpdate } from "../../services/AttendanceService";
import { ATTENDANCE_VALIDATE_FIELDS } from "../../constants/Messages";
import { isValue } from "../../utils/common";
import { showToast } from "../common/Toast";

export const JobsHeading = [
  "jobs Name",
  "project Name",
  "client Name",
  "start Date",
  "end Date",
  "logged hours",
  "user(s)",
  "action",
];

export const ProjectsHeading = [
  "Project Name",
  "Client Name",
  "Project Head",
  "Status",
  "Jobs",
  "Action",
];

export const TimeLogHeading = [
  "Job Name",
  "Check In",
  "Check Out",
  "Location",
  "Total Hours",
  "Approval Status",
  "Actions",
];

export const options = [
  {
    id: 1,
    name: "Working Hours",
    activeItem: 1,
  },
  // {
  //   id: 2,
  //   name: "Regularization",
  //   activeItem: 2,
  // },
  {
    id: 3,
    name: "Time Logs",
    activeItem: 3,
  },
];

// isBillable condition for creating and updating jobs
export const getIsBillableValue = isBillableValue => {
  if (isBillableValue === "true" || isBillableValue === true) {
    return true;
  } else if (isBillableValue === "false" || isBillableValue === false) {
    return false;
  }
};

// Attendance settings strict mode
export const strictMode = [
  {
    id: 1,
    label: "Full Day",
    type: "time",
    name: "fullDay",
    placeholder: "Select time",
    required: true,
  },
  {
    id: 2,
    label: "Half Day",
    type: "time",
    name: "halfDay",
    placeholder: "Select time",
    required: true,
  },
];

// Attendance settings shift start and shift end
export const shiftStartEnd = [
  {
    id: 1,
    label: "Shift Start",
    type: "time",
    name: "shiftStart",
    placeholder: "Select time",
    required: true,
  },
  {
    id: 2,
    label: "Shift End",
    type: "time",
    name: "shiftEnd",
    placeholder: "Select time",
    required: true,
  },
];

// Attendance settings lenient Mode
export const lenientMode = [
  {
    id: 1,
    label: "Full Day",
    type: "time",
    name: "fullDay",
    placeholder: "Select time",
    required: true,
  },
];

//Attendance settings checkbox input fields.
export const timeLogSettingsObject = [
  {
    id: "0",
    label: "Attendance Update Cut-Off Days",
    type: "number",
    name: "attendanceUpdateCutOffDays",
  },
  // {
  //   id: "1",
  //   label: "Future Date(s)",
  //   type: "checkbox",
  //   name: "restrictLogsForFutureDates",
  // },
  // {
  //   id: "2",
  //   label: "Past Date(s)",
  //   type: "checkbox",
  //   name: "restrictLogsForPastDates",
  // },
  {
    id: "3",
    label: "Weekends",
    type: "checkbox",
    name: "restrictLogsForWeekend",
  },
  {
    id: "4",
    label: "Holidays",
    type: "checkbox",
    name: "restrictLogsForHolidays",
  },
  // {
  //   id: "5",
  //   label: "Estimated job hours ",
  //   type: "checkbox",
  //   name: "restrictLogsToEstimatedJobHours",
  // },
];

export const AttendanceHeading = [
  "Day",
  "Date",
  "Status",
  "Check IN",
  "Check Out",
  "Over Time",
  "Total hours",
  "Approval Status",
  "Actions",
];

export const attendancesStatus = status => {
  if (status === TimeLogApprovalStatus.Approved) {
    return "Approved";
  }
  if (status === TimeLogApprovalStatus.Rejected) {
    return "Rejected";
  }
  if (status === TimeLogApprovalStatus.Pending) {
    return "Pending";
  }
};

export const selectOptionsAttendances = (userContext, user) => {
  let option = [];
  if (user?.overallApprovalStatus === TimeLogApprovalStatus.Pending) {
    option = [
      {
        value: TimeLogApprovalStatus.Approved,
        label: "Approved",
        className: "text-green-800 rounded-lg -ml-5 mr-2",
        dotClassName: "bg-green-500 border rounded-full",
        backgroundColor: "bg-green-100 w-[150px]",
        itemClassName: "w-[150px]",
        attendanceId: user.id,
      },
      {
        value: TimeLogApprovalStatus.Rejected,
        label: "Rejected",
        className: "text-red-800 rounded-lg -ml-5 mr-2",
        dotClassName: "bg-red-500 border rounded-full",
        backgroundColor: "bg-red-100 w-[150px]",
        itemClassName: "w-[150px]",
        attendanceId: user.id,
      },
    ];

    return option;
  }
  let status =
    user?.overallApprovalStatus === TimeLogApprovalStatus.Approved ? "Rejected" : "Approved";

  let _className =
    user?.overallApprovalStatus === TimeLogApprovalStatus.Approved
      ? "text-red-800"
      : "text-green-800"; // if approved then show disapprove color else show approve color
  let _dotClassName =
    user?.overallApprovalStatus === TimeLogApprovalStatus.Approved
      ? "bg-red-500 border rounded-full"
      : "bg-green-500 border rounded-full";
  let _backgroundColor =
    user?.overallApprovalStatus === TimeLogApprovalStatus.Approved
      ? "bg-red-100 w-[150px]"
      : "bg-green-100 w-[150px]";

  option = [
    {
      value:
        user?.overallApprovalStatus === TimeLogApprovalStatus.Approved
          ? TimeLogApprovalStatus.Rejected
          : TimeLogApprovalStatus.Approved,
      label: status,
      className: _className,
      dotClassName: _dotClassName,
      backgroundColor: _backgroundColor,
      itemClassName: "w-[150px]",
      attendanceId: user.id,
    },
  ];
  return option;
};

export const updateSingleAttendanceStatus = (item, singleAttendanceUpdated, setReRenderSelect) => {
  if (
    item &&
    (item.value === TimeLogApprovalStatus.Approved || item.value === TimeLogApprovalStatus.Rejected)
  ) {
    let status = item.value;
    attendanceStatusUpdate(item.attendanceId, status).then(res => {
      if (res.data.statusCode === 200) {
        if (singleAttendanceUpdated.current === null) {
          return (singleAttendanceUpdated.current = true);
        }
        setReRenderSelect(s => !s);
        singleAttendanceUpdated.current = !singleAttendanceUpdated.current;
      }
    });
  }
};

export const validateFields = (selectedUserId, startDate, endDate) => {
  if (!isValue(selectedUserId)) {
    showToast("error", ATTENDANCE_VALIDATE_FIELDS.SELECT_USER);
    return true;
  }
  if (!isValue(startDate)) {
    showToast("error", ATTENDANCE_VALIDATE_FIELDS.START_DATE);
    return true;
  }
  if (!isValue(endDate)) {
    showToast("error", ATTENDANCE_VALIDATE_FIELDS.END_DATE);
    return true;
  }
  return false;
};

export const maintainDate = originalDate => {
  // Convert the date to ISO string and adjust to local time zone offset
  const localTimeOffset = originalDate.getTimezoneOffset();
  const localISOString = new Date(originalDate.getTime() - localTimeOffset * 60000).toISOString();
  return localISOString;
};

export const getTimeOnlyFromLoggedHours = loggedHours => {
  let timeSpanParts = loggedHours.split('.');
  if (timeSpanParts.length === 3) {
  
    let timePart = timeSpanParts[1]; // Extract the time part

    // Split the time part into hours, minutes, seconds, and milliseconds
    let timeParts = timePart.split(':');
    if (timeParts.length >= 3) {
      let days = timeSpanParts[0];
      let hours = parseInt(timeParts[0], 10);

      // Format the time as Days Hours Minutes
      return `${days.toString()} Day(s) ${hours.toString().padStart(2, '0')} Hours`;
    }
  }
  else if (timeSpanParts.length === 2) {
    let timeParts = timeSpanParts[0].split(':');

    let hours = parseInt(timeParts[0], 10);
    let minutes = parseInt(timeParts[1], 10);

    // Format the time as Days Hours Minutes
    return `${hours.toString().padStart(2, '0')} Hours`;
  }
}

export const Checkbox = memo(
  forwardRef(({ id, handleCheck, data, checked, indeterminate, disabled, classes }, ref) => {
    let allowRef = indeterminate === true ? ref : null;
    let _class =
      "ml-4 mr-4 w-3.5 h-3.5 text-blue-600 bg-white-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600";
    if (classes) {
      _class = _class.concat(" ", classes);
    }

    if (disabled) {
      _class = _class.replace("bg-white-100", "bg-gray-100");
    }

    return (
      <>
        <input
          ref={allowRef}
          id={id}
          type="checkbox"
          name="checkbox"
          data-set={data}
          checked={checked}
          onChange={handleCheck}
          className={`${_class}`}
          disabled={disabled}
        />
      </>
    );
  })
);
