import React, { useEffect, useRef, useState } from "react";
import PageSection from "../../components/Layout/PageSection/PageSection";
import { Button } from "flowbite-react";
import { btnPrimary } from "../../utils/helper";
import JobsList from "../../components/Attendance/Jobs/JobsList";
import { addJob, getJob, getJobById, updateJob } from "../../services/JobService";
import DeleteJob from "../../components/Attendance/Jobs/DeleteJob";
import AddJob from "../../components/Attendance/Jobs/AddJob";
import { DEFAULT_PAGE_NO, DEFAULT_PAGE_SIZE, STATUS_CODES } from "../../constants/Constant";
import { JOB_MESSAGES } from "../../constants/Messages";
import { showToast } from "../../components/common/Toast";
import { GetUserByParam } from "../../services/UserService";
import { debouncedPromiseOptions } from "../../utils/common";
import { format } from "date-fns";
import { GoPlus } from "react-icons/go";
import { MdOutlineRocketLaunch } from "react-icons/md";
import { getAssignedUsers, getProjects } from "../../services/ProjectService";
import { getIsBillableValue } from "../../components/Attendance/helper";
import usePagination, { PaginationComponent } from "../../hooks/usePagination";
import { useSelector } from "react-redux";

//initial object for Job detail
const jobInitialDetails = {
  name: "",
  projectName: {},
  startDate: null,
  endDate: null,
  isBillable: true,
  rph: "",
  description: "",
  workItems: [],
  employeeJobAssociations: [],
};

//toggle initial details
const initialToggleJobDetails = {
  toggleJobModal: false,
  toggleJobAssignUser: false,
  isChange: false,
  isDisabled: true,
  isUpdatingJobDetails: false,
  isDeleteJobDetails: false,
};

const Job = () => {
  // state for storing all job details to show in the listing page.
  const [jobsDetails, setJobsDetails] = useState([]);

  // state for handling and storing toggle job details.
  const [toggleJobDetails, setToggleJobDetails] = useState(initialToggleJobDetails);

  // State for storing form values.
  const [jobFormDetails, setJobFormDetails] = useState(jobInitialDetails);

  //State to store id of a job for deleting an element.
  const [deleteId, setDeleteId] = useState(0);

  const { pageSize, pageNo } = useSelector(state => state.pagination);
  const [totalRecords, setTotalRecords] = useState(0);
  const { setPageSizeHandler, onPageChange } = usePagination(false, totalRecords);

  //State for storing assignees for a job of a selected project
  const [assignees, setAssignees] = useState([]);

  // fetching all Jobs to show in the listing page
  useEffect(() => {
    getAllJobs();
  }, [toggleJobDetails.isChange, pageSize, pageNo]);

  //fetching all assignees to show in dropdown
  useEffect(() => {
    if (jobFormDetails.projectName.id) {
      setAssignees([]);
      fetchAssignees();
    }
  }, [jobFormDetails.projectName.id]);

  const getAllJobs = () => {
    getJob(pageNo, pageSize).then(response => {
      if (!response.data.data) return setJobsDetails([]);
      setJobsDetails(response?.data?.data);
      setTotalRecords(response?.data?.totalRecords);
    });
  };

  //Function to reset states after opening, closing and cancelling the modal
  const resetStates = () => {
    setToggleJobDetails(prevTPD => {
      return {
        ...prevTPD,
        toggleJobModal: false,
        isChange: !prevTPD.isChange,
        isDisabled: true,
        isUpdatingJobDetails: false,
        isDeleteJobDetails: false,
        toggleJobAssignUser: false,
      };
    });
    setJobFormDetails(jobInitialDetails);
    setAssignees([]);
  };

  //Function to fetch all assignees of a selected project
  const fetchAssignees = async () => {
    const response = await getAssignedUsers(jobFormDetails?.projectName?.id);
    response?.data.forEach((user, index) => {
      let userObj = {
        ...user,
        label: user?.firstName + " " + user?.lastName,
        value: user?.id,
      };
      setAssignees(prevAssignees => [...prevAssignees, userObj]);
    });
  };

  // Function to get form values while Adding and Updating Jobs
  const onChangeJobDetails = event => {
    const { name, value } = event.target;
    setJobFormDetails(prevPFD => {
      return {
        ...prevPFD,
        [name]: value,
      };
    });
    setToggleJobDetails(prevTRD => {
      return {
        ...prevTRD,
        isDisabled: !value.trim(),
      };
    });
  };

  // Function to handle the toggle of adding a job.
  const handleToggleAddJob = () => {
    setToggleJobDetails(prevTJD => {
      return {
        ...prevTJD,
        toggleJobModal: !prevTJD.toggleJobModal,
      };
    });
  };

  //Debounce function for handling the Projects API call from react select input field.
  const debouncedProjectPromiseOptions = debouncedPromiseOptions((inputValue, callback) => {
    if (inputValue) {
      getProjects(DEFAULT_PAGE_NO, DEFAULT_PAGE_SIZE, inputValue).then(response => {
        let structuredData = response?.data.map(user => {
          return {
            label: `${user.name}`,
            id: user.id,
            value: user.id,
          };
        });
        callback(structuredData);
      });
    } else {
      callback([]);
    }
  });

  //Function for handling the change of Project details from react select input field.
  const handleProjectChange = selectedProject => {
    setJobFormDetails(prevJFD => {
      return {
        ...prevJFD,
        projectName: selectedProject,
        employeeJobAssociations: [],
      };
    });
    setToggleJobDetails(prevTJD => {
      return {
        ...prevTJD,
        isDisabled: !selectedProject,
      };
    });
  };

  //Function for submitting job details while Adding a Job.
  const onSubmitJobDetails = async () => {
    if (jobFormDetails.name.trim() === "") {
      return showToast("error", JOB_MESSAGES.MANDATORY_FIELDS);
    }

    if (Object.keys(jobFormDetails?.projectName).length === 0) {
      return showToast("error", JOB_MESSAGES.MANDATORY_FIELDS);
    }

    const newEmployeeJobAssociations = [];
    jobFormDetails?.employeeJobAssociations.forEach((user, index) => {
      let userObj = {};
      userObj.employeeId = user?.id;
      newEmployeeJobAssociations.push(userObj);
    });

    const jobFields = {
      name: jobFormDetails.name,
      projectId: jobFormDetails.projectName.id,
      startDate: jobFormDetails.startDate,
      endDate: jobFormDetails.endDate,
      isBillable: getIsBillableValue(jobFormDetails.isBillable),
      rph: +jobFormDetails.rph,
      description: jobFormDetails.description,
      employeeJobAssociations: newEmployeeJobAssociations,
    };

    const response = await addJob(jobFields);
    if (response.data.statusCode === STATUS_CODES.SUCCESS) {
      showToast("success", JOB_MESSAGES.SUCCESS);
      resetStates();
    } else {
      showToast("error", JOB_MESSAGES.SUCCESS_FAILED);
    }
  };

  //Function to get job details when clicking on update icon for Update Job details Or Function to get job details by Job id.
  const handleUpdateJob = async jobId => {
    setToggleJobDetails(prevTJD => {
      return {
        ...prevTJD,
        toggleJobModal: !prevTJD.toggleJobModal,
        isUpdatingJobDetails: true,
      };
    });

    const response = await getJobById(jobId);
    const {
      id,
      name,
      project,
      startDate,
      endDate,
      isBillable,
      rph,
      description,
      workItems,
      employeeJobAssociations,
    } = response?.data?.data;

    //Formatting start date to send to API
    if (startDate) {
      const modifiedStartDate = new Date(startDate);
      const formattedStartDate = format(modifiedStartDate, "yyyy-MM-dd");
      setJobFormDetails(prevPFD => {
        return {
          ...prevPFD,
          startDate: formattedStartDate,
        };
      });
    } else {
      setJobFormDetails(prevJFD => {
        return {
          ...prevJFD,
          startDate: null,
        };
      });
    }

    //Formatting end date to send to API
    if (endDate) {
      const modifiedEndDate = new Date(endDate);
      const formattedEndDate = format(modifiedEndDate, "yyyy-MM-dd");
      setJobFormDetails(prevPFD => {
        return {
          ...prevPFD,
          endDate: formattedEndDate,
        };
      });
    } else {
      setJobFormDetails(prevJFD => {
        return {
          ...prevJFD,
          endDate: null,
        };
      });
    }

    if (response?.data?.statusCode === STATUS_CODES.SUCCESS) {
      //formatting employeeJobAssociations to show in async select
      let jobInputFields = [];
      employeeJobAssociations?.forEach((user, index) => {
        const asyncOptions = {
          label: `${user?.employee?.firstName} ${user?.employee?.lastName}`,
          value: user?.employeeId,
          id: user?.id,
          employeeId: user?.employeeId,
        };
        jobInputFields.push(asyncOptions);
      });

      //formatting projects to show in async select
      let projectInputFields = {
        label: project?.name,
        id: project?.id,
        value: project?.id,
      };
      setJobFormDetails(prevPFD => {
        return {
          ...prevPFD,
          id: id,
          name: name,
          projectName: projectInputFields,
          isBillable: isBillable,
          rph: +rph,
          description: description,
          workItems: workItems,
          employeeJobAssociations: jobInputFields,
        };
      });
    }
  };

  // Function for handling the submission and update of job details.
  const onSubmitUpdateJobDetails = async () => {
    if (jobFormDetails.name.trim() === "") {
      return showToast("error", JOB_MESSAGES.MANDATORY_FIELDS);
    }

    if (Object.keys(jobFormDetails?.projectName).length === 0) {
      return showToast("error", JOB_MESSAGES.MANDATORY_FIELDS);
    }

    const newEmployeeJobAssociations = jobFormDetails?.employeeJobAssociations.map(userObj => {
      if (userObj.employeeId) {
        return {
          id: userObj.id,
          employeeId: userObj.employeeId,
        };
      } else {
        return {
          employeeId: userObj.id,
        };
      }
    });

    const jobFields = {
      ...jobFormDetails,
      isBillable: getIsBillableValue(jobFormDetails.isBillable),
      employeeJobAssociations: newEmployeeJobAssociations,
      projectId: jobFormDetails.projectName.id,
      rph: +jobFormDetails.rph,
    };

    const response = await updateJob(jobFields);
    if (response?.data?.statusCode === STATUS_CODES.SUCCESS) {
      showToast("success", JOB_MESSAGES.UPDATE);
      resetStates();
    } else {
      showToast("error", JOB_MESSAGES.UPDATE_FAILED);
    }
  };

  //Function for handling the change of Assignee user details
  const handleAssigneesChange = selectedValue => {
    setJobFormDetails(prevJFD => {
      return {
        ...prevJFD,
        employeeJobAssociations: selectedValue,
      };
    });
    setToggleJobDetails(prevTJD => {
      return {
        ...prevTJD,
        isDisabled: !selectedValue,
      };
    });
  };

  //Function for handling the Assignees user from react select. NOT REQUIRED AS OF NOW
  const debouncedPromiseOptionsAssignee = debouncedPromiseOptions((inputValue, callback) => {
    if (inputValue) {
      GetUserByParam(inputValue).then(response => {
        let structuredData = response?.data.map(user => {
          return {
            label: `${user.firstName} ${user.lastName}`,
            value: user.id,
          };
        });
        callback(structuredData);
      });
    } else {
      callback([]);
    }
  });

  // Function to delete a project by its ID
  const handleDeleteJob = id => {
    setToggleJobDetails(prevTJD => {
      return {
        ...prevTJD,
        isDeleteJobDetails: true,
      };
    });
    setDeleteId(id);
  };

  return (
    <>
      <PageSection
        heading="Jobs"
        icon={<MdOutlineRocketLaunch size={"2rem"} />}
        showIcon={true}
        button={
          <Button onClick={handleToggleAddJob} className={`${btnPrimary()}`}>
            <GoPlus size={"1rem"} />
            &nbsp;&nbsp;Add Job
          </Button>
        }>
        {/* JobsList component for displaying all jobs */}
        <JobsList
          jobsDetails={jobsDetails}
          handleDeleteJob={handleDeleteJob}
          handleUpdateJob={handleUpdateJob}
        />
        <PaginationComponent
          onChange={setPageSizeHandler}
          onPageChange={onPageChange}
          showIcons={true}
          totalRecords={totalRecords}
        />
      </PageSection>

      {/* AddJob component for Adding and Updating a Job */}
      <AddJob
        toggleJobDetails={toggleJobDetails}
        resetStates={resetStates}
        jobFormDetails={jobFormDetails}
        onChangeJobDetails={onChangeJobDetails}
        onSubmitUpdateJobDetails={onSubmitUpdateJobDetails}
        onSubmitJobDetails={onSubmitJobDetails}
        handleAssigneesChange={handleAssigneesChange}
        setJobFormDetails={setJobFormDetails}
        debouncedPromiseOptionsAssignee={debouncedPromiseOptionsAssignee}
        handleProjectChange={handleProjectChange}
        debouncedProjectPromiseOptions={debouncedProjectPromiseOptions}
        assignees={assignees}
      />

      {/* DeleteJob component for Deleting a Job */}
      <DeleteJob
        toggleJobDetails={toggleJobDetails}
        resetStates={resetStates}
        deleteId={deleteId}
      />
    </>
  );
};

export default Job;
