import React, { useState, useEffect } from "react";
import { updateQuickLinks } from "../../../services/CommonService";
import ModalComponent from "../../../components/common/Modal/Modal";
import ModalButtons from "../../../components/common/Modal/ModalButtons";
import { showToast } from "../../../components/common/Toast";
import { QUICKLINK_MESSAGES } from "../../../constants/Messages";
import InputFields from "../../../components/common/Input/InputFields";
import { inputArray, inputClassName } from "./helper";

const UpdateQuickLink = ({ show, onClose, renderQuickLink, setLoading, quickLinkInfo }) => {
  const [quickLinkDetails, setQuickLinkDetails] = useState(quickLinkInfo);

  useEffect(() => {
    // Update quickLinkDetails when quickLinkInfo changes
    setQuickLinkDetails(quickLinkInfo);
  }, [quickLinkInfo]);

  const onChangeQuickLinkHandler = event => {
    let { name, value } = event.target;

    setQuickLinkDetails(prevQL => {
      return {
        ...prevQL,
        [name]: value,
      };
    });
  };
  
  const onSubmitQuickLink = async event => {
    event.preventDefault();

    const { id, name, url } = quickLinkDetails;

    const currentQuickLinkDetails = {
      id: id,
      name: name,
      url: url,
    };

    if (name.trim() === "" || url === "") return showToast("warn", QUICKLINK_MESSAGES.WARN);

    const { toast, toastId } = showToast("loading", QUICKLINK_MESSAGES.LOADING_UPDATE);
    //Submitting Quick Link details
    await updateQuickLinks(currentQuickLinkDetails);
    showToast("success", QUICKLINK_MESSAGES.UPDATE);
    toast.dismiss(toastId);

    setLoading(true);
    renderQuickLink();
    onModalCloseHandler();
    setLoading(false);
  };

  const onModalCloseHandler = () => {
    onClose();
  };

  const _inputArray = inputArray(quickLinkDetails);
  const _inputClassName = inputClassName;

  return (
    <>
      <ModalComponent show={show} onClose={onClose} showFooter={false} heading="Edit Quick Link">
        <form className="mx-auto max-w-2xl p-4 m-auto overflow-x-hidden overflow-y-auto">
          <div className="grid gap-4 mb-4 md:gap-6 md:grid-cols-2 sm:mb-8">
            {/*Input Fields for updating quick link */}
            <InputFields
              inputArr={_inputArray}
              onChange={onChangeQuickLinkHandler}
              inputClassName={_inputClassName}
            />
          </div>

          {/* Button to Update Quick LInk */}
          <ModalButtons
            onClickAction={onSubmitQuickLink}
            onClickCancel={onModalCloseHandler}
            actionBtnText="Update"
            cancelBtnText="Cancel"
          />
        </form>
      </ModalComponent>
    </>
  );
};

export default UpdateQuickLink;
