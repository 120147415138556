import { useState, useEffect, memo } from "react";
import { IoFilter } from "react-icons/io5";
import { getBalanceReports, getBalanceHistories } from "../../services/LeaveService";
import AddCustomizeBalance from "./AddCustomizeBalance";
import CustomizeBalanceFilter from "./CustomizeBalanceFilter";
import HistoryModal from "./HistoryModal";
import { formattedDate } from "../../utils/common";
import Table from "../common/Table/Table";
import TableRows from "../common/Table/TableRows";
import { MdUpdate } from "react-icons/md";
import { Tooltip as ReactTooltip } from "react-tooltip";
import usePagination, { PaginationComponent } from "../../hooks/usePagination";
import { useSelector } from "react-redux";

const CustomizeBalance = memo(() => {

  const { pageNo, pageSize } = useSelector(state => state.pagination);
  const [totalRecords, setTotalRecords] = useState(0);
  const { setPageSizeHandler, onPageChange } = usePagination(false, totalRecords);

  const [toggleCustomizeBalance, setToggleCustomizeBalance] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [customizeBalanceColumn, setCutomizeBalanceColumn] = useState([]);
  const [cutomizeBalanceRow, setCutomizeBalanceRow] = useState([]);
  const [employeeId, setEmployee] = useState({});
  const [balanceHistoryState, setBalanceHistoryState] = useState({
    show: false,
    balanceHistoryRows: [],
    balanceHistoryColumns: ["Type", "Change", "Balance", "Date", "Reason", "Action"],
  });
  let [defaultValueState, setDefaultValue] = useState({
    defaultValue: null,
  });
  const [reFetch, setReFetch] = useState(false);
  // closing dropdown
  const [isOpen, setIsOpen] = useState(false);

  const { show, balanceHistoryRows, balanceHistoryColumns } = balanceHistoryState;
  let { defaultValue } = defaultValueState;

  const fetchLeaveBuckets = () => {
    getBalanceReports("", pageNo, pageSize).then(response => {
      let rows = [],
        columns = ["Employee"];
      rows = response.data.data.map(item => {
        let row = new Array(item.leaveBuckets.length + 1).fill("-");
        row[0] = { label: `${item.firstName} ${item.lastName}`, value: item.employeeId };
        item.leaveBuckets.forEach((leaveBucket, index) => {
          if (!columns.includes(leaveBucket.leaveType.name)) {
            columns.push(leaveBucket.leaveType.name);
          }
          row[index + 1] = {
            label: leaveBucket.availableLeaves.toFixed(1),
            value: { leaveTypeId: leaveBucket.leaveType.id, employeeId: item.employeeId },
          };
        });
        rows.push(row);
        return row;
      });
      setTotalRecords(response.data.totalRecords)
      setCutomizeBalanceRow(rows);
      setCutomizeBalanceColumn(columns);
      // setSelectedUserId(rows[0][0].value);
    });
  };

  useEffect(() => {
    fetchLeaveBuckets();
  }, [reFetch, pageSize, pageNo]);

  // fetching filtered data
  const onSubmitFilterHandler = e => {
    e.preventDefault();
    let id = "value" in employeeId ? employeeId.value : "";
    getBalanceReports(id).then(response => {
      let rows = [],
        columns = ["Employee"];
      rows = response.data.data.map(item => {
        let row = new Array(item.leaveBuckets.length + 1).fill("-");
        row[0] = { label: `${item.firstName} ${item.lastName}`, value: null };
        item.leaveBuckets.forEach((leaveBucket, index) => {
          if (!columns.includes(leaveBucket.leaveType.name)) {
            columns.push(leaveBucket.leaveType.name);
          }
          row[index + 1] = {
            label: leaveBucket.availableLeaves.toFixed(1),
            value: { leaveTypeId: leaveBucket.leaveType.id, employeeId: item.employeeId },
          };
        });
        rows.push(row);
        return row;
      });
      setCutomizeBalanceRow(rows);
      setCutomizeBalanceColumn(columns);
    });
  };

  const toggleDropDown = () => {
    setIsOpen(prev => !prev);
  };

  // when clicked outside dropdown
  const closeDropdown = () => {
    setIsOpen(false);
  };

  // clear filter
  const clearFilter = e => {
    setEmployee({});
    getBalanceReports("").then(response => {
      let rows = [],
        columns = ["Employee"];
      rows = response.data.data.map(item => {
        let row = new Array(item.leaveBuckets.length + 1).fill("-");
        row[0] = { label: `${item.firstName} ${item.lastName}`, value: null };
        item.leaveBuckets.forEach((leaveBucket, index) => {
          if (!columns.includes(leaveBucket.leaveType.name)) {
            columns.push(leaveBucket.leaveType.name);
          }
          row[index + 1] = {
            label: leaveBucket.availableLeaves.toFixed(1),
            value: { leaveTypeId: leaveBucket.leaveType.id, employeeId: item.employeeId },
          };
        });
        rows.push(row);
        return row;
      });
      setCutomizeBalanceRow(rows);
      setCutomizeBalanceColumn(columns);
    });
  };

  const handleCell = e => {
    if (e.target.nodeName === "svg") {
      let elem = e.target.closest("[data-obj]");
      if (elem) {
        let data = JSON.parse(elem.dataset.obj);
        getBalanceHistories(data.employeeId, data.leaveTypeId)
          .then(res => {
            let data = res.data.data;
            if (data.length === 0) return;
            let rows = [];
            data.forEach(item => {
              let row = new Array(3).fill("-");
              row[0] = { label: item.type, value: null };
              row[1] = { label: item.change, value: null };
              row[2] = { label: item.balanceAfterChange, value: null };
              row[3] = { label: formattedDate(item.date), value: null };
              row[4] = { label: item.reason == "" ? "-" : item.reason, value: null}
              if (item.type === "ManualUpdate") {
                row[5] = { label: "Delete", value: item.id, canBeDeleted: true };
              }
              else {
                row[5] = { label: "Delete", value: item.id };
              }
              rows.push(row);
            });

            setBalanceHistoryState(s => ({ ...s, show: !s.show, balanceHistoryRows: rows }));
          })
          .catch(e => {
            console.error(e);
          });
      }
      return;
    }

    let row = e.target.closest("[data-row]");
    if (row) {
      row = JSON.parse(row.dataset.row);
      let employeeId = row.length > 0 ? row.at(-1).value.employeeId : null;
      setDefaultValue(s => ({ ...s, defaultValue: row[0] }));
      setToggleCustomizeBalance(true);
      setSelectedUserId(employeeId);
      return;
    }
  };

  const closeHistoryModal = () => {
    setBalanceHistoryState(s => ({ ...s, show: false }));
    setReFetch(f => !f);
  };

  const toggleModal = () => {
    let employeeId = cutomizeBalanceRow[0][0].value;
    setDefaultValue(s => ({ ...s, defaultValue: cutomizeBalanceRow[0] }));
    setSelectedUserId(employeeId);
    setToggleCustomizeBalance(true);
  };

  return (
    <div className="my-leaves-container min-h-[89.7vh] relative">
      <section className="h-[calc(100vh-61px)] py-3 sm:py-5 tc-page">
        <div className="px-4 mx-auto max-w-screen-4xl lg:px-12">
          <div className="flex flex-wrap items-center gap-1 justify-between">
            <div className="flex flex-wrap items-center gap-3">
              <div className="pt-1 focus:none text-gray-600 dark:text-white">
                <MdUpdate size={"2.2rem"} />
              </div>
              <h1 className="text-4xl text-gray-900 dark:text-white py-4 cursor-pointer">
                Update Leaves
              </h1>
            </div>
            <div className="flex flex-wrap items-end wrap space-x-4">
              <div className="flex justify-end items-center m-1">
                {/* <button
                  id="openBtn"
                  className="!px-4 !py-2 rounded-md text-white bg-gray-400 tracking-wide font-semibold flex items-center gap-2 m-4 "
                  onClick={toggleDropDown}
                  data-id="openCustomizeBalanceFilter">
                  {" "}
                  <span>
                    <IoFilter />
                  </span>
                  Filter
                </button> */}

                <AddCustomizeBalance
                  key={selectedUserId}
                  customizeBalanceCol={customizeBalanceColumn}
                  toggleCustomizeBalance={toggleCustomizeBalance}
                  setToggleCustomizeBalance={setToggleCustomizeBalance}
                  selectedUserId={selectedUserId}
                  setSelectedUserId={setSelectedUserId}
                  defaultValue={defaultValue}
                  toggleModal={toggleModal}
                  setDefaultValue={setDefaultValue}
                  setReFetch={setReFetch}
                />
                <HistoryModal
                  show={show}
                  balanceHistoryRows={balanceHistoryRows}
                  balanceHistoryColumns={balanceHistoryColumns}
                  onClose={closeHistoryModal}
                  Table={Table}
                  TableRows={TableRows}
                />
              </div>
            </div>
          </div>

          <div className="mx-auto flex justify-center items-center">
            <div className="flex items-center justify-end p-2 ">
              {isOpen && (
                <CustomizeBalanceFilter
                  isOpen={isOpen}
                  closeDropdown={closeDropdown}
                  clearFilter={clearFilter}
                  setEmployee={setEmployee}
                  onSubmitFilterHandler={onSubmitFilterHandler}
                  employeeId={employeeId}
                />
              )}
            </div>
          </div>

          <div className="overflow-hidden bg-white shadow-md dark:bg-gray-800 sm:rounded-lg relative">
            <div className="overflow-x-auto">
              <Table col={customizeBalanceColumn} thClassName={"text-center"}>
                <tbody className=" text-center" onClick={handleCell}>
                  {cutomizeBalanceRow.map((row, index) => {
                    return (
                      <TableRows
                        key={index}
                        dataset={JSON.stringify(row)}
                        className="h-10 border-b dark:border-gray-600 hover:bg-gray-100 dark:hover:bg-gray-700">
                        {row.map((cell, cellIndex) => {
                          let showDots =
                            cellIndex !== 0 ? (
                              <div>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  strokeWidth={1.5}
                                  stroke="currentColor"
                                  data-obj={JSON.stringify(cell.value)}
                                  data-tooltip-id="leaves-history"
                                  data-tooltip-html="View history"
                                  data-tooltip-place="right-start"
                                  className="w-[17px] h-[17px] group-hover:block cursor-pointer absolute right-[-25px] top-[3px] hidden">
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M12 6v6h4.5m4.5 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                                  />
                                </svg>
                                <ReactTooltip id="leaves-history" />
                              </div>
                            ) : null;
                          return (
                            <td className="group" key={cellIndex}>
                              <div className="flex items-center justify-center gap-[10px]">
                                <p className="relative">
                                  {cell.label}
                                  {showDots}
                                </p>
                              </div>
                            </td>
                          );
                        })}
                      </TableRows>
                    );
                  })}
                </tbody>
              </Table>
              <PaginationComponent
                onChange={setPageSizeHandler}
                onPageChange={onPageChange}
                showIcons={true}
                totalRecords={totalRecords}
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
});

export default CustomizeBalance;
