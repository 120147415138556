import React, { useEffect, useState } from "react";
import { FaRegCopy, FaRegEdit, FaRegTrashAlt } from "react-icons/fa";
import AddNewLeaveType from "./LeaveTypes/AddNewLeaveType";
import {
  changeLeaveTypeToggleStatus,
  deleteLeaveTypes,
  getLeaveTypePolicyById,
  getLeaveTypes,
} from "../../services/LeaveService";
import {
  generateEntitlementCard,
  initialEntitlementCardDetails,
  initialLeaveTypeDetails,
  leaveTypeColors,
  leaveTypeImageIcons,
  status,
  storeApplicableDetails,
} from "./helper";
import { btnPrimary } from "../../utils/helper";
import { Button, Modal } from "flowbite-react";
import toast from "react-hot-toast";
import { Role } from "../../enum/Role";
import { GiThreeLeaves } from "react-icons/gi";
import { getUsersFields } from "../../services/UserService";
import { GoPlus } from "react-icons/go";
import { STATUS_CODES } from "../../constants/Constant";
import usePagination, { PaginationComponent } from "../../hooks/usePagination";
import { useSelector } from "react-redux";

const LeaveTypes = () => {
  //State to render the component
  const [isChange, setIsChange] = useState(false);
  //state to toggle the leave type modal
  const [toggleAddLeaveType, setToggleAddLeaveType] = useState(false);

  //State to store entitlement card details
  const [entitlementCardDetails, setEntitlementCardDetails] = useState([
    initialEntitlementCardDetails,
  ]);

  //State to store Applicable input field details
  const [selectedApplicableOptions, setSelectedApplicableOptions] = useState({
    employmentType: {
      selected: [],
    },
  });

  //State for toggling to Update leave type
  const [isUpdatingLeaveType, setIsUpdatingLeaveType] = useState(false);

  //State to store leave type details
  const [leaveTypeFormDetails, setLeaveTypeFormDetails] = useState(initialLeaveTypeDetails);
  const [openLeaveTypeDeleteModal, setOpenModal] = useState(false);
  const [deleteId, setDeleteId] = useState(undefined);

  const [allLeaveType, setAllLeaveType] = useState({
    leaveTypes: [],
    reFetch: false,
  });

  //State for toggling leave type icon and color
  const [leaveTypeIconAndColorToggle, setLeaveTypeIconAndColorToggle] = useState({
    isImageIconToggle: false,
    isColorToggle: false,
  });

  //State for selecting leave type icon and color
  const [selectedImageIconAndColor, setSelectedImageIconAndColor] = useState({
    selectedImageIcon: leaveTypeImageIcons[0],
    selectedIconColor: leaveTypeColors[0],
  });

  //State to store applicable details coming from server
  const [applicableDetails, setApplicableDetails] = useState([]);

  //State to store sidebar tab details
  const [activeItem, setActiveItem] = useState("generalInformation");

  //State to disable the submit button
  const [disableSubmitBtn, setDisableSubmitBtn] = useState(true);

  const { pageNo, pageSize } = useSelector(state => state.pagination);
  const [totalRecords, setTotalRecords] = useState(0);
  const { setPageSizeHandler, onPageChange } = usePagination(false, totalRecords);

  //useEffect for fetching leave types
  useEffect(() => {
    fetchLeaveTypes();
  }, [isChange, pageSize, pageNo]);

  //use effect hook, is used here to make the api call when user moves to the applicable tab
  useEffect(() => {
    fetchUsersFieldsDetails();
  }, []);

  let { leaveTypes } = allLeaveType;

  // Fetching user fields data from where all the applicable options are coming
  const fetchUsersFieldsDetails = () => {
    getUsersFields().then(response => {
      setApplicableDetails(response?.data);
    });
  };

  // Reset state function
  const resetState = () => {
    setToggleAddLeaveType(false);
    setEntitlementCardDetails([initialEntitlementCardDetails]);
    setLeaveTypeFormDetails(initialLeaveTypeDetails);
    setSelectedApplicableOptions({ employmentType: { selected: [] } });
    setSelectedImageIconAndColor({
      selectedImageIcon: leaveTypeImageIcons[0],
      selectedIconColor: leaveTypeColors[0],
    });
    setLeaveTypeIconAndColorToggle({ isImageIconToggle: false, isColorToggle: false });
    setIsUpdatingLeaveType(false);
    setActiveItem("generalInformation");
    setDisableSubmitBtn(true);
  };

  //Function to change the status of the leave type
  const onHandleToggleLeaveType = async id => {
    const response = await changeLeaveTypeToggleStatus(id);
    if (response?.statusCode === STATUS_CODES.SUCCESS) {
      setAllLeaveType(prevALT => {
        const updatedLeaveTypes = prevALT.leaveTypes.map(item => {
          if (item.id === id) {
            return {
              ...item,
              status: !item.status,
            };
          }
          return item;
        });

        return {
          ...prevALT,
          leaveTypes: updatedLeaveTypes,
        };
      });
    }
  };

  const leaveTypeDelete = async () => {
    const response = await deleteLeaveTypes(+deleteId);
    if (response.data.statusCode === 200) {
      setAllLeaveType(prevState => ({
        ...prevState,
        leaveTypes: prevState.leaveTypes.filter(item => item.id !== +deleteId),
        reFetch: !prevState.reFetch,
      }));

      toast.success("deleted successfully");
      setOpenModal(false);
    }
  };

  const fetchLeaveTypes = async () => {
    const res = await getLeaveTypes(pageNo, pageSize);

    setAllLeaveType(s => ({ ...s, leaveTypes: res.data }));
    setTotalRecords(res.totalRecords);
  };

  //reset the values to default value on Closing the Modal
  const onCloseModal = () => {
    if (isUpdatingLeaveType) {
      setIsUpdatingLeaveType(false);
    }
    resetState();
  };

  // Function for Cloning leave type
  const handleCloneLeaveType = leaveTypeId => {
    setToggleAddLeaveType(true);
    getLeaveTypePolicyById(leaveTypeId).then(response => {
      const {
        leavePolicies,
        description,
        isPaid,
        name,
        shortCode,
        colorCode,
        icon,
        validityEnd,
        validityStart,
        id,
        leaveTypeFieldValues,
        allowNegativeLeaves,
      } = response.data.data;

      //selecting icon component according to icon name
      let iconObject = leaveTypeImageIcons.filter(item => {
        if (item.iconName === icon) {
          return item;
        }
      });
      setSelectedImageIconAndColor(prevSIIAC => {
        return {
          ...prevSIIAC,
          selectedImageIcon: iconObject[0],
        };
      });

      //Selecting color
      let colorObject = leaveTypeColors.filter(item => {
        if (item.color === colorCode) {
          return item;
        }
      });
      setSelectedImageIconAndColor(prevSIIAC => {
        return {
          ...prevSIIAC,
          selectedIconColor: colorObject[0],
        };
      });

      //Getting and storing entitlement card details
      const updatedEntitlementCardDetails = generateEntitlementCard(leavePolicies, false);
      setEntitlementCardDetails(updatedEntitlementCardDetails);

      //Storing leave type form details
      setLeaveTypeFormDetails({
        id: 0,
        name: name + ` (Clone)`,
        shortCode: null,
        colorCode: colorCode,
        icon: icon,
        isPaid: isPaid,
        description: description,
        validityStart: validityStart,
        validityEnd: validityEnd,
        allowNegativeLeaves: allowNegativeLeaves
      });

      //Getting and storing applicable details
      const selectedOption = storeApplicableDetails(leaveTypeFieldValues, "cloneLeaveType");
      setSelectedApplicableOptions({
        employmentType: {
          selected: selectedOption,
        },
      });
    });
  };

  //Function for updating leave type
  const updateToggleLeaveType = id => {
    setIsUpdatingLeaveType(true);
    setToggleAddLeaveType(true);
    getLeaveTypePolicyById(id).then(response => {
      const {
        leavePolicies,
        description,
        isPaid,
        name,
        shortCode,
        validityEnd,
        validityStart,
        id,
        leaveTypeFieldValues,
        reduceLeaveOnAbsent,
        allowNegativeLeaves,
        icon,
        colorCode,
      } = response.data.data;

      //selecting icon component according to icon name
      let iconObject = leaveTypeImageIcons.filter(item => {
        if (item.iconName === icon) {
          return item;
        }
      });
      setSelectedImageIconAndColor(prevSIIAC => {
        return {
          ...prevSIIAC,
          selectedImageIcon: iconObject[0],
        };
      });

      //Selecting color
      let colorObject = leaveTypeColors.filter(item => {
        if (item.color === colorCode) {
          return item;
        }
      });
      setSelectedImageIconAndColor(prevSIIAC => {
        return {
          ...prevSIIAC,
          selectedIconColor: colorObject[0],
        };
      });

      //Getting and storing entitlement card details
      const updatedEntitlementCardDetails = generateEntitlementCard(leavePolicies, true);
      setEntitlementCardDetails(updatedEntitlementCardDetails);

      //Storing leave type form details
      setLeaveTypeFormDetails({
        id: id,
        name: name,
        shortCode: shortCode,
        colorCode: colorCode,
        icon: icon,
        isPaid: isPaid,
        description: description,
        validityStart: validityStart,
        validityEnd: validityEnd,
        reduceLeaveOnAbsent: reduceLeaveOnAbsent,
        allowNegativeLeaves: allowNegativeLeaves
      });

      //Getting and storing applicable details
      const selectedOption = storeApplicableDetails(leaveTypeFieldValues, "updateLeaveType");
      setSelectedApplicableOptions({
        employmentType: {
          selected: selectedOption,
        },
      });
    });
  };

  const openDeleteModal = function (id) {
    const typeId = id;
    setDeleteId(typeId);
    setOpenModal(true);
  };

  return (
    <div className="my-leaves-container relative overflow-hidden">
      <section className="py-3 sm:py-5 tc-page h-[92vh] overflow-y-auto">
        <div className="px-4 mx-auto max-w-screen-4xl lg:px-12">
          <div className="flex flex-wrap items-center gap-1 justify-between mb-5">
            <div className="flex flex-wrap items-center gap-3">
              <div className="pt-1 focus:none text-gray-800 dark:text-white">
                <GiThreeLeaves size={"2rem"} />
              </div>
              <h1 className="text-4xl text-gray-900 dark:text-white py-4 cursor-pointer">
                Leave Types
              </h1>
            </div>
            {Role.Administrator && (
              <div className="flex justify-center items-center mr-2flex flex-wrap items-end wrap space-x-4">
                <Button onClick={() => setToggleAddLeaveType(true)} className={`${btnPrimary()}`}>
                  <GoPlus size={"1rem"} />
                  &nbsp;&nbsp;Add Leave Type
                </Button>
              </div>
            )}
          </div>
          <div className="overflow-hidden shadow-md sm:rounded-lg bg-white relative">
            <div className="overflow-x-auto pt-0 p-3">
              {leaveTypes.map((type, index) => {
                return (
                  <div
                    key={index}
                    className="min-w-[35rem] flex justify-between mt-3 border border-gray-200 rounded-lg shadow-sm p-4 bg-white items-center group hover:bg-gray-100">
                    <div className="flex items-center w-[11rem] md:gap-[15px] md:w-[15rem]">
                      <div
                        style={{ ...(type?.colorCode && { backgroundColor: type?.colorCode }) }}
                        className={`min-w-[12px] min-h-[12px] bg-${
                          !type?.colorCode && "yellow-300"
                        } 
                        bg-[${type?.colorCode !== null && type?.colorCode}]
                        border-2 border-white dark:border-gray-800 rounded-full`}></div>
                      <div className="font-semibold">{type.name}</div>
                      <div className="h-6 w-[1px] mx-[5px] bg-gray-200"></div>
                      <div className="">
                        <span className="bg-gray-200 text-gray-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-gray-300">
                          {status(type?.isPaid)}
                        </span>
                      </div>
                    </div>

                    <div className={`text-gray-500`}>
                      <div>Days</div>
                    </div>

                    <div className="relative flex items-center min-w-[10rem]">
                      <div className="">
                        <label className="relative inline-flex items-center cursor-pointer">
                          <input
                            type="checkbox"
                            value=""
                            checked={type?.status}
                            onChange={() => onHandleToggleLeaveType(type?.id)}
                            className="sr-only peer"
                          />
                          <div
                            className={`w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-thynkwebPrimary-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-thynkwebPrimary-800`}></div>
                        </label>
                      </div>
                      <div className="h-6 w-[1px] mx-[5px] bg-gray-200"></div>

                      <div className="absolute right-0 flex items-center opacity-0 transition-opacity duration-300 group-hover:opacity-100">
                        {!type?.reduceLeaveOnAbsent && (
                          <div
                            className="cursor-pointer p-2 hover:text-thynkwebPrimary-800 hover:text-xl"
                            onClick={handleCloneLeaveType.bind(null, type?.id)}>
                            <FaRegCopy />
                          </div>
                        )}

                        <div className="cursor-pointer p-2 hover:text-thynkwebPrimary-800 hover:text-xl">
                          <FaRegEdit onClick={updateToggleLeaveType.bind(null, type?.id)} />
                        </div>
                        {!type?.isEligibleOnFlexiHoliday && (
                          <div
                            className="cursor-pointer p-2 hover:text-red-400 hover:text-xl"
                            data-modal-target="popup-modal"
                            data-modal-toggle="popup-modal"
                            onClick={() => openDeleteModal(type.id)}>
                            <FaRegTrashAlt />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}
              <AddNewLeaveType
                toggleAddLeaveType={toggleAddLeaveType}
                setToggleAddLeaveType={setToggleAddLeaveType}
                onCloseModal={onCloseModal}
                entitlementCardDetails={entitlementCardDetails}
                setEntitlementCardDetails={setEntitlementCardDetails}
                selectedApplicableOptions={selectedApplicableOptions}
                setSelectedApplicableOptions={setSelectedApplicableOptions}
                leaveTypeFormDetails={leaveTypeFormDetails}
                setLeaveTypeFormDetails={setLeaveTypeFormDetails}
                isUpdatingLeaveType={isUpdatingLeaveType}
                setIsUpdatingLeaveType={setIsUpdatingLeaveType}
                isChange={isChange}
                setIsChange={setIsChange}
                leaveTypeIconAndColorToggle={leaveTypeIconAndColorToggle}
                setLeaveTypeIconAndColorToggle={setLeaveTypeIconAndColorToggle}
                selectedImageIconAndColor={selectedImageIconAndColor}
                setSelectedImageIconAndColor={setSelectedImageIconAndColor}
                setApplicableDetails={setApplicableDetails}
                applicableDetails={applicableDetails}
                resetState={resetState}
                activeItem={activeItem}
                setActiveItem={setActiveItem}
                disableSubmitBtn={disableSubmitBtn}
                setDisableSubmitBtn={setDisableSubmitBtn}
              />
            </div>
          </div>
        </div>

        <PaginationComponent
          onChange={setPageSizeHandler}
          onPageChange={onPageChange}
          showIcons={true}
          totalRecords={totalRecords}
        />
      </section>

      <div className="my-leaves-container relative">
        <Modal show={openLeaveTypeDeleteModal} size="md" onClose={() => setOpenModal(false)}>
          <Modal.Header>Delete Leave Type</Modal.Header>
          <Modal.Body>
            <p className="text-base text-gray-500 dark:text-gray-400">
              Are you sure you want to delete this leave type?
            </p>
          </Modal.Body>
          <Modal.Footer style={{ borderTop: "none", justifyContent: "flex-end" }}>
            <button
              type="button"
              onClick={leaveTypeDelete}
              className="focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900">
              Yes, I'm sure
            </button>

            <button
              type="button"
              onClick={() => setOpenModal(false)}
              className="text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700">
              Cancel
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default LeaveTypes;
