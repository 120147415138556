import { GiCelebrationFire, GiFoodTruck, GiGlassCelebration } from "react-icons/gi";
import { MdCelebration, MdEmojiFoodBeverage, MdOutlineHealthAndSafety } from "react-icons/md";
import { RiCalendar2Line } from "react-icons/ri";
import { v4 as uuidv4 } from "uuid";
import { ImTree } from "react-icons/im";
import { WiNightAltCloudyWindy } from "react-icons/wi";
import { TiCalendarOutline } from "react-icons/ti";
import { MdOutlineEmojiFoodBeverage } from "react-icons/md";
import { SiFoodpanda } from "react-icons/si";
import { TfiShoppingCartFull, TfiVideoCamera } from "react-icons/tfi";
import { TbChristmasTree } from "react-icons/tb";
import { BsMoonStarsFill } from "react-icons/bs";
import { FaFlag } from "react-icons/fa";

//Common styles for input fields
export const tw_styles =
  "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-thynkwebPrimary-600 focus:border-thynkwebPrimary-600 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-thynkwebPrimary-500 dark:focus:border-thynkwebPrimary-500";

//Leave type image icons
export const holidayIcons = [
  {
    id: uuidv4(),
    iconName: "MdCelebration",
    iconComponent: <MdCelebration size={23} />,
  },
  {
    id: uuidv4(),
    iconName: "GiGlassCelebration",
    iconComponent: <GiGlassCelebration size={23} />,
  },
  {
    id: uuidv4(),
    iconName: "TbChristmasTree",
    iconComponent: <TbChristmasTree size={23} />,
  },
  {
    id: uuidv4(),
    iconName: "ImTree",
    iconComponent: <BsMoonStarsFill size={23} />,
  },
  {
    id: uuidv4(),
    iconName: "WiNightAltCloudyWindy",
    iconComponent: <WiNightAltCloudyWindy size={23} />,
  },
  {
    id: uuidv4(),
    iconName: "FaFlag",
    iconComponent: <FaFlag size={23} />,
  },
  {
    id: uuidv4(),
    iconName: "MdOutlineEmojiFoodBeverage",
    iconComponent: <MdOutlineEmojiFoodBeverage size={23} />,
  },
  {
    id: uuidv4(),
    iconName: "GiFoodTruck",
    iconComponent: <GiFoodTruck size={23} />,
  },
  {
    id: uuidv4(),
    iconName: "MdEmojiFoodBeverage",
    iconComponent: <MdEmojiFoodBeverage size={23} />,
  },
  {
    id: uuidv4(),
    iconName: "SiFoodpanda",
    iconComponent: <SiFoodpanda size={23} />,
  },
  {
    id: uuidv4(),
    iconName: "TfiShoppingCartFull",
    iconComponent: <TfiShoppingCartFull size={23} />,
  },
  {
    id: uuidv4(),
    iconName: "TfiVideoCamera",
    iconComponent: <TfiVideoCamera size={23} />,
  },
];

//Leave type icon colors
export const holidayColors = [
  {
    id: uuidv4(),
    color: "#bad989",
  },
  {
    id: uuidv4(),
    color: "#aae09f",
  },
  {
    id: uuidv4(),
    color: "#b8fcc7",
  },
  {
    id: uuidv4(),
    color: "#b8fce2",
  },
  {
    id: uuidv4(),
    color: "#9dc4f5",
  },
  {
    id: uuidv4(),
    color: "#9e9df5",
  },
  {
    id: uuidv4(),
    color: "#bd9df5",
  },
  {
    id: uuidv4(),
    color: "#d7abf7",
  },
  {
    id: uuidv4(),
    color: "#f2abf7",
  },
  {
    id: uuidv4(),
    color: "#f7b5ee",
  },
  {
    id: uuidv4(),
    color: "#f7b5d5",
  },
  {
    id: uuidv4(),
    color: "#f7b5bf",
  },
  {
    id: uuidv4(),
    color: "#f7c5b5",
  },
  {
    id: uuidv4(),
    color: "#f7f3b5",
  },
];
