import Address from "./Address";
import BankDetails from "./BankDetails";
import BasicInfo from "./BasicInfo";
import TaxInformation from "./TaxInformation";
import AdditionalInfo from "./AdditionalInfo";

const Tabs = ({
  isClient,
  onInputChange,
  handleFileChange,
  fileData,
  details,
  activeItem,
  onDeleteLogoHandler,
}) => {
  return (
    <>
      <BasicInfo
        isClient={isClient}
        onChange={onInputChange}
        handleFileChange={handleFileChange}
        fileData={fileData}
        details={details}
        activeItem={activeItem}
        onDeleteLogoHandler={onDeleteLogoHandler}
      />

      <BankDetails
        activeItem={activeItem}
        details={details}
        handleAddNewClientData={onInputChange}
      />

      <TaxInformation
        details={details}
        handleAddNewClientData={onInputChange}
        activeItem={activeItem}
      />

      <Address handleAddNewClientData={onInputChange} details={details} activeItem={activeItem} />

      <AdditionalInfo
        activeItem={activeItem}
        details={details}
        handleAddNewClientData={onInputChange}
      />
    </>
  );
};

export default Tabs;
