import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoadingFirstTC: true,
  disabledInputs: false,
};

const TC = createSlice({
  name: "TC",
  initialState,
  reducers: {
    setLoadingFirstTC(state, action) {
      state.isLoadingFirstTC = action.payload;
    },
    setDisabledInputs(state, action) {
      state.disabledInputs = action.payload;
    },
  },
});

export const { setLoadingFirstTC, setDisabledInputs } = TC.actions;
export default TC.reducer;
