import { createSlice } from "@reduxjs/toolkit";
import { PayType } from "../../enum/PayType";
import { CalculationType } from "../../enum/CalculationType";
import { EPFContributionType } from "../../enum/EPFContributionType";
import { DeductionType } from "../../enum/DeductionType";

const initialState = {
    id: "salary",

    tenantEarnings: [],
    tenantDeductions: [],

    modalValues: {
        showEarningModal: false,
        showDeductionModal: false,
        isEditEarningModal: false,
        isEditDeductionModal: false,
    },

    earningTypesList: [],

    earningDetails: {
        type: null,
        selectedEarning: null,
        lockOptions: {
            lockIsFixedPay: false,
            lockIsPartOfSalaryStructure: false,
            lockIsTaxableEarning: false,
            lockIsProRateBasis: false,
            lockEPFContributionType: false,
            lockIsConsideredForESI: false,
        },
        // Values entered by user
        values: {
            earningName:"",
            nameInPayslip: "",
            amount: 0,
            isFixedPay: false,
            calculationType: CalculationType.FlatAmount,
            isPartOfSalaryStructure: false,
            isTaxableEarning: false,
            isProRateBasis: false,
            epfContributionType: EPFContributionType.Always,
            isConsideredForESI: false,
        }
    },
    deductionDetails:{
        selectedDeduction : null,
        values:{
            deductionName:"",
            description:"",
            isRecurring: false,
            deductionType: DeductionType.PostTax,
            preTaxDeductionSection: null
        }
    },
    
}

const salarySlice = createSlice({
    name: "salary",
    initialState,
    reducers: {
        setModalValues: (state, action) => {
            state.modalValues = action.payload
        },
        setShowEarningModal: (state, action) => {
            state.modalValues.showEarningModal = action.payload
        },
        setShowDeductionModal: (state, action) => {
            state.modalValues.showDeductionModal = action.payload
        },
        setIsEditEarningModal: (state, action) => {
            state.modalValues.isEditEarningModal = action.payload
        },
        setIsEditDeductionModal: (state, action) => {
            state.modalValues.isEditDeductionModal = action.payload
        },
        setEarningTypesList: (state, action) => {
            state.earningTypesList = action.payload
        },
        setTenantEarnings: (state, action) => {
            state.tenantEarnings = action.payload
        },
        setTenantDeductions: (state, action) => {
            state.tenantDeductions = action.payload
        },
        setEarningDetails: (state, action) => {
            state.earningDetails = action.payload
        },
        setDeductionDetails: (state, action) => {
            state.deductionDetails = action.payload
        },
        resetSalarySlice: () => initialState
    },
})

export const {
    setModalValues,
    setShowEarningModal,
    setShowDeductionModal,
    setIsEditEarningModal,
    setIsEditDeductionModal,
    setEarningTypesList,
    setTenantEarnings,
    setTenantDeductions,
    setEarningDetails,
    setDeductionDetails,
    resetSalarySlice,
 } = salarySlice.actions

export default salarySlice.reducer