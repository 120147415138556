import React, { useEffect, useState } from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { capitalizeFirstLetter } from "../../common/helper";

const animatedComponents = makeAnimated();

const LeaveTypeApplicableDetails = ({
  selectedApplicableOptions,
  setSelectedApplicableOptions,
  isUpdatingLeaveType,
  applicableDetails,
  activeItem,
}) => {
  const [availableOptions, setAvailableOptions] = useState({});

  // Initialize available options
  useEffect(() => {
    const initialOptions = {};
    applicableDetails.forEach(item => {
      initialOptions[item.name] = item.userFieldOptions.map(option => ({
        value: option.id,
        label: option.optionName,
      }));
    });
    setAvailableOptions(initialOptions);
  }, [applicableDetails]);

  // Update available options whenever selected options change
  useEffect(() => {
    const updatedOptions = {};
    applicableDetails.forEach(item => {
      const selectedValues =
        selectedApplicableOptions[item.name]?.selected.map(option => option.value) || [];
      updatedOptions[item.name] = item.userFieldOptions
        .filter(option => !selectedValues.includes(option.id))
        .map(option => ({
          value: option.id,
          label: option.optionName,
        }));
    });
    setAvailableOptions(updatedOptions);
  }, [selectedApplicableOptions, applicableDetails]);

  // Function to get default values for a specific field
  const getDefaultValues = name => {
    if (name === "Employment Type") {
      name = "employmentType";
    }
    const selectedOptions = selectedApplicableOptions[name]?.selected || [];
    const defaultValuesList = selectedOptions.map(option => ({
      label: option.name,
      value: option.optionId,
    }));
    return defaultValuesList;
  };

  const onSelectingEmploymentTabOption = (name, selected, userFieldId) => {
    // Initialize previous selected options
    let previousSelectedOptions = [];
    if (isUpdatingLeaveType) {
      previousSelectedOptions = selectedApplicableOptions["Employment Type"]?.selected || [];
    }

    // Transform the selected options, ensuring the label is set correctly
    const modifiedSelectedOptions =
      selected?.map(item => ({
        ...item,
        optionId: item.value,
        userFieldId: userFieldId,
        label: item.label || item.name,
        name: item.label || item.name,
      })) || [];

    // Combine previous and new selected options, prioritizing new selections
    let updatedSelectedOptions = [
      ...previousSelectedOptions.filter(
        prevItem => !modifiedSelectedOptions.some(item => item.value === prevItem.value)
      ),
      ...modifiedSelectedOptions,
    ];

    // Filter out deselected options (keep only those currently selected)
    updatedSelectedOptions = updatedSelectedOptions.filter(option =>
      selected.some(selectedOption => selectedOption.value === option.value)
    );

    // Use a Set to ensure unique selections
    const uniqueSelectedOptions = Array.from(
      new Set(updatedSelectedOptions.map(option => option.value))
    ).map(value => updatedSelectedOptions.find(option => option.value === value));

    // Update the state with the unique selected options
    setSelectedApplicableOptions(prevSAO => ({
      ...prevSAO,
      [capitalizeFirstLetter(name)]: { selected: uniqueSelectedOptions },
    }));
  };

  return (
    <section className="m-2 mb-12 mt-8 px-6 py-2 bg-white border">
      <div className="employment-type-container min-h-[2rem]">
        {applicableDetails.map(item => {
          const defaultValues = getDefaultValues(item.name);

          return (
            <div key={item.id} className="w-full flex my-5">
              <div className="w-[22%] flex justify-start items-center">
                <p>{item.name}</p>
              </div>
              <div className="w-[42%]">
                <Select
                  key={`${activeItem}-${item.id}`}
                  closeMenuOnSelect={false}
                  components={animatedComponents}
                  isMulti={true}
                  options={availableOptions[item.name] || []}
                  defaultValue={defaultValues}
                  id={item.id}
                  inputId={item.id}
                  value={selectedApplicableOptions[item.name]?.selected}
                  onChange={selected =>
                    onSelectingEmploymentTabOption(item.name, selected, item.id)
                  }
                />
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default LeaveTypeApplicableDetails;
