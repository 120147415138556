import { Textarea } from "flowbite-react";
import { useState, useEffect, useRef } from "react";
import {
  updateCustomFields,
  deleteCustomFields,
  addCustomField,
} from "../../../../services/InvoiceService";
import { getAllNotes } from "../../customFieldHelper";
import { RxCross2 } from "react-icons/rx";

let isInputChange = false;

const Note = ({ updateToggleState, setFormValues, Note, invoiceId }) => {
  const [isChange, setIsChange] = useState(false);
  const firstRender = useRef(false);

  useEffect(() => {
    //fetch all notes on UpdateCustomFields
    if (firstRender) getAllNotes(setFormValues, updateToggleState, invoiceId);
    firstRender.current = true;
  }, [isChange]);

  const onChangeInputHandler = e => {
    isInputChange = true;
    const updatedNote = {
      ...Note,
      fieldName: e.target.value,
    };
    setFormValues(prevFV => ({
      ...prevFV,
      Note: updatedNote,
    }));
  };

  const onBlurInputHandler = async e => {
    if (Note.fieldName.trim() === "" || !isInputChange) return;

    //checking for tenantId because, new field created by user does not have tenantId
    //until the newly created field send to the server on blur event
    if ("id" in Note && "tenantId" in Note) {
      //remove the loading spinner from the update request by sending seconding argument true for updating Notes
      await updateCustomFields(Note, true);
      setIsChange(prevIC => !prevIC);
    } else {
      const res = await addCustomField({ fieldName: Note.fieldName, groupId: Note.groupId });
      setFormValues(prevFV => ({ ...prevFV, Note: res.data }));
    }
    isInputChange = false;
  };

  const onClickDeleteHandler = async () => {
    //If editing invoice pass the note id and invoiceId
    if (Note?.invoiceId) await deleteCustomFields(Note.id, Note?.invoiceId);
    //If creating invoice pass the note id only and don't send the delete request if the fieldName is empty
    if (Note?.id && Note?.fieldName !== "") await deleteCustomFields(Note.id);

    //hide Notes
    updateToggleState("isNotesVisible", false);
    setFormValues(prevFV => ({ ...prevFV, Note: {} }));
    firstRender.current = false;
  };

  return (
    <section className="bg-[#f7faff] p-2 mb-5 flex flex-col justify-start">
      <div className="flex items-center justify-between w-[100%] md:!w-[52%]">
        <h6 className="font-medium mb-2 pd-1 text-gray-700 border-dotted border-b-2 border-gray-700">
          Notes
        </h6>
        <button
          className="bg-transparent hover:bg-gray-200 focus:bg-gray-200 p-1 rounded transition-colors w-fit outline-none"
          onClick={onClickDeleteHandler}>
          <RxCross2 size={25} color="#cdc733" />
        </button>
      </div>

      <Textarea
        onBlur={onBlurInputHandler}
        onChange={onChangeInputHandler}
        rows={8}
        cols={10}
        value={Note.fieldName || ""}
        className="!w-[100%] md:!w-[50%] border-b border-gray-400 focus:border focus:border-gray-900 hover:border-gray-900 focus:ring-0"
        name="Notes"
      />
    </section>
  );
};

export default Note;
