import { useState } from "react";
import { HiEye } from "react-icons/hi";
import { Alert, Accordion } from "flowbite-react";

const CsvRecordDetail = ({ record, color }) => {
  const [isRawRecordShown, setIsRawRecordShown] = useState(false);

  const toggleRawRecordHandler = () => {
    setIsRawRecordShown(!isRawRecordShown);
  };

  return (
    <Alert color={color} className="mb-4" withBorderAccent>
      <div className="flex items-center">
        <button
          onClick={toggleRawRecordHandler}
          type="button">
          <HiEye className="h-4 w-4" />
        </button>&nbsp;
        <span className="mr-2">{record?.message}</span>

      </div>
      {isRawRecordShown && (
        <div className="mb-4 mt-2 text-sm text-cyan-700" style={{ wordBreak: 'break-all' }}>
          {record?.rawRecord}
        </div>
      )}
    </Alert>
  );
};


const ImportSummaryReport = ({ importSummaryReport }) => {
  return (
    <Accordion alwaysOpen collapseAll={true}>
      
      <Accordion.Panel>
        <Accordion.Title>New Records: {importSummaryReport.newRecords.length}</Accordion.Title>
        <Accordion.Content>
          {importSummaryReport.newRecords.map((record, index) => (
            <CsvRecordDetail key={index} record={record} color="success" />
          ))}
        </Accordion.Content>
      </Accordion.Panel>
      <Accordion.Panel>
        <Accordion.Title>Updated Records: {importSummaryReport.updatedRecords.length}</Accordion.Title>
        <Accordion.Content>
          {importSummaryReport.updatedRecords.map((record, index) => (
            <CsvRecordDetail key={index} record={record} color="info"/>
          ))}
        </Accordion.Content>
      </Accordion.Panel>
      <Accordion.Panel>
        <Accordion.Title>Invalid Records: {importSummaryReport.invalidRecords.length}</Accordion.Title>
        <Accordion.Content>
          {importSummaryReport.invalidRecords.map((record, index) => (
            <CsvRecordDetail key={index} record={record} color="failure"/>
          ))}
        </Accordion.Content>
      </Accordion.Panel>
    </Accordion>

  );
};

export default ImportSummaryReport;