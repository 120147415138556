import React from 'react'
/**
 * Function to render an indicator element with or without a label.
 *
 * @param {Object} showWithLabel - boolean to determine if the indicator should be shown with a label
 * @param {string} parentClass - class for the parent element
 * @param {string} indicatorClass - class for the indicator element
 * @param {string} labelClass - class for the label element
 * @param {string} label - label text to be displayed
 * @return {JSX.Element} the rendered indicator element
 */
const Indicator = ({showWithLabel, parentClass, indicatorClass, labelClass, label}) => {
  return (
    <>
      {showWithLabel && (
        <div className={`flex items-center mr-4 ${parentClass}`}>
          <div className={`w-2 h-2 rounded-full ${indicatorClass} mr-2`}></div>
          <span className={labelClass}>{label}</span>
        </div>
      )}
      {!showWithLabel && <div className={`w-1.5 h-1.5 rounded-full ${indicatorClass}`}></div>}
    </>  
  );
}

export default Indicator