import { formattedDate } from '../../../utils/common';
import { Avatar } from 'flowbite-react';
import { LiaBirthdayCakeSolid } from "react-icons/lia";

const UpcomingBirthday = ({upcomingBirthdays}) => {

  return (
    <div>
      <div className="w-full h-96 bg-white rounded-lg relative">
        <div className="flex items-center justify-between p-5 pb-3">
          <h5 className="text-xl font-bold leading-none text-gray-700 dark:text-white">
            Birthdays
          </h5>
          <p>Next 30 days</p>
        </div>
        <div>
          <hr className="h-px my-2 bg-gray-200 border-0 dark:bg-gray-700"></hr>
        </div>
        {upcomingBirthdays.length === 0 && (
        <div className="flex flex-col items-center relative">
          <LiaBirthdayCakeSolid className="absolute top-10" size={"10rem"} color="#BFE2D2" />
          <p className="text-center absolute top-60 text-gray-500 dark:text-gray-400">No upcoming birthdays</p>
        </div>
      )}
      {upcomingBirthdays.length > 0 && (
        <div className="pl-3 pr-5 pb-5 flow-root scrollbar-hide" style={{ maxHeight: "calc(100% - 90px)" }}>
          <ul role="list" className=" divide-gray-200 dark:divide-gray-700">
            {upcomingBirthdays.map(birthday => {
            const isToday = formattedDate(birthday.date) === formattedDate(new Date());
            const textColor = isToday ? "text-green-400" : "text-gray-900";
            return (
            <li key={birthday.id} className="py-2 sm:py-2 relative">
                {isToday && (
                  <div className="absolute left-0 top-1/2 bottom-0 -translate-y-1/2 w-1 bg-green-400 rounded-sm"></div>
                )}
                <div className="flex items-center">
                  <div className="flex-shrink-0">
                      <Avatar
                      rounded={true}
                      placeholderInitials={`${birthday.firstName && birthday.firstName[0].toUpperCase()}${birthday.lastName && birthday.lastName[0].toUpperCase()}`}
                      className="mx-2 z-auto cursor-pointer"
                      img={birthday.image}
                    />
                  </div>
                  <div className="flex-1 min-w-0 ms-4">
                    <p className="text-sm font-medium text-gray-900 truncate dark:text-white">
                     {`${birthday.firstName} ${birthday.lastName}`}
                    </p>
                    <p className="text-sm text-gray-500 truncate dark:text-gray-400">
                      {birthday.email}
                    </p>
                  </div>
                  <div className={`inline-flex items-center text-base font-semibold ${textColor} dark:text-white pl-4`}>
                      {isToday ? "Today" : formattedDate(birthday.date)}
                  </div>
                </div>
                {/* {formattedDate(birthday.date) === formattedDate(new Date()) && (
                  <div className="absolute left-0 top-1/2 -translate-y-1/2 bottom-0 w-1 bg-green-400 rounded-sm"></div>
                )} */}
              </li>
            )}
            )}
          </ul>
        </div>
      )}
      </div>
    </div>
  );
}

export default UpcomingBirthday