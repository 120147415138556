export const FileTypes = {
  AdhaarCard: 1,
  PanCard: 2,
  Passport: 3,
  VoterIdCard: 4,
  DrivingLicense: 5,
  MatricMarkSheet: 6,
  InterMarksheet: 7,
  GraduationCertificate: 8,
  Resume: 9,
  Other: 10,
};

export const FileTypesLabel = {
  1: "Adhaar Card",
  2: "Pan Card",
  3: "Passport",
  4: "Voter Id Card",
  5: "Driving License",
  6: "Matric Mark Sheet",
  7: "Inter Marks Sheet",
  8: "Graduation Certificate",
  9: "CV/Resume",
  10: "Other",
};
