import { isEqual, parseISO, isBefore } from "date-fns";
import { NewAttendanceStatus } from "../enum/NewAttendanceStatus";
import Indicator from "../components/common/Indicator/Indicator";
/**
 * Generate attendance indicators based on events and a specific day.
 *
 * @param {Array} events - The list of events (here attendances list) to generate indicators from.
 * @param {Date} day - The specific day to generate indicators for.
 * @return {Array} The array of attendance indicators for the given day.
 */
export const attendanceIndicatorConditions = (events, day) => {
  return events && events.map(status => {
    if (isEqual(parseISO(status.date), day) && isBefore(parseISO(status.date), new Date())) {
      switch (status.attendanceStatus) {
        case NewAttendanceStatus.Present:
          return (
            <div key={status.date + "_present"} className="w-1 h-1 mx-auto">
              <Indicator indicatorClass="bg-green-400" />
            </div>
          );
        case NewAttendanceStatus.HalfDay:
          if (status.isFirstHalf) {
            return (
              <div key={status.date + "_holiday"} className="w-1 h-1 mx-auto">
                <Indicator indicatorClass="bg-gradient-to-r from-green-400 via-green-400 to-red-400" />
              </div>
            );
          } else if (status.isSecondHalf) {
            return (
              <div key={status.date + "_holiday"} className="w-1 h-1 mx-auto">
                <Indicator indicatorClass="bg-gradient-to-r from-red-400 via-red-400 to-green-400" />
              </div>
            );
          }
          break;
        case NewAttendanceStatus.Absent:
          return (
            <div key={status.date + "_absent"} className="w-1 h-1 mx-auto">
              <Indicator indicatorClass="bg-red-500" />
            </div>
          );
        default:
          if (status.isHoliday && !status.isWeekend) {
            return (
              <div key={status.date + "_holiday"} className="w-1 h-1 mx-auto">
                <Indicator indicatorClass="bg-yellow-400" />
              </div>
            );
          }
          break;
      }
    }
    return null;
  });
};

/**
 * Check if any events match the given day and return an indicator for holiday if so.
 *
 * @param {array} events - The list of events (here holidays list) to check
 * @param {Date} day - The day to check for events
 * @return {JSX.Element|null} - The indicator component or null
 */
export const holidayIndicatorConditions = (events, day) => {
  return events && events.some(item => isEqual(parseISO(item.date), day)) ? (
    <div className="w-1 h-1 mx-auto">
      <Indicator indicatorClass="bg-green-400" />
    </div>
  ) : null
}