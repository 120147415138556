import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-hot-toast";

import ModalComponent from "../../common/Modal/Modal";
import UploadDocs from "./UploadDocs";
import SkillsComponent from "./SkillsComponent";

import { VIEW_DETAILS_TABS_ENUM } from "../../../constants/Constant";
import { addFile } from "../../../services/FileUploadService";
import { addSkill, resetEmploymentStates } from "../../../store/slices/employment";
import ModalButtons from "../../common/Modal/ModalButtons";

/**
 * Renders the ViewDetailsModals component.
 *
 * @param {Function} toggleOpenModal - A function to toggle the open modal state.
 * @param {Object} modals - An object containing the modal state.
 * @param {Object} user - An object representing the user.
 * @return {JSX.Element} The rendered ViewDetailsModals component.
 */
const ViewDetailsModals = ({ toggleOpenModal, modals, user }) => {
  const { imageDetails, docType } = useSelector(state => state.employment.user);
  const dispatch = useDispatch();

  const onClickUploadDocHandler = async () => {
    const fileUploadObj = {
      userId: Number(user.id),
      fileType: docType.otherType ? docType.otherType : docType.type,
      file: imageDetails.file,
    };
    const res = await addFile(fileUploadObj);

    if (res.statusCode === 200) {
      toast.success("File uploaded successfully");
      toggleOpenModal(VIEW_DETAILS_TABS_ENUM.uploadedDocs, false);
      dispatch(resetEmploymentStates());
    }
    if (res.statusCode === 403) {
      toast.error(res.message);
    }
  };

  const onClickAddSkillHandler = data => {
    dispatch(addSkill(data));
    toggleOpenModal(VIEW_DETAILS_TABS_ENUM.skill, false);
  };

  const onCloseDocHandler = () => {
    dispatch(resetEmploymentStates());
    toggleOpenModal(VIEW_DETAILS_TABS_ENUM.uploadedDocs, false);
  };

  const onCloseSkillHandler = () => {
    toggleOpenModal(VIEW_DETAILS_TABS_ENUM.skill, false);
  };

  return (
    <>
      <ModalComponent
        show={modals.skills}
        onClose={toggleOpenModal.bind(null, VIEW_DETAILS_TABS_ENUM.skill, false)}
        bodyClassName="px-9 mt-4 pb-8"
        showFooter={false}
        heading="Add Skill">
        <SkillsComponent
          onCancel={onCloseSkillHandler}
          onSubmit={onClickAddSkillHandler}
          user={user}
        />
      </ModalComponent>

      <ModalComponent
        size="2xl"
        show={modals.uploadedDocuments}
        onClose={onCloseDocHandler}
        bodyClassName="px-8 mt-4"
        showFooter={false}
        heading={"Add Document"}>
        <UploadDocs />

        <ModalButtons
          isActionDeleteBtn={false}
          cancelBtnText="Cancel"
          actionBtnText="Add Document"
          onClickCancel={onCloseDocHandler}
          onClickAction={onClickUploadDocHandler}
        />
      </ModalComponent>
    </>
  );
};

export default ViewDetailsModals;
