import React from "react";
import DeleteModal from "../../common/Modal/DeleteModal";
import { deleteProjectById } from "../../../services/ProjectService";
import { showToast } from "../../common/Toast";
import { STATUS_CODES } from "../../../constants/Constant";
import { PROJECT_MESSAGES } from "../../../constants/Messages";

/**
 * Delete a project and handle the response accordingly.
 *
 * @param {Object} toggleProjectDetails - function to toggle project details
 * @param {Function} resetState - function to reset state
 * @param {string} deleteId - ID of the project to be deleted
 * @return {void} No return value
 */

const DeleteProject = ({ toggleProjectDetails, resetState, deleteId }) => {
  const onDeleteProjectDetails = async () => {
    const response = await deleteProjectById(deleteId);
    if (response.data.statusCode === STATUS_CODES.SUCCESS) {
      showToast("success", PROJECT_MESSAGES.DELETE);
      resetState();
    } else {
      showToast("error", "Error");
    }
  };

  const modalConfigs = {
    show: toggleProjectDetails.isDeleteProjectDetails,
    size: "md",
    onClose: resetState,
    showFooter: false,
    bodyClassName: "gap-4 mt-2",
  };

  return (
    <div>
      <DeleteModal
        modalConfigs={modalConfigs}
        onDelete={onDeleteProjectDetails}
        onCancel={resetState}
        showIcon={true}
      />
    </div>
  );
};

export default DeleteProject;
