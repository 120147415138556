import { memo } from "react";

/**
 *
 * @returns PaySlip not found markup
 */
const NotFound = memo(() => (
  <div className=" w-4/5 m-auto ">
    <section className="mb-10 dark:bg-gray-900">
      <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
        <div className="mx-auto max-w-screen-sm text-center">
          <div className="flex flex-col sm:flex-row items-center justify-center gap-4">
            <h1 className="mb-4 p-0 text-3xl tracking-tight font-extrabold lg:text-5xl text-thynkwebPrimary-600 dark:text-thynkwebPrimary-500">
              404
            </h1>
            <p className="mb-4 text-3xl tracking-tight font-bold text-gray-900 md:text-4xl dark:text-white ">
              Payslip Not Found.
            </p>
          </div>
          <p className="mb-4 text-lg font-semibold text-gray-500 dark:text-gray-400">
            Sorry, payslip for selected month is not found. Please select another month and try
            again.
          </p>
        </div>
      </div>
    </section>
  </div>
));

export default NotFound;
