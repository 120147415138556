import { Dropdown } from "flowbite-react";
import { Link } from "react-router-dom";

import { DeleteInvoices } from "./../DeleteInvoice";
import { downloadInvoice } from "../../../services/InvoiceService";

const InvoicesList = ({ invoices, isChange, setIsChange }) => {
  const downloadInvoiceById = (invoiceNumber, invoiceId, businessName, clientName) => {
    downloadInvoice(invoiceNumber, invoiceId, businessName, clientName);
  };

  return (
    <>
      {invoices?.map(invoice => {
        return (
          <tr
            className="border-b dark:border-gray-600 hover:bg-gray-100 dark:hover:bg-gray-700"
            key={invoice?.id}>
            <th
              scope="row"
              className="px-6 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white">
              <div className="flex items-center">{invoice?.invoiceNumber}</div>
            </th>
            <td className="px-6 py-2">
              <div className="inline-flex items-center bg-thynkwebPrimary-50 text-thynkwebPrimary-900 text-xs font-medium px-2 py-0.5 rounded dark:bg-thynkwebPrimary-900 dark:text-thynkwebPrimary-300">
                {(invoice.invoiceDate && invoice.invoiceDate).split("T")[0]}
              </div>
            </td>
            <td className="px-6 py-2">
              <div className="inline-flex items-center bg-thynkwebPrimary-50 text-thynkwebPrimary-900 text-xs font-medium px-2 py-0.5 rounded dark:bg-thynkwebPrimary-900 dark:text-thynkwebPrimary-300">
                {(invoice.dueDate && invoice.dueDate).split("T")[0]}
              </div>
            </td>
            <td className="px-6 py-2 whitespace-nowrap">{invoice?.billedByBusiness?.name}</td>
            <td className="px-6 py-2 whitespace-nowrap">{invoice?.billedToBusiness?.name}</td>
            <td className="px-6 py-2 whitespace-nowrap">{Intl.NumberFormat("en-US", {maximumFractionDigits: 2}).format(invoice?.totalAmount)}</td>
            <td className="px-4 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white action-toggle-btn">
              <Dropdown
                label={"•••"}
                inline={true}
                size="sm"
                placement="left-start">
                <Dropdown.Item>
                  <Link to={`/invoices/${invoice.id}`}>
                    <div className="px-6 py-2">Update</div>
                  </Link>
                </Dropdown.Item>
                <Dropdown.Item>
                  <div
                    className="px-6 py-2"
                    onClick={() =>
                      downloadInvoiceById(
                        invoice.id,
                        invoice.billedByBusiness?.name,
                        invoice.billedToBusiness?.name,
                        invoice.invoiceNumber
                      )
                    }>
                    Download
                  </div>
                </Dropdown.Item>
                <Dropdown.Item>
                  <DeleteInvoices
                    key={invoice?.id}
                    id={invoice?.id}
                    setIsChange={setIsChange}
                    isChange={isChange}
                    invoiceNo={invoice?.invoiceNumber}
                  />
                </Dropdown.Item>
              </Dropdown>
            </td>
          </tr>
        );
      })}
    </>
  );
};

export default InvoicesList;
