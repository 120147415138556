import moment from "moment";

import FormContainer from "./FormContainer";
import InputBoxContainer from "./InputBoxContainer";
import Label from "../../common/Label";
import FormInput from "../../common/FormInput";

const TransportDetails = ({ shippingDetails, onShippingDetailsChange }) => {
  return (
    <div className="md:flex md:justify-between w-full mx-auto mt-2">
      <FormContainer className="!w-full">
        <p className="text-2xl underline decoration-dotted decoration-3 underline-offset-8">
          Transport Details
        </p>
        <InputBoxContainer>
          <div className="flex flex-col gap-4">
            <span className="flex justify-between items-center">
              <Label
                value={"Challan Number"}
                htmlFor="challan-number"
              />
              <FormInput
                id="challan-number"
                className="w-[70%] p-2 outline-none border-t-0 border-l-0 border-r-0 border-b border-b-gray-400 focus:border-b focus:border-b-gray-900 hover:border-b-gray-900 hover:outline-0 focus:outline-0 "
                name="challanNumber"
                value={shippingDetails.challanNumber}
                onChange={onShippingDetailsChange}
                placeholder={"Challan Number (optional)"}
              />
            </span>
            <span className="flex justify-between items-center">
              <Label
                value={"Challan Date"}
                htmlFor="challan-date"
              />
              <span className="border-b border-b-gray-400 focus:border-b-gray-500 hover:border-b-gray-500 w-[70%]">
                <FormInput
                  id="challan-date"
                  className="w-full border-none p-2 outline-none focus:ring-0 border-b border-b-gray-400 focus:border-b focus:border-b-gray-900 hover:border-b-gray-900"
                  name="challanDate"
                  type={"date"}
                  value={
                    shippingDetails.challanDate
                      ? moment(`${shippingDetails.challanDate}Z`)
                          .format("YYYY MM DD")
                          .split(" ")
                          .join("-")
                      : "" //if challanDate are not defined add this as a default value
                  }
                  onChange={onShippingDetailsChange}
                  placeholder={"Challan Date (optional)"}
                />
              </span>
            </span>
            <span className="flex justify-between items-center">
              <Label
                value={"Transport"}
                htmlFor="transport"
              />
              <FormInput
                name="transportName"
                className="w-[70%] p-2 border-b !border-b-gray-400 focus:!border-b-gray-900 hover:!border-b-gray-900 "
                value={shippingDetails.transportName || ""}
                onChange={onShippingDetailsChange}
                placeholder={"Transport Name (optional)"}
              />
            </span>
            <span className="flex justify-between">
              <Label
                value={"Extra Information"}
                htmlFor="transporterExtraInformation"
              />
              <textarea
                className="w-[70%] outline-none border-t-0 border-l-0 border-r-0 border-b focus:ring-0 border-b-gray-400 focus:border-b focus:border-b-gray-900 hover:border-b-gray-900 hover:outline-0 focus:outline-0 "
                name="transportNote"
                placeholder="Transporter notes"
                value={shippingDetails.transportNote || ""}
                onChange={onShippingDetailsChange}
                rows="2"></textarea>
            </span>
          </div>
        </InputBoxContainer>
      </FormContainer>
    </div>
  );
};

export default TransportDetails;
