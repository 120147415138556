export const steps = [
  { color: "blue", id: 0 },
  { color: "gray", id: 1 },
  { color: "gray", id: 2 },
];

export const fields = [
  {
    id: "tntName",
    label: "Organization name",
    placeholder: "Enter your organization name",
    type: "text",
    required: true,
    value: "",
  },
  {
    id: "tntAddress",
    label: "Address",
    placeholder: "Enter your organization address",
    type: "text",
    required: true,
    value: "",
  },
  {
    id: "tntPan",
    label: "PAN (Permanent Account Number)",
    placeholder: "Enter your organization PAN number",
    type: "text",
    required: true,
    value: "",
  },
  {
    id: "tntTan",
    label: "TAN (Tax Deduction and Collection Account Number)",
    placeholder: "Enter your organization TAN number",
    type: "text",
    required: false,
    value: "",
  },
  {
    id: "tntGst",
    label: "GST number",
    placeholder: "Enter your organization GST number",
    type: "text",
    required: false,
    value: "",
  },
];

export const getLast12Months = () => {
  var last12Months = [];
  const months = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];
  for(var i = 1; i <= 12; i++) {
    var today = new Date();
    var date = new Date(today.setMonth(today.getMonth() - i));

    last12Months.push({
      year: date.getFullYear(),
      month: date.getMonth() + 1,
      label: `${months[date.getMonth()]} ${date.getFullYear()}`,
      className: "-pl-8 w-[180px]",
      dotClassName: "",
      backgroundColor: "bg-gray-100 rounded-md h-[2.5rem]",
      itemClassName: "w-[14.2rem]",
    });
  }
  return last12Months;
}

export const ToolTipContent = {
  payPeriod:
    "A pay period refers to the specific timeframe <br /> during which an employer calculates and disburses <br /> wages   or salaries to their employees.",
  lastPayRollRun:
    "Last pay run refers to the most recent or latest <br /> payroll that ran within an organization.",
};
