import React, { useState } from "react";
import { days, months, tw_styles } from "../helper";
import { RxCrossCircled } from "react-icons/rx";
import {
  AccrualIntervalDetails,
  CarryForwardTypeDetails,
  ResetIntervalDetails,
} from "../../../enum/LeaveTypePolicy";
import MandatorySign from "../../common/Input/MandatorySign";
import { FiInfo } from "react-icons/fi";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { PolicyEffectiveAfterDateTypeLabels } from "../../../enum/PolicyEffectiveAfterDateType";
import { Dropdown } from "primereact/dropdown";
import { IoMdArrowDropleft } from "react-icons/io";

const shadowStyle = {
  boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px",
};
const LeaveTypeEntitlementDetails = ({
  entitlementCardDetailsObject,
  entitlementCardDetailsIndex,
  setEntitlementCardDetails,
  onDeleteEntitlementCard,
}) => {
  const individualEntitlementCardDetails = entitlementCardDetailsObject;

  //function for toggling and storing accrual details
  const storeAccrualDetails = (name, value, checked, id, e) => {
    if (name === "accrualCheckBox") {
      setEntitlementCardDetails(prevECD => {
        return prevECD.map((item, index) => {
          if (index === entitlementCardDetailsIndex) {
            return {
              ...item,
              toggleDetails: {
                ...item.toggleDetails,
                accrualCheckBox: {
                  ...item.toggleDetails.accrualCheckBox,
                  isChecked: checked,
                },
              },
            };
          } else {
            return {
              ...item,
            };
          }
        });
      });
      return;
    }
    if (name === "accrualInterval") {
      setEntitlementCardDetails(prevECD => {
        return prevECD.map((item, index) => {
          if (index === entitlementCardDetailsIndex) {
            return {
              ...item,
              toggleDetails: {
                ...item.toggleDetails,
                accrualCheckBox: {
                  ...item.toggleDetails.accrualCheckBox,
                  isMonthlySelected: +value === 2 ? true : false,
                },
              },
            };
          } else {
            return {
              ...item,
            };
          }
        });
      });
    }

    setEntitlementCardDetails(prevECD => {
      return prevECD.map((item, index) => {
        if (index === entitlementCardDetailsIndex) {
          return {
            ...item,
            formDetails: {
              ...item.formDetails,
              accrualCheckBox: {
                ...item.formDetails.accrualCheckBox,
                [name]: +value,
              },
            },
          };
        } else {
          return {
            ...item,
          };
        }
      });
    });
  };

  //function for toggling and storing reset details
  const storeResetDetails = (name, value, checked, id, e) => {
    if (name === "resetCheckBox") {
      setEntitlementCardDetails(prevECD => {
        return prevECD.map((item, index) => {
          if (index === entitlementCardDetailsIndex) {
            return {
              ...item,
              toggleDetails: {
                ...item.toggleDetails,
                resetCheckBox: {
                  ...item.toggleDetails.resetCheckBox,
                  isChecked: checked,
                },
              },
            };
          } else {
            return {
              ...item,
            };
          }
        });
      });
      return;
    }

    if (name === "resetInterval") {
      setEntitlementCardDetails(prevECD => {
        return prevECD.map((item, index) => {
          if (index === entitlementCardDetailsIndex) {
            return {
              ...item,
              toggleDetails: {
                ...item.toggleDetails,
                resetCheckBox: {
                  ...item.toggleDetails.resetCheckBox,
                  isMonthlySelected: +value === 2 ? true : false,
                },
              },
            };
          } else {
            return {
              ...item,
            };
          }
        });
      });
    }

    setEntitlementCardDetails(prevECD => {
      return prevECD.map((item, index) => {
        if (index === entitlementCardDetailsIndex) {
          return {
            ...item,
            formDetails: {
              ...item.formDetails,
              resetCheckBox: {
                ...item.formDetails.resetCheckBox,
                [name]: +value,
              },
            },
          };
        } else {
          return {
            ...item,
          };
        }
      });
    });
  };

  //function to store other fields in entitlement card
  const storeOtherFields = (name, value, checked, id, e) => {
    setEntitlementCardDetails(prevECD => {
      return prevECD.map((item, index) => {
        if (index === entitlementCardDetailsIndex) {
          return {
            ...item,
            formDetails: {
              ...item.formDetails,
              others: {
                ...item.formDetails.others,
                [name]:
                  name === "isProrateAccrualEnabled"
                    ? !prevECD[entitlementCardDetailsIndex].formDetails.others
                        .isProrateAccrualEnabled
                    : +value,
              },
            },
          };
        } else {
          return {
            ...item,
          };
        }
      });
    });
  };

  //function to get the form values
  const onChangeEntitleMentDetails = e => {
    const { value, checked, name } = e.target;
    let lowerCaseName = name.toLowerCase();
    if (
      (lowerCaseName.includes("accrual") || name === "entitlement") &&
      name !== "isProrateAccrualEnabled"
    ) {
      storeAccrualDetails(name, value, checked, individualEntitlementCardDetails?.id, e);
    }
    if (
      lowerCaseName.includes("reset") ||
      name === "carryForwardVal" ||
      name === "carryForwardType"
    ) {
      storeResetDetails(name, value, checked, individualEntitlementCardDetails?.id, e);
    }
    if (
      name === "effectiveAfter" ||
      name === "effectiveAfterUnit" ||
      name === "isProrateAccrualEnabled"
    ) {
      storeOtherFields(name, value, checked, individualEntitlementCardDetails?.id, e);
    }
  };

  const handleEffectiveAfterChange = (e) => {
    storeOtherFields("effectiveAfterDateType", e.target.value, false, individualEntitlementCardDetails?.id, e)
  }

  return (
    <section
      className="entitlement-container mx-1 mt-1 mb-0 bg-white relative p-4 rounded-lg mb-5 shadow-lg border"
      data-obj={JSON.stringify(individualEntitlementCardDetails)}>
      {/* cross icon */}
      {entitlementCardDetailsIndex !== 0 && (
        <div
          className=" absolute top-3 right-4 cursor-pointer"
          onClick={() => onDeleteEntitlementCard(individualEntitlementCardDetails?.uid)}>
          <RxCrossCircled size={24} className="text-red-700 hover:!text-red-600" />
        </div>
      )}

      {/* first */}
      <div className="entitlement-effective-container px-4 flex min-h-[2.2rem]">
        <div className="flex justify-start items-center relative gap-3 text-sm w-[40%]">
          <p className="m-0 p-0">Policy Effective After</p>
          <div className="flex justify-center items-center gap-3">
            <FiInfo
              size={18}
              color="#c0c1c4"
              className="cursor-pointer"
              data-tooltip-id="effective-after-input"
              data-tooltip-html="<div>The value of Effective after field must be unique </br>for each policy!"
              data-tooltip-place="right-start"
            />
            <ReactTooltip id="effective-after-input" />
          </div>
        </div>
        <div className="flex gap-3 w-[60%]">
          <div className="flex">
            <span className="tc-input">
              <input
                type="number"
                name="effectiveAfter"
                value={individualEntitlementCardDetails?.formDetails?.others?.effectiveAfter}
                className={`w-[4rem] h-full pt-0 pb-0 mt-0 mb-0 !bg-red-50 !rounded-r-none focus:border-white ${tw_styles}`}
                onChange={onChangeEntitleMentDetails}
              />
            </span>
            <span className="tc-input">
              <select
                name="effectiveAfterUnit"
                id=""
                className={`w-full h-full pt-0 pb-0 mt-0 mb-0 cursor-pointer !bg-red-50 rounded-l-none ${tw_styles}`}
                onChange={onChangeEntitleMentDetails}
                value={individualEntitlementCardDetails?.formDetails?.others?.effectiveAfterUnit}>
                <option value={3}>Year(s)</option>
                <option value={2}>Months(s)</option>
                <option value={1}>Day(s)</option>
              </select>
            </span>
          </div>
          <p className="flex items-center">from</p>
          <div className="flex items-center">
            <div className="">
              <span className="tc-input">
                <select
                  name="effectiveAfterUnit"
                  id=""
                  className={`w-full h-full pt-0 pb-0 mt-0 mb-0 cursor-pointer !bg-red-50 rounded-l-none ${tw_styles}`}
                  onChange={handleEffectiveAfterChange}
                  value={individualEntitlementCardDetails?.formDetails?.others?.effectiveAfterDateType}>
                  <option value={0}>Date of Joining</option>
                  <option value={1}>Date of Confirmation</option>
                </select>
              </span>
              {/* <div className="card flex justify-content-center">
                <Dropdown
                  value={individualEntitlementCardDetails.formDetails.others.effectiveAfterDateType}
                  onChange={handleEffectiveAfterChange}
                  options={policyEffectiveAfterOptions}
                  // disabled={salary?.modalValues.isEditEarningModal}
                  optionLabel="name"
                  placeholder="Select type"
                  filter
                  className=" md:w-14rem w-[45%] h-10 border"
                />
                <div className=" flex justify-end items-center  ">
                  <IoMdArrowDropleft size={28} className="-mr-[0.7rem]" color="#eef7ff" />
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      {/* 2nd */}
      <div className={`entitlement-accrual-container mt-4 min-h-[2.2rem] items-center`}>
        <div className="flex items-center mb-2 bg-gray-100 px-4 py-3 rounded-lg">
          <div className="flex items-center w-full h-full gap-2">
            <input
              onChange={onChangeEntitleMentDetails}
              checked={individualEntitlementCardDetails?.toggleDetails?.accrualCheckBox?.isChecked}
              id="default-checkbox"
              type="checkbox"
              value="accrualCheckBox"
              name="accrualCheckBox"
              className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600 "
            />
            <label
              htmlFor="default-checkbox"
              className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Accrual
              <p className="text-xs text-gray-600 font-normal">
                Accrued leave is like a piggy bank for time off, where an employee earns paid time
                off bit by bit as they work.
              </p>
            </label>
          </div>
        </div>
        {individualEntitlementCardDetails?.toggleDetails?.accrualCheckBox?.isChecked && (
          <>
            <div className="px-4 py-2 rounded-lg text-sm flex items-center">
              <p className="w-[40%]">How often you want to accrue leaves to an employee?</p>
              <div className="flex gap-4 min-h-[2.2rem] items-center w-[60%]">
                <div className="tc-input">
                  <select
                    name="accrualInterval"
                    id=""
                    className={`w-full h-full pt-0 pb-0 mt-0 mb-0 ${tw_styles}`}
                    onChange={onChangeEntitleMentDetails}
                    value={
                      individualEntitlementCardDetails?.formDetails?.accrualCheckBox
                        ?.accrualInterval
                    }>
                    <option value={AccrualIntervalDetails.ONE_TIME}>One Time</option>
                    <option value={AccrualIntervalDetails.YEARLY}>Yearly</option>
                    <option value={AccrualIntervalDetails.MONTHLY}>Monthly</option>
                  </select>
                </div>
                <p className="flex justify-start items-center">on</p>
                <div className="w-auto tc-input">
                  <select
                    onChange={onChangeEntitleMentDetails}
                    value={
                      individualEntitlementCardDetails?.formDetails?.accrualCheckBox?.accrualDay
                    }
                    name="accrualDay"
                    id=""
                    className={`w-full h-full pt-0 pb-0 mt-0 mb-0 ${tw_styles}`}>
                    {days.map((day, index) => {
                      return (
                        <option key={index} value={day.value}>
                          {day.label}
                        </option>
                      );
                    })}
                  </select>
                </div>
                {!individualEntitlementCardDetails?.toggleDetails?.accrualCheckBox
                  ?.isMonthlySelected ? (
                  <div className=" w-[19%] tc-input">
                    <select
                      onChange={onChangeEntitleMentDetails}
                      value={
                        individualEntitlementCardDetails?.formDetails?.accrualCheckBox?.accrualMonth
                      }
                      name="accrualMonth"
                      id=""
                      className={`w-full h-full pt-0 pb-0 mt-0 mb-0 ${tw_styles}`}>
                      {months.map((month, index) => {
                        return (
                          <option key={index} value={month.value}>
                            {month.label}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="px-4 py-2 rounded-lg text-sm flex items-center">
              <div className="flex min-h-[2.2rem] w-[100%]">
                <p className="m-0 p-0 flex justify-start items-center w-[40%]">
                  No. of days to add
                </p>
                <div className="tc-input w-[60%] flex items-center gap-3">
                  <input
                    onChange={onChangeEntitleMentDetails}
                    value={
                      individualEntitlementCardDetails?.formDetails?.accrualCheckBox?.entitlement
                    }
                    type="number"
                    name="entitlement"
                    className={`h-full pt-0 pb-0 mt-0 mb-0 w-[5rem] ${tw_styles}`}
                  />
                  <p>days</p>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      {/* 3rd */}
      <div className="entitlement-reset-container mt-3">
        <div className="flex items-center mb-2 bg-gray-100 px-4 py-3 rounded-lg">
          <div className="flex w-full h-full gap-2 items-center">
            <input
              onChange={onChangeEntitleMentDetails}
              checked={individualEntitlementCardDetails?.toggleDetails?.resetCheckBox?.isChecked}
              id="default-checkbox"
              type="checkbox"
              value="resetCheckBox"
              name="resetCheckBox"
              className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
            />
            <label
              htmlFor="default-checkbox"
              className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Reset
              <p className="text-xs text-gray-600 font-normal">
                Resetting leaves refer to the deadline when to carry over unused leave days.
              </p>
            </label>
          </div>
        </div>
        {individualEntitlementCardDetails?.toggleDetails?.resetCheckBox?.isChecked && (
          <>
            <div className="px-4 py-2 rounded-lg text-sm flex items-center">
              <p className="w-[40%]">When do you want to reset the leaves?</p>
              <div className="w-[60%] ">
                <div className=" flex gap-4 min-h-[2.2rem] items-center">
                  <div className="tc-input">
                    <select
                      name="resetInterval"
                      value={
                        individualEntitlementCardDetails?.formDetails?.resetCheckBox?.resetInterval
                      }
                      id=""
                      className={`w-full h-full pt-0 pb-0 mt-0 mb-0 ${tw_styles}`}
                      onChange={onChangeEntitleMentDetails}>
                      <option value={ResetIntervalDetails.ONE_TIME}>One Time</option>
                      <option value={ResetIntervalDetails.YEARLY}>Yearly</option>
                      <option value={ResetIntervalDetails.MONTHLY}>Monthly</option>
                    </select>
                  </div>
                  <p className="">on</p>
                  <div className="tc-input">
                    <select
                      name="resetDay"
                      value={individualEntitlementCardDetails?.formDetails?.resetCheckBox?.resetDay}
                      id=""
                      className={`w-full h-full pt-0 pb-0 mt-0 mb-0 ${tw_styles}`}
                      onChange={onChangeEntitleMentDetails}>
                      {days.map((day, index) => {
                        return (
                          <option key={index} value={day.value}>
                            {day.label}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  {!individualEntitlementCardDetails?.toggleDetails?.resetCheckBox
                    ?.isMonthlySelected ? (
                    <div className="tc-input">
                      <select
                        name="resetMonth"
                        value={
                          individualEntitlementCardDetails?.formDetails?.resetCheckBox?.resetMonth
                        }
                        id=""
                        className={`w-full h-full pt-0 pb-0 mt-0 mb-0 ${tw_styles}`}
                        onChange={onChangeEntitleMentDetails}>
                        {months.map((month, index) => {
                          return (
                            <option key={index} value={month.value}>
                              {month.label}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
            <div className="flex min-h-[2.2rem] px-4 py-2 rounded-lg text-sm">
              <div className="w-[40%] flex justify-start items-center">Carry forward</div>
              <div className="w-[60%] flex gap-4">
                <div className="tc-input">
                  <input
                    type="number"
                    name="carryForwardVal"
                    className={`h-full pt-0 pb-0 mt-0 mb-0 w-[5rem] ${tw_styles}`}
                    onChange={onChangeEntitleMentDetails}
                    value={
                      individualEntitlementCardDetails?.formDetails?.resetCheckBox?.carryForwardVal
                    }
                  />
                </div>
                <div className="tc-input">
                  <select
                    name="carryForwardType"
                    value={
                      individualEntitlementCardDetails?.formDetails?.resetCheckBox?.carryForwardType
                    }
                    id=""
                    className={`h-full pt-0 pb-0 mt-0 mb-0 ${tw_styles}`}
                    onChange={onChangeEntitleMentDetails}>
                    <option value={CarryForwardTypeDetails.UNIT}>Unit(s)</option>
                    <option value={CarryForwardTypeDetails.PERCENTAGE}>Percentage</option>
                  </select>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      {/* 4th */}
      <div className="entitlement-prorateAccrual-container mt-5 flex justify-start items-center text-sm">
        <label className="relative inline-flex items-center cursor-pointer ">
          <input
            name="isProrateAccrualEnabled"
            type="checkbox"
            checked={individualEntitlementCardDetails?.formDetails?.others?.isProrateAccrualEnabled}
            className="sr-only peer"
            onChange={onChangeEntitleMentDetails}
          />
          <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
        </label>
          <span className="ms-3 text-sm text-gray-900 dark:text-gray-300 ml-4">
            <p>Prorate Accrual - Start and end of policy</p>
            <p className="text-xs">
              An employee accrues time for the fraction of time they work between scheduled accruals
              at the beginning and end of the policy
            </p>
          </span>
      </div>
    </section>
  );
};

export default LeaveTypeEntitlementDetails;
