import { Button } from "flowbite-react";
import React, { useEffect, useState } from "react";
import { btnPrimary } from "../../utils/helper";
import { addNewRole, getAllRoles, getSpecificRole, updateRole } from "../../services/RolesService";
import toast from "react-hot-toast";
import DeleteRole from "../../components/Control Panel/Roles/DeleteRole";
import { ROLES_MESSAGES } from "../../constants/Messages";
import { showToast } from "../../components/common/Toast";
import { STATUS_CODES } from "../../constants/Constant";
import AddRole from "../../components/Control Panel/Roles/AddRole";
import RolesList from "../../components/Control Panel/Roles/RolesList";

//initial object for role details
const roleInitialDetails = {
  name: "",
  description: "",
};

//toggle initial details
const initialToggleRoleDetails = {
  toggleRoleModal: false,
  isChange: false,
  isDisabled: true,
  isUpdatingRoleDetails: false,
  isDeleteRoleDetails: false,
};

const Roles = () => {
  //State for handling and storing toggle role details
  const [toggleRoleDetails, setToggleRoleDetails] = useState(initialToggleRoleDetails);
  //State for storing role details
  const [roleDetails, setRoleDetails] = useState([]);
  //State for getting form values
  const [roleFormDetails, setRoleFormDetails] = useState(roleInitialDetails);
  //State to store id for deleting an element
  const [deleteId, setDeleteId] = useState(0);

  //Fetching all roles
  useEffect(() => {
    getAllRolesDetails();
  }, [toggleRoleDetails.isChange]);

  //Function for getting all roles
  const getAllRolesDetails = async () => {
    getAllRoles().then(response => {
      setRoleDetails(prevRD => {
        return response;
      });
    });
  };

  //Function for resetting states, opening and closing the modal component
  const resetStates = () => {
    setToggleRoleDetails(prevTRD => {
      return {
        ...prevTRD,
        toggleRoleModal: false,
        isChange: !prevTRD.isChange,
        isDisabled: true,
        isUpdatingRoleDetails: false,
        isDeleteRoleDetails: false,
      };
    });
    setRoleFormDetails(roleInitialDetails);
  };

  //Function to open a modal for Adding new Roles
  const handleToggleAddNewRule = () => {
    setToggleRoleDetails(prevTRD => {
      return {
        ...prevTRD,
        toggleRoleModal: !prevTRD.toggleRoleModal,
      };
    });
  };

  //Function to get details for Update Roles
  const handleClickUpdateRole = async roleId => {
    setToggleRoleDetails(prevRD => {
      return {
        ...prevRD,
        toggleRoleModal: !prevRD.toggleRoleModal,
        isUpdatingRoleDetails: true,
      };
    });
    const response = await getSpecificRole(roleId);
    const { id, name, description } = response?.data;
    if (response?.statusCode === STATUS_CODES.SUCCESS) {
      setRoleFormDetails(prevRFD => {
        return {
          ...prevRFD,
          id: id,
          name: name,
          description: description,
        };
      });
    }
  };

  //Function to get form input values
  const onChangeRoleDetails = event => {
    const { name, value } = event.target;
    setRoleFormDetails(prevRFD => {
      return {
        ...prevRFD,
        [name]: value,
      };
    });
    setToggleRoleDetails(prevTRD => {
      return {
        ...prevTRD,
        isDisabled: !value.trim(),
      };
    });
  };

  //Function for submitting Add new Role details
  const onSubmitRoleDetails = async () => {
    if (roleFormDetails.name.trim() !== "" && roleFormDetails.description.trim() !== "") {
      const response = await addNewRole(roleFormDetails);
      if (response.data.statusCode === STATUS_CODES.SUCCESS) {
        showToast("success", ROLES_MESSAGES.SUCCESS);
        resetStates();
      } else {
        showToast("error", ROLES_MESSAGES.SUCCESS_FAILED);
      }
    } else {
      return toast.error("Please fill all the details !");
    }
  };

  //Function for submitting Edit or Update Role details
  const onSubmitUpdateRoleDetails = async () => {
    if (roleFormDetails.name === "" || roleFormDetails.description === "") {
      return toast.error("Please fill all the details !");
    }
    const response = await updateRole(roleFormDetails);
    if (response?.data?.statusCode === STATUS_CODES.SUCCESS) {
      showToast("success", ROLES_MESSAGES.UPDATE);
      resetStates();
    } else {
      showToast("error", ROLES_MESSAGES.UPDATE_FAILED);
    }
  };

  //Function for deleting role details
  const handleClickDeleteRole = id => {
    setToggleRoleDetails(prevTRD => {
      return {
        ...prevTRD,
        isDeleteRoleDetails: true,
      };
    });
    setDeleteId(prevDI => {
      return id;
    });
  };

  return (
    <>
      <div className="my-leaves-container min-h-[89.7vh] relative">
        <section className="py-3 sm:py-5 tc-page h-[calc(100vh-61px)]">
          <div className="px-4 mx-auto max-w-screen-4xl lg:px-12">
            <div className="flex flex-wrap items-center gap-1 justify-between">
              <div className="flex flex-wrap items-center gap-1">
                <h1 className="text-3xl text-gray-900 dark:text-white py-4 cursor-pointer">
                  Roles
                </h1>
              </div>
              {/* <div className="flex justify-center mr-2flex flex-wrap items-end wrap space-x-4">
                <Button onClick={handleToggleAddNewRule} className={`${btnPrimary()}`}>
                  Add User Role
                </Button>
              </div> */}
            </div>

            <div className="overflow-hidden bg-white shadow-md dark:bg-gray-800 sm:rounded-lg relative">
              <div className="overflow-x-auto">
                <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                  <RolesList
                    roleDetails={roleDetails}
                    handleClickDeleteRole={handleClickDeleteRole}
                    handleClickUpdateRole={handleClickUpdateRole}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      {/* AddRole component for Adding and Updating User Roles */}
      <AddRole
        toggleRoleDetails={toggleRoleDetails}
        resetStates={resetStates}
        roleFormDetails={roleFormDetails}
        onChangeRoleDetails={onChangeRoleDetails}
        onSubmitUpdateRoleDetails={onSubmitUpdateRoleDetails}
        onSubmitRoleDetails={onSubmitRoleDetails}
      />
      {/* DeleteRole component for Deleting User Roles */}
      <DeleteRole
        toggleRoleDetails={toggleRoleDetails}
        resetState={resetStates}
        deleteId={deleteId}
      />
    </>
  );
};

export default Roles;
