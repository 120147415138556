import { createSlice } from "@reduxjs/toolkit";
import { INITIAL_NOTIFICATION_COUNT, STATUS_CODES } from "../../constants/Constant";
import {
  getNotifications,
  markNotificationAsReadService,
} from "../../services/NotificationService";
import { getNotificationCount } from "./../../services/NotificationService";

let timeout;
const allNotifications = [];
const initialState = {
  allNotifications,
  notificationCount: INITIAL_NOTIFICATION_COUNT,
  totalRecords: 0,
};

const Notification = createSlice({
  name: "notification",
  initialState,
  reducers: {
    /**
     * Updates the state with all notifications and total records from the action payload.
     *
     * @param {Object} state - The current state of the application.
     * @param {Object} action - The action object containing the payload data.
     * @param {Array} action.payload.data - The array of all notifications.
     * @param {number} action.payload.records - The total number of records.
     */
    getAllNotifications(state, action) {
      state.allNotifications = action.payload.data;
      state.totalRecords = action.payload.totalRecords;
    },

    /**
     * Updates all notifications in the state by adding new notifications.
     *
     * @param {Object} state - The current state of the application.
     * @param {Array} action.payload - The array of new notifications to be added.
     * @return {undefined}
     */
    updateAllNotifications(state, action) {
      state.allNotifications = [...state.allNotifications, ...action.payload];
    },

    /**
     * Updates the state with the unread notification count.
     *
     * @param {Object} state - The current state.
     * @param {Object} action - The action containing the payload with the new notification count.
     * @return {void}
     */
    getUnreadNotificationCount(state, action) {
      state.notificationCount = action.payload;
    },

    /**
     * Marks the notification as read and updates the notification count.
     *
     * @param {Object} state - The state object representing the current application state.
     */
    notificationRead(state) {
      state.notificationCount = 0;
    },

    resetNotifications(state) {
      state.allNotifications = allNotifications;
      state.notificationCount = INITIAL_NOTIFICATION_COUNT;
      state.totalRecords = 0;
    },
  },
});

/**
 * Fetches notifications from the server and handles the response.
 *
 * @param {number} pageNo - The page number of notifications to fetch.
 * @return {Promise} A promise that resolves when the notifications are fetched and dispatched.
 */
const fetchNotifications = pageNo => async dispatch => {
  const res = await getNotifications(pageNo);

  if (res.statusCode === STATUS_CODES.SUCCESS) {
    dispatch(Notification.actions.getAllNotifications(res));
  }
};

/**
 * Marks a notification as read after a delay.
 *
 * @return {Promise<void>} A promise that resolves when the notification is marked as read.
 */
const markNotificationAsRead = notificationCount => async dispatch => {
  timeout && clearTimeout(timeout);
  timeout = setTimeout(async () => {
    if (notificationCount > 0) {
      const res = await markNotificationAsReadService();
      if (res.statusCode === STATUS_CODES.SUCCESS)
        dispatch(Notification.actions.notificationRead());
    }
  }, 900);
};

/**
 * Fetches the notification counts asynchronously and dispatches the result.
 *
 * @return {Promise<void>} Returns a promise that resolves when the dispatch is complete.
 */
const fetchNotificationCounts = () => async dispatch => {
  const res = await getNotificationCount();

  if (res.statusCode === STATUS_CODES.SUCCESS)
    dispatch(Notification.actions.getUnreadNotificationCount(res.data));
};

const updateNotificationOnScroll = pageNo => async dispatch => {
  const res = await getNotifications(pageNo);
  if (res.statusCode === STATUS_CODES.SUCCESS) {
    dispatch(Notification.actions.updateAllNotifications(res.data));
  }
};

export {
  fetchNotifications,
  fetchNotificationCounts,
  markNotificationAsRead,
  updateNotificationOnScroll,
};
export const { resetNotifications } = Notification.actions;
export default Notification.reducer;
