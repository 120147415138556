import React from "react";
import { MdCheck, MdOutlineEdit } from "react-icons/md";
import { RxCross2 } from "react-icons/rx";
import { RiDeleteBin6Line } from "react-icons/ri";
import DeleteModal from "../../../components/common/Modal/DeleteModal";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import {
  resetStatutoryEpfDetails,
  resetStatutorySlice,
  setStatutoryEpfDetails,
  setStatutoryToggleDetails,
} from "../../../store/slices/statutorySlice";
import {
  disableStatutoryComponent,
  getStatutoryComponent,
} from "../../../services/StatutoryService";
import { STATUS_CODES } from "../../../constants/Constant";
import { showToast } from "../../../components/common/Toast";
import { STATUTORY_MESSAGE } from "../../../constants/Messages";
import { StatutoryComponentType } from "../../../enum/StatutoryComponentType";

const EpfDetails = () => {
  const dispatch = useAppDispatch();
  const statutory = useAppSelector(state => state.statutory);
  const { statutoryToggleDetails, statutoryEpfDetails } = statutory;

  //Function to open the disable epf modal
  const onOpenDisableEpfModal = () => {
    dispatch(
      setStatutoryToggleDetails({
        ...statutoryToggleDetails,
        toggleEpfDeleteModal: true,
      })
    );
  };

  //Function to close the disable or delete epf modal
  const onCloseDisableEpfModal = () => {
    dispatch(
      setStatutoryToggleDetails({
        ...statutoryToggleDetails,
        toggleEpfDeleteModal: false,
      })
    );
  };

  const modalConfigs = {
    show: statutoryToggleDetails.toggleEpfDeleteModal,
    size: "2xl",
    showFooter: false,
    onClose: onCloseDisableEpfModal,
    bodyClassName: "gap-4 mt-2",
  };

  //Function to open the update epf modal
  const onOpenUpdateEpfModal = () => {
    dispatch(
      setStatutoryToggleDetails({
        ...statutoryToggleDetails,
        toggleUpdateStatutoryModal: true,
        isEpfEnabled: false,
      })
    );
  };

  //Function to delete or disable epf
  const onDeleteEpfComponentDetails = async () => {
    if (statutoryEpfDetails?.id) {
      const response = await disableStatutoryComponent(statutoryEpfDetails?.id);
      if (response?.data?.statusCode === STATUS_CODES.SUCCESS) {
        showToast("success", STATUTORY_MESSAGE.EPF_DELETE);
        dispatch(
          setStatutoryToggleDetails({
            ...statutoryToggleDetails,
            toggleEpfDeleteModal: false,
            isEpfEnabled: false,
          })
        );
        dispatch(resetStatutoryEpfDetails());
      }
    }
  };

  return (
    <>
      <div className=" min-h-[20rem]">
        {/* First heading container */}
        <div className="heading-container  mt-4 flex gap-2">
          <h1 className="text-xl font-medium">Employees' Provident Fund</h1>
          <button
            className="border w-8 h-8 rounded-[50%] flex justify-center items-center bg-gray-200 cursor-pointer"
            onClick={onOpenUpdateEpfModal}>
            <MdOutlineEdit size={23} />
          </button>
        </div>
        {/* 2nd epf number container */}
        <div className="epf-container mt-4  flex">
          <p className="epf-number-container  w-[35%]">EPF Number</p>
          <p className="epf-number-value ">{statutoryEpfDetails?.number ?? "-"}</p>
        </div>

        {/* 3rd deduction cycle container */}
        <div className="deduction-cycle-container mt-4 flex">
          <p className="epf-number-container  w-[35%]">Deduction Cycle</p>
          <p className="deduction-cycle-value ">Monthly</p>
        </div>

        {/* 4th Employee Contribution Rate container */}
        <div className="employee-contribution-rate-container mt-4  flex">
          <p className="employee-contribution-rate-container w-[35%]">Employee Contribution Rate</p>
          <p className="employee-contribution-rate-value ">
            {statutoryEpfDetails?.employeeContributionRate ?? 0}% of Actual PF Wage
          </p>
        </div>

        {/* 5th Employer Contribution Rate container */}
        <div className="employer-contribution-rate-container mt-4  flex">
          <p className="employer-contribution-rate-container  w-[35%]">
            Employer Contribution Rate
          </p>
          <p className="employer-contribution-rate-value ">
            {statutoryEpfDetails?.employerContributionRate ?? 0}% of Actual PF Wage
          </p>
        </div>

        {/* 6th ctc inclusions container */}
        <div className="ctc-inclusions-container mt-4  flex">
          <p className="ctc-inclusions-container w-[35%]">CTC Inclusions</p>
          <div className="ctc-inclusions-value ">
            <div className=" flex gap-2">
              {statutoryEpfDetails?.includeEmployersContributionInCtc && (
                <p className="flex justify-center items-center">
                  <MdCheck size={22} color="green" />
                </p>
              )}
              {!statutoryEpfDetails?.includeEmployersContributionInCtc && (
                <p className="flex justify-center items-center">
                  <RxCross2 size={22} color="#999999" />
                </p>
              )}
              <p>Employer's contribution is included in the CTC.</p>
            </div>
          </div>
        </div>

        {/* 8th Pro rata restricted container */}
        <div className="pro-rata-restricted-container mt-4 flex">
          <p className="pro-rata-restricted-container  w-[35%]">Pro-rate Restricted PF Wa...</p>
          <p className="pro-rata-restricted-value ">
            {statutoryEpfDetails?.proRataRestricted ? "Yes" : "No"}
          </p>
        </div>

        {/* 9th Consider applicable salary container */}
        <div className="consider-applicable-salary-container mt-4  flex">
          <p className="consider-applicable-salary-container  w-[35%]">
            Consider applicable salary components based on LOP
          </p>
          <p className="consider-applicable-salary-value ">
            {statutoryEpfDetails?.considerAllSalaryComponents ? "Yes" : "No"} (when PF wage is less
            than ₹15,000)
          </p>
        </div>

        {/* 10th Delete or Disable EPF container */}
        <div className="delete-or-disable-epf-container mt-4 ">
          <button
            className="cursor-pointer flex justify-center items-center text-[#aabee6] hover:text-[#5b7fc7]"
            onClick={onOpenDisableEpfModal}>
            <p className="flex justify-center items-center">
              <RiDeleteBin6Line size={20} />
            </p>
            <p className="flex justify-center items-center">Disable EPF</p>
          </button>
        </div>
      </div>
      <DeleteModal
        modalConfigs={modalConfigs}
        onDelete={onDeleteEpfComponentDetails}
        onCancel={onCloseDisableEpfModal}
        showIcon={true}
        message={
          "If your organisation has 20 or more employees, it is necessary that you register for the EPF scheme. Are you sure you want to disable EPF for this organisation?"
        }
      />
    </>
  );
};

export default EpfDetails;
