import React from "react";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import InputFields from "../../../components/common/Input/InputFields";
import { Button } from "flowbite-react";
import { btnPrimary } from "../../../utils/helper";
import {
  resetStatutoryProfessionalTaxDetails,
  setStatutoryProfessionalTaxDetails,
  setStatutoryToggleDetails,
} from "../../../store/slices/statutorySlice";
import { StatutoryComponentType } from "../../../enum/StatutoryComponentType";
import {
  updateStatutoryComponent,
} from "../../../services/StatutoryService";
import { STATUS_CODES } from "../../../constants/Constant";
import { showToast } from "../../../components/common/Toast";
import { STATUTORY_MESSAGE } from "../../../constants/Messages";

const ProfessionalTaxComponent = ({ onCloseProfessionalTaxModal }) => {
  const dispatch = useAppDispatch();
  const statutory = useAppSelector(state => state.statutory);

  const { statutoryToggleDetails, statutoryProfessionalTaxDetails } = statutory;

  //Function to get Professional tax form details
  const handleChangeProfessionalTaxDetails = event => {
    const { name, value } = event.target;
    dispatch(
      setStatutoryProfessionalTaxDetails({
        ...statutoryProfessionalTaxDetails,
        [name]: value,
      })
    );
  };

  //Function to submit professional tax details to server
  const handleSubmitProfessionalTaxDetails = async event => {
    const formattedProfessionalTaxDetails = {
      statutoryComponentType: StatutoryComponentType.ProfessionalTax,
      employeeContributionRate: +statutoryProfessionalTaxDetails.employeeContributionRate,
    };

    const response = await updateStatutoryComponent(formattedProfessionalTaxDetails);
    if (response?.data?.statusCode === STATUS_CODES.SUCCESS) {
      showToast("success", STATUTORY_MESSAGE.PROFESSIONAL_TAX_SUCCESS);
      dispatch(
        setStatutoryToggleDetails({
          ...statutoryToggleDetails,
          toggleProfessionalTaxModal: false,
          isProfessionalTaxEnabled: true,
        })
      );
      dispatch(resetStatutoryProfessionalTaxDetails());
    }
  };

  //Function to update professional tax details
  const handleUpdateProfessionalTaxDetails = async event => {
    const formattedProfessionalTaxDetails = {
      ...statutoryProfessionalTaxDetails,
      employeeContributionRate: +statutoryProfessionalTaxDetails.employeeContributionRate,
    };
    if (
      formattedProfessionalTaxDetails?.statutoryComponentType ===
      StatutoryComponentType.ProfessionalTax
    ) {
      const response = await updateStatutoryComponent(formattedProfessionalTaxDetails);
      if (response?.data?.statusCode === STATUS_CODES.SUCCESS) {
        showToast("success", STATUTORY_MESSAGE.PROFESSIONAL_TAX_SUCCESS);
        dispatch(
          setStatutoryToggleDetails({
            ...statutoryToggleDetails,
            toggleUpdateProfessionalTaxModal: false,
            isProfessionalTaxEnabled: true,
          })
        );
      }
    }
  };
  return (
    <div className="professional-tax-conatiner">
      <div className="min-h-8 w-[45%]">
        <p className="mb-2">Professional Tax Amount</p>
        <InputFields
          InputField={{
            type: "number",
            placeholder: "Amount",
            name: "employeeContributionRate",
          }}
          inputClassName="w-full"
          onChange={handleChangeProfessionalTaxDetails}
          value={statutoryProfessionalTaxDetails.employeeContributionRate}
        />
      </div>
      <hr className="h-px mt-6 bg-gray-200 border-0 dark:bg-gray-700" />
      <div className=" mt-3">
        <div className=" w-[100%]  flex gap-4">
          <Button
            type="submit"
            className={btnPrimary()}
            form="add-modal"
            onClick={
              statutoryToggleDetails.toggleUpdateProfessionalTaxModal
                ? handleUpdateProfessionalTaxDetails
                : handleSubmitProfessionalTaxDetails
            }>
            {statutoryToggleDetails.toggleUpdateProfessionalTaxModal ? "Save" : "Enable"}
          </Button>
          <Button
            type="submit"
            className={btnPrimary()}
            form="add-statutory-modal"
            onClick={() => onCloseProfessionalTaxModal()}>
            Cancel
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ProfessionalTaxComponent;
