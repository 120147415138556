import { handleAddNewInvoiceItemClick } from "../helper";

const AddNewItem = ({
  invoiceItems,
  setAllAmounts,
  setFormValues,
  setNewItemsCount,
  newItemsCount,
  isUpdating,
  paramsId,
}) => {
  return (
    <div className="mt-6 ">
      <button
        onClick={() =>
          handleAddNewInvoiceItemClick(
            invoiceItems,
            setAllAmounts,
            setFormValues,
            setNewItemsCount,
            newItemsCount,
            isUpdating,
            paramsId
          )
        }
        type="button"
        className="w-full border border-[#b4c2d5] bg-[#f7faff] text-xl py-2.5 px-5 mr-2 mb-2 font-semibold text-thynkwebPrimary-800 focus:outline-none rounded-lg hover:bg-[#ecf1fa]  focus:bg-[#ecf1fa] hover:text-thynkwebPrimary-900 focus:text-thynkwebPrimary-900  dark:bg-gray-600 dark:text-gray-600  dark:hover:text-white dark:hover:bg-gray-700">
        Add New Item
      </button>
    </div>
  );
};

export default AddNewItem;
