import { Sidebar, Button } from "flowbite-react";
import { activeStyle, inactiveStyle } from "../../EmployeeManagement/helper";
import { btnPrimary } from "../../../utils/helper";
import { toggleSubmitBtn } from "../../EmployeeManagement/helper";
import { memo } from "react";

const topCornersRadius = "!rounded-tr-[5px] !rounded-tl-[5px]";
const bottomCornersRadius = "!rounded-br-[5px] !rounded-bl-[5px]";

/**
 * Renders the Sidebar component.
 *
 * @param {{options: [{activeItem: string, name: string, id: string | number}], activeItem: string, onClickSideBarItem: function, handleSubmit: function, isEditing?: boolean, disabled?: boolean}} props
 * @param {[{activeItem: string, name: string, id: string | number}]} options - The options for the sidebar.
 * @param {string} activeItem - The active item in the sidebar.
 * @param {function} onClickSideBarItem - The function called when a sidebar item is clicked.
 * @param {function} handleSubmit - The function called when the submit button is clicked.
 * @param {boolean?} isEditing - Indicates whether the component is in edit mode or not.
 * @param {boolean?} disabled - disabled submit button
 * @return {JSX.Element} The rendered Invoice Sidebar component.
 */
const SidebarComponent = ({
  options,
  activeItem,
  onClickSideBarItem,
  handleSubmit,
  isEditing,
  disabled,
}) => {
  return (
    <Sidebar
      className="sm:text-base p-0 m-0 tc-modal-menu h-[calc(100vh-220px)] relative">
      <Sidebar.Items>
        <Sidebar.ItemGroup className="bg-gray-50 rounded-lg">
          {options.map((item, i) => (
            <Sidebar.Item
              key={item.id}
              className={`${i === 0 && topCornersRadius} ${
                i === options.length - 1 && bottomCornersRadius
              } -mt-[7px]`}
              onClick={() => onClickSideBarItem(item.activeItem)}
              style={activeItem === item.activeItem ? activeStyle : inactiveStyle}>
              <p>{item.name}</p>
            </Sidebar.Item>
          ))}
        </Sidebar.ItemGroup>
      </Sidebar.Items>

      <Sidebar.ItemGroup className="sticky bottom-0">
        <Sidebar.Items className="-mt-[7px]">
          {/* Button to save */}
          <Button
            type="submit"
            disabled={disabled}
            className={
              disabled
                ? toggleSubmitBtn("bg-gray-400", "hover:bg-gray-400 !py-1")
                : `${btnPrimary()} !py-1`
            }
            onClick={handleSubmit}>
            Save {isEditing ? "Changes" : "Details"}
          </Button>
        </Sidebar.Items>
      </Sidebar.ItemGroup>
    </Sidebar>
  );
};
const ModalSidebar = memo(SidebarComponent);
export default ModalSidebar;
