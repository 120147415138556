import { ThynkWebLogoSvg } from "../../media/ThynkWebLogoSvg";

export const Footer = () => (
  <footer className="p-4 bg-white md:p-8 lg:p-10 dark:bg-gray-800">
    <div className="mx-auto max-w-screen-xl text-center">
      <a
        href="/#"
        className="flex justify-center items-center text-2xl font-semibold w-fit mx-auto text-gray-900 dark:text-white"
      >
        <ThynkWebLogoSvg />
        <span className="self-center ml-3 whitespace-nowrap text-xl font-semibold dark:text-white">
          Thynk Corp
        </span>
      </a>
      <span className="text-sm text-gray-500 sm:text-center dark:text-gray-400">
        ©{" "}
        <a href="/#" className="hover:underline">
          Thynk Corp™
        </a>
        . All Rights Reserved.
      </span>
    </div>
  </footer>
);
