import { useDispatch } from "react-redux";

import { Button } from "flowbite-react";
import ModalComponent from "../../../common/Modal/Modal";
import InputFields from "../../../common/Input/InputFields";

import { btnPrimary } from "../../../../utils/helper";
import { updateSkills } from "../../../../store/slices/addSkillSlice";
import { modalsEnum } from "../../helper";

/**
 * Renders the UpdateSkillModal component.
 *
 * @param {{toggleModal: Function, modals: Object, selectedSkill: Object, setSelectedSkill: Function}} props
 * @param {function} toggleModal - Function to toggle the update modal
 * @param {object} modals - Object containing modal states
 * @param {object} selectedSkill - Object representing the selected skill
 * @param {function} setSelectedSkill - Function to set the selected skill
 * @return {JSX.Element} The rendered UpdateSkillModal component
 */
const UpdateSkillModal = ({ toggleModal, modals, selectedSkill, setSelectedSkill }) => {
  const dispatch = useDispatch();
  const onChangeUpdateSkill = function (e) {
    setSelectedSkill(prevUS => ({ ...prevUS, name: e.target.value }));
  };

  const onSubmitUpdateHandler = function (e) {
    e.preventDefault();
    toggleModal(modalsEnum.updateSkill);
    dispatch(updateSkills(selectedSkill));
    //reset state
    setSelectedSkill({ id: null, name: "" });
  };

  return (
    <ModalComponent
      onClose={toggleModal.bind(null, modalsEnum.updateSkill)}
      show={modals.updateSkill}
      showFooter={false}
      size="xl"
      bodyClassName="px-6"
      heading="Update Skill">
      <form onSubmit={onSubmitUpdateHandler}>
        <div className="mt-4 flex items-end justify-center gap-4">
          <InputFields
            InputField={{
              id: "update-skill",
              label: "Update Skill",
              type: "text",
              name: "update-skill",
              placeholder: 'Enter update skill name',
              required: true,
              required: false,
            }}
            helperText="Note: if this skill is associated with any users, it'll be updated for those users."
            onChange={onChangeUpdateSkill}
            value={selectedSkill.name}
            inputClassName="w-full"
            parentClassName="w-[80%]"
          />
        </div>

        <div className="flex justify-end mt-5 gap-4">
          <button
            className={`${btnPrimary()} rounded-md text-white font-semibold  px-4 py-[10px] tracking-wide`}>
            Update Skill
          </button>
          <Button color="gray" onClick={toggleModal.bind(null, modalsEnum.updateSkill)}>
            Close
          </Button>
        </div>
      </form>
    </ModalComponent>
  );
};

export default UpdateSkillModal;
