import React from "react";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import { Button } from "flowbite-react";
import {
  setStatutoryProfessionalTaxDetails,
  setStatutoryToggleDetails,
} from "../../../store/slices/statutorySlice";

const InitialProfessionalTaxComponent = () => {
  const dispatch = useAppDispatch();
  const statutory = useAppSelector(state => state.statutory);

  const { statutoryToggleDetails } = statutory;

  const onClickProfessionalTaxEnable = () => {
    dispatch(
      setStatutoryToggleDetails({
        ...statutoryToggleDetails,
        toggleProfessionalTaxModal: true,
      })
    );
  };
  return (
    <div>
      <div className="mx-auto w-[50%]">
        <div className=" h-40 w-44 mx-auto">
          <img
            style={{ width: "100%", height: "100%" }}
            src="https://css.zohostatic.com/zfpayroll/zpayroll//assets/images/empty-states/ind/esi-317ed7e2208127d94c4d41bd8ca29641.svg"
            alt=""
          />
        </div>
        <h1 className="text-center text-xl font-medium">
          Enable professional tax if applicable for your work location
        </h1>
        {/* <p className="text-center mt-4">
          Enable professional tax if applicable for your work location
        </p> */}
        <Button className="mx-auto mt-6" onClick={onClickProfessionalTaxEnable}>
          Enable Professional Tax
        </Button>
      </div>
    </div>
  );
};

export default InitialProfessionalTaxComponent;
