import { useEffect, useState } from "react";
import { useParams } from "react-router";

import ItemTable from "../../components/invoices/components/ItemTable";
import Total from "../../components/invoices/components/Total";
import ShippingDetails from "../../components/invoices/ShippingDetails";
import InputFields from "../../components/common/Input/InputFields";
import InvoiceHeader from "../../components/invoices/components/InvoiceHeader";
import InvoiceDate from "../../components/invoices/components/InvoiceDate";
import Billing from "../../components/invoices/components/Billing";
import TaxButtons from "../../components/invoices/components/TaxButtons";
import Legal from "../../components/invoices/components/Legal";
import SubmitButton from "../../components/invoices/components/SubmitButton";
import AddNewItem from "./../../components/invoices/components/AddNewItem";
import { Footer } from "../../components/common/Footer";

import {
  toggleInitialState,
  allAmountInitialState,
  initialFormValues,
  CalculateAmount,
  addShippingAddress,
} from "../../components/invoices/helper";
import { DEFAULT_PAGE_NO, DEFAULT_PAGE_SIZE } from "../../constants/Constant";
import { getBusinesses } from "../../services/BusinessService";
import { getInvoiceById, getInvoiceNumber } from "../../services/InvoiceService";
import {
  fetchCustomFields,
  getAllTAndC,
  getAllNotes,
} from "../../components/invoices/customFieldHelper";
import { radioPrimaryClass } from "../../utils/helper";

const isClient = false;

const UpdateInvoice = () => {
  const { id: paramsId } = useParams();

  const [newItemsCount, setNewItemsCount] = useState(1);
  const [formValues, setFormValues] = useState(initialFormValues);
  const [toggleStates, setToggleStates] = useState(toggleInitialState);

  //Header fields
  const [date, setDate] = useState({
    futureDate: "",
    currentDate: "",
  });
  const [billed, setBilled] = useState({
    billedToId: "",
    billedById: "",
  });
  const [allAmounts, setAllAmounts] = useState(allAmountInitialState);

  useEffect(() => {
    Promise.allSettled([fetchBusinessesNames(), fetchBusinessClientNames()]);
  }, [toggleStates.isChange]);

  useEffect(() => {
    Promise.allSettled([
      fetchInvoiceById(paramsId),
      getInvoiceNum(),
      getAllTAndC(setFormValues, updateToggleState, paramsId),
      getAllNotes(setFormValues, updateToggleState, paramsId),
    ]);
  }, []);

  //Logo Url
  useEffect(() => {
    const { selectedBusiness } = formValues;
    if (selectedBusiness?.logoBytes) {
      const base64Data = selectedBusiness?.logoBytes;
      const imageSrc = `data:image/jpeg;base64,${base64Data}`;
      setFormValues(prevFV => ({ ...prevFV, logoUrl: base64Data ? imageSrc : "" }));
    }
  }, [formValues.selectedBusiness]);

  useEffect(() => {
    //hide terms and condition if there's no item in the array
    if (formValues.TAndC.length === 0) {
      updateToggleState("isTermsAndConditionsVisible", false);
    }
  }, [formValues.TAndC.length]);

  const updateToggleState = (key, value) => {
    setToggleStates(prevS => ({ ...prevS, [key]: value }));
  };

  //Fetching invoice details by id
  const fetchInvoiceById = paramsId => {
    getInvoiceById(paramsId).then(res => {
      const data = res.data;

      //fetch customfields
      fetchCustomFields(setFormValues, true, data?.customFields, paramsId);

      const tempInvoiceItem = data?.invoiceItems.map(invoiceItem => ({
        ...invoiceItem,
      }));
      const { tempAmount, tempCgstAmount, tempSgstAmount, tempIgstAmount } =
        CalculateAmount(tempInvoiceItem);

      setAllAmounts({
        amount: tempAmount,
        igstAmount: tempIgstAmount,
        cgstAmount: tempCgstAmount,
        sgstAmount: tempSgstAmount,
      });

      setBilled({
        billedById: data.billedBy,
        billedToId: data.billedTo,
      });
      setDate({
        currentDate: data.invoiceDate,
        futureDate: data.dueDate,
      });
      setFormValues(prevFV => ({
        ...prevFV,
        invoiceNumber: data?.invoiceNumber,
        selectedBusiness: data?.billedByBusiness,
        selectedClientBusiness: data?.billedToBusiness,
        radioButtonValue: data.isIntegratedTax ? "igst" : "cgst",
        invoiceItems: tempInvoiceItem,
        ...data,
        invoiceHeader: {
          fieldName: data?.invoiceHeader,
        },
        shippingDetails: {
          sameAsBusinessAddress: data?.sameAsBusinessAddress,
          sameAsClientAddress: data?.sameAsClientAddress,
          challanNumber: data?.challanNumber,
          challanDate: data?.challanDate,
          transportName: data?.transportName,
          transportNote: data?.transportNote,
          shippedFrom: data?.shippedFrom,
          shippedTo: data?.shippedTo,
        },
      }));

      //show shipping detail component when
      //Case 1 : if shipping details are same as business/client address
      //Case 2 : if shippedFrom/shippedTo address exist and are not same as billedFrom and billedTo
      const isShippingDetailsExist =
        data.sameAsBusinessAddress ||
        data.sameAsClientAddress ||
        data.shippedFrom !== 0 ||
        data.shippedTo !== 0;

      setToggleStates(prevTS => ({
        ...prevTS,
        showShippingDetails: isShippingDetailsExist,
      }));
    });
  };

  //fetch business names for user business dropdown(Billed By)
  const fetchBusinessesNames = async () => {
    const res = await getBusinesses(DEFAULT_PAGE_NO, 100, isClient);
    const businessById = res?.data.find(ele => ele.id === +billed.billedById);
    
    //Update selected business details
    if (businessById)
      setFormValues(prevFV => ({
        ...prevFV,
        businesses: res?.data,
        selectedBusiness: businessById,
      }));
    else
      setFormValues(prevFV => ({
        ...prevFV,
        businesses: res?.data,
      }));

    //it's not possible to create a invoice without adding business and when user update the
    //existing invoice business address must be present
    setToggleStates(prevTS => ({ ...prevTS, isSelectedBusiness: true }));
  };

  //fetch client business names for  client dropdown(BIlled To)
  const fetchBusinessClientNames = async () => {
    const res = await GetBusinesses(DEFAULT_PAGE_NO, 100, !isClient);
    const clientById = res?.data.find(ele => ele.id === +billed.billedToId);

    //update selected client details
    if (clientById)
      setFormValues(prevFV => ({
        ...prevFV,
        clients: res?.data,
        selectedClientBusiness: clientById,
      }));
    else
      setFormValues(prevFV => ({
        ...prevFV,
        clients: res?.data,
      }));

    //it's not possible to create a invoice without adding client and when user update the
    //existing invoice client address must be present
    setToggleStates(prevTS => ({ ...prevTS, isSelectedClient: true }));
  };

  //Getting Invoice Number
  const getInvoiceNum = () => {
    getInvoiceNumber().then(res =>
      setFormValues(prevFV => ({ ...prevFV, invoiceNumberDetails: res?.data }))
    );
  };

  const toggleShippingDetails = () => {
    if (!toggleStates.showShippingDetails) {
      setFormValues(prevFV => ({
        ...prevFV,
        shippingDetails: {
          ...prevFV.shippingDetails,
          shippedFrom: 0,
          shippedTo: 0,
        },
      }));
    }
    setToggleStates(prevTS => ({
      ...prevTS,
      showShippingDetails: !prevTS.showShippingDetails,
    }));
  };

  return (
    <>
      <div className="w-4/5 mx-auto shadow-xl px-8 mt-6 mb-4">
        <InvoiceHeader
          setFormValues={setFormValues}
          formValues={formValues}
          updateToggleState={updateToggleState}
          toggleStates={toggleStates}
        />

        {/* invoice-date-div */}
        <InvoiceDate
          formValues={formValues}
          toggleStates={toggleStates}
          setFormValues={setFormValues}
          setDate={setDate}
          date={date}
          updateToggleState={updateToggleState}
          isUpdating={true}
          invoiceId={paramsId}
        />

        {/* billed_by and billed_to section */}
        <Billing
          toggleStates={toggleStates}
          setToggleStates={setToggleStates}
          setFormValues={setFormValues}
          formValues={formValues}
          updateToggleState={updateToggleState}
          setBilled={setBilled}
          billedById={billed.billedById}
        />

        {/* shipping-details-ui */}
        <InputFields
          parentClassName="flex p-1 items-center gap-3 mt-2 flex-row-reverse w-fit items-end"
          InputField={addShippingAddress}
          value={toggleStates.showShippingDetails}
          inputClassName={`${radioPrimaryClass()} flex !rounded`}
          labelClassName="!text-base !mb-0"
          otherProps={{
            checked: toggleStates.showShippingDetails,
          }}
          onChange={toggleShippingDetails}
        />

        {toggleStates.showShippingDetails && (
          <ShippingDetails
            toggleStates={toggleStates}
            setFormValues={setFormValues}
            formValues={formValues}
            setToggleStates={setToggleStates}
            isUpdating={true}
          />
        )}

        <div className="shipping-container border border-[red] border-opacity-0 mt-2">
          <TaxButtons
            setFormValues={setFormValues}
            formValues={formValues}
          />

          <ItemTable
            invoiceItems={formValues.invoiceItems}
            setAllAmounts={setAllAmounts}
            setFormValues={setFormValues}
            allAmounts={allAmounts}
            radioButtonValue={formValues.radioButtonValue}
          />

          {/* Add new line button */}
          <AddNewItem
            invoiceItems={formValues.invoiceItems}
            setAllAmounts={setAllAmounts}
            setFormValues={setFormValues}
            setNewItemsCount={setNewItemsCount}
            newItemsCount={newItemsCount}
            isUpdating={true}
            paramsId={paramsId}
          />

          {/* showing final data */}
          <Total
            allAmounts={allAmounts}
            radioButtonValue={formValues.radioButtonValue}
          />

          {/* Terms And Condition and Notes */}
          <Legal
            toggleStates={toggleStates}
            formValues={formValues}
            setFormValues={setFormValues}
            updateToggleState={updateToggleState}
            invoiceId={paramsId}
          />

          {/* save & continue button */}
          <SubmitButton
            formValues={formValues}
            date={date}
            billed={billed}
            allAmounts={allAmounts}
            setFormValues={setFormValues}
            setAllAmounts={setAllAmounts}
            setToggleState={setToggleStates}
            isUpdating={true}
            toggleStates={toggleStates}
            invoiceId={paramsId}
          />
        </div>
      </div>
    </>
  );
};

export default UpdateInvoice;
