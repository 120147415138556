import { AiOutlinePercentage } from "react-icons/ai";
import { BsCurrencyRupee } from "react-icons/bs";
import { RxCrossCircled } from "react-icons/rx";
import { handleDelete, getInvoiceItem } from "./helper";

const InvoiceItemSmallScreen = ({
  value,
  radioValue,
  invoiceItems,
  setAllAmounts,
  setFormValues,
  allAmounts,
  ind,
}) => {
  const { name, hsnsac, gstRate, quantity, rate, id } = value;

  const onItemValueChange = (event) => {
    const newItemValue = {
      ...value,
      [event.target.name]: event.target.value,
    };

    getInvoiceItem(
      invoiceItems,
      setAllAmounts,
      setFormValues,
      allAmounts,
      newItemValue
    );
  };
  return (
    <>
      <div className="item-container-small-screen mt-2 rounded-lg p-2 pb-4 bg-[hsl(218,100%,98%)] text-[0.9rem] leading-[0.5rem]">
        <div className="serialNumber-row-container w-full h-10 flex justify-around ">
          <div className="heading border-purple-700 w-[50%] min-[480px]:w-[27%] pt-2 pl-2 border-opacity-0 border border-x-0 border-t-0">
            {`${ind + 1}.`}
          </div>
          <div className="value-input-box border border-x-0 border-t-0 w-[50%] min-[480px]:w-[73%] flex justify-end pr-4 pt-[0.1rem]">
            <div className="w-7 text-center">
              <RxCrossCircled
                className="cursor-pointer"
                fontSize={25}
                color={"red"}
                onClick={() =>
                  handleDelete(
                    invoiceItems,
                    setAllAmounts,
                    setFormValues,
                    id,
                    allAmounts
                  )
                }
              />
            </div>
          </div>
        </div>
        <div className="item-row-container w-full h-10 flex mt-2 ">
          <div className="heading border border-purple-700 w-[50%] min-[480px]:w-[27%] pt-[0.9rem] pl-2 border-opacity-0 text-[0.9rem] leading-[0.9rem] text-[#21314a]">
            Item
          </div>
          <div className="value-input-box w-[50%] min-[480px]:w-[73%]">
            <input
              type="text"
              name="name"
              value={name}
              onChange={onItemValueChange}
              className="w-full border-x-0 border-t-0 focus:outline-none focus:ring-transparent bg-[hsl(218,100%,98%)] text-[0.9rem] leading-[0.9rem]"
              placeholder="Item Name (Required) "
            />
          </div>
        </div>
        <div className="hsnsac-row-container w-full h-10 flex mt-2">
          <div className="heading border border-purple-700 w-[50%] min-[480px]:w-[27%] pt-[0.9rem] pl-2 border-opacity-0  text-[0.9rem] leading-[0.9rem] text-[#21314a]">
            HSN/SAC
          </div>
          <div className="value-input-box w-[50%] min-[480px]:w-[73%]">
            <input
              type="text"
              name="hsnsac"
              value={hsnsac}
              onChange={onItemValueChange}
              className="w-full border-x-0 border-t-0 focus:outline-none focus:ring-transparent bg-[hsl(218,100%,98%)] text-[0.9rem] leading-[0.9rem]"
              placeholder="Enter HSN/SAC"
            />
          </div>
        </div>
        <div className="gstRate-row-container w-full h-10 flex mt-2 border border-red-700 border-transparent">
          <div className="heading border border-purple-700 w-[50%] min-[480px]:w-[27%] pt-[0.9rem] pl-2 border-opacity-0  text-[0.9rem] leading-[0.9rem] text-[#21314a]">
            GST Rate
          </div>
          <div className="value-input-box w-[50%] min-[480px]:w-[73%] relative">
            <input
              type="text"
              name="gstRate"
              value={gstRate}
              onChange={onItemValueChange}
              className="w-full border-x-0 border-t-0 focus:outline-none focus:ring-transparent bg-[hsl(218,100%,98%)] text-[0.9rem] leading-[0.9rem]"
              placeholder="Enter GST Rate"
            />
            <span className="absolute right-[0.65rem] top-[0.75rem]">
              <AiOutlinePercentage size={16} />
            </span>
          </div>
        </div>
        <div className="quantity-row-container w-full h-10 flex mt-2">
          <div className="heading border border-purple-700 w-[50%] min-[480px]:w-[27%] pt-[0.9rem] pl-2 border-opacity-0 text-[0.9rem] leading-[0.9rem] text-[#21314a]">
            Quantity
          </div>
          <div className="value-input-box w-[50%]  min-[480px]:w-[73%]">
            <input
              type="text"
              name="quantity"
              value={quantity}
              onChange={onItemValueChange}
              className="w-full border-x-0 border-t-0 focus:outline-none focus:ring-transparent bg-[hsl(218,100%,98%)] text-[0.9rem] leading-[0.9rem]"
              placeholder="Enter Quantity"
            />
          </div>
        </div>
        <div className="rate-row-container w-full h-10 flex mt-2">
          <div className="heading border border-purple-700 w-[50%] min-[480px]:w-[27%] pt-[0.9rem] pl-2 border-opacity-0 text-[0.9rem] leading-[0.9rem] text-[#21314a]">
            Rate
          </div>
          <div className="value-input-box w-[50%] min-[480px]:w-[73%] relative">
            <input
              type="text"
              name="rate"
              value={rate}
              onChange={onItemValueChange}
              className="w-full border-x-0 border-t-0 focus:outline-none focus:ring-transparent bg-[hsl(218,100%,98%)] text-[0.9rem] leading-[0.9rem]"
              placeholder="Enter Rate"
            />
            <span className="absolute left-[-0.2rem] top-[0.67rem]">
              <BsCurrencyRupee size={16} />
            </span>
          </div>
        </div>
        <div className="amount-row-container w-full h-10 flex mt-2">
          <div className="heading border border-purple-700 w-[50%] min-[480px]:w-[27%] pt-[0.9rem] pl-2 border-opacity-0  text-[0.9rem] leading-[0.9rem] text-[#21314a]">
            Amount
          </div>
          <div className="value-input-box w-[50%] min-[480px]:w-[73%] relative">
            <input
              type="text"
              name="amount"
              value={+quantity * +rate}
              readOnly
              className="w-full border-x-0 border-t-0 focus:outline-none focus:ring-transparent bg-[hsl(218,100%,98%)] text-[0.9rem] leading-[0.9rem]"
              placeholder="Amount"
            />
            <span className="absolute left-[-0.2rem] top-[0.67rem]">
              <BsCurrencyRupee size={16} />
            </span>
          </div>
        </div>
        {radioValue === "igst" ? (
          <>
            <div className="igst-row-container w-full h-10 flex mt-2">
              <div className="heading border border-purple-700 w-[50%] min-[480px]:w-[27%] pt-[0.9rem] pl-2 border-opacity-0 text-[0.9rem] leading-[0.9rem] text-[#21314a]">
                IGST
              </div>
              <div className="value-input-box w-[50%] min-[480px]:w-[73%] relative">
                <input
                  type="text"
                  name="igstValue"
                  value={(+quantity * +rate * +gstRate) / 100}
                  readOnly
                  className="w-full border-x-0 border-t-0 focus:outline-none focus:ring-transparent bg-[hsl(218,100%,98%)] text-[0.9rem] leading-[0.9rem]"
                  placeholder="IGST"
                />
                <span className="absolute left-[-0.2rem] top-[0.67rem]">
                  <BsCurrencyRupee size={16} />
                </span>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="cgst-row-container w-full h-10 flex mt-2">
              <div className="heading border border-purple-700 w-[50%] min-[480px]:w-[27%] pt-[0.9rem] pl-2 border-opacity-0 text-[0.9rem] leading-[0.9rem] text-[#21314a]">
                CGST
              </div>
              <div className="value-input-box w-[50%] min-[480px]:w-[73%] relative">
                <input
                  type="text"
                  name="cgstValue"
                  value={(+quantity * +rate * +gstRate) / 200}
                  readOnly
                  className="w-full border-x-0 border-t-0 focus:outline-none focus:ring-transparent bg-[hsl(218,100%,98%)] text-[0.9rem] leading-[0.9rem]"
                  placeholder="CGST"
                />
                <span className="absolute left-[-0.2rem] top-[0.67rem]">
                  <BsCurrencyRupee size={16} />
                </span>
              </div>
            </div>
            <div className="sgst-row-container w-full h-10 flex mt-2">
              <div className="heading border border-purple-700 w-[50%] min-[480px]:w-[27%] pt-[0.9rem] pl-2 border-opacity-0 text-[0.9rem] leading-[0.9rem] text-[#21314a]">
                SGST
              </div>
              <div className="value-input-box w-[50%] min-[480px]:w-[73%] relative">
                <input
                  type="text"
                  name="sgstValue"
                  value={(+quantity * +rate * +gstRate) / 200}
                  readOnly
                  className="w-full border-x-0 border-t-0 focus:outline-none focus:ring-transparent bg-[hsl(218,100%,98%)] text-[0.9rem] leading-[0.9rem]"
                  placeholder="SGST"
                />
                <span className="absolute left-[-0.2rem] top-[0.67rem]">
                  <BsCurrencyRupee size={16} />
                </span>
              </div>
            </div>
          </>
        )}
        <div className="total-amount-row-container w-full h-10 flex mt-2">
          <div className="heading border border-purple-700 w-[50%] min-[480px]:w-[27%] pt-[0.9rem] pl-2 border-opacity-0 text-[0.9rem] leading-[0.9rem] text-[#21314a]">
            Total
          </div>
          <div className="value-input-box w-[50%] min-[480px]:w-[73%] relative">
            <input
              type="text"
              name="total"
              value={(
                +quantity * +rate +
                (+quantity * +rate * +gstRate) / 100
              ).toFixed(2)}
              readOnly
              className="w-full border-x-0 border-t-0 focus:outline-none focus:ring-transparent bg-[hsl(218,100%,98%)] text-[0.9rem] leading-[0.5rem]"
              placeholder="Total"
            />
            <span className="absolute left-[-0.2rem] top-[0.67rem]">
              <BsCurrencyRupee size={16} />
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default InvoiceItemSmallScreen;
