import {
  parse,
  startOfToday,
  getDate,
  isAfter} from "date-fns";
import { NewAttendanceStatus } from "../../../enum/NewAttendanceStatus";  
export const presentData = (attendances) => {
  const today = startOfToday(); // Getting the current date
  const presentArray = Array.from({ length: 31 }, () => 0); // Initializing array with zeros
  attendances.forEach((attendance) => {
    const date = parse(attendance.date, 'yyyy-MM-dd', new Date());
    const dayOfMonth = getDate(date);
    if (isAfter(date, today)) {
      // If the date is after the current date, assign 0
      presentArray[dayOfMonth - 1] = 0;
    } else if (attendance.attendanceStatus === NewAttendanceStatus.Present && attendance.overallApprovalStatus != 3) {
      let totalHours = 0;
      if (attendance.totalHours == '00:00:00') totalHours = 9;
      else if (attendance.totalHours < '01:00:00') totalHours = 1;
      else totalHours = parseInt(attendance.totalHours.split(':')[0]); // Extracting hours part as totalHours is coming as '09:00:00'
      presentArray[dayOfMonth - 1] = totalHours; // Parsing totalHours to integer
    }
  });
  return presentArray;
};

export const absentData = (attendances) => {
  const today = startOfToday();
  const absentArray = Array.from({ length: 31 }, () => 0); // Initializing array with zeros
  attendances.forEach((attendance) => {
    const date = parse(attendance.date, 'yyyy-MM-dd', new Date());
    const dayOfMonth = getDate(date);
    if (isAfter(date, today)) {
      // If the date is after the current date, assign 0
      absentArray[dayOfMonth - 1] = 0;
    }
    else if (attendance.attendanceStatus === NewAttendanceStatus.Present && attendance.overallApprovalStatus == 3) { // Rejected attendance
      absentArray[dayOfMonth - 1] = 9;
    }
    else if (attendance.attendanceStatus === NewAttendanceStatus.Absent) {
      absentArray[dayOfMonth - 1] = 9; // Directly putting 9 as totalHours is 0 for absent
    }
  });
  return absentArray;
};

export const holidayData = (attendances) => {
  const today = startOfToday();
  const holidayArray = Array.from({ length: 31 }, () => 0); // Initializing array with zeros
  attendances.forEach((attendance) => {
    const date = parse(attendance.date, 'yyyy-MM-dd', new Date());
    const dayOfMonth = getDate(date);
    if (isAfter(date, today)) {
      // If the date is after the current date, assign 0
      holidayArray[dayOfMonth - 1] = 0;
    } else if (attendance.isHoliday == true) {
      holidayArray[dayOfMonth - 1] = 9; // Directly putting 9 as totalHours is 0 for absent
    }
  });
  return holidayArray;
};
