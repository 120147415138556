const baseUrl = process.env.REACT_APP_BASE_URL;
const generalBaseUrl = `${baseUrl}/General`;

//getting user details form contact-us form
export const SubmitContactUsDetails = async (userDetails) => {
  const response = await fetch(`${generalBaseUrl}/contact-us`, {
    method: "POST",
    body: JSON.stringify(userDetails),
    headers: {
      "Content-Type": "application/json",
    },
  });
  return response.json();
};
