import { useContext, useEffect, useState, useRef } from "react";
import { useCheckbox } from "../../hooks/useCheckbox";
import { AuthContext } from "../../context/AuthContext";

import { Footer } from "../../components/common/Footer";
import { showToast } from "../../components/common/Toast";
import AddHoliday from "../../components/holidays/AddHoliday";
import HolidaysList from "../../components/holidays/HolidaysList";
import UpdateHoliday from "../../components/holidays/UpdateHoliday";
import DeleteHoliday from "../../components/holidays/DeleteHoliday";
import PageSection from "../../components/Layout/PageSection/PageSection";

import { Role } from "../../enum/Role";
import { HOLIDAY_STATUS } from "./../../enum/HolidayStatus";
import { getHolidays, changeHolidaysStatus } from "../../services/CommonService";
import FilterHolidaysOption from "../../components/holidays/FilterHolidaysOption";
import { GiPalmTree } from "react-icons/gi";

const initialState = {
  allChecked: false,
  checkedItems: [],
};

const currentYear = new Date().getFullYear();
const dotDefaultClasses = "w-4 h-4 rounded-full";

const Holidays = () => {
  const { user } = useContext(AuthContext);
  const selectAllRef = useRef();

  const [holidaysList, setHolidaysList] = useState([]);
  const [isChange, setIsChange] = useState(false);
  const [holidayId, setHolidayId] = useState("");
  const [toggleUpdateHoliday, setToggleUpdateHoliday] = useState(false);
  const [isUpdateHolidayId, setIsUpdateHolidayId] = useState(false);
  const [toggleDeleteHoliday, setToggleDeleteHoliday] = useState(false);
  const [selectedHoliday, setSelectedHoliday] = useState({});
  const [selectedCheckedbox, setSelectedCheckedbox] = useState(initialState);
  const [holidayFilterYear, setHolidayFilterYear] = useState(currentYear);
  const { isSelectedHandler } = useCheckbox(
    setSelectedCheckedbox,
    selectedCheckedbox,
    holidaysList,
    selectAllRef
  );
  
  useEffect(() => {
    getHolidaysList();
    setIsChange(false);
  }, [isChange]);

  const getHolidaysList = () => {
    //if filtered year exist fetch that year list
    if (holidayFilterYear) {
      getHolidays(holidayFilterYear).then(res => {
        setHolidaysList(res?.data);
      });
      return;
    }

    //if holidaysFilter does not exist fetch current year list
    getHolidays().then(res => {
      setHolidaysList(res?.data);
    });
  };

  const onEditHolidayHandler = id => {
    setToggleUpdateHoliday(true);
    setHolidayId(id);
    setIsUpdateHolidayId(true);
  };

  const onDeleteHolidayHandler = holiday => {
    setToggleDeleteHoliday(true);
    setSelectedHoliday(holiday);
    setHolidayId(holiday?.id);
  };

  const onChangeFilterHandler = async e => {
    const { value } = e.target;
    setHolidayFilterYear(value);

    try {
      const res = await getHolidays(+value);
      setHolidaysList(res.data);
    } catch (error) {
      showToast("warn", "Something went wrong!");
    }
  };

  const handleStatus = status => {
    const { checkedItems, allChecked } = selectedCheckedbox;

    changeHolidaysStatus(checkedItems, status)
      .then(res => {
        if (res) {
          showToast(
            "success",
            `${status === HOLIDAY_STATUS.Publish ? "Publish" : "Unpublish"} ${
              allChecked ? "All" : "Selected"
            } Holidays.`
          );
          setSelectedCheckedbox(initialState);
          //do something about year changing when we publish/unpublish holidays
          setHolidayFilterYear(holidayFilterYear);
          setIsChange(true);
          return;
        }
      })
      .catch(() => {
        showToast("warn", "Something went wrong!");
      });
  };

  const AddHolidayBtn = +user?.roleId === Role.Administrator && (
    <AddHoliday isChange={isChange} setIsChange={setIsChange} />
  );

  return (
    <>
      <PageSection 
        button={AddHolidayBtn}
        showIcon={true}
        icon={<GiPalmTree size={"2rem"} />}
        heading="Holidays">
        <div className="dark:divide-gray-700 bg-white rounded">
          <div className="flex flex-row justify-between items-stretch sm:items-center px-4 py-3">
            <div className="sm:self-start"></div>

            <FilterHolidaysOption
              checkedItems={selectedCheckedbox.checkedItems}
              user={user}
              onChangeFilterHandler={onChangeFilterHandler}
              handleStatus={handleStatus}
              holidayFilterYear={holidayFilterYear}
            />
          </div>
          <div className="">
            <table className="w-full text-sm text-left">
              <thead className="text-xs uppercase">
              </thead>
              <tbody className={holidaysList.length === 0 ? "h-[8rem]" : undefined}>
                {holidaysList.length > 0 && (
                  <HolidaysList
                    holidaysList={holidaysList}
                    user={user}
                    editHoliday={onEditHolidayHandler}
                    deleteHoliday={onDeleteHolidayHandler}
                    isSelectedHandler={isSelectedHandler}
                    checkedHolidays={selectedCheckedbox.checkedItems}
                  />
                )}

                {holidaysList.length === 0 && (
                  <tr className="border-0">
                    <td colSpan={5} className="tracking-wide text-center border-0 text-[#6b7280]">
                      No Holiday Found!
                    </td>
                  </tr>
                )}
              </tbody>
            </table>

            <UpdateHoliday
              holidayId={holidayId}
              isChange={isChange}
              setIsChange={setIsChange}
              setToggleUpdateHoliday={setToggleUpdateHoliday}
              toggleUpdateHoliday={toggleUpdateHoliday}
              setIsUpdateHolidayId={setIsUpdateHolidayId}
              isUpdateHolidayId={isUpdateHolidayId}
              setSelectedCheckedbox={setSelectedCheckedbox}
            />
            <DeleteHoliday
              toggleDeleteHoliday={toggleDeleteHoliday}
              setToggleDeleteHoliday={setToggleDeleteHoliday}
              selectedHoliday={selectedHoliday}
              setIsChange={setIsChange}
              setSelectedCheckedbox={setSelectedCheckedbox}
            />
          </div>
        </div>
      </PageSection>
    </>
  );
};

export default Holidays;
