import { useSearchParams } from "react-router-dom";
import { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import TableHeader from "../../../common/Table/TableHeader";
import SkillsRows from "../SkillsRows";
import SkillsComponent from "../SkillsComponent";
import ModalComponent from "../../../common/Modal/Modal";

import { profileSkillsTableHeader, skillInputFields, skillsTableHeader } from "../../helper";
import { deleteSkill, updateSkill } from "../../../../store/slices/employment";

/**
 * Renders the Skills component.
 *
 * @param {boolean} isProfile - Indicates if the component is being rendered in the profile page.
 * @return {ReactNode} The rendered Skills component.
 */
const Skills = ({ isProfile }) => {
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [selectedSkill, setSelectedSkill] = useState([]);
  const [selectedSkillDetails, setSelectedSkillDetails] = useState({});
  const [searchParams, _] = useSearchParams();
  const { skills } = useSelector(state => state.employment.user);
  const dispatch = useDispatch();
  const { rawSelectedSkill } = skills;

  const memoizedCols = useMemo(
    () => (isProfile ? profileSkillsTableHeader : skillsTableHeader),
    [skillsTableHeader.length]
  );

  const onClickUpdateSkill = e => {
    const elem = e.target.closest("[data-id]");
    if (!elem) return;
    //data --> [skillId, actionType]
    const data = JSON.parse(elem.dataset.id);
    if (data[1] === "update") {
      const filteredSkill = rawSelectedSkill.filter(skill => skill.skillId === +data[0])[0];

      //create new reference of the skill object
      const options = skillInputFields.map(skill => ({ ...skill }));
      options[0].value = filteredSkill.strength;
      options[1].value = moment(filteredSkill.lastWorkedOn).format("YYYY-MM");
      setSelectedSkill(options);
      setSelectedSkillDetails({
        id: filteredSkill.skillId,
        label: filteredSkill.name,
        value: filteredSkill.name,
      });
      setIsEditModalVisible(true);
      return;
    }
    if (data[1] === "delete") {
      deleteUserSkill(searchParams.get("userId"), +data[0]);
    }
  };

  const onSubmitHandler = data => {
    setSelectedSkillDetails({});
    setSelectedSkill([]);
    setIsEditModalVisible(false);
    dispatch(updateSkill(data));
  };

  const onCancelHandler = () => {
    setSelectedSkillDetails({});
    setSelectedSkill([]);
    setIsEditModalVisible(false);
  };

  const deleteUserSkill = (userId, skillId) => {
    dispatch(deleteSkill(userId, skillId));
  };

  return (
    <div className="w-full overflow-auto">
      <table
        onClick={onClickUpdateSkill}
        className={`${rawSelectedSkill.length === 0 ? "h-[10rem]" : ""} w-full`}>
        <TableHeader cols={memoizedCols} />
        <tbody>
          {rawSelectedSkill.length === 0 && (
            <tr>
              <td
                colSpan={7}
                className="tracking-wide text-center border-0 py-5 font-semibold text-[#6b7280]">
                No Skills added!
              </td>
            </tr>
          )}
          {rawSelectedSkill.length > 0 &&
            rawSelectedSkill.map(skill => (
              <SkillsRows isProfile={isProfile} key={skill.skillId} {...skill} />
            ))}
        </tbody>
      </table>

      <ModalComponent
        showFooter={false}
        heading="Edit Skill"
        bodyClassName="px-9 pb-8 mt-4"
        show={isEditModalVisible}
        onClose={setIsEditModalVisible.bind(null, false)}>
        <SkillsComponent
          isEditing={true}
          selectedSkill={selectedSkill}
          selectedSkillDetails={selectedSkillDetails}
          onSubmit={onSubmitHandler}
          onCancel={onCancelHandler}
        />
      </ModalComponent>
    </div>
  );
};

export default Skills;
