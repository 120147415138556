import React, { useState } from "react";
import { Button } from "flowbite-react";

import { showToast } from "../common/Toast";
import ModalComponent from "../common/Modal/Modal";

import {
  importUsersCsv,
} from "../../services/UserService";
import { getImportCsvSample } from "../../services/FileUploadService";
import { btnPrimary } from "../../utils/helper";
import ProfileSvg from "../../media/ProfileSvg";
import { FaUserGroup } from "react-icons/fa6";
import DragAndDrop from "../common/FileUpload/DragAndDrop";
import ImportSummaryReport from "./ImportSummaryReport";
import { useAppDispatch, useAppSelector } from "../../store/store";
import { getCustomFields } from "../../services/InvoiceService";
import { setIsAddingRestrictedUser, setIsEditingUser, setRefetchUsers, setToggleUserModal } from "../../store/slices/userManagementSlice";

export const EmployeeManagementButtons = () => {

  const dispatch = useAppDispatch();
  const umState = useAppSelector(state => state.userManagement);

  const [toggleImportUsers, setToggleImportUsers] = useState(false);
  const [isRestricted, setIsRestricted] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);

  const [fileName, setFileName] = useState(null); 

  const [importSummary, setImportSummary] = useState(null);

  const [importFile, setImportFile] = useState(null);

  // Function to handle file change
  const handleUserCsvFileChange = async event => {
    const file = event.target.files[0];
    if (file?.type === "text/csv") {
      setFileName(file.name);
      setImportFile(file);
    } else {
      return showToast("warning", "Please upload valid CSV file!");
    }
  };

  const handleImport = async () => {
    try {
      const importSummary = await importUsersCsv(importFile, isRestricted);
      setImportSummary(importSummary.data);
    } catch (error) {
      return showToast("warn", "Error importing users");
    }
  };

  const downloadCsvSample = async () => {
    try {
      setIsDownloading(true);
      const csvSample = await getImportCsvSample();
      const csvBlob = new Blob([csvSample.data], { type: "text/csv" });
      // Temp url
      const url = window.URL.createObjectURL(csvBlob);
      // Create temp <a> to trigger the download
      const tempLink = document.createElement("a");
      tempLink.href = url;
      tempLink.setAttribute("download", "ImportCsvSample.csv");

      document.body.appendChild(tempLink);
      tempLink.click();

      // Clean up
      document.body.removeChild(tempLink);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      return showToast("warn", "Error downloading CSV");
    } finally {
      setIsDownloading(false);
    }
  };

  //set states to initial states
  const resetStates = () => {
    dispatch(setRefetchUsers(!umState.refetchUsers));
    setToggleImportUsers(false);
    setImportFile(null);
    setFileName("");
    setImportSummary(null);
  };

  const onRestrictedToggleChange = () => {
    setIsRestricted(!isRestricted);
  };
  
  return (
    <>
      <div className="flex gap-1 items-center justify-center">

        {/* Import Users button */}
        <Button
          onClick={() => setToggleImportUsers(true)}
          className={`${btnPrimary()} px-0 ml-auto flex item-center justify-center`}>
          <div className="flex mr-2">
            <FaUserGroup />
          </div>
          <span>Import Users</span>
        </Button>

        {/*  Import Users Modal */}
        <ModalComponent
          show={toggleImportUsers}
          size="5xl"
          bodyClassName="mt-2"
          heading="Import Users"
          showFooter={true}
          onClose={resetStates}
          footer={
            <div className="flex justify-between w-full">
              <Button onClick={downloadCsvSample} disabled={isDownloading}>
                Download CSV Sample
              </Button>
              <div>
                {!importSummary && (
                  <Button
                    disabled={!fileName}
                    onClick={handleImport}
                    className={`${btnPrimary()} px-0 ml-auto flex item-center justify-center`}>
                    <span>Import</span>
                  </Button>
                )}
              </div>
            </div>
          }>
          {importSummary && (
            <div>
              <ImportSummaryReport importSummaryReport={importSummary} />
            </div>
          )}
          {!importSummary && (
            <>
              <div className="h-fit grid grid-cols-2 gap-3 ">
                <div>
                  <DragAndDrop
                    types={["csv"]} // Accept only CSV files
                    value={fileName ?? ""} // File name to display when uploaded
                    boldMessage="Drop your user CSV file here"
                    normalMessage="or click to select a CSV file"
                    onChange={handleUserCsvFileChange}
                    onDragOver={e => e.preventDefault()}
                    onDrop={e => e.preventDefault()}
                  />

                  <div className="flex  mt-10">
                    <span className="ms-3 text-sm text-gray-900 dark:text-gray-300 ml-4">
                      <p className="font-bold">Restricted Login</p>
                      <p className="text-xs">
                        Users that cannot login <br /> but can be part of a payroll.
                      </p>
                    </span>
                    <span>
                      <label className="relative inline-flex items-center cursor-pointer">
                        <input
                          type="checkbox"
                          value=""
                          checked={!isRestricted}
                          onChange={onRestrictedToggleChange.bind()}
                          className="sr-only peer"
                        />
                        <div
                          className={`w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-thynkwebPrimary-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-thynkwebPrimary-800`}></div>
                      </label>
                    </span>
                    <span className="ms-3 text-sm text-gray-900 dark:text-gray-300 ml-4">
                      <p className="font-bold">Invite</p>
                      <p className="text-xs">Users that can login.</p>
                    </span>
                  </div>
                </div>

                <div>
                  <ol className="list-decimal pl-4 space-y-4">
                    <li className="text-gray-700">
                      <span className="font-semibold">
                        Required fields: EmployeeNumber, FirstName, Email, DateOfJoining, AnnualCTC,
                        RegimeType.
                      </span>
                    </li>
                    <li className="text-gray-700">
                      Values should be separated by a{" "}
                      <span className="font-semibold">comma (,)</span>. If a field's value contains
                      a comma, enclose the entire field in double quotes.
                    </li>
                    <li className="text-gray-700">
                      Email and employee number must be unique for each user.
                    </li>
                    <li className="text-gray-700">
                      Empty fields are represented by consecutive commas (,,).
                    </li>
                    <li className="text-gray-700">
                      Email fields should contain valid email formats.
                    </li>
                    <li className="text-gray-700">Dates should be in the format YYYY-MM-DD.</li>
                    <li className="text-gray-700">
                      Gender, BloodGroup, MaritalStatus, and RegimeType must match one of the
                      following enum:
                      <ul className="list-disc pl-4 space-y-2">
                        <li>
                          <span className="font-semibold">Gender</span>: Male, Female,
                          PreferNotToDisclose, Others.
                        </li>
                        <li>
                          <span className="font-semibold">BloodGroup</span>: NotAvailable,
                          OPositive, APositive, BPositive, ABPositive, ABNegative, ANegative,
                          BNegative, ONegative.
                        </li>
                        <li>
                          <span className="font-semibold">MaritalStatus</span>: NotAvailable,
                          Married, Unmarried.
                        </li>
                        <li>
                          <span className="font-semibold">RegimeType</span>: New, Old.
                        </li>
                      </ul>
                    </li>
                    <li className="text-gray-700">
                      Numeric fields should only contain numeric values.
                    </li>
                  </ol>
                </div>
              </div>
            </>
          )}
        </ModalComponent>

        {/* Add restricted login user button */}
        <Button
          onClick={() => {
            // Refer AddEditEmployeeModal component
            dispatch(setToggleUserModal(true));
            dispatch(setIsEditingUser(false));
            dispatch(setIsAddingRestrictedUser(true));
          }}
          className={`${btnPrimary()} px-0 ml-auto flex items-center justify-center`}>
          <div className="flex mr-2 mt-0.5">
            <ProfileSvg className="w-[0.7rem] h-[0.7rem] fill-current " />
          </div>
          <span>Add User</span>
        </Button>

        {/* Invite user button */}
        <Button
          onClick={() => {
            // Refer AddEditEmployeeModal component
            dispatch(setToggleUserModal(true));
            dispatch(setIsEditingUser(false));
          }}
          className={`${btnPrimary()} px-0 ml-auto flex items-center justify-center`}>
          <div className="flex mr-2 mt-0.5">
            <ProfileSvg className="w-[0.7rem] h-[0.7rem] fill-current " />
          </div>
          <span>Invite User</span>
        </Button>
      </div>
    </>
  );
};
