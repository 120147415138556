import { useCallback, useContext, useEffect, useState } from "react";

import {
  approxTime_Badge,
  backgroundColor,
  currentPage,
  defaultClassName,
  dotClassName,
  isFirstHalf_SecondHalf,
  onPageChange,
  options,
  pagesize,
  setDefaultValue,
  setIsDefaultValue,
  totalPages,
} from "../../pages/LeaveManagement/helper";

import { DEFAULT_PAGE_NO, DEFAULT_PAGE_SIZE, STATUS_CODES } from "../../constants/Constant";
import { TimestampInfo, approx_time_show, cn } from "../../utils/common";
import {
  GetUserLeaves_UnderCurrentUser,
  getLeaveById,
  getMyApprovals,
  getSpecificUserApprovals,
  statusOfLeaves,
} from "../../services/LeaveService";
import SelectWithDropdown from "../../components/common/SelectWithDropdown/SelectWithDropdown";

import {
  ReportingUsers,
  statusOptions,
  statusOptionsForNonAdmin,
  statusOptionsForUser,
} from "../../pages/LeaveManagement/LeaveCommon";
import { showToast } from "../../components/common/Toast";
import { Tooltip } from "react-tooltip";
import { LeaveStatus } from "../../enum/LeaveStatus";
import { GetReportingUsers, GetUserById } from "../../services/UserService";
import { formatDate, radioPrimaryClass } from "../../utils/helper";
import PageNavigation from "../../components/common/Pagination/Pagination";
import { Role } from "../../enum/Role";
import { useParams, useSearchParams } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import { BsShieldCheck } from "react-icons/bs";
import PageSection from "../Layout/PageSection/PageSection";
import Table from "../common/Table/Table";
import TableHeader from "../common/Table/TableHeader";
import TableRows from "../common/Table/TableRows";
import TableData from "../common/Table/TableData";
import { ProTipApprovals, getStatusColorAndRenderLeaveStatus, myApprovalsHeading } from "./helper";
import usePagination, { PaginationComponent } from "../../hooks/usePagination";
import { useSelector } from "react-redux";
import ModalComponent from "../common/Modal/Modal";
import moment from "moment";
import { Button } from "flowbite-react";
import { ADD_NEW_LEAVE_MESSAGES } from "../../constants/Messages";

export const MyApprovals = () => {
  const [leavesResponse, setLeavesResponse] = useState();
  const [leaves, setLeaves] = useState([]);
  const [leaveIds, setLeaveIds] = useState([]);
  const [renderLeaveManagement, setRenderLeaveManagement] = useState(false);
  const [roleId, setRoleId] = useState();
  const [userId, setUserId] = useState();
  const [radioSelection, setRadioSelection] = useState("myApprovals");
  const [reportingUsers, setReportingUsers] = useState([]);
  const [modifiedReportingUsers, setModifiedReportingUsers] = useState();
  const [selectedUser, setSelectedUser] = useState();
  const [leaveModal, setLeaveModal] = useState(false);
  const [notificationLeave, setNotificationLeave] = useState({});

  // Setting the page no
  const [myApprovalsPageNo, setMyApprovalsPageNo] = useState(DEFAULT_PAGE_NO);
  // Setting the page size
  const [myApprovalsPageSize, setMyApprovalsPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [searchParams, _] = useSearchParams();
  const params = useParams();
  const { user: currentUser } = useContext(AuthContext);

  const { pageSize, pageNo, totalPages } = useSelector(state => state.pagination);
  const [totalRecords, setTotalRecords] = useState(0);
  const { setPageSizeHandler, onPageChange } = usePagination(false, totalRecords);

  const paramLeavesId = searchParams.get("leaveId");
  const paramUserId = searchParams.get("userId");
  const paramMyApprovalsUserId = searchParams.get("approvalUserId");

  const [leaveStatus, setLeaveStatus] = useState("");
  const [leaveType, setLeaveType] = useState("");

  const { colorClass, statusText } = getStatusColorAndRenderLeaveStatus(leaveStatus);
  const getReportingUsers = async () => {
    await GetReportingUsers("", false, pageSize, pageNo).then(response => {
      setReportingUsers(response?.data?.data);
    });
  };

  // Getting leaves
  useEffect(() => {
    if (paramMyApprovalsUserId) {
      fetchSelectedUserMyApprovals(paramMyApprovalsUserId);
    } else {
      fetchLeaves(pageNo, pageSize, renderLeaveManagement);
    }
  }, [pageNo, pageSize, paramMyApprovalsUserId, renderLeaveManagement]);

  useEffect(() => {
    getReportingUsers();
  }, []);

  useEffect(() => {
    if (reportingUsers?.length > 0) {
      const modified = ReportingUsers(reportingUsers);
      setModifiedReportingUsers(modified);
    }
  }, [reportingUsers]);

  useEffect(() => {
    //if search params exist
    if (paramLeavesId && paramUserId && +paramUserId !== currentUser.id) {
      GetUserById(paramUserId).then(res => {
        if (res?.status === 200) {
          const { firstName, lastName } = res.data;
          setSelectedUser({ value: +paramUserId, label: `${firstName} ${lastName}` });
          handleMyApprovals({ value: +paramUserId, label: `${firstName} ${lastName}` });
        }
      });

      getLeaveById(paramLeavesId).then(res => {
        const { status, isLeaveFirstHalf, isLeaveSecondHalf } = res.data;
        const validStatuses = [
          LeaveStatus.WaitingForApproval,
          LeaveStatus.Approved,
          LeaveStatus.Rejected,
          LeaveStatus.Cancelled,
        ];
        if (validStatuses.includes(status)) {
          setLeaveModal(true);
        }

        if (!isLeaveFirstHalf && !isLeaveSecondHalf) {
          setLeaveType("Full Day");
        } else if (isLeaveFirstHalf) {
          setLeaveType("First Half");
        } else if (isLeaveSecondHalf) {
          setLeaveType("Second Half");
        }

        setLeaveStatus(status);
        setNotificationLeave(res.data);
      });
    }
  }, [searchParams.get("leaveId"), searchParams.get("userId")]);

  useEffect(() => {
    // Setting up a new interval
    const newIntervalId = setInterval(() => {
      updateLeavesTimeDifference();
    }, 30000); // 30 seconds difference

    return () => {
      clearInterval(newIntervalId); // Clearing interval on component unmount or when interval is reset
    };
  }, [leaves]);

  useEffect(() => {
    if (leaveIds.length > 0) {
      LeaveStatus_Employee(leaveIds);
    }
  }, [leaveIds]);

  // Function to fetch leaves
  const fetchLeaves = async (pageNo, pageSize) => {
    const loggedInUser = JSON.parse(sessionStorage.getItem("user"));
    setRoleId(loggedInUser.roleId);
    setUserId(loggedInUser.id);

    // Checking for selected radio button and setting data according to it.
    const myApprovalsResponse = await getMyApprovals(pageNo, pageSize);
    setLeavesResponse(myApprovalsResponse);
    const myLeaves = myApprovalsResponse?.data;
    setLeaves(myLeaves);
    setSelectedUser();
    setTotalRecords(myApprovalsResponse?.totalRecords);
  };

  // Function to set the page size and page number
  const setPage = e => {
    setMyApprovalsPageSize(e.target.value);
    if (Math.ceil(leavesResponse?.totalRecords / myApprovalsPageSize) > 1) {
      setMyApprovalsPageNo(DEFAULT_PAGE_NO);
    }
  };

  const dateTime = (dateTime, month_Date_Year) => {
    const [dateTimeObj, month, date, year] = TimestampInfo(dateTime);

    const dateTimeMonth = (month + 1).toLocaleString("en-US", {
      minimumIntegerDigits: 2,
    });
    const dateTimeMonthInShort = dateTimeObj.toLocaleString("default", {
      month: "short",
    });
    const dateTimeDate = date.toLocaleString("en-US", {
      minimumIntegerDigits: 2,
    });
    const dateTimeYear = year;

    if (month_Date_Year) {
      return dateTimeMonth + "/" + dateTimeDate + "/" + dateTimeYear;
    }
    return dateTimeMonthInShort + " " + dateTimeDate;
  };

  const updateLeavesTimeDifference = () => {
    const updatedLeaves = leaves.map(user => {
      return {
        ...user,
        timeDifference: approx_time_show(user.dateCreated),
      };
    });
    setLeaves(updatedLeaves);
  };

  //Function to Change the Leave status for employees
  const LeaveStatus_Employee = useCallback(
    async leaveIds => {
      await statusOfLeaves(leaveIds)
        .then(response => {
          if (
            response.data?.message === "Success!" &&
            response.data?.statusCode === STATUS_CODES.SUCCESS
          ) {
            showToast("success", `Leave ${leaveIds[0].status}!`);
            setLeaveIds([]);
            return;
          }
        })
        .catch(err => console.error(err));
    },
    [leaveIds]
  );
  // Function to handle changes in select dropdown
  const handleSelectChange = async (leaveId, selectedOption) => {
    setLeaves(prevLeaves =>
      prevLeaves.map(leave => {
        if (leave.id === leaveId) {
          return {
            ...leave,
            status: selectedOption.value,
          };
        }
        return leave;
      })
    );
    setLeaveIds([{ leaveId: leaveId, status: selectedOption.label }]);
  };

  //Function to get selected user My Approvals from database.
  const fetchSelectedUserMyApprovals = async function (userId) {
    const myApprovalsResponse = await getSpecificUserApprovals(pageNo, pageSize, userId);
    if (myApprovalsResponse.statusCode === STATUS_CODES.SUCCESS) {
      const filterCancelledLeaves = myApprovalsResponse?.data?.filter(
        leave => leave.status !== LeaveStatus.Cancelled
      );
      setLeavesResponse(myApprovalsResponse);

      const myApprovals = filterCancelledLeaves;
      setLeaves(myApprovals);
      setTotalRecords(myApprovalsResponse?.totalRecords);
    } else showToast("error", res.message);
  };

  const handleMyApprovals = async selectedOption => {
    const myApprovalsResponse = await GetUserLeaves_UnderCurrentUser(
      pageNo,
      pageSize,
      true,
      selectedOption.value || paramUserId
    );
    const filterCancelledLeaves = myApprovalsResponse?.data?.filter(
      leave => leave.status !== LeaveStatus.Cancelled
    );
    setLeavesResponse(myApprovalsResponse);
    setSelectedUser(selectedOption);

    const myApprovals = filterCancelledLeaves;
    setLeaves(myApprovals);
    setTotalRecords(myApprovalsResponse?.totalRecords);
  };

  const onClickChangeLeaveStatus = async (leaveId, status) => {
    const res = await statusOfLeaves([{ leaveId, status }]);

    if (res.statusCode === STATUS_CODES.SUCCESS) showToast("success", ADD_NEW_LEAVE_MESSAGES.LEAVE_APPROVED);
    if (status === LeaveStatus.Rejected) showToast("success", ADD_NEW_LEAVE_MESSAGES.LEAVE_REJECTED);

    setLeaveModal(false);
    handleMyApprovals({
      value: +paramUserId,
      label: `${selectedUser.firstName} ${selectedUser.lastName}`,
    });
  };

  const handleLeaveAction = status => {
    onClickChangeLeaveStatus(notificationLeave.id, status);
  };

  return (
    <div className="ml-56">
      <PageSection heading="My Approvals" showIcon={true} icon={<BsShieldCheck size={"2rem"} />}>
        <Table>
          <TableHeader cols={myApprovalsHeading} />

          <tbody className={leaves.length === 0 ? "h-[8rem]" : "auto"}>
            {leaves.length > 0 &&
              leaves?.map(user => {
                const updatedUser = {
                  ...user,
                  timeDifference: approx_time_show(user.dateCreated),
                };
                return (
                  <TableRows key={user?.id}>
                    <TableData data={user?.user?.firstName + " " + user?.user?.lastName} />
                    <TableData>
                      {formatDate(user.leaveDate)} {isFirstHalf_SecondHalf(user)}
                    </TableData>
                    <TableData data={user?.leaveTypeName} />
                    <TableData
                      data-tooltip-id={"approx-time" + user?.id}
                      data-tooltip-content={dateTime(user?.dateCreated, true)}>
                      {approxTime_Badge(updatedUser)}
                      <Tooltip id={"approx-time" + user?.id} cursor="help" place="bottom" />
                    </TableData>
                    <TableData
                      id={"notes" + user?.id}
                      data-tooltip-content={user?.reason}
                      data-tooltip-id={"notes" + user?.id}
                      className={cn("px-4 py-2 whitespace-nowrap hover: cursor-help")}>
                      {user.reason && user.reason.length > 10
                        ? `${user.reason.slice(0, 14)}...`
                        : user.reason}
                      <Tooltip id={"notes" + user?.id} cursor="help" place="bottom" />
                    </TableData>
                    <TableData
                      className={cn("tc-dropdown px-4 py-2 whitespace-nowrap flex items-center")}>
                      <SelectWithDropdown
                        roundedDot={true}
                        backgroundColor={backgroundColor(user, LeaveStatus)}
                        className={defaultClassName(user, LeaveStatus)}
                        dotClassName={dotClassName(user, LeaveStatus)}
                        size="sm"
                        options={options(
                          roleId,
                          userId,
                          user,
                          statusOptions(user, LeaveStatus),
                          statusOptionsForNonAdmin(user, LeaveStatus),
                          statusOptionsForUser(user, LeaveStatus)
                        )}
                        hideMenu={
                          options(
                            roleId,
                            userId,
                            user,
                            statusOptions(user, LeaveStatus),
                            statusOptionsForNonAdmin(user, LeaveStatus),
                            statusOptionsForUser(user, LeaveStatus)
                          ).length === 0
                        }
                        defaultValue={setDefaultValue(user, LeaveStatus)}
                        isDefaultValue={setIsDefaultValue(user, LeaveStatus)}
                        handleChange={handleSelectChange}
                        otherOptions={user?.id}
                      />
                    </TableData>
                  </TableRows>
                );
              })}

            {/* ProTip */}
            <ProTipApprovals
              leaves={leaves}
              roleId={roleId}
              Role={Role}
              radioSelection={radioSelection}
            />
          </tbody>
        </Table>
        {/* Pagination for myApprovals*/}
        <PaginationComponent
          onChange={setPageSizeHandler}
          onPageChange={onPageChange}
          showIcons={true}
          totalRecords={leavesResponse?.totalRecords}
        />
      </PageSection>

      <ModalComponent
        show={leaveModal}
        onClose={() => setLeaveModal(false)}
        bodyClassName="pt-4 px-8 flex flex-col gap-4"
        showFooter={false}
        heading="Leave Info">
        <div className="flex items-center gap-4 bg-gray-100 px-3 py-2 rounded-md justify-center h-[5rem]">
          <h3 className="dark:text-white ">
            <span className="text-gray-900 font-semibold capitalize">
              {notificationLeave?.user?.firstName} {notificationLeave?.user?.lastName}
            </span>{" "}
            has applied for {notificationLeave?.leaveTypeName} for
            <span className="text-gray-900 font-semibold">
              {" "}
              {formatDate(notificationLeave?.leaveDate)}
              <span className="pl-2 font-bold  text-gray-400">({leaveType})</span>
            </span>
          </h3>
        </div>

        {notificationLeave?.reason && (
          <textarea
            disabled
            className={`${radioPrimaryClass} border-0 rounded-md px-2 py-1 focus-within:border-0`}
            defaultValue={notificationLeave?.reason}
          />
        )}

        <div className={`flex items-center mt-4 px-3 py-2 rounded-md ${colorClass}`}>
          Note: This Leave has {statusText}.
        </div>

        <div className="flex flex-wrap justify-end gap-2 mt-4">
          {(leaveStatus === LeaveStatus.WaitingForApproval ||
            leaveStatus === LeaveStatus.Rejected) && (
            <Button onClick={() => handleLeaveAction(LeaveStatus.Approved)} color="success">
              Approve leave
            </Button>
          )}
          {(leaveStatus === LeaveStatus.WaitingForApproval ||
            leaveStatus === LeaveStatus.Approved) && (
            <Button onClick={() => handleLeaveAction(LeaveStatus.Rejected)} color="failure">
              Reject Leave
            </Button>
          )}

          <Button onClick={() => setLeaveModal(false)} color="light">
            Close
          </Button>
        </div>
      </ModalComponent>
    </div>
  );
};
