import { useSelector } from "react-redux";
import { memo } from "react";
import { DetailCard } from "./components/detailCard/DetailCard";
import { cardOptions } from "./helpers";

const General = ({ user }) => {
  const { activeTab } = useSelector(state => state.profile.tabs);

  const isActive = activeTab === "general";

  const options = cardOptions(user);
  return (
    <>
      {isActive && (
        <div className="col-span-2 grid grid-cols-[repeat(auto-fit,_minmax(343px,1fr))] gap-x-[1rem]">
          {options.length > 0 &&
            options.map(item => {
              let { id, children, head } = item;
              return <DetailCard head={head} data={children} key={id} />;
            })}
        </div>
      )}
    </>
  );
};

export default memo(General);
