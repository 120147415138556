import React from "react";
import { deleteRole } from "../../../services/RolesService";
import toast from "react-hot-toast";
import DeleteModal from "../../common/Modal/DeleteModal";

const DeleteRole = ({ toggleRoleDetails, resetState, deleteId }) => {
  const onDeleteRoleDetails = async () => {
    const response = await deleteRole(deleteId);
    if (response.data.statusCode === 200) {
      toast.success("Role deleted successfully");
      resetState();
    } else {
      toast.error("Error");
    }
  };

  const modalConfigs = {
    show: toggleRoleDetails.isDeleteRoleDetails,
    size: "md",
    onClose: resetState,
    showFooter: false,
    bodyClassName: "gap-4 mt-2",
  };

  return (
    <DeleteModal
      modalConfigs={modalConfigs}
      onDelete={onDeleteRoleDetails}
      onCancel={resetState}
      showIcon={true}
    />
  );
};

export default DeleteRole;
