import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { AuthContextProvider } from "./context/AuthContext";
import { PaymentResponseContextProvider } from "./context/PaymentResponseContext";
import { InvoiceContextProvider } from "./context/InvoiceContext";
import { Provider } from "./context/createFastContext";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import 'primeicons/primeicons.css';
import { PrimeReactProvider } from 'primereact/api';
import 'primereact/resources/themes/saga-blue/theme.css'; // Choose your desired theme
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
//redux store
import { Provider as ReduxProvider } from "react-redux";
import { store } from "./store/store";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <PrimeReactProvider>
      <ReduxProvider store={store}>
        <BrowserRouter>
          <Provider>
            <AuthContextProvider>
              <InvoiceContextProvider>
                <PaymentResponseContextProvider>
                  <App />
                </PaymentResponseContextProvider>
              </InvoiceContextProvider>
            </AuthContextProvider>
          </Provider>
        </BrowserRouter>
      </ReduxProvider>
    </PrimeReactProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
