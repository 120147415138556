export const UserSvg = ({ _size }) => {
  let size = "w-7 h-7";
  if (_size) {
    size = _size;
  }
  return (
    <svg
      version="1.0"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      className={size}
      viewBox="0 0 512.000000 512.000000"
      preserveAspectRatio="xMidYMid meet">
      <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" stroke="none">
        <path
          d="M2405 4309 c-293 -43 -564 -205 -744 -444 -67 -89 -154 -267 -180
-368 -113 -440 31 -890 374 -1165 l76 -61 -63 -32 c-525 -262 -867 -769 -904
-1339 l-7 -100 161 0 162 0 0 41 c0 82 32 256 65 356 68 204 158 348 314 503
58 58 134 125 168 148 l62 42 38 -72 c56 -109 191 -240 303 -296 121 -60 193
-76 330 -76 137 0 209 16 330 76 112 56 247 187 303 296 l38 72 62 -42 c34
-23 110 -90 168 -148 156 -155 246 -299 314 -503 33 -100 65 -274 65 -356 l0
-41 162 0 161 0 -7 102 c-36 560 -378 1073 -886 1328 l-81 41 76 61 c201 161
339 393 397 663 16 77 16 333 0 410 -103 486 -474 836 -957 905 -83 11 -216
11 -300 -1z m360 -334 c155 -42 322 -152 420 -277 319 -407 183 -995 -281
-1219 -393 -190 -874 -20 -1064 375 -60 126 -74 190 -74 346 0 152 13 216 70
340 109 236 344 411 609 454 57 9 260 -3 320 -19z m-45 -1905 c99 -13 210 -40
210 -51 0 -21 -40 -86 -80 -131 -27 -32 -67 -60 -118 -85 -72 -36 -81 -38
-172 -38 -91 0 -100 2 -172 38 -51 25 -91 53 -118 85 -40 45 -80 110 -80 131
0 11 110 39 200 50 91 12 241 12 330 1z"
        />
      </g>
    </svg>
  );
};
