import InfoIconSvg from "../../../media/InfoIconSvg";

/**
 * Renders an in-modal message component with a given message and optional styles.
 *
 * @param {{message?: string, styles: {parentClass?: string, messageContClass?: string, iconClass?: string, messageClass?: string}, children?: ReactNode}} props - The properties for the component
 * @param {string?} message - The message to display in the component
 * @param {{parentClass?: string, messageContClass?: string, iconClass?: string, messageClass?: string}} styles - Optional styles for the component
 * @param {ReactNode?} children - Optional children elements to include in the component
 * @return {ReactNode} The rendered in-modal message component
 */
const InModalMessage = ({ message, styles, children }) => {
  return (
    <div
      className={`${styles?.parentClass} flex items-center gap-2 mx-auto bg-red-200 px-4 py-2 w-full text-red-800 font-semibold my-6 rounded-md mb-4`}>
      <div className={`${styles?.messageContClass} flex items-center gap-2`}>
        <InfoIconSvg className={`${styles?.iconClass} fill-current`} />
        {message && <span className={styles?.messageClass}>{message}</span>}
      </div>
      {children}
    </div>
  );
};

export default InModalMessage;
