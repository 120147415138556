import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { RxCross2 } from "react-icons/rx";

import { getSkillsList } from "../../../../store/slices/addSkillSlice";

/**
 * Renders a list of skills.
 *
 * @return {JSX.Element} The rendered list of skills.
 */
const SkillList = () => {
  const { skills } = useSelector(state => state.skills);
  const { allSkills, filteredSkills } = skills;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSkillsList());
  }, []);

  return (
    <>
      {allSkills.length === 0 && (
        <h5 className="font-semibold mx-auto text-md tracking-wide text-gray-700">
          No skills added!
        </h5>
      )}
      {filteredSkills.length === 0 && allSkills.length > 0 && (
        <>
          {allSkills.map(skill => (
            <li
              key={skill.id}
              data-id={JSON.stringify([skill.id, "update"])}
              className="flex justify-between cursor-pointer items-center gap-3 hover:outline hover:outline-2 hover:outline-thynkwebPrimary-600 focus:outline-2 focus:outline focus:outline-thynkwebPrimary-600 p-2 rounded-md bg-white hover:bg-gray-100 focus:bg-gray-100 dark:bg-gray-500 w-fit">
              <span>{skill.name}</span>
              <span
                data-id={JSON.stringify([skill.id, "delete"])}
                className="block cursor-pointer p-1 rounded-md bg-white hover:bg-gray-200 focus:bg-gray-200">
                <RxCross2 color="red" />
              </span>
            </li>
          ))}
        </>
      )}

      {/* Filtered Skills */}
      {filteredSkills.length > 0 && allSkills.length > 0 && (
        <>
          {filteredSkills.map(skill => (
            <li
              key={skill.id}
              data-id={`${JSON.stringify([skill.id, "update"])}`}
              className="flex justify-between cursor-pointer items-center gap-3 hover:outline hover:outline-2 hover:outline-thynkwebPrimary-600 focus:outline-2 focus:outline focus:outline-thynkwebPrimary-600 p-2 rounded-md bg-white hover:bg-gray-100 focus:bg-gray-100 dark:bg-gray-500 w-fit">
              <span>{skill.name}</span>
              <span
                data-id={`${JSON.stringify([skill.id, "delete"])}`}
                className="block cursor-pointer p-1 rounded-md bg-white hover:bg-gray-200 focus:bg-gray-200">
                <RxCross2 color="red" />
              </span>
            </li>
          ))}
        </>
      )}
    </>
  );
};

export default SkillList;
