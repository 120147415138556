import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "flowbite-react";

import { RxCross2 } from "react-icons/rx";
import ModalComponent from "../../common/Modal/Modal";

import { removeFile } from "../../../store/slices/employment";
import { btnPrimary } from "../../../utils/helper";
import { FileTypes, FileTypesLabel } from "../../../enum/FileTypes";


/**
 * Renders the UI for an uploaded image.
 *
 * @return {ReactElement} The rendered UI component.
 */
const UploadedImage = () => {
  const [show, setShow] = useState(false);
  const { imageDetails, docType } = useSelector(state => state.employment.user);
  const { fileName, fileUrl } = imageDetails;
  const dispatch = useDispatch();

  const fileType = imageDetails.fileType.split("/")[1];

  const onClickHandler = e => {
    const elem = e.target.closest("[data-image]");
    if (!elem) return;

    const dataset = elem.dataset.image;

    //return for now, will add the functionality later
    if (dataset === "img") setShow(true);
    if (dataset === "delete") dispatch(removeFile());
  };

  return (
    <>
      {fileName && (
        <>
          <div
            onClick={onClickHandler}
            data-image="img"
            className="flex justify-between items-center gap-4 text-gray-700 bg-gray-200 dark:text-gray-400 rounded-lg cursor-pointer">
            <span className="font-semibold tracking-[0.5px] p-4">
              {docType.type === FileTypes.Other ? docType.otherType : FileTypesLabel[docType.type]}{" "}
              - {fileName}
            </span>
            <div
              data-image="delete"
              className="p-1 mr-4 h-8 w-8 text-[1.2rem] font-bold flex items-center justify-center text-gray-800  bg-gray-300 hover:bg-gray-400 focus:bg-slate-400 dark:text-gray-400 rounded-md">
              <RxCross2 />
            </div>
          </div>

          <ModalComponent
            show={show}
            position="center"
            size="lg"
            heading="Uploaded Document"
            onClose={setShow.bind(null, false)}
            showFooter={false}>
            <div className="md:w-full md:h-[20rem] lg:[70vh] mt-4 text-center mx-auto">
              {fileType !== "pdf" && (
                <img className="w-full h-full object-contain" src={fileUrl} alt={fileName} />
              )}
              {fileType === "pdf" && <embed src={fileUrl} className="h-full w-full" />}
            </div>

            <Button
              onClick={setShow.bind(null, false)}
              className={`${btnPrimary()} !p-0 ml-auto mt-7`}>
              Close
            </Button>
          </ModalComponent>
        </>
      )}
    </>
  );
};

export default UploadedImage;
