import { Select, Label, TextInput } from "flowbite-react";
/**
 * Renders the bank details form based on the active item.
 *
 * @param {{activeItem: string, details: object, handleAddNewClientData: Function}} props
 * @param {string} activeItem - The active item in the form.
 * @param {object} details - The details object containing bank details.
 * @param {Function} handleAddNewClientData - The function to handle adding new client data.
 * @return {JSX.Element} The bank details form.
 */
const BankDetails = ({ activeItem, details, handleAddNewClientData }) => {
  const isActive = activeItem === "bankDetails";
  return (
    <>
      {isActive && (
        <div className="grid gap-4 mb-7 md:grid-cols-2 md:auto-rows-min sm:grid-cols-1 pt-4">
          <div>
            <div className="mb-2 block">
              <Label
                htmlFor="business accountHolderName"
                value="Account Holder Name"
              />
            </div>
            <TextInput
              id="business accountHolderName"
              type="text"
              name="accountHolderName"
              value={details.accountHolderName || ""}
              placeholder="Account Holder Name ( Optional )"
              shadow={true}
              onChange={handleAddNewClientData}
            />
          </div>

          <div>
            <div className="mb-2 block">
              <Label
                htmlFor="Business accountNumber"
                value="Account Number"
              />
            </div>
            <TextInput
              id="Business accountNumber"
              type="number"
              name="accountNumber"
              placeholder="Account Number ( Optional )"
              shadow={true}
              value={details.accountNumber || ""}
              onChange={handleAddNewClientData}
            />
          </div>
          <div>
            <div className="mb-2 block">
              <Label
                htmlFor="Business ifsc"
                value="IFSC Code"
              />
            </div>
            <TextInput
              id="Business ifsc"
              type="text"
              name="ifsc"
              placeholder="IFSC Code ( Optional )"
              shadow={true}
              value={details.ifsc || ""}
              onChange={handleAddNewClientData}
            />
          </div>
          <div>
            <div className="mb-2 block">
              <Label
                htmlFor="Business accountType"
                value="Account Type"
              />
            </div>
            <Select
              id="Business accountType"
              required={true}
              name="accountType"
              value={details?.accountType || ""}
              onChange={handleAddNewClientData}>
              <option hidden={true}>Select Account Type ( Optional )</option>
              <option value="savings account">Savings Account</option>
              <option value="current account">Current Account</option>
            </Select>
          </div>
          <div>
            <div className="mb-2 block">
              <Label
                htmlFor="Business bank"
                value="Bank Name"
              />
            </div>
            <TextInput
              id="Business bank"
              type="text"
              name="bank"
              placeholder="Bank Name ( Optional )"
              shadow={true}
              value={details.bank || ""}
              onChange={handleAddNewClientData}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default BankDetails;
