import { useState } from "react";
import Gear from "../../media/GearSvg";
import { NavLink } from "react-router-dom";

import { dashboardOptions } from "./helper";
import styles from "../Layout/Sidebar/Sidebar.module.css";
import Sidebar from "../Layout/LeftMenu/LeftMenu";
import { toggleGlobalSettingsModal } from "../../store/slices/settingsSlice";
import { useAppDispatch } from "../../store/store";

const activeLinkStyles = `${styles["sidebaritems--active"]} !mb-0 !px-[.7rem] !py-1`;
const inActiveLinkStyles = `${styles["sidebaritems"]} !mb-0 !px-[.7rem] !py-1`;
/**
 * Renders the sidebar for administration purposes.
 *
 * @return {JSX.Element} The rendered sidebar component.
 */
export const SidebarAdministration = () => {
  const [isModalActive, setToggleModal] = useState(false);

  const dispatch = useAppDispatch();

  const openModal = () => {
    setToggleModal(true);
    dispatch(toggleGlobalSettingsModal(true));
  };

  return (
    <Sidebar sidebarListItem={dashboardOptions} isObjectContainParent={true}>
      <li
        onClick={openModal}
        style={{ cursor: "pointer" }}
        className={`${inActiveLinkStyles} !px-[.7rem] !py-3 mx-2 !mb-0 mt-auto`}>
        <Gear className="text-[1.7rem] w-[20px] h-[20px]" />
        <span>Settings</span>
      </li>
    </Sidebar>
  );
};
