import React from "react";
import DeleteSvg from "../../../media/DeleteSvg";
import TableData from "../../common/Table/TableData";
import MemoizedNoItemFound from "../../NoItemFound/Noitemfound";
import { FaRegEdit, FaRegTrashAlt } from "react-icons/fa";

const ClientLists = ({ clients, setClientUpdate, openDeleteModal }) => {
  return (
    <>
      <MemoizedNoItemFound
        isTable={true}
        colSpan={5}
        itemsArr={clients}
        message={"No Clients added!"}
      />
      {clients.length > 0 &&
        clients?.map(client => (
          <tr
            className="h-10 border-b dark:border-gray-600 hover:bg-gray-100 dark:hover:bg-gray-700"
            key={client.id}>
            <TableData data={client.name} />
            <TableData data={client.aliasName} />
            <TableData data={client.email} />
            <TableData data={client.industry} />
            <TableData data={client.totalInvoices} />
            <TableData>
              <div className="flex gap-3">
                <button
                  onClick={() => setClientUpdate(client.id)}
                  className="hover:text-thynkwebPrimary-800">
                  <FaRegEdit />
                </button>
                <div
                  data-modal-target="popup-modal"
                  data-modal-toggle="popup-modal"
                  onClick={() => openDeleteModal(client.id, client.name)}
                  className="cursor-pointer hover:text-red-400">
                  <FaRegTrashAlt />
                </div>
              </div>
            </TableData>
          </tr>
        ))}
    </>
  );
};

export default ClientLists;
