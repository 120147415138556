export const statusOptions = (user, LeaveStatus) => {
  let options;
  switch (user?.status) {
    case LeaveStatus.Approved:
      options = [
        {
          value: 4,
          label: "Cancelled",
          className: "text-red-800 rounded-lg -ml-5 mr-2",
          dotClassName: "bg-red-500 border rounded-full",
          backgroundColor: "bg-red-100 w-[150px]",
          itemClassName: "w-[150px]",
        },
      ];
      break;

    case LeaveStatus.Cancelled:
      options = [
        {
          value: 1,
          label: "Approved",
          className: "text-green-800 rounded-lg -ml-2 mr-2",
          dotClassName: "w-3 h-3 bg-green-500 border pl-0.5 rounded-full",
          backgroundColor: "bg-green-100 w-[150px]",
          itemClassName: "w-[150px]",
        },
      ];
      break;

    default:
      options = [
        {
          value: 1,
          label: "Approved",
          className: "text-green-800 rounded-lg -ml-2 mr-2",
          dotClassName: "w-3 h-3 bg-green-500 border pl-0.5 rounded-full",
          backgroundColor: "bg-green-100 w-[150px]",
          itemClassName: "w-[150px]",
        },
        {
          value: 4,
          label: "Cancelled",
          className: "text-red-800 rounded-lg -ml-5 mr-2",
          dotClassName: "bg-red-500 border rounded-full",
          backgroundColor: "bg-red-100 w-[150px]",
          itemClassName: "w-[150px]",
        },
      ];
  }
  return options;
};

export const statusOptionsForNonAdmin = (user, LeaveStatus) => {
  let options;
  switch (user?.status) {
    case LeaveStatus.Approved:
      options = [
        {
          value: 3,
          label: "Rejected",
          className: "text-red-800 rounded-lg -ml-3 mr-2",
          dotClassName: "w-3 h-3 bg-red-500 border pl-0.5 rounded-full",
          backgroundColor: "bg-red-100 w-[150px]",
          itemClassName: "w-[150px]",
        },
      ];

      break;

    case LeaveStatus.Rejected:
      options = [
        {
          value: 1,
          label: "Approved",
          className: "text-green-800 rounded-lg -ml-2 mr-2",
          dotClassName: "w-3 h-3 bg-green-500 border pl-0.5 rounded-full",
          backgroundColor: "bg-green-100 w-[150px]",
          itemClassName: "w-[150px]",
        },
      ];

      break;

    default:
      options = [
        {
          value: 1,
          label: "Approved",
          className: "text-green-800 rounded-lg -ml-2 mr-2",
          dotClassName: "w-3 h-3 bg-green-500 border pl-0.5 rounded-full",
          backgroundColor: "bg-green-100 w-[150px]",
          itemClassName: "w-[150px]",
        },
        {
          value: 3,
          label: "Rejected",
          className: "text-red-800 rounded-lg -ml-3 mr-2",
          dotClassName: "w-3 h-3 bg-red-500 border pl-0.5 rounded-full",
          backgroundColor: "bg-red-100 w-[150px]",
          itemClassName: "w-[150px]",
        },
      ];
  }
  return options;
};

export const statusOptionsForUser = (user, LeaveStatus) => {
  if (user?.status === LeaveStatus.WaitingForApproval) {
    return [
      {
        value: 4,
        label: "Cancelled",
        className: "text-red-800 rounded-lg -ml-5 mr-2",
        dotClassName: "bg-red-500 border rounded-full",
        backgroundColor: "bg-red-100 w-[150px]",
        itemClassName: "w-[150px]",
      },
    ];
  } else {
    return []; // Return empty array when there are no options
  }
};

export const createDropDownOptions = leaveTypes => {
  return leaveTypes.map(leaveType => {
    return {
      value: leaveType.id,
      label: leaveType.name,
      className: "-pl-8 w-[16rem] border-1 border-red-300",
      dotClassName: "",
      backgroundColor: "bg-gray-100 h-[2.5rem] rounded-md ",
      itemClassName: "w-[18.5rem]",
      isFlexi: leaveType.isEligibleOnFlexiHoliday
    };
  });
};

export const ReportingUsers = reportingUsers => {
  return reportingUsers.map(reportingUser => {
    return {
      value: reportingUser.id,
      label: reportingUser.firstName + " " + (reportingUser.lastName ? reportingUser.lastName : ""),
      className: "-pl-8 w-[120px]",
      dotClassName: "",
      backgroundColor: "bg-gray-100",
      itemClassName: "w-[170px]",
    };
  });
};

export const customStyles = () => ({
  control: baseStyles => ({
    ...baseStyles,
    cursor: "pointer",
    width: "160px",
    fontWeight: 400,
    fontSize: 16,
    color: "#666666",
    minHeight: 25,
  }),
  dropdownIndicator: base => ({
    ...base,
    color: "#666666",
  }),
  option: (base, { isSelected }) => ({
    ...base,
    fontWeight: 400,
    background: isSelected ? "#9d9fa5" : "",
    "&:hover": {
      cursor: "pointer",
    },
  }),
  menu: provided => ({
    ...provided,
    minHeight: 30,
  }),
});

export const proTip = (roleId, Role, radioSelection) => {
  let message;
  let combination = `${roleId}-${radioSelection}`;
  let admin = `${Role.Administrator}`;
  let member = `${Role.Member}`;
  switch (combination) {
    case `${admin}-myLeaves`:
      message = "Status can be changed to either Approved or Cancelled.";
      break;

    case `${admin}-myApprovals`:
      message = "Status can be changed to either Approved or Rejected.";
      break;

    case `${member}-myApprovals`:
      message = "Status can be changed to either Approved or Rejected.";
      break;

    case `${member}-myLeaves`:
      message =
        "Status can be changed only when in pending state. Can be changed to cancelled only.";
      break;
  }
  return message;
};
