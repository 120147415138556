import { v4 } from "uuid";

export const checkboxOptions = [
  {
    id: v4(),
    checked: true,
    name: "reduceLeaveOnAbsent",
    label: "Reduce Leave On Absent",
  },

  {
    id: v4(),
    checked: false,
    name: "allowNegativeLeaves",
    label: "Allow Negative Leaves",
  },

  {
    id: v4(),
    checked: false,
    name: "isPaid",
    label: "Is Paid",
  },
];
