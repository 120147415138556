
/**
 * Renders a mandatory sign component.
 *
 * @param {{className: string}} props - The component props.
 * @param {string} props.className - The class name for the component.
 * @return {JSX.Element} The mandatory sign component.
 */
const MandatorySign = ({ className }) => {
  return <span className={`${className} text-red-700 ml-1`}>*</span>;
};

export default MandatorySign;
