import { Button } from "flowbite-react";
import React, { memo } from "react";

const Dialog = ({ _propsState, btn_styles, heading, body }) => {
  const { btn_text, cancel_text } = btn_styles;
  const { isOpen, onClose, onClick } = _propsState;
  return (
    <div
      id="info-popup"
      className={`${
        isOpen ? "" : "hidden"
      } bg-gray-900 bg-opacity-50 overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-modal md:h-full`}>
      <div className="mx-[auto] p-4 w-full max-w-lg h-full md:h-auto">
        <div className="bg-white rounded-lg shadow dark:bg-gray-800">
          <div className="mb-4 text-sm text-center font-light text-gray-500 dark:text-gray-400">
            {heading && <Heading heading={heading} />}
            {body && <Body body={body} />}
          </div>
          <div className="justify-end items-center pt-0 space-y-4 sm:flex sm:space-y-0">
            <div className="items-center p-4 space-y-4 sm:space-x-4 sm:flex sm:space-y-0">
              <Button
                onClick={onClick}
                color="failure">
                {btn_text ?? "Button"}
              </Button>
              <Button
                color="gray"
                onClick={onClose}>
                {cancel_text ?? "Cancel"}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(Dialog);

const Heading = memo(({ heading }) => {
  return (
    <>
      <h3 className="mx-auto py-4 border-b border-gray-200 text-2xl font-bold text-gray-900 dark:text-white">
        {heading}
      </h3>
    </>
  );
});

const Body = memo(({ body }) => {
  return (
    <>
      <p className="mx-auto py-3 font-semibold">{body}</p>
    </>
  );
});
