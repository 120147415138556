import { useContext, useEffect, useState } from "react";
import Slider from "react-slick";
import { LeaveListViewResponsive, leaveTypeImageIcons } from "./helper";
import { getBalanceReports } from "../../services/LeaveService";
import { AuthContext } from "../../context/AuthContext";

const CardViewLeaves = () => {
  //State to store leave balance details.
  const [leaveBucketsData, setLeaveBucketsData] = useState([]);
  const { user: currentUser } = useContext(AuthContext);

  //useEffect hook for fetching data
  useEffect(() => {
    fetchLeaveBuckets();
  }, []);

  //Function to get all available leave balances for the logged in user
  const fetchLeaveBuckets = () => {
    getBalanceReports(currentUser.id).then(response => {
      setLeaveBucketsData(response.data.data);
    });
  };

  const firstItem = leaveBucketsData.length > 0 ? leaveBucketsData[0] : null;

  const noLeaves = firstItem && firstItem.leaveBuckets.length === 0;

  return (
    <div className="w-[99%] absolute">
      {noLeaves && (
        <div className="flex items-center justify-center font-semi bold text-2xl text-gray-400">
          Currently no leave associated with you
        </div>
      )}

      {firstItem && (
        <Slider {...LeaveListViewResponsive}>
          {firstItem.leaveBuckets.map(bucket => {
            return (
              <div className="!flex !w-[14.5rem] justify-start" key={bucket.leaveTypeId}>
                <div
                  className={`!w-full !h-full bg-white mr-8 border-gray-200 py-6 px-4 border rounded-lg shadow dark:bg-gray-800 dark:border-gray-700`}>
                  <p className="flex justify-center items-center mb-2 text-lg font-bold tracking-tight text-gray-500 dark:text-white">
                    {bucket.leaveType.name}
                  </p>
                  <p className="flex justify-center items-center">
                    {leaveTypeImageIcons.map(iconObject => {
                      // Creating a new object with the updated size
                      const updatedIconComponent = {
                        ...iconObject.iconComponent,
                        props: {
                          ...iconObject.iconComponent.props,
                          size: 32,
                        },
                      };
                      if (iconObject.iconName === bucket.leaveType.icon) {
                        return (
                          <p
                            style={{
                              color: `${bucket.leaveType.colorCode}`,
                              marginBottom: "0.4rem",
                            }}
                            key={iconObject.id}>
                            {updatedIconComponent}
                          </p>
                        );
                      }
                    })}
                  </p>

                  <p className="flex justify-center items-center">
                    Available: {bucket.availableLeaves}
                  </p>
                  <p className="flex justify-center items-center">Booked: {bucket.plannedLeaves + bucket.usedLeaves}</p>
                </div>
              </div>
            );
          })}
        </Slider>
      )}
    </div>
  );
};

export default CardViewLeaves;
