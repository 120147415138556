import { memo } from "react";
import OrganizationDetailsContent from "./OrganizationDetailsContent";
import Settings from "./Settings";

export const Content = memo(({ activeStep }) => {
  switch (activeStep) {
    case "orgDetails":
      return (
        <Container>
          <OrganizationDetailsContent />
        </Container>
      );
    case "settings":
      return (
        <Container>
          <Settings />
        </Container>
      );
    case "finish":
      return null;
    default:
      return null;
  }
});

const Container = memo(({ children }) => {
  return (
    <div className="max-w-[35rem] mx-auto mt-[3.5rem] px-[1rem] py-[1.5rem] bg-white sm:rounded-lg">
      {children}
    </div>
  );
});
