import { axiosInstance as axios } from "../context/AuthContext";

const userBaseUrl = process.env.REACT_APP_BASE_URL;

export const GetUserAddresses = async (
  pageNumber,
  pageSize,
  searchText,
  isClient = true
) => {
  return await axios
    .get(
      `${userBaseUrl}/Addresses?pageNumber=${pageNumber}&pageSize=${pageSize}&searchText=${searchText}&isClient=${isClient}`
    )
    .then((response) => {
      return response.data;
    });
};

export const AddNewAddress = async (newAddress) => {
  const res = await axios.post(`${userBaseUrl}/Addresses`, newAddress);
  return res.data;
};

export const getAddressById = async (addressId) => {
  const res = await axios.get(`${userBaseUrl}/addressId=${addressId}`);
  return res.data;
};

export const deleteAddress = async (addressId) => {
  const res = await axios.delete(`${userBaseUrl}/addressId=${addressId}`);
  return res.data;
};

export const UpdateAddress = async (newAddress) => {
  return await axios
    .put(`${userBaseUrl}/Addresses`, newAddress)
    .then((response) => response.data);
};
