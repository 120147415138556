export const inputArray = (quickLinkDetails) => {
  return  [
    {
      id: "title",
      label: "Title",
      type: "text",
      name: "name",
      placeholder: "Enter link title",
      required: true,
      otherProps: {
        value: quickLinkDetails.name
      }
    },
    {
      id: "quicklinkurl",
      label: "URL",
      type: "text",
      name: "url",
      placeholder: "Enter link URL",
      required: true,
      otherProps: {
        value: quickLinkDetails.url
      }
    },
  ]
}

export const inputClassName = "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-thynkwebPrimary-600 focus:border-thynkwebPrimary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-thynkwebPrimary-500 dark:focus:border-thynkwebPrimary-500"