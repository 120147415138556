import React from "react";
import DeleteModal from "../../common/Modal/DeleteModal";
import { deleteJobById } from "../../../services/JobService";
import { STATUS_CODES } from "../../../constants/Constant";
import { showToast } from "../../common/Toast";
import { JOB_MESSAGES } from "../../../constants/Messages";

/**
 * Delete a job and show a modal to confirm the deletion.
 *
 * @param {object} toggleJobDetails - object containing details to toggle job
 * @param {string} deleteId - the ID of the job to be deleted
 * @param {function} resetStates - function to reset state
 * @return {void}
 */

const DeleteJob = ({ toggleJobDetails, deleteId, resetStates }) => {
  const deleteJob = async () => {
    const response = await deleteJobById(deleteId);
    if (response.data.statusCode === STATUS_CODES.SUCCESS) {
      showToast("success", JOB_MESSAGES.DELETE);
      resetStates();
    } else {
      showToast("error", JOB_MESSAGES.RESPONSE_ERROR);
    }
  };

  const modalConfigs = {
    show: toggleJobDetails.isDeleteJobDetails,
    size: "md",
    onClose: resetStates,
    showFooter: false,
    bodyClassName: "gap-4 mt-2",
  };

  return (
    <DeleteModal
      modalConfigs={modalConfigs}
      onDelete={deleteJob}
      onCancel={resetStates}
      showIcon={true}
    />
  );
};

export default DeleteJob;
