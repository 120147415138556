import { useEffect, useState } from "react";
import { getEmploymentAssociationsById } from "../../../services/LeaveService";
import { GetEmploymentTypeById, deleteEmploymentTypes } from "../../../services/UserService";
import { DeleteEntity } from "../../../components/common/DeleteEntity";

export const EditEmploymentTypeBody = ({
  employmentTypeId,
  employmentTypeName,
  setIsChange,
  isChange,
  onClose,
  renderEditEmploymentTypeModal,
  handleEditEmploymentType,
  handleLeaveTypes,
  handleSubmit,
  formRef,
}) => {
  const [employmentType, setEmploymentType] = useState();
  const [employmentAssociationById, setEmploymentAssociationById] = useState();

  const getEmploymentTypeById = async () => {
    const response = await GetEmploymentTypeById(employmentTypeId);

    const employmentTypeById = response?.data?.data;
    setEmploymentType(employmentTypeById);
  };

  const GetEmploymentAssociationsById = async () => {
    const response = await getEmploymentAssociationsById(employmentTypeId);

    const employmentAssociation = response?.data;
    setEmploymentAssociationById(employmentAssociation);
  };

  useEffect(() => {
    getEmploymentTypeById();
    GetEmploymentAssociationsById();
    sessionStorage.removeItem("renderAddEmploymentType");
  }, [renderEditEmploymentTypeModal]);

  return (
    <section className="bg-white mt-4 dark:bg-gray-900">
      <div className="-mb-12 px-4 mx-auto max-w-2xl lg:py-4 m-auto overflow-x-hidden overflow-y-auto">
        <form ref={formRef}>
          <div className="grid gap-4 mb-4 md:gap-6 md:grid-cols-2 sm:mb-8">
            {/* Employment Type Name*/}
            <div>
              <label
                htmlFor="employment-type-name"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                Employment Type Name
              </label>

              <input
                type="text"
                name="name"
                defaultValue={employmentType?.name}
                id="employment-type-name"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-thynkwebPrimary-600 focus:border-thynkwebPrimary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-thynkwebPrimary-500 dark:focus:border-thynkwebPrimary-500"
                placeholder="Employment Type Name"
                onChange={handleEditEmploymentType}
              />
            </div>
          </div>

          {/* Employment Type Description */}
          <div className="mt-4">
            <label
              htmlFor="employment-type-description"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
              Description
            </label>

            <textarea
              type="text"
              name="description"
              defaultValue={employmentType?.description}
              rows="3"
              id="employment-type-description"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-thynkwebPrimary-600 focus:border-thynkwebPrimary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-thynkwebPrimary-500 dark:focus:border-thynkwebPrimary-500"
              placeholder="Employment type description"
              onChange={handleEditEmploymentType}
            />
          </div>

          <div className="mt-6">
            <label
              htmlFor="leave-types"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
              Leave Types :
            </label>
          </div>
          <div className="grid gap-4 mt-2 mb-4 md:gap-6 md:grid-cols-2 sm:mb-8">
            {employmentAssociationById?.leaveTypes.map((type, i) => {
              return (
                <div key={i}>
                  <label
                    htmlFor={`leave-type-${i}`}
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                    <span>{type?.leaveTypeName}</span>{" "}
                    <span className="mb-2 text-sm font-medium text-gray-500">
                      <i>(leaves allowed in a year)</i>
                    </span>
                  </label>

                  <input
                    type="number"
                    name="leavesAllowedInYear"
                    defaultValue={type?.leavesAllowedInYear}
                    id={`leave-type-${i}`}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-thynkwebPrimary-600 focus:border-thynkwebPrimary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-thynkwebPrimary-500 dark:focus:border-thynkwebPrimary-500"
                    placeholder={`${type?.leaveTypeName} (leaves allowed in year)`}
                    onChange={e => handleLeaveTypes(e, type?.leaveTypeId, type?.leaveTypeName)}
                  />
                </div>
              );
            })}
          </div>
        </form>
        {/* Button section */}
        <div className="mb-2 flex justify-end gap-2">
          {/* Button to delete Employment Type */}
          <DeleteEntity
            id={employmentTypeId}
            setIsChange={setIsChange}
            isChange={isChange}
            closeOtherModal={onClose}
            onchange={deleteEmploymentTypes}
            name={employmentTypeName}
            className="px-5 py-2.5 mt-8 text-sm font-medium text-center text-white bg-red-700 rounded-lg hover:bg-red-800"
            successToastMessage={`${employmentTypeName} successfully deleted`}
          />

          {/* Button to Update Employment Type */}
          <button
            type="submit"
            onClick={handleSubmit}
            className="px-5 py-2.5 mt-8 text-sm font-medium text-center text-white bg-thynkwebPrimary-700 rounded-lg focus:ring-4 focus:ring-thynkwebPrimary-200 dark:focus:ring-thynkwebPrimary-900 hover:bg-thynkwebPrimary-800">
            Update
          </button>
        </div>
      </div>
    </section>
  );
};
