import { createSlice } from "@reduxjs/toolkit";
import { getAllSkills } from "./../../services/SkillsService";
import { createReactSelectOptions } from "../../utils/helper";
import {
  addUserSkill,
  deleteUserSkill,
  getAllUserSkills,
  updateUserSkill,
} from "../../services/UserSkillService";
import toast from "react-hot-toast";

const imageDetails = {
  id: 0,
  fileType: "",
  fileName: "",
  file: "",
  docType: "",
  fileUrl: "",
};

const docType = {
  id: "doctype",
  otherType: "",
  type: 0,
};

const skills = {
  options: [],
  selectedSkills: [],
  rawSelectedSkill: [],
};

const initialState = {
  user: {
    imageDetails,
    docType,
    skills,
  },
};

const employment = createSlice({
  name: "employment",
  initialState,
  reducers: {
    setFile: (state, action) => {
      const { otherType, type } = state.user.docType;
      const { fileName, fileType, file, fileUrl } = action.payload;
      state.user.imageDetails = {
        ...state.user.imageDetails,
        fileType,
        fileName,
        file,
        fileUrl,
        //add docType in image details object so every doc type is known
        docType: otherType ? otherType : type,
      };
    },
    removeFile: state => {
      state.user.imageDetails = imageDetails;
    },

    setDocType: (state, action) => {
      const type = action.payload;
      state.user.docType = {
        ...state.user.docType,
        type: type,
        //reset the otherType to empty string if user change the doc type
        otherType: "",
      };
    },
    setOtherDocType: (state, action) => {
      const type = action.payload;
      state.user.docType = {
        ...state.user.docType,
        otherType: type,
      };
    },

    setSkillsOptions: (state, action) => {
      const options = action.payload;

      state.user.skills.options = options;
    },

    setSelectedUserSkills: (state, action) => {
      const options = createReactSelectOptions(action.payload, "skillId");
      state.user.skills.selectedSkills = options;
      state.user.skills.rawSelectedSkill = action.payload;
    },

    addedSkills: (state, action) => {
      state.user.skills.selectedSkills = [...state.user.skills.selectedSkills, action.payload];
      state.user.skills.rawSelectedSkill = [...state.user.skills.rawSelectedSkill, action.payload];
    },

    updateSkills: (state, action) => {
      const updatedSkills = state.user.skills.rawSelectedSkill.map(skill => {
        if (skill.skillId === action.payload.skillId) {
          return {
            ...skill,
            ...action.payload,
          };
        }
        return skill;
      });
      state.user.skills = {
        ...state.user.skills,
        selectedSkills: updatedSkills,
        rawSelectedSkill: updatedSkills,
      };
    },

    removeSkill: (state, action) => {
      const { rawSelectedSkill } = state.user.skills;
      const filteredSkills = rawSelectedSkill.filter(skill => {
        return skill.skillId !== +action.payload.skillId && skill.userId === +action.payload.userId;
      });

      state.user.skills = {
        ...state.user.skills,
        selectedSkills: filteredSkills,
        rawSelectedSkill: filteredSkills,
      };
    },

    removeAllSkill: state => {
      state.user.skills = {
        ...state.user.skills,
        selectedSkills: [],
      };
    },

    resetEmploymentStates: state => {
      state.user = {
        imageDetails,
        docType,
        skills: {
          ...state.user.skills,
        },
      };
    },
  },
});

const getAllSkillsOptions = function () {
  return async dispatch => {
    const res = await getAllSkills();
    if (res.statusCode === 200) {
      const options = createReactSelectOptions(res.data);
      dispatch(setSkillsOptions(options));
    } else {
      console.error(res);
    }
  };
};

/**
 * Retrieves the skills of a user and dispatches an action to set the selected user skills.
 *
 * @param {string} userId - The ID of the user.
 * @return {Promise} A promise that resolves when the skills are retrieved and the action is dispatched.
 */
const getUserSkills = function (userId) {
  return async dispatch => {
    if (!userId) return;
    const res = await getAllUserSkills(userId);
    if (res.statusCode === 200) {
      dispatch(setSelectedUserSkills(res.data));
    } else {
      console.error(res);
    }
  };
};

/**
 * Adds a skill to the backend using the given data.
 *
 * @param {Array} data - The array of skills to add.
 * @return {Promise} A promise that resolves when the skill is added.
 */
const addSkill = function (data) {
  return async dispatch => {
    //convert the array into the backend format
    const res = await addUserSkill([data]);
    if (res.statusCode === 200) {
      dispatch(employment.actions.addedSkills(res.data[0]));
      toast.success("Added skill successfully");
    } else {
      toast.error(res.message);
    }
  };
};

const updateSkill = function (data) {
  return async dispatch => {
    const res = await updateUserSkill(data);
    if (res.statusCode === 200) {
      dispatch(employment.actions.updateSkills(res.data));
      toast.success("Updated skill successfully");
    } else {
      toast.error(res.message);
    }
  };
};

/**
 * Deletes a skill from the user's profile.
 *
 * @param {string} id - The ID of the skill to be deleted.
 * @return {Promise} A promise that resolves when the skill is successfully deleted.
 */
const deleteSkill = function (userId, skillId) {
  return async dispatch => {
    const res = await deleteUserSkill(userId, skillId);
    if (res.statusCode === 200) {
      dispatch(employment.actions.removeSkill({ userId, skillId }));
      toast.success("Deleted skill successfully");
    } else toast.error(res.message);
  };
};

export { getAllSkillsOptions, getUserSkills, addSkill, deleteSkill, updateSkill };
export const {
  removeFile,
  setFile,
  setDocType,
  setOtherDocType,
  resetEmploymentStates,
  removeAllSkill,
  setSkillsOptions,
  setSelectedUserSkills,
} = employment.actions;
export default employment.reducer;
