import React, { useEffect, useState } from 'react';
import Calendar from '../../../components/common/Calendar/Calendar';
import {
  add,
  eachDayOfInterval,
  endOfMonth,
  format,
  parse,
  startOfToday,
  startOfMonth,
} from "date-fns";
import { getAttendances } from '../../../services/AttendanceService';
import { attendanceIndicatorConditions } from '../../../utils/indicatorConditions';
import Indicator from '../../../components/common/Indicator/Indicator';

let colStartClasses = [
  "",
  "col-start-2",
  "col-start-3",
  "col-start-4",
  "col-start-5",
  "col-start-6",
  "col-start-7",
];

const CalenderViewAttendance = ({user}) => {
    const [showAllData, setShowAllData] = useState(true);
    const [attendances, setAttendances] = useState([]);
    const employeeId = user.id;

    let today = startOfToday();
    let [selectedDay, setSelectedDay] = useState(today);
    let [currentMonth, setCurrentMonth] = useState(format(today, "MMM-yyyy"));
    const [monthInfo, setMonthInfo] = useState({
      startDate: format(startOfMonth(new Date()), "yyyy-MM-dd HH:mm:ss.SSSSSS"),
      endDate: format(endOfMonth(new Date()), "yyyy-MM-dd HH:mm:ss.SSSSSS")
    });

    let firstDayCurrentMonth = parse(currentMonth, "MMM-yyyy", new Date());

    let days = eachDayOfInterval({
      start: firstDayCurrentMonth,
      end: endOfMonth(firstDayCurrentMonth),
    });

    function previousMonth() {
      let firstDayNextMonth = add(firstDayCurrentMonth, { months: -1 });
      const currentMonth = format(firstDayNextMonth, "MMM-yyyy");
      setCurrentMonth(currentMonth);
      setMonthInfo({
        startDate: format(startOfMonth(new Date(currentMonth)), "yyyy-MM-dd HH:mm:ss.SSSSSS"),
        endDate: format(endOfMonth(new Date(currentMonth)), "yyyy-MM-dd HH:mm:ss.SSSSSS")
      })
    }

    function nextMonth() {
      let firstDayNextMonth = add(firstDayCurrentMonth, { months: 1 });
      const currentMonth = format(firstDayNextMonth, "MMM-yyyy");
      setCurrentMonth(currentMonth);
      setMonthInfo({
        startDate: format(startOfMonth(new Date(currentMonth)), "yyyy-MM-dd HH:mm:ss.SSSSSS"),
        endDate: format(endOfMonth(new Date(currentMonth)), "yyyy-MM-dd HH:mm:ss.SSSSSS")
      })
    }

    useEffect(() => {
      getAttendances(monthInfo.startDate, monthInfo.endDate, employeeId).then(res => {
      if (res.statusCode === 200) {
        setAttendances(res.data);
      }
    })
    },[monthInfo]);

    {/* Present, Holiday, Absent indicators */}
    const calendarIndicators = (
      <div className="flex mt-4">
        <Indicator showWithLabel indicatorClass="bg-green-400" label="Present" labelClass="text-green-400" />
        <Indicator showWithLabel indicatorClass="bg-yellow-400" label="Holiday" labelClass="text-yellow-400" />
        <Indicator showWithLabel indicatorClass="bg-red-500" label="Absent" labelClass="text-red-400" />
      </div>
    );

  return (
    <div className="w-full h-96 p-4 bg-white rounded-lg shadow sm:p-8">
      <Calendar
        currentMonth={firstDayCurrentMonth}
        previousMonth={previousMonth}
        nextMonth={nextMonth}
        days={days}
        colStartClasses={colStartClasses}
        selectedDay={selectedDay}
        setSelectedDay={setSelectedDay}
        showAllData={showAllData}
        setShowAllData={setShowAllData}
        events={attendances}
        others = {calendarIndicators}
        indicatorConditions={attendanceIndicatorConditions}
      />
    </div>
  );
}

export default CalenderViewAttendance;