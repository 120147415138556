import { Avatar } from "flowbite-react";
import { formattedDate } from "../../../utils/common";
import { MdPeopleOutline } from "react-icons/md";

const NewJoins = ({ newEmployees }) => {

  return (
    <div>
      <div className="w-full h-96 bg-white rounded-lg">
        <div className="flex items-center justify-between p-5 pb-3">
          <h5 className="text-xl font-bold leading-none text-gray-700 dark:text-white">
            New Faces
          </h5>
          <p>Last 30 days</p>
        </div>
        <div className="mb-3">
          <hr className="h-px my-2 bg-gray-200 border-0 dark:bg-gray-700"></hr>
        </div>
        {newEmployees.length === 0 && (
          <div className="flex flex-col items-center relative">
            <MdPeopleOutline className="absolute top-10" size={"10rem"} color="#BFE2D2" />
            <p className="text-center absolute top-60 text-gray-500 dark:text-gray-400">
              No new joinings
            </p>
          </div>
        )}
        {newEmployees.length > 0 && (
          <div
            className="pl-3 pr-5 pb-5 flow-root scrollbar-hide"
            style={{ maxHeight: "calc(100% - 90px)" }}>
            <ul role="list" className="divide-gray-200 dark:divide-gray-700">
              {newEmployees.map(
                employee =>
                    <li key={employee.id} className="py-2 sm:py-2">
                      <div className="flex items-center">
                        <div className="flex-shrink-0">
                          <Avatar
                            rounded={true}
                            placeholderInitials={`${employee.firstName && employee.firstName[0].toUpperCase()}${employee.lastName && employee.lastName[0].toUpperCase()}`}
                            className="mx-2 z-auto cursor-pointer"
                            img={employee.image}
                          />
                        </div>
                        <div className="flex-1 min-w-0 ms-4">
                          <p className="text-sm font-medium text-gray-900 truncate dark:text-white">
                            {employee.firstName + " " + employee.lastName}
                          </p>
                          <p className="text-sm text-gray-500 truncate dark:text-gray-400">
                            {employee.email}
                          </p>
                        </div>
                        <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                          {formattedDate(employee.dateOfJoining)}
                        </div>
                      </div>
                    </li>
              )}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default NewJoins;
