import { Label, TextInput } from "flowbite-react";
/**
 * Renders the tax information section based on the active item.
 *
 * @param {{details: object, handleAddNewClientData: Function, activeItem: string}} props 
 * @param {object} details - The details object containing the GSTIN and PAN information.
 * @param {Function} handleAddNewClientData - The function to handle adding new client data.
 * @param {string} activeItem - The active item in the tax information section.
 * @return {JSX.Element} The JSX element representing the tax information section.
 */
const TaxInformation = ({ details, handleAddNewClientData, activeItem }) => {
  const isActive = activeItem === "taxInfo";
  return (
    <>
      {isActive && (
        <div className="!w-full grid gap-4 mb-7 md:grid-cols-2 md:auto-rows-min sm:grid-cols-1 pt-4">
          <div>
            <div className="mb-2 block">
              <Label
                htmlFor="business gstin"
                value="Business GSTIN"
              />
            </div>
            <TextInput
              id="business gstin"
              type="text"
              name="gstin"
              placeholder="Business GSTIN ( Optional )"
              shadow={true}
              value={details.gstin || ""}
              onChange={handleAddNewClientData}
            />
          </div>

          <div>
            <div className="mb-2 block">
              <Label
                htmlFor="Business PAN Number"
                value="Business PAN Number"
              />
            </div>
            <TextInput
              id="Business PAN Number"
              type="text"
              name="pan"
              placeholder="Business PAN Number ( Optional )"
              shadow={true}
              value={details.pan || ""}
              onChange={handleAddNewClientData}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default TaxInformation;
