export const salaryState = {
  fetchComponents: false,
  showEarningModal: false,
  editModal: false,
  editRowId: null,
  isEditEarningModal: false,
  showDeductionModal: false,
  isEditDeductionModal: false,
  earningTypeOptions: [
    {
      value: 1,
      label: "Earning",
      className: "-pl-8 w-[120px]",
      dotClassName: "",
      backgroundColor: "bg-gray-100",
      itemClassName: "w-[170px]",
    },
    {
      value: 2,
      label: "Deduction",
      className: "-pl-8 w-[120px]",
      dotClassName: "",
      backgroundColor: "bg-gray-100",
      itemClassName: "w-[170px]",
    },
  ],
  selectedEarningType: null,
  selectedEarningTypeEdit: null,
  txtFields: {
    name: "",
    description: "",
  },
  txtFieldsEdit: {
    name: "",
    description: "",
  },
  payType: {
    active: "Fixed",
  },
  payTypeEdit: {
    active: "Fixed",
  },
  checkbox: {
    status: true,
    isTaxable: false,
  },
  checkboxEdit: {
    status: true,
    isTaxable: false,
  },
  rows: [],
  switchValues: {},
};
