import React, { useEffect } from "react";
import MandatorySign from "../../../components/common/Input/MandatorySign";
import InputFields from "../../../components/common/Input/InputFields";
import { BiRupee } from "react-icons/bi";
import { FiInfo } from "react-icons/fi";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { BsInfoCircleFill } from "react-icons/bs";
import { PiPercent } from "react-icons/pi";
import { LiaRupeeSignSolid } from "react-icons/lia";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import { setEarningDetails } from "../../../store/slices/salarySlice";
import { CalculationType, calculationTypeLabels } from "../../../enum/CalculationType";
import { EarningCategory } from "../../../enum/EarningCategory";
import { MdPercent } from "react-icons/md";

const Earning = () => {
  const dispatch = useAppDispatch();
  const salary = useAppSelector(state => state.salary);

  const onChangeFixedPay = e => {
    const isFixedPay = e.target.checked;
    dispatch(
      setEarningDetails({
        ...salary.earningDetails,
        values: {
          ...salary.earningDetails.values,
          isFixedPay: isFixedPay,
        },
      })
    );
  };

  const onChangeIsPartOfSalaryStructure = e => {
    const isPartOfSalaryStructure = e.target.checked;
    dispatch(
      setEarningDetails({
        ...salary.earningDetails,
        values: {
          ...salary.earningDetails.values,
          isPartOfSalaryStructure: isPartOfSalaryStructure,
        },
      })
    );
  };

  const onChangeIsTaxableEarning = e => {
    const isTaxableEarning = e.target.checked;
    dispatch(
      setEarningDetails({
        ...salary.earningDetails,
        values: {
          ...salary.earningDetails.values,
          isTaxableEarning: isTaxableEarning,
        },
      })
    );
  };

  const onChangeIsProRateBasis = e => {
    const isProRateBasis = e.target.checked;
    dispatch(
      setEarningDetails({
        ...salary.earningDetails,
        values: {
          ...salary.earningDetails.values,
          isProRateBasis: isProRateBasis,
        },
      })
    );
  };

  const onChangeIsConsideredForESI = e => {
    const isConsideredForESI = e.target.checked;
    dispatch(
      setEarningDetails({
        ...salary.earningDetails,
        values: {
          ...salary.earningDetails.values,
          isConsideredForESI: isConsideredForESI,
        },
      })
    );
  };

  const onChangeEarningName = e => {

    const earningName = e.target.value;
    dispatch(
      setEarningDetails({
        ...salary.earningDetails,
        values: {
          ...salary.earningDetails.values,
          earningName: earningName,
        },
      })
    );
  };

  const onChangePayslipName = e => {
    const nameInPayslip = e.target.value;
    dispatch(
      setEarningDetails({
        ...salary.earningDetails,
        values: {
          ...salary.earningDetails.values,
          nameInPayslip: nameInPayslip,
        },
      })
    );
  };

  const onChangeAmount = e => {
    const amount = Number(e.target.value);
    dispatch(
      setEarningDetails({
        ...salary.earningDetails,
        values: {
          ...salary.earningDetails.values,
          amount: amount,
        },
      })
    );
  };

  const onChangeCalculationType = e => {
    const ct = Number(e.target.value);
    dispatch(
      setEarningDetails({
        ...salary.earningDetails,
        values: {
          ...salary.earningDetails.values,
          calculationType: ct,
        },
      })
    );
  };

  return (
    <div className="sections-with-earning-type-container flex justify-between">
      {/* Left side container */}
      <div className=" w-[48%]">
        {/* Earning name and name in payslip container */}
        <div className="">
          <div className="">
            <h3 className="underline decoration-dashed decoration-[0.8px] underline-offset-4">
              Earning Name <MandatorySign />
            </h3>

            <InputFields
              InputField={{
                type: "text",
                id: "earningName",
                name: "earningName",
                required: true,
              }}
              parentClassName="mt-3"
              inputClassName="w-[80%]"
              value={salary.earningDetails.values.earningName ?? ""}
              onChange={onChangeEarningName}
            />
          </div>
          <div className="mt-4">
            <h3 className="underline decoration-dashed decoration-[0.75px] underline-offset-4">
              Name in Payslip <MandatorySign />
            </h3>

            <InputFields
              InputField={{
                type: "text",
                id: "nameInPayslip",
                name: "nameInPayslip",
                required: true,
              }}
              parentClassName="mt-3"
              inputClassName="w-[80%]"
              value={salary.earningDetails.values.nameInPayslip ?? ""}
              onChange={onChangePayslipName}
            />
          </div>
        </div>
        {/* pay type section */}
        {!salary?.earningDetails.lockOptions.lockIsFixedPay && (
          <div className="pay-type-container mt-6">
            <h2>Pay Type</h2>
            <div className="flex items-center mb-1 mt-2">
              <div className="">
                <input
                  id="fixed-pay-checkbox"
                  type="checkbox"
                  value={salary?.earningDetails.values.isFixedPay ?? false}
                  onChange={onChangeFixedPay}
                  checked={salary?.earningDetails.values.isFixedPay}
                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
              </div>
              <div className="">
                <label
                  for="fixed-pay-checkbox"
                  className="ms-2 text-sm text-gray-900 dark:text-gray-300 w-full">
                  Fixed Pay
                </label>
              </div>
            </div>
          </div>
        )}

        {/* Calculation type section */}
        {salary?.earningDetails.values.isFixedPay && (
          <div className="pay-type-container mt-6">
            <h2>
              Calculation Type
              <MandatorySign />
            </h2>
            {salary?.earningDetails.type.nameType === EarningCategory.FixedAllowance && (
              <>
              Gross - Sum of other fixed earnings
              </>
            )}
            {salary?.earningDetails.type.nameType !== EarningCategory.FixedAllowance && salary?.earningDetails.type.componentCalculationTypes.map(type => (
              <div className="flex items-center mb-1 mt-2" key={type}>
                <input
                  id={`${type}-ct-radio`}
                  type="radio"
                  value={type}
                  name="ct-radio"
                  onChange={onChangeCalculationType}
                  checked={type === salary?.earningDetails.values.calculationType}
                  className="w-[0.8rem] h-[0.8rem] text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
                <label
                  htmlFor={`${type}-ct-radio`}
                  className="ms-2 text-sm text-gray-900 dark:text-gray-300 -mt-[0.2rem]">
                  {calculationTypeLabels[type]}
                </label>
              </div>
            ))}
          </div>
        )}

        {/* Amount section */}
        {salary?.earningDetails.values.isFixedPay && salary?.earningDetails.type.nameType !== EarningCategory.FixedAllowance && (
          <div className="amount-container  mt-4">
            <h2>Enter Amount</h2>
            <div className=" flex mt-2">
              {salary?.earningDetails.values.calculationType === CalculationType.FlatAmount && (
                <div className=" bg-slate-200 min-h-full flex justify-center items-center w-7 rounded-l-[5px]">
                  <BiRupee className="h-full flex justify-center items-center" size={17} />
                </div>
              )}

              <InputFields
                InputField={{
                  type: "number",
                  id: "amount",
                  name: "amount",
                }}
                inputClassName={"!rounded-none  w-full"}
                parentClassName={"w-[35%]"}
                value={salary.earningDetails.values.amount}
                onChange={onChangeAmount}
              />
              {salary?.earningDetails.values.calculationType !== CalculationType.FlatAmount && (
                <div className=" bg-slate-200 min-h-full flex justify-center items-center w-7 rounded-r-[5px]">
                  <MdPercent className="h-full flex justify-center items-center" size={17} />
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      <div className="border"></div>
      {/* Right side container */}
      <div className=" w-[48%]">
        <h1 className=" font-medium">Other Configurations</h1>
        {/* <div className=" mt-4">
          <div className="flex mb-4">
            <div className="">
              <input
                id="salary-structure-checkbox"
                type="checkbox"
                value={salary?.earningDetails.values.isPartOfSalaryStructure ?? false}
                onChange={onChangeIsPartOfSalaryStructure}
                checked={salary?.earningDetails.values.isPartOfSalaryStructure}
                disabled={salary?.earningDetails.lockOptions.lockIsPartOfSalaryStructure}
                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              />
            </div>
            <div className="">
              <label
                for="salary-structure-checkbox"
                className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300 w-full">
                Make this earning a part of the employee’s salary structure
              </label>
            </div>
          </div>
        </div>
        <div className="">
          <div className="flex mb-4">
            <div className="">
              <input
                id="is-taxable-earning-checkbox"
                type="checkbox"
                value={salary?.earningDetails.values.isTaxableEarning ?? false}
                onChange={onChangeIsTaxableEarning}
                checked={salary?.earningDetails.values.isTaxableEarning}
                disabled={salary?.earningDetails.lockOptions.lockIsTaxableEarning}
                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              />
            </div>
            <div className="">
              <label
                for="is-taxable-earning-checkbox"
                className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300  w-full">
                This is a taxable earning
              </label>
              <p className="ms-2 text-sm text-gray-900 dark:text-gray-300">
                The income tax amount will be divided equally and deducted every month across the
                financial year.
              </p>
            </div>
          </div>
        </div>
        <div className="">
          <div className="flex mb-4">
            <div className="">
              <input
                id="is-pro-rate-checkbox"
                type="checkbox"
                value={salary?.earningDetails.values.isProRateBasis ?? false}
                onChange={onChangeIsProRateBasis}
                checked={salary?.earningDetails.values.isProRateBasis}
                disabled={salary?.earningDetails.lockOptions.lockIsProRateBasis}
                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              />
            </div>
            <div className="">
              <label
                for="is-pro-rate-checkbox"
                className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300 w-full">
                Calculate on pro-rata basis
              </label>
              <p className="ms-2 text-sm text-gray-900 dark:text-gray-300">
                Pay will be adjusted based on employee working days.
              </p>
            </div>
          </div>
        </div> */}
        <div className=" mt-4">
          <div className="flex mb-4">
            <div className="">
              <input
                id="default-checkbox"
                type="checkbox"
                value=""
                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              />
            </div>
            <div className="">
              <label
                for="default-checkbox"
                className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300 w-full">
                Consider for EPF Contribution
              </label>
              <p className="ms-2 text-sm text-gray-900 dark:text-gray-300">
                <div className="flex items-center mb-1 mt-2">
                  <input
                    id="default-radio-1"
                    type="radio"
                    value=""
                    name="default-radio"
                    className="w-[0.8rem] h-[0.8rem] text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600 "
                  />
                  <label
                    for="default-radio-1"
                    className="ms-2 text-sm text-gray-900 dark:text-gray-300 -mt-[0.2rem]">
                    Always
                  </label>
                </div>
                <div className="flex items-center">
                  <input
                    checked
                    id="default-radio-2"
                    type="radio"
                    value=""
                    name="default-radio"
                    className="w-[0.8rem] h-[0.8rem] text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                  <label
                    for="default-radio-2"
                    className="ms-2 text-sm text-gray-900 dark:text-gray-300 -mt-[0.2rem]">
                    Only when PF Wage is less than ₹ 15,000
                  </label>
                  <BsInfoCircleFill
                    size={13}
                    color="#c0c1c4"
                    className="cursor-pointer ml-1 "
                    data-tooltip-id="pf-wage"
                    data-tooltip-html={`<div>
                    If the sum of PF Wage </br> exceeds Rs 15000. when this</br>component is included, the</br> EPF will be calculated only for</br> a maximum amount of</br> Rs 15000.
                    </div>`}
                    data-tooltip-place="top-middle"
                  />
                  <ReactTooltip id="pf-wage" />
                </div>
              </p>
            </div>
          </div>
        </div>
        {/* <div className="">
          <div className="flex mb-4">
            <div className="">
              <input
                id="is-esi-contribution-checkbox"
                type="checkbox"
                value={salary?.earningDetails.values.isConsideredForESI ?? false}
                onChange={onChangeIsConsideredForESI}
                checked={salary?.earningDetails.values.isConsideredForESI}
                disabled={salary?.earningDetails.lockOptions.lockIsConsideredForESI}
                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              />
            </div>
            <div className="">
              <label
                for="is-esi-contribution-checkbox"
                className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300 w-full">
                Consider for ESI Contribution
              </label>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default Earning;
