import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import { DEFAULT_PAGE_NO, DEFAULT_PAGE_SIZE } from "../../constants/Constant";
import { getInvoices } from "../../services/InvoiceService";
import { InvoiceCustomField } from "../../enum/InvoiceCustomField";

export const TABLE_HEADER = [
  "invoice no",
  "invoice date",
  "due date",
  "billed by",
  "billed to",
  "total amount",
  "actions",
];
export const TABLE_HEADINGS = [
  "items",
  "hsn/sac",
  "gst rate",
  "quantity",
  "rate",
  "amount",
  "igst",
  "cgst",
  "sgst",
  "total",
  "action",
];

export const sidebarTabs = [
  {
    id: 1,
    name: "Basic Information",
    activeItem: "basicInfo",
  },
  {
    id: 2,
    name: "Bank Details (optional)",
    activeItem: "bankDetails",
  },
  {
    id: 3,
    name: "Tax Information (optional)",
    activeItem: "taxInfo",
  },
  {
    id: 4,
    name: "Address (optional)",
    activeItem: "address",
  },
  {
    id: 5,
    name: "Contact (optional)",
    activeItem: "additional",
  },
];

export const DEFAULT_TC = [
  {
    groupId: InvoiceCustomField.TermsAndConditions,
    fieldName:
      "Please pay within 15 days from the date of invoice, overdue interest @ 14% will be charged on delayed payments.",
    id: Date.now(),
  },
  {
    groupId: InvoiceCustomField.TermsAndConditions,
    fieldName: "Please quote invoice number when remitting funds.",
    id: Date.now() + 1,
  },
];

export const shippingDetailsModal = {
  challanNumber: "",
  challanDate: "",
  transportName: "",
  transportNote: "",
  sameAsBusinessAddress: false,
  sameAsClientAddress: false,
  shippedFrom: 0,
  shippedTo: 0,
};

export const addressModal = {
  id: 0,
  tenantId: 0,
  businessName: "",
  address: "",
  country: "",
  state: "",
  city: "",
  postalCode: "",
  gstin: "",
  isClient: "",
};

export const initialFormValues = {
  invoiceItems: [],
  selectedBusiness: {},
  businesses: [],
  clients: [],
  selectedClientBusiness: {},
  invoiceHeader: {},
  logoUrl: "",
  shippingDetails: shippingDetailsModal,
  invoiceNumber: undefined,
  invoiceNumberDetails: {},
  radioButtonValue: "igst",
  headerField: [],
  TAndC: [],
  Note: {
    fieldName: "",
    id: Date.now(),
    groupId: InvoiceCustomField.Notes,
  },
};

export const toggleInitialState = {
  businessDetailsToggle: false,
  clientDetailsToggle: false,
  invoiceNumberChanged: false,
  invoiceNumberValidation: false,
  isChange: false,
  isChangeHeader: false,
  isSelectedBusiness: false,
  isSelectedClient: false,
  isClient: false,
  showShippingDetails: false,
  billedByModal: false,
  billedToModal: false,
  toggleShippingModal: false,
  shippingIsClient: false,
  isTermsAndConditionsVisible: false,
  isNotesVisible: false,
  isClientUpdate: false,
};

export const allAmountInitialState = {
  amount: 0,
  igstAmount: 0,
  cgstAmount: 0,
  sgstAmount: 0,
};

export const addShippingAddress = {
  id: "add-shipping-address",
  label: "Add shipping address",
  type: "checkbox",
  name: "add-shipping-address",
  placeholder: "",
  required: false,
};

export const states = [
  "andhra pradesh",
  "andaman and nicobar islands",
  "arunachal pradesh",
  "assam",
  "bihar",
  "chandigarh",
  "chhattisgarh",
  "dadar and nagar haveli",
  "daman and diu",
  "delhi",
  "lakshadweep",
  "puducherry",
  "goa",
  "gujarat",
  "haryana",
  "himachal pradesh",
  "jammu and kashmir",
  "jharkhand",
  "karnataka",
  "kerala",
  "madhya pradesh",
  "maharashtra",
  "manipur",
  "meghalaya",
  "mizoram",
  "nagaland",
  "odisha",
  "punjab",
  "rajasthan",
  "sikkim",
  "tamil nadu",
  "telangana",
  "tripura",
  "uttar pradesh",
  "uttarakhand",
  "west bengal",
];

export const Industries = [
  "Apparel & Fashion",
  "Architecture & Planning",
  "Business Supplies & Equipment",
  "Computer Software",
  "Computer Hardware",
  "Education",
  "Entertainment",
  "FMCG",
  "Health, Wellness and Fitness",
  "Hospitality",
  "Import and Export",
  "IT & ITes",
  "Legal Services",
  "Manufacture",
  "Marketing & Advertising",
  "Packaging & Containers",
  "Printing",
  "Pharmaceuticals",
  "Retail",
  "Tourism",
];

export const Currency = [
  "USD",
  "INR",
  "EUR",
  "CAD",
  "AUD",
  "JPY",
  "GBP",
  "SGD",
  "MXN",
  "BRL",
  "CNY",
  "CHF",
  "RUB",
  "AED",
  "KRW",
  "TRY",
  "ZAR",
  "NZD",
  "SEK",
  "HKD",
];

//fetch all invoices id
const fetchAllInvoices = async () => {
  return await getInvoices(DEFAULT_PAGE_NO, DEFAULT_PAGE_SIZE).then(res => {
    let allInvoiceItems = res.data.map(el => {
      return el.invoiceItems.map(ele => {
        return ele.id;
      });
    });
    return Math.max(...allInvoiceItems.flat());
  });
};

export const CalculateAmount = items => {
  const tempAmount = items.reduce((ac, cv) => {
    return ac + +cv.quantity * +cv.rate;
  }, 0);
  const tempIgstAmount = items.reduce((ac, cv) => {
    return ac + (+cv.quantity * +cv.rate * +cv.gstRate) / 100;
  }, 0);
  const tempCgstAmount = items.reduce((ac, cv) => {
    return ac + (+cv.quantity * +cv.rate * +cv.gstRate) / 200;
  }, 0);
  const tempSgstAmount = items.reduce((ac, cv) => {
    return ac + (+cv.quantity * +cv.rate * +cv.gstRate) / 200;
  }, 0);

  return {
    tempAmount,
    tempIgstAmount,
    tempCgstAmount,
    tempSgstAmount,
  };
};

//create first invoice for the form
export const firstInvoiceItem = (invoiceItems, setAllAmounts, setFormValues) => {
  const latestInvoiceItems = [
    ...invoiceItems,
    {
      name: "",
      hsnsac: "998314",
      gstRate: "18",
      quantity: "1",
      rate: "1",
      id: uuidv4(),
    },
  ];

  const { tempAmount, tempCgstAmount, tempIgstAmount, tempSgstAmount } =
    CalculateAmount(latestInvoiceItems);

  setAllAmounts({
    amount: tempAmount,
    igstAmount: tempIgstAmount,
    cgstAmount: tempCgstAmount,
    sgstAmount: tempSgstAmount,
  });
  setFormValues(prevFV => ({ ...prevFV, invoiceItems: latestInvoiceItems }));
};

//deleting add new element in invoice
export const handleDelete = (invoiceItems, setAllAmounts, setFormValues, id, allAmounts) => {
  //remove deleted item
  const filteredData = invoiceItems.filter(el => el.id !== id);

  const { tempAmount, tempCgstAmount, tempIgstAmount, tempSgstAmount } =
    CalculateAmount(filteredData);

  setAllAmounts({
    ...allAmounts,
    amount: tempAmount,
    igstAmount: tempIgstAmount,
    cgstAmount: tempCgstAmount,
    sgstAmount: tempSgstAmount,
  });
  setFormValues(prevFV => ({ ...prevFV, invoiceItems: filteredData }));
};

//add new field
//Add new Invoice items
//setNewItemsCount, newItemsCount, isUpdating, paramsId are only for update invoice component
export const handleAddNewInvoiceItemClick = async (
  invoiceItems,
  setAllAmounts,
  setFormValues,
  setNewItemsCount,
  newItemsCount,
  isUpdating,
  paramsId
) => {
  let updatedInvoice;
  if (isUpdating) {
    const previousInvoiceItemId = await fetchAllInvoices();
    updatedInvoice = [
      ...invoiceItems,
      {
        name: "",
        hsnsac: "998314",
        gstRate: "18",
        quantity: "1",
        rate: "1",
        id: previousInvoiceItemId + newItemsCount,
        invoiceId: +paramsId,
      },
    ];
    setNewItemsCount(newItemsCount + 1);
  } else {
    updatedInvoice = [
      ...invoiceItems,
      {
        name: "",
        hsnsac: "998314",
        gstRate: "18",
        quantity: "1",
        rate: "1",
        id: Date.now(),
      },
    ];
  }

  const { tempAmount, tempCgstAmount, tempIgstAmount, tempSgstAmount } =
    CalculateAmount(updatedInvoice);

  setAllAmounts({
    amount: tempAmount,
    igstAmount: tempIgstAmount,
    cgstAmount: tempCgstAmount,
    sgstAmount: tempSgstAmount,
  });
  setFormValues(prevFV => ({ ...prevFV, invoiceItems: updatedInvoice }));
};

//getting values from form
export const getInvoiceItem = (
  invoiceItems,
  setAllAmounts,
  setFormValues,
  allAmounts,
  invoiceItem
) => {
  const updateData = invoiceItems.map(el => {
    return el.id === invoiceItem.id ? invoiceItem : el;
  });

  const { tempAmount, tempCgstAmount, tempIgstAmount, tempSgstAmount } =
    CalculateAmount(updateData);

  setAllAmounts({
    ...allAmounts,
    amount: tempAmount,
    igstAmount: tempIgstAmount,
    cgstAmount: tempCgstAmount,
    sgstAmount: tempSgstAmount,
  });
  setFormValues(prevFV => ({ ...prevFV, invoiceItems: updateData }));
};

//Date content
export const getCurrentDate = setDate => {
  const currentDate = moment().format("YYYY-MM-DD");
  const futureDate = moment().add(15, "days").format("YYYY-MM-DD");

  setDate({
    currentDate,
    futureDate,
  });
};

export const onDateChange = (value, setDate) => {
  const currentDate = value;
  const futureDate = moment(value).add(15, "days").format("YYYY-MM-DD");
  setDate({
    currentDate,
    futureDate,
  });
};

export const onFutureDateChange = (value, setDate) => {
  setDate(prevD => ({ ...prevD, futureDate: value }));
};

export const createItemsObj = (invoiceItems, isUpdating) => {
  if (isUpdating) {
    return invoiceItems.map(el => ({
      name: el.name,
      gstRate: el.gstRate,
      quantity: el.quantity,
      rate: el.rate,
      hsnsac: el.hsnsac,
      amount: +el.quantity * +el.rate,
      invoiceId: +el.invoiceId,
      id: el.id,
    }));
  }

  if (!isUpdating) {
    return invoiceItems.map(el => ({
      name: el.name,
      gstRate: el.gstRate,
      quantity: el.quantity,
      rate: el.rate,
      hsnsac: el.hsnsac,
      amount: +el.quantity * +el.rate,
    }));
  }
};

export const createHeaderFieldItems = (headerField, isUpdating, invoiceId) => {
  if (isUpdating) {
    return headerField.map(field => {
      //if headerField has invoice id - in simple terms if ele was added when invoice created.
      if (field.invoiceId)
        return {
          ...field,
          id: +field.id,
          fieldId: +field.fieldId,
          fieldName: field.fieldName,
          fieldValue: field.fieldValue,
        };
      // If user created a new custom field and then add the invoiceId in the field
      else
        return {
          fieldId: +field.id,
          fieldValue: field.fieldValue,
          fieldName: field.fieldName,
          invoiceId: invoiceId,
          groupId: InvoiceCustomField.HeaderFields,
        };
    });
  }

  if (!isUpdating) {
    return headerField.map(ele => {
      if (ele.id)
        return {
          groupId: InvoiceCustomField.HeaderFields,
          fieldId: ele.id,
          fieldName: ele.fieldName,
          fieldValue: ele.fieldValue,
        };
    });
  }
};

/**
 * Generates an address string based on the provided address object.
 *
 * @param {{street: string, city: string, state: string, country: string, postalCode: string}} address - The address object containing street, city, state, country, and postalCode properties.
 * @return {string} The generated address string.
 */
export const createAddressStr = address => {
  const { street, city, state, country, postalCode } = address;
  const addressStr = `${!street ? "" : street + ", "} ${!city ? "" : city + ", "} ${!state ? "" : state + ","
    } ${!country ? "" : country}${!postalCode ? "" : "-" + postalCode}`;
  return addressStr.trim();
};

/**
 * Sets the modal heading based on the provided parameters.
 *
 * @param {string} clientUpdateId - The client update ID
 * @param {boolean} isClient - Indicates if the entity is a client
 * @param {boolean} isClientUpdate - Indicates if the entity is a client update
 * @return {string} The modal heading based on the provided parameters
 */
export const setModalHeading = (clientUpdateId, isClient, isClientUpdate, billedToModal) => {
  if (!clientUpdateId && !isClient) return "Add Business";

  if (billedToModal && clientUpdateId && isClientUpdate) return "Update Client";


  if (clientUpdateId && isClientUpdate) return "Update Business";
  // this for when create business component
  //  if(businessUpdateId && isClient)
  //  return ""

  if (!clientUpdateId && isClient) return "Add Client";

};
