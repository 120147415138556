import { toggleGlobalSettingsModal } from "../../../store/slices/settingsSlice";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import ModalComponent from "../../common/Modal/Modal";

import SettingsForm from "./SettingsForm";

/**
 *
 * @param {boolean} props.whether Popup Modal or not
 * @param {function} props.close function for Popup Modal
 * @returns Dashboard settings Popup Modal
 */
export default function ControlPanelSettings() {
  const dispatch = useAppDispatch();
  const { showGlobalSettingsModal } = useAppSelector(state => state.settings);

  const onClickCloseHandler = () => {
    dispatch(toggleGlobalSettingsModal(false));
  };

  return (
    <ModalComponent
      show={showGlobalSettingsModal}
      showFooter={false}
      onClose={onClickCloseHandler}
      heading="Settings">
      <SettingsForm onClose={onClickCloseHandler} />
    </ModalComponent>
  );
}
