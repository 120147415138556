import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  logoDetails: {
    logoUrl: null,
    logoFile: null,
  },
  brandDetails: {
    id: "brandDetails",
    logobytes: "",
    headerBgColor: "#ffffff",
    isHeaderFontLight: false,
  },
};

// Create a slice for managing the state
const logoAndBrandSlice = createSlice({
  name: "logoAndBrand",
  initialState,
  reducers: {
    // Reducer to set logo file details
    setLogoDetails: (state, action) => {
      state.logoDetails = {
        ...state.logoDetails,
        ...action.payload,
      };
    },
    // Reducer to set brand details
    setBrandDetails: (state, action) => {
      state.brandDetails = {
        ...state.brandDetails,
        ...action.payload,
      };
    },
  },
});

// Export the action creators
export const { setLogoDetails, setBrandDetails } = logoAndBrandSlice.actions;

// Export the reducer
export default logoAndBrandSlice.reducer;
