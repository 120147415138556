import { axiosInstance as axios } from "../context/AuthContext";

const BASE_URL = process.env.REACT_APP_BASE_URL;
const BASE_SKILLS_URL = `${BASE_URL}/Skills`;

/**
 * Retrieves the user skills from the server.
 *
 * @return {Array} The user's skills.
 */
export const getAllSkills = async function () {
  const res = await axios.get(`${BASE_SKILLS_URL}`);
  return res?.data;
};

/**
 * Adds a skill to the database.
 *
 * @param {[{id: number, name: string}]} data - The skill to be added.
 * @return {Promise} The response data from the API call.
 */
export const addSkill = async function (data) {
  const res = await axios.post(`${BASE_SKILLS_URL}`, data);
  return res?.data;
};

/**
 * Update the skill with the given data.
 *
 * @param {{id: number, name: string}} data - The data to update the skill.
 * @return {Promise<Object>} The updated skill data.
 */
export const updateSkill = async function (data) {
  const res = await axios.put(`${BASE_SKILLS_URL}`, data);
  return res?.data;
};

/**
 * Deletes a skill with the specified ID.
 *
 * @param {number} id - The ID of the skill to delete.
 * @return {Promise<any>} A Promise that resolves to the deleted skill data.
 */
export const deleteSkill = async function (id) {
  const res = await axios.delete(`${BASE_SKILLS_URL}/${id}`);
  return res?.data;
};
