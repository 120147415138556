const Step = ({ iconColor, barColor, isLast, id, name }) => {
  const stepIconClasses = {
    blue: "text-blue-600 dark:text-blue-500",
    gray: "text-gray-500 dark:text-gray-100",
  };

  const stepBorderClasses = {
    blue: "after:border-blue-100 after:dark:border-blue-800",
    gray: "border-gray-100 dark:border-gray-700",
  };

  const stepIconBackgroundClasses = {
    blue: "bg-blue-100 dark:bg-blue-800",
    gray: "bg-white dark:bg-white",
  };

  const barClasses =
    !isLast &&
    `after:content-[''] after:w-[100%] after:h-1 after:border-b after:border ${stepBorderClasses[barColor]} after:border-4 after:inline-block dark:after:border-blue-800`;

  return (
    <li
      data-id={id}
      className={`flex ${!isLast && "w-full"} items-center ${barClasses}`}>
      <span
        className={`cursor-pointer relative flex items-center justify-center w-10 h-10 ${stepIconBackgroundClasses[iconColor]} rounded-full lg:h-12 lg:w-12 shrink-0`}>
        <div
          className={`${
            +id === 0 && "-left-[.9rem]"
          } absolute top-[45px] left-[.1rem] mt-3 w-[max-content]`}>
          <h5 className="text-[12px] w-[80%] text-center text-gray-500 dark:text-gray-100 font-semibold">
            {name}
          </h5>
        </div>

        <div>
          <p className={stepIconClasses[iconColor]}>{id + 1}</p>
        </div>
      </span>
    </li>
  );
};

export default Step;
