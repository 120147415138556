import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { SendResetPasswordLink } from "../services/UserService";
import { showToast } from "../components/common/Toast";

const ForgotPassword = () => {
  const navigate = useNavigate();
  //Initial state for form email
  const [userDetails, setUserDetails] = useState({
    email: "",
  });

  //Getting user email
  const onChangeForgotPassword = event => {
    const currentEmail = {
      ...userDetails,
      email: event.target.value,
    };
    setUserDetails(currentEmail);
  };

  //Submitting user email for resetting password
  const onSubmitForgotPassword = event => {
    event.preventDefault();
    const userData = {
      ...userDetails,
      clientURI: `${location.origin}/reset-password`,
    };
    SendResetPasswordLink(userData)
      .then(res => {
        if (+res.status === 200) {
          showToast("success", "Please check your email for further instructions");
          setTimeout(() => navigate("/"), 1000);
        }
      })
      .catch(err => showToast("error", "Invalid email, Please check the entered email."));
  };
  return (
    <section className="bg-gray-100 dark:bg-gray-900 border border-red-700 min-h-[89.8vh] border-transparent ">
      <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto">
        <div className="w-full bg-white rounded-lg shadow dark:border md:mt-4 mt-24 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
          <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
            <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white text-center">
              Forgot password
            </h1>
            <form
              className="space-y-4 md:space-y-6"
              action="#">
              <div>
                <label
                  htmlFor="user-email"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                  Email
                </label>
                <input
                  type="email"
                  name="email"
                  onChange={onChangeForgotPassword}
                  id="user-email"
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-thynkwebPrimary-600 focus:border-thynkwebPrimary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-thynkwebPrimary-500 dark:focus:border-thynkwebPrimary-500"
                  placeholder="name@company.com"
                  required
                />
              </div>
              <button
                type="submit"
                onClick={onSubmitForgotPassword}
                className="w-full text-white bg-thynkwebPrimary-600 hover:bg-thynkwebPrimary-700 focus:ring-4 focus:outline-none focus:ring-thynkwebPrimary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-thynkwebPrimary-600 dark:hover:bg-thynkwebPrimary-700 dark:focus:ring-thynkwebPrimary-800">
                Send reset link
              </button>
              <div className="flex justify-between">
                <p className="text-sm font-light text-gray-500 dark:text-gray-400 mt-2 text-center">
                  Remember the password?{" "}
                  <Link
                    to={"/login"}
                    className="font-medium text-[#2563eb]">
                    Login here
                  </Link>
                </p>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ForgotPassword;
