import { createSlice } from "@reduxjs/toolkit";
import { GetExceptionLogs } from "../../services/exceptionLogsServices";

const initialState = {
  exceptions: [],
  totalRecords: 0,
};

const exceptionLogs = createSlice({
  name: "exceptionLogs",
  initialState,
  reducers: {
    getLogs: (state, action) => {
      const data = action.payload;
      state.exceptions = data;
      state.totalRecords = data.totalRecords;
    },
  },
});

//action creator
export const getExceptionLogs = (pageSize, pageNo) => {
  return async dispatch => {
    try {
      const res = await GetExceptionLogs(pageSize, pageNo);

      dispatch(exceptionLogs.actions.getLogs(res.data.data));
    } catch (error) {
      console.error(error);
    }
  };
};

export const { getLogs } = exceptionLogs.actions;
export default exceptionLogs.reducer;
