import { IoDocumentAttachOutline } from "react-icons/io5";
import { MdOutlineDocumentScanner } from "react-icons/md";
import ReadingDocsSvg from "./ReadingDocsSvg";

export const GeneralSvg = ({ color, size, className }) => (
  <MdOutlineDocumentScanner
    color={color}
    size={size}
    className={className}
  />
);

export const DocumentSvg = ({ color, size, className }) => (
  <IoDocumentAttachOutline
    color={color}
    size={size}
    className={className}
  />
);

export const ReadingDocs = ({className}) => <ReadingDocsSvg className={className} />;
