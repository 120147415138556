import { TbClock } from "react-icons/tb";
import { FiUser } from "react-icons/fi";
import { GiPalmTree } from "react-icons/gi";
import { CiMoneyCheck1 } from "react-icons/ci";
import { TbFileInvoice } from "react-icons/tb";
import { MdOutlineScheduleSend } from "react-icons/md";
import { RxGear } from "react-icons/rx";
import { IoMdPaperPlane } from "react-icons/io";
import { IoMdLogOut } from "react-icons/io";


export const SIDEBAR__USER__LINKS = user => [
  {
    name: "My Profile",
    link: `/profile?userId=${user?.id}`,
    icon: <FiUser size={"1.2rem"} />,
  },
  {
    name: "my attendance",
    link: "/attendance/my-attendance",
    icon: <TbClock size={"1.2rem"} />,
  },
  {
    name: "my leaves",
    link: "/leaves/my-leaves",
    icon: <IoMdLogOut size={"1.2rem"} />,
  },
  {
    name: "my holidays",
    link: "/holidays",
    icon: <GiPalmTree size={"1.2rem"} />,
  },
  {
    name: "My Payslips",
    link: "/payslips",
    icon: <CiMoneyCheck1 size={"1.2rem"} />,
  },
];

export const SIDEBAR__ADMIN__LINKS = user => [
  {
    name: "My Profile",
    link: `/profile?userId=${user?.id}`,
    icon: <FiUser size={"1.2rem"} />,
  },
  {
    name: "my attendance",
    link: "/attendance/my-attendance",
    icon: <TbClock size={"1.2rem"} />,
  },
  {
    name: "my leaves",
    link: "/leaves/my-leaves",
    icon: <IoMdLogOut size={"1.2rem"} />,
  },
  {
    name: "My holidays",
    link: "/holidays",
    icon: <GiPalmTree size={"1.2rem"} />,
  },
  {
    name: "My Payslips",
    link: "/payslips",
    icon: <CiMoneyCheck1 style={{ stroke: "black", strokeWidth: "0.5" }} size={"1.2rem"} />,
  },
];
