import { useDispatch, useSelector } from "react-redux";
import { v4 as uuid4 } from "uuid";

import { setFile } from "../../../store/slices/employment";
import UploadedImage from "./UploadedImage";
import DragAndDrop from "../../common/FileUpload/DragAndDrop";
import toast from "react-hot-toast";

import SelectDocumentType from "../SelectDocumentType";

const acceptedFileTypes = ["JPEG", "JPG", "PNG", "PDF"];

/**
 * Generates a function comment for the given function body in a markdown code block with the correct language syntax.
 *
 * @return {string} The function comment generated for the function body.
 */
const UploadDocs = () => {
  const { imageDetails, docType } = useSelector(state => state.employment.user);
  const fileName = imageDetails?.fileName;
  const dispatch = useDispatch();

  const createFileObject = filesObj => {
    //show error if file type is not selected
    if (!docType.type) toast.error("Please select document type first");
    else {
      const file = filesObj[0];

      if (!file) return;

      const { type } = file;

      const isValidType = acceptedFileTypes.includes(type.split("/")[1].toUpperCase());
      if (!isValidType) return toast.error("Only mentioned types are accepted");
      const url = URL.createObjectURL(file);
      dispatch(
        setFile({
          id: uuid4(),
          fileName: file.name,
          fileType: file.type,
          file: file,
          fileUrl: url,
        })
      );
    }
  };

  const onChange = e => {
    const file = e.target.files;
    createFileObject(file);
  };

  const onDragOver = e => {
    e.preventDefault();
  };

  const onDrop = e => {
    e.preventDefault();
    const file = e.dataTransfer.files;
    createFileObject(file);
  };

  return (
    <div className="flex flex-col justify-center gap-4">
      <SelectDocumentType />

      <DragAndDrop
        types={acceptedFileTypes}
        boldMessage="Click here to"
        normalMessage="upload or drag and drop the file"
        onDrop={onDrop}
        onDragOver={onDragOver}
        onChange={onChange}
        value={fileName}
      />

      <UploadedImage />
    </div>
  );
};

export default UploadDocs;
