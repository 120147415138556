import { useEffect, memo, forwardRef } from "react";
import { radioPrimaryClass } from "../utils/helper";

/**
 * @namespace useCheckbox this hook provides some predefined functionality for checkboxes
 * @param {function} setSelectedCheckedbox useState set function to update state
 * @param {{allChecked: boolean, checkedItems: []}} selectedCheckedbox useState returned value
 * @param {boolean} selectedCheckedbox.allChecked It'll return true when all checkboxes are selected
 * @param {(string|number)[]} selectedCheckedbox.checkedItems It'll contain all the checkedItems Ids as a string
 * @param {[{id: number|string}]} itemsList array of objects which contains all the items
 * @param {ref} selectAllRef  Checkbox ref which select all checkboxes, indeterminate have to set to true for checkbox which selects all checkboxes
 * @returns `isSelectedHandler` attach to the Checkbox component as onChangeListener
 */

export const useCheckbox = function (
  setSelectedCheckedbox,
  selectedCheckedbox,
  itemsList,
  selectAllRef
) {
  useEffect(() => {
    //checked all Select option if user select all checkbox
    const { checkedItems } = selectedCheckedbox;

    const timeout = setTimeout(() => {
      if (
        itemsList.length === checkedItems.length &&
        selectAllRef.current &&
        itemsList.length !== 0 &&
        checkedItems.length !== 0
      ) {
        selectAllRef.current.indeterminate = false;

        setSelectedCheckedbox({
          allChecked: true,
          checkedItems: selectedCheckedbox.checkedItems,
        });
        return;
      }
    }, 100);

    if (checkedItems.length >= 2 && selectAllRef.current)
      selectAllRef.current.indeterminate = true;
    else if (checkedItems.length < 2 && selectAllRef.current)
      selectAllRef.current.indeterminate = false;

    return () => clearTimeout(timeout);
  }, [selectedCheckedbox.checkedItems]);

  //if all checkboxes are marked update the checkitems everytime itemList get updated
  useEffect(() => {
    if (selectedCheckedbox.allChecked) {
      const selectedAllItems = itemsList.map((list) => list.id?.toString());

      setSelectedCheckedbox((prevSC) => {
        return {
          ...prevSC,
          checkedItems: selectedAllItems,
        };
      });
    }
  }, [itemsList]);
  /**
   *
   * @param {Object} e event object of event listener
   * @returns void
   */
  const isSelectedHandler = (e) => {
    if (e.target.id === "select-all") {
      // if all checkbox selected deselect all checkbox
      if (selectedCheckedbox.allChecked) {
        setSelectedCheckedbox({
          allChecked: false,
          checkedItems: [],
        });

        return;
      }

      //select all checkbox in the  itemsList
      const allCheckedItems = itemsList.map((h) => h.id?.toString());
      setSelectedCheckedbox({
        allChecked: true,
        checkedItems: allCheckedItems,
      });

      return;
    }

    //individual select
    const { checkedItems } = selectedCheckedbox;

    //if checkbox is selected remove that from the array
    if (checkedItems.includes(e.target.id)) {
      const filteredIds = checkedItems.filter((ids) => ids !== e.target.id);

      setSelectedCheckedbox((prevSC) => {
        return {
          ...prevSC.allChecked,
          checkedItems: filteredIds,
        };
      });

      return;
    }

    //if checkbox does not exist in array, push that into the array
    setSelectedCheckedbox((prevSC) => {
      return {
        ...prevSC.allChecked,
        checkedItems: [...checkedItems, e.target.id],
      };
    });
  };
  return {
    isSelectedHandler,
  };
};

/**
 * @namespace Checkbox — set id to `select-all` for the checkbox which selects all checkboxes
 * @param {string} props.id id of the checkbox
 * @param {string} props.name name of the checkbox
 * @param {function} props.onChange onChange event of the checkbox
 * @param {Object} props.data dataset of the checkbox
 * @param {boolean?} props.checked value of the checkbox
 * @param {boolean?} props.indeterminate value of the checkbox
 * @param {boolean?} props.disabled value of the checkbox
 * @param {string} props.label label of the checkbox
 * @param {string?} props.labelClass label class of the checkbox
 * @param {string?} props.inputClass input class of the checkbox
 * @param {string?} props.parentClass class of the checkbox container
 * @param {React.RefObject<HTMLInputElement>} ref ref of the checkbox
 *
 */
export const Checkbox = memo(
  forwardRef(
    (
      {
        id,
        onChange,
        data,
        checked,
        indeterminate,
        disabled,
        label,
        name,
        labelClass,
        inputClass,
        parentClass,
      },
      ref
    ) => {
      let allowRef = indeterminate === true ? ref : null;

      let _class =
        "cursor-pointer mr-2 w-3.5 h-3.5 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600";
      return (
        <div className={`${parentClass} flex flex-row items-center`}>
          <input
            ref={allowRef}
            id={id}
            value={id || ""}
            type="checkbox"
            name={name ?? "checkbox"}
            data-checkbox={data}
            checked={checked || false}
            onChange={onChange}
            className={`${inputClass} ${_class} ${radioPrimaryClass()}`}
            disabled={disabled}
          />
          {label && (
            <label className={labelClass} htmlFor={id}>
              {label}
            </label>
          )}
        </div>
      );
    }
  )
);
