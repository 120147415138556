import React from "react";

const CheckSvg = () => {
  return (
    <svg viewBox="0 0 512 512" className="w-6 h-6 fill-green-600">
      <path d="M256 4C116.8 4 4 116.8 4 256s112.8 252 252 252 252-112.8 252-252S395.2 4 256 4zm156 188L240.7 363.3c-6 6-13.8 9-21.6 9-7.8 0-15.7-3-21.6-9l-81.1-81.1c-12-12-12-31.3 0-43.3 12-12 31.3-12 43.3 0l59.5 59.5 149.7-149.7c12-12 31.3-12 43.3 0C424 160.6 424 180 412 192z"></path>
    </svg>
  );
};

export default CheckSvg;
