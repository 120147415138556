import { useEffect, useState, useContext } from "react";
import { getAnalytics } from "../../services/CommonService";
import { AuthContext } from "../../context/AuthContext";
import AttendanceGraph from "./AttendanceGraph/AttendanceGraph";
import CalenderViewAttendance from "./CalenderViewAttendance/CalenderViewAttendance";
import EmployeesOnLeave from "./EmployeesOnLeave/EmployeesOnLeave";
import NewJoins from "./NewJoins/NewJoins";
import QuickLinks from "./QuickLinks/QuickLinks";
import UpcomingBirthday from "./UpcomingBirthday/UpcomingBirthday";
import UpcomingHolidays from "./UpcomingHolidays/UpcomingHolidays";
import WorkAnniversary from "./WorkAnniversary/WorkAnniversary";
import Profile from "./Profile/Profile";
import Announcements from "./Announcement/Announcements";
import LoadingSpinner from "../../utils/LoadingSpinner";
import { STATUS_CODES } from "../../constants/Constant";

const noAlerts = "flex items-center justify-center";

export const Home = () => {
  const { user } = useContext(AuthContext);
  const [analytics, setAnalytics] = useState({});
  
  const announcements = analytics?.announcements ?? [];
  const employeesOnLeave = analytics?.employeesOnLeave ?? [];
  const newEmployees = analytics?.newEmployees ?? [];
  const upcomingBirthdays = analytics?.upcomingBirthdays ?? [];
  const upcomingHolidays = analytics?.upcomingHolidays ?? [];
  const workAnniversaries = analytics?.workAnniversaries ?? [];
  const quickLinks = analytics?.quickLinks ?? [];
  const workingHoursWeekly = analytics?.workHoursWeekly ?? [];

  useEffect(() => {
    getAnalytics().then(res => {
      if (res.data.statusCode === STATUS_CODES.SUCCESS) {
        setAnalytics(res.data.data);
      }
    });
  }, []);

  const render = () => {
    getAnalytics().then((res) => {
      if (res.data.statusCode === STATUS_CODES.SUCCESS) {
        setAnalytics(res.data.data);
      }
    });
  };

  return (
    <>
      <section className="min-h-screen p-4 tc-gray-bg ">
        {Object.keys(analytics).length > 0 && (
          <>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
              <div className="h-full md:col-span-2 lg:col-span-1 xl:col-span-2 order-2 md:order-1">
                <AttendanceGraph user={user} />
              </div>
              <div className="h-full order-3 md:order-2">
                <Announcements
                  announcements={announcements}
                  user={user}
                  renderAnnouncement={render}
                />
              </div>
              <div className="h-full order-1 md:order-3 lg:order-3">
                <Profile user={user} />
              </div>
              <div className="h-full order-4">
                <NewJoins newEmployees={newEmployees} />
              </div>
              <div className="h-full order-5">
                <EmployeesOnLeave employeesOnLeave={employeesOnLeave} />
              </div>
              <div className="h-full order-6">
                <CalenderViewAttendance user={user} />
              </div>
              <div className="h-full order-7">
                <QuickLinks quickLinks={quickLinks} renderQuickLink={render} />
              </div>
              <div className="h-full order-8">
                <UpcomingBirthday upcomingBirthdays={upcomingBirthdays} />
              </div>
              <div className="h-full order-9">
                <WorkAnniversary workAnniversaries={workAnniversaries} />
              </div>
              <div className="h-full order-10">
                <UpcomingHolidays upcomingHolidays={upcomingHolidays} />
              </div>
            </div>
          </>
        )}
        {Object.keys(analytics).length === 0 && <LoadingSpinner />}

      </section>
    </>
  );
};
