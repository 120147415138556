import { Checkbox } from "../../../hooks/useCheckbox";
import { Fragment } from "react";
import { checkboxOptions } from "./options";

export const AddLeaveTypeBody = ({
  handleAddLeaveType,
  handleSubmit,
  formRef,
  checkboxState,
  setCheckboxState,
}) => {
  
  const handleCheckbox = e => {
    setCheckboxState(prevState => ({
      ...prevState,
      [e.target.name]: e.target.checked,
    }));
  };

  return (
    <section className="bg-white mt-4 dark:bg-gray-900">
      <div className="-mb-12 px-4 mx-auto max-w-2xl lg:py-4 m-auto overflow-x-hidden overflow-y-auto">
        <form ref={formRef}>
          {/* Employment Type */}
          <div>
            <label
              htmlFor="leave-type"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Leave Type Name
            </label>

            <input
              type="text"
              name="name"
              id="leave-type"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-thynkwebPrimary-600 focus:border-thynkwebPrimary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-thynkwebPrimary-500 dark:focus:border-thynkwebPrimary-500"
              placeholder="Leave Type Name"
              onChange={handleAddLeaveType}
            />
          </div>

          {/* Employment Type Description */}
          <div className="mt-4">
            <label
              htmlFor="leave-type-description"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Description
            </label>

            <input
              type="text"
              name="description"
              id="leave-type-description"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-thynkwebPrimary-600 focus:border-thynkwebPrimary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-thynkwebPrimary-500 dark:focus:border-thynkwebPrimary-500"
              placeholder="Leave type description"
              onChange={handleAddLeaveType}
            />
          </div>

          <div className="flex items-center gap-[1.5rem] pt-[1rem] flex-wrap">
            {checkboxOptions.map((item, id) => {
              return (
                <Fragment key={id}>
                  <Checkbox
                    id={item.id}
                    name={item.name}
                    onChange={handleCheckbox}
                    checked={checkboxState[item.name]}
                    label={item.label}
                  />
                </Fragment>
              );
            })}
          </div>

          {/* Button to Add Employment Type */}
          <div className="mb-2 flex justify-end">
            <button
              type="submit"
              onClick={handleSubmit}
              className="px-5 py-2.5 mt-8 text-sm font-medium text-center text-white bg-thynkwebPrimary-700 rounded-lg focus:ring-4 focus:ring-thynkwebPrimary-200 dark:focus:ring-thynkwebPrimary-900 hover:bg-thynkwebPrimary-800"
            >
              Add
            </button>
          </div>
        </form>
      </div>
    </section>
  );
};
