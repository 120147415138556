export const AttendanceStatus = {
    Not_Applicable : 0,
    Present : 1,
    HalfDay : 2,
    Absent : 3,
}

export const TimeLogApprovalStatus = {
  Pending: 1,
  Approved: 2,
  Rejected: 3,
};

export const AttendanceLeaveStatus = {
  [0]: {
    key: 0,
    class: "bg-red-100 text-red-800",
    text: "Not Applicable",
  },
  [1]: {
    key: 1,
    class: "bg-green-100 text-green-800",
    text: "Present",
  },
  [2]: {
    key: 2,
    class: "bg-green-100 text-green-800",
    text: "Half Day",
  },
  [3]: {
    key: 3,
    class: "bg-red-100 text-red-800",
    text: "Absent",
  },
  [4]: {
    key: 4,
    class: "bg-yellow-100 text-yellow-800",
    text: "Leave",
  },
  [5]: {
    key: 5,
    class: "bg-blue-100 text-blue-800",
    text: "Week Off",
  },
  [6]: {
    key: 6,
    class: "bg-gray-100 text-gray-800",
    text: "Holiday",
  },
  [7]: {
    key: 7,
    class: "bg-green-100 text-green-800",
    text: "Present (Holiday)",
  },
  [8]: {
    key: 8,
    class: "bg-green-100 text-green-800",
    text: "Present (Leave)",
  },
  [9]: {
    key: 9,
    class: "bg-green-100 text-green-800",
    text: "Present (WeekOff)",
  },
};


// export const AttendanceStatus = {
//   Not_Applicable: 0,
//   Present: 1,
//   Absent: 2,
//   Leave: 3,
//   WeekOff: 4,
//   Holiday: 5,
//   Present_holiday: 6,
//   Present_leave: 7,
//   Present_weekoff: 8,
// };
