import { updateCustomFields } from "../../../services/InvoiceService";

const InvoiceHeader = ({ setFormValues, formValues, updateToggleState, toggleStates }) => {
  //on blur invoice header
  const onInvoiceHeaderBlur = async e => {
    const headerDetails = {
      id: formValues.invoiceHeader?.id,
      fieldName: formValues.invoiceHeader?.fieldName,
    };
    //only save invoice header if there's some value
    if (e.target.value.trim() !== "") await updateCustomFields(headerDetails, true);
    else
      setFormValues(prevFV => ({
        ...prevFV,
        invoiceHeader: { ...prevFV.invoiceHeader, fieldName: "Tax Invoice" },
      }));
    updateToggleState("isChangeHeader", false);
  };

  //on change invoice header
  const onInvoiceHeaderChange = e => {
    setFormValues(prevFV => {
      return {
        ...prevFV,
        invoiceHeader: {
          ...prevFV.invoiceHeader,
          fieldName: e.target.value,
        },
      };
    });
  };

  return (
    <header className="pt-8 mx-auto p-[0.9rem] text-center z-20">
      {toggleStates.isChangeHeader && (
        <div className="w-[100%] sm:w-[30%] sm:m-auto">
          <input
            type="text"
            value={formValues.invoiceHeader?.fieldName || ""}
            onChange={onInvoiceHeaderChange}
            onBlur={onInvoiceHeaderBlur}
            autoFocus={true}
            className="outline-none focus:ring-0 border-t-0 border-r-0 border-l-0 w-full max-sm:p-0"
          />
        </div>
      )}
      {!toggleStates.isChangeHeader && (
        <h1
          className="invoice-text underline decoration-dashed decoration-[grey] underline-offset-[0.4rem] text-3xl font-medium decoration-[0.061rem] w-fit m-auto text-[#474b53] cursor-pointer"
          onClick={() => updateToggleState("isChangeHeader", true)}>
          {formValues.invoiceHeader?.fieldName}
        </h1>
      )}
    </header>
  );
};

export default InvoiceHeader;
