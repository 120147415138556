import { Tabs } from "flowbite-react";
import { EmploymentTypesTab } from "./EmploymentTypesTab/EmploymentTypesTab";
import { LeaveTypesTab } from "./LeaveTypesTab/LeaveTypesTab";

const LeaveConfigurations = () => {

  return (
    <section className="bg-white dark:bg-gray-900 py-3 mt-6 sm:py-5 ">
    <div className="  px-4 mx-auto max-w-screen-2xl lg:px-12">
      <div className=" text-gray-900 dark:text-white tab-payslip-links">
        <Tabs aria-label="Default tabs">
          <Tabs.Item active title="Employment Types">
            <EmploymentTypesTab />
          </Tabs.Item>
          <Tabs.Item title="Leave Types">
            <LeaveTypesTab />
          </Tabs.Item>
        </Tabs>
      </div>
    </div>
  </section>
  )
};

export default LeaveConfigurations;
