import { FaChevronLeft } from "react-icons/fa";

/**
 * Generates the function comment for the given function body.
 *@param {{onClick: function, text: string, customIcon: (svg | image | JSX.Element), styles: {iconContClassName: string, iconColor: string, iconSize: number, textClassName: string, buttonClass: string}}} props - The props for the button.
 * @param {Object} onClick - The click event handler.
 * @param {string} text - The text to display on the button.
 * @param {(svg | image | JSX.Element)} customIcon - Custom Icon.
 * @param {{iconContClassName: string, iconColor: string, iconSize: number, textClassName: string, buttonClass: string}} styles - The styles to apply to the button.
 * @return {JSX.Element} The JSX element representing the button with back icon.
 */
const BackButton = ({ onClick, text, customIcon, styles }) => {
  return (
    <button
      className={`${styles?.buttonClass} text-[1rem] text-gray-500 font-semibold flex items-center gap-2 mt-2`}
      onClick={onClick}>
      <div className={styles?.iconContClassName}>
        {customIcon && customIcon}
        {!customIcon && <FaChevronLeft color={styles?.iconColor} size={styles?.iconSize} />}
      </div>
      <span className={styles?.textClassName}>{text}</span>
    </button>
  );
};
export default BackButton;
