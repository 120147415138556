import { useState, useRef, useEffect } from "react";

import ModalComponent from "../../../common/Modal/Modal";
import SidebarComponent from "../../../common/Sidebar/Sidebar";
import Tabs from "./Sidebar/Tabs/Tabs";

import { DEFAULT_FILE_SIZE_LIMIT_IN_BYTES, STATUS_CODES } from "../../../../constants/Constant";
import {
  addBusinessLogo,
  createBusiness,
  deleteBusinessLogo,
  editBusinesses,
  getBusinessById,
} from "../../../../services/BusinessService";
import { createAddressStr, setModalHeading, sidebarTabs } from "../../helper";
import { trimValues } from "../../../../utils/helper";
import { toast } from "react-hot-toast";
import { showToast } from "../../../common/Toast";
import { ADD_BUSINESS_MESSAGES, FILE_MESSAGES } from "../../../../constants/Messages";

const initialData = {
  clientLogo: null,
  logoBytes: null,
  name: "",
  address: "",
};

const initialFileDataState = {
  url: null,
  file: null,
};

/**
 * Function for adding a new business.
 *
 * @param {object} show - boolean flag to control the visibility of the modal
 * @param {function} setToggleStates - function to set the toggle states
 * @param {boolean} isClient - boolean flag to indicate if the client is active
 * @param {string} modal - string representing the type of modal
 * @param {string} clientUpdateId - string representing the client update ID
 * @param {boolean} isClientUpdate - boolean flag to indicate if it's a client update
 * @param {function} resetClientUpdateId - function to reset the client update ID
 * @return {JSX.Element} The modal component for adding a new business
 */
const AddBusiness = ({
  show,
  setToggleStates,
  isClient,
  modal,
  clientUpdateId,
  isClientUpdate,
  resetClientUpdateId,
  billedToModal,
}) => {
  const [fileData, setFileData] = useState(initialFileDataState);
  const formRef = useRef(null);
  const [addNewClientData, setAddNewClientData] = useState({ ...initialData });
  const [activeItem, setActiveItem] = useState("basicInfo");
  const [prevUploadedLogo, setPrevUploadedLogo] = useState(null);

  useEffect(() => {
    if (clientUpdateId) {
      getBusinessById(clientUpdateId).then(response => {
        if (response.data) {
          setAddNewClientData({
            ...response.data,
          });
        }
      });
    }
  }, [clientUpdateId]);

  const handleAddNewClientData = e => {
    let value = e.target.value;
    const name = e.target.name;

    if (name === "ifsc" || name === "pan") value = value.toUpperCase();

    setAddNewClientData({
      ...addNewClientData,
      [name]: value,
    });
  };

  const handleFileChange = event => {
    const selectedFile = event.target.files[0];

    const fileSizeLimit = DEFAULT_FILE_SIZE_LIMIT_IN_BYTES;
    if (
      selectedFile &&
      (selectedFile.type === "image/png" ||
        selectedFile.type === "image/jpeg" ||
        selectedFile.type === "image/jpg")
    ) {
      const link = URL.createObjectURL(selectedFile);
      const isFileValid = selectedFile.size <= fileSizeLimit;

      if (isFileValid) {
        setFileData({ url: link, file: selectedFile });

        if (addNewClientData.logoBytes) {
          setPrevUploadedLogo(addNewClientData.logoBytes);

          //change the logo bytes to null
          setAddNewClientData(prevANCD => ({ ...prevANCD, logoBytes: null }));

          showToast("success", ADD_BUSINESS_MESSAGES.LOGO_CHANGES_NOT_SAVED, {
            duration: 3000,
          });
        } else if (prevUploadedLogo) {
          showToast("success", ADD_BUSINESS_MESSAGES.LOGO_CHANGES_NOT_SAVED, {
            duration: 3000,
          });
        }
      }
      if (!isFileValid) {
        setFileData(initialFileDataState);
        showToast("error", FILE_MESSAGES.BUSINESS_LOGO_SIZE);
      }
    } else {
      setFileData(initialFileDataState);
      formRef.current.reset();
      showToast("error", FILE_MESSAGES.FILE_TYPE_ERROR, { duration: 3000 });

      //if user does not select the file again then set the previously uploaded file again
      if (prevUploadedLogo)
        setAddNewClientData(prevANCD => ({ ...prevANCD, logoBytes: prevUploadedLogo }));
    }
  };

  const handleSubmit = async e => {
    e.preventDefault();
    let res = null;

    if (!addNewClientData.name || addNewClientData.name.trim() === "")
      return toast("Business Name Required");

    const address = createAddressStr(addNewClientData);

    //trim values
    const trimmedObj = trimValues({ ...addNewClientData, address });

    if (clientUpdateId) {
      res = await updateBusiness(clientUpdateId, trimmedObj);
      updateBusinessState("_", res.statusCode, `${ADD_BUSINESS_MESSAGES.UPDATE}`);
    } else {
      res = await createBusiness({
        ...trimmedObj,
        postalCode: trimmedObj?.postalCode ? +trimmedObj.postalCode : null,
        isClient: isClient,
      });

      if (res.statusCode !== STATUS_CODES.SUCCESS) return showToast("error", res.message);

      updateBusinessState(ADD_BUSINESS_MESSAGES.SUCCESS, res.statusCode);
    }
    let businessId;
    if (clientUpdateId) {
      businessId = clientUpdateId;
    } else {
      businessId = res.data.id;
    }

    if (businessId && fileData.file) {
      const res = await addBusinessLogo(businessId, fileData.file);
      if (res.data.statusCode !== STATUS_CODES.SUCCESS)
        showToast("error", ADD_BUSINESS_MESSAGES.LOGO_UPLOAD_FAILED);
    }
    onCloseModalHandler();
  };

  const updateBusinessState = async (message, statusCode, customMessage) => {
    if (statusCode === STATUS_CODES.SUCCESS) {
      setToggleStates(prevTS => ({ ...prevTS, isChange: !prevTS.isChange }));
      customMessage
        ? showToast("success", customMessage)
        : showToast("success", `${isClient ? "Billed To" : "Billed By"} ${message}`);
    }
  };

  const updateBusiness = async (businessId, trimmedObj) => {
    const editBusinessResponse = await editBusinesses({
      ...trimmedObj,
      postalCode: trimmedObj?.postalCode ? +trimmedObj.postalCode : null,
      businessId: businessId,
    });
    return editBusinessResponse;
  };

  const onCloseModalHandler = () => {
    setFileData(initialFileDataState);
    setToggleStates(prevTS => ({
      ...prevTS,
      [modal]: false,
      isClient: false,
      isClientUpdate: false,
    }));

    formRef.current.reset();
    setActiveItem("basicInfo");
    setAddNewClientData(initialData);
    resetClientUpdateId && resetClientUpdateId();
  };

  const onDeleteLogoHandler = async id => {
    setFileData(initialFileDataState);
    showToast("success", ADD_BUSINESS_MESSAGES.REMOVE_UNSAVED_LOGO);
    formRef.current.reset();
  };

  return (
    <div>
      {/* Modal for AddBusiness */}
      <ModalComponent
        show={clientUpdateId ? true : show}
        size="4xl"
        showFooter={false}
        onClose={onCloseModalHandler}
        heading={setModalHeading(clientUpdateId, isClient, isClientUpdate, billedToModal)}>
        <form ref={formRef} className="flex gap-4" encType="multipart/form-data">
          <SidebarComponent
            options={sidebarTabs}
            activeItem={activeItem}
            disabled={addNewClientData?.name.trim() === ""}
            handleSubmit={handleSubmit}
            isEditing={clientUpdateId ? true : false}
            onClickSideBarItem={setActiveItem}
          />

          <div className="border mx-2"></div>

          <Tabs
            isClient={isClient}
            handleFileChange={handleFileChange}
            onInputChange={handleAddNewClientData}
            details={addNewClientData}
            activeItem={activeItem}
            fileData={fileData}
            onDeleteLogoHandler={onDeleteLogoHandler}
          />
        </form>
      </ModalComponent>
    </div>
  );
};

export default AddBusiness;
