import React from "react";
import { JOBS_INPUT_FIELDS } from "../../../constants/Constant";
import AsyncSelect from "react-select/async";
import { customStyles } from "../../Settings/helper/helper";
import InputFields from "../../common/Input/InputFields";
import { Select as FlowbiteSelect } from "flowbite-react";
import TextArea from "../../common/Input/TextArea";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import MandatorySign from "../../common/Input/MandatorySign";
const animatedComponents = makeAnimated();

const AddJobInputs = ({
  onChangeJobDetails,
  handleAssigneesChange,
  debouncedProjectPromiseOptions,
  jobFormDetails,
  handleProjectChange,
  assignees,
}) => {
  return (
    <>
      {JOBS_INPUT_FIELDS.map((item, index) => {
        if (item.name === "projectName") {
          return (
            <div className="" key={index}>
              <label
                htmlFor="project-name"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                {item.label}
                <MandatorySign />
              </label>
              <AsyncSelect
                key={index}
                name={item.name}
                cacheOptions
                defaultOptions
                isMulti={false}
                id="project-name"
                placeholder="Search project"
                onChange={handleProjectChange}
                styles={customStyles()}
                value={jobFormDetails?.projectName}
                loadOptions={debouncedProjectPromiseOptions}
                filterOption={() => true}
                closeMenuOnSelect={true}
                isSearchable={true}
              />
            </div>
          );
        }

        if (item.name === "isBillable") {
          return (
            <div className="w-full" key={index}>
              <label
                htmlFor="billable-status"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white cursor-pointer">
                {item.label}
              </label>
              <div className="max-w-md ">
                <FlowbiteSelect
                  key={index}
                  id="billable-status"
                  name="isBillable"
                  value={jobFormDetails?.isBillable}
                  onChange={onChangeJobDetails}>
                  <option hidden={true}>Select</option>
                  <option value={true}>Billable</option>
                  <option value={false}>Non-billable</option>
                </FlowbiteSelect>
              </div>
            </div>
          );
        }

        if (item.name === "assignees") {
          return (
            <div className="" key={index}>
              <label
                htmlFor="user-Assignees"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                {item.label}
              </label>
              {jobFormDetails?.employeeJobAssociations.length > 0 && (
                <Select
                  cacheOptions
                  defaultOptions
                  components={animatedComponents}
                  defaultValue={jobFormDetails?.employeeJobAssociations}
                  isMulti={true}
                  id="user-Assignees"
                  placeholder="Search user"
                  options={assignees}
                  onChange={handleAssigneesChange}
                  styles={customStyles()}
                  filterOption={() => true}
                  closeMenuOnSelect={true}
                  isSearchable={true}
                />
              )}

              {jobFormDetails?.employeeJobAssociations.length === 0 && (
                <Select
                  cacheOptions
                  defaultOptions
                  components={animatedComponents}
                  defaultValue={[]}
                  isMulti={true}
                  id="user-Assignees"
                  placeholder="Search user"
                  options={assignees}
                  onChange={handleAssigneesChange}
                  styles={customStyles()}
                  filterOption={() => true}
                  closeMenuOnSelect={true}
                  isSearchable={true}
                />
              )}
            </div>
          );
        }
        if (item.name === "description") {
          return (
            <TextArea
              key={index}
              id={item.id}
              name={item.name}
              label={item.label}
              value={jobFormDetails.description}
              onChange={onChangeJobDetails}
              placeholder={item.placeholder}
              styles={{
                textAreaContainer: "col-span-2",
                textAreaLabelClassName: "",
                textAreaInputClassName: "",
              }}
            />
          );
        }
        return (
          <InputFields
            key={index}
            InputField={item}
            value={jobFormDetails[item.name]}
            onChange={onChangeJobDetails}
            inputClassName={"w-full"}
            parentClassName={"w-full"}
          />
        );
      })}
    </>
  );
};

export default AddJobInputs;
