import { useContext, useEffect, useState } from "react";
import { myLeaveAdminOptions, myLeaveOptions } from "../../components/Leaves/helper";
import LeftMenu from "../../components/Layout/LeftMenu/LeftMenu";
import { Outlet, useLocation } from "react-router";
import InputFields from "../../components/common/Input/InputFields";
import { GetReportingUsers, GetUsers } from "../../services/UserService";
import { Avatar } from "flowbite-react";
import { useSearchParams } from "react-router-dom";
import { cn } from "../../utils/common";
import { IoIosArrowForward } from "react-icons/io";
import { IoSearch } from "react-icons/io5";
import { AuthContext } from "../../context/AuthContext";
import { Role } from "../../enum/Role";
import Toggle from "../../components/common/Toggle/Toggle";
import { DEFAULT_PAGE_NO, DEFAULT_PAGE_SIZE } from "../../constants/Constant";

export const LeaveManagement = () => {
  //setting active leaves sidebar details
  const [activeSidebarItem, setActiveSidebarItem] = useState("my-leaves");
  const [isModalActive, setToggleModal] = useState(false);

  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filteredUser, SetFilteredUser] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const { user } = useContext(AuthContext);
  const selectedUserParam = searchParams.get("approvalUserId");

  const [approvalsView, setApprovalsView] = useState(true);

  //State to toggle 'View all User Checkbox'
  const [isChecked, setIsChecked] = useState(false);

  //State to store the searchText from the search input field
  const [searchText, setSearchText] = useState("");

  const location = useLocation();

  const isAdmin = user?.roleId === Role.Administrator;
  const isModerator = user?.roleId === Role.Moderator;

  //useEffect hook to get reportToMe users
  useEffect(() => {
    getReportToMeUsers();
  }, [searchText, isChecked]);

  const closeModal = () => {
    setToggleModal(false);
  };

  const openModal = () => {
    setToggleModal(true);
  };

  //function to get sidebar details
  const getSidebarDetails = activeItem => {
    setActiveSidebarItem(prev => {
      return activeItem?.url;
    });
  };

  // Access specific parts of the location
  const pathname = location.pathname;

  const isApprovalsSelected = pathname === "/leaves/my-approvals";

  const handleSearch = event => {
    const searchTerm = event.target.value.toLowerCase();

    setSearchText(searchTerm);

    if (searchTerm === "") {
      // If search term is empty, show all user
      SetFilteredUser([]);
    } else {
      // If there's a search term, filter the users based on firstName and lastName
      const filteredUsers = users.filter(
        user =>
          user.firstName.toLowerCase().includes(searchTerm) ||
          user.lastName.toLowerCase().includes(searchTerm)
      );
      SetFilteredUser(filteredUsers);
    }
  };

  const handleMyApprovals = function (user) {
    setSearchParams({ approvalUserId: user.id });
  };

  //Function to fetch Report to me users to show in the left menu where users are shown.
  const getReportToMeUsers = async () => {
    const response = await GetReportingUsers(
      searchText,
      isChecked,
      DEFAULT_PAGE_SIZE,
      DEFAULT_PAGE_NO
    );
    setUsers(response?.data?.data ? response?.data?.data : []);
    setLoading(false);

    if (response.data.data.length === 0) {
      setApprovalsView(false);
    }
  };

  //Function to toggle checkbox for users to show in the left menu where users are shown.
  const onToggleUserCheckbox = event => {
    setIsChecked(event.target.checked);
  };

  return (
    <div className="flex">
      {(isAdmin || isModerator) && <LeftMenu sidebarListItem={myLeaveAdminOptions} />}

      {approvalsView ? (
        <>{!isAdmin && !isModerator && <LeftMenu sidebarListItem={myLeaveOptions} />}</>
      ) : null}

      {/* leaves details */}
      <div className="my-leaves-right-list-section h-full relative ">
        {isApprovalsSelected && (
          <div className="w-56 fixed z-9">
            <section className="flex items-center space-x-4 mx-5 mt-4 mb-2">
              <div className="mb-2 w-full relative">
                <InputFields
                  inputClassName="w-full"
                  InputField={{
                    label: "",
                    type: "search",
                    id: "search",
                    name: "search",
                    placeholder: "Search a user",
                  }}
                  value={searchText}
                  icon={<IoSearch />}
                  onChange={handleSearch}
                />
                {isAdmin && (
                  <div className="flex items-center justify-start mt-3">
                    <Toggle
                      checked={isChecked}
                      onChange={onToggleUserCheckbox}
                      name={"userCheckbox"}
                      id={"userCheckbox"}
                    />
                    View all users
                  </div>
                )}
              </div>
            </section>

            <div className="h-screen pl-1 pr-2">
              <div className="w-full">
                {loading ? (
                  <p className="m-8">Loading...</p>
                ) : (
                  <ul>
                    {filteredUser.length > 0 &&
                      filteredUser.map(user => {
                        return (
                          <li
                            className="p-2 cursor-pointer"
                            key={user.id}
                            onClick={() => handleMyApprovals(user)}>
                            <div className="flex items-center">
                              <div className="flex-shrink-0">
                                <Avatar
                                  rounded={true}
                                  className="mx-2 z-auto cursor-pointer border rounded-[50%]"
                                  placeholderInitials={`${user?.firstName && user?.firstName[0].toUpperCase()}${
                                    user?.lastName && user?.lastName[0].toUpperCase()
                                  }`}
                                  img={
                                    user?.imageBytes &&
                                    `data:image/png;base64, ${user?.imageBase64}`
                                  }
                                />
                              </div>
                              <div className="flex-1 min-w-0 ms-4">
                                <p
                                  className={`${cn("text-gray-900", {
                                    "text-[#76ABAE]": user.id === +selectedUserParam,
                                  })}
                              text-sm font-medium truncate transition-colors dark:text-white flex justify-between items-center`}>
                                  {user.firstName + " " + user.lastName}{" "}
                                  {user.id === +selectedUserParam && (
                                    <IoIosArrowForward color="#76ABAE" size={14} />
                                  )}
                                </p>
                                <p className="text-sm text-gray-500 truncate dark:text-gray-400">
                                  {user.email}
                                </p>
                              </div>
                            </div>
                          </li>
                        );
                      })}

                    {filteredUser.length === 0 &&
                      users.map(user => (
                        <li
                          className="p-2 cursor-pointer"
                          key={user.id}
                          onClick={() => handleMyApprovals(user)}>
                          <div className="flex items-center">
                            <div className="flex-shrink-0">
                              <Avatar
                                rounded={true}
                                className="mx-2 z-auto cursor-pointer border rounded-[50%]"
                                placeholderInitials={`${user?.firstName && user?.firstName[0].toUpperCase()}${
                                  user?.lastName && user?.lastName[0].toUpperCase()
                                }`}
                                img={
                                  user?.imageBytes && `data:image/png;base64, ${user?.imageBase64}`
                                }
                              />
                            </div>
                            <div className="flex-1 min-w-0">
                              <p
                                className={`${cn("text-gray-900", {
                                  "text-[#76ABAE]": user.id === +selectedUserParam,
                                })}
                              text-sm font-medium truncate transition-colors dark:text-white flex justify-between items-center`}>
                                {user.firstName + " " + user.lastName}{" "}
                                {user.id === +selectedUserParam && (
                                  <IoIosArrowForward color="#76ABAE" size={18} />
                                )}
                              </p>
                              <p className="text-xs text-gray-500 truncate dark:text-gray-400">
                                {user.email}
                              </p>
                            </div>
                          </div>
                        </li>
                      ))}
                  </ul>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
      <div className={`my-leaves-right-list-section h-full w-full`}>
        {/* My leaves content details */}
        {/* All the different route will be shown here */}
        {/* Outlet is given by react router dom  */}
        <Outlet />
      </div>
    </div>
  );
};
