import React from 'react'
import { Oval } from 'react-loader-spinner'

const LoadingSpinner = () => {
    return (
        <div className='fixed top-[50%] left-[50%] z-[9999]'>
            <Oval
                height={60}
                width={60}
                color="#2563eb"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
                ariaLabel="oval-loading"
                secondaryColor="#4fa94d"
                strokeWidth={3}
                strokeWidthSecondary={3}
            />
        </div>
    )
}

export default LoadingSpinner