import { useContext } from "react";
import { Navigate, useSearchParams } from "react-router-dom";
import { Route, Routes, useLocation } from "react-router";
import { useStore } from "../context/createFastContext";
import RootLayout from "../pages/Root/RootLayout";
import RequiredDetailsRoot from "../pages/Root/RequiredDetailsRoot";
import { Home } from "../pages/Home/Home";
import Register from "../pages/Register/Register";
import { Login } from "../pages/Login";
import ForgotPassword from "../pages/ForgotPassword";
import ResetPassword from "../pages/ResetPassword";
import { Profile } from "../pages/Profile";
import { EmployeeManagement } from "../pages/EmployeeManagement";
import ControlPanel from "../pages/ControlPanel";
import SkillManagement from "../pages/SkillManagement";
import InvoiceManagement from "../pages/Invoice/InvoiceManagement";
import UpdateInvoice from "../pages/Invoice/UpdateInvoice";
import CreateInvoice from "../pages/Invoice/CreateInvoice";
import { PageNotFound } from "../components/common/PageNotFound";
import Pricing from "../pages/Pricing";
import AboutUs from "../pages/AboutUs";
import Checkout from "../pages/payments/Checkout";
import ContactUs from "../pages/ContactUs";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import TermsAndConditions from "../pages/TermsAndConditions";
import CancellationAndRefundPolicy from "../pages/CancellationAndRefundPolicy";
import Holidays from "../pages/Holidays/Holidays";
import LeaveConfigurations from "../pages/LeaveConfigurations/LeaveConfigurations";
import SalaryComponents from "../pages/SalaryComponents/SalaryComponents";
import OrganizationDetails from "../pages/OrganizationDetails/OrganizationDetails";
import ExceptionLogs from "../pages/ExceptionLog";
import MultiStepForm from "../pages/MultiStepForm/MultiStepForm";
import AccessDeniedPage from "../components/common/AccessDeniedPage";
import Payroll from "../pages/Payroll/Payroll";
import ViewDetails from "../components/Payroll/ViewDetails";
import { LeaveManagement } from "../pages/LeaveManagement/LeaveManagement";
import { AttendanceManagement } from "../pages/Attendance Management/AttendanceManagement";
import { PaySlips } from "../components/payslips/PaySlips";
import { Role } from "../enum/Role";
import { AuthContext } from "../context/AuthContext";
import { MyLeaves } from "../components/Leaves/MyLeaves";
import { MyApprovals } from "../components/Leaves/MyApprovals";
import CustomizeBalance from "../components/Leaves/CustomizeBalance";
import LeaveTypes from "../components/Leaves/LeaveTypes";
import Resignations from "../pages/Resignations";
import Roles from "../pages/Roles/Roles";
import VerifyUser from "../pages/VerifyUser";
import Project from "../pages/Project/Project";
import Clients from "../components/Attendance/Clients/Clients";
import Job from "../pages/Jobs/Job";
import LogoBranding from "../pages/Logo-Branding/LogoBranding";
import MyAttendance from "../components/Attendance/MyAttendance/MyAttendance";
import Approvals from "../components/Attendance/Approvals/Approvals";
import PayrollHistory from "../components/Payroll/PayrollHistory";
import PayrollRun from "../components/Payroll/PayrollRun";
import Invoice from "../pages/Invoice/Invoice";
import Businesses from "../pages/Invoice/Businesses";
import SalaryComponentManagement from "../pages/SalaryComponents/SalaryComponentManagement";
import StatutoryComponent from "../pages/SalaryComponents/StatutoryComponent";

const AllRoutes = () => {
  const { user, userAuth } = useContext(AuthContext);
  const [searchParam, setSearchP] = useSearchParams();

  return (
    <Routes>
      <Route
        path="/login"
        element={
          <LoginRoute user={user} userAuth={userAuth}>
            <Login />
          </LoginRoute>
        }
      />

      <Route path="/register" element={<Register />} />

      <Route path="/forgot-password" element={<ForgotPassword />} />

      <Route path="/reset-password/:token/:email" element={<ResetPassword />} />

      <Route path="/verify-user/:token/:email" element={<VerifyUser />} />

      <Route path="/reset-password" element={<ResetPassword />} />

      <Route path="/verify-user" element={<VerifyUser />} />

      <Route path="/required-details" element={<RequiredDetailsRoot />}>
        <Route
          index
          element={
            <RequiredDetailsRoute user={user}>
              <MultiStepForm />
            </RequiredDetailsRoute>
          }
        />
      </Route>

      {/* Protected routes */}

      <Route
        path="/"
        element={
          <ProtectedRoute user={user} userAuth={userAuth}>
            <RootLayout />
          </ProtectedRoute>
        }>
        <Route index element={<Home />} />

        <Route path="profile" element={<Profile />} />

        <Route path="my-attendance" element={<AttendanceManagement />} />

        <Route path="payslips" element={<PaySlips />} />

        <Route path="holidays" element={<Holidays />} />

        {(user?.roleId === Role.Administrator || user?.roleId === Role.Moderator) && (
          <>
            <Route path="control-panel" element={<ControlPanel />}>
              <Route index path="users-management" element={<EmployeeManagement />} />

              <Route index path="Roles" element={<Roles />} />

              <Route path="leave-configurations" element={<LeaveConfigurations />} />

              <Route path="salary-components" element={<SalaryComponents />} />

              <Route path="organisation-details" element={<OrganizationDetails />} />

              <Route path="exception-logs" element={<ExceptionLogs />} />

              <Route path="skill-management" element={<SkillManagement />} />

              <Route path="resignations" element={<Resignations />} />

              <Route path="logo-branding" element={<LogoBranding />} />
            </Route>

            <Route path="invoices" element={<InvoiceManagement />}>
              <Route index path="allInvoices" element={<Invoice />} />
              <Route index path="businesses" element={<Businesses />} />
              <Route index path="clients" element={<Clients />} />
            </Route>

            <Route path="Invoice/create" element={<CreateInvoice />} />

            <Route path="Invoices/:id" element={<UpdateInvoice />} />

            <Route path="payroll" element={<Payroll />}>
              <Route index path="payroll-run" element={<PayrollRun />} />

              <Route path="payroll-history" element={<PayrollHistory />} />
            </Route>

            <Route path="payroll/:id" element={<ViewDetails />} />

            <Route path="settings">
              <Route path="users" element={<EmployeeManagement />} />
              <Route path="roles" element={<Roles />} />
              <Route path="payroll-components" element={<SalaryComponentManagement />}>
                <Route path="salary" element={<SalaryComponents />} />
                <Route path="statutory" element={<StatutoryComponent />} />
              </Route>
              <Route path="organisation-details" element={<OrganizationDetails />} />
              <Route path="exception-logs" element={<ExceptionLogs />} />
              <Route path="skills-management" element={<SkillManagement />} />
              <Route path="logo-branding" element={<LogoBranding />} />
            </Route>
          </>
        )}

        {/* Protected routes for LeaveManagement */}

        <Route path="leaves" element={<LeaveManagement />}>
          <Route index path="my-leaves" element={<MyLeaves />} />

          <Route path="my-approvals" element={<MyApprovals />} />

          <Route path="leave-types" element={<LeaveTypes />} />

          <Route path="customize-balance" element={<CustomizeBalance />} />
        </Route>

        {/* Protected routes for AttendanceManagement */}

        <Route path="attendance" element={<AttendanceManagement />}>
          <Route index path="my-attendance" element={<MyAttendance />} />

          <Route path="approvals" element={<Approvals />} />

          {(user?.roleId === Role.Administrator || user?.roleId === Role.Moderator) && (
            <>
              <Route path="jobs" element={<Job />} />

              <Route path="projects" element={<Project />} />

              <Route path="clients" element={<Clients />} />
            </>
          )}
        </Route>

        {/* Other public routes */}

        <Route path="pricing" element={<Pricing />} />

        <Route path="about-us" element={<AboutUs />} />

        <Route path="checkout" element={<Checkout />} />

        <Route path="contact-us" element={<ContactUs />} />

        <Route path="privacy-policy" element={<PrivacyPolicy />} />

        <Route path="terms-and-conditions" element={<TermsAndConditions />} />

        <Route path="cancellation-and-refund-policy" element={<CancellationAndRefundPolicy />} />
      </Route>

      <Route path="/403-Forbidden" element={<AccessDeniedPage />} />

      {/* Rendering the 'PageNotFound' component when the URL doesn't match other routes. */}
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
};

export default AllRoutes;

const LoginRoute = ({ children, user }) => {
  if (user?.token) {
    return <Navigate to="/" replace />;
  }

  return <>{children}</>;
};

const ProtectedRoute = ({ children, user }) => {
  const location = useLocation();

  let url = "/login?redirect=" + location.pathname + location.search;

  if (location.pathname === "/" || location.pathname === "/login") {
    url = "/login";
  }

  if (user?.token) {
    return <>{children}</>;
  } else if (!user && location.search) {
    return <Navigate to={url} replace />;
  } else {
    return <Navigate to={url} state={{ prevUrl: location.pathname }} replace />;
  }
};

const RequiredDetailsRoute = ({ children, user }) => {
  const [initialContextState, _] = useStore(s => s);
  if (initialContextState.orgCompletionStatus.isCompleted) {
    return <Navigate to="/" replace />;
  }

  if (user && user?.roleId && user.roleId === Role.Administrator) {
    return <>{children}</>;
  }

  return <Navigate to="/" replace />;
};
