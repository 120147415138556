import { memo } from "react";
import { radioPrimaryClass } from "../../../utils/helper";

const Radio = memo(
  ({
    id = "",
    name = "",
    value = "",
    label = null,
    onChange,
    labelClass,
    radioClass,
    parentClass,
    checked,
    additionalProps,
  }) => {
    return (
      <div className={`${parentClass} flex items-center gap-[.3rem]`}>
        <input
          onChange={onChange}
          type="radio"
          className={`${radioClass} ${radioPrimaryClass()}`}
          id={id}
          name={name}
          value={value}
          checked={checked}
          {...additionalProps}
        />
        {label && (
          <label
            className={`${labelClass} text-sm`}
            htmlFor={id}>
            {label}
          </label>
        )}
      </div>
    );
  }
);

export default Radio;
