import { axiosInstance as axios } from "../context/AuthContext";

const baseUrl = process.env.REACT_APP_BASE_URL;
const leavesBaseUrl = `${baseUrl}/Leaves`;

//Create Leave Rules
export const CreateLeaveRules = async leavesInfo => {
  const response = await axios({
    url: `${leavesBaseUrl}/rules`,
    method: "POST",
    data: JSON.stringify(leavesInfo),
  });
  return response;
};

//Get Leave Rules
export const GetLeaveRules = async (pageNumber, pageSize) => {
  const response = await axios
    .get(`${leavesBaseUrl}/rules?pageNumber=${pageNumber}&pageSize=${pageSize}`)
    .then(response => {
      return response.data;
    });

  return response;
};

//Get Leave Rules By Id
export const GetLeaveRuleById = async leaveRuleId => {
  const res = await axios.get(`${leavesBaseUrl}/rules/${leaveRuleId}`);
  const data = await res.data;
  return data;
};

/**
 * Retrieves a leave by its ID.
 *
 * @param {string} leaveId - The ID of the leave to retrieve.
 * @return {Promise<any>} The data of the retrieved leave.
 */
export const getLeaveById = async leaveId => {
  const res = await axios.get(`${leavesBaseUrl}/${leaveId}`);
  const data = await res.data;
  return data;
};

//Update Leave Rules
export const EditLeaveRule = async leavesInfo => {
  const response = await axios({
    url: `${leavesBaseUrl}/rules`,
    method: "PUT",
    data: JSON.stringify(leavesInfo),
  });
  return response;
};

//Delete leave Rules
export const DeleteLeaveRuleById = async leaveRuleId => {
  const response = await axios.delete(`${leavesBaseUrl}/rules/${leaveRuleId}`);
  return response;
};

// Get current user leaves
export const GetCurrentUserLeaves = async (pageNumber, pageSize, isMyApproval) => {
  const response = await axios
    .get(
      `${leavesBaseUrl}?pageNumber=${pageNumber}&pageSize=${pageSize}&isMyApproval=${isMyApproval}`
    )
    .then(response => {
      return response.data;
    });
  return response;
};

// Get leaves of users under the current user
export const GetUserLeaves_UnderCurrentUser = async (
  pageNumber,
  pageSize,
  isMyApproval,
  userId
) => {
  const response = await axios
    .get(
      `${leavesBaseUrl}?pageNumber=${pageNumber}&pageSize=${pageSize}&isMyApproval=${isMyApproval}&userId=${userId}`
    )
    .then(response => {
      return response.data;
    });
  return response;
};

// Apply for Leave
export const ApplyLeave = async appliedLeaveData => {
  const response = await axios.post(`${leavesBaseUrl}`, appliedLeaveData).then(response => {
    return response;
  });

  return response.data;
};

// Status of leaves
export const statusOfLeaves = async leaveData => {
  const { leaveId, status } = leaveData[0];

  const response = await axios
    .put(`${leavesBaseUrl}/status?leaveIdsCSV=${leaveId}&status=${status}`, leaveData[0])
    .then(response => {
      return response;
    });
  return response;
};

// Get all types of leaves
export const getLeaveTypes = async (pageNumber, pageSize) => {
  const response = await axios
    .get(`${leavesBaseUrl}/types?pageNumber=${pageNumber}&pageSize=${pageSize}`)
    .then(response => {
      return response.data;
    });
  return response;
};

// Create Leave Type
export const CreateLeaveTypes = async typeInfo => {
  const response = await axios.post(`${leavesBaseUrl}/types`, typeInfo).then(response => {
    return response;
  });
  return response;
};

// Get Leave Type By Id
export const getLeaveTypeById = async leaveTypeId => {
  const response = await axios.get(`${leavesBaseUrl}/types/${leaveTypeId}`).then(response => {
    return response.data;
  });
  return response;
};

//Update Leave Types
export const editLeaveType = async leaveTypeInfo => {
  const response = await axios.put(`${leavesBaseUrl}/types`, leaveTypeInfo).then(response => {
    return response;
  });
  return response;
};

// Create Associations
export const createAssociation = async associationInfo => {
  const response = await axios
    .post(`${leavesBaseUrl}/employment/associations`, associationInfo)
    .then(response => {
      return response;
    });
  return response;
};

// Get Employment Associations By Id
export const getEmploymentAssociationsById = async employmentTypeId => {
  const response = await axios
    .get(`${leavesBaseUrl}/employment/associations/${employmentTypeId}`)
    .then(response => {
      return response.data;
    });
  return response;
};

// Update Associations
export const editAssociation = async associationInfo => {
  const response = await axios
    .put(`${leavesBaseUrl}/employment/associations`, associationInfo)
    .then(response => {
      return response;
    });
  return response;
};

// Delete LeaveTypes
export const deleteLeaveTypes = async leaveTypeId => {
  const response = await axios.delete(`${leavesBaseUrl}/types/${leaveTypeId}`);
  return response;
};

// Get all types balance from leaves Bucket
export const getLeaveBuckets = async (employeeId = "") => {
  const response = await axios
    .get(`${leavesBaseUrl}/buckets?employeeId=${employeeId}`)
    .then(response => {
      return response;
    });
  return response;
};

// Get all types balance from leaves Bucket
export const getBalanceReports = async (employeeId = "", pageNumber = 1, pageSize = 10) => {
  const response = await axios.get(
    `${leavesBaseUrl}/types/employeeAssociations/balanceReports?employeeId=${employeeId}&pageNumber=${pageNumber}&pageSize=${pageSize}`
  );
  return response;
};

// Get leaves balance till date
export const getBalanceTillDate = async (employeeId = "", leaveTypeId = "", date = "") => {
  const response = await axios.get(
    `${leavesBaseUrl}/types/employeeAssociations/balanceTillDate?employeeId=${employeeId}&leaveTypeId=${leaveTypeId}&date=${date}`
  );
  return response;
};

// Get balance histories of a particular leave
export const getBalanceHistories = async (employeeId = "", leaveTypeId = "") => {
  const response = await axios.get(
    `${leavesBaseUrl}/types/employeeAssociations/balanceHistories?employeeId=${employeeId}&leaveTypeId=${leaveTypeId}`
  );
  return response;
};

export const deleteLeaveBalanceHistory = async (balanceHistoryId) => {
  const response = await axios.delete(
    `${leavesBaseUrl}/types/employeeAssociations/balance/${balanceHistoryId}`
  );
  return response;
}

// Update Balance Type Data
export const updateLeaveBuckets = async leaveBucketsData => {
  const response = await axios.put(
    `${leavesBaseUrl}/types/employeeAssociations/balance`,
    leaveBucketsData
  );
  return response;
};

// Leave type with policy
export const AddLeaveType = async leaveTypePolicyDetails => {
  const response = await axios.post(`${leavesBaseUrl}/types`, leaveTypePolicyDetails);
  return response;
};

// Update leave type with policy
export const UpdateLeaveType = async leaveTypePolicyDetails => {
  const response = await axios.put(`${leavesBaseUrl}/types`, leaveTypePolicyDetails);
  return response;
};

// Get leave type policy with Id
export const getLeaveTypePolicyById = async leaveTypeId => {
  const response = await axios.get(`${leavesBaseUrl}/types/${leaveTypeId}`);
  return response;
};

// Get My Approvals or Get the list of users who have applied for leaves under me
export const getMyApprovals = async (pageNumber, pageSize) => {
  const response = await axios
    .get(`${leavesBaseUrl}/myApprovals?pageNumber=${pageNumber}&pageSize=${pageSize}`)
    .then(response => {
      return response.data;
    });
  return response;
};

// get specific users approval.
export const getSpecificUserApprovals = async (pageNumber, pageSize, employeeId) => {
  const response = await axios
    .get(
      `${leavesBaseUrl}/myApprovals?pageNumber=${pageNumber}&pageSize=${pageSize}&employeeId=${employeeId}`
    )
    .then(response => {
      return response.data;
    });
  return response;
};

/**
 * @param {string} employeeId - The ID of the employee to retrieve associated leaves.
 * @return {Promise<any>} The data of the retrieved associated leaves.
 */
export const getAssociatedLeavesService = async employeeId => {
  const res = await axios.get(
    `${leavesBaseUrl}/types/employeeAssociations?employeeId=${employeeId}`
  );
  return res;
};

//Leave type toggle status
export const changeLeaveTypeToggleStatus = async leaveTypeId => {
  const response = await axios.put(
    `${leavesBaseUrl}/types/toggleStatus?leaveTypeId=${leaveTypeId}`
  );
  return response?.data;
};
