import { useEffect, useRef } from "react";
import Stepper from "../../components/common/Stepper/Stepper";
import { fields } from "./helper";
import { getOrgCompletionStatus } from "../../services/CommonService";
import { useStore } from "../../context/createFastContext";
import { GetTenants } from "../../services/OrganizationDetailsService";
import { Content } from "./Content";

const MultiStepForm = () => {
  const [multipleFormsState, setStore] = useStore(s => s.multipleFormsState);

  const refState = useRef({
    initialRender: 0,
  });
  useEffect(() => {
    getOrgCompletionStatus().then(result => {
      const { orgDetails } = result.data;

      const activeStepColor = multipleFormsState.steps.map((step, i) => {
        if (i === 1 && orgDetails) {
          return {
            ...step,
            color: "blue",
          };
        }
        return step;
      });

      GetTenants().then(res => {
        const { pan, tan, gstNumber, address, tenantName } = res.data.data;
        setStore({
          multipleFormsState: {
            ...multipleFormsState,
            activeStep: orgDetails ? 1 : 0,
            stepsStatus: result.data,
            steps: activeStepColor,
            organizationDetailsState: {
              ...multipleFormsState.organizationDetailsState,
              tenant: res.data.data,
              inputFields: {
                ...multipleFormsState.organizationDetailsState.inputFields,
                [fields[0].id]: tenantName,
                [fields[1].id]: address,
                [fields[2].id]: pan,
                [fields[3].id]: tan,
                [fields[4].id]: gstNumber,
              },
            },
          },
        });
      });
    });
  }, []);

  useEffect(() => {
    if (refState.current.initialRender < 2) {
      refState.current.initialRender = refState.current.initialRender + 1;
      return;
    }

    getOrgCompletionStatus().then(result => {
      setStore({
        multipleFormsState: {
          ...multipleFormsState,
          stepsStatus: result.data,
        },
      });
    });
  }, [multipleFormsState.reFetchStatus]);

  const onStepClick = elem => {
    let dataset;

    if (elem) dataset = elem.dataset.id;
    if (!elem) return;

    let id = Number(dataset);

    setStore({
      multipleFormsState: {
        ...multipleFormsState,
        activeStep: id, //current tab
        steps: multipleFormsState.steps.map((step, i) => {
          if (i === id) {
            return {
              ...step,
              color: "blue",
            };
          }
          if (id < multipleFormsState.activeStep && i > id) {
            return {
              ...step,
              color: "gray",
            };
          }
          return step;
        }),
      },
    });
  };

  return (
    <section className="min-h-[100vh] bg-gray-100 dark:bg-gray-900 py-3 sm:py-5">
      <div className="px-4 mx-auto my-4 max-w-screen-2xl lg:px-12">
        <Stepper
          steps={multipleFormsState.steps}
          handleStep={e => onStepClick(e.target.closest("[data-id]"))}
          content={<Content activeStep={multipleFormsState.step[multipleFormsState.activeStep]} />}
        />
      </div>
    </section>
  );
};

export default MultiStepForm;
