import { AiOutlinePercentage } from "react-icons/ai";
import { BsCurrencyRupee } from "react-icons/bs";
import { RxCrossCircled } from "react-icons/rx";

import { handleDelete, getInvoiceItem } from "./helper";
import { showToast } from "../common/Toast";

const InvoiceItem = ({
  value,
  radioValue,
  invoiceItems,
  setAllAmounts,
  setFormValues,
  allAmounts,
}) => {
  const { name, hsnsac, gstRate, quantity, rate, id } = value;

  const GSTOnBlurHandler = e => {
    const name = e.currentTarget.name;

    const inputValue = e.currentTarget.value;

    if (inputValue.trim() === "") showToast("info", "GST rate cannot be empty");

    const newItemValue = {
      ...value,
      [name]: inputValue.trim() === "" ? "0" : inputValue,
    };
    getInvoiceItem(invoiceItems, setAllAmounts, setFormValues, allAmounts, newItemValue);
  };

  const onItemValueChange = e => {
    let newItemValue = {};

    //fixed the value at two decimal place
    if (e.target.name === "rate" && e.target.value.includes(".")) {
      let rateValue = e.target.value;

      const rateValueArr = rateValue.split(".");
      const decimalPlace = rateValueArr[0] + "." + rateValueArr[1].slice(0, 2);
      rateValue = decimalPlace;

      newItemValue = {
        ...value,
        [e.target.name]: rateValue,
      };
    } else {
      newItemValue = {
        ...value,
        [e.target.name]: e.target.value,
      };
    }
    getInvoiceItem(invoiceItems, setAllAmounts, setFormValues, allAmounts, newItemValue);
  };

  return (
    <tr className="bg-[hsl(218,100%,98%)] shadow-xl border px-0 -mx-1 dark:border-gray-700 dark:bg-gray-800">
      <td className="w-fit items-row whitespace-nowrap mr-2 text-gray-900 dark:text-white">
        <input
          type="text"
          name="name"
          value={name || ""}
          placeholder="Item name"
          onChange={onItemValueChange}
          className="w-[90%] px-0 border-t-0 border-x-0 border-b border-b-gray-400 focus:border-b focus:border-b-gray-900 hover:border-b-gray-900 focus:ring-0 bg-[hsl(218,100%,98%)] text-[0.9rem] leading-[0.5rem] ml-2"
          required
        />
      </td>
      <td className="items-row">
        <input
          type="number"
          name="hsnsac"
          value={hsnsac || ""}
          onChange={onItemValueChange}
          className="w-full pr-0 pl-0 border-t-0 border-x-0 border-b border-b-gray-400 focus:border-b focus:border-b-gray-900 hover:border-b-gray-900 focus:ring-0 bg-[hsl(218,100%,98%)] text-[0.9rem] leading-[0.5rem] "
        />
      </td>
      <td className="relative items-row">
        <input
          type="number"
          name="gstRate"
          onBlur={GSTOnBlurHandler}
          value={gstRate || ""}
          onChange={onItemValueChange}
          className="w-full pl-0 pr-0 border-t-0 border-x-0 border-b border-b-gray-400 focus:border-b focus:border-b-gray-900 hover:border-b-gray-900 focus:ring-0 bg-[hsl(218,100%,98%)] text-[0.9rem] leading-[0.5rem]"
        />
        <span className="absolute right-8 top-[1.3rem]">
          <AiOutlinePercentage />
        </span>
      </td>
      <td className="items-row">
        <input
          type="number"
          name="quantity"
          value={quantity || ""}
          onChange={onItemValueChange}
          className="w-full pl-0 pr-0 border-t-0 border-x-0 border-b border-b-gray-400 focus:border-b focus:border-b-gray-900 hover:border-b-gray-900 focus:ring-0 bg-[hsl(218,100%,98%)] text-[0.9rem] leading-[0.5rem]"
        />
      </td>
      <td className="relative items-row">
        <input
          type="number"
          name="rate"
          value={rate || ""}
          onChange={onItemValueChange}
          className="w-full  border-t-0 border-x-0 border-b border-b-gray-400 focus:border-b focus:border-b-gray-900 hover:border-b-gray-900 focus:ring-0 bg-[hsl(218,100%,98%)] text-[0.9rem] leading-[0.5rem]"
        />
        <span className="absolute left-0 top-[1.3rem]">
          <BsCurrencyRupee />
        </span>
      </td>
      <td className="items-row relative">
        <input
          type="number"
          name="amount"
          value={(+quantity * +rate).toFixed(2) || ""}
          readOnly
          disabled={true}
          className="w-full border-t-0 border-x-0 text-[0.9rem] leading-[0.5rem] !bg-[hsl(216,100%,98%)]"
        />
        <span className="absolute left-0 top-[1.3rem]">
          <BsCurrencyRupee />
        </span>
      </td>
      {radioValue === "igst" ? (
        <>
          <td className="items-row relative">
            <input
              type="number"
              name="igstValue"
              value={((+quantity * +rate * +gstRate) / 100).toFixed(2)}
              readOnly
              disabled={true}
              className="w-full border-t-0 border-x-0 focus:outline-none  focus:ring-transparent !bg-[hsl(216,100%,98%)] text-[0.9rem] leading-[0.5rem]"
            />
            <span className="absolute left-0 top-[1.3rem]">
              <BsCurrencyRupee />
            </span>
          </td>
        </>
      ) : (
        <>
          <td className="items-row relative">
            <input
              type="number"
              name="cgstValue"
              disabled={true}
              value={((+quantity * +rate * +gstRate) / 200).toFixed(2)}
              readOnly
              className="w-full border-t-0 border-x-0 focus:outline-none  focus:ring-transparent !bg-[hsl(216,100%,98%)] text-[0.9rem] leading-[0.5rem]"
            />
            <span className="absolute left-0 top-[1.3rem]">
              <BsCurrencyRupee />
            </span>
          </td>
          <td className="items-row relative">
            <input
              type="number"
              name="sgstValue"
              value={((+quantity * +rate * +gstRate) / 200).toFixed(2)}
              readOnly
              disabled={true}
              className="w-full border-t-0 border-x-0 focus:outline-none  focus:ring-transparent !bg-[hsl(216,100%,98%)] text-[0.9rem] leading-[0.5rem]"
            />
            <span className="absolute left-0 top-[1.3rem]">
              <BsCurrencyRupee />
            </span>
          </td>
        </>
      )}
      <td className="items-row relative">
        <input
          type="number"
          name="total"
          value={(+quantity * +rate + (+quantity * +rate * +gstRate) / 100).toFixed(2)}
          readOnly
          disabled={true}
          className="w-full border-t-0 border-x-0 focus:outline-none  focus:ring-transparent !bg-[hsl(216,100%,98%)] text-[0.9rem] leading-[0.5rem]"
        />
        <span className="absolute left-0 top-[1.3rem]">
          <BsCurrencyRupee />
        </span>
      </td>
      <td className="">
        <RxCrossCircled
          className="cursor-pointer bg-[hsl(218,100%,98%)]"
          fontSize={25}
          color={"red"}
          onClick={() => handleDelete(invoiceItems, setAllAmounts, setFormValues, id, allAmounts)}
        />
      </td>
    </tr>
  );
};

export default InvoiceItem;
