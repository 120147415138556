import { axiosInstance } from "../context/AuthContext";
const rolesBaseUrl = `${process.env.REACT_APP_BASE_URL}/Roles`;

//Creating a new Role
export const addNewRole = async roleDetails => {
  const response = await axiosInstance.post(`${rolesBaseUrl}`, roleDetails);
  return response;
};

//Getting all the roles.
export const getAllRoles = async () => {
  const response = await axiosInstance.get(`${rolesBaseUrl}`).then(response => {
    return response.data.data;
  });
  return response;
};

//Getting individual Role by RoleId
export const getSpecificRole = async id => {
  const response = await axiosInstance.get(`${rolesBaseUrl}/${id}`);
  return response.data;
};

//Update Role Details
export const updateRole = async roleDetails => {
  const response = await axiosInstance({
    url: `${rolesBaseUrl}`,
    method: "PUT",
    data: JSON.stringify(roleDetails),
  });
  return response;
};

//Delete Role Details
export const deleteRole = async roleId => {
  const response = await axiosInstance.delete(`${rolesBaseUrl}/${roleId}`);
  return response;
};
