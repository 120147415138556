import { useState, useEffect, useMemo, useCallback } from "react";
import { Button } from "flowbite-react";

import FormInput from "../common/FormInput";
import StateSelect from "../invoices/components/StateSelect";
import { UpdateAddress } from "../../services/AddressService";
import { showToast } from "../common/Toast";
import SelectComponent from "../Settings/controlPanelSettings/SelectComponent/SelectComponent";
import ModalComponent from "./../common/Modal/Modal";
import { btnPrimary } from "./../../utils/helper";
import useCountriesAPI from "../../hooks/useCountriesAPI";

const defaultClass =
  "w-full mb-4 p-2 capitalize border-t-0 border-l-0 border-r-0 border-b focus:ring-0 border-b-gray-400 focus:border-b focus:border-b-gray-900 hover:border-b-gray-900";

const EditAddress = ({ oldAddress, onClose, isClient }) => {
  const [newAddress, setNewAddress] = useState({ ...oldAddress });
  const [toggleEditAddress, setToggleEditAddress] = useState(false);
  const { countries } = useCountriesAPI();

  const handleAddressChange = event => {
    let { name, value } = event.target;
    setNewAddress(prev => {
      return {
        ...prev,
        [name]: value,
        isClient: isClient,
      };
    });
  };

  const toggleAddressModal = (onModal = false) => {
    setToggleEditAddress(false);

    //if user close the modal with cross btn change the address back to old address
    if (onModal) {
      setNewAddress({});
      onClose(oldAddress);
      return;
    }
    //else update the state in shippingDetails and in database
    onClose(newAddress);
    setNewAddress({});
  };

  useEffect(() => {
    setNewAddress(oldAddress);
  }, [oldAddress]);

  const saveNewAddress = e => {
    e.preventDefault();
    UpdateAddress(newAddress).then(res => {
      if (res.statusCode == 200) {
        toggleAddressModal();
        showToast("success", "Address updated successfully");
        return;
      } else showToast("error", "Something went wrong");
    });
  };

  return (
    <div>
      <button onClick={() => setToggleEditAddress(true)} className="px-5 py-2 ">
        Edit
      </button>
      <ModalComponent
        onClose={toggleAddressModal.bind(null, true)}
        show={toggleEditAddress}
        showFooter={false}
        heading="Edit Address">
        <form>
          <FormInput
            className={defaultClass}
            placeholder={"Business name"}
            name="businessName"
            value={newAddress?.businessName}
            onChange={handleAddressChange}
          />
          <SelectComponent
            onChangeHandler={useCallback(handleAddressChange, [countries])}
            selectClass="mt-2 mb-5"
            optionsArr={useMemo(() => ["Select Country", ...countries], [countries])}
            defaultValue={newAddress?.country ? newAddress?.country : "Select country"}
            capFirstLetter={true}
            nameAttr="country"
          />
          <FormInput
            className={defaultClass}
            value={newAddress?.address}
            name="address"
            placeholder={"Address (Optional)"}
            onChange={handleAddressChange}
          />
          <FormInput
            name="city"
            value={newAddress?.city}
            placeholder={"City (Optional)"}
            className={`!w-[49%] p-2 mr-[1%] my-4 ${defaultClass}`}
            onChange={handleAddressChange}
          />
          <FormInput
            className={`!w-[49%] p-2 my-4 ${defaultClass}`}
            name="postalCode"
            value={newAddress?.postalCode}
            onChange={handleAddressChange}
            placeholder={"Postal Code / Zip code"}
          />

          {newAddress?.country != "India" ? (
            <FormInput
              className={defaultClass}
              name="state"
              placeholder={"State (Optional)"}
              value={newAddress?.state}
              key={"state-select-input"}
              onChange={handleAddressChange}
            />
          ) : (
            <StateSelect
              className={"my-4"}
              key={"state-select-dropdown"}
              value={newAddress?.state}
              name="state"
              onChange={handleAddressChange}
            />
          )}

          <FormInput
            name="gstin"
            value={newAddress?.gstin}
            className={`w-full p-2 mb-2 ${defaultClass}`}
            onChange={handleAddressChange}
            placeholder={"Enter your GSTIN here(optional)"}
          />
          <div className="flex justify-end">
            <Button size="sm" onClick={saveNewAddress} className={`${btnPrimary()} mt-4 mr-3`}>
              Update Address
            </Button>
          </div>
        </form>
      </ModalComponent>
    </div>
  );
};

export default EditAddress;
