import React from 'react'
import MemoizedNoItemFound from '../../components/NoItemFound/Noitemfound'
import TableData from '../../components/common/Table/TableData';
import { FaRegEdit, FaRegTrashAlt } from 'react-icons/fa';

const BusinessLists = ({ business, setClientUpdate, openDeleteModal }) => {
  return (
    <>
      <MemoizedNoItemFound
        isTable={true}
        colSpan={5}
        itemsArr={business}
        message={"No Business added!"}
      />

      {business.length > 0 &&
        business?.map(business => (
          <tr
            className="h-10 border-b dark:border-gray-600 hover:bg-gray-100 dark:hover:bg-gray-700"
            key={business.id}>
            <TableData data={business.name} />
            <TableData data={business.industry} />
            <TableData data={business.totalInvoices} />
            <TableData>
              <div className="flex gap-3">
                <button
                  onClick={() => setClientUpdate(business.id)}
                  className="hover:text-thynkwebPrimary-800">
                  <FaRegEdit />
                </button>
                <div
                  data-modal-target="popup-modal"
                  data-modal-toggle="popup-modal"
                  onClick={() => openDeleteModal(business.id, business.name)}
                  className="cursor-pointer hover:text-red-400">
                  <FaRegTrashAlt />
                </div>
              </div>
            </TableData>
          </tr>
        ))}
    </>
  );
};

export default BusinessLists