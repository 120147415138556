import Skills from "./ViewDetailsTabs/Skills";
import UploadedDocuments from "./ViewDetailsTabs/UploadedDocuments";

import { VIEW_DETAILS_TABS_ENUM } from "../../../constants/Constant";

/**
 * Generate the function comment for the given function body in a markdown code block with the correct language syntax.
 *
 * @param {Object} activeTab - The active tab.
 * @return {JSX.Element} - The section containing the active tab's content.
 */
const ViewDetailTabs = ({ activeTab }) => {
  return (
    <section className="mt-5 max-h-[30rem] overflow-y-scroll">
      {activeTab === VIEW_DETAILS_TABS_ENUM.skill && <Skills />}
      {activeTab === VIEW_DETAILS_TABS_ENUM.uploadedDocs && <UploadedDocuments />}
    </section>
  );
};

export default ViewDetailTabs;
