import React from "react";
import { Footer } from "../components/common/Footer";

const AboutUs = () => {
  return (
    <div>
      <div className="bg-white dark:bg-gray-900 border border-blue-700 my-8 border-transparent">
        <section className="bg-gray-50 dark:bg-gray-900">
          <div className=" py-8 px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6 border border-red-700 border-transparent">
            <h3 className="mb-4 text-3xl font-medium dark:text-white  leading-none tracking-tight text-gray-900 md:text-4xl lg:text-5xl  border border-red-700 text-center border-transparent">
              <mark className="px-2 text-white bg-black rounded dark:bg-black">
                Hello
              </mark>{" "}
              We are Thynk Corp.
            </h3>
          </div>
        </section>
        <section className="bg-gray-50 dark:bg-gray-900  border border-red-700 border-transparent">
          <div className="py-8 px-4 mx-auto space-y-12 max-w-screen-xl lg:space-y-20 sm:py-16 lg:px-6">
            <div className="gap-8 items-center lg:grid lg:grid-cols-2 xl:gap-16">
              <div className="text-gray-500 sm:text-lg dark:text-gray-400">
                <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white">
                  Work with tools you already use
                </h2>
                <p className="mb-8 font-light lg:text-xl">
                  Deliver great service experiences fast - without the
                  complexity of traditional ITSM solutions.Accelerate critical
                  development work, eliminate toil, and deploy changes with
                  ease.
                </p>
                <ul className="pt-8 my-7 space-y-5 border-t border-gray-200 dark:border-gray-700">
                  <li className="flex space-x-3">
                    <svg
                      className="flex-shrink-0 w-5 h-5 text-thynkwebPrimary-600 dark:text-thynkwebPrimary-500"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    <span className="text-base font-medium leading-tight text-gray-900 dark:text-white">
                      Continuous integration and deployment
                    </span>
                  </li>
                  <li className="flex space-x-3">
                    <svg
                      className="flex-shrink-0 w-5 h-5 text-thynkwebPrimary-600 dark:text-thynkwebPrimary-500"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    <span className="text-base font-medium leading-tight text-gray-900 dark:text-white">
                      Development workflow
                    </span>
                  </li>
                  <li className="flex space-x-3">
                    <svg
                      className="flex-shrink-0 w-5 h-5 text-thynkwebPrimary-600 dark:text-thynkwebPrimary-500"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    <span className="text-base font-medium leading-tight text-gray-900 dark:text-white">
                      Knowledge management
                    </span>
                  </li>
                </ul>
                <p className="mb-8 font-light lg:text-xl">
                  Deliver great service experiences fast - without the
                  complexity of traditional ITSM solutions.
                </p>
              </div>
              <img
                className="hidden mb-4 w-full lg:mb-0 lg:flex rounded-lg"
                src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/features/feature-office-1.png"
                alt="office feature "
              />
            </div>
            <div className="gap-8 items-center lg:grid lg:grid-cols-2 xl:gap-16">
              <img
                className="hidden mb-4 w-full lg:mb-0 lg:flex rounded-lg"
                src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/features/feature-office-2.png"
                alt="office feature "
              />
              <div className="text-gray-500 sm:text-lg dark:text-gray-400">
                <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white">
                  We invest in the world’s potential
                </h2>
                <p className="mb-8 font-light lg:text-xl">
                  Deliver great service experiences fast - without the
                  complexity of traditional ITSM solutions.Accelerate critical
                  development work, eliminate toil, and deploy changes with
                  ease.
                </p>
                <ul className="pt-8 my-7 space-y-5 border-t border-gray-200 dark:border-gray-700">
                  <li className="flex space-x-3">
                    <svg
                      className="flex-shrink-0 w-5 h-5 text-thynkwebPrimary-600 dark:text-thynkwebPrimary-500"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    <span className="text-base font-medium leading-tight text-gray-900 dark:text-white">
                      Dynamic reports and dashboards
                    </span>
                  </li>
                  <li className="flex space-x-3">
                    <svg
                      className="flex-shrink-0 w-5 h-5 text-thynkwebPrimary-600 dark:text-thynkwebPrimary-500"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    <span className="text-base font-medium leading-tight text-gray-900 dark:text-white">
                      Templates for everyone
                    </span>
                  </li>
                  <li className="flex space-x-3">
                    <svg
                      className="flex-shrink-0 w-5 h-5 text-thynkwebPrimary-600 dark:text-thynkwebPrimary-500"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    <span className="text-base font-medium leading-tight text-gray-900 dark:text-white">
                      Development workflow
                    </span>
                  </li>
                  <li className="flex space-x-3">
                    <svg
                      className="flex-shrink-0 w-5 h-5 text-thynkwebPrimary-600 dark:text-thynkwebPrimary-500"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    <span className="text-base font-medium leading-tight text-gray-900 dark:text-white">
                      Limitless business automation
                    </span>
                  </li>
                  <li className="flex space-x-3">
                    <svg
                      className="flex-shrink-0 w-5 h-5 text-thynkwebPrimary-600 dark:text-thynkwebPrimary-500"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                        clip="evenodd"
                      ></path>
                    </svg>
                    <span className="text-base font-medium leading-tight text-gray-900 dark:text-white">
                      Knowledge management
                    </span>
                  </li>
                </ul>
                <p className="font-light lg:text-xl">
                  Deliver great service experiences fast - without the
                  complexity of traditional ITSM solutions.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="bg-white dark:bg-gray-900">
          <div className="items-center py-8 px-4 mx-auto max-w-screen-xl lg:grid xl:grid-cols-3 lg:gap-8 xl:gap-24 sm:py-16 lg:px-6">
            <div className="mb-8 lg:mb-0">
              <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white">
                Designed for strong teams like yours
              </h2>
              <p className="mb-4 text-gray-500 sm:text-xl dark:text-gray-400">
                Here we focus on markets where creativity and innovation can
                unlock long-term value and growth.
              </p>
              <a
                href="/#"
                className="inline-flex items-center text-lg font-medium text-thynkwebPrimary-600 hover:text-thynkwebPrimary-800 dark:text-thynkwebPrimary-500 dark:hover:text-thynkwebPrimary-700"
              >
                Learn more
                <svg
                  className="ml-1 w-6 h-6"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </a>
            </div>
            <div className="col-span-2 space-y-8 md:grid md:grid-cols-2 md:gap-12 md:space-y-0">
              <div>
                <div className="flex justify-center items-center mb-4 w-10 h-10 rounded-full bg-thynkwebPrimary-100 lg:h-12 lg:w-12 dark:bg-thynkwebPrimary-900">
                  <svg
                    className="w-5 h-5 text-thynkwebPrimary-600 lg:w-6 lg:h-6 dark:text-thynkwebPrimary-300"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M3 3a1 1 0 000 2v8a2 2 0 002 2h2.586l-1.293 1.293a1 1 0 101.414 1.414L10 15.414l2.293 2.293a1 1 0 001.414-1.414L12.414 15H15a2 2 0 002-2V5a1 1 0 100-2H3zm11.707 4.707a1 1 0 00-1.414-1.414L10 9.586 8.707 8.293a1 1 0 00-1.414 0l-2 2a1 1 0 101.414 1.414L8 10.414l1.293 1.293a1 1 0 001.414 0l4-4z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </div>
                <h3 className="mb-2 text-xl font-bold dark:text-white">
                  Marketing
                </h3>
                <p className="text-gray-500 dark:text-gray-400">
                  Plan it, create it, launch it. Collaborate seamlessly with all
                  the organization and hit your marketing goals every month with
                  our marketing plan.
                </p>
              </div>
              <div>
                <div className="flex justify-center items-center mb-4 w-10 h-10 rounded-full bg-thynkwebPrimary-100 lg:h-12 lg:w-12 dark:bg-thynkwebPrimary-900">
                  <svg
                    className="w-5 h-5 text-thynkwebPrimary-600 lg:w-6 lg:h-6 dark:text-thynkwebPrimary-300"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M6 6V5a3 3 0 013-3h2a3 3 0 013 3v1h2a2 2 0 012 2v3.57A22.952 22.952 0 0110 13a22.95 22.95 0 01-8-1.43V8a2 2 0 012-2h2zm2-1a1 1 0 011-1h2a1 1 0 011 1v1H8V5zm1 5a1 1 0 011-1h.01a1 1 0 110 2H10a1 1 0 01-1-1z"
                      clipRule="evenodd"
                    ></path>
                    <path d="M2 13.692V16a2 2 0 002 2h12a2 2 0 002-2v-2.308A24.974 24.974 0 0110 15c-2.796 0-5.487-.46-8-1.308z"></path>
                  </svg>
                </div>
                <h3 className="mb-2 text-xl font-bold dark:text-white">
                  Business Automation
                </h3>
                <p className="text-gray-500 dark:text-gray-400">
                  Auto-assign tasks, send Slack messages, and much more. Now
                  power up with hundreds of new templates to help you get
                  started.
                </p>
              </div>
              <div>
                <div className="flex justify-center items-center mb-4 w-10 h-10 rounded-full bg-thynkwebPrimary-100 lg:h-12 lg:w-12 dark:bg-thynkwebPrimary-900">
                  <svg
                    className="w-5 h-5 text-thynkwebPrimary-600 lg:w-6 lg:h-6 dark:text-thynkwebPrimary-300"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M8.433 7.418c.155-.103.346-.196.567-.267v1.698a2.305 2.305 0 01-.567-.267C8.07 8.34 8 8.114 8 8c0-.114.07-.34.433-.582zM11 12.849v-1.698c.22.071.412.164.567.267.364.243.433.468.433.582 0 .114-.07.34-.433.582a2.305 2.305 0 01-.567.267z"></path>
                    <path
                      fillRule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-13a1 1 0 10-2 0v.092a4.535 4.535 0 00-1.676.662C6.602 6.234 6 7.009 6 8c0 .99.602 1.765 1.324 2.246.48.32 1.054.545 1.676.662v1.941c-.391-.127-.68-.317-.843-.504a1 1 0 10-1.51 1.31c.562.649 1.413 1.076 2.353 1.253V15a1 1 0 102 0v-.092a4.535 4.535 0 001.676-.662C13.398 13.766 14 12.991 14 12c0-.99-.602-1.765-1.324-2.246A4.535 4.535 0 0011 9.092V7.151c.391.127.68.317.843.504a1 1 0 101.511-1.31c-.563-.649-1.413-1.076-2.354-1.253V5z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </div>
                <h3 className="mb-2 text-xl font-bold dark:text-white">
                  Finance
                </h3>
                <p className="text-gray-500 dark:text-gray-400">
                  Audit-proof software built for critical financial operations
                  like month-end close and quarterly budgeting.
                </p>
              </div>
              <div>
                <div className="flex justify-center items-center mb-4 w-10 h-10 rounded-full bg-thynkwebPrimary-100 lg:h-12 lg:w-12 dark:bg-thynkwebPrimary-900">
                  <svg
                    className="w-5 h-5 text-thynkwebPrimary-600 lg:w-6 lg:h-6 dark:text-thynkwebPrimary-300"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M7 3a1 1 0 000 2h6a1 1 0 100-2H7zM4 7a1 1 0 011-1h10a1 1 0 110 2H5a1 1 0 01-1-1zM2 11a2 2 0 012-2h12a2 2 0 012 2v4a2 2 0 01-2 2H4a2 2 0 01-2-2v-4z"></path>
                  </svg>
                </div>
                <h3 className="mb-2 text-xl font-bold dark:text-white">
                  Enterprise Design
                </h3>
                <p className="text-gray-500 dark:text-gray-400">
                  Craft beautiful, delightful experiences for both marketing and
                  product with real cross-company collaboration.
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>
      <footer>
        <Footer />
      </footer>
    </div>
  );
};

export default AboutUs;
