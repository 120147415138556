import InputFields from "../../components/common/Input/InputFields";

/**
 * Renders a component for applying flexi leave.
 *
 * @param {Object} flexLeaveInfo - The information about the flexi leave.
 * @param {string} reason - The reason for applying the leave.
 * @return {JSX.Element} The rendered component.
 */
const ApplyLeaveFlexi = ({ flexLeaveInfo, onChangeHandler }) => {

  return (
    <InputFields
      InputField={{
        type: "date",
        name: "date",
        label: "Date",
        id: "flexiLeaveDate",
        required: true,
      }}
      onChange={onChangeHandler}
      value={flexLeaveInfo.date}
      parentClassName="col-start-1 col-end-2 w-full"
      inputClassName="w-full"
    />
  );
};

export default ApplyLeaveFlexi;
