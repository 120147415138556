import { Dropdown } from "flowbite-react";
import React, { useState, useEffect, useRef } from "react";

/**
 * Renders a select input field with a dropdown menu.
 *
 * @param {{inline: boolean, className: string, component: React.Component, itemsContainer: string, roundedDot: boolean, size: string, options: Array, defaultValue: string, isDefaultValue: boolean, handleChange: function, otherOptions: Array, backgroundColor: string, fetch: boolean, isCurrentUser: boolean, id: string, reRenderSelect: boolean, hideMenu: boolean}} - The properties for rendering the select input.
 * @param {boolean} inline - Specifies whether the dropdown should be displayed inline.
 * @param {React.Component} component - The component to render for each dropdown item.
 * @param {boolean} roundedDot - Specifies whether to display a rounded dot next to each dropdown item.
 * @param {string} className - The CSS class for the select input.
 * @param {string} dotClassName - The CSS class for the rounded dot.
 * @param {string} size - The size of the select input.
 * @param {Array} options - The options for the select input. `{value: string, label: string, itemClassName}`
 * @param {string} defaultValue - The default value for the select input.
 * @param {boolean} isDefaultValue - Specifies whether the default value should be used.
 * @param {function} handleChange - The function to handle the change event.
 * @param {Array} otherOptions - The other options for the select input.
 * @param {string} backgroundColor - The background color for the select input.
 * @param {boolean} fetch - Specifies whether to fetch data.
 * @param {boolean} isCurrentUser - Specifies whether the user is the current user.
 * @param {string} id - The ID of the select input element.
 * @param {boolean} reRenderSelect - Specifies whether to re-render the select input.
 *@param {string} itemsContainer - The CSS class for the dropdown menu Container.
 * @param {boolean} hideMenu - Specifies whether to hide the dropdown menu.
 * @return {React.Component} The rendered select input with dropdown menu.
 */
const SelectWithDropdown = ({
  inline,
  component,
  roundedDot,
  className,
  dotClassName,
  size,
  options,
  defaultValue,
  isDefaultValue,
  handleChange,
  otherOptions,
  backgroundColor,
  fetch,
  isCurrentUser,
  id,
  reRenderSelect,
  itemsContainer,
  hideMenu = false,
}) => {
  const [selectedLabel, setSelectedLabel] = useState();
  const [selectedClassName, setSelectedClassName] = useState();
  const [dotClassname, setDotClassName] = useState();
  const [background_Color, setBackgroundColor] = useState();
  const firstRender = useRef(false);
  const ref = useRef(null);

  useEffect(() => {
    if (firstRender.current === false) {
      // skipping useEffect run on initial render
      firstRender.current = true;
      return;
    }
    setSelectedLabel(null);
    setSelectedClassName(null);
    setDotClassName(null);
    setBackgroundColor(null);
  }, [reRenderSelect]);

  useEffect(() => {
    if (hideMenu) {
      const ele = document.querySelectorAll(".disabled");
      ele.forEach(item => {
        item.parentElement.parentElement.style.display = "none";
      });
    }
  }, [hideMenu]);

  const handleSelectChange = async selectedOption => {
    if (isCurrentUser) return;

    await handleChange(otherOptions, selectedOption);
    if (fetch) {
      ref.current = { selectedOption };
      return;
    }

    setSelectedLabel(selectedOption.label);
    setSelectedClassName(selectedOption.className);
    setDotClassName(selectedOption.dotClassName);
    setBackgroundColor(selectedOption.backgroundColor);
  };

  return (
    <Dropdown
      class={background_Color || backgroundColor}
      inline={inline}
      id={id}
      disabled={hideMenu}
      label={
        <>
          {roundedDot === true && (
            <div className={`w-3 h-3 mr-2 mt-1 ${dotClassname || dotClassName}`}></div>
          )}
          <div className={`text-start ${selectedClassName || className}`}>
            {/* isDefaultValue will help to set and empty the default value conditionally */}
            {isDefaultValue ? defaultValue : selectedLabel}
          </div>
        </>
      }
      size={size}>
      <div className={itemsContainer}>
        {component
          ? options.map((option, i) => (
              <Dropdown.Item
                // no need of uuid as it conflicts when multiple elements are rendered dynamically within a loop or a map function,
                // instead index is used to keep ids unique
                key={i}
                value={option.value}
                className={option.itemClassName}>
                {roundedDot === true && (
                  <div className={`w-3 h-3 mr-2 ${option.dotClassName}`}></div>
                )}
                {option.label}
              </Dropdown.Item>
            ))
          : options.map((option, i) => (
              <Dropdown.Item
                key={i}
                value={option.value}
                className={`${option.itemClassName} ${hideMenu ? "disabled" : ""}`}
                onClick={async () => await handleSelectChange(option)}>
                {roundedDot === true && (
                  <div className={`w-3 h-3 mr-2 ${option.dotClassName}`}></div>
                )}
                {option.label}
              </Dropdown.Item>
            ))}
      </div>
    </Dropdown>
  );
};

export default SelectWithDropdown;
