// Add Earning modal
import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { showToast } from "../../../components/common/Toast";
import MandatorySign from "../../../components/common/Input/MandatorySign";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import {
  setEarningTypesList,
  setEarningDetails,
  resetSalarySlice,
  setTenantEarnings,
  setTenantDeductions,
} from "../../../store/slices/salarySlice";

import { Dropdown } from "primereact/dropdown";
import { btnPrimary } from "../../../utils/helper";
import { Button } from "flowbite-react";
import Earning from "./EarningType";
import { IoMdArrowDropleft, IoMdInformationCircleOutline } from "react-icons/io";
import { AddTenantEarning, GetDeductions, GetEarningTypeById, GetEarningTypes, GetEarnings, UpdateTenantEarning } from "../../../services/SalaryComponentService";
import { CalculationType } from "../../../enum/CalculationType";

const AddSalaryComponent = ({ onCloseEarningModal }) => {
  const dispatch = useAppDispatch();
  const salary = useAppSelector(state => state.salary);

  const handleEarningTypeChange = async event => {
    const res = await GetEarningTypeById(event.value);
    const earningType = res.data.data;

    const earningLockOptions = {
      lockIsFixedPay: earningType.lockIsFixedPay,
      lockIsPartOfSalaryStructure: earningType.lockIsPartOfSalaryStructure,
      lockIsTaxableEarning: earningType.lockIsTaxableEarning,
      lockIsProRateBasis: earningType.lockIsProRateBasis,
      lockEPFContributionType: earningType.lockEPFContributionType,
      lockIsConsideredForESI: earningType.lockIsConsideredForESI,
    }

    const earningValues = {
      earningName:"",
      isFixedPay: earningType.isFixedPay,
      calculationType: earningType.componentCalculationTypes[0],
      isPartOfSalaryStructure: earningType.isPartOfSalaryStructure,
      isTaxableEarning: earningType.isTaxableEarning,
      isProRateBasis: earningType.isProRateBasis,
      epfContributionType: earningType.epfContributionType,
      isConsideredForESI: earningType.isConsideredForESI,
    }

    dispatch(setEarningDetails({
      ...salary.earningDetails,
      type: earningType,
      lockOptions: earningLockOptions,
      values: earningValues
    }))
  };

  useEffect(() => {
    GetEarningTypes().then(res => {
      dispatch(setEarningTypesList(res.data.data));
    })
  }, [])

  const getEarningTypeDropdownOptions = () => {
    const options = salary?.earningTypesList.map(earningType => {
      return {
        value: earningType.id,
        name: earningType.name,
        itemClassName: "border border-red-700",
      }
    });
    return options ?? [];
  }

  const onSubmitEarningModal = async() => {
    if(!salary?.modalValues.isEditEarningModal){
      await submitAddEarning();
    }
    else {
      await submitUpdateEarning();
    }
  }

  const submitAddEarning = async() => {
    
    const bodyData = {
      name: salary?.earningDetails.values.earningName,
      earningTypeId: salary?.earningDetails.type.id,
      description: salary?.earningDetails.values.earningName,
      calculationType: salary?.earningDetails.values.calculationType,
      calculationValue: salary?.earningDetails.values.amount,
      isFixedPay: salary?.earningDetails.values.isFixedPay,
      isPartOfSalaryStructure: salary?.earningDetails.values.isPartOfSalaryStructure,
      isTaxableEarning: salary?.earningDetails.values.isTaxableEarning,
      isProRateBasis: salary?.earningDetails.values.isProRateBasis,
      epfContributionType: salary?.earningDetails.values.epfContributionType,
      isConsideredForESI: salary?.earningDetails.values.isConsideredForESI,
    }

    await AddTenantEarning(bodyData).then(res => {
      dispatch(resetSalarySlice());
      if(res.data.data > 0){
        showToast("success", "Earning succesfully added");
        GetEarnings(false).then(res => {
          dispatch(setTenantEarnings(res.data))
        });
      }
      else{
        showToast("warning", "Error adding earning")
      }
    });
  }

  const submitUpdateEarning = async() => {
    const bodyData = {
      id: salary?.earningDetails.selectedEarning.id,
      name: salary?.earningDetails.values.earningName,
      description: salary?.earningDetails.values.earningName,
      calculationType: salary?.earningDetails.values.calculationType,
      calculationValue: salary?.earningDetails.values.amount,
      isFixedPay: salary?.earningDetails.values.isFixedPay,
      isPartOfSalaryStructure: salary?.earningDetails.values.isPartOfSalaryStructure,
      isTaxableEarning: salary?.earningDetails.values.isTaxableEarning,
      isProRateBasis: salary?.earningDetails.values.isProRateBasis,
      epfContributionType: salary?.earningDetails.values.epfContributionType,
      isConsideredForESI: salary?.earningDetails.values.isConsideredForESI,
    }

    await UpdateTenantEarning(bodyData).then(res => {
      dispatch(resetSalarySlice());
      if(res.data.data > 0){
        showToast("success", "Earning succesfully updated");
        GetEarnings(false).then(res => {
          dispatch(setTenantEarnings(res.data))
        });
      }
      else{
        showToast("warning", "Error updating earning");
      }
    })
  }

  return (
    <>
      <div className="">
        <h3 className=" text-lg">
          Earning Type <MandatorySign />
        </h3>
        <div className="mt-4">
          <div className="card flex justify-content-center">
            <Dropdown
              value={salary?.earningDetails.type?.id}
              onChange={handleEarningTypeChange}
              options={getEarningTypeDropdownOptions()}
              disabled={salary?.modalValues.isEditEarningModal}
              optionLabel="name"
              placeholder="Select type"
              filter
              className=" md:w-14rem w-[45%] h-10 border"
            />
            <div className=" flex justify-end items-center  ">
              <IoMdArrowDropleft size={28} className="-mr-[0.7rem]" color="#eef7ff" />
            </div>
            {salary?.earningDetails.lockOptions.lockIsFixedPay && salary?.earningDetails.values.isFixedPay && (
              <div className=" w-[33%] bg-[#eef7ff] flex items-center">
                <div className="f-hull  flex justify-center items-center">
                  <IoMdInformationCircleOutline size={22} className="pl-2" />
                </div>
                <p className="text-xs ml-1 ">Fixed amount paid at the end of every month.</p>
              </div>
            )}
            {salary?.earningDetails.lockOptions.lockIsFixedPay && !salary?.earningDetails.values.isFixedPay && (
              <div className=" w-[33%] bg-[#eef7ff] flex items-center">
                <div className="f-hull  flex justify-center items-center">
                  <IoMdInformationCircleOutline size={22} className="pl-2" />
                </div>
                <p className="text-xs ml-1 ">Variable amount paid during payroll.</p>
              </div>
            )}
          </div>
        </div>
      </div>
      <hr className="h-px my-6 bg-gray-200 border-0 dark:bg-gray-700" />
      {/* Different sections according to selected earning type */}
      {salary?.earningDetails.type && (<Earning />)}
      {/* Notes section */}
      <div className=" bg-[#fff5e3] text-sm p-2 mt-8">
        <span className="font-medium">Note:</span> Once you associate this component with an
        employee, you will only be able to edit the Name and Amount/Percentage. The changes you make
        to Amount/Percentage will apply only to new employees.
      </div>
      <hr className="h-px mt-6 bg-gray-200 border-0 dark:bg-gray-700" />
      <div className=" mt-3">
        <div className=" w-[100%]  flex gap-4">
          <Button 
            type="submit" 
            className={btnPrimary()} 
            form="add-modal"
            onClick={onSubmitEarningModal}>
            Save
          </Button>
          <Button
            type="submit"
            className={btnPrimary()}
            form="add-modal"
            onClick={() => onCloseEarningModal()}>
            Cancel
          </Button>
        </div>
      </div>
    </>
  );
};

export default memo(AddSalaryComponent);
