import { useRef, useState } from "react";
import ModalComponent from "../../../components/common/Modal/Modal";
import { AddLeaveTypeBody } from "./AddLeaveTypeBody";
import { CreateLeaveTypes } from "../../../services/LeaveService";
import { showToast } from "../../../components/common/Toast";
import PlusSvg from "../../../media/PlusSvg";
import { checkboxOptions } from "./options";

const obj = checkboxOptions.reduce((acc, option) => {
  acc[option.name] = option.checked;
  return acc;
}, {});

export const AddLeaveTypeModal = ({ setRenderLeaveType }) => {
  const formRef = useRef(null);
  const [toggleModal, setToggleModal] = useState(false);
  const [checkboxState, setCheckboxState] = useState(obj);
  const [leaveTypesInfo, setLeaveTypesInfo] = useState();

  const handleAddLeaveType = e => {
    e.preventDefault();
    const { name, value } = e.target;

    setLeaveTypesInfo(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const Submit = async e => {
    e.preventDefault();

    if (!leaveTypesInfo?.name || leaveTypesInfo.name.trim() === "")
      return showToast("warn", "Leave type name is empty!");

    const body = {
      name: leaveTypesInfo.name,
      description: leaveTypesInfo.description,
      isPaid: checkboxState.isPaid,
      reduceLeaveOnAbsent: checkboxState.reduceLeaveOnAbsent,
      allowNegativeLeaves: checkboxState.allowNegativeLeaves,
    };
    const res = await CreateLeaveTypes(body);
    const data = await res.data;
    setRenderLeaveType(true);
    setToggleModal(false);
    formRef.current.reset();
    
    if (data?.message === "Success!" && data?.statusCode === 200) {
      localStorage.setItem("renderAddEmploymentType", JSON.stringify(true));
      showToast("success", "Leave type created successfully");
    }

    //clear previous value on success/error
    setLeaveTypesInfo({});
  };

  return (
    <div>
      <div onClick={() => setToggleModal(true)}>
        <PlusSvg className="w-9 h-9" />
      </div>

      <ModalComponent
        show={toggleModal}
        headerClassName="modal-header-title mb-4"
        heading="Add Leave Type"
        onClose={() => setToggleModal(false)}
        body={
          <AddLeaveTypeBody
            handleAddLeaveType={handleAddLeaveType}
            handleSubmit={Submit}
            formRef={formRef}
            checkboxState={checkboxState}
            setCheckboxState={setCheckboxState}
          />
        }
      />
    </div>
  );
};
