import React from "react";
import MandatorySign from "../../../components/common/Input/MandatorySign";
import InputFields from "../../../components/common/Input/InputFields";
import { Button } from "flowbite-react";
import { btnPrimary } from "../../../utils/helper";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import { resetSalarySlice, setDeductionDetails, setTenantDeductions } from "../../../store/slices/salarySlice";
import { AddTenantDeduction, GetDeductions, UpdateTenantDeduction } from "../../../services/SalaryComponentService";
import { showToast } from "../../../components/common/Toast";
import { IoMdArrowDropleft } from "react-icons/io";
import { Dropdown } from "primereact/dropdown";
import { DeductionType, DeductionTypeLabels } from "../../../enum/DeductionType";
import { ITDeductionSectionLabels } from "../../../enum/ITDeductionSection";

const AddDeductions = ({ onCloseDeductionModal }) => {
  const dispatch = useAppDispatch();
  const salary = useAppSelector(state => state.salary);
  
  const deductionSectionOptions = [
    { name: "None", value: null, itemClassName: "border border-red-700" },
    ...Object.entries(ITDeductionSectionLabels).map(([value, label]) => ({ 
      name: label, 
      value, 
      itemClassName: "border border-red-700" 
    }))
  ];

  const onChangeDeductionName = e => {
    const deductionName = e.target.value;
    dispatch(setDeductionDetails({
      ...salary.deductionDetails,
      values: {
        ...salary.deductionDetails.values,
        deductionName: deductionName
      }
    }))
  }

  const onChangeDeductionDescription = e => {
    const deductionDescription = e.target.value;
    dispatch(setDeductionDetails({
      ...salary.deductionDetails,
      values: {
        ...salary.deductionDetails.values,
        description: deductionDescription
      }
    }))
  }

  const onChangeIsRecurring = e => {
    const isRecurring = e.target.checked;
    dispatch(setDeductionDetails({
      ...salary.deductionDetails,
      values: {
        ...salary.deductionDetails.values,
        isRecurring: isRecurring
      }
    }))
  }

  const onChangePreTaxCheckBox = e => {
    var deductionType = DeductionType.PostTax;
    if(e.target.checked){
      deductionType = DeductionType.PreTax;
    }
    dispatch(setDeductionDetails({
      ...salary.deductionDetails,
      values: {
        ...salary.deductionDetails.values,
        deductionType: deductionType
      }
    }))
  }

  const onChangeDeductionSection = (e) => {
   const itDeductionSection = e.value;
   dispatch(setDeductionDetails({
    ...salary.deductionDetails,
    values:{
      ...salary.deductionDetails.values,
      preTaxDeductionSection: itDeductionSection,
    }
   }))
  }

  const onSubmitDeductionModal = async () => {
    if (!salary?.modalValues.isEditDeductionModal) {
      await submitAddDeduction();
    }
    else {
      await submitUpdateDeduction();
    }
  }

  const submitAddDeduction = async () => {
    const bodyData = {
      name: salary.deductionDetails.values.deductionName,
      isRecurring: salary.deductionDetails.values.isRecurring,
      deductionType: salary.deductionDetails.values.deductionType,
      description: salary.deductionDetails.values.description,
      preTaxDeductionSection: Number(salary.deductionDetails.values.preTaxDeductionSection)
    }

    await AddTenantDeduction(bodyData).then(res => {
      dispatch(resetSalarySlice());
      if (res.data.data > 0) {
        showToast("success", "Deduction succesfully added");
        GetDeductions(false).then(res => {
          dispatch(setTenantDeductions(res.data))
        });
      }
      else {
        showToast("warning", "Error adding deduction")
      }
    });
  }

  const submitUpdateDeduction = async () => {

    const bodyData = {
      id: salary.deductionDetails.selectedDeduction.id,
      name: salary.deductionDetails.values.deductionName,
      isRecurring: salary.deductionDetails.values.isRecurring,
      deductionType: salary.deductionDetails.values.deductionType,
      description: salary.deductionDetails.values.description,
      preTaxDeductionSection: Number(salary.deductionDetails.values.preTaxDeductionSection)
    }

    await UpdateTenantDeduction(bodyData).then(res => {
      dispatch(resetSalarySlice());
      if (res.data.data > 0) {
        showToast("success", "Deduction succesfully updated");
        GetDeductions(false).then(res => {
          dispatch(setTenantDeductions(res.data))
        });
      }
      else {
        showToast("warning", "Error updating deduction")
      }
    });
  }

  return (
    <div className="">
      <div className="name-in-payslip-container">
        <h2>
          Name in Payslip
          <MandatorySign />
        </h2>
        <InputFields
          InputField={{
            type: "text",
            name: "nameInPayslip",
            id: "nameInPayslip",
          }}
          parentClassName="mt-2"
          inputClassName="w-[47%]"
          value={salary.deductionDetails.values.deductionName}
          onChange={onChangeDeductionName}
        />
      </div>
      <div className="name-in-payslip-container">
        <h2>
          Description
        </h2>
        <InputFields
          InputField={{
            type: "text",
            name: "description",
            id: "description",
          }}
          parentClassName="mt-2"
          inputClassName="w-[47%]"
          value={salary.deductionDetails.values.description}
          onChange={onChangeDeductionDescription}
        />
      </div>
      <div className="deduction-frequency-container mt-3">
        <div className="flex mb-4">
          <div className="">
            <input
              id="is-recurring-checkbox"
              type="checkbox"
              onChange={onChangeIsRecurring}
              checked={salary?.deductionDetails.values.isRecurring}
              className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
            />
          </div>
          <div className="">
            <label
              for="is-recurring-checkbox"
              className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300 w-full">
              Recurring Deduction
            </label>
          </div>
        </div>
        <div className="flex mb-4">
          <div className="">
            <input
              id="pre-tax-checkbox"
              type="checkbox"
              onChange={onChangePreTaxCheckBox}
              disabled={salary.modalValues.isEditDeductionModal}
              checked={salary.deductionDetails.values.deductionType === DeductionType.PreTax}
              className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
            />
          </div>
          <div className="">
            <label
              for="pre-tax-checkbox"
              className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300 w-full">
              Pre-Tax Deduction
            </label>
          </div>
        </div>
        {salary.deductionDetails.values.deductionType === DeductionType.PreTax && (
          <div className="">
            <h2>
              Associate Deduction With
            </h2>
            <div className="mt-4">
              <div className="card flex justify-content-center">
                <Dropdown
                  value={salary.deductionDetails.values.preTaxDeductionSection}
                  onChange={onChangeDeductionSection}
                  options={deductionSectionOptions}
                  disabled={salary.modalValues.isEditDeductionModal}
                  optionLabel="name"
                  placeholder="Select investment type"
                  filter
                  className=" md:w-14rem w-[45%] h-10 border"
                />
                <div className=" flex justify-end items-center  ">
                  <IoMdArrowDropleft size={28} className="-mr-[0.7rem]" color="#eef7ff" />
                </div>
              </div>
            </div>
          </div>
        )}
        {/* <h2>
          Select the deduction frequency
          <MandatorySign />
        </h2>
        <div className="flex items-center mb-1 mt-2">
          <input
            id="default-radio-1"
            type="radio"
            value=""
            name="default-radio"
            className="w-[0.8rem] h-[0.8rem] text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600 "
          />
          <label
            for="default-radio-1"
            className="ms-2 text-sm text-gray-900 dark:text-gray-300 -mt-[0.2rem]">
            One-time deduction
          </label>
        </div>
        <div className="flex items-center">
          <input
            checked
            id="default-radio-2"
            type="radio"
            value=""
            name="default-radio"
            className="w-[0.8rem] h-[0.8rem] text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
          />
          <label
            for="default-radio-2"
            className="ms-2 text-sm text-gray-900 dark:text-gray-300 -mt-[0.2rem]">
            Recurring deduction for subsequent Payrolls
          </label>
        </div> */}
      </div>
      <div className="bg-[#fff5e3] text-sm p-2 mt-8">
        <span className="font-medium">Note:</span> Once you associate this deduction with an
        employee, you will only be able to edit the Name in Payslip. The change will be reflected in
        both new and existing employees.
      </div>
      <hr className="h-px mt-6 bg-gray-200 border-0 dark:bg-gray-700" />
      <div className="mt-3">
        <div className=" w-[100%]  flex gap-4">
          <Button
            type="submit"
            className={btnPrimary()}
            form="add-modal"
            onClick={onSubmitDeductionModal}>
            Save
          </Button>
          <Button
            type="submit"
            className={btnPrimary()}
            form="add-modal"
            onClick={() => onCloseDeductionModal()}>
            Cancel
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AddDeductions;
