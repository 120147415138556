import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import PageSection from "../../../Layout/PageSection/PageSection";
import SkillInput from "./SkillInput";
import SkillList from "./SkillList";
import UpdateSkillModal from "./UpdateSkillModal";
import InputFields from "../../../common/Input/InputFields";
import DeleteSkillModal from "./DeleteSkillModal";

import { modalsEnum, searchSkillOption } from "../../helper";
import { filteredSkills } from "../../../../store/slices/addSkillSlice";

const updateSkillState = {
  id: null,
  name: null,
};

const modalInitialState = {
  updateSkill: false,
  deleteModal: false,
};

const ManageSkills = () => {
  const [selectedSkill, setSelectedSkill] = useState(updateSkillState);
  const [modals, setModals] = useState(modalInitialState);

  const { skills } = useSelector(state => state.skills);
  const dispatch = useDispatch();

  const toggleModal = function (modal) {
    setModals(prevM => ({ ...prevM, [modal]: !prevM[modal] }));
  };

  const onClickHandler = function (e) {
    const elem = e.target.closest("[data-id]");

    if (!elem) return;

    const dataset = JSON.parse(elem.dataset.id);
    const id = +dataset[0];
    const type = dataset[1];

    const selectedSkill = skills.allSkills.filter(skill => skill.id === id);
    setSelectedSkill(selectedSkill[0]);
    type === "update" ? toggleModal(modalsEnum.updateSkill) : toggleModal(modalsEnum.deleteModal);
  };

  const onChangeFilterHandler = function (e) {
    const value = e.target.value;
    dispatch(filteredSkills(value));
  };

  return (
    <PageSection heading="Skills Management">
      <SkillInput />
      <hr className="border-0 bg-gray-200 h-[1px] my-5" />
      <div>
        <div className="flex items-start justify-between p-3">
          <h5>
            Total Skills Added:
            <span className="font-bold text-gray-700"> {skills.allSkills.length}</span>
          </h5>
          <InputFields
            value={skills.filteredSkillValue}
            onChange={onChangeFilterHandler}
            InputField={searchSkillOption}
          />
        </div>
        <ul
          onClick={onClickHandler}
          className="px-6 py-4 bg-gray-200 rounded-md mt-2 flex flex-wrap gap-2">
          <SkillList />
        </ul>
      </div>

      <UpdateSkillModal
        modals={modals}
        selectedSkill={selectedSkill}
        setSelectedSkill={setSelectedSkill}
        toggleModal={toggleModal}
      />
      <DeleteSkillModal
        toggleModal={toggleModal}
        modals={modals}
        selectedSkill={selectedSkill}
        setSelectedSkill={setSelectedSkill}
      />
    </PageSection>
  );
};

export default ManageSkills;
