import { useState, useCallback, useMemo, useEffect, useContext } from "react";
import GearSvg from "../../../media/GearSvg";
import ModalComponent from "../../common/Modal/Modal";
import Radio from "../../common/Radio/Radio";
import InputFields from "../../common/Input/InputFields";
import { cn } from "../../../utils/common";
import { lenientMode, options, shiftStartEnd, strictMode, timeLogSettingsObject } from "../helper";
import SidebarComponent from "../../common/Sidebar/Sidebar";
import {
  getAttendanceSettings,
  updateAttendanceSettings,
} from "../../../services/AttendanceService";
import { showToast } from "../../common/Toast";
import { ATTENDANCE_SETTINGS_MESSAGES } from "../../../constants/Messages";
import {
  AttendanceSettings,
  MinHoursInputType,
  MinHoursRequiredMode,
} from "../../../enum/AttendanceSettings";
import { useDispatch, useSelector } from "react-redux";
import { toggleAttendanceModal } from "../../../store/slices/settingsSlice";
import { AuthContext } from "../../../context/AuthContext";
import { Role } from "../../../enum/Role";

const parentClassName = cn("w-full items-start");
const inputDateClassName = cn("w-full");
const inputTextClassName = cn("flex w-[50%]");
const checkboxClassName = cn("flex flex-row-reverse justify-end gap-1 mt-2 ");
const lenientModeClassName = cn("w-[50%]");

//Initial state
const stateModeInitial = {
  strictModeChecked: true,
  lenientModeChecked: false,
  strictModeManualChecked: true,
  shiftHoursChecked: false,
  lenientModeManualInputChecked: true,
  lenientModeShiftHoursChecked: false,
};

//Initial toggle details for working hours
const initialWorkingHourToggleDetails = {
  minHourStrictModeChecked: false,
  minHourLenientModeChecked: false,
  minHourManualChecked: false,
  minHourShiftHoursChecked: false,
};

const colonCount = time => ((time.match(/:/g) || []).length < 2 ? false : true);

const MyAttendanceSetting = () => {
  //Getting current user
  const { user } = useContext(AuthContext);
  const isAdmin = user?.roleId === Role.Administrator;
  const isModerator = user?.roleId === Role.Moderator;

  //State for working hour tab
  const [workingHourToggleDetails, setWorkingHourToggleDetails] = useState(
    initialWorkingHourToggleDetails
  );

  const dispatch = useDispatch();
  const settings = useSelector(state => state.settings);

  const [selectedValue, setSelectedValue] = useState("");
  const [localState, setLocalState] = useState({
    shift: {
      shiftStart: "",
      shiftEnd: "",
    },
    activeItem: 1,
    settingsData: null,
    totalHoursCalculation: 0,
    minHoursRequiredForDay: 0,
    minHoursInputMode: MinHoursInputType.ManualInput,
    duration: {
      fullDay: "",
      halfDay: "",
    },
    regularizationFutureDates: 0,
    regularizationRequest: "",
    reasonForRegularization: false,
    regularizationEntriesWill: 0,
    attendanceUpdateCutOffDays: 0,
    restrictLogsForFutureDates: false,
    restrictLogsForPastDates: false,
    restrictLogsForWeekend: false,
    restrictLogsForHolidays: false,
    restrictLogsToEstimatedJobHours: false,
    //working hour tab details
    workingHourDetails: {
      minHoursRequiredMode: "",
      minHoursInputType: "",
    },
  });

  let {
    settingsData,
    activeItem,
    totalHoursCalculation,
    minHoursRequiredForDay,
    minHoursInputMode,
    duration,
    regularizationEntriesWill,
    regularizationFutureDates,
    regularizationRequest,
    reasonForRegularization,
    attendanceUpdateCutOffDays,
    restrictLogsForFutureDates,
    restrictLogsForPastDates,
    restrictLogsForHolidays,
    restrictLogsForWeekend,
    restrictLogsToEstimatedJobHours,
    shift,
    workingHourDetails,
  } = localState;

  //useEffect hook to get attendance setting details from API
  useEffect(() => {
    if (isAdmin || isModerator) {
      getAttendanceSettings()
        .then(res => {
          let {
            totalHoursCalculationType,
            minHoursRequiredMode,
            minHoursInputType,
            fullDayDuration,
            halfDayDuration,
            isFutureDateRegularizationEnabled,
            isReasonEnabled,
            regularizationEntryType,
            attendanceUpdateCutOffDays,
            restrictLogsToEstimatedJobHours,
            restrictLogsForFutureDates,
            restrictLogsForPastDates,
            restrictLogsForWeekend,
            restrictLogsForHolidays,
            shiftStart,
            shiftEnd,
          } = res.data;

          setLocalState(s => ({
            ...s,
            settingsData: res.data,
            totalHoursCalculation: totalHoursCalculationType,
            minHoursRequiredForDay: minHoursRequiredMode,
            duration: { fullDay: fullDayDuration, halfDay: halfDayDuration },
            regularizationFutureDates: isFutureDateRegularizationEnabled,
            reasonForRegularization: isReasonEnabled,
            regularizationEntriesWill: regularizationEntryType,
            attendanceUpdateCutOffDays: attendanceUpdateCutOffDays,
            restrictLogsForFutureDates: restrictLogsForFutureDates,
            restrictLogsForPastDates: restrictLogsForPastDates,
            restrictLogsForWeekend: restrictLogsForWeekend,
            restrictLogsForHolidays: restrictLogsForHolidays,
            restrictLogsToEstimatedJobHours: restrictLogsToEstimatedJobHours,
            shift: {
              shiftStart: shiftStart,
              shiftEnd: shiftEnd,
            },
            workingHourDetails: {
              minHoursRequiredMode: +minHoursRequiredMode,
              minHoursInputType: +minHoursInputType,
            },
          }));
          setWorkingHourToggleDetails(prevWHTD => ({
            ...prevWHTD,
            minHourStrictModeChecked: +minHoursRequiredMode === 1 ? true : false,
            minHourLenientModeChecked: +minHoursRequiredMode === 2 ? true : false,
            minHourManualChecked: +minHoursInputType === 1 ? true : false,
            minHourShiftHoursChecked: +minHoursInputType === 2 ? true : false,
          }));
        })
        .catch(e => {
          console.error(e);
        });
    }
  }, [settings.showAttendanceModal]);

  const handleOpenModal = () => {
    dispatch(toggleAttendanceModal(true));
  };

  const handleCloseModal = () => {
    dispatch(toggleAttendanceModal(false));
  };

  const handleChange = event => {
    setSelectedValue(event.target.value);
  };

  const onClickSideBarItem = item => {
    setLocalState(s => ({ ...s, activeItem: item }));
  };

  const onSaveUserDetails = () => {
    let body = {
      ...settingsData,
      totalHoursCalculationType: totalHoursCalculation,
      minHoursRequiredMode: +localState?.workingHourDetails?.minHoursRequiredMode,
      minHoursInputType: +localState?.workingHourDetails?.minHoursInputType,
      fullDayDuration: duration.fullDay + (colonCount(duration.fullDay) ? "" : ":00"),
      halfDayDuration: duration.halfDay + (colonCount(duration.halfDay) ? "" : ":00"),
      isFutureDateRegularizationEnabled: regularizationFutureDates,
      isReasonEnabled: reasonForRegularization,
      regularizationEntryType: regularizationEntriesWill,
      attendanceUpdateCutOffDays: attendanceUpdateCutOffDays,
      restrictLogsForFutureDates: restrictLogsForFutureDates,
      restrictLogsForPastDates: restrictLogsForPastDates,
      restrictLogsForWeekend: restrictLogsForWeekend,
      restrictLogsForHolidays: restrictLogsForHolidays,
      restrictLogsToEstimatedJobHours: restrictLogsToEstimatedJobHours,
      shiftStart: shift.shiftStart + (colonCount(shift.shiftStart) ? "" : ":00"),
      shiftEnd: shift.shiftEnd + (colonCount(shift.shiftEnd) ? "" : ":00"),
    };

    updateAttendanceSettings(body)
      .then(res => {
        showToast("success", ATTENDANCE_SETTINGS_MESSAGES.SUCCESS);
        dispatch(toggleAttendanceModal(false));
      })
      .catch(e => {
        showToast("error", ATTENDANCE_SETTINGS_MESSAGES.FAIL);
        console.error(e);
      });
  };

  const handleRadioChange = e => {
    const key = e.target.name;
    let value = e.target.value;
    if (value === "true" || value === "false") {
      value = value === "true";
    } else {
      value = Number(value);
    }
    setLocalState(s => ({
      ...s,
      [key]: value,
    }));
  };

  const handleTimeChange = e => {
    let key = e.target.name,
      value = e.target.value;
    setLocalState(s => ({ ...s, duration: { ...s.duration, [key]: value } }));
  };

  const handleShiftStartEndChange = e => {
    let key = e.target.name,
      value = e.target.value;
    setLocalState(s => ({ ...s, shift: { ...s.shift, [key]: value } }));
  };

  const handleCheckboxChange = e => {
    setLocalState(s => ({
      ...s,
      [e.target.name]: e.target.checked,
    }));
  };

  const handleCutOffDayChange = e => {
    setLocalState(s => ({
      ...s,
      attendanceUpdateCutOffDays: Number(e.target.value),
    }));
  };

  const onChangeWorkingHourToggleDetails = event => {
    const { name, value } = event.target;
    if (name === "minHoursRequiredMode") {
      setLocalState(prevLS => ({
        ...prevLS,
        workingHourDetails: {
          ...prevLS.workingHourDetails,
          minHoursRequiredMode: +value,
        },
      }));
      if (value === "1" || value === MinHoursRequiredMode.StrictMode) {
        setWorkingHourToggleDetails(prevWHTD => ({
          ...prevWHTD,
          minHourStrictModeChecked: true,
          minHourLenientModeChecked: false,
        }));
      } else if (value === "2" || value === MinHoursRequiredMode.LenientMode) {
        setWorkingHourToggleDetails(prevWHTD => ({
          ...prevWHTD,
          minHourStrictModeChecked: false,
          minHourLenientModeChecked: true,
        }));
      }
    }
    if (name === "minHoursInputMode") {
      setLocalState(prevLS => {
        return {
          ...prevLS,
          workingHourDetails: {
            ...prevLS.workingHourDetails,
            minHoursInputType: +value,
          },
        };
      });

      if (value === "1" || value === MinHoursInputType.ManualInput) {
        setWorkingHourToggleDetails(prevWHTD => ({
          ...prevWHTD,
          minHourManualChecked: true,
          minHourShiftHoursChecked: false,
        }));
      } else if (value === "2" || value === MinHoursInputType.ShiftHours) {
        setWorkingHourToggleDetails(prevWHTD => ({
          ...prevWHTD,
          minHourManualChecked: false,
          minHourShiftHoursChecked: true,
        }));
      }
    }
  };

  return (
    <>
      <ModalComponent
        show={settings.showAttendanceModal}
        size="4xl"
        onClose={handleCloseModal}
        heading="Attendance Settings"
        footer={false}>
        <div className="flex items-baseline gap-4 mt-2">
          <SidebarComponent
            options={options}
            activeItem={useMemo(() => activeItem, [activeItem])}
            handleSubmit={onSaveUserDetails}
            onClickSideBarItem={useCallback(onClickSideBarItem, [])}
            activeStyle={"active"}
          />
          <div className="border self-stretch"></div>
          <div className="w-[100%]">
            {/* 'Working Hour' tab section*/}
            {activeItem === 1 && (
              <div className="">
                <div className="bg-gray-200 p-2 rounded-md mb-2">
                  <div className="flex mt-3 gap-4">
                    {shiftStartEnd.map(item => {
                      return (
                        <InputFields
                          parentClassName={parentClassName}
                          key={item.id}
                          InputField={item}
                          inputClassName={inputDateClassName}
                          onChange={handleShiftStartEndChange}
                          value={shift[item.name] ?? ""}
                          defaultValue="12:00"
                        />
                      );
                    })}
                  </div>
                </div>
                {/* total hours calculation section */}
                <p>Total hours calculation</p>
                <div className="flex gap-4 pt-1 pb-2">
                  <Radio
                    id="radioOption1"
                    name="totalHoursCalculation"
                    value={AttendanceSettings.FirstCheckInLastCheckOut}
                    label="First Check-in & Last Check-out"
                    checked={totalHoursCalculation === AttendanceSettings.FirstCheckInLastCheckOut}
                    onChange={handleRadioChange}
                    parentClass="your-custom-parent-class"
                    labelClass="your-custom-label-class"
                    radioClass="your-custom-radio-class"
                  />

                  <Radio
                    id="radioOption2"
                    name="totalHoursCalculation"
                    value={AttendanceSettings.EveryValidCheckInCheckOut}
                    label="Every Valid Check-in & Check-out"
                    checked={totalHoursCalculation === AttendanceSettings.EveryValidCheckInCheckOut}
                    onChange={handleRadioChange}
                    parentClass="your-custom-parent-class"
                    labelClass="your-custom-label-class"
                    radioClass="your-custom-radio-class"
                  />
                </div>

                {/* Minimum hours required for day section */}
                <p className="mt-3">Minimum hours required for day</p>
                <div className="flex gap-4 pt-1 pb-3">
                  <Radio
                    id="strict-mode"
                    name="minHoursRequiredMode"
                    value={MinHoursRequiredMode.StrictMode}
                    checked={workingHourToggleDetails.minHourStrictModeChecked}
                    label="Strict Mode"
                    parentClass="your-custom-parent-class"
                    labelClass="your-custom-label-class"
                    radioClass="your-custom-radio-class"
                    onChange={onChangeWorkingHourToggleDetails}
                  />

                  <Radio
                    id="lenient-mode"
                    name="minHoursRequiredMode"
                    checked={workingHourToggleDetails.minHourLenientModeChecked}
                    value={MinHoursRequiredMode.LenientMode}
                    label="Lenient Mode(Expected Hours)"
                    parentClass="your-custom-parent-class"
                    labelClass="your-custom-label-class"
                    radioClass="your-custom-radio-class"
                    onChange={onChangeWorkingHourToggleDetails}
                  />
                </div>

                {/* minHoursInputType section */}
                <div className="bg-gray-200 p-2 rounded-md mb-2">
                  <div className="flex gap-4 pt-2 pb-2">
                    <Radio
                      id="manualInput"
                      name="minHoursInputMode"
                      value={MinHoursInputType.ManualInput}
                      label="Manual Input"
                      checked={workingHourToggleDetails.minHourManualChecked}
                      parentClass="your-custom-parent-class"
                      labelClass="your-custom-label-class"
                      radioClass="your-custom-radio-class"
                      onChange={onChangeWorkingHourToggleDetails}
                    />

                    <Radio
                      id="shiftHours"
                      name="minHoursInputMode"
                      value={MinHoursInputType.ShiftHours}
                      label="Shift Hours"
                      checked={workingHourToggleDetails.minHourShiftHoursChecked}
                      parentClass="your-custom-parent-class"
                      labelClass="your-custom-label-class"
                      radioClass="your-custom-radio-class"
                      onChange={onChangeWorkingHourToggleDetails}
                    />
                  </div>

                  {workingHourToggleDetails.minHourStrictModeChecked &&
                    workingHourToggleDetails.minHourManualChecked && (
                      <div className="">
                        <div className="flex mt-3 gap-4">
                          {strictMode.map(item => {
                            return (
                              <InputFields
                                parentClassName={parentClassName}
                                key={item.id}
                                InputField={item}
                                inputClassName={inputDateClassName}
                                onChange={handleTimeChange}
                                value={duration[item.name] ?? ""}
                                defaultValue="12:00"
                                otherProps={{ step: 1 }}
                              />
                            );
                          })}
                        </div>
                      </div>
                    )}

                  {workingHourToggleDetails.minHourStrictModeChecked &&
                    workingHourToggleDetails.minHourShiftHoursChecked && (
                      <div>
                        <div className="flex mt-3 gap-2">
                          <p>Full Day :</p>
                          <p>Duration of the Shift</p>
                        </div>
                        <div className="flex mt-3 gap-2">
                          <p>Half Day :</p>
                          <p>Half of the Shift Duration</p>
                        </div>
                      </div>
                    )}

                  {workingHourToggleDetails.minHourLenientModeChecked &&
                    workingHourToggleDetails.minHourManualChecked && (
                      <div>
                        <div className="flex mt-3 gap-4">
                          {lenientMode.map(item => {
                            return (
                              <InputFields
                                parentClassName={lenientModeClassName}
                                key={item.id}
                                InputField={item}
                                inputClassName={inputDateClassName}
                                onChange={handleTimeChange}
                                value={duration[item.name] ?? ""}
                                defaultValue="12:00"
                                otherProps={{ step: 1 }}
                              />
                            );
                          })}
                        </div>
                      </div>
                    )}

                  {workingHourToggleDetails.minHourLenientModeChecked &&
                    workingHourToggleDetails.minHourShiftHoursChecked && (
                      <div>
                        <div className="flex mt-3 gap-2">
                          <p>Full Day :</p>
                          <p>Duration of the Shift</p>
                        </div>
                      </div>
                    )}
                </div>
              </div>
            )}

            {/*Regularization tab section */}
            {activeItem === 2 && (
              <div>
                <div className="flex gap-4">
                  Regularization for future dates
                  <Radio
                    id="radioOption11"
                    name="regularizationFutureDates"
                    value={!regularizationFutureDates}
                    checked={regularizationFutureDates === true}
                    onChange={handleRadioChange}
                    label="Enable"
                    parentClass="your-custom-parent-class"
                    labelClass="your-custom-label-class"
                    radioClass="your-custom-radio-class"
                  />
                  <Radio
                    id="radioOption12"
                    name="regularizationFutureDates"
                    value={!regularizationFutureDates}
                    checked={regularizationFutureDates === false}
                    onChange={handleRadioChange}
                    label="Disable"
                    parentClass="your-custom-parent-class"
                    labelClass="your-custom-label-class"
                    radioClass="your-custom-radio-class"
                  />
                </div>

                <div className="flex gap-4 mt-3 mb-3">
                  <p>Reason for Regularization</p>
                  <Radio
                    id="radioOption13"
                    name="reasonForRegularization"
                    value={!reasonForRegularization}
                    checked={reasonForRegularization === true}
                    onChange={handleRadioChange}
                    label="Enable"
                    parentClass="your-custom-parent-class"
                    labelClass="your-custom-label-class"
                    radioClass="your-custom-radio-class"
                  />

                  <Radio
                    id="radioOption14"
                    name="reasonForRegularization"
                    value={!reasonForRegularization}
                    checked={reasonForRegularization === false}
                    onChange={handleRadioChange}
                    label="Disable"
                    parentClass="your-custom-parent-class"
                    labelClass="your-custom-label-class"
                    radioClass="your-custom-radio-class"
                  />
                </div>

                <div className=" mt-3 mb-3">
                  <div className="flex flex-col gap-4">
                    <InputFields
                      parentClassName={cn(
                        "flex items-center flex-row-reverse justify-end gap-4 mt-3 mb-3"
                      )}
                      inputClassName={cn("flex flex-row-reverse justify-end gap-1 mt-2 ")}
                      InputField={timeLogSettingsObject[0]}
                      value={attendanceUpdateCutOffDays}
                      onChange={handleCutOffDayChange}
                    />
                  </div>
                </div>
              </div>
            )}
            {/* Time log tab section */}
            {activeItem === 3 && (
              <>
                <p>Restrict time logs on:</p>
                {/* <div>
                  <InputFields
                    parentClassName={checkboxClassName}
                    InputField={timeLogSettingsObject[1]}
                    otherProps={{
                      checked: restrictLogsForFutureDates,
                    }}
                    onChange={handleCheckboxChange}
                  />
                </div>
                <div>
                  <InputFields
                    parentClassName={checkboxClassName}
                    InputField={timeLogSettingsObject[2]}
                    otherProps={{
                      checked: restrictLogsForPastDates,
                    }}
                    onChange={handleCheckboxChange}
                  />
                </div> */}
                <div>
                  <InputFields
                    parentClassName={checkboxClassName}
                    InputField={timeLogSettingsObject[1]}
                    otherProps={{
                      checked: restrictLogsForWeekend,
                    }}
                    onChange={handleCheckboxChange}
                  />
                </div>
                <div>
                  <InputFields
                    parentClassName={checkboxClassName}
                    InputField={timeLogSettingsObject[2]}
                    otherProps={{
                      checked: restrictLogsForHolidays,
                    }}
                    onChange={handleCheckboxChange}
                  />
                </div>
                {/* <div>
                  <InputFields
                    parentClassName={checkboxClassName}
                    InputField={timeLogSettingsObject[5]}
                    otherProps={{
                      checked: restrictLogsToEstimatedJobHours,
                    }}
                    onChange={handleCheckboxChange}
                  />
                </div> */}
              </>
            )}
          </div>
        </div>
      </ModalComponent>
    </>
  );
};

export default MyAttendanceSetting;
