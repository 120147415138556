import React from "react";
import Table from "../../common/Table/Table";
import TableHeader from "../../common/Table/TableHeader";
import TableData from "../../common/Table/TableData";
import EditIconSvg from "../../../media/EditIconSvg";
import { Role } from "../../../enum/Role";
import DeleteSvg from "../../../media/DeleteSvg";
import { FaCheckCircle, FaRegEdit, FaRegTrashAlt, FaUser } from "react-icons/fa";
import { formatDate } from "../../../utils/helper";
import TableRows from "../../common/Table/TableRows";
import { JobsHeading, getTimeOnlyFromLoggedHours } from "../helper";
import MemoizedNoItemFound from "../../NoItemFound/Noitemfound";
import { format } from "date-fns";

/**
 * Render a list of jobs with their details.
 *
 * @param {Object} jobsDetails - the details of the jobs
 * @param {Function} handleDeleteJob - function to handle job deletion
 * @param {Function} handleUpdateJob - function to handle job update
 * @param {Function} handleAssignUser - function to handle job user assignment
 * @return {JSX.Element} the rendered list of jobs with details
 */

const JobsList = ({ jobsDetails, handleDeleteJob, handleUpdateJob, handleAssignUser }) => {
  return (
    <>
      <Table>
        <TableHeader
          cols={JobsHeading}
          styles={{
            tableHead: "text-xs uppercase",
            tableRow: "",
            tableCol: "",
          }}
        />

        <tbody className={`${jobsDetails?.length === 0 ? "h-[8rem]" : "auto"}`}>
          <MemoizedNoItemFound
            isTable={true}
            itemsArr={jobsDetails}
            colSpan={JobsHeading?.length}
            message={"No jobs added"}
          />

          {jobsDetails?.length > 0 &&
            jobsDetails?.map((job, index) => (
              <TableRows
                key={job?.id}
                className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                <TableData data={job?.name} />

                <TableData>{job?.project?.name}</TableData>
                <TableData>{job?.project?.businessName}</TableData>
                <TableData data={formatDate(job?.startDate)} />
                <TableData data={formatDate(job?.endDate)} />
                <TableData>
                  {getTimeOnlyFromLoggedHours(job?.loggedHours)}
                </TableData>
                <TableData>
                  {" "}
                  <div className="cursor-pointer relative w-fit">
                    <FaUser size="1.2rem" />
                    <div className=" min-w-[1rem] h-[1rem] absolute -top-[0.6rem] left-[0.7rem] rounded-full flex justify-center items-center bg-thynkwebPrimary-800 text-white">
                      <span>{job?.employeeJobAssociations?.length}</span>
                    </div>
                  </div>
                </TableData>

                <TableData className={""}>
                  <div className="flex gap-2">
                    <div
                      onClick={handleUpdateJob.bind(null, job?.id)}
                      className="cursor-pointer p-2 hover:text-thynkwebPrimary-800">
                      <FaRegEdit />
                    </div>
                    <div
                      className="cursor-pointer p-2 hover:text-red-400"
                      onClick={handleDeleteJob.bind(null, job?.id)}>
                      <FaRegTrashAlt />
                    </div>
                  </div>
                </TableData>
              </TableRows>
            ))}
        </tbody>
      </Table>
    </>
  );
};

export default JobsList;
