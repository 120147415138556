import FileUploadSvg from "./../../../media/FileUploadSvg";

const TYPES_TEXT = "text-xs text-gray-500 dark:text-gray-400";
const VALUE_TEXT = "text-medium text-gray-800 dark:text-gray-500";


/**
 * Renders a Drag and Drop component.
 *
 * @param {{types: Array, value: string, boldMessage: string, normalMessage: string, onChange: function, onDragOver: function, onDrop: function, styles: {parentClass?: string}}} props - The component props.
 * @param {Array} types - An array of file types that are accepted for input.
 * @param {string} value - Show the file name when file is uploaded.
 * @param {string} boldMessage - The bold message to display.
 * @param {string} normalMessage - The normal message to display.
 * @param {function} onChange - The function to call when the value changes.
 * @param {function} onDragOver - The function to call when a file is dragged over the component.
 * @param {function} onDrop - The function to call when a file is dropped onto the component.
 * @param {{parentClass?: string}} styles - The styles for the component.
 * @return {ReactElement} The rendered Drag and Drop component.
 */
const DragAndDrop = ({
  types,
  value,
  boldMessage,
  normalMessage,
  onChange,
  onDragOver,
  onDrop,
  styles
}) => {
  const acceptFileTypes = types.map(type => `.${type.toLowerCase()}`).toString();
  return (
  <div className={`${styles?.parentClass} flex items-center justify-center w-full`}>
      <label
        onDrop={onDrop}
        onDragOver={onDragOver}
        htmlFor="dropzone-file"
        className="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
        <div className="flex flex-col items-center justify-center pt-5 pb-6">
          <FileUploadSvg />
          <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
            <span className="font-semibold">{boldMessage}</span> {normalMessage}
          </p>
          <p className={`${value ? VALUE_TEXT : TYPES_TEXT}`}>
            {value ? value : types.toString().replaceAll(",", ", ")}
          </p>
        </div>
        <input
          id="dropzone-file"
          type="file"
          className="cursor-pointer"
          onChange={onChange}
          defaultValue={""}
          accept={acceptFileTypes}
          hidden={true}
        />
      </label>
    </div>
  );
};

export default DragAndDrop;
