import ExceptionLogsTable from "../components/ExceptionLog/ExceptionLogsTable";
/**
 * Renders the ExceptionLogs component.
 *
 * @return {React.Element} The rendered ExceptionLogs component.
 */
const ExceptionLogs = () => {
  return <ExceptionLogsTable />;
};

export default ExceptionLogs;
