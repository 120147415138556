import { v4 } from "uuid";

import { UserSvg } from "../../media/UserSvg";
import LeavesRuleSvg from "../../media/LeavesRuleSvg";
import { SalaryComponentSvg } from "../../media/SalaryComponentSvg";
import { OrganizationIcon } from "../../media/OrganizationIcon";
import ExceptionLogsSvg from "../../media/ExceptionLogsSvg";
import SkillsSvg from "../../media/SkillsSvg";
import { GiAlliedStar } from "react-icons/gi";
import { BsPersonFillX } from "react-icons/bs";
import { RiUserSettingsLine } from "react-icons/ri";

export const dashboardOptions = [
  {
    parent: "General",
    id: v4(),
    children: [
      {
        txt: "User Management",
        url: "users-management",
        icon: <UserSvg _size="w-[22px] h-[22px]" />,
        id: v4(),
      },
      {
        txt: "Roles",
        url: "Roles",
        icon: <RiUserSettingsLine size="md" />,
        id: v4(),
      },

      {
        txt: "Salary Components",
        url: "salary-components",
        icon: <SalaryComponentSvg _size="w-[22px] h-[22px]" />,
        id: v4(),
      },
      {
        txt: "Skills Management",
        url: "skill-management",
        icon: <SkillsSvg className="w-[22px] h-[22px]" />,
        id: v4(),
      },
      // {
      //   txt: "Resignations ",
      //   url: "resignations",
      //   icon: <BsPersonFillX className="w-[22px] h-[22px]" />,
      //   id: v4(),
      // },
      {
        txt: "Exception Logs",
        url: "exception-logs",
        icon: <ExceptionLogsSvg _size="22" />,
        id: v4(),
      },
    ],
  },
  {
    parent: "Brand",
    id: v4(),
    children: [
      {
        txt: "Organisation Details",
        url: "organisation-details",
        icon: <OrganizationIcon size="22" />,
        id: v4(),
      },
      {
        txt: "Logo & Branding",
        url: "logo-branding",
        icon: <GiAlliedStar size="22" />,
        id: v4(),
      },
    ],
  },
];

export const modalsEnum = {
  updateSkill: "updateSkill",
  deleteModal: "deleteModal",
};

export const addSkillOption = {
  id: "add-skills",
  label: "Add Skills",
  type: "text",
  name: "add-skills",
  placeholder: "Enter Skill Name",
  required: false,
};

export const searchSkillOption = {
  id: "search",
  type: "search",
  name: "search",
  placeholder: "Search Skill...",
  required: false,
};

export const ResignationsHEADINGS = [
  "Id",
  "Employee",
  "Department",
  "Join Date",
  "Approved Last Date",
  "Notice Period",
  "Status",
];

// Add resignation Input fields
export const addResignationInputFields = [
  {
    id: "adminReason",
    label: "Feedback",
    type: "text",
    name: "adminReason",
    placeholder: "Reason",
    required: true,
    value: "",
  },
  {
    id: "reportingManagerReason",
    label: "Feedback from Reporting Manager",
    type: "text",
    name: "reportingManagerReason",
    placeholder: "Reason",
    required: true,
    value: "",
  },
  {
    id: "exitDate",
    label: "Seperation Date of Employee",
    type: "Date",
    name: "exitDate",
    placeholder: "",
    required: true,
    value: "",
  },
];
