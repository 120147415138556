import { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, Outlet } from "react-router-dom";
import InvoicesList from "../../components/invoices/components/InvoicesList";
import usePagination, { PaginationComponent } from "../../hooks/usePagination";
import { InvoiceContext } from "../../context/InvoiceContext";
import { getInvoices } from "../../services/InvoiceService";
import { TABLE_HEADER } from "../../components/invoices/helper";
import InvoiceSvg from "../../media/InvoiceSvg";
import LeftMenu from "../../components/Layout/LeftMenu/LeftMenu";
import { invoiceOptions } from "../../constants/SidebarOptions";

const InvoiceManagement = () => {
  return (
    <>
      <div className="flex">
        <LeftMenu sidebarListItem={invoiceOptions} />

        <div className={`my-leaves-right-list-section h-full w-full`}>
          {/* Invoice content details */}
          {/* All the different route will be shown here */}
          {/* Outlet is given by react router dom  */}
          <Outlet />
        </div>
      </div>
    </>
  );
};

export default InvoiceManagement;
