import { useEffect, useState } from "react";

import AddAddress from "./components/AddAddress";
import ShippedFrom from "./components/ShippedFrom";
import ShippedTo from "./components/ShippedTo";
import TransportDetails from "./components/TransportDetails";
import { showToast } from "../common/Toast";

import { addressModal } from "./helper";
import { GetUserAddresses } from "../../services/AddressService";

const pageNumber = 1;
const pageSize = 1000;
const searchText = "";

const ShippingDetails = ({
  setFormValues,
  setToggleStates,
  toggleStates,
  formValues,
  isUpdating,
}) => {
  // show hide shipping details form
  const [businessAddresses, setBusinessAddresses] = useState([]);
  const [clientAddresses, setClientAddresses] = useState([]);
  const [toggleEditAddressModal, setToggleEditAddressModal] = useState({});

  const [selectedBusinessAddress, setSelectedBusinessAddress] = useState(addressModal);
  const [selectedClientAddress, setSelectedClientAddress] = useState(addressModal);

  //update sameAsBusinessAddress and sameAsClientAddress
  //only when user is updating the invoice
  useEffect(() => {
    const {
      challanDate,
      challanNumber,
      shippedFrom,
      shippedTo,
      transportName,
      transportNote,
      sameAsBusinessAddress,
      sameAsClientAddress,
    } = formValues.shippingDetails;

    if (isUpdating) {
      setFormValues(prevFV => {
        return {
          ...prevFV,
          shippingDetails: {
            ...prevFV.shippingDetails,
            sameAsBusinessAddress,
            sameAsClientAddress,
            challanDate,
            challanNumber,
            shippedFrom,
            shippedTo,
            transportName,
            transportNote,
          },
        };
      });

      //update selected business and client addresses if sameAsBusinessAddress/sameAsClientAddress is checked
      //also update the address if user change the address in business dropdown
      if (sameAsBusinessAddress) setSelectedBusinessAddress(formValues.selectedBusiness);
      if (sameAsClientAddress) setSelectedClientAddress(formValues.selectedClientBusiness);
    }

    if (!isUpdating) {
      //update selected business and client addresses if sameAsBusinessAddress/sameAsClientAddress is checked
      if (sameAsBusinessAddress) setSelectedBusinessAddress(formValues.selectedBusiness);
      if (sameAsClientAddress) setSelectedClientAddress(formValues.selectedClientBusiness);
    }
  }, [formValues.selectedBusiness, formValues.selectedClientBusiness]);

  // fetch addresses
  useEffect(() => {
    fetchBusinessAddresses();
    fetchClientAddresses();
  }, [toggleStates.showShippingDetails, toggleEditAddressModal, toggleStates.toggleShippingModal]);

  // setting state based on if the Add shipping details is checked
  useEffect(() => {
    if (!toggleStates.showShippingDetails) {
      setFormValues(prevFV => ({
        ...prevFV,
        shippingDetails: {
          sameAsBusinessAddress: false,
          sameAsClientAddress: false,
        },
      }));
    }
  }, [toggleStates.showShippingDetails]);

  const changeSelectedBusinessAddress = e => {
    let currentlySelectedBusiness = businessAddresses.filter(x => +x.id === +e.target.value)[0];
    setSelectedBusinessAddress(currentlySelectedBusiness);
  };

  const changeSelectedClientAddress = e => {
    let currentlySelectedClient = clientAddresses.filter(x => +x.id === +e.target.value)[0];

    setSelectedClientAddress(currentlySelectedClient);
  };

  //close address edit modal and update shippedFrom/shippedTo address
  const closeEditAddressModal = updatedAddress => {
    if (updatedAddress.isClient) setSelectedClientAddress(updatedAddress);
    else setSelectedBusinessAddress(updatedAddress);
    setToggleEditAddressModal(prev => !prev);
  };

  // onChange when same as business address is checked;
  const setAddressAsBusiness = e => {
    const { checked } = e.target;
    const { shippingDetails } = formValues;

    if (checked && formValues.selectedBusiness == undefined)
      return showToast("warn", "Please select a business first.");
    if (checked && formValues.selectedBusiness != undefined) {
      setSelectedBusinessAddress(formValues.selectedBusiness);
      setFormValues(prevFV => ({
        ...prevFV,
        shippingDetails: {
          ...prevFV.shippingDetails,
          sameAsBusinessAddress: checked,
        },
      }));
      return;
    }

    //address from dropdown menu
    if (shippingDetails.shippedFrom != 0) {
      let currentlySelectedBusinessAddress = businessAddresses.filter(
        address => +address.id === +shippingDetails.shippedFrom
      );
      setSelectedBusinessAddress(currentlySelectedBusinessAddress[0]);
    }

    setFormValues(prevFV => ({
      ...prevFV,
      shippingDetails: {
        ...prevFV.shippingDetails,
        sameAsBusinessAddress: checked,
      },
    }));
  };

  // onChange when same as client address is checked;
  const setAddressAsClient = e => {
    const { checked } = e.target;
    const { shippingDetails } = formValues;

    if (checked && formValues.selectedClientBusiness == undefined)
      return showToast("warn", "Please select a client first.");
    if (checked && formValues.selectedClientBusiness != undefined) {
      setSelectedClientAddress(formValues.selectedClientBusiness);
      setFormValues(prevFV => ({
        ...prevFV,
        shippingDetails: {
          ...prevFV.shippingDetails,
          sameAsClientAddress: checked,
        },
      }));
      return;
    }

    if (shippingDetails.shippedTo != 0) {
      let currentlySelectedClientAddress = clientAddresses.filter(
        address => +address.id === +shippingDetails.shippedTo
      );
      setSelectedClientAddress(currentlySelectedClientAddress[0]);
    }

    setFormValues(prevFV => ({
      ...prevFV,
      shippingDetails: {
        ...prevFV.shippingDetails,
        sameAsClientAddress: checked,
      },
    }));
  };

  const onShippingDetailsChange = e => {
    const { name, value } = e.target;
    if (name === "shippedFrom") changeSelectedBusinessAddress(e);
    if (name === "shippedTo") changeSelectedClientAddress(e);

    setFormValues(prevFV => ({
      ...prevFV,
      shippingDetails: {
        ...prevFV.shippingDetails,
        [name]: value,
      },
    }));
  };

  //fetch shippedFrom business addresses
  const fetchBusinessAddresses = () => {
    const isClient = false;

    GetUserAddresses(pageNumber, pageSize, searchText, isClient).then(res => {
      //if shippedFrom address was set filter that address from the array

      const selectedShippedToAddress = res?.data.filter(
        address => +address.id === +formValues.shippedFrom
      );
      //only set state if at least there's one selected address
      selectedShippedToAddress.length > 0 &&
        setSelectedBusinessAddress(selectedShippedToAddress[0]);

      setBusinessAddresses(res.data);
    });
  };

  //fetch shippedTo clients addresses
  const fetchClientAddresses = async () => {
    const res = await GetUserAddresses(pageNumber, pageSize, searchText, true);
    //if shippedTo address was set filter that address from the array
    const selectedShippedFromAddress = await res?.data.filter(
      address => +address.id === +formValues.shippedTo
    );
    //only set state if at least there's one selected address
    selectedShippedFromAddress.length > 0 &&
      setSelectedClientAddress(selectedShippedFromAddress[0]);

    setClientAddresses(res.data);
  };

  return (
    <>
      <section className="py-2 bg-[#fff]">
        {/* Shipped from Details */}
        <div className="md:flex md:justify-between gap-2 mt-2">
          <ShippedFrom
            shippingDetails={formValues.shippingDetails}
            setToggleStates={setToggleStates}
            selectedBusinessAddress={selectedBusinessAddress}
            closeEditAddressModal={closeEditAddressModal}
            businessAddresses={businessAddresses}
            onShippingDetailsChange={onShippingDetailsChange}
            setAddressAsBusiness={setAddressAsBusiness}
          />

          <ShippedTo
            shippingDetails={formValues.shippingDetails}
            setToggleStates={setToggleStates}
            selectedClientAddress={selectedClientAddress}
            closeEditAddressModal={closeEditAddressModal}
            clientAddresses={clientAddresses}
            onShippingDetailsChange={onShippingDetailsChange}
            setAddressAsClient={setAddressAsClient}
          />
        </div>

        <TransportDetails
          shippingDetails={formValues.shippingDetails}
          onShippingDetailsChange={onShippingDetailsChange}
        />
      </section>

      <AddAddress
        isClient={toggleStates.shippingIsClient}
        show={toggleStates.toggleShippingModal}
        setToggleStates={setToggleStates}
      />
    </>
  );
};

export default ShippingDetails;
