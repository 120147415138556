function getWeekDates(inputDate) {
  // Copy the input date to avoid modifying the original
  const currentDate = new Date(inputDate);

  // Calculate the day of the week (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
  const dayOfWeek = currentDate.getDay();

  // Calculate the start date of the week (Monday)
  const startDate = new Date(currentDate);

  startDate.setDate(currentDate.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1));

  // Calculate the end date of the week (Sunday)
  const endDate = new Date(startDate);
  endDate.setDate(startDate.getDate() + 6);

  return {
    fromDate: startDate,
    toDate: endDate,
  };
}

const currentDate = new Date();

const fromDate = new Date(getWeekDates(currentDate).fromDate);
const toDate = new Date(getWeekDates(currentDate).toDate);

export const attendanceState = {
  addAttendanceModal: false,
  selectedUserModal: null,
  userOptions: null,
  userOptionsInModal: null,
  selectedUser: null,
  dateRangeInModalGlobal: {
    dateRange: [
      {
        startDate: new Date(),
        endDate: new Date(),
        key: "selection",
      },
    ],
    isOpen: false,
  },
  dateRangeInAttendanceList: {
    dateRange: [
      {
        startDate: fromDate,
        endDate: toDate,
        key: "selection",
      },
    ],
    isOpen: false,
    isDefaultWeekDate: true,
    weekCount: 0,
  },
  add_modal_timer: {
    checkinTime: {
      hours: "10",
      minutes: "00",
      ampm: "AM",
    },
    checkoutTime: {
      hours: "07",
      minutes: "00",
      ampm: "PM",
    },
  },
  add_modal_notes: "",
  rangeGap: 6,
  dialogState: {
    isOpen: false,
    item: null,
  },
};
