import { useState, useEffect } from "react";

import ModalComponent from "../../common/Modal/Modal";
import ViewUserShowCV from "./ViewUserShowCV";
import ViewUserUploadCV from "./ViewUserUploadCV";

import { btnPrimary } from "../../../utils/helper";
import toast from "react-hot-toast";
import { deleteResume, getResume, uploadResume } from "../../../services/FileUploadService";
import { FileTypes } from "../../../enum/FileTypes";
import ModalButtons from "./../../common/Modal/ModalButtons";

const initialFileDetails = {
  fileExt: "",
  imageBytes: "",
  userId: "",
  fileType: undefined,
};

const acceptTypes = ["PDF"];

/**
 * Renders a modal component for viewing a user's CV.
 *
 * @param {Object} viewCV - The state variable for controlling the visibility of the modal.
 * @param {Function} setViewCV - The function for updating the viewCV state variable.
 * @param {string} firstName - The first name of the user.
 * @param {string} lastName - The last name of the user.
 * @param {number} userId - The unique identifier of the user.
 * @return {JSX.Element} The rendered modal component.
 */
const ViewUserCVModal = ({ viewCV, setViewCV, firstName, lastName, userId }) => {
  const [fileDetails, setFileDetails] = useState(initialFileDetails);

  useEffect(() => {
    userId &&
      getResume(userId)
        .then(res => {
          if (res.statusCode === 200) {
            const { fileExt, imageBytes, userId, fileType } = res.data;
            setFileDetails({
              fileExt,
              imageBytes,
              userId,
              fileType,
            });
          }
        })
        .catch(err => toast.error(err.message));
  }, [userId]);

  const createFileObject = async filesObj => {
    const file = filesObj[0];

    if (!file) return;
    const { type } = file;

    const isValidType = acceptTypes.includes(type.split("/")[1].toUpperCase());
    if (!isValidType) return toast.error("Only mentioned types are accepted");

    const data = {
      userId: +userId,
      fileType: FileTypes.Resume,
    };

    const toastId = toast.loading("Uploading Resume...");
    try {
      const res = await uploadResume(file, data);
      if (res.statusCode === 200) {
        const { fileExt, imageBytes, userId, fileType } = res.data;

        setFileDetails({
          fileExt,
          imageBytes,
          userId,
          fileType,
        });
        toast.dismiss(toastId);
        toast.success("File uploaded successfully");
      }
    } catch (err) {
      toast.dismiss(toastId);
      toast.error(err.message);
    }
  };

  const onChangeInputHandler = async e => {
    const files = e.target.files;
    await createFileObject(files);
  };

  const onDragOver = e => {
    e.preventDefault();
  };

  const onDrop = async e => {
    e.preventDefault();
    const file = e.dataTransfer.files;
    await createFileObject(file);
  };

  const onClickDeleteHandler = async () => {
    const res = await deleteResume(userId);
    if (res.statusCode === 200) {
      setFileDetails(initialFileDetails);
      setViewCV(false);
      toast.success("File deleted successfully");
    }
  };

  return (
    <ModalComponent
      show={viewCV}
      onClose={setViewCV.bind(null, false)}
      showFooter={false}
      bodyClassName="px-6"
      heading={`${firstName} ${lastName}'s CV/Resume`}>
      <div
        className={`mt-4 ${
          fileDetails.imageBytes ? "h-[40rem] mx-auto lg:w-[40rem] md:w-[25rem] sm:w-[20rem]" : ""
        }`}>
        {!fileDetails.imageBytes && (
          <ViewUserUploadCV
            fileDetails={fileDetails}
            onChangeInputHandler={onChangeInputHandler}
            onDragOver={onDragOver}
            onDrop={onDrop}
            acceptTypes={acceptTypes}
          />
        )}

        {fileDetails.imageBytes && (
          <ViewUserShowCV
            fileDetails={fileDetails}
            onClickDeleteHandler={onClickDeleteHandler.bind(null, userId)}
          />
        )}
      </div>

      <ModalButtons
        isCustomDeleteButton={true}
        isActionDeleteBtn={false}
        cancelBtnText="Close"
        onClickCancel={setViewCV.bind(null, false)}>
        <a
          disabled={Boolean(fileDetails.imageBytes)}
          href={`data:application/pdf;base64,${fileDetails.imageBytes}`}
          download={`${firstName} ${lastName}'s Resume__Thynkweb.pdf`}
          className={`${
            fileDetails.imageBytes ? btnPrimary() : "bg-gray-600"
          } block px-4 py-2 rounded-md text-white font-[500] cursor-pointer`}>
          Download
        </a>
      </ModalButtons>
    </ModalComponent>
  );
};

export default ViewUserCVModal;
