import styles from "../../components/Layout/Sidebar/Sidebar.module.css";
import { useState, useEffect, useContext } from "react";
import { Outlet, useLocation } from "react-router";
import LeftMenu from "../../components/Layout/LeftMenu/LeftMenu";
import { UserAttendanceOptions, adminAttendanceOptions } from "../../constants/SidebarOptions";
import { useStore } from "../../context/createFastContext";
import { GetReportingUsers, GetUsers, userPreferences } from "../../services/UserService";
import { Avatar } from "flowbite-react";
import InputFields from "../../components/common/Input/InputFields";
import { jobsAssign } from "../../services/JobService";
import { IoIosArrowForward } from "react-icons/io";
import { IoSearch } from "react-icons/io5";
import { cn } from "../../utils/common";
import { useSearchParams } from "react-router-dom";
import Toggle from "../../components/common/Toggle/Toggle";
import { DEFAULT_PAGE_NO, DEFAULT_PAGE_SIZE } from "../../constants/Constant";
import { AuthContext } from "../../context/AuthContext";
import { Role } from "../../enum/Role";

const inActiveLinkStyles = `${styles["sidebaritems"]} !mb-0 !px-[.7rem] !py-2`;
const shadowStyle = {
  boxShadow: "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
};

export const AttendanceManagement = () => {
  const [attendanceState, setStore] = useStore(store => store.attendanceState);

  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);

  const [searchText, setSearchText] = useState("");

  const [filteredUser, setFilteredUser] = useState([]);

  const [jobList, setJobList] = useState([]);
  const [loadingJobs, setLoadingJobs] = useState(true);

  const [searchParams, setSearchParams] = useSearchParams();
  const selectedUserParam = searchParams.get("jobId");

  const [previousJobs, setPreviousJobs] = useState(null);

  const [viewAllUsers, setViewAllUsers] = useState(false);

  const [searchPerformed, setSearchPerformed] = useState(false);

  const { user } = useContext(AuthContext);

  const isAdmin = user.roleId === Role.Administrator;
  const isModerator = user?.roleId === Role.Moderator;

  const selectedUser = attendanceState?.selectedUser?.value?.id;

  const [approvalsView, setApprovalsView] = useState(false);

  // Access specific parts of the location
  const location = useLocation();
  const pathname = location.pathname;

  const isJobAttendanceSelected = pathname === "/attendance/my-attendance";

  const isApprovalsSelected = pathname === "/attendance/approvals";

  useEffect(() => {
    assignJobFetch();
    lastJobs();
  }, [isJobAttendanceSelected]);

  useEffect(() => {
    allReportingUsers();
  }, [viewAllUsers, searchText]);

  const [state, setState] = useState({
    userIds: [],
    showUserDropdown: [false, true],
    approvalUserIds: [],
  });

  const handleUserChange = selectedOption => {
    setStore({
      attendanceState: {
        ...attendanceState,
        selectedUser: selectedOption,
      },
    });
  };
  const handleSearch = event => {
    const searchTerm = event.target.value.trim().toLowerCase();

    setSearchText(searchTerm);

    if (searchTerm === "") {
      // If search term is empty, show all user
      setFilteredUser([]);
      setSearchPerformed(false);
    } else {
      // If there's a search term, filter the users based on firstName and lastName
      const filteredUsers = users.filter(
        user =>
          user.firstName.toLowerCase().includes(searchTerm) ||
          user.lastName.toLowerCase().includes(searchTerm)
      );

      setFilteredUser(filteredUsers);
      setSearchPerformed(true);
    }
  };

  const assignJobFetch = () => {
    jobsAssign().then(response => {
      setJobList(response.data.data);
      setLoadingJobs(false);
    });
  };

  const handleJobChange = function (job) {
    if (job.id === previousJobs) {
      setSearchParams({ jobId: previousJobs });
    } else {
      setSearchParams({ jobId: job.id });
    }
  };

  const lastJobs = () => {
    userPreferences().then(response => {
      setPreviousJobs(response.lastSelectedJobId);
    });
  };

  const allReportingUsers = async () => {
    setLoading(true);
    const response = await GetReportingUsers(
      searchText,
      viewAllUsers,
      DEFAULT_PAGE_SIZE,
      DEFAULT_PAGE_NO
    );
    setUsers(response?.data?.data || []);

    setLoading(false);

    if (searchText?.length === 0 && response.data.data.length === 0 && !isAdmin && !isModerator) {
      setApprovalsView(false);
    } else {
      setApprovalsView(true);
    }
  };

  const handleToggleGetUser = () => {
    setViewAllUsers(prevChecked => !prevChecked);
  };

  return (
    <>
      <div className="flex">
        {(isAdmin || isModerator) && <LeftMenu sidebarListItem={adminAttendanceOptions} />}

        {approvalsView ? (
          <>{!isAdmin && !isModerator && <LeftMenu sidebarListItem={UserAttendanceOptions} />}</>
        ) : null}

        {(isAdmin || isModerator || approvalsView) && (
          <>
            {isApprovalsSelected && (
              <div className="w-[15rem] min-w-[15rem]">
                <section className="flex items-center space-x-4 mt-3">
                  {state[[state.showUserDropdown[0] === true ? "userIds" : "approvalUserIds"]]
                    .length > 0 ? (
                    <>
                      <div className=" relative mt-2">
                        <SelectRejectAll
                          _className="cursor-pointer flex items-center"
                          textclassName="text-[green]"
                          text="Approve all"
                          onClick={() => handleStatus(2)}
                        />
                      </div>
                      <div className=" relative mt-2">
                        <SelectRejectAll
                          _className="cursor-pointer flex items-center"
                          textclassName="text-[red]"
                          text="Reject all"
                          onClick={() => handleStatus(3)}
                        />
                      </div>
                    </>
                  ) : null}

                  <div className="mb-3 mt-3 ml-4">
                    <InputFields
                      InputField={{
                        label: "",
                        type: "search",
                        id: "search",
                        name: "search",
                        placeholder: "Search a user",
                      }}
                      icon={<IoSearch />}
                      value={searchText}
                      onChange={handleSearch}
                    />

                    {isAdmin && (
                      <div className="flex items-center justify-start mt-3">
                        <Toggle
                          checked={viewAllUsers}
                          onChange={handleToggleGetUser}
                          name="myToggle"
                          id="myToggleId"
                          otherProps={{}}
                        />
                        View all users
                      </div>
                    )}
                  </div>
                </section>

                <div>
                  <div>
                    {loading ? (
                      <div className="h-96 flex items-center justify-center">
                        <p className=" text-gray-400">Loading...</p>
                      </div>
                    ) : (
                      <ul>
                        {searchPerformed ? (
                          filteredUser.length > 0 ? (
                            filteredUser.map(user => (
                              <li
                                className="p-2 cursor-pointer"
                                key={user.id}
                                onClick={() => handleUserChange({ value: user })}>
                                <div className="flex items-center">
                                  <div className="flex-shrink-0">
                                    <Avatar
                                      rounded={true}
                                      placeholderInitials={`${user?.firstName && user?.firstName[0].toUpperCase()}${
                                        user?.lastName && user?.lastName[0].toUpperCase()
                                      }`}
                                      className="mx-2 z-auto cursor-pointer border rounded-[50%]"
                                      img={
                                        user?.imageBytes &&
                                        `data:image/jpeg;base64,${user?.imageBytes}`
                                      }
                                    />
                                  </div>
                                  <div className="flex-1 min-w-0 ms-4">
                                    <p className="text-sm font-medium text-gray-900 truncate dark:text-white">
                                      {user.firstName + " " + user.lastName}
                                    </p>
                                    <p className="text-sm text-gray-500 truncate dark:text-gray-400">
                                      {user.email}
                                    </p>
                                  </div>
                                </div>
                              </li>
                            ))
                          ) : (
                            <li className="h-96 flex items-center justify-center">
                              <p className=" text-gray-400">No users found</p>
                            </li>
                          )
                        ) : null}

                        {filteredUser.length === 0 &&
                          users.map(user => (
                            <li
                              className="p-2 cursor-pointer"
                              key={user.id}
                              onClick={() => handleUserChange({ value: user })}>
                              <div className="flex items-center">
                                <div className="flex-shrink-0">
                                  <Avatar
                                    rounded={true}
                                    placeholderInitials={`${user?.firstName && user?.firstName[0].toUpperCase()}${
                                      user?.lastName && user?.lastName[0].toUpperCase()
                                    }`}
                                    className="mx-2 z-auto cursor-pointer border rounded-[50%]"
                                    img={
                                      user?.imageBytes &&
                                      `data:image/jpeg;base64,${user?.imageBytes}`
                                    }
                                  />
                                </div>
                                <div className="flex-1 min-w-0 ms-4">
                                  <p
                                    className={`${cn("text-gray-900", {
                                      "text-[#76ABAE]": user.id === +selectedUser,
                                    })}
                              text-sm font-medium truncate transition-colors dark:text-white flex justify-between items-center`}>
                                    {user.firstName + " " + user.lastName}{" "}
                                    {user.id === +selectedUser && (
                                      <IoIosArrowForward color="#76ABAE" size={14} />
                                    )}
                                  </p>
                                  <p className="text-sm text-gray-500 truncate dark:text-gray-400">
                                    {user.email}
                                  </p>
                                </div>
                              </div>
                            </li>
                          ))}
                      </ul>
                    )}
                  </div>
                </div>
              </div>
            )}
          </>
        )}

        {isJobAttendanceSelected && (
          <div
            className={`bg-white min-w-[15rem] h-[calc(100vh-61px)] overflow-auto p-2 pt-0 pb-0 ${isAdmin || isModerator || approvalsView ? "" : ""}`}>
            <h1 className="flex ml-2 text-l font-semibold border-b border-gray-200 pb-2 mr-2 pt-4 sticky top-0 bg-white">
              Assigned jobs
            </h1>
            <div className="overflow-auto">
              <ul className="overflow-y-auto">
                {loadingJobs && (
                  <p className="text-sm text-gray-700 dark:text-gray-300 mt-5 ml-2 mb-5">
                    Loading...
                  </p>
                )}
                {!loadingJobs && jobList.length === 0 && (
                  <p className="text-sm text-gray-700 dark:text-gray-300 mt-5 ml-2 mb-5">
                    No jobs assigned
                  </p>
                )}
                {!loadingJobs &&
                  jobList.length > 0 &&
                  jobList.map(job => (
                    <li
                      className="m-2 pb-5 pt-3 overflow-hidden cursor-pointer border-b border-gray-200"
                      onClick={() => handleJobChange(job)}
                      key={job.id}>
                      <div>
                        <div className="">
                          <div className="flex-1 min-w-0">
                            <p
                              className={`text-sm font-medium truncate transition-colors dark:text-white flex justify-between ${cn(
                                job.id === +selectedUserParam ||
                                  (job.id === previousJobs && selectedUserParam === null)
                                  ? "text-[#76ABAE]"
                                  : "text-gray-900"
                              )}`}>
                              <span>Id: {job.id}</span>
                              {(job.id === +selectedUserParam ||
                                (job.id === previousJobs && selectedUserParam === null)) && (
                                <IoIosArrowForward color="#76ABAE" size={18} />
                              )}
                            </p>
                          </div>
                          <p className="text-sm font-medium text-gray-900 truncate dark:text-white">
                            Job: {job.name}
                          </p>
                          <p className="text-sm font-medium text-gray-900 truncate dark:text-white">
                            Project: {job.projectName}
                          </p>
                          {job.clientName && (
                            <p className="text-sm font-medium text-gray-900 truncate dark:text-white">
                              Client: {job.clientName}
                            </p>
                          )}
                          {job.clientName && job.isBillable && (
                            <p className="text-sm font-medium text-gray-900 truncate dark:text-white">
                              Billable
                            </p>
                          )}
                          {job.clientName && !job.isBillable && (
                            <p className="text-sm font-medium text-gray-900 truncate dark:text-white">
                              Non Billable
                            </p>
                          )}
                        </div>
                      </div>
                    </li>
                  ))}
              </ul>
            </div>

            <div className="pt-2 pb-2 sticky bottom-0 bg-white">
              <div className="p-4 bg-gray-100 rounded-lg dark:bg-gray-700">
                <div className="flex justify-between items-center mb-3">
                  <span className="bg-purple-100 text-purple-600 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-purple-600 dark:text-purple-200">
                    ProTip
                  </span>
                </div>
                <div className="text-sm text-gray-700 dark:text-gray-300">
                  1. Select a job
                  <br />
                  2. Write description
                  <br />
                  3. Start timer
                </div>
              </div>
            </div>
          </div>
        )}
        <div
          className={`my-leaves-right-list-section h-full w-full ${isAdmin || isModerator || approvalsView ? "" : ""}`}>
          {/* My Attendance content details */}
          {/* All the different route will be shown here */}
          {/* Outlet is given by react router dom  */}
          <Outlet />
        </div>
      </div>
    </>
  );
};
