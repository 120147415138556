import { useState } from "react";
import TableData from "../../../common/Table/TableData";

import TableHeader from "../../../common/Table/TableHeader";
import { ResignationsHEADINGS } from "../../helper";
import { btnPrimary } from "../../../../utils/helper";
import ModalComponent from "../../../common/Modal/Modal";
import ManageResignations from "./ManageResignations";
import PageSection from "../../../Layout/PageSection/PageSection";

/**
 * Create and manage Resignation.
 *
 * @return {JSX.Element} The component JSX element.
 */
const ResignationsList = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  // resignation fields
  const [resignationFields, setResignationFields] = useState({
    id: "",
    adminReason: "",
    reportingManagerReason: "",
    exitDate: "",
    approvelStatus: 3,
  });

  const toggleModal = () => {
    setIsModalVisible(!isModalVisible);
    // clearing fields for add model
    if (isUpdating) {
      setResignationFields(prev => ({
        ...prev.resignationFields,
        id: "",
        adminReason: "",
        reportingManagerReason: "",
        exitDate: "",
        approvelStatus : 3
      }))
    }
    setIsUpdating(false);
  };

  const onSubmitAddResignationHandler = data => {
    // call AddResignation
    toggleModal();
  };

  const onCloseResignHandler = () => {
    toggleModal();
  };

  const onSubmitUpdateResignationHandler = data => {
    // call updateResignation
    toggleModal();
  };

  const onClickHandleUpdateResignation = event => {
    const dataId = event.currentTarget.dataset.id;
    // we'll pick this data from row or we'll call api
    setResignationFields(prev => ({
      ...prev.resignationFields,
      id: 1,
      adminReason: "my admin reason",
      reportingManagerReason: "my admin reason",
      exitDate: "",
      approvelStatus : 3
    }))
    setIsUpdating(true);
    setIsModalVisible(true);
  };

  const updateResignationInputs = (name, value) => {
    setResignationFields(prev => ({
      ...prev,[name]:value
    }))      
  }

  const data = [
    {
      id: 1,
      Employee: "John Doe",
      Department: "HR",
      "Join Date": "2022-01-01",
      "Approved Last Date": "2022-02-01",
      "Notice Period": "30 days",
      Status: "Approved",
    },
  ];

  const addResignationbutton = (
    <button
      className={`${btnPrimary()} px-4 py-2 text-white font-semibold tracking-wide rounded-md`}
      onClick={toggleModal}>
      Add Resignation
    </button>
  );

  return (
    <>
      <PageSection heading="Resignations" button={addResignationbutton}>
        <div className="px-4 divide-y dark:divide-gray-700">
          <div className="flex flex-col py-3 space-y-3 md:flex-row md:items-center md:justify-between md:space-y-0 md:space-x-4">
            <div className="flex items-center justify-between flex-1 space-x-4">
              <h5>
                <span className="text-gray-500">Total Resignations : </span>
                <span className="dark:text-white">1</span>
              </h5>
            </div>
          </div>
        </div>
        <div className="overflow-x-auto">
          <table className="w-full text-sm text-left">
            <TableHeader
              cols={ResignationsHEADINGS}
              styles={{
                tableHead:
                  "text-xs uppercase",
              }}
            />
            <tbody>
              {data.map((rowData, rowIndex) => (
                  <div
                    key={rowIndex}
                    onClick={onClickHandleUpdateResignation}
                    data-id={rowData.id}
                    className="border-b dark:border-gray-600 hover:bg-gray-100 dark:hover:bg-gray-700 table-row cursor-pointer">
                    {Object.values(rowData).map((colValue) => (
                      <TableData data={colValue} />
                    ))}
                  </div>
              ))}
            </tbody>
          </table>

          {/* Mangage Resignation Componenet */}
          <ModalComponent
            show={isModalVisible}
            onClose={toggleModal}
            bodyClassName="px-9 mt-4 pb-8"
            showFooter={false}
            heading={isUpdating ? "Update the Resignation" : "Apply for Resignation"}>
            <ManageResignations
              onSubmit={
                isUpdating ? onSubmitUpdateResignationHandler : onSubmitAddResignationHandler
              }
              onCancel={onCloseResignHandler}
              resignationFields={resignationFields}
              updateResignationInputs = {updateResignationInputs}
            />
          </ModalComponent>
        </div>
      </PageSection>
    </>
  );
};

export default ResignationsList;
