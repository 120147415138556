import { memo, useState } from "react";
import ModalComponent from "../common/Modal/Modal";
import ModalButtons from "../common/Modal/ModalButtons";
import { RiDeleteBin6Line } from "react-icons/ri";
import DeleteModal from "../common/Modal/DeleteModal";
import { deleteLeaveBalanceHistory } from "../../services/LeaveService";
import { STATUS_CODES } from "../../constants/Constant";
import { showToast } from "../common/Toast";

const HistoryModal = memo(props => {
  const {
    show,
    balanceHistoryRows = [],
    balanceHistoryColumns = [],
    onClose,
    Table,
    TableRows,
  } = props;

  const [confirmationModal, setConfirmationModal] = useState({
    show: false,
    date: "", 
    id: undefined,
  });

  const handleOpenConfirmationModal = (id, date) => {
    setConfirmationModal({
      show: true,
      date,
      id
    });
  };

  const handleCloseConfirmationModal = () => {
    setConfirmationModal({
      show: false,
      date: "",
      id: undefined,
    });
  };

  const deleteManualUpdate = async() =>
  {
    const res = await deleteLeaveBalanceHistory(confirmationModal.id);
    if(res.data.statusCode === STATUS_CODES.SUCCESS) {
      showToast("success", "Successfully deleted");
    }
    else {
      showToast("error", "Error deleting history");
    }
    handleCloseConfirmationModal();
    onClose();
  }

  return (
    <div>
      <ModalComponent
        show={show}
        size="5xl"
        bodyClassName="gap-4 mt-2"
        onClose={onClose}
        heading="Balance Histories">
        <div className="overflow-hidden bg-white shadow-md dark:bg-gray-800 sm:rounded-lg relative">
          <div className="overflow-x-auto pt-1">
            <Table col={balanceHistoryColumns} thClassName={"text-center"}>
              <tbody className=" ">
                {balanceHistoryRows.map((row, index) => {
                  return (
                    <TableRows
                      key={index}
                      className="h-10 border-b dark:border-gray-600 hover:bg-gray-100 dark:hover:bg-gray-700">
                      {row.map((cell, cellIndex) => {
                        return (
                          <td className=" text-center" key={cellIndex}>
                            {cell.label === "Delete" ? (
                              <span>
                              <button
                                className={`text-lg font-medium pt-2 hover:text-red-500 ${!cell.canBeDeleted ? 'pointer-events-none' : ''}`}
                                disabled={!cell.canBeDeleted}
                                onClick={e =>{ 
                                  e.stopPropagation(); 
                                  handleOpenConfirmationModal(cell.value, cell.label);
                                  }}>
                                <RiDeleteBin6Line size={15} />
                              </button>
                            </span>
                            ) :(
                              <span>{cell.label}</span>
                            )}
                          </td>
                        );
                      })}
                    </TableRows>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </div>
        <ModalButtons onClickCancel={onClose} cancelBtnText="Cancel" hideActionBtn />
      </ModalComponent>
      <DeleteModal
        modalConfigs={{
          show: confirmationModal.show,
          size: "md",
          onClose: handleCloseConfirmationModal,
          showFooter: false,
          otherProps: {
            popup: true,
          },
        }}
        message={`Are you sure you want to delete update history on ${confirmationModal.date}?`}
        onDelete={deleteManualUpdate}
        onCancel={handleCloseConfirmationModal}
        showIcon={true}
      />
    </div>
  );
});

export default HistoryModal;
