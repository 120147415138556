import { useEffect, useState, useMemo, useCallback, useRef } from "react";

import ModalComponent from "../common/Modal/Modal";
import SidebarComponent from "../common/Sidebar/Sidebar";
import EmployeeModalTabs from "./Tabs";
import { showToast } from "../common/Toast";

import { EditEmployeeTabs, MANDATORY_EDIT_FIELDS, checkMandatoryFields } from "./helper";
import { CreateUser, EditUser } from "../../services/UserService";
import { trimValues } from "../../utils/helper";
import toast from "react-hot-toast";
import { INVITE_USER_MESSAGES } from "../../constants/Messages";
import { useAppDispatch, useAppSelector } from "../../store/store";
import { resetSalaryStructure } from "../../store/slices/salaryStructureSlice";
import { initialUserManagementState, resetUserManagement, setDisableSubmitButton, setRefetchUsers, setSelectedUserModalItem, setUser, setUserManagement } from "../../store/slices/userManagementSlice";
import { UserStatus } from "../../enum/UserStatus";

export const AddEditEmployeeModal = ({}) => {
  const dispatch = useAppDispatch();
  const salaryStructure = useAppSelector(state => state.salaryStructure);
  const umState = useAppSelector(state => state.userManagement);
  const user = useAppSelector(state => state.userManagement.user);

  useEffect(() => {
    let timeout;

    if (Object.keys(user).length > 0) {
      //toggle submit button
      timeout = setTimeout(() => {
        const mandatoryFields = MANDATORY_EDIT_FIELDS(user, salaryStructure);
        const isEmpty = checkMandatoryFields(user, mandatoryFields);

        //if values are not empty enable submit btn
        dispatch(setDisableSubmitButton(!isEmpty));
      }, 100);
    }

    return () => clearTimeout(timeout);
  }, [user, salaryStructure]);
  

  // Function to handle the change in  user
  const handleUserChange = e => {
    const { name } = e.target;
    let value = e.target.value;

    if (name.toLowerCase() === "pan") value = value.toUpperCase();

    dispatch(setUser({
      ...user,
      [name]: value,
    }))
  };

  //Function for email validation
  const isValidEmail = email => {
    // Regular expression for checking email format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const updateUser = async () => {

    const mandatoryFields = MANDATORY_EDIT_FIELDS(user, salaryStructure);

    const isEmpty = checkMandatoryFields(user, mandatoryFields);

    //if values are not empty disable submit btn
    dispatch(setDisableSubmitButton(!isEmpty));

    if (!isEmpty) return toast.error("Fields marked with ( * ) are mandatory");

    //create a new object with same fields as columns in database
    const userDetails = {};

    for (let key in user) {
        userDetails[key] = user[key];
    }

    // Temp-fix: Remove unnecessary fields when saving user without updating employment type when editing user
    // TO-DO: Proper fix in backend
    const fixedUserFieldValues = userDetails.userFieldValues.map(ufv => {
      return {
        ...ufv,
        userField: null,
        option: null,
      };
    });

    const trimmedUserDetails = trimValues({...userDetails, userFieldValues: fixedUserFieldValues});
    // Validate work email before submitting
    if (!isValidEmail(trimmedUserDetails?.email)) {
      return showToast("warn", INVITE_USER_MESSAGES.VALID_EMAIL);
    }

    //Validate personal email before submitting
    if (trimmedUserDetails?.personalEmail) {
      if (!isValidEmail(trimmedUserDetails?.personalEmail)) {
        return showToast("warn", INVITE_USER_MESSAGES.VALID_EMAIL);
      }
    }

    const earningAssociationsReq = salaryStructure.earningAssociationValues.map(ea => {
      return {
        id: ea.associationId,
        tenantEarningId: ea.tenantEarningId,
        calculationValue: ea.calculationValue
      }
    })

    const userDetailsWithComponentAssociations = {
      ...trimmedUserDetails,
      earningAssociations: earningAssociationsReq,
      deductionAssociations: salaryStructure.deductionAssociationValues,
      annualCTC: salaryStructure.annualCTC,
      regimeType: +salaryStructure.regimeType,
    };

    const editUserRes = await EditUser(userDetailsWithComponentAssociations);

    if (editUserRes?.status === 200) {
      resetStates();
      showToast("success", "User updated successfully");
      return;
    }
  };

  const inviteUser = async () => {
    const userWithAllFields = {
      ...user,
      annualCTC: salaryStructure.annualCTC,
      regimeType: +salaryStructure.regimeType,
    }
    const isEmpty = checkMandatoryFields(userWithAllFields);

    if (!isEmpty) return showToast("warn", "Fields marked with ( * ) are mandatory");

    //empty string cannot be send to the database dateOfBirth = null/value
    const dateOfBirth = user.dateOfBirth === "" ? null : user.dateOfBirth;
    const dateOfConfirmation = user.dateOfConfirmation === "" ? null : user.dateOfConfirmation;
    const status = umState.isAddingRestrictedUser ? UserStatus.RestrictedLogin : UserStatus.PendingEmailVerification;
    const userDetails = {
      ...user,
      dateOfBirth,
      dateOfConfirmation,
      status
    };

    let trimmedUserDetails = trimValues(userDetails);

    // Validate work email before submitting
    if (!isValidEmail(trimmedUserDetails?.email)) {
      return showToast("warn", INVITE_USER_MESSAGES.VALID_EMAIL);
    }

    //Validate personal email before submitting
    if (trimmedUserDetails?.personalEmail) {
      if (!isValidEmail(trimmedUserDetails?.personalEmail)) {
        return showToast("warn", INVITE_USER_MESSAGES.VALID_EMAIL);
      }
    }

    // Create earning association body
    const earningAssociationsReq = salaryStructure.earningAssociationValues.map(ea => {
      return {
        id: ea.associationId,
        tenantEarningId: ea.tenantEarningId,
        calculationValue: ea.calculationValue
      }
    })

    trimmedUserDetails = {
      ...trimmedUserDetails,
      earningAssociations: earningAssociationsReq,
      deductionAssociations: salaryStructure.deductionAssociationValues,
      annualCTC: salaryStructure.annualCTC,
      regimeType: +salaryStructure.regimeType
    }

    try {
      const res = await CreateUser(trimmedUserDetails);

      if (+res.data?.statusCode === 300)
        return showToast("warn", "User already exists with this email!");
      if (+res.data?.statusCode !== 200) return showToast("warn", "Something went wrong!");

      //removed else statement because that would run the code even if we get 400, 404 etc errors
      resetStates();
      showToast("success", "User has been invited, pending verification!");
    } catch (error) {
      showToast("warn", "Error inviting user");
    }
  };

  // Function to submit user details
  const onSubmitUserDetails = () => {
    if(umState.isEditingUser) {
      updateUser();
    }
    else {
      inviteUser();
    }
  };

  const onClickSideBarItem = item => {
    dispatch(setSelectedUserModalItem(item));
  };

  const resetStates = () => {
    dispatch(setUserManagement({
      ...initialUserManagementState,
      selectedEmployeeListTab: umState.selectedEmployeeListTab,
      refetchUsers: !umState.refetchUsers, 
    }));
    dispatch(resetSalaryStructure());
  };

  const getModalHeading = () => {
    if(umState.isAddingRestrictedUser) {
      return "Add User";
    }
    else {
      return umState.isEditingUser ? "Edit User" : "Invite User"
    }
  }
  //memoized functions and objects
  const memoizedOnSubmit = onSubmitUserDetails;
  const memoizedOnClickSideBarItem = useCallback(onClickSideBarItem, []);


  return (
    <>
      {umState.toggleUserModal && (
        <ModalComponent
          show={umState.toggleUserModal}
          onClose={resetStates}
          heading={getModalHeading()}
          showFooter={false}
          size="5xl"
          bodyClassName="flex gap-4 mt-2">
          <SidebarComponent
            options={EditEmployeeTabs}
            activeItem={umState.selectedUserModalItem}
            handleSubmit={memoizedOnSubmit}
            disabled={umState.disableSubmitButton}
            onClickSideBarItem={memoizedOnClickSideBarItem}
            isEditing={umState.isEditingUser}
          />

          <div className="border"></div>
          <div className="w-[100%] h-[75vh] px-4 py-4 overflow-auto">
              <form>
                <EmployeeModalTabs
                  handleUserChange={handleUserChange}
                />
              </form>
          </div>
        </ModalComponent>
      )}
    </>
  );
};
