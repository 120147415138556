import { memo } from "react";
import Step from "./Step";

const Stepper = ({ steps, content, prevNext, handleStep }) => {
  return (
    <>
      <ol
        onClick={handleStep}
        className="flex items-center w-[100%] max-w-[35rem] mx-[auto] relative">
        {prevNext}
        {steps.map((step, index) => {
          return (
            <Step
              id={step.id}
              key={index}
              name={step.name}
              iconColor={step.color}
              barColor={step.barColor}
              isLast={index === steps.length - 1}
            />
          );
        })}
      </ol>
      {content}
    </>
  );
};

export default memo(Stepper);
