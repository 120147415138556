import { useRef, useState } from "react";
import ModalComponent from "../../../components/common/Modal/Modal";
import { editLeaveType, deleteLeaveTypes } from "../../../services/LeaveService";
import { showToast } from "../../../components/common/Toast";
import { EditLeaveTypeBody } from "./EditLeaveTypeBody";
import { checkboxOptions } from "./options";
import { Button } from "flowbite-react";
import { DeleteEntity } from "./../../../components/common/DeleteEntity";
import { btnPrimary } from "../../../utils/helper";

const obj = checkboxOptions.reduce((acc, option) => {
  acc[option.name] = option.checked;
  return acc;
}, {});

export const EditLeaveTypeModal = ({
  leaveTypeId,
  leaveTypeName,
  setIsChange,
  setRenderLeaveType,
  toggleEditModal,
  onClose,
}) => {
  const formRef = useRef(null);
  const [leaveTypeInfo, setLeaveTypeInfo] = useState({});
  const [checkboxState, setCheckboxState] = useState(obj);
  const [isDelete, setIsDelete] = useState(false);

  const handleEditLeaveType = e => {
    e.preventDefault();
    const { name, value } = e.target;
    setLeaveTypeInfo(prevState => ({
      ...prevState,
      id: leaveTypeId,
      [name]: value,
    }));
  };

  const handleSubmit = e => {
    e.preventDefault();
    const body = {
      id: leaveTypeInfo.id,
      name: leaveTypeInfo.name,
      description: leaveTypeInfo.description,
      isPaid: checkboxState.isPaid,
      reduceLeaveOnAbsent: checkboxState.reduceLeaveOnAbsent,
      allowNegativeLeaves: checkboxState.allowNegativeLeaves,
    };
    editLeaveType(body).then(response => {
      setRenderLeaveType(true);
      onClose();
      if (response.data?.message === "Success!" && response.data?.statusCode === 200) {
        sessionStorage.setItem("renderAddEmploymentType", JSON.stringify(true));
        showToast("success", "Leave type updated successfully");
        setIsChange(prevIC => !prevIC);
      }
    });
  };

  const handleDelete = async id => {
    const res = await deleteLeaveTypes(id);
    if (res?.data?.statusCode === 200) {
      showToast("success", "Leave type deleted successfully");
      setRenderLeaveType(prevRLT => !prevRLT);
      onClose();
      setIsDelete(false);
    }
    if (res?.data?.statusCode === 406) {
      showToast("error", "Leave Type Already in use!");
    }
  };

  return (
    <div>
      <ModalComponent
        show={toggleEditModal}
        heading="Edit Leave Type"
        showFooter={false}
        onClose={() => onClose()}>
        <section className="flex flex-col gap-4 mt-5 px-3 justify-center items-stretch bg-white dark:bg-gray-900">
          <EditLeaveTypeBody
            leaveTypeId={leaveTypeId}
            handleEditLeaveType={handleEditLeaveType}
            formRef={formRef}
            checkboxState={checkboxState}
            setCheckboxState={setCheckboxState}
            leaveTypeInfo={leaveTypeInfo}
            setLeaveTypeInfo={setLeaveTypeInfo}
          />

          <div className="mt-8 mb-2 flex justify-end items-center gap-2">
            {/* // Button to delete leave type */}
            <Button onClick={() => setIsDelete(true)} color="failure">
              Delete
            </Button>

            {/* Button to Update Leave Type */}
            <button
              type="submit"
              onClick={handleSubmit}
              className={`${btnPrimary()} px-5 py-2.5 text-sm font-medium text-center text-white  rounded-lg `}>
              Update
            </button>
          </div>
        </section>
      </ModalComponent>
      <ModalComponent
        size="lg"
        heading="Delete Leave Type"
        show={isDelete}
        onClose={setIsDelete.bind(null, false)}
        showFooter={false}>
        <h2 className="text-lg text-gray-700 font-semibold text-center mt-4">
          Are you sure you want to Delete "{leaveTypeName}" ?
        </h2>

        <div className="flex items-center gap-4 justify-end w-full mt-8">
          <Button onClick={handleDelete.bind(null, leaveTypeId)} color="failure">
            Yes, I am sure
          </Button>
          <Button onClick={setIsDelete.bind(null, false)} color="gray">
            Cancel
          </Button>
        </div>
      </ModalComponent>
    </div>
  );
};
