import { useSelector, useDispatch } from "react-redux";
import { BsPlusSquareFill } from "react-icons/bs";
import { Button } from "flowbite-react";

import TermsAndConditions from "./TermsAndConditions/TermsAndConditions";
import Note from "./Note/Note";

import { setLoadingFirstTC } from "../../../store/slices/TCSlice";
import { DEFAULT_TC } from "../helper";
import { btnPrimary } from "../../../utils/helper";
import { AddFirstTC } from "../customFieldHelper";

const Legal = ({ toggleStates, formValues, setFormValues, updateToggleState, invoiceId }) => {
  const { isLoadingFirstTC } = useSelector(state => state.TC);
  const dispatch = useDispatch();

  const onClickTCHandler = async () => {
    updateToggleState("isTermsAndConditionsVisible", true);
    const TC = await AddFirstTC(DEFAULT_TC);
    //
    setFormValues(prevFV => ({ ...prevFV, TAndC: TC }));
    //don't show the Terms and Conditions until API request is finish
    dispatch(setLoadingFirstTC(false));
  };

  return (
    <>
      <div className="flex items-center gap-4 mb-4">
        {!toggleStates.isTermsAndConditionsVisible && (
          <Button
            onClick={onClickTCHandler}
            className={`${btnPrimary()} flex items-center`}>
            <div>
              <BsPlusSquareFill />
            </div>
            <span className="ml-2">Terms And Conditions</span>
          </Button>
        )}
        {!toggleStates.isNotesVisible && (
          <Button
            onClick={() => updateToggleState("isNotesVisible", true)}
            className={`${btnPrimary()} flex items-center`}>
            <div>
              <BsPlusSquareFill />
            </div>
            <span className="ml-2">Notes</span>
          </Button>
        )}
      </div>

      {toggleStates.isTermsAndConditionsVisible && !isLoadingFirstTC && (
        <TermsAndConditions
          TC={formValues.TAndC}
          setFormValues={setFormValues}
          updateToggleState={updateToggleState}
          invoiceId={invoiceId}
        />
      )}

      {toggleStates.isNotesVisible && (
        <Note
          Note={formValues.Note}
          setFormValues={setFormValues}
          updateToggleState={updateToggleState}
          invoiceId={invoiceId}
        />
      )}
    </>
  );
};

export default Legal;
