import {
  getCustomFields,
  deleteCustomFields,
  deleteAssociatedCustomField,
} from "../../services/InvoiceService";
import { InvoiceCustomField } from "../../enum/InvoiceCustomField";
import { addCustomField } from "../../services/InvoiceService";
import { v4 as uuidv4 } from "uuid";
import toast from "react-hot-toast";

const fetchHeaderFields = async (headerField, invoiceId, setFormValues) => {
  const res = await getCustomFields(headerField, invoiceId);

  setFormValues(prevFV => ({ ...prevFV, headerField: res.data }));
};

const fetchInvoiceHeader = async (header, setFormValues) => {
  const res = await getCustomFields(header);
  setFormValues(prevFV => ({ ...prevFV, invoiceHeader: res.data[0] }));
};

/**
 * Fetches custom fields and updates the form values.
 *
 * @param {function} setFormValues - A function to set the form values.
 * @param {boolean} isUpdating - Specifies whether the data is being updated.
 * @param {any} data - The data to be processed.
 * @param {string} invoiceId - The ID of the invoice.
 * @return {Promise<void>} A Promise that resolves when the custom fields have been fetched and the form values have been updated.
 */
export const fetchCustomFields = async (setFormValues, isUpdating = false, data, invoiceId) => {
  const fetchUpdatedCustomFields = async () => {
    if (!data) return;
    const res = await fetchHeaderFields(InvoiceCustomField.HeaderFields, invoiceId, setFormValues);

    if (res?.data.length === 0) return;

    const updatedCustomFields = data.map(field => {
      const matchingElement = res?.data.find(item => field.fieldId === item.id);

      if (!matchingElement) return field;
      const { id, ...rest } = matchingElement;
      return { ...field, ...rest };
    });

    setFormValues(prevFV => ({
      ...prevFV,
      headerField: updatedCustomFields,
    }));
  };

  if (!isUpdating)
    await Promise.allSettled([
      fetchHeaderFields(InvoiceCustomField.HeaderFields, undefined, setFormValues),
      fetchInvoiceHeader(InvoiceCustomField.Header, setFormValues),
    ]);
  if (isUpdating) await fetchUpdatedCustomFields();
};

//Add more header fields
export const onAddMoreHeaderField = (headerField, setFormValues) => {
  //create new header field
  const updatedHeaderField = [
    ...headerField,
    {
      fieldName: "",
      fieldValue: "",
      fieldId: uuidv4(),
    },
  ];
  setFormValues(prevFV => ({ ...prevFV, headerField: updatedHeaderField }));
};

//Getting form values
//only pass isUpdating if user is updating the invoice
export const getHeaderFieldFormValues = (
  formValues,
  headerField,
  setFormValues,
  isUpdating = false
) => {
  let updatedHeaderField;

  //if id and groupId exist in current item check for id and update the item corresponding object
  if ("id" in formValues && "groupId" in formValues && !isUpdating) {
    updatedHeaderField = headerField.map(ele => {
      return formValues.id === ele.id ? formValues : ele;
    });
  }
  //id and groupId does not exist in the the item check for fieldId and update the corresponding object
  else {
    updatedHeaderField = headerField.map(ele => {
      return formValues.fieldId === ele.fieldId ? formValues : ele;
    });
  }
  setFormValues(prevFV => ({ ...prevFV, headerField: updatedHeaderField }));

  if (isUpdating) {
    const updatedHeaderField = headerField.map(ele => {
      return formValues.fieldId === ele.fieldId ? formValues : ele;
    });

    setFormValues(prevFV => ({
      ...prevFV,
      headerField: updatedHeaderField,
    }));
  }
};

//Deleting Header Fields
export const onDeleteHeaderField = async (index, headerField, setFormValues) => {
  let res;
  const tempHeaderField = headerField[index];
  const updatedHeaderField = headerField.filter((_, ind) => ind !== index);
  const isExist = "invoiceId" in tempHeaderField && tempHeaderField?.tenantId;

  //if updating existing invoice - it'll have both invoiceId and tenantId
  if (isExist)
    res = await deleteAssociatedCustomField(tempHeaderField.id, tempHeaderField.invoiceId);
  else if (!isExist) res = await deleteCustomFields(tempHeaderField.id);

  if (res.statusCode === 200) {
    setFormValues(prevFV => ({ ...prevFV, headerField: updatedHeaderField }));
    toast.success("Header field deleted");
    return;
  } else return toast.error(res.message);
};

//Terms and condition
export const getAllTAndC = async (setFormValues, updateToggleState, invoiceId) => {
  let res;
  if (!invoiceId) res = await getCustomFields(InvoiceCustomField.TermsAndConditions);
  else res = await getCustomFields(InvoiceCustomField.TermsAndConditions, invoiceId);
  const data = await res?.data;

  //if T&C exist show the container and only update the TAndC state if some data exist on server
  if (data.length > 0) {
    updateToggleState("isTermsAndConditionsVisible", true);
    setFormValues(prevFV => ({ ...prevFV, TAndC: data }));
  }
};

export const AddFirstTC = async TC => {
  const PROMISES = TC.map(field =>
    addCustomField({
      fieldName: field.fieldName,
      groupId: InvoiceCustomField.TermsAndConditions,
    })
  );
  const res = await Promise.allSettled(PROMISES);
  const filteredTC = res.map(field =>
    field.status === "fulfilled" ? field.value.data : field.reason
  );
  return filteredTC;
};

export const getAllNotes = async (setFormValues, updateToggleState, invoiceId) => {
  let res;
  if (!invoiceId) res = await getCustomFields(InvoiceCustomField.Notes);
  else res = await getCustomFields(InvoiceCustomField.Notes, invoiceId);
  const data = await res?.data;

  //if T&C exist show the container and only update the TAndC state if some data exist on server
  if (data.length > 0) {
    updateToggleState("isNotesVisible", true);
    setFormValues(prevFV => ({ ...prevFV, Note: data[0] }));
  }
};
