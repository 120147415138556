import { Button } from "flowbite-react";
import { btnPrimary } from "../../../utils/helper";

/**
 * Renders a set of modal buttons.
 *
 * @param {{children : JSX.Element, cancelBtnClass: string, actionBtnClass: string, cancelBtnText: string, actionBtnText: string, actionBtnType: string, btnCont: string,  isActionDeleteBtn: boolean, onClickAction: Function, onClickCancel: Function}} props - The props for the modal buttons.
 * @param {JSX.Element} children - With children you can modify action button or pass custom buttons.
 * @param {boolean} isCustomDeleteButton - Indicates if the delete is a custom button.
 * @param {string} cancelBtnText - The text to display on the cancel button.
 * @param {string} actionBtnText - The text to display on the action button.
 * @param {boolean} isActionDeleteBtn - Indicates if the action button is a delete button.
 * @param {string} cancelBtnClass - The CSS class for the cancel button.
 * @param {Function} onClickAction - The function to execute when the action button is clicked.
 * @param {Function} onClickCancel - The function to execute when the cancel button is clicked.
 * @param {string} actionBtnClass - The CSS class for the action button.
 * @param {string} actionBtnType - The type of the action button.
 * @param {btnCont} btnCont - The CSS class for the button container.
 * @return {JSX.Element} The rendered modal buttons.
 */
const ModalButtons = ({
  children,
  cancelBtnText,
  actionBtnText,
  isActionDeleteBtn,
  cancelBtnClass,
  onClickAction,
  actionBtnType,
  onClickCancel,
  actionBtnClass,
  btnCont,
  isCustomDeleteButton,
  hideActionBtn,
  form,
}) => {
  return (
    <div className={`${btnCont} flex items-center justify-end gap-3 mt-4`}>
      {/* If user wants to use some other action other than deleting then render the action button and also the button is not a custom button */}
      {!hideActionBtn && !isActionDeleteBtn && !isCustomDeleteButton && (
        <button
          type={actionBtnType}
          form={form}
          onClick={onClickAction}
          className={`${actionBtnClass} px-4 py-2 rounded-md text-white !font-[500] tracking-wide ${btnPrimary()}`}>
          {actionBtnText}
        </button>
      )}
      {/* If user wants to perform an delete action and also a button is also not a custom button*/}
      {isActionDeleteBtn && !isCustomDeleteButton && (
        <Button color="failure" onClick={onClickAction} className={actionBtnClass}>
          {actionBtnText}
        </Button>
      )}
      {/*  If user wants to use a custom button*/}
      {isCustomDeleteButton && children}
      {/* if user wants to cancel the action , it is not  a custom button*/}
      <Button color="gray" className={cancelBtnClass} onClick={onClickCancel}>
        {cancelBtnText}
      </Button>
    </div>
  );
};

export default ModalButtons;
