/**
 * @namespace formatDate format date
 * @param {string} date date string in any format
 * @returns formatted date `Aug 07, 2023`
 */
export const formatDate = date => {
  if (!date) return;
  return new Date(date)
    .toLocaleDateString("en-US", {
      day: "2-digit",
      month: "short",
      year: "numeric",
    })
    .split("-")
    .join(" ");
};


export const radioPrimaryClass = () =>
  "cursor-pointer text-thynkwebPrimary-900 bg-gray-100 border-gray-300 focus:ring-thynkwebPrimary-500 dark:focus:ring-thynkwebPrimary-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600";

export const btnPrimary = () =>
  "tc-btn-dark";

/**
 * @namespace trimValues trim values from object only from a string value
 * @param {Object} values Object with key value pair
 * @returns trimmed values from object values
 */
export const trimValues = values => {
  const trimmedValues = {};
  for (const key in values) {
    if (typeof values[key] === "string") trimmedValues[key] = values[key].trim();
    else trimmedValues[key] = values[key];
  }
  return trimmedValues;
};

// Converts a date to a string by using the current or specified locale.
export const convert_Date = (split, join, date, lang, day, month, year, splitChar, joinChar) => {
  if (split) {
    return new Date(date)
      .toLocaleDateString(lang, {
        day: day,
        month: month,
        year: year,
      })
      .split(splitChar);
  } else if (split && join) {
    return new Date(date)
      .toLocaleDateString(lang, {
        day: day,
        month: month,
        year: year,
      })
      .split(splitChar)
      .join(joinChar);
  } else {
    return new Date(date).toLocaleDateString(lang, {
      day: day,
      month: month,
      year: year,
    });
  }
};

/**
 * Checks if a value includes a specific substring.
 *
 * @param {any} value - The value to check.
 * @param {string} included - The substring to search for.
 * @return {boolean} Returns true if the value includes the substring, otherwise false.
 */
export const isIncludes = (value, included) => {
  return value.toString().includes(included);
};

/**
 * Replaces occurrences of a specified value in a string with another value.
 *
 * @param {any} value - The string or number to be replaced.
 * @param {any} from - The value to be replaced.
 * @param {any} to - The value to replace all occurrences of `from`.
 * @return {string} - The resulting string after replacement.
 */
export const replaceWith = (value, from, to) => {
  return value.toString().replace(from, to);
};

/**
 * Generates an array of options for a React Select component based on the given data.
 *
 * @param {[{id: string|number, value : string| number, label: string | number}]} data - The data used to generate the options.
 * @param {string} customId - The name of the property that contains the unique identifier for each option. (optional)
 * @return {Array} An array of options for the React Select component.
 */
export const createReactSelectOptions = function (data, customId) {
  if (data.length === 0) return data;
  else {
    const options = data.map(item => {
      return {
        id: item.id || item[customId],
        value: item.name,
        label: item.name,
      };
    });
    return options;
  }
};

/**
 * Converts a base64 string to a File object with the specified file name.
 *
 * @param {string} stringFile - The base64 string to convert.
 * @param {string} fileName - The name of the file.
 * @return {File} The converted File object.
 */
export const base64ToFile = function (stringFile, fileName, fileType) {
  let type;
  if (fileType === "pdf") type = `application/${fileType}`;
  else type = `image/${fileType}`;
  const file = new File([stringFile], fileName, { type: type });
  return file;
};
