import { useSelector, useDispatch } from "react-redux";
import { setDocType, setOtherDocType } from "../../store/slices/employment";

import InputFields from "../common/Input/InputFields";

import { DOCS_LIST } from "../../constants/Constant";
import { otherDocTypeFields } from "./helper";
import SelectWithDropdown from "../common/SelectWithDropdown/SelectWithDropdown";
import { FileTypes, FileTypesLabel } from "../../enum/FileTypes";

/**
 * Generates the function comment for the given function body.
 *
 * @return {JSX.Element} The JSX element representing the select document type component.
 */
const SelectDocumentType = () => {
  const { docType } = useSelector(state => state.employment.user);
  const dispatch = useDispatch();

  const selectDocTypeHandler = (_, selectedOption) => {
    dispatch(setDocType(selectedOption.value));
  };
  const otherDocTypeHandler = e => {
    const docType = e.target.value;

    if (!docType || docType.trim() === "") return dispatch(setOtherDocType(""));

    dispatch(setOtherDocType(docType));
  };

  return (
    <>
      <div>
        <label className="font-semibold tracking-wide mb-1 block" htmlFor="docType">Document Type</label>
        <SelectWithDropdown
          options={DOCS_LIST}
          itemsContainer="h-[14rem] overflow-y-scroll"
          defaultValue={docType.type ? FileTypesLabel[docType.type] : "Select Document Type"}
          isDefaultValue={true}
          size="sm"
          id="docType"
          handleChange={selectDocTypeHandler}
          backgroundColor="bg-gray-50 border border-gray-300 w-[20rem] rounded-lg h-[2.7rem]"
          className="w-[18rem] -pl-[1rem]"
        />
      </div>

      {docType.type === FileTypes.Other && (
        <InputFields
          onChange={otherDocTypeHandler}
          InputField={otherDocTypeFields}
          value={docType.otherType}
        />
      )}
    </>
  );
};

export default SelectDocumentType;
