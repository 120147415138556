import { v4 } from "uuid";

export const inputFields = [
  {
    name: "tenantName",
    label: "Organization Name",
    id: v4(),
    placeholder: "Enter Organization Name",
    type: "text",
    required: true,
  },
  {
    name: "pan",
    label: "PAN Number",
    id: v4(),
    placeholder: "Enter PAN Number",
    type: "text",
    required: false,
  },
  {
    name: "tan",
    label: "TAN Number",
    id: v4(),
    placeholder: "Enter TAN Number",
    type: "text",
    required: false,
  },
  {
    name: "address",
    label: "Address",
    id: v4(),
    placeholder: "Enter Address",
    type: "text",
    required: true,
  },
  {
    name: "gstNumber",
    label: "GST",
    id: v4(),
    placeholder: "Enter GST Number",
    type: "number",
    required: false,
  },
];
