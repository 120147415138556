import { IoAddCircleOutline } from "react-icons/io5";

const AddSvg = ({ color, size, className }) => (
  <IoAddCircleOutline
    color={color}
    size={size}
    className={className}
  />
);

export default AddSvg;
