import { axiosInstance } from "../context/AuthContext";
const BaseUrl = process.env.REACT_APP_BASE_URL;
const statutoryBaseUrl = `${BaseUrl}/Payroll/StatutoryComponents`;


//Getting Statutory Component
export const getStatutoryComponent = async (statutoryComponentType) => {
    const response = await axiosInstance.get(`${statutoryBaseUrl}?statutoryComponentType=${statutoryComponentType}`)
    return response
}

export const getAllStatutoryComponents = async() => {
    const response = await axiosInstance.get(`${statutoryBaseUrl}/all`)
    return response
}

//Deleting Statutory Component
export const disableStatutoryComponent = async (id) => {
    const response = await axiosInstance.delete(`${statutoryBaseUrl}/${id}`)
    return response
}

//Updating Statutory Component
export const updateStatutoryComponent = async (statutoryDetails) => {
    const response = await axiosInstance({
        url: statutoryBaseUrl,
        method: "PUT",
        data: JSON.stringify(statutoryDetails),
    })
    return response
}