import React, { useEffect, useState } from "react";

import { Button } from "flowbite-react";
import { showToast } from "../../components/common/Toast";
import { useSelector } from "react-redux";
import { GoPlus } from "react-icons/go";

import { btnPrimary } from "../../utils/helper";
import ProjectsList from "../../components/Attendance/Projects/ProjectsList";
import {
  addProject,
  getProjects,
  getProjectsById,
  updateProject,
} from "../../services/ProjectService";
import { DEFAULT_PAGE_NO, DEFAULT_PAGE_SIZE, STATUS_CODES } from "../../constants/Constant";
import AddProject from "../../components/Attendance/Projects/AddProject";
import { PROJECT_MESSAGES } from "../../constants/Messages";
import DeleteProject from "../../components/Attendance/Projects/DeleteProject";
import PageSection from "../../components/Layout/PageSection/PageSection";
import usePagination, { PaginationComponent } from "../../hooks/usePagination";
import { GetUserByParam } from "../../services/UserService";
import { debouncedPromiseOptions } from "../../utils/common";
import { getBusinessById, getBusinesses } from "../../services/BusinessService";
import { ProjectRole } from "../../enum/Project";
import { AiOutlineFundProjectionScreen } from "react-icons/ai";

//Initial data for project details
const projectInitialDetails = {
  name: "",
  businessId: {},
  projectCost: "",
  description: "",
  projectHead: {},
  projectManager: {},
  assignees: [],
};

let timeout;

// Initial data for toggle project details
const initialToggleProjectDetails = {
  toggleProjectModal: false,
  isChange: false,
  isDisabled: true,
  isUpdatingProjectDetails: false,
  isDeleteProjectDetails: false,
};

//temp variable for api call
const isClient = true;

/**
 * @param {{toggleProjectModal: boolean, isChange: boolean, isDisabled: boolean, isUpdatingProjectDetails: boolean, isDeleteProjectDetails: boolean}} toggleProjectDetails
 * @param {{name: string, businessId: Object, isBillable: boolean, projectCost: string, description: string, projectHead: Object, projectManager: Object, assignees: Array}} projectFormDetails
 * Generate the function comment for the given function body in a markdown code block with the correct language syntax.
 */
const Project = () => {
  // State for toggling project component details.
  const [toggleProjectDetails, setToggleProjectDetails] = useState(initialToggleProjectDetails);

  // State for storing project details from API call to show in table.
  const [projectDetails, setProjectDetails] = useState([]);

  // State for getting form values.
  const [projectFormDetails, setProjectFormDetails] = useState(projectInitialDetails);

  //State to store id for deleting an element
  const [deleteId, setDeleteId] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);

  const { setPageSizeHandler, onPageChange } = usePagination(false, totalRecords);
  const { pageNo, pageSize } = useSelector(state => state.pagination);

  // Fetching all project details form server
  useEffect(() => {
    getAllProjectDetails();
  }, [toggleProjectDetails.isChange, pageNo, pageSize]);

  // Function to asynchronously retrieve all project details.
  const getAllProjectDetails = async () => {
    const response = await getProjects(pageNo, pageSize);
    setProjectDetails(response.data);
    setTotalRecords(response?.totalRecords);
  };

  // Function to handle the toggle status of adding a project.
  const handleToggleAddProject = () => {
    setToggleProjectDetails(prevTPD => {
      return {
        ...prevTPD,
        toggleProjectModal: !prevTPD.toggleProjectModal,
      };
    });
  };

  // Function for resetting states, Disabled button, opening and closing the modal component
  const resetStates = () => {
    setToggleProjectDetails(prevTPD => {
      return {
        ...prevTPD,
        toggleProjectModal: false,
        isChange: !prevTPD.isChange,
        isDisabled: true,
        isUpdatingProjectDetails: false,
        isDeleteProjectDetails: false,
      };
    });
    setProjectFormDetails(projectInitialDetails);
  };

  // Function for getting, storing form values or changing form values
  const onChangeProjectDetails = event => {
    const { name, value } = event.target;
    setProjectFormDetails(prevPFD => {
      return {
        ...prevPFD,
        [name]: value,
      };
    });

    setToggleProjectDetails(prevTRD => {
      return {
        ...prevTRD,
        isDisabled: !value.trim(),
      };
    });
  };

  // projectAssociations section
  //debounce function for handling the Clients API call
  const debouncedPromiseOptionsClient = debouncedPromiseOptions((inputValue, callback) => {
    if (inputValue) {
      getBusinesses(DEFAULT_PAGE_NO, DEFAULT_PAGE_SIZE, isClient, inputValue).then(response => {
        let structuredData = response?.data.map(client => {
          return {
            label: client.name,
            clientId: client.id,
            value: client.id,
          };
        });
        callback(structuredData);
      });
    } else {
      callback([]);
    }
  });

  //debounce function for handling the projects head, projects manager and projects user API call
  const handleDebouncedPromiseOptionsProject = function (inputValue, callback, projectRole) {
    timeout && clearTimeout(timeout);

    timeout = setTimeout(() => {
      if (inputValue && inputValue.length > 2) {
        GetUserByParam(inputValue).then(response => {
          let structuredData = response?.data.map(user => {
            return {
              label: `${user.firstName} ${user.lastName}`,
              value: user.id,
              employeeId: user.id,
              projectRole: projectRole,
            };
          });
          callback(structuredData);
        });
      } else {
        callback([]);
      }
    }, 300);
  };

  //Onchange function for handling the clients user input field from react select.
  const handleClientChange = selectedClient => {
    setProjectFormDetails(prevPFD => {
      return {
        ...prevPFD,
        businessId: selectedClient,
      };
    });
    setToggleProjectDetails(prevTRD => {
      return {
        ...prevTRD,
        isDisabled: !selectedClient,
      };
    });
  };

  //Onchange function for handling the projectsHead user input field from react select.
  const handleProjectHeadChange = selectedProjectHead => {
    setProjectFormDetails(prevPFD => {
      return {
        ...prevPFD,
        projectHead: selectedProjectHead,
      };
    });
    setToggleProjectDetails(prevTRD => {
      return {
        ...prevTRD,
        isDisabled: !selectedProjectHead,
      };
    });
  };

  //Onchange function for handling the projects Manager user input field from react select.
  const handleProjectManagerChange = selectedProjectManager => {
    setProjectFormDetails(prevPFD => {
      return {
        ...prevPFD,
        projectManager: selectedProjectManager,
      };
    });
    setToggleProjectDetails(prevTRD => {
      return {
        ...prevTRD,
        isDisabled: !selectedProjectManager,
      };
    });
  };

  //Onchange function for handling the projects user input field from react select.
  const handleAssignUserChange = selectedProjectsUser => {
    setProjectFormDetails(prevPFD => {
      return {
        ...prevPFD,
        assignees: selectedProjectsUser,
      };
    });
    setToggleProjectDetails(prevTRD => {
      return {
        ...prevTRD,
        isDisabled: !selectedProjectsUser,
      };
    });
  };

  // Function for Adding or creating new projects
  const onSubmitProjectDetails = async () => {
    if (projectFormDetails.name.trim() === "") {
      return showToast("error", PROJECT_MESSAGES.MANDATORY_FIELDS);
    }

    //Re-structuring project associations to send to API
    let associationsArray = [];
    if ((projectFormDetails?.assignees).length > 0) {
      associationsArray = associationsArray.concat(projectFormDetails.assignees);
    }

    if (Object.keys(projectFormDetails?.projectHead).length !== 0) {
      associationsArray.push(projectFormDetails?.projectHead);
    }

    if (Object.keys(projectFormDetails?.projectManager).length !== 0) {
      associationsArray.push(projectFormDetails?.projectManager);
    }

    //Re-structuring project details to send it to API
    const structuredProjectDetails = {
      businessId: projectFormDetails?.businessId?.clientId,
      name: projectFormDetails?.name,
      description: projectFormDetails?.description,
      projectCost: +projectFormDetails?.projectCost,
      projectAssociations: associationsArray,
    };

    const response = await addProject(structuredProjectDetails);
    if (response.data.statusCode === STATUS_CODES.SUCCESS) {
      showToast("success", PROJECT_MESSAGES.SUCCESS);
      resetStates();
    } else {
      showToast("error", PROJECT_MESSAGES.SUCCESS_FAILED);
    }
  };

  // Function for getting project details by id or function for getting individual projects.
  const handleClickUpdateProject = async projectId => {
    setToggleProjectDetails(prevTPD => {
      return {
        ...prevTPD,
        toggleProjectModal: !prevTPD.toggleProjectModal,
        isUpdatingProjectDetails: true,
      };
    });
    const response = await getProjectsById(projectId);

    const { id, businessId, name, description, projectCost, isActive, projectAssociations } =
      response?.data;

    //Extracting project head object from projectAssociations
    let projectHead = projectAssociations
      ?.filter(item => item.projectRole === ProjectRole.PROJECT_HEAD)
      .map(item => {
        return {
          ...item,
          label: `${item?.employee?.firstName} ${item?.employee?.lastName}`,
          value: item?.employeeId,
        };
      });

    //Extracting project manager object from projectAssociations
    let projectManager = projectAssociations
      ?.filter(item => item.projectRole === ProjectRole.PROJECT_MANAGER)
      .map(item => {
        return {
          ...item,
          label: `${item?.employee?.firstName} ${item?.employee?.lastName}`,
          value: item?.employeeId,
        };
      });

    //Extracting assigness object from projectAssociations
    let assignees = projectAssociations
      ?.filter(item => item.projectRole === ProjectRole.PROJECT_USER)
      .map(item => {
        return {
          ...item,
          label: `${item?.employee?.firstName} ${item?.employee?.lastName}`,
          value: item?.employeeId,
        };
      });

    //TODO: No need to make an API call here, instead backend will return client details, Will be updated later.
    //Extracting client object from business API
    let clientDetails = {};
    if (businessId) {
      let clientsResponse = await getBusinessById(businessId);
      clientDetails = {
        ...clientsResponse?.data,
        label: clientsResponse?.data?.name,
        value: clientsResponse?.data?.id,
      };
    }

    //Storing project details in projectFormDetails
    if (response?.statusCode === STATUS_CODES.SUCCESS) {
      setProjectFormDetails(prevPFD => {
        return {
          ...prevPFD,
          id: id,
          businessId: clientDetails,
          name: name,
          description: description,
          projectCost: projectCost,
          isActive: isActive,
          projectHead: projectHead[0],
          projectManager: projectManager[0],
          assignees: assignees,
        };
      });
    }
  };

  // Function for updating or editing existing projects.
  const onSubmitUpdateProjectDetails = async () => {
    if (projectFormDetails.name.trim() === "") {
      return showToast("error", PROJECT_MESSAGES.MANDATORY_FIELDS);
    }

    //Re-structuring project associations to send to API
    let associationsArray = [];
    if ((projectFormDetails?.assignees).length > 0) {
      associationsArray = associationsArray.concat(projectFormDetails.assignees);
    }

    if (projectFormDetails?.projectHead) {
      if (Object.keys(projectFormDetails?.projectHead).length !== 0) {
        associationsArray.push(projectFormDetails?.projectHead);
      }
    }

    if (projectFormDetails?.projectManager) {
      if (Object.keys(projectFormDetails?.projectManager).length !== 0) {
        associationsArray.push(projectFormDetails?.projectManager);
      }
    }

    //Re-structuring projects details to send to API
    const structuredProjectDetails = {
      id: projectFormDetails?.id,
      businessId: projectFormDetails?.businessId?.clientId
        ? projectFormDetails?.businessId?.clientId
        : projectFormDetails?.businessId?.id,
      name: projectFormDetails?.name,
      description: projectFormDetails?.description,
      projectCost: +projectFormDetails?.projectCost,
      projectAssociations: associationsArray,
    };

    const response = await updateProject(structuredProjectDetails);
    if (response?.data?.statusCode === STATUS_CODES.SUCCESS) {
      showToast("success", PROJECT_MESSAGES.UPDATE);
      resetStates();
    } else {
      showToast("error", PROJECT_MESSAGES.UPDATE_FAILED);
    }
  };

  // Function for deleting or removing projects from list of project.
  const handleClickDeleteProject = id => {
    setToggleProjectDetails(prevTRD => {
      return {
        ...prevTRD,
        isDeleteProjectDetails: true,
      };
    });
    setDeleteId(prevDI => {
      return id;
    });
  };

  return (
    <>
      <PageSection
        heading="Projects"
        showIcon={true}
        icon={<AiOutlineFundProjectionScreen size={"2rem"} />}
        button={
          <Button onClick={handleToggleAddProject} className={`${btnPrimary()}`}>
            <GoPlus size={"1rem"} />
            &nbsp;&nbsp;Add Project
          </Button>
        }>
        <ProjectsList
          projectDetails={projectDetails}
          handleClickDeleteProject={handleClickDeleteProject}
          handleClickUpdateProject={handleClickUpdateProject}
        />

        <PaginationComponent
          onChange={setPageSizeHandler}
          onPageChange={onPageChange}
          showIcons={true}
          totalRecords={totalRecords}
        />
      </PageSection>

      <AddProject
        toggleProjectDetails={toggleProjectDetails}
        resetStates={resetStates}
        projectFormDetails={projectFormDetails}
        onChangeProjectDetails={onChangeProjectDetails}
        onSubmitUpdateProjectDetails={onSubmitUpdateProjectDetails}
        onSubmitProjectDetails={onSubmitProjectDetails}
        setProjectFormDetails={setProjectFormDetails}
        debouncedPromiseOptionsClient={debouncedPromiseOptionsClient}
        handleAssignUserChange={handleAssignUserChange}
        handleClientChange={handleClientChange}
        handleProjectHeadChange={handleProjectHeadChange}
        handleProjectManagerChange={handleProjectManagerChange}
        handleDebouncedPromiseOptionsProject={handleDebouncedPromiseOptionsProject}
      />
      <DeleteProject
        toggleProjectDetails={toggleProjectDetails}
        resetState={resetStates}
        deleteId={deleteId}
      />
    </>
  );
};

export default Project;
