import { VIEW_DETAILS_TABS_ENUM } from "../../../constants/Constant";
import { btnPrimary } from "../../../utils/helper";

/**
 * Renders a button component for adding user details.
 *
 * @param {function} onClick - The callback function to be executed when the button is clicked.
 * @param {string} activeTab - The active tab of the user details view.
 * @return {JSX.Element} The rendered button component.
 */
const ViewUserDetailsAddBtn = ({ onClick, activeTab }) => {
  return (
    <button
      onClick={onClick}
      className={`px-4 py-2 mb-3 rounded-md text-white font-semibold tracking-wide ${btnPrimary()}`}>
      {activeTab === VIEW_DETAILS_TABS_ENUM.skill ? "Add Skill" : "Add Document"}
    </button>
  );
};

export default ViewUserDetailsAddBtn;
