import { Outlet } from "react-router";
import RequiredDetailsHeader from "../../components/Layout/Header/RequiredDetailsHeader";

const RequiredDetailsRoot = () => {
  return (
    <>
      <RequiredDetailsHeader />
      <Outlet />
    </>
  );
};

export default RequiredDetailsRoot;
