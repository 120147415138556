import React, { useContext, useEffect, useState } from "react";
import { Avatar, Button } from "flowbite-react";
import { useStore } from "../../../context/createFastContext";
import { Link } from "react-router-dom";
import { MdOutlineTimer } from "react-icons/md";
import {
  getAttendances,
  startTimer,
  stopTimer,
  timeLogsActive,
} from "../../../services/AttendanceService";
import { jobsAssign } from "../../../services/JobService";
import { AuthContext } from "../../../context/AuthContext";
import { format, parse } from "date-fns";
import { showToast } from "../../../components/common/Toast";
import { PROFILE_MESSAGES } from "../../../constants/Messages";
import { cn } from "../../../utils/common";
import { userPreferences } from "../../../services/UserService";

/**
 * The Profile component renders the user's profile information and allows them to check in and out of jobs.
 *
 * @return {JSX.Element} The JSX for the Profile component
 */

const Profile = () => {
  const [selectedOption, setSelectedOption] = useState("");

  const [initialContextState, _] = useStore(s => s);

  const [currentTime, setCurrentTime] = useState([]);

  const [jobList, setJobList] = useState([]);

  const [isCheckedIn, setIsCheckedIn] = useState(false);

  const [timer, setTimer] = useState("00:00:00");

  const [formData, setFormData] = useState({
    jobId: 0,
  });

  const [selectedJobId, setSelectedJobId] = useState([]);

  const [previousJobs, setPreviousJobs] = useState(null);

  const { user } = useContext(AuthContext);

  const employeeId = user.id;

  const userInfoById = initialContextState.userInfoById;

  const currentDate = new Date();

  // Format current date
  const formattedCurrentDate = format(currentDate, "yyyy-MM-dd");

  let fromDate = formattedCurrentDate;

  let toDate = formattedCurrentDate;

  // Handle form data
  useEffect(() => {
    setFormData(prev => ({
      ...prev,
      jobId: selectedJobId,
    }));
  }, [selectedJobId]);

  // Update timer
  useEffect(() => {
    let interval;
    if (isCheckedIn) {
      // Update timer every second
      interval = setInterval(() => {
        // Increment timer by one second
        setTimer(prevTimer => {
          const [hours, minutes, seconds] = prevTimer.split(":").map(parseFloat);
          const timeElapsedInSeconds = hours * 3600 + minutes * 60 + seconds + 1;
          const newHours = Math.floor(timeElapsedInSeconds / 3600);
          const newMinutes = Math.floor((timeElapsedInSeconds % 3600) / 60);
          const newSeconds = timeElapsedInSeconds % 60;
          return `${String(newHours).padStart(2, "0")}:${String(newMinutes).padStart(
            2,
            "0"
          )}:${String(newSeconds).padStart(2, "0")}`;
        });
      }, 1000);
    } else {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [isCheckedIn]);

  useEffect(() => {
    fetchAttendance();
    JobFetch();
    fetchActiveTimeLog();
    lastJobsSelected();
  }, []);

  const userInfo = {
    placeholderInitials: `${user?.firstName && user?.firstName[0].toUpperCase()}${
      user?.lastName && user?.lastName[0].toUpperCase()
    }`,
    image: userInfoById?.imageBytes && `data:image/jpeg;base64,${userInfoById?.imageBytes}`,
    userName: `${user?.firstName} ${user?.lastName}`,
    userEmail: `${user?.email}`,
    userDesignation: `${user?.designaton || ""}`,
  };

  // Handle job selection
  const handleOptionChange = event => {
    const selectedIndex = event.target.selectedIndex - 1;
    const selectedJobId = jobList[selectedIndex]?.id;
    setSelectedOption(event.target.value);
    setSelectedJobId(selectedJobId);
  };

  // Get job list
  const JobFetch = () => {
    jobsAssign().then(response => {
      setJobList(response.data.data);
    });
  };

  // Get attendance data
  const fetchAttendance = async () => {
    await getAttendances(fromDate, toDate, employeeId).then(response => {
      setCurrentTime(response?.data);
    });
  };

  // Get active time log which I checked into before.
  const fetchActiveTimeLog = async () => {
    await timeLogsActive().then(response => {
      var activeLog = response.data;
      if (activeLog != null) {
        formData.jobId = activeLog.jobId;
        setTimer(activeLog.timeElapsed.split(".")[0]);
        setIsCheckedIn(true);
      } else {
        setTimer("00:00:00");
        setIsCheckedIn(false);
      }
    });
  };

  // Start timer
  const checkInHandle = async event => {
    event.preventDefault();

     if (!formData.jobId || formData.jobId == 0) {
       formData.jobId = previousJobs;
     }

    if (!formData.jobId || formData.jobId == 0) {
      showToast("error", PROFILE_MESSAGES.SELECT_JOB);
      return;
    }

    await startTimer(formData);
    setIsCheckedIn(true);
    showToast("success", PROFILE_MESSAGES.CHECK_IN);
  };

  // Stop timer
  const checkOutHandle = async event => {
    event.preventDefault();

    var res = await stopTimer();

    if (!res) return;
    
    setTimer("00:00:00");
    setIsCheckedIn(false);
    showToast("success", PROFILE_MESSAGES.CHECK_OUT);
    fetchAttendance();
  };

  const lastJobsSelected = () => {
    userPreferences().then(response => {
      setPreviousJobs(response.lastSelectedJobId);
    });
  };


  return (
    <div>
      <div className="w-full h-96 bg-white rounded-lg shadow m-0">
        <div className="flex flex-col items-center pb-0 pt-6">
          <Link
            data-home-avatar="home-avatar"
            to={`/profile?userId=${user?.id}`}
            className="flex items-center gap-2">
            <Avatar
              rounded={true}
              placeholderInitials={userInfo.placeholderInitials}
              className="tc-home-avatar mx-2 z-auto cursor-pointer"
              img={userInfo.image}
            />
          </Link>
          <h5 className="mb-1 text-xl font-medium text-gray-900 dark:text-white">
            {userInfo.userName}
          </h5>
          <span className="text-sm text-gray-500 dark:text-gray-400">{userInfo.userEmail}</span>
          <span className="text-sm text-gray-500 dark:text-gray-400">
            {userInfo.userDesignation}
          </span>
        </div>

        <div className="mt-2">
          <div className="border-l-4 rounded-sm primary-border pl-1">Your today's timing</div>
          <div></div>
          <div className="m-2 mt-2 pl-2 h-24 flex items-center border-2 rounded-lg">
            <div className="flex-1 flex items-center justify-center border-r-2 border-gray-200">
              <div className="text-center">
                <span className="font-semibold text-green-400 block">Total Hours</span>
                {!currentTime && "00:00:00"}
                {currentTime &&
                  currentTime.map((attendance, index) => (
                    <span key={index}>
                      {!attendance?.totalHours && "00:00:00"}
                      {attendance?.totalHours
                        ? format(
                            parse(attendance?.totalHours?.split(".")[0], "HH:mm:ss", new Date()),
                            "HH:mm:ss"
                          )
                        : null}
                    </span>
                  ))}
              </div>
            </div>

            <div className="flex-1 flex items-center justify-center pl-4">
              <div className="text-center">
                <span className="text-thynkwebPrimary-800 font-semibold block">Current Time</span>
                <span>{timer}</span>
              </div>
            </div>
          </div>
        </div>
        <form onSubmit={isCheckedIn ? checkOutHandle : checkInHandle}>
          <div className="flex justify-center items-center">
            <div className=" pl-2 pr-2">
              <select
                disabled={isCheckedIn}
                className="block pl-3 bg-white border border-gray-300 text-gray-700 py-3 px-1  pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500 text-sm"
                value={
                  selectedOption.length === 0
                    ? currentTime &&
                      currentTime.length > 0 &&
                      currentTime[0].timeLogs &&
                      currentTime[0].timeLogs.length > 0 &&
                      currentTime[0].timeLogs[currentTime[0].timeLogs.length - 1].job ?
                      currentTime[0].timeLogs[currentTime[0].timeLogs.length - 1].job.name
                      : ""
                    : selectedOption
                }
                onChange={handleOptionChange}>
                <option value="0" disabled>
                  Select a Job
                </option>
                {jobList.map(job => (
                  <option key={job.id} value={job.name}>
                    {job.name}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <Button
                type="submit"
                className={`tc-btn-dark`}
                style={{
                  backgroundColor: cn({ "#EF4444": isCheckedIn, "#10B981": !isCheckedIn }),
                }}>
                <MdOutlineTimer size={"1.2rem"} />{" "}
                <span className="pl-2">{isCheckedIn ? " Check Out" : " Check In"}</span>
              </Button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Profile;
