import { useMemo, forwardRef, useState, useContext, useEffect } from "react";
import { Avatar, Button } from "flowbite-react";
import { Link } from "react-router-dom";
import { Logout } from "../../../pages/Logout";
import { Role } from "../../../enum/Role";
import { RxCross2 } from "react-icons/rx";
import SidebarItems from "./SidebarItems";
import { SIDEBAR__ADMIN__LINKS, SIDEBAR__USER__LINKS } from "./helper.js";
import styles from "./Sidebar.module.css";
import OrgChange from "./OrgChange/OrgChange.jsx";

const logoutBtnClasses =
  "!flex !justify-center items-center text-[0.8rem] lg:text-base p-2 py-3 mx-6 mt-[auto] hover:bg-[#bfdbfe] focus:bg-[#bfdbfe] cursor-pointer ";

const Sidebar = forwardRef(function Sidebar({ user, userInfoById, toggleSidebar }, ref) {

  const [changeOrgVisible, setChangeOrgVisible] = useState(false);


  useEffect(() => {
    // If the user is part of only 1 organization and the user is a member, then don't show Org Change/Add org dropdown.
    if (user?.tenants.length === 1) {
      if (user?.tenants[0].userRole === Role.Member) {
        setChangeOrgVisible(false);
      }
      else {
        setChangeOrgVisible(true);
      }
    }
    else {
      setChangeOrgVisible(true);
    }
  }, []);

  return (
    <aside
      ref={ref}
      data-sidebar="sidebar"
      className={`${toggleSidebar && styles["sidebar--active"]} ${styles["sidebar"]}`}>
      <div className="mr-4 ml-4 py-5 mt-0 items-center flex relative border-b border-b-1">
        <Link
          data-sidebar="sidebar-item"
          to={`/profile?userId=${user?.id}`}
          className="flex items-center gap-2">
          <Avatar
            rounded={true}
            placeholderInitials={`${user?.firstName && user?.firstName[0].toUpperCase()}${user?.lastName && user?.lastName[0].toUpperCase()
              }`}
            className=" mx-2 z-auto cursor-pointer"
            img={userInfoById?.imageBytes && `data:image/jpeg;base64,${userInfoById?.imageBytes}`}
          />

          <div className="flex flex-col gap-0">
            <p className="!p-0 !m-0 leading-[1.2] text-sm font-semibold">
              {user?.firstName} {user?.lastName}
            </p>
            <span className="!p-0 leading-[.9] text-gray-500 text-xs mt-1">
              {user?.email}
            </span>
          </div>
        </Link>

        <div
          data-sidebar="closebtn"
          className="bg-gray-200 self-start transition-colors duration-300 hover:bg-gray-300 focus:bg-gray-300 p-1 rounded cursor-pointer w-[fit-content] ml-[auto]">
          <RxCross2 />
        </div>
      </div>

      <div className="mx-4 px-2 py-4 border-b border-b-1">
        {user?.roleId === Role.Administrator && (
          <SidebarItems
            items={useMemo(() => SIDEBAR__ADMIN__LINKS(user), [])}
            isActiveClass={styles["sidebaritems--active"]}
            className={styles.sidebaritems}
          />
        )}

        {user?.roleId === Role.Moderator && (
          <SidebarItems
            items={useMemo(() => SIDEBAR__ADMIN__LINKS(user), [])}
            isActiveClass={styles["sidebaritems--active"]}
            className={styles.sidebaritems}
          />
        )}

        {user?.roleId !== Role.Administrator && user?.roleId !== Role.Moderator && (
          <SidebarItems
            items={useMemo(() => SIDEBAR__USER__LINKS(user), [])}
            isActiveClass={styles["sidebaritems--active"]}
            className={styles.sidebaritems}
          />
        )}
      </div>

      {changeOrgVisible && <div className="mx-4 px-2 pb-4 overflow-y-auto border-b border-b-1">
        <OrgChange />
      </div>}

      <div className="mx-4 px-2 py-4">
        <Logout className={`!text-red-800 pl-3 py-2`} />
      </div>
    </aside>
  );
});

export default Sidebar;
