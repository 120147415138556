import { useState } from "react";
import { RxCross2 } from "react-icons/rx";

import {
  addCustomField,
  deleteCustomFields,
  updateCustomFields,
} from "../../../../services/InvoiceService";
import { InvoiceCustomField } from "../../../../enum/InvoiceCustomField";

let isInputChange = false;

const TCList = ({ setFormValues, TC }) => {
  //to prevent sending unnecessary fetch request, only send if there's a change
  const [disabledInputs, setDisabledInputs] = useState(false);

  const onChangeInputHandler = (newValue, index) => {
    //only set to true if there's a change
    isInputChange = true;

    setFormValues(prevFV => {
      //get the current field
      const currentField = prevFV.TAndC[index];
      //update the current field
      const updatedField = {
        ...currentField,
        fieldName: newValue,
      };
      //resign the current field
      prevFV.TAndC[index] = updatedField;
      return { ...prevFV, TAndC: prevFV.TAndC };
    });
  };

  const onBlurInputHandler = async (field, index) => {
    //only send the request if there's a change
    if (field.fieldName.trim() === "" || !isInputChange) return;

    //disable the inputs
    setDisabledInputs(true);

    //checking for tenantId because, new field created by user does not have tenantId
    //until the newly created field send to the server on blur event
    if ("id" in field && "tenantId" in field) {
      //remove the loading spinner from the fetch request for updating T&C
      await updateCustomFields({ ...field, fieldName: field.fieldName });
    } else {
      const res = await addCustomField({
        fieldName: field.fieldName,
        groupId: InvoiceCustomField.TermsAndConditions,
      });

      //replaced the current field with the new one from the server
      setFormValues(prevFV => {
        prevFV.TAndC[index] = res.data;
        return { ...prevFV, TAndC: prevFV.TAndC };
      });
    }
    setDisabledInputs(false);
    isInputChange = false;
  };

  const onClickDeleteHandler = async (id, field) => {
    //If editing invoice pass the field id and invoiceId
    if (field?.invoiceId) await deleteCustomFields(id, field?.invoiceId);
    //only send backend request if tenantId and field id exist in obj
    if (field?.id && field?.tenantId) await deleteCustomFields(id);

    setFormValues(prevFV => {
      const updatedTAndC = prevFV.TAndC.filter(field => +id !== field.id);
      return { ...prevFV, TAndC: updatedTAndC };
    });
  };

  return (
    <>
      {TC.map((field, index) => (
        <li className="px-3" key={field.id}>
          <div className="flex items-center bg-transparent">
            <input
              type="text"
              key={field.id}
              id={field.id}
              name="T&C"
              value={field.fieldName || ""}
              disabled={disabledInputs}
              onChange={e => onChangeInputHandler(e.target.value, index)}
              onBlur={e => onBlurInputHandler(field, index)}
              className={`${
                disabledInputs ? "cursor-not-allowed text-gray-600" : "text-gray-900 cursor-auto"
              } border-b-gray-400 bg-transparent focus:outline-none focus:ring-0 cursor-text border-t-[0] border-l-[0] border-r-[0] focus:border-b-gray-900 hover:border-b-gray-900 w-full pl-0 text-[0.9rem] sm:text-[1rem] leading-[0.5rem]`}
            />

            <button
              disabled={disabledInputs}
              onClick={() => onClickDeleteHandler(field.id, field)}
              className="pt-[0.6rem] w-fit ml-2">
              <RxCross2 size={23} color="#cdc733" className="cursor-pointer" />
            </button>
          </div>
        </li>
      ))}
    </>
  );
};

export default TCList;
