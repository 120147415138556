import { Select } from "flowbite-react";

import Options from "../../Settings/controlPanelSettings/Options";
import { states } from "../helper";

const StateSelect = ({ className, onChange, moreOptions, children, ...otherProps }) => {
  return (
    <Select
      className={className}
      onChange={onChange}
      {...otherProps}>
      <option
        value="Select State / Province"
        hidden={true}>
        Select State / Province
      </option>
      <Options
        capFirstLetter={true}
        optionsArr={states}
      />
    </Select>
  );
};
export default StateSelect;
