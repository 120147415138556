import React, { useEffect, useState, memo, useRef, forwardRef, useContext, useMemo } from "react";
import { Button, Pagination } from "flowbite-react";
import { AuthContext } from "../../../context/AuthContext";
import { DEFAULT_PAGE_NO, DEFAULT_PAGE_SIZE, STATUS_CODES } from "../../../constants/Constant";
import { useStore } from "../../../context/createFastContext";
import ModalComponent from "../../common/Modal/Modal";
import DatePicker from "../../common/DatePicker/DatePicker";
import { Role } from "../../../enum/Role";
import Dialog from "../../common/Dialog/Dialog";
import { GetReportingUsers } from "../../../services/UserService";
import {
  TimestampInfo,
  formattedDate,
  presentCheck,
  convertLocalTimeTo24hourUTC,
  getWeekDates,
  isValue,
  cn,
} from "../../../utils/common";
import {
  AttendanceLeaveStatus,
  AttendanceStatus,
  TimeLogApprovalStatus,
} from "../../../enum/AttendanceStatus";
import { showToast } from "../../common/Toast";
import SelectWithDropdown from "../../common/SelectWithDropdown/SelectWithDropdown";
import SelectRejectAll from "../../common/SelectRejectAll/SelectRejectAll";
import { AiOutlineSafetyCertificate } from "react-icons/ai";
import PageSection from "../../Layout/PageSection/PageSection";
import { BsExclamationOctagon } from "react-icons/bs";
import {
  attendanceStatusUpdate,
  attendancesTimelogs,
  deleteTimeLog,
  getAttendances,
} from "../../../services/AttendanceService";
import {
  Checkbox,
  Checkboxs,
  TimeLogHeading,
  attendancesStatus,
  maintainDate,
  selectOptionsAttendances,
  updateSingleAttendanceStatus,
  validateFields,
} from "../helper";
import TimeLogs from "../TimeLogs/TimeLogs";
import { btnPrimary } from "../../../utils/helper";
import { GoPlus } from "react-icons/go";
import AddAttendanceModalBody from "../../../pages/Attendance Management/AddAttendanceModalBody";

/**
 * This function renders the Approvals component, including the table of time sheets and various UI components for managing approvals and adding new time sheets.
 *
 * @param {string} fromDate - The start date for the time sheet range
 * @param {string} toDate - The end date for the time sheet range
 * @param {string} userId - The ID of the user for whom time sheets are being fetched
 * @param {boolean} isMyAttendance - Flag to indicate whether the time sheets being fetched are for the current user
 * @return {JSX.Element} - The Approvals component UI
 */

const Approvals = () => {
  const { userContext, user } = useContext(AuthContext);
  const [attendancesResponse, setAttendancesResponse] = useState();
  const [attendances, setAttendances] = useState([]);
  const [renderEditAttendance, setRenderEditAttendance] = useState(false);
  const [roleId, setRoleId] = useState();
  // Setting the page no
  const [pageNo, setPageNo] = useState(DEFAULT_PAGE_NO);

  // Setting the page size
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);

  const [expandedRow, setExpandedRow] = useState(null);
  const [rowDate, setRowDate] = useState(null);

  const isAdmin = user.roleId === Role.Administrator;
  const isModerator = user?.roleId === Role.Moderator;

  const [state, setState] = useState({
    userIds: [],
    showUserDropdown: [false, true],
    approvalUserIds: [],
  });
  const checkboxRef = useRef(null);
  const initialRender = useRef(false);
  const [globalHeaderState] = useStore(store => store.headerState);
  const [attendanceState, setStore] = useStore(store => store.attendanceState);
  const singleAttendanceUpdated = useRef(null);
  const refState = useRef({
    isAttendanceAdded: false,
    isAttendanceUpdated: false,
  });
  const [reRenderSelect, setReRenderSelect] = useState(false);
  let isMyAttendance = state.showUserDropdown[0];
  const fromDate = useMemo(
    () =>
      attendanceState.dateRangeInAttendanceList.dateRange[0].startDate.toLocaleDateString("en-US"),
    [attendanceState.dateRangeInAttendanceList.dateRange[0].startDate.toISOString()]
  );

  const toDate = useMemo(
    () =>
      attendanceState.dateRangeInAttendanceList.dateRange[0].endDate.toLocaleDateString("en-US"),
    [attendanceState.dateRangeInAttendanceList.dateRange[0].endDate.toISOString()]
  );

  const fetchTimeSheets = async (fromDate, toDate, userId, isMyAttendance) => {
    const loggedInUser = JSON.parse(sessionStorage.getItem("user"));
    setRoleId(loggedInUser.roleId);
    await getAttendances(fromDate, toDate, userId, isMyAttendance).then(response => {
      setAttendancesResponse(response?.data);
      setAttendances(response?.data);
    });
  };

  // Function to set the page size and page number
  const setPage = e => {
    setPageSize(e.target.value);
    if (Math.ceil(attendancesResponse?.records / pageSize) > 1) {
      setPageNo(DEFAULT_PAGE_NO);
    }
  };

  let selectedUserChange = 0;
  if (attendanceState.selectedUser !== null) {
    selectedUserChange = attendanceState.selectedUser.value;
  }

  useEffect(() => {
    const fromdate = new Date(fromDate);
    const todate = new Date(toDate);

    // Calculate the time difference in milliseconds between the two dates
    const timeDifference = todate - fromdate;

    // Convert milliseconds to days
    const daysDifference = timeDifference / (1000 * 60 * 60 * 24);
    if (daysDifference === 0 && attendanceState.dateRangeInAttendanceList.isDefaultWeekDate) {
      attendanceState.dateRangeInAttendanceList.isDefaultWeekDate = false;
      attendanceState.dateRangeInAttendanceList.weekCount = 0;
    }
    attendanceState.rangeGap = daysDifference; // this will give us the days difference between two dates
  }, [fromDate, toDate]);

  useEffect(() => {
    let userId = "";
    if (attendanceState.selectedUser !== null && state.showUserDropdown[0] === false) {
      userId = attendanceState.selectedUser.value.id;
    }
    if (state.showUserDropdown[1] === true && attendanceState.selectedUser === null) {
      setAttendances([]);
      return;
    }
    fetchTimeSheets(fromDate, toDate, userId, state.showUserDropdown[0]);
  }, [
    fromDate,
    toDate,
    globalHeaderState.isCheckedInCheckedOut,
    singleAttendanceUpdated.current,
    state.showUserDropdown[0],
    selectedUserChange,
    refState.current.isAttendanceAdded,
    refState.current.isAttendanceUpdated,
  ]);

  const dateTime = dateTime => {
    const [datetimeObj, month, date, year] = TimestampInfo(dateTime);

    const dateTimeMonth = (month + 1).toLocaleString("en-US", {
      minimumIntegerDigits: 2,
    });
    const dateTimeDate = date.toLocaleString("en-US", {
      minimumIntegerDigits: 2,
    });
    const dateTimeYear = year;
    return dateTimeMonth + "/" + dateTimeDate + "/" + dateTimeYear;
  };

  const duration = (start, end) => {
    if (end === null) {
      return "00:00";
    }

    const [startHour, startMinute] = start.split(":");
    const [endHour, endMinute] = end.split(":");

    const startDateTime = new Date();
    startDateTime.setHours(startHour, startMinute, 0, 0);

    const endDateTime = new Date();
    endDateTime.setHours(endHour, endMinute, 0, 0);

    let durationMs = endDateTime - startDateTime;

    if (durationMs < 0) {
      durationMs += 24 * 60 * 60 * 1000; // Adding 24 hours to account for the next day
    }

    const durationHours = Math.floor(durationMs / (1000 * 60 * 60));
    const durationMinutes = Math.floor((durationMs % (1000 * 60 * 60)) / (1000 * 60));

    return (
      <>
        <span>
          {`${durationHours.toString().padStart(2, "0")}:${durationMinutes
            .toString()
            .padStart(2, "0")}`}
        </span>
      </>
    );
  };

  const calculateDaysDifference = dateCreated => {
    const startDate = new Date(dateTime(dateCreated));
    const currentDate = new Date();
    const timeDifference = currentDate.getTime() - startDate.getTime();
    const daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
    return daysDifference;
  };

  useEffect(() => {
    if (initialRender.current === false) {
      initialRender.current = true;
      return;
    }
    if (
      attendances.length > 0 &&
      state[state.showUserDropdown[0] === true ? "userIds" : "approvalUserIds"].length > 0 &&
      state[state.showUserDropdown[0] === true ? "userIds" : "approvalUserIds"].length !==
        attendances.length
    ) {
      checkboxRef.current.indeterminate = true;
      checkboxRef.current.checked = false;
    } else if (
      attendances.length > 0 &&
      state[state.showUserDropdown[0] === true ? "userIds" : "approvalUserIds"].length ===
        attendances.length
    ) {
      checkboxRef.current.indeterminate = false;
      checkboxRef.current.checked = true;
    } else if (
      state[state.showUserDropdown[0] === true ? "userIds" : "approvalUserIds"].length === 0
    ) {
      checkboxRef.current.checked = false;
      checkboxRef.current.indeterminate = false;
    }
  }, [state.userIds.length, state.approvalUserIds.length]);

  const handleCheck = e => {
    let key = state.showUserDropdown[0] === true ? "userIds" : "approvalUserIds";

    if (e.target.id === "select-all" && e.target.checked) {
      setState(s => ({
        ...s,
        [key]: attendances
          .filter(user => presentCheck.includes(user.attendanceStatus) === true) // filter absent
          .map(user => user.id),
      }));
      return;
    } else if (e.target.id === "select-all" && !e.target.checked) {
      return setState(s => ({
        ...s,
        [key]: [],
      }));
    }

    if (state.showUserDropdown[0] === true && userContext.user.roleId !== Role.Administrator)
      return;

    let item = JSON.parse(e.target.getAttribute("data-set"));
    let isItemPresentIndex = state[key].findIndex(v => v === item.id);
    if (isItemPresentIndex !== -1) {
      return setState(s => ({
        ...s,
        [key]: s[key].filter(v => v !== item.id),
      }));
    }
    setState(s => ({
      ...s,
      [key]: [...s[key], item.id],
    }));
  };

  const handleSelectChange = (otherOptions, selectedOption) => {
    updateSingleAttendanceStatus(selectedOption, singleAttendanceUpdated, setReRenderSelect);
  };

  const toggleAddAttendanceModal = () => {
    if (attendanceState.addAttendanceModal) {
      attendanceState.add_modal_timer.checkinTime = {
        hours: "10",
        minutes: "00",
        ampm: "AM",
      };

      attendanceState.add_modal_timer.checkoutTime = {
        hours: "07",
        minutes: "00",
        ampm: "PM",
      };
    }
    attendanceState.dateRangeInModalGlobal.dateRange[0] = {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    };
    setStore({
      attendanceState: {
        ...attendanceState,
        addAttendanceModal: !attendanceState.addAttendanceModal,
      },
    });
  };

  const handleAddAttendance = e => {
    e.preventDefault();
    const { startDate, endDate } = attendanceState.dateRangeInModalGlobal.dateRange[0];
    const selectedUserInModal = attendanceState.selectedUserModal;
    if (validateFields(selectedUserInModal, startDate, endDate)) {
      return;
    }

    let checkInhours = attendanceState.add_modal_timer.checkinTime.hours,
      checkInminutes = attendanceState.add_modal_timer.checkinTime.minutes,
      checkInampm = attendanceState.add_modal_timer.checkinTime.ampm,
      checkouthours = attendanceState.add_modal_timer.checkoutTime.hours,
      checkoutminutes = attendanceState.add_modal_timer.checkoutTime.minutes,
      checkoutampm = attendanceState.add_modal_timer.checkoutTime.ampm;

    let UTCcheckintime = convertLocalTimeTo24hourUTC(
      checkInhours + ":" + checkInminutes + ":" + checkInampm
    );

    let UTCcheckouttime = convertLocalTimeTo24hourUTC(
      checkouthours + ":" + checkoutminutes + ":" + checkoutampm
    );

    let startTime = `${UTCcheckintime}:00`;
    let endTime = `${UTCcheckouttime}:00`;

    const body = {
      employeeId: selectedUserInModal.value,
      note: attendanceState.add_modal_notes,
      Date: maintainDate(startDate).split("T")[0],
      endDate: maintainDate(endDate).split("T")[0],
      startTime: startTime,
      endTime: endTime,
      timeLogType: 2,
    };
    attendancesTimelogs(body)
      .then(res => {
        if (res.data.statusCode === STATUS_CODES.BAD_REQUEST) {
          showToast("error", res.data.message);
          return;
        } else if (res.data.statusCode === STATUS_CODES.CONFLICT_ERROR) {
          showToast("error", res.data.message);
          return;
        }
        refState.current.isAttendanceAdded = !refState.current.isAttendanceAdded;
        showToast("success", "Attendance added");
        setStore({
          attendanceState: {
            ...attendanceState,
            addAttendanceModal: false,
          },
        });
        if (isMyAttendance) {
          if (globalHeaderState.isCheckedInCheckedOut === null) {
            globalHeaderState.isCheckedInCheckedOut = true;
          } else {
            globalHeaderState.isCheckedInCheckedOut = !globalHeaderState.isCheckedInCheckedOut;
          }
        }
      })
      .catch(e => {
        console.error(e);
      });
  };

  const handleDateRange = item => {
    setStore({
      attendanceState: {
        ...attendanceState,
        dateRangeInAttendanceList: {
          ...attendanceState.dateRangeInAttendanceList,
          dateRange: [item.selection],
        },
      },
    });
  };

  //onChange handler
  const handleDateRangePicker = () => {
    setStore({
      attendanceState: {
        ...attendanceState,
        dateRangeInAttendanceList: {
          ...attendanceState.dateRangeInAttendanceList,
          dateRange: [...attendanceState.dateRangeInAttendanceList.dateRange],
          isOpen: !attendanceState.dateRangeInAttendanceList.isOpen,
        },
      },
    });
  };

  //Open date picker on focus.
  const onFocusDateRangePickerHandler = () => {
    setStore({
      attendanceState: {
        ...attendanceState,
        dateRangeInAttendanceList: {
          ...attendanceState.dateRangeInAttendanceList,
          dateRange: [...attendanceState.dateRangeInAttendanceList.dateRange],
          isOpen: !attendanceState.dateRangeInAttendanceList.isOpen,
        },
      },
    });
  };

  const onDatePickerClickOutside = () => {
    setStore({
      attendanceState: {
        ...attendanceState,
        dateRangeInAttendanceList: {
          ...attendanceState.dateRangeInAttendanceList,
          dateRange: [...attendanceState.dateRangeInAttendanceList.dateRange],
          isOpen: false,
        },
      },
    });
  };

  const handleDeleteAttendance = item => {
    setStore({
      attendanceState: {
        ...attendanceState,
        dialogState: {
          isOpen: true,
          item: item,
        },
      },
    });
  };

  const closeDialog = () => {
    setStore({
      attendanceState: {
        ...attendanceState,
        dialogState: {
          isOpen: false,
        },
      },
    });
  };

  const onDelete = () => {
    const { item } = attendanceState.dialogState;
    deleteTimeLog(item.id).then(res => {
      showToast("success", "Attendance deleted");
      refState.current.isAttendanceAdded = !refState.current.isAttendanceAdded;
      closeDialog();
      if (isMyAttendance) {
        if (globalHeaderState.isCheckedInCheckedOut === null) {
          globalHeaderState.isCheckedInCheckedOut = true;
        } else {
          globalHeaderState.isCheckedInCheckedOut = !globalHeaderState.isCheckedInCheckedOut;
        }
      }
    });
  };

  const handleBackward = () => {
    if (attendanceState.dateRangeInAttendanceList.isDefaultWeekDate) {
      attendanceState.dateRangeInAttendanceList.weekCount--;
      const { startDate, endDate } = getWeekDates(
        attendanceState.dateRangeInAttendanceList.weekCount
      );
      return setStore({
        attendanceState: {
          ...attendanceState,
          dateRangeInAttendanceList: {
            ...attendanceState.dateRangeInAttendanceList,
            dateRange: [
              {
                startDate: startDate,
                endDate: endDate,
                key: "selection",
              },
            ],
          },
        },
      });
    }
    let startDate = new Date(fromDate);
    startDate.setDate(startDate.getDate() - 1); // decrease one day from fromDate
    let updatedToDate = new Date(startDate);
    startDate.setDate(startDate.getDate() - attendanceState.rangeGap); // decrease rangeGap from fromDate
    let updatedFromDate = new Date(startDate);
    setStore({
      attendanceState: {
        ...attendanceState,
        dateRangeInAttendanceList: {
          ...attendanceState.dateRangeInAttendanceList,
          dateRange: [
            {
              startDate: updatedFromDate,
              endDate: updatedToDate,
              key: "selection",
            },
          ],
        },
      },
    });
  };

  const handleForward = () => {
    if (attendanceState.dateRangeInAttendanceList.isDefaultWeekDate) {
      attendanceState.dateRangeInAttendanceList.weekCount++;
      const { startDate, endDate } = getWeekDates(
        attendanceState.dateRangeInAttendanceList.weekCount
      );
      return setStore({
        attendanceState: {
          ...attendanceState,
          dateRangeInAttendanceList: {
            ...attendanceState.dateRangeInAttendanceList,
            dateRange: [
              {
                startDate: startDate,
                endDate: endDate,
                key: "selection",
              },
            ],
          },
        },
      });
    }
    let endDate = new Date(toDate);
    // Add one day to the endDate
    endDate.setDate(endDate.getDate() + 1);
    let updatedFromDate = new Date(endDate);

    // Add the rangeGap days to the endDate
    endDate.setDate(endDate.getDate() + attendanceState.rangeGap);
    let updatedToDate = new Date(endDate);
    setStore({
      attendanceState: {
        ...attendanceState,
        dateRangeInAttendanceList: {
          ...attendanceState.dateRangeInAttendanceList,
          dateRange: [
            {
              startDate: updatedFromDate,
              endDate: updatedToDate,
              key: "selection",
            },
          ],
        },
      },
    });
  };

  const handleStatus = status => {
    if (
      (state.showUserDropdown[0] === true && state.userIds.length === 0) ||
      (state.showUserDropdown[1] === true && state.approvalUserIds.length === 0)
    ) {
      return;
    }

    let userIds =
      state.showUserDropdown[0] === true
        ? state.userIds.join(",")
        : state.approvalUserIds.join(",");
    attendanceStatusUpdate(userIds, status).then(res => {
      if (res.data.statusCode === 200) {
        getAttendances(fromDate, toDate, "", state.showUserDropdown[0]).then(response => {
          setReRenderSelect(s => !s);
          setAttendancesResponse(response?.data);
          setAttendances(response?.data);
        });
      }
    });
  };

  const handleRowClick = (i, date) => {
    setExpandedRow(expandedRow === i ? null : i);
    setRowDate(date); // Update rowDate using state
  };

  return (
    <>
      <div>
        <PageSection
          heading="My Approvals"
          showIcon={true}
          icon={<AiOutlineSafetyCertificate size={"2rem"} />}
          button={
            <div className="flex space-x-4">
              <div className="pt-7">
                {(isAdmin || isModerator) && (
                  <Button onClick={toggleAddAttendanceModal} className={btnPrimary()}>
                    <GoPlus className="mr-2" size={"1rem"} /> Add Attendance
                  </Button>
                )}
                {/*  */}
                <article className="flex flex-wrap items-end wrap space-x-4 pb-[1rem]">
                  <>
                    <ModalComponent
                      show={attendanceState.addAttendanceModal}
                      onClose={toggleAddAttendanceModal}
                      heading="Add Attendance"
                      headerClassName="modal-header-title mb-4">
                      {attendanceState.addAttendanceModal && (
                        <AddAttendanceModalBody
                          btn_prop={{ txt: "Add", onClick: handleAddAttendance }}
                        />
                      )}
                    </ModalComponent>
                  </>
                </article>
              </div>
              <div>
                <DatePicker
                  options={useMemo(() => {
                    return {
                      type: 0,
                      showRangeBtns: true,
                      _position: "relative",
                    };
                  }, [])}
                  _propsState={attendanceState.dateRangeInAttendanceList}
                  _propsFunc={{
                    // do not memoize this object
                    handleDateRangePicker,
                    handleDateRange,
                    onDatePickerClickOutside,
                    onFocusDateRangePickerHandler,
                    handleForward,
                    handleBackward,
                  }}
                />
              </div>
            </div>
          }>
          <div className="">
            <section className="flex justify-end space-x-4 pr-8">
              {state[[state.showUserDropdown[0] === true ? "userIds" : "approvalUserIds"]].length >
              0 ? (
                <>
                  <div className=" relative mt-2">
                    <SelectRejectAll
                      _class="cursor-pointer flex items-center"
                      textClass="text-[green]"
                      text="Approve all"
                      onClick={() => handleStatus(2)}
                    />
                  </div>
                  <div className=" relative mt-2">
                    <SelectRejectAll
                      _class="cursor-pointer flex items-center"
                      textClass="text-[red]"
                      text="Reject all"
                      onClick={() => handleStatus(3)}
                    />
                  </div>
                </>
              ) : null}
            </section>
            <div className="static!important overflow-hidden  dark:bg-gray-800 sm:rounded-lg relative">
              <div className="overflow-x-auto">
                <table className="w-full text-sm text-left bg-white">
                  <thead className="text-xs uppercase">
                    <tr>
                      <th
                        scope="col"
                        className={`px-6 ${
                          isMyAttendance ? "pl-[16px]" : "pl-[2px]"
                        } py-3 flex gap-[.3rem] items-center whitespace-nowrap`}>
                        <Checkbox
                          id={"select-all"}
                          handleCheck={handleCheck}
                          indeterminate
                          ref={checkboxRef}
                          disabled={
                            state.showUserDropdown[0] === true &&
                            userContext?.user?.roleId !== Role.Administrator
                              ? true
                              : false
                          }
                          classes={isMyAttendance === true ? "hidden" : ""}
                        />
                        Employee
                      </th>
                      <th scope="col" className="px-4 py-3 whitespace-nowrap">
                        Date
                      </th>
                      <th scope="col" className="px-4 py-3 whitespace-nowrap">
                        Status
                      </th>
                      <th scope="col" className="px-4 py-3 whitespace-nowrap">
                        Check IN
                      </th>
                      <th scope="col" className="px-4 py-3 whitespace-nowrap">
                        Check OUT
                      </th>
                      <th scope="col" className="px-4 py-3 whitespace-nowrap">
                        Over Time
                      </th>
                      <th scope="col" className="px-4 py-3 whitespace-nowrap">
                        Total hours
                      </th>
                      <th scope="col" className="px-4 py-3 whitespace-nowrap">
                        Approval Status
                      </th>
                      <th scope="col" className="px-4 py-3 whitespace-nowrap">
                        Actions
                      </th>
                    </tr>
                  </thead>
                  {
                    <tbody
                      className={cn("h-auto", {
                        "h-[8rem]":
                          state.showUserDropdown[1] === true &&
                          attendanceState.selectedUser === null,
                      })}>
                      {state.showUserDropdown[1] === true &&
                        attendanceState.selectedUser === null && (
                          <tr>
                            <td
                              colSpan={9}
                              className="tracking-wide text-center border-0 text-[#6b7280]">
                              <div className="flex items-center justify-center w-full gap-1">
                                <BsExclamationOctagon />{" "}
                                <p>I'm empty, unless you select a user from left menu.</p>
                              </div>
                            </td>
                          </tr>
                        )}
                      {attendances?.map((attendance, i) => {
                        let startTime = attendance?.startTime
                          ? new Date(`2000-01-01T${attendance.startTime}Z`)
                              .toLocaleTimeString("en-US")
                              .split(":")
                          : null;

                        if (startTime) {
                          startTime[2] = startTime[2].split(" ")[1];
                          startTime = startTime[0] + ":" + startTime[1] + " " + startTime[2];
                        }

                        if (attendance?.startTime === "00:00:00") {
                          startTime = "00:00";
                        }

                        let endTime = attendance?.endTime
                          ? new Date(`2000-01-01T${attendance.endTime}Z`)
                              .toLocaleTimeString("en-US")
                              .split(":")
                          : null;
                        if (endTime) {
                          endTime[2] = endTime[2].split(" ")[1];
                          endTime = endTime[0] + ":" + endTime[1] + " " + endTime[2];
                        }

                        if (attendance?.endTime === "00:00:00" || attendance?.endTime === null) {
                          endTime = "00:00";
                        }

                        var attendanceStatus = attendance?.attendanceStatus;

                        // When user is present.
                        if (attendanceStatus == AttendanceStatus.Present) {
                          attendanceStatus = 1; // Present

                          if (attendance?.isHoliday) {
                            attendanceStatus = 7; // Present but Holiday
                          }

                          if (attendance?.isOnLeave) {
                            attendanceStatus = 8; // Present but on leave
                          }

                          if (attendance?.isWeekend) {
                            attendanceStatus = 9; // Present but week off
                          }
                        } else if (
                          attendanceStatus == AttendanceStatus.Absent ||
                          attendanceStatus == AttendanceStatus.Not_Applicable
                        ) {
                          if (attendance?.isOnLeave) {
                            attendanceStatus = 4;
                          }

                          if (attendance?.isWeekend) {
                            attendanceStatus = 5;
                          }

                          if (attendance?.isHoliday) {
                            attendanceStatus = 6;
                          }
                        }

                        const daysDifference = calculateDaysDifference(attendance?.date);

                        let key =
                          state.showUserDropdown[0] === true ? "userIds" : "approvalUserIds";

                        let currentDay = false;
                        if (attendance?.date === new Date().toISOString().slice(0, 10)) {
                          currentDay = true;
                        }
                        return (
                          <React.Fragment key={i}>
                            <tr
                              className={`border-b h-[50px] dark:border-gray-600 hover:bg-gray-100 dark:hover:bg-gray-700 ${
                                currentDay ? "bg-[#fbfaef]" : ""
                              }`}
                              key={i}
                              onClick={() => handleRowClick(i)}>
                              <td
                                className={`px-8 py-2 ${
                                  isMyAttendance ? "pl-[16px]" : "pl-[2px]"
                                }  dark:text-white`}>
                                <div className="flex items-center font-medium text-gray-900 whitespace-nowrap">
                                  <Checkbox
                                    id={attendance?.id}
                                    handleCheck={handleCheck}
                                    data={JSON.stringify(attendance)}
                                    checked={state[key].includes(attendance?.id) ? true : false}
                                    disabled={
                                      !presentCheck.includes(attendance.attendanceStatus) ||
                                      (state.showUserDropdown[0] === true &&
                                        userContext?.user?.roleId !== Role.Administrator)
                                        ? true
                                        : false
                                    }
                                    classes={isMyAttendance === true ? "hidden" : ""}
                                  />
                                  {attendance.user === null
                                    ? ""
                                    : attendance?.firstName + " " + (attendance.lastName ? attendance.lastName : "")}
                                </div>
                              </td>
                              <td className="px-4 py-2 whitespace-nowrap">
                                {formattedDate(attendance?.date)}
                              </td>
                              <td className="px-4 py-2 whitespace-nowrap">
                                {attendance.overallApprovalStatus !== TimeLogApprovalStatus.Rejected && (
                                  <span
                                    className={`${
                                      AttendanceLeaveStatus[attendanceStatus].class
                                    } text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-green-900 dark:text-green-300`}>
                                    {attendance.attendanceStatus === 0 && new Date(attendance.date) > new Date()
                                      ? "-"
                                      : AttendanceLeaveStatus[attendanceStatus].text}
                                  </span>
                                )}
                                {attendance.overallApprovalStatus === TimeLogApprovalStatus.Rejected && (
                                  <span
                                    className={`${
                                      AttendanceLeaveStatus[AttendanceStatus.Absent].class
                                    } text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-green-900 dark:text-green-300`}>
                                    Rejected
                                  </span>
                                )}
                              </td>
                              <td className="px-4 py-2 whitespace-nowrap">{startTime}</td>
                              <td className="px-4 py-2 whitespace-nowrap">{endTime}</td>
                              <td className="px-4 py-2 whitespace-nowrap">
                                {attendance?.overTime === "00:00:00" ? "00:00" : attendance?.overTime}
                              </td>
                              <td className="px-4 py-2 whitespace-nowrap">
                                {duration(attendance?.startTime, attendance?.endTime)}
                              </td>
                              <td
                                onClick={e => {
                                  e.stopPropagation();
                                }}
                                className="px-4 py-2 whitespace-nowrap">
                                <div className="tc-dropdown">
                                  {!presentCheck.includes(attendance?.attendanceStatus) ? (
                                    ""
                                  ) : (
                                    <SelectWithDropdown
                                      roundedDot={true}
                                      backgroundColor={`${
                                        attendance?.overallApprovalStatus ===
                                        TimeLogApprovalStatus.Approved
                                          ? "bg-green-100 w-[150px]"
                                          : attendance?.overallApprovalStatus ===
                                              TimeLogApprovalStatus.Rejected
                                            ? "bg-red-100 w-[150px]"
                                            : attendance?.overallApprovalStatus ===
                                                TimeLogApprovalStatus.Pending
                                              ? "bg-thynkwebPrimary-50 w-[150px]"
                                              : null
                                      }`}
                                      className={`${
                                        attendance?.overallApprovalStatus ===
                                        TimeLogApprovalStatus.Approved
                                          ? "text-green-800 rounded-lg -ml-2 mr-2"
                                          : attendance?.overallApprovalStatus ===
                                              TimeLogApprovalStatus.Rejected
                                            ? "text-red-800 rounded-lg -ml-5 mr-2"
                                            : attendance?.overallApprovalStatus ===
                                                TimeLogApprovalStatus.Pending
                                              ? "text-thynkwebPrimary-700 rounded-lg -ml-5 mr-2"
                                              : null
                                      }`}
                                      dotClassName={`w-3 h-3 mr-2 ml-0.5 ${
                                        attendance?.overallApprovalStatus ===
                                        TimeLogApprovalStatus.Approved
                                          ? "bg-green-500"
                                          : attendance?.overallApprovalStatus ===
                                              TimeLogApprovalStatus.Rejected
                                            ? "bg-red-500"
                                            : attendance?.overallApprovalStatus ===
                                                TimeLogApprovalStatus.Pending
                                              ? "bg-thynkwebPrimary-500"
                                              : "bg-thynkwebPrimary-500"
                                      } border rounded-full`}
                                      size="sm"
                                      options={selectOptionsAttendances(userContext, attendance)}
                                      defaultValue={attendancesStatus(attendance?.overallApprovalStatus)}
                                      handleChange={
                                        userContext?.user?.roleId !== Role.Administrator &&
                                        attendance?.userId === userContext?.user?.id
                                          ? ""
                                          : handleSelectChange
                                      }
                                      otherOptions={attendance?.id}
                                      fetch
                                      singleAttendanceUpdated={singleAttendanceUpdated}
                                      isCurrentUser={
                                        userContext?.user?.roleId !== Role.Administrator &&
                                        attendance?.userId === userContext?.user?.id
                                          ? true
                                          : false
                                      }
                                      reRenderSelect={reRenderSelect}
                                      hideMenu={
                                        userContext?.user?.roleId !== Role.Administrator &&
                                        attendance?.userId === userContext?.user?.id
                                          ? true
                                          : userContext?.user?.roleId === Role.Administrator &&
                                              formattedDate(attendance?.date) ===
                                                formattedDate(new Date())
                                            ? true
                                            : false
                                      }
                                      isDefaultValue
                                    />
                                  )}
                                </div>
                              </td>
                              <td className="px-4 py-2 dark:text-white"></td>
                            </tr>

                            {expandedRow === i &&
                              attendance?.timeLogs != null &&
                              attendance?.timeLogs.length != 0 && (
                                <tr>
                                  <td colSpan={9} className="pl-12 py-2 whitespace-nowrap">
                                    <TimeLogs
                                      expandedRow={expandedRow}
                                      i={i}
                                      user={attendance}
                                      TimeLogHeading={TimeLogHeading}
                                      duration={duration}
                                      handleDeleteAttendance={handleDeleteAttendance}
                                      TimeLogApprovalStatus={TimeLogApprovalStatus}
                                    />
                                  </td>
                                </tr>
                              )}
                          </React.Fragment>
                        );
                      })}
                    </tbody>
                  }
                </table>
              </div>
              {true ? (
                ""
              ) : (
                <nav
                  className="flex flex-col items-start justify-between p-4 space-y-3 md:flex-row md:items-center md:space-y-0"
                  aria-label="Table navigation">
                  <div className="flex items-center space-x-3">
                    {attendancesResponse?.records > 21 ? (
                      <>
                        <label
                          htmlFor="rows"
                          className="text-xs font-normal text-gray-500 dark:text-gray-400">
                          Rows per page
                        </label>
                        <select
                          id="rows"
                          onChange={setPage}
                          value={pageSize}
                          className="text-gray-900 text-sm rounded-lg focus:ring-thynkwebPrimary-500 focus:border-thynkwebPrimary-500 block py-1.5 pl-3.5 pr-6 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-thynkwebPrimary-500 dark:focus:border-thynkwebPrimary-500">
                          <option value={20}>20</option>
                          <option value={50}>50</option>
                          <option value={100}>100</option>
                          <option value={200}>200</option>
                        </select>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                  <div>
                    {Math.ceil(attendancesResponse?.records / pageSize) > 1 ? (
                      <Pagination
                        currentPage={pageNo}
                        onPageChange={pageNumber => setPageNo(pageNumber)}
                        showIcons={true}
                        totalPages={
                          attendancesResponse?.records === undefined
                            ? 1
                            : Math.ceil(attendancesResponse?.records / pageSize)
                        }
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </nav>
              )}
            </div>
          </div>
        </PageSection>
      </div>
      <Dialog
        _propsState={useMemo(() => {
          return {
            isOpen: attendanceState.dialogState.isOpen,
            onClose: closeDialog,
            onClick: onDelete,
          };
        }, [attendanceState.dialogState.isOpen])}
        btn_styles={useMemo(() => {
          return {
            btn_text: "Delete",
          };
        }, [])}
        heading="Delete Attendance"
        body={"Are you sure you want to delete this attendance record?"}
      />
    </>
  );
};

export default Approvals;
