import ModalComponent from "../../components/common/Modal/Modal";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { setOrganizationFields, setSideBarState } from "../../store/slices/sideBarSlice";
import InputFields from "../../components/common/Input/InputFields";
import ModalButtons from "../../components/common/Modal/ModalButtons";
import Select from "react-select";
import { memo, useState, useEffect } from "react";
import { inputFields } from "./helpers";
import { CreateTenant } from "../../services/OrganizationDetailsService";
import { showToast } from "../../components/common/Toast";
import { STATUS_CODES } from "../../constants/Constant";
import { ORGANIZATION_MANAGEMENT } from "../../constants/Messages";
import { getRegionalSettings } from "../../services/CommonService";

const AddOrganisationModal = () => {
  const dispatch = useDispatch();
  const [modalState, setModalState] = useState({
    regionalSettings: [],
    selectedTimezone: "",
  });

  let { regionalSettings, selectedTimezone } = modalState;

  const { toggle, organizationFields } = useSelector(
    s => ({
      toggle: s.sidebar.toggle,
      organizationFields: s.sidebar.organizationFields,
    }),
    shallowEqual
  );

  useEffect(() => {
    getRegionalSettings()
      .then(res => {
        let regionalSettings = res.data.data.map((item, index) => {
          return {
            value: item.ianaId,
            label: item.displayName,
          };
        });
        setModalState(s => ({ ...s, regionalSettings }));
      })
      .catch(e => {
        console.error(e);
      });
  }, []);
  const onClose = () => {
    dispatch(setSideBarState({ toggle: false }));
  };

  const onCancel = () => {
    dispatch(setSideBarState({ toggle: false, organizationFields: {} }));
  };

  const handleSumbit = e => {
    e.preventDefault();
    let { tenantName, pan, tan, address, gstNumber } = organizationFields;
    let bodyData = {
      tenantName: tenantName,
      pan: pan,
      tan: tan,
      address: address,
      gstNumber: gstNumber,
      ianaId: selectedTimezone?.value ? selectedTimezone.value : selectedTimezone,
    };
    CreateTenant(bodyData)
      .then(res => {
        if (res.data.statusCode === STATUS_CODES.SUCCESS) {
          showToast("success", ORGANIZATION_MANAGEMENT.SUCCESS);
          setTimeout(() => {
            dispatch(setSideBarState({ toggle: false, organizationFields: {} }));
          }, 500);
        }
      })
      .catch(e => {
        showToast("error", ORGANIZATION_MANAGEMENT.FAIL);
        console.error(e);
      });
  };

  const handleChange = selectedTimezone => {
    setModalState(s => ({ ...s, selectedTimezone }));
  };

  const handleFields = e => {
    let field = { [e.target.name]: e.target.value };
    dispatch(setOrganizationFields(field));
  };

  return (
    <ModalComponent showFooter={false} show={toggle} onClose={onClose} heading="Add Organization">
      <form
        onSubmit={handleSumbit}
        id="add-organization"
        className="grid grid-cols-2 gap-[2rem] mt-[1rem]">
        {inputFields.map((item, index) => {
          return (
            <InputFields
              key={item.id}
              InputField={item}
              parentClassName="justify-self-center w-[100%]"
              inputClassName="w-[100%]"
              onChange={handleFields}
              value={organizationFields[item.name]}
            />
          );
        })}
        <div className="justify-self-center w-[100%]">
          <p className="block mb-2 text-sm font-medium text-gray-900 dark:text-white cursor-pointer">
            Regional Settings
          </p>
          <Select options={regionalSettings} onChange={handleChange} value={selectedTimezone} />
        </div>
      </form>
      <ModalButtons
        onClickCancel={onCancel}
        actionBtnText="Save"
        cancelBtnText="Cancel"
        actionBtnType="submit"
        form="add-organization"
      />
    </ModalComponent>
  );
};

export default memo(AddOrganisationModal);
