import { axiosInstance as axios } from "../context/AuthContext";

const BASE_URL = process.env.REACT_APP_BASE_URL;
const USER_SKILL_BASE_URL = `${BASE_URL}/Skills/associations`;

/**
 * Retrieves all the user's skills.
 *
 * @param {number} userId - The ID of the user.
 * @return {Promise} A promise that resolves to the user's skills.
 */
export const getAllUserSkills = async function (userId) {
  const res = await axios.get(`${USER_SKILL_BASE_URL}?userId=${userId}`);
  return res.data;
};

/**
 * Adds a user skill to the database.
 *
 * @param {[{ userId: number, skillId: number, lastWorkedOn: DateTime, strength: number }]} data - The skill data to be added.
 * @return {Promise} A promise that resolves to the response data from the server.
 */
export const addUserSkill = async function (data) {
  const res = await axios.post(USER_SKILL_BASE_URL, data);
  return res.data;
};

/**
 * Updates the user's skill with the provided data.
 *
 * @param {{ userId: number, skillId: number, lastWorkedOn: DateTime, strength: number }} data - The data containing the user's skill information.
 * @return {Object} The updated user's skill information.
 */
export const updateUserSkill = async function (data) {
  const res = await axios.put(USER_SKILL_BASE_URL, data);
  return res.data;
};

/**
 * Deletes a user's skill.
 *
 * @param {string} userId - The ID of the user.
 * @param {string} skillId - The ID of the skill to be deleted.
 * @return {Promise<any>} The deleted skill data.
 */
export const deleteUserSkill = async function (userId, skillId) {
  const res = await axios.delete(`${USER_SKILL_BASE_URL}?userId=${userId}&skillId=${skillId}`);
  return res.data;
};
