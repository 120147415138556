import { Button, Select } from "flowbite-react";
import React, { useState } from "react";
import ModalComponent from "../../common/Modal/Modal";
import { btnPrimary } from "../../../utils/helper";
import AddJobInputs from "./AddJobInputs";

/**
 * Renders a form for adding or updating job details.
 *
 * @param {Object} toggleJobDetails - Object containing toggle information for job modal
 * @param {Function} resetStates - Function to reset form states
 * @param {Function} onSubmitUpdateJobDetails - Function to submit updated job details
 * @param {Function} onSubmitJobDetails - Function to submit new job details
 * @param {Function} onChangeJobDetails - Function to handle changes in job details
 * @param {Object} jobFormDetails - Object containing job form details
 * @return {JSX.Element} The modal component for adding or updating job details
 */

const AddJob = ({
  toggleJobDetails,
  resetStates,
  onSubmitUpdateJobDetails,
  onSubmitJobDetails,
  onChangeJobDetails,
  jobFormDetails,
  handleAssigneesChange,
  debouncedPromiseOptionsAssignee,
  setJobFormDetails,
  debouncedProjectPromiseOptions,
  handleProjectChange,
  assignees,
}) => {
  return (
    <>
      <ModalComponent
        show={toggleJobDetails.toggleJobModal}
        size="2xl"
        bodyClassName="gap-4 mt-2"
        heading={toggleJobDetails.isUpdatingJobDetails ? "Edit Job" : "Add Job"}
        showFooter={false}
        onClose={resetStates}>
        <div className=" grid gap-4 mb-4 md:gap-6 md:grid-cols-2 sm:mb-8">
          <AddJobInputs
            handleAssigneesChange={handleAssigneesChange}
            debouncedPromiseOptionsAssignee={debouncedPromiseOptionsAssignee}
            jobFormDetails={jobFormDetails}
            onChangeJobDetails={onChangeJobDetails}
            // formValues={formValues}
            debouncedProjectPromiseOptions={debouncedProjectPromiseOptions}
            handleProjectChange={handleProjectChange}
            assignees={assignees}
          />
        </div>

        <div className="mt-6 flex gap-2 justify-end">
          <div>
            <button
              type="button"
              onClick={
                toggleJobDetails?.isUpdatingJobDetails
                  ? onSubmitUpdateJobDetails
                  : onSubmitJobDetails
              }
              disabled={toggleJobDetails.isDisabled}
              className={`${
                toggleJobDetails.isDisabled
                  ? "bg-gray-300 text-gray-600"
                  : `${btnPrimary()} text-white`
              } !px-4 !py-2 rounded-md tracking-wide font-semibold flex items-center gap-2`}>
              {" "}
              {toggleJobDetails?.isUpdatingJobDetails ? "Update" : "Submit"}
            </button>
          </div>

          <button
            onClick={resetStates}
            type="button"
            className="text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700">
            Cancel
          </button>
        </div>
      </ModalComponent>
    </>
  );
};

export default AddJob;
