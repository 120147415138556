import { useMemo } from "react";
import { Label, TextInput } from "flowbite-react";
import useCountriesAPI from "../../../../../../hooks/useCountriesAPI";

import SelectComponent from "../../../../../Settings/controlPanelSettings/SelectComponent/SelectComponent";

import { states } from "../../../../helper";

/**
 * Renders the address component.
 *
 * @param {{handleAddNewClientData: Function, details: object, activeItem: string}} props
 * @param {function} handleAddNewClientData - The function to handle adding new client data.
 * @param {object} details - The object containing client details.
 * @param {string} activeItem - The active item.
 * @return {JSX.Element} The rendered address component.
 */
const Address = ({ handleAddNewClientData, details, activeItem }) => {
  const { countries } = useCountriesAPI();
  const memoizedCountries = useMemo(() => ["Select Country", ...countries], [countries?.length]);
  const memoizedStates = useMemo(() => ["Select State / Province", ...states], [states?.length]);
  const isActive = activeItem === "address";

  return (
    <>
      {isActive && (
        <div className="grid gap-4 mb-7 md:grid-cols-2 md:auto-rows-min sm:grid-cols-1 pt-4">
          <div>
            <div>
              <Label
                htmlFor="country"
                value="Select Country"
              />
            </div>
            <SelectComponent
              onChangeHandler={handleAddNewClientData}
              optionsArr={memoizedCountries}
              capFirstLetter={true}
              nameAttr="country"
              defaultValue={details.country || ""}
            />
          </div>

          <div>
            <div>
              <Label
                htmlFor="state"
                value="State / Province"
              />
            </div>
            <SelectComponent
              onChangeHandler={handleAddNewClientData}
              optionsArr={memoizedStates}
              defaultValue={details.state || ""}
              capFirstLetter={true}
              nameAttr="state"
            />
          </div>

          <div>
            <div>
              <Label
                htmlFor="city under basic information section"
                value="City"
              />
            </div>
            <TextInput
              id="city under basic information section"
              type="text"
              name="city"
              placeholder="City Name"
              shadow={true}
              value={details.city || ""}
              onChange={handleAddNewClientData}
            />
          </div>

          <div>
            <div>
              <Label
                htmlFor="postal code / zip code under address section"
                value="Postal Code / Zip Code"
              />
            </div>
            <TextInput
              id="postal code / zip code under address section"
              type="number"
              name="postalCode"
              placeholder="Postal Code / Zip Code"
              shadow={true}
              value={details.postalCode || ""}
              onChange={handleAddNewClientData}
            />
          </div>

          <div>
            <div>
              <Label
                htmlFor="street address under address section"
                value="Street Address"
              />
            </div>
            <TextInput
              id="street address under address section"
              type="text"
              name="street"
              placeholder="Street Address"
              shadow={true}
              value={details.street || ""}
              onChange={handleAddNewClientData}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default Address;
