import General from "./Tabs/General";
import ProfileSkillTab from "./Tabs/ProfileSkillTab";
import ResignationTab from "./Tabs/ResignationTab";
/**
 * Renders the Tabs component.
 *
 * @param {Object} user - The user object.
 * @return {ReactElement} The rendered Tabs component.
 */
const Tabs = ({ user }) => {
  return (
    <>
      <General user={user} />
      <ProfileSkillTab user={user} />
      {/* <ResignationTab user={user} /> */}
    </>
  );
};

export default Tabs;
