import React, { useEffect, useState, memo, useRef, forwardRef, useContext, useMemo } from "react";
import { Button, Pagination } from "flowbite-react";
import { AuthContext } from "../../../context/AuthContext";
import { DEFAULT_PAGE_NO, DEFAULT_PAGE_SIZE, STATUS_CODES } from "../../../constants/Constant";
import { useStore } from "../../../context/createFastContext";
import DatePicker from "../../common/DatePicker/DatePicker";
import { Role } from "../../../enum/Role";
import Dialog from "../../common/Dialog/Dialog";
import { TbClock } from "react-icons/tb";
import {
  TimestampInfo,
  formattedDate,
  presentCheck,
  convertLocalTimeTo24hourUTC,
  getWeekDates,
  isValue,
  cn,
} from "../../../utils/common";
import {
  AttendanceLeaveStatus,
  AttendanceStatus,
  TimeLogApprovalStatus,
} from "../../../enum/AttendanceStatus";
import { showToast } from "../../common/Toast";
import SelectWithDropdown from "../../common/SelectWithDropdown/SelectWithDropdown";
import { format } from "date-fns";
import MyAttendanceSetting from "../MyAttendanceSetting/MyAttendanceSetting";
import PageSection from "../../Layout/PageSection/PageSection";
import {
  AttendanceHeading,
  TimeLogHeading,
  attendancesStatus,
  maintainDate,
  selectOptionsAttendances,
  updateSingleAttendanceStatus,
  validateFields,
} from "../helper";
import DeleteSvg from "../../../media/DeleteSvg";
import InputFields from "../../common/Input/InputFields";
import { MdOutlineTimer } from "react-icons/md";
import {
  AttendanceStatusUpdate,
  attendancesTimelogs,
  deleteTimeLog,
  getAttendances,
  startTimer,
  stopTimer,
  timeLogsActive,
} from "../../../services/AttendanceService";
import { useSearchParams } from "react-router-dom";
import TimeLogs from "../TimeLogs/TimeLogs";
import { userPreferences } from "../../../services/UserService";
import { btnPrimary } from "../../../utils/helper";
import ModalComponent from "../../common/Modal/Modal";
import { GoPlus } from "react-icons/go";
import { ATTENDANCE_MESSAGES } from "../../../constants/Messages";
import AddAttendanceModalBody from "../../../pages/Attendance Management/AddAttendanceModalBody";
import GearSvg from "../../../media/GearSvg";
import { useAppDispatch } from "../../../store/store";
import { toggleAttendanceModal } from "../../../store/slices/settingsSlice";

const MyAttendance = () => {
  const userContext = useContext(AuthContext);
  const [attendancesResponse, setAttendancesResponse] = useState();
  const [attendances, setAttendances] = useState([]);
  const [roleId, setRoleId] = useState();

  const [searchParams, _] = useSearchParams();

  const [expandedRow, setExpandedRow] = useState(null);

  const [isCheckedIn, setIsCheckedIn] = useState(false);

  const [timer, setTimer] = useState("00:00:00");
  const [isActive, setIsActive] = useState(false);
  const { user } = useContext(AuthContext);

  const employeeId = user.id;

  const [rowDate, setRowDate] = useState(null);

  const [previousJobs, setPreviousJobs] = useState(null);

  const isAdmin = user.roleId === Role.Administrator;
  const isModerator = user?.roleId === Role.Moderator;

  const handleRowClick = (i, date) => {
    setExpandedRow(expandedRow === i ? null : i);
    setRowDate(date); // Update rowDate using state
  };

  // Setting the page no
  const [pageNo, setPageNo] = useState(DEFAULT_PAGE_NO);

  // Setting the page size
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [state, setState] = useState({
    userIds: [],
    showUserDropdown: [true, false],
    approvalUserIds: [],
  });
  const checkboxRef = useRef(null);
  const initialRender = useRef(false);
  const [globalHeaderState] = useStore(store => store.headerState);
  const [attendanceState, setStore] = useStore(store => store.attendanceState);
  const singleAttendanceUpdated = useRef(null);
  const refState = useRef({
    isAdded: false,
    isAttendanceUpdated: false,
  });
  const [reRenderSelect, setReRenderSelect] = useState(false);
  let isMyAttendance = state.showUserDropdown[0];
  const fromDate = useMemo(
    () =>
      attendanceState.dateRangeInAttendanceList.dateRange[0].startDate.toLocaleDateString("en-US"),
    [attendanceState.dateRangeInAttendanceList.dateRange[0].startDate.toISOString()]
  );

  const toDate = useMemo(
    () =>
      attendanceState.dateRangeInAttendanceList.dateRange[0].endDate.toLocaleDateString("en-US"),
    [attendanceState.dateRangeInAttendanceList.dateRange[0].endDate.toISOString()]
  );

  useEffect(() => {
    let interval;
    if (isCheckedIn) {
      // Update timer every second
      interval = setInterval(() => {
        // Increment timer by one second
        setTimer(prevTimer => {
          const [hours, minutes, seconds] = prevTimer.split(":").map(parseFloat);
          const timeElapsedInSeconds = hours * 3600 + minutes * 60 + seconds + 1;
          const newHours = Math.floor(timeElapsedInSeconds / 3600);
          const newMinutes = Math.floor((timeElapsedInSeconds % 3600) / 60);
          const newSeconds = timeElapsedInSeconds % 60;
          return `${String(newHours).padStart(2, "0")}:${String(newMinutes).padStart(
            2,
            "0"
          )}:${String(newSeconds).padStart(2, "0")}`;
        });
      }, 1000);
    } else {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [isCheckedIn]);

  const fetchAttendanceWithLogs = async (fromDate, toDate, userId) => {
    const loggedInUser = JSON.parse(sessionStorage.getItem("user"));
    setRoleId(loggedInUser.roleId);
    await getAttendances(fromDate, toDate, userId).then(response => {
      setAttendancesResponse(response?.data);
      setAttendances(response?.data);

      attendances?.map((t, i) => {
        if (new Date(t?.date).toLocaleDateString() === new Date().toLocaleDateString().slice(0, 10))
          setExpandedRow(i);
      });
    });
  };

  // Get active time log which I checked into before.
  const fetchActiveTimeLog = async () => {
    await timeLogsActive().then(response => {
      var activeLog = response.data;
      if (activeLog != null) {
        setFormData(prevState => ({
          ...prevState,
          workDescription: activeLog.workDescription,
        }));
        setTimer(activeLog.timeElapsed.split(".")[0]);
        setIsCheckedIn(true);
      } else {
        formData.workDescription = "";
        setTimer("00:00:00");
        setIsCheckedIn(false);
      }
    });
  };

  // Function to set the page size and page number
  const setPage = e => {
    setPageSize(e.target.value);
    if (Math.ceil(attendancesResponse?.records / pageSize) > 1) {
      setPageNo(DEFAULT_PAGE_NO);
    }
  };

  let selectedUserChange = 0;
  if (attendanceState.selectedUser !== null) {
    selectedUserChange = attendanceState.selectedUser.value.id;
  }

  useEffect(() => {
    const fromdate = new Date(fromDate);
    const todate = new Date(toDate);

    // Calculate the time difference in milliseconds between the two dates
    const timeDifference = todate - fromdate;

    // Convert milliseconds to days
    const daysDifference = timeDifference / (1000 * 60 * 60 * 24);
    if (daysDifference === 0 && attendanceState.dateRangeInAttendanceList.isDefaultWeekDate) {
      attendanceState.dateRangeInAttendanceList.isDefaultWeekDate = false;
      attendanceState.dateRangeInAttendanceList.weekCount = 0;
    }
    attendanceState.rangeGap = daysDifference; // this will give us the days difference between two dates
  }, [fromDate, toDate]);

  // On changing anything on the page, reload attendance and timelogs with active checkin.
  useEffect(() => {
    fetchAttendanceWithLogs(fromDate, toDate, employeeId);
    fetchActiveTimeLog();
  }, [
    fromDate,
    toDate,
    globalHeaderState.isCheckedInCheckedOut,
    singleAttendanceUpdated.current,
    refState.current.isAttendanceAdded,
    refState.current.isAttendanceUpdated,
  ]);

  useEffect(() => {
    Promise.all([
      fetchAttendanceWithLogs(fromDate, toDate, employeeId),
      fetchActiveTimeLog(),
      lastJobs(),
    ]);
  }, []);

  const dateTime = dateTime => {
    const [datetimeObj, month, date, year] = TimestampInfo(dateTime);

    const dateTimeMonth = (month + 1).toLocaleString("en-US", {
      minimumIntegerDigits: 2,
    });
    const dateTimeDate = date.toLocaleString("en-US", {
      minimumIntegerDigits: 2,
    });
    const dateTimeYear = year;
    return dateTimeMonth + "/" + dateTimeDate + "/" + dateTimeYear;
  };

  const duration = (start, end) => {
    if (start == null || end === null) {
      return "00:00";
    }

    const [startHour, startMinute] = start.split(":");
    const [endHour, endMinute] = end.split(":");

    const startDateTime = new Date();
    startDateTime.setHours(startHour, startMinute, 0, 0);

    const endDateTime = new Date();
    endDateTime.setHours(endHour, endMinute, 0, 0);

    let durationMs = endDateTime - startDateTime;

    if (durationMs < 0) {
      durationMs += 24 * 60 * 60 * 1000; // Adding 24 hours to account for the next day
    }

    const durationHours = Math.floor(durationMs / (1000 * 60 * 60));
    const durationMinutes = Math.floor((durationMs % (1000 * 60 * 60)) / (1000 * 60));

    return (
      <>
        <span>
          {`${durationHours.toString().padStart(2, "0")}:${durationMinutes
            .toString()
            .padStart(2, "0")}`}
        </span>
      </>
    );
  };

  const calculateDaysDifference = dateCreated => {
    const startDate = new Date(dateTime(dateCreated));
    const currentDate = new Date();
    const timeDifference = currentDate.getTime() - startDate.getTime();
    const daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
    return daysDifference;
  };

  useEffect(() => {
    let interval;
    if (isActive) {
      interval = setInterval(() => {
        setTimer(prevTimer => prevTimer + 1);
      }, 1000);
    } else {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [isActive]);

  const handleSelectChange = (otherOptions, selectedOption) => {
    updateSingleAttendanceStatus(selectedOption, singleAttendanceUpdated, setReRenderSelect);
  };

  const toggleAddAttendanceModal = () => {
    if (attendanceState.addAttendanceModal) {
      attendanceState.add_modal_timer.checkinTime = {
        hours: "10",
        minutes: "00",
        ampm: "am",
      };

      attendanceState.add_modal_timer.checkoutTime = {
        hours: "07",
        minutes: "00",
        ampm: "pm",
      };
    }
    attendanceState.dateRangeInModalGlobal.dateRange[0] = {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    };
    setStore({
      attendanceState: {
        ...attendanceState,
        addAttendanceModal: !attendanceState.addAttendanceModal,
      },
    });
  };

  const handleDateRange = item => {
    setStore({
      attendanceState: {
        ...attendanceState,
        dateRangeInAttendanceList: {
          ...attendanceState.dateRangeInAttendanceList,
          dateRange: [item.selection],
        },
      },
    });
  };

  //onChange handler
  const handleDateRangePicker = () => {
    setStore({
      attendanceState: {
        ...attendanceState,
        dateRangeInAttendanceList: {
          ...attendanceState.dateRangeInAttendanceList,
          dateRange: [...attendanceState.dateRangeInAttendanceList.dateRange],
          isOpen: !attendanceState.dateRangeInAttendanceList.isOpen,
        },
      },
    });
  };

  //Open date picker on focus.
  const onFocusDateRangePickerHandler = () => {
    setStore({
      attendanceState: {
        ...attendanceState,
        dateRangeInAttendanceList: {
          ...attendanceState.dateRangeInAttendanceList,
          dateRange: [...attendanceState.dateRangeInAttendanceList.dateRange],
          isOpen: !attendanceState.dateRangeInAttendanceList.isOpen,
        },
      },
    });
  };

  const onDatePickerClickOutside = () => {
    setStore({
      attendanceState: {
        ...attendanceState,
        dateRangeInAttendanceList: {
          ...attendanceState.dateRangeInAttendanceList,
          dateRange: [...attendanceState.dateRangeInAttendanceList.dateRange],
          isOpen: false,
        },
      },
    });
  };

  const handleDeleteAttendance = item => {
    setStore({
      attendanceState: {
        ...attendanceState,
        dialogState: {
          isOpen: true,
          item: item,
        },
      },
    });
  };

  const closeDialog = () => {
    setStore({
      attendanceState: {
        ...attendanceState,
        dialogState: {
          isOpen: false,
        },
      },
    });
  };

  const onDelete = () => {
    const { item } = attendanceState.dialogState;
    deleteTimeLog(item.id).then(res => {
      showToast("success", ATTENDANCE_MESSAGES.TIMELOG_DELETE);
      refState.current.isAttendanceAdded = !refState.current.isAttendanceAdded;
      closeDialog();
      if (isMyAttendance) {
        if (globalHeaderState.isCheckedInCheckedOut === null) {
          globalHeaderState.isCheckedInCheckedOut = true;
        } else {
          globalHeaderState.isCheckedInCheckedOut = !globalHeaderState.isCheckedInCheckedOut;
        }
      }
    });
  };

  const handleAddAttendance = (e, employeeId) => {
    e.preventDefault();
    const { startDate, endDate } = attendanceState.dateRangeInModalGlobal.dateRange[0];
    const selectedUserInModal = attendanceState.selectedUserModal;
    if (validateFields(employeeId ? employeeId : selectedUserInModal, startDate, endDate)) {
      return;
    }

    let checkInhours = attendanceState.add_modal_timer.checkinTime.hours,
      checkInminutes = attendanceState.add_modal_timer.checkinTime.minutes,
      checkInampm = attendanceState.add_modal_timer.checkinTime.ampm,
      checkouthours = attendanceState.add_modal_timer.checkoutTime.hours,
      checkoutminutes = attendanceState.add_modal_timer.checkoutTime.minutes,
      checkoutampm = attendanceState.add_modal_timer.checkoutTime.ampm;

    let UTCcheckintime = convertLocalTimeTo24hourUTC(
      checkInhours + ":" + checkInminutes + ":" + checkInampm
    );

    let UTCcheckouttime = convertLocalTimeTo24hourUTC(
      checkouthours + ":" + checkoutminutes + ":" + checkoutampm
    );

    let startTime = `${UTCcheckintime}:00`;
    let endTime = `${UTCcheckouttime}:00`;

    const body = {
      employeeId: employeeId ? employeeId : selectedUserInModal.value.id,
      note: attendanceState.add_modal_notes,
      Date: maintainDate(startDate).split("T")[0],
      endDate: maintainDate(endDate).split("T")[0],
      startTime: startTime,
      endTime: endTime,
      timeLogType: 2,
    };
    attendancesTimelogs(body)
      .then(res => {
        if (res.data.statusCode === STATUS_CODES.BAD_REQUEST) {
          showToast("error", res.data.message);
          return;
        } else if (res.data.statusCode === STATUS_CODES.CONFLICT_ERROR) {
          showToast("error", res.data.message);
          return;
        }
        refState.current.isAttendanceAdded = !refState.current.isAttendanceAdded;
        showToast("success", ATTENDANCE_MESSAGES.ATTENDANCE_ADD);
        setStore({
          attendanceState: {
            ...attendanceState,
            addAttendanceModal: false,
          },
        });
        if (isMyAttendance) {
          if (globalHeaderState.isCheckedInCheckedOut === null) {
            globalHeaderState.isCheckedInCheckedOut = true;
          } else {
            globalHeaderState.isCheckedInCheckedOut = !globalHeaderState.isCheckedInCheckedOut;
          }
        }
      })
      .catch(e => {
        console.error(e);
      });
  };

  const handleBackward = () => {
    if (attendanceState.dateRangeInAttendanceList.isDefaultWeekDate) {
      attendanceState.dateRangeInAttendanceList.weekCount--;
      const { startDate, endDate } = getWeekDates(
        attendanceState.dateRangeInAttendanceList.weekCount
      );
      return setStore({
        attendanceState: {
          ...attendanceState,
          dateRangeInAttendanceList: {
            ...attendanceState.dateRangeInAttendanceList,
            dateRange: [
              {
                startDate: startDate,
                endDate: endDate,
                key: "selection",
              },
            ],
          },
        },
      });
    }
    let startDate = new Date(fromDate);
    startDate.setDate(startDate.getDate() - 1); // decrease one day from fromDate
    let updatedToDate = new Date(startDate);
    startDate.setDate(startDate.getDate() - attendanceState.rangeGap); // decrease rangeGap from fromDate
    let updatedFromDate = new Date(startDate);
    setStore({
      attendanceState: {
        ...attendanceState,
        dateRangeInAttendanceList: {
          ...attendanceState.dateRangeInAttendanceList,
          dateRange: [
            {
              startDate: updatedFromDate,
              endDate: updatedToDate,
              key: "selection",
            },
          ],
        },
      },
    });
  };

  const handleForward = () => {
    if (attendanceState.dateRangeInAttendanceList.isDefaultWeekDate) {
      attendanceState.dateRangeInAttendanceList.weekCount++;
      const { startDate, endDate } = getWeekDates(
        attendanceState.dateRangeInAttendanceList.weekCount
      );
      return setStore({
        attendanceState: {
          ...attendanceState,
          dateRangeInAttendanceList: {
            ...attendanceState.dateRangeInAttendanceList,
            dateRange: [
              {
                startDate: startDate,
                endDate: endDate,
                key: "selection",
              },
            ],
          },
        },
      });
    }
    let endDate = new Date(toDate);
    // Add one day to the endDate
    endDate.setDate(endDate.getDate() + 1);
    let updatedFromDate = new Date(endDate);

    // Add the rangeGap days to the endDate
    endDate.setDate(endDate.getDate() + attendanceState.rangeGap);
    let updatedToDate = new Date(endDate);
    setStore({
      attendanceState: {
        ...attendanceState,
        dateRangeInAttendanceList: {
          ...attendanceState.dateRangeInAttendanceList,
          dateRange: [
            {
              startDate: updatedFromDate,
              endDate: updatedToDate,
              key: "selection",
            },
          ],
        },
      },
    });
  };

  const [formData, setFormData] = useState({
    jobId: 0,
    workDescription: "",
  });

  const jobIdFromParams = Number(searchParams.get("jobId"));

  useEffect(() => {
    setFormData(prevState => ({
      ...prevState,
      jobId: jobIdFromParams,
    }));
  }, [jobIdFromParams]);

  const handleInputChange = event => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const checkInHandle = async event => {
    event.preventDefault();

    if (formData.jobId === 0) {
      formData.jobId = previousJobs;
    }

    if (!formData.jobId || formData.jobId == 0) {
      showToast("error", ATTENDANCE_MESSAGES.SELECT_JOB);
      return;
    }

    // if (formData.workDescription == null || formData.workDescription.length == 0) {
    //   showToast("error", ATTENDANCE_MESSAGES.WORK_DESCRIPTION);
    //   return;
    // }

    // try {
    //   // Request location permission
    //   const permission = await requestLocationPermission();
    //   if (permission === "granted") {
    //     //  Log location if permission is granted
    //     const location = await getCurrentLocation();
    //     formData.latitude = location.latitude;
    //     formData.longitude = location.longitude;
    //     formData.accuracy = location.accuracy;

    //   } else {
    //     showToast("error", ATTENDANCE_MESSAGES.LOCATION_PERMISSION);
    //   }
    // } catch (error) {
    //   showToast("error", ATTENDANCE_MESSAGES.TIMER_FAILED);
    // }

    // Assuming startTimer is an asynchronous function that returns a promise
    const res = await startTimer(formData);
    if(res) { 
      setIsCheckedIn(true);
      showToast("success", ATTENDANCE_MESSAGES.TIMER_START);
    }
    fetchAttendanceWithLogs(fromDate, toDate, employeeId);
  };

  // Function to request location permission
  async function requestLocationPermission() {
    return new Promise(resolve => {
      navigator.permissions.query({ name: "geolocation" }).then(permissionStatus => {
        if (permissionStatus.state === "granted" || permissionStatus.state === "prompt") {
          resolve("granted");
        } else {
          resolve("denied");
        }
      });
    });
  }

  // Function to get current location
  async function getCurrentLocation() {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
        position => resolve(position.coords),
        error => reject(error)
      );
    });
  }

  const checkOutHandle = async event => {
    event.preventDefault();

    var res = await stopTimer();
    if (!res) return;

    setTimer("00:00:00");

    formData.workDescription = "";
    setIsCheckedIn(false);

    showToast("success", ATTENDANCE_MESSAGES.TIMER_STOP);
    fetchAttendanceWithLogs(fromDate, toDate, employeeId);
  };

  const lastJobs = () => {
    userPreferences().then(response => {
      setPreviousJobs(response.lastSelectedJobId);
    });
  };

  return (
    <>
      <div>
        <PageSection
          icon={<TbClock size={"2rem"} />}
          showIcon={true}
          heading={<SectionHeading isAdmin={isAdmin} isModerator={isModerator} />}
          containerClass="bg-white rounded"
          button={
            <div className="flex gap-4 items-end">
              {(isAdmin || isModerator) && (
                <div>
                  <Button onClick={toggleAddAttendanceModal} className={btnPrimary()}>
                    <GoPlus className="mr-2" size={"1rem"} /> Add Attendance
                  </Button>
                </div>
              )}
              <div>
                <DatePicker
                  options={useMemo(() => {
                    return {
                      type: 0,
                      showRangeBtns: true,
                      _position: "relative",
                    };
                  }, [])}
                  _propsState={attendanceState.dateRangeInAttendanceList}
                  _propsFunc={{
                    // do not memoize this object
                    handleDateRangePicker,
                    handleDateRange,
                    onDatePickerClickOutside,
                    onFocusDateRangePickerHandler,
                    handleForward,
                    handleBackward,
                  }}
                />
              </div>
            </div>
          }>
          <div>
            <form onSubmit={isCheckedIn ? checkOutHandle : checkInHandle}>
              <div className="flex gap-2 p-2 items-center">
                <div className="flex-1">
                  <InputFields
                    disabled={isCheckedIn}
                    inputClassName={cn("w-full")}
                    InputField={{
                      label: "",
                      type: "text",
                      id: "workDescription",
                      name: "workDescription",
                      placeholder: "What are you working on?",
                    }}
                    onChange={handleInputChange}
                    value={formData.workDescription}
                  />
                </div>
                <div>
                  <Button
                    type="submit"
                    className={`tc-btn-dark`}
                    style={{ backgroundColor: isCheckedIn ? "#EF4444" : "#10B981" }}>
                    <MdOutlineTimer size={"1.2rem"} /> <span className="pl-2">{timer}</span>{" "}
                  </Button>
                </div>
              </div>
            </form>

            <div className="static!important rounded-lg overflow-hidden bg-white dark:bg-gray-800 relative min-h-[16rem]">
              <div className="overflow-x-auto">
                <table className="w-full text-sm text-left">
                  <thead className="text-xs uppercase">
                    <tr>
                      {AttendanceHeading.map((heading, index) => (
                        <th key={index} scope="col" className="px-4 py-3 whitespace-nowrap">
                          {heading}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  {state.showUserDropdown[1] === true && attendanceState.selectedUser === null ? (
                    <span className="block top-[60%] text-[16px] absolute text-center left-[22rem] right-[22rem] whitespace-nowrap">
                      {" "}
                      Please select a user from top right{" "}
                    </span>
                  ) : (
                    <tbody>
                      {attendances?.map((user, i) => {
                        let startTime = user?.startTime
                          ? new Date(`2000-01-01T${user.startTime}Z`)
                              .toLocaleTimeString("en-IN")
                              .split(":")
                          : null;

                        if (startTime) {
                          startTime[2] = startTime[2].split(" ")[1];
                          startTime = startTime[0] + ":" + startTime[1] + " " + startTime[2];
                        }

                        if (user?.startTime === "00:00:00") {
                          startTime = "00:00";
                        }

                        let endTime = user?.endTime
                          ? new Date(`2000-01-01T${user.endTime ?? "00:00:00"}Z`)
                              .toLocaleTimeString("en-IN")
                              .split(":")
                          : null;
                        if (endTime) {
                          endTime[2] = endTime[2].split(" ")[1];
                          endTime = endTime[0] + ":" + endTime[1] + " " + endTime[2];
                        }

                        if (user?.endTime === "00:00:00" || user?.endTime === null) {
                          endTime = "00:00";
                        }

                        var attendanceStatus = user?.attendanceStatus;

                        // When user is present.
                        if (attendanceStatus == AttendanceStatus.Present) {
                          attendanceStatus = 1; // Present

                          if (user?.isHoliday) {
                            attendanceStatus = 7; // Present but Holiday
                          }

                          if (user?.isOnLeave) {
                            attendanceStatus = 8; // Present but on leave
                          }

                          if (user?.isWeekend) {
                            attendanceStatus = 9; // Present but week off
                          }
                        } else if (
                          attendanceStatus == AttendanceStatus.Absent ||
                          attendanceStatus == AttendanceStatus.Not_Applicable
                        ) {
                          if (user?.isOnLeave) {
                            if (user?.leave?.isFirstHalf || user?.leave?.isSecondHalf) {
                              attendanceStatus = 2;
                            } else {
                              attendanceStatus = 4;
                            }
                          }

                          if (user?.isWeekend) {
                            attendanceStatus = 5;
                          }

                          if (user?.isHoliday) {
                            attendanceStatus = 6;
                          }
                        }

                        const daysDifference = calculateDaysDifference(user?.date);

                        let key =
                          state.showUserDropdown[0] === true ? "userIds" : "approvalUserIds";

                        let currentDay = false;
                        if (
                          new Date(user?.date).toLocaleDateString() ===
                          new Date().toLocaleDateString().slice(0, 10)
                        ) {
                          currentDay = true;
                        }
                        return (
                          <React.Fragment key={i}>
                            <tr
                              className={`border-b h-[50px] dark:border-gray-600 hover:bg-gray-100 dark:hover:bg-gray-700 cursor-pointer ${
                                currentDay ? "bg-[#fbfaef]" : ""
                              }`}
                              key={i}
                              onClick={() => handleRowClick(i)}>
                              <td
                                className={`px-8 py-2 ${
                                  isMyAttendance ? "pl-[16px]" : "pl-[2px]"
                                }  dark:text-white`}>
                                <div className="flex items-center font-medium text-gray-900 whitespace-nowrap">
                                  {format(new Date(user?.date), "EEEE")}
                                </div>
                              </td>
                              <td className="px-4 py-2 whitespace-nowrap">
                                {formattedDate(user?.date)}
                              </td>
                              <td className="px-4 py-2 whitespace-nowrap">
                                <span
                                  className={`${
                                    AttendanceLeaveStatus[attendanceStatus]?.class || " "
                                  } text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-green-900 dark:text-green-300`}>
                                  {user.attendanceStatus === 0 && new Date(user.date) > new Date()
                                    ? "-"
                                    : AttendanceLeaveStatus[attendanceStatus]?.text || ""}
                                </span>
                              </td>
                              <td className="px-4 py-2 whitespace-nowrap">{startTime}</td>
                              <td className="px-4 py-2 whitespace-nowrap">{endTime}</td>
                              <td className="px-4 py-2 whitespace-nowrap">
                                {user?.overTime === "00:00:00" ? "00:00" : user?.overTime}
                              </td>
                              <td className="px-4 py-2 whitespace-nowrap">
                                {user?.totalHours && user.totalHours.slice(0, 5)}
                              </td>

                              <td
                                onClick={e => {
                                  e.stopPropagation();
                                }}
                                className="px-4 py-2 whitespace-nowrap">
                                <div className="tc-dropdown">
                                  {!presentCheck.includes(user?.attendanceStatus) ? (
                                    ""
                                  ) : (        

                                    <SelectWithDropdown
                                      roundedDot={true}
                                      backgroundColor={`${
                                        user?.overallApprovalStatus ===
                                        TimeLogApprovalStatus.Approved
                                          ? "bg-green-100 w-[150px]"
                                          : user?.overallApprovalStatus ===
                                              TimeLogApprovalStatus.Rejected
                                            ? "bg-red-100 w-[150px]"
                                            : user?.overallApprovalStatus ===
                                                TimeLogApprovalStatus.Pending
                                              ? "bg-thynkwebPrimary-50 w-[150px]"
                                              : null
                                      }`}
                                      className={`${
                                        user?.overallApprovalStatus ===
                                        TimeLogApprovalStatus.Approved
                                          ? "text-green-800 rounded-lg -ml-2 mr-2"
                                          : user?.overallApprovalStatus ===
                                              TimeLogApprovalStatus.Rejected
                                            ? "text-red-800 rounded-lg -ml-5 mr-2"
                                            : user?.overallApprovalStatus ===
                                                TimeLogApprovalStatus.Pending
                                              ? "text-thynkwebPrimary-700 rounded-lg -ml-5 mr-2"
                                              : null
                                      }`}
                                      dotClassName={`w-3 h-3 mr-2 ml-0.5 ${
                                        user?.overallApprovalStatus ===
                                        TimeLogApprovalStatus.Approved
                                          ? "bg-green-500"
                                          : user?.overallApprovalStatus ===
                                              TimeLogApprovalStatus.Rejected
                                            ? "bg-red-500"
                                            : user?.overallApprovalStatus ===
                                                TimeLogApprovalStatus.Pending
                                              ? "bg-thynkwebPrimary-500"
                                              : "bg-thynkwebPrimary-500"
                                      } border rounded-full`}
                                      size="sm"
                                      options={selectOptionsAttendances(userContext, user)}
                                      defaultValue={attendancesStatus(user?.overallApprovalStatus)}

                                      handleChange={
                                        userContext?.user?.roleId !== Role.Administrator &&
                                        user?.userId === userContext?.user?.id
                                          ? ""
                                          : handleSelectChange
                                      }
                                      otherOptions={user?.id}
                                      fetch
                                      singleAttendanceUpdated={singleAttendanceUpdated}

                                      isCurrentUser={
                                        userContext?.user?.roleId !== Role.Administrator &&
                                        user?.userId === userContext?.user?.id
                                          ? true
                                          : false
                                      }
                                      reRenderSelect={reRenderSelect}
                                      
                                      hideMenu={
                                        userContext?.user?.roleId !== Role.Administrator &&
                                        user?.userId === userContext?.user?.id
                                          ? true
                                          : userContext?.user?.roleId === Role.Administrator &&
                                              formattedDate(user?.date) ===
                                                formattedDate(new Date())
                                            ? true
                                            : false
                                      }
                                      isDefaultValue
                                    />
                                  )}
                                </div>
                              </td>
                              <td className="px-4 py-2 whitespace-nowrap"></td>
                            </tr>

                            {expandedRow === i &&
                              user?.timeLogs != null &&
                              user?.timeLogs.length != 0 && (
                                <tr>
                                  <td colSpan={9} className="pl-12 py-2 whitespace-nowrap">
                                    <TimeLogs
                                      expandedRow={expandedRow}
                                      i={i}
                                      user={user}
                                      TimeLogHeading={TimeLogHeading}
                                      duration={duration}
                                      handleDeleteAttendance={handleDeleteAttendance}
                                      TimeLogApprovalStatus={TimeLogApprovalStatus}
                                    />
                                  </td>
                                </tr>
                              )}
                          </React.Fragment>
                        );
                      })}
                    </tbody>
                  )}
                </table>
              </div>
              {true ? (
                ""
              ) : (
                <nav
                  className="flex flex-col items-start justify-between p-4 space-y-3 md:flex-row md:items-center md:space-y-0"
                  aria-label="Table navigation">
                  <div className="flex items-center space-x-3">
                    {attendancesResponse?.records > 21 ? (
                      <>
                        <label
                          htmlFor="rows"
                          className="text-xs font-normal text-gray-500 dark:text-gray-400">
                          Rows per page
                        </label>
                        <select
                          id="rows"
                          onChange={setPage}
                          value={pageSize}
                          className="text-gray-900 text-sm rounded-lg focus:ring-thynkwebPrimary-500 focus:border-thynkwebPrimary-500 block py-1.5 pl-3.5 pr-6 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-thynkwebPrimary-500 dark:focus:border-thynkwebPrimary-500">
                          <option value={20}>20</option>
                          <option value={50}>50</option>
                          <option value={100}>100</option>
                          <option value={200}>200</option>
                        </select>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                  <div>
                    {Math.ceil(attendancesResponse?.records / pageSize) > 1 ? (
                      <Pagination
                        currentPage={pageNo}
                        onPageChange={pageNumber => setPageNo(pageNumber)}
                        showIcons={true}
                        totalPages={
                          attendancesResponse?.records === undefined
                            ? 1
                            : Math.ceil(attendancesResponse?.records / pageSize)
                        }
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </nav>
              )}
            </div>
          </div>
        </PageSection>
      </div>

      {/* <div className="flex flex-wrap items-end wrap space-x-4 pb-[1rem]"> */}
      <ModalComponent
        show={attendanceState.addAttendanceModal}
        onClose={toggleAddAttendanceModal}
        heading="Add Attendance"
        headerClassName="modal-header-title mb-4">
        {attendanceState.addAttendanceModal && (
          <AddAttendanceModalBody btn_prop={{ txt: "Add", onClick: handleAddAttendance }} />
        )}
      </ModalComponent>
      {/* </div> */}

      <Dialog
        _propsState={useMemo(() => {
          return {
            isOpen: attendanceState.dialogState.isOpen,
            onClose: closeDialog,
            onClick: onDelete,
          };
        }, [attendanceState.dialogState.isOpen])}
        btn_styles={useMemo(() => {
          return {
            btn_text: "Delete",
          };
        }, [])}
        heading="Delete time log"
        body={"Are you sure you want to delete this time log?"}
      />
    </>
  );
};

export default memo(MyAttendance);

const SectionHeading = ({ isAdmin, isModerator }) => {
  const dispatch = useAppDispatch();

  //Opening attendance settings modal
  const handleOpenModal = () => {
    dispatch(toggleAttendanceModal(true));
  };
  return (
    <>
      {(isAdmin || isModerator) && (
        <div
          onClick={handleOpenModal}
          className="text-gray-400 transition duration-300 hover:text-gray-700 cursor-pointer tc-setting-icon top-[5rem] absolute">
          <GearSvg size={"1.5rem"} />
        </div>
      )}
      <span>My Attendance</span>
      <span>{(isAdmin || isModerator) && <MyAttendanceSetting />}</span>
    </>
  );
};
