import React, { useRef, useState } from "react";
import { Footer } from "../components/common/Footer";
import { FaUser } from "react-icons/fa";
import { SubmitContactUsDetails } from "../services/GeneralService";

const ContactUs = () => {
  const [userInfo, setUserInfo] = useState({
    name: "",
    email: "",
    message: "",
  });
  const ref = useRef();

  //Getting user information from contact-us form
  const onChangeUserInformation = (event) => {
    const userInformation = {
      ...userInfo,
      [event.target.name]: event.target.value,
    };
    setUserInfo(userInformation);
  };
  //Submitting user information
  const onSubmitUserInformation = (event) => {
    event.preventDefault();
    SubmitContactUsDetails(userInfo)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log("error:", error);
      });
    setUserInfo({
      name: "",
      email: "",
      message: "",
    });
    ref.current.reset();
  };

  return (
    <div>
      <div>
        <section className=" border border-red-700 mt-12  border-opacity-0">
          <div className="border-blue-700 relative m-3">
            <img
              className=" w-full rounded-lg shadow-xl dark:shadow-gray-800"
              src="https://www.linkpicture.com/q/1231.webp"
              alt="image description"
            />

            <p className="border border-red-700 absolute min-[100px]:top-[20%] min-[100px]:text-[0.6rem] min-[200px]:top-[30%] sm:top-[40%] left-[14%] lg:text-4xl border-transparent sm:text-2xl md:3xl min-[320px]:text-sm min-[450px]:text-base">
              We want to talk to you :)
            </p>
          </div>
        </section>
        <section className="border border-red-700 mt-10 border-opacity-0">
          <div className="text-center text-3xl font-bold md:w-[68%] m-auto">
            <h1>Discuss your requirements with us.</h1>
            <h1>Can't wait to meet you.</h1>
          </div>
          <div className="border border-blue-700 text-xl text-center mt-8 md:w-[68%] m-auto border-opacity-0">
            <p className="">
              Please complete the form below to schedule a call with a Thynk
              Corp expert. We'll discuss your
            </p>
            <p>
              goals and show you how Thynk Corp will help your organization.
            </p>
          </div>
        </section>
        <section className="border border-green-900 mt-[3.5rem] border-opacity-0">
          <div className="md:w-[66%] md:m-auto p-5 md:m-0 m-2 shadow-sm bg-gray-200 shadow-md bg-gray-200 rounded-[0.5rem]">
            <form onSubmit={onSubmitUserInformation} ref={ref}>
              {/* User Name */}
              <div>
                <label
                  htmlFor="name"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Your Name
                </label>
                <div className="relative">
                  <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                    <FaUser color="#6b7280" />
                  </div>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    onChange={onChangeUserInformation}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Enter your name"
                  />
                </div>
              </div>
              {/* User Email */}
              <div className="mt-4">
                <label
                  htmlFor="email"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Your Email
                </label>
                <div className="relative">
                  <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                    <svg
                      aria-hidden="true"
                      className="w-5 h-5 text-gray-500 dark:text-gray-400"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z"></path>
                      <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z"></path>
                    </svg>
                  </div>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    onChange={onChangeUserInformation}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="name@company.com"
                  />
                </div>
              </div>
              {/* User Message */}
              <div className="mt-4">
                <label
                  htmlFor="message"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Your message
                </label>
                <textarea
                  id="message"
                  rows="4"
                  name="message"
                  onChange={onChangeUserInformation}
                  className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Enter your message here..."
                ></textarea>
              </div>
              <div className="m-auto text-center mt-4">
                <button
                  type="submit"
                  // onClick={onSubmitUserInformation}
                  className="text-white py-2.5 px-5 mr-2 mb-2 text-base font-medium rounded-lg bg-thynkwebPrimary-800 hover:bg-thynkwebPrimary-900 focus:ring-4 focus:ring-thynkwebPrimary-300 dark:focus:ring-thynkwebPrimary-900"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </section>
      </div>
      <div className="mt-5">
        <Footer />
      </div>
    </div>
  );
};

export default ContactUs;
