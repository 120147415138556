import { Button } from "flowbite-react";
import React from "react";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import { setStatutoryToggleDetails } from "../../../store/slices/statutorySlice";

const InitialEsiComponent = () => {
  const dispatch = useAppDispatch();
  const statutory = useAppSelector(state => state.statutory);

  const { statutoryToggleDetails, statutoryEpfDetails } = statutory;

  //Function to open the ESI Modal.
  const onClickEnableEsi = () => {
    dispatch(
      setStatutoryToggleDetails({
        ...statutoryToggleDetails,
        toggleEsiModal: true,
      })
    );
  };

  return (
    <div className="mx-auto w-[50%]">
      <div className=" h-40 w-44 mx-auto">
        <img
          style={{ width: "100%", height: "100%" }}
          src="https://css.zohostatic.com/zfpayroll/zpayroll//assets/images/empty-states/ind/esi-317ed7e2208127d94c4d41bd8ca29641.svg"
          alt=""
        />
      </div>
      <h1 className="text-center text-xl font-medium">Are you registered for ESI?</h1>
      <p className="text-center mt-4">
        Organisations having 10 or more employees must register for Employee State Insurance (ESI).
        This scheme provides cash allowances and medical benefits for employees whose monthly salary
        is less than ₹21,000.
      </p>
      <Button className="mx-auto mt-6" onClick={onClickEnableEsi}>
        Enable ESI
      </Button>
    </div>
  );
};

export default InitialEsiComponent;
