import { Label } from "flowbite-react";
import Select from "react-select";

import { showToast } from "../../../common/Toast";
import { WEEKDAYS, WEEKDAYSArr } from "../../../../constants/Constant";
import MandatorySign from "../../../common/Input/MandatorySign";
import { options, createWeekDaysOffObj, customStyles } from "../../helper/helper";

/**
 * @param {{weekDaysOff: string[], setSelectedOptions: function, isMandatory: boolean, valueContainerStyles: object, isRequiredDetails: boolean}} ReactPropTypes React props
 * @param {string[]} weekDaysOff Array of strings
 * @param {function} setSelectedOptions useState set function
 * @param {boolean} isMandatory return label with asterisk sign
 * @param {Object} valueContainerStyles styles for react select value container
 * @param {boolean} isRequiredDetails only for the first time login
 * @returns select dropdown with multiselect functionality
 */

export default function WeekDayOffSelect({
  weekDaysOff,
  setSelectedOptions,
  isMandatory,
  valueContainerStyles,
  isRequiredDetails,
}) {
  //format object for react select with data received from backend
  const defaultValue = createWeekDaysOffObj(WEEKDAYS, weekDaysOff);

  const handleChange = function (SelectedOption) {
    let weekDaysIndex = [];

    //if selected options is more than 2 show a warning
    if (SelectedOption.length > 2) {
      const { toast, toastId } = showToast("warn", "More than two WeekDaysOff are not allowed!");
      toast.dismiss(toastId);
      return;
    }

    for (let key of SelectedOption) {
      //key if weekdaysArr value the key
      if (WEEKDAYSArr.includes(key.value.toLowerCase())) {
        //look for the key's index in the array
        const index = WEEKDAYSArr.findIndex(days => days === key.value.toLowerCase());

        // push that index into the weekDaysIndex array to send that to backends
        weekDaysIndex.push(index.toString());
      }
    }

    setSelectedOptions(prevSO => {
      //only for required details
      if (isRequiredDetails) {
        return [
          {
            ...prevSO[0],
            weekDaysOff: weekDaysIndex,
          },
        ];
      }

      return [
        {
          ...prevSO[0],
          weekDaysOff: weekDaysIndex,
        },
      ];
    });
  };

  return (
    <div>
      <div className="flex flex-col">
        {!isMandatory && (
          <Label className="text-gray-600 font-medium self-start" htmlFor="weekdaysOff">
            Weekdays Off
          </Label>
        )}

        {isMandatory && (
          <div>
            <Label className="text-gray-600 font-medium self-start" htmlFor="weekdaysOff">
              Weekdays Off
            </Label>
            <MandatorySign />
          </div>
        )}
        {weekDaysOff.length > 0 && (
          <Select
            id="weekdaysOff"
            defaultValue={defaultValue}
            onChange={handleChange}
            options={options}
            isMulti={true}
            styles={customStyles(valueContainerStyles)}
          />
        )}

        {/* Render Without values to prevent layout shift */}
        {weekDaysOff.length === 0 && (
          <Select
            id="weekdaysOff"
            defaultValue={[]}
            onChange={handleChange}
            options={options}
            isMulti={true}
            styles={customStyles(valueContainerStyles)}
          />
        )}
      </div>
    </div>
  );
}
