import { useState, useContext } from "react";
import { Link, Navigate, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import { UserLoginBasicSchema } from "../schema/UserLoginBasicSchema";
import { userLogin } from "../services/UserService";
import { LoadingSvg } from "../media/LoadingSvg";
import { ThynkWebLogoSvg } from "../media/ThynkWebLogoSvg";
import InputFields from "../components/common/Input/InputFields";
import { showToast } from "../components/common/Toast";
import ToastNotification from "../components/common/Toast/ToastNotification";
import { LOGIN_MESSAGES } from "../constants/Messages";

const isEnabled =
  "bg-thynkwebPrimary-600 hover:bg-thynkwebPrimary-700 focus:outline-none focus:ring-thynkwebPrimary-300 focus:ring-thynkwebPrimary-600 focus:border-thynkwebPrimary-600 dark:bg-thynkwebPrimary-600 dark:hover:bg-thynkwebPrimary-700 dark:focus:ring-thynkwebPrimary-800";

const loginObject = [
  {
    id: "email",
    label: "Email",
    type: "text",
    name: "email",
    placeholder: "name@company.com",
    required: true,
  },
  {
    id: "password",
    label: "Password",
    type: "password",
    name: "password",
    placeholder: "Enter password",
    required: true,
  },
];

export const Login = () => {

  const navigate = useNavigate();

  const location = useLocation();
  // const monthRef = useRef();
  // const yearRef = useRef();
  // const [searchParam, _] = useSearchParams();
  //State to manage Loading for login
  const [loading, setLoading] = useState(false);
  const { setUser } = useContext(AuthContext);
  const [userFields, setUserFields] = useState(UserLoginBasicSchema);
  const [searchParams, setSearchP] = useSearchParams();
  // State to manage the login form validation

  //Getting the user Login form values
  const handleLogin = e => {
    const { name, value } = e.target;
    setUserFields(prevUF => {
      //Submitting the Login Form
      return {
        ...prevUF,
        [name]: value,
      };
    });
  };

  function isValidEmail(email) {
    // Regular expression for checking email format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }

  //Submitting the Login Form
  const login = async e => {
    e.preventDefault();
    const redirectParam = searchParams.get("redirect");
    //we got month with redirect param but for year we have to explicitly get it.
    const year = searchParams.get("year");
    

    if (userFields.email.trim() === "") {
      showToast("error", LOGIN_MESSAGES.EMAIL_FIELDS);
      setLoading(false);
      return;
    } else if (!isValidEmail(userFields.email.trim())) {
      showToast("error", LOGIN_MESSAGES.VALID_EMAIL);
      setLoading(false);
      return;
    }

    if (userFields.password.trim() === "") {
      showToast("error", LOGIN_MESSAGES.PASSWORD_FIELDS);
      setLoading(false);
      return;
    }

    setLoading(true);
    const res = await userLogin(userFields);
    const data = await res?.data?.data;

    const resStatusCode = res?.data?.statusCode;

    if (resStatusCode === 401 || resStatusCode !== 200) {
      setLoading(false);
      return;
    }

    sessionStorage.setItem("user", JSON.stringify(data));
    setUser(data);
    setLoading(false);

    //only redirect if year and month is present
    if (redirectParam && year) navigate(`/${redirectParam}&year=${year}`);
    else navigate(location?.state?.prevUrl || "/");
  };

  return (
    <>
      <section className="bg-[url('https://flowbite.s3.amazonaws.com/blocks/marketing-ui/authentication/background.jpg')] bg-no-repeat bg-cover bg-center bg-gray-700 bg-blend-multiply bg-opacity-60">
        <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
          <div className="w-full bg-white rounded-2xl shadow dark:border md:mt-4 mt-24 sm:max-w-md xl:p-0 dark:bg-gray-100 dark:border-gray-700 bg-opacity-55 backdrop-blur-lg">
            <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
              <a href="#" className="flex items-center mb-6 text-2xl font-semibold text-white">
                <ThynkWebLogoSvg />
                <p className="text-blue-800 ml-2">Thynk corp</p>
              </a>
              <form className="space-y-1 md:space-y-6" action="#">
                {loginObject.map(inputField => {
                  if(inputField.name === "email"){
                    return (
                      <InputFields
                        inputClassName="w-full"
                        InputField={inputField}
                        onChange={handleLogin}
                        value={userFields[inputField.name]}
                        key={"email"}
                      />
                    );
                  }
                  if(inputField.name === "password"){
                    return (
                      <InputFields
                        inputClassName="w-full"
                        InputField={inputField}
                        onChange={handleLogin}
                        value={userFields[inputField.name]}
                        key={"password"}
                        otherProps={{
                          onPaste: e => e.preventDefault(),
                          onCopy: e => e.preventDefault(),
                        }}
                      />
                    );
                  }
                })}
          
                <button
                  disabled={loading}
                  type="submit"
                  onClick={login}
                  className={`${isEnabled} tc-btn-dark focus:ring-4 w-full text-white font-medium rounded-lg text-sm px-5 py-2.5 !mt-[1.8rem] text-center`}>
                  {loading && <LoadingSvg />}
                  {loading ? "Signing in..." : "Sign in"}
                </button>

                <div className="flex justify-between">
                  <p className="text-md font-semibold text-gray-900 dark:text-gray-900">
                    Don't have an account? <br />
                    <Link to={"/register"} className="font-medium text-[#2649a1]">
                      Create new Account
                    </Link>
                  </p>
                  <p className="text-sm font-light text-gray-500 dark:text-gray-400 mt-2">
                    <Link to={"/forgot-password"} className="font-medium text-[#2649a1]">
                      Forgot Password?
                    </Link>
                  </p>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
      <ToastNotification />
    </>
  );
};
