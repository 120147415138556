import { useRef, useState, useEffect } from "react";
import ModalComponent from "../../../components/common/Modal/Modal";
import { EditEmploymentType, GetEmploymentTypeById } from "../../../services/UserService";
import { showToast } from "../../../components/common/Toast";
import { editAssociation } from "../../../services/LeaveService";
import { EditEmploymentTypeBody } from "./EditEmploymentTypeBody";

export const EditEmploymentTypeModal = ({
  employmentTypeId,
  employmentTypeName,
  setRenderEmploymentType,
  toggleEditModal,
  onClose,
  setIsChange,
  isChange,
}) => {
  const formRef = useRef(null);

  const [employmentTypesInfo, setEmploymentTypesInfo] = useState();
  const [leaveTypesInfo, setLeaveTypesInfo] = useState([]);

  const renderEditEmploymentTypeModal = JSON.parse(
    sessionStorage.getItem("renderAddEmploymentType")
  );

  const handleEditEmploymentType = e => {
    e.preventDefault();
    const { name, value } = e.target;
    setEmploymentTypesInfo(prevState => ({
      ...prevState,
      id: employmentTypeId,
      [name]: value,
    }));
  };

  const handleLeaveTypes = (e, typeId, typeName) => {
    e.preventDefault();
    const { name, value } = e.target;

    const leaveTypeIndex = leaveTypesInfo.findIndex(leaveType => leaveType.leaveTypeId === typeId);

    if (leaveTypeIndex !== -1) {
      // If the leaveType with the typeId already exists, update its property
      setLeaveTypesInfo(prevLeaveTypesInfo =>
        prevLeaveTypesInfo.map((leaveType, index) =>
          index === leaveTypeIndex
            ? {
                ...leaveType,
                [name]: value,
              }
            : leaveType
        )
      );
    } else {
      // If the leaveType with the typeId doesn't exist, add a new object to the array
      setLeaveTypesInfo(prevLeaveTypesInfo => [
        ...prevLeaveTypesInfo,
        {
          leaveTypeId: typeId,
          leaveTypeName: typeName,
          [name]: value,
        },
      ]);
    }
  };

  useEffect(() => {
    GetEmploymentTypeById(employmentTypeId).then(response => {
      if (employmentTypesInfo === undefined) {
        setEmploymentTypesInfo(prevState => ({
          ...prevState,
          id: employmentTypeId,
          name: response?.data?.data?.name,
          description: response?.data?.data?.description,
        }));
      } else {
        return;
      }
    });
  }, []);

  const Submit = async e => {
    e.preventDefault();

    const createEmploymentResponse = await EditEmploymentType(employmentTypesInfo);

    if (
      createEmploymentResponse.data?.message === "Success!" &&
      createEmploymentResponse.data?.statusCode === 200
    ) {
      setRenderEmploymentType(true);
      onClose();

      // Preparing the associations data with the obtained employmentTypeId to pass as an argument to
      // the editAssociation API
      const associationsData = {
        employmentTypeId: employmentTypeId,
        employementTypeName: employmentTypesInfo.name,
        leaveTypes: leaveTypesInfo,
      };

      // Calling the editAssociation API with the associations data
      const editAssociationResponse = await editAssociation(associationsData);

      if (
        (editAssociationResponse.data?.message === "Success!" &&
          editAssociationResponse.data?.statusCode === 200) ||
        (createEmploymentResponse.data?.message === "Success!" &&
          createEmploymentResponse.data?.statusCode === 200)
      ) {
        showToast("success", "Employment type updated successfully");
      }
    }
  };

  return (
    <div>
      <ModalComponent
        show={toggleEditModal}
        headerClassName="modal-header-title mb-4"
        heading="Edit Employment Type"
        onClose={() => {
          onClose();
        }}
        body={
          <EditEmploymentTypeBody
            employmentTypeId={employmentTypeId}
            employmentTypeName={employmentTypeName}
            setIsChange={setIsChange}
            isChange={isChange}
            onClose={onClose}
            renderEditEmploymentTypeModal={renderEditEmploymentTypeModal}
            handleEditEmploymentType={handleEditEmploymentType}
            handleLeaveTypes={handleLeaveTypes}
            handleSubmit={Submit}
            formRef={formRef}
          />
        }
      />
    </div>
  );
};
