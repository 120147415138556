const dotDefaultClasses = "w-4 h-4 rounded-full";

/**
 * Renders a section with specified icon, heading, children, and additional options.
 *
 *@param {{icon?: svg|image, showIcon?: boolean, heading: string, children: ReactNode, dotsOptions?: {id: string | number, text?: string, dotClassName?: string, textClassName?: string  }[], dotsPosition?: string, button: element, containerClass: string, headingClassName: string, headingContainerClass: string, headingTextClass: string, sectionClass?: string, divWrapperClass?: string}}} props
 * @param {object} icon - the icon to be displayed in the section
 * @param {boolean} showIcon - flag to show the icon
 * @param {string} heading - the heading of the section
 * @param {ReactNode} children - the content within the section
 * @param {array} dotsOptions - an array of options for displaying dots
 * @param {string} dotsPosition - the position of the dots
 * @param {element} button - an optional button component
 * @param {string} containerClass - the class for the section container
 * @param {string} headingClassName - the class for the heading
 * @param {string} headingContainerClass - the class for the heading container
 * @param {string} headingTextClass - the class for the heading text
 * @param {string} sectionClass - the class for the section
 * @param {string} divWrapperClass - the class for the div wrapper
 * @return {element} the rendered section component
 */
const PageSection = ({
  icon,
  showIcon,
  heading,
  children,
  dotsOptions = [],
  dotsPosition,
  button,
  containerClass,
  headingClassName,
  headingContainerClass,
  headingTextClass,
  sectionClass,
  divWrapperClass,
}) => {
  return (
    <section className={`${sectionClass} tc-page h-[calc(100vh-61px)] py-3 sm:py-5 overflow-scroll`}>
      <div className={`${divWrapperClass} px-4 mx-auto lg:px-12 mb-10`}>
        <div
          className={`${headingContainerClass} mb-5 flex flex-wrap justify-between items-center gap-1`}>
          {showIcon && (
            <div className={`${headingClassName} flex items-center gap-3 pt-1 focus:none text-gray-800 dark:text-white`}>
              <div className="tc-page-icon">{icon}</div>
              <h1
                className={`${headingTextClass} text-3xl text-gray-900 dark:text-white py-4 cursor-default`}>
                {heading}
              </h1>
            </div>
          )}
          {!showIcon && (
            <div className={`${headingClassName} pt-1 focus:none text-gray-800 dark:text-white`}>
              <h1
                className={`${headingTextClass} text-4xl text-gray-900 dark:text-white py-4 cursor-default`}>
                {heading}
              </h1>
            </div>
          )}
          {dotsPosition === "top" && dotsOptions?.length > 0 && (
            <div className=" flex gap-4 items-center self-end pb-3 pr-2">
              {dotsOptions.map(dot => (
                <div key={dot.id} className="flex gap-2 items-center">
                  <div className={`${dot?.dotClassName} ${dotDefaultClasses}`}></div>
                  <span className={`${dot?.textClassName} `}>{dot?.text}</span>
                </div>
              ))}
            </div>
          )}
          {button && button}
        </div>
        <div
          className={`${containerClass} relative bg-white rounded-lg`}>
          {children} 
        </div>
        {dotsPosition === "bottom" && dotsOptions?.length > 0 && (
          <div className=" flex gap-4 items-center justify-end mt-2 pb-3 pr-2">
            {dotsOptions.map(dot => (
              <div key={dot.id} className="flex gap-1 items-center">
                <div className={`${dot?.dotClassName} ${dotDefaultClasses}`}></div>
                <span className={`${dot?.textClassName} `}>{dot?.text}</span>
              </div>
            ))}
          </div>
        )}
      </div>
    </section>
  );
};

export default PageSection;
