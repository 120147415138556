import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  showAttendanceModal: false,
  showGlobalSettingsModal: false,
  isChangeGlobalSettings: false,
  tenants: [],
  hasAdminAccess: false,
};

const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    toggleAttendanceModal: (state, action) => {
      state.showAttendanceModal = action.payload;
    },
    toggleGlobalSettingsModal: (state, action) => {
      state.showGlobalSettingsModal = action.payload;
    },
    setIsChangeGlobalSettings: (state, action) => {
      state.isChangeGlobalSettings = action.payload;
    },

    setSettingState: (state, action) => {
      let obj = action.payload;
      for (let key in obj) {
        state[key] = obj[key];
      }
    },
  },
});

export const {
  toggleAttendanceModal,
  toggleGlobalSettingsModal,
  setIsChangeGlobalSettings,
  setSettingState,
} = settingsSlice.actions;

export default settingsSlice.reducer;
