import { memo } from "react";
import { Label } from "flowbite-react";
import OPTIONS from "../Options";
import MandatorySign from "../../../common/Input/MandatorySign";
import { INPUT_FIELDS_CLASS } from "../../../../constants/Constant";

/**
 * Renders a select component.
 *
 * @param {{name: string, onChangeHandler: function, optionsArr: String[], defaultValue: string, nameAttr: string, labelClass: string, selectClass: string, parentClass: string, children: ReactNode, optionsAdditionalProps: object, disabled: boolean, required: boolean, capFirstLetter: boolean, isMandatory: boolean, placeholder: string, showPlaceholder: boolean}} props - The properties for the select component.
 * @param {string} name - The name of the select component.
 * @param {function} onChangeHandler - The event handler for changing the select value.
 * @param {String[]} optionsArr - An array of options for the select component.
 * @param {string} defaultValue - The default value for the select component.
 * @param {string} nameAttr - The name attribute for the select component.
 * @param {string} labelClass - The class name for the label element.
 * @param {string} selectClass - The class name for the select element.
 * @param {string} parentClass - The class name for the parent element.
 * @param {ReactNode} children - The content to be rendered inside the select component.
 * @param {object} optionsAdditionalProps - Additional properties for the options.
 * @param {boolean} disabled - Determines whether the select component is disabled or not.
 * @param {boolean} required - Determines whether the select component is required or not.
 * @param {boolean} capFirstLetter - Determines whether to capitalize the first letter of each option.
 * @param {boolean} isMandatory - Determines whether the select component is mandatory or not.
 * @param {string} placeholder - The placeholder text for the select component.
 * @param {boolean} showPlaceholder - Determines whether to show the placeholder text or not in the select component.
 * @return {JSX.Element} The rendered single select component.
 */
const SelectComponent = memo(function SelectComponent({
  name,
  onChangeHandler,
  optionsArr,
  defaultValue,
  nameAttr,
  labelClass = "",
  selectClass = "",
  parentClass = "",
  children,
  optionsAdditionalProps,
  disabled = null,
  required = false,
  capFirstLetter = false,
  isMandatory,
  placeholder,
  showPlaceholder,
}) {
  return (
    <div className={`flex flex-col ${parentClass}`}>
      {!isMandatory && (
        <Label
          className={`text-gray-600 font-medium self-start capitalize ${labelClass}`}
          htmlFor={name}>
          {name && `${name}`}
        </Label>
      )}
      {isMandatory && (
        <div className="flex">
          <Label
            className={`text-gray-600 font-medium self-start capitalize ${labelClass}`}
            htmlFor={name}>
            {name && `${name}`}
          </Label>
          <MandatorySign />
        </div>
      )}
      <select
        disabled={disabled}
        style={disabled ? { cursor: "not-allowed" } : { cursor: "pointer" }}
        onChange={onChangeHandler}
        value={defaultValue}
        className={`${selectClass} ml-auto w-[100%] mt-1 ${INPUT_FIELDS_CLASS()}`}
        name={nameAttr}
        id={name}
        required={required}>
        {optionsArr.length > 0 && (
          <OPTIONS
            optionsAdditionalProps={optionsAdditionalProps}
            optionsArr={optionsArr}
            capFirstLetter={capFirstLetter}
            showPlaceholder={showPlaceholder}
            placeholder={placeholder}
          />
        )}
        {children}
      </select>
    </div>
  );
});
export default SelectComponent;
