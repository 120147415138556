import React, { useEffect, useState } from "react";
import PageSection from "../../components/Layout/PageSection/PageSection";
import { MdOutlineBusinessCenter } from "react-icons/md";
import { Button } from "flowbite-react";
import { btnPrimary } from "../../utils/helper";
import { GoPlus } from "react-icons/go";
import Table from "../../components/common/Table/Table";
import TableHeader from "../../components/common/Table/TableHeader";
import { BUSINESS_HEADING, STATUS_CODES } from "../../constants/Constant";
import ClientLists from "../../components/Attendance/Clients/ClientLists";
import { deleteBusiness, getBusinesses } from "../../services/BusinessService";
import { useSelector } from "react-redux";
import usePagination, { PaginationComponent } from "../../hooks/usePagination";
import AddBusiness from "../../components/invoices/components/businesses/AddBusiness";
import { toggleInitialState } from "../../components/invoices/helper";
import BusinessLists from "./BusinessLists";
import DeleteModal from "../../components/common/Modal/DeleteModal";
import { showToast } from "../../components/common/Toast";

//temp variable for api call
const isClient = false;
const Businesses = () => {
  const [toggleStates, setToggleStates] = useState(toggleInitialState);
  const [business, setBusiness] = useState([]);
  const [clientUpdateId, setClientUpdateId] = useState(null);
  const { pageSize, pageNo } = useSelector(state => state.pagination);
  const [totalRecords, setTotalRecords] = useState(0);
  const { setPageSizeHandler, onPageChange } = usePagination(false, totalRecords);
  const [deleteId, setDeleteId] = useState({
    id: undefined,
    name: "",
  });
  const [conformationDeleteModal, setConformationDeleteModal] = useState(false);

  const openBilledByModal = () => {
    setToggleStates(prevTS => ({
      ...prevTS,
      billedByModal: true,
      isClient: false,
    }));
  };

  useEffect(() => {
    fetchClients();
  }, [toggleStates.isChange, pageSize, pageNo]);

  const fetchClients = async () => {
    // const isClient = true;
    const response = await getBusinesses(pageNo, pageSize, isClient);
    if (response?.statusCode === STATUS_CODES.SUCCESS) {
      setBusiness(response?.data);
      setTotalRecords(response?.totalRecords);
    }
  };

  const setClientUpdate = async id => {
    setClientUpdateId(id),
      setToggleStates(prevTS => {
        return {
          ...prevTS,
          isClientUpdate: true,
        };
      });
  };

  const resetClientUpdateId = () => {
    setClientUpdateId(null);
  };

  const deleteClients = async () => {
    const response = await deleteBusiness(+deleteId.id);
    setBusiness(prevState => prevState.filter(cl => cl.id !== +deleteId.id));
    showToast("success", "Deleted Business successfully");

    setToggleStates(prevTS => ({ ...prevTS, isChange: !prevTS.isChange }));
    setConformationDeleteModal(false);

    // reset
    setDeleteId({
      id: undefined,
      name: "",
    });
  };

  const openDeleteModal = function (id, name) {
    setDeleteId({
      id: id,
      name: name,
    });
    setConformationDeleteModal(true);
  };

  return (
    <>
      <PageSection
        heading="Businesses"
        icon={<MdOutlineBusinessCenter size={"2rem"} />}
        showIcon={true}
        button={
          <Button onClick={openBilledByModal} className={`${btnPrimary()}`}>
            <GoPlus size={"1rem"} />
            &nbsp;&nbsp;Add Business
          </Button>
        }>
        <Table>
          <TableHeader cols={BUSINESS_HEADING} />

          <tbody>
            <BusinessLists
              business={business}
              setClientUpdate={setClientUpdate}
              openDeleteModal={openDeleteModal}
            />
          </tbody>
        </Table>

        <AddBusiness
          show={toggleStates.billedByModal}
          modal="billedByModal"
          setToggleStates={setToggleStates}
          isClientUpdate={toggleStates.isClientUpdate}
          isClient={toggleStates.isClient}
          clientUpdateId={clientUpdateId}
          resetClientUpdateId={resetClientUpdateId}
        />

        <DeleteModal
          modalConfigs={{
            show: conformationDeleteModal,
            size: "md",
            onClose: () => setConformationDeleteModal(false),
            showFooter: false,
            otherProps: {
              popup: true,
            },
          }}
          message={`Are you sure you want to delete ${deleteId.name}`}
          onDelete={deleteClients}
          onCancel={() => setConformationDeleteModal(false)}
          showIcon={true}></DeleteModal>

        <PaginationComponent
          onChange={setPageSizeHandler}
          onPageChange={onPageChange}
          showIcons={true}
          totalRecords={totalRecords}
        />
      </PageSection>
    </>
  );
};

export default Businesses;
