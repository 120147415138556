import { DEFAULT_MONTH } from "../../constants/Constant";
import { btnPrimary } from "../../utils/helper";

const DownloadBtn = ({ selectedMonth, userData, pdfData }) => {
  const [year, month] = selectedMonth.split("-");

  const monthName = DEFAULT_MONTH?.filter(ele => ele?.value === month);
  const pdfName = `${userData?.firstName}_${userData?.lastName}_${monthName[0].value}-${monthName[0].label}_${year}`;

  return (
    <a
      href={URL.createObjectURL(pdfData.pdfBlob)}
      className={`${btnPrimary()} rounded-md text-white bg-thynkwebPrimary-900 inline-block px-4 py-2 font-semibold`}
      download={`Payslip_${pdfName}.pdf`}>
      Download
    </a>
  );
};

export default DownloadBtn;
