import { memo } from "react";

export const CardItem = memo(props => {
  const { subHead = "Enter sub heading...", value } = props;
  return (
    <>
      <div>
        <dt className="text-sm font-medium text-gray-500 dark:text-gray-400">{subHead}</dt>
        <dd className="text-sm font-semibold text-gray-900 dark:text-white">{value}</dd>
      </div>
    </>
  );
});
