import { Navbar } from "flowbite-react";
import { Link, useLocation } from "react-router-dom";

import { Logout } from "../../../pages/Logout";

import { ThynkWebLogoSvg } from "../../../media/ThynkWebLogoSvg";
import { btnPrimary } from "../../../utils/helper";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../context/AuthContext";
import useClickOutside from "../../../hooks/useClickOutside";
import { FcApproval, FcOrganization } from "react-icons/fc";
import { IoIosArrowDown } from "react-icons/io";
import { retrieveTenants, selectTenant } from "../../../services/AccountService";
import { LoadingSvg } from "../../../media/LoadingSvg";
import { STATUS_CODES } from "../../../constants/Constant";

const orgDropdown = "organisation-dropdown";
const trigger = "org-trigger";

const activeClass = "bg-[whitesmoke]";

const RequiredDetailsHeader = () => {
  // Access specific parts of the location
  const [state, setState] = useState({
    showDropdown: false,
        tenants: [],
        loading: false,
        clickedTenant: null,
        activeTenant: null,
  });
  const { user } = useContext(AuthContext);


  let { tenants, loading, showDropdown, clickedTenant, activeTenant } = state;


  useEffect(() => {
    retrieveTenants()
      .then(res => {
        // Check for successful response with status code 200
        if (res.status === STATUS_CODES.SUCCESS) {
          const data = res.data.data;
          let activeTenant = null;
          data.forEach(tenant => {
            if (tenant.id === user.currentTenantId) {
              activeTenant = tenant;
            }
          });
          setState(s => ({ ...s, tenants: res.data.data, activeTenant: activeTenant }));
        } else {
          // Handle error when response status is not successful
          console.error(`Failed to retrieve tenants. Status code: ${res.status}`);
          // You can set an error state or display an error message here.
        }
      })
      .catch(e => {
        console.error(e);
      });
  }, []);


  const toggleDropdown = () => {
    setState(s => ({ ...s, showDropdown: !showDropdown }));
  };

  const onClose = e => {
    const elem = e.target.closest(`[data-obj=${trigger}]`);
    if (elem) return;
    setState(s => ({ ...s, showDropdown: false }));
  };

  const switchTenant = id => {
    if (user.currentTenantId === id || loading) {
      return;
    }
    setState(s => ({ ...s, loading: true, clickedTenant: id }));
    selectTenant(id)
      .then(res => {
        if(res.status === STATUS_CODES.SUCCESS){
        const data = res.data.data;
        sessionStorage.setItem("user", JSON.stringify(data));
        window.location.reload();
        }
        else{
          // Handle error when response status is not successful
          console.error(`Failed to select tenants. Status code: ${res.status}`);
          // You can set an error state or display an error message here.
        }
      })
      .catch(e => {
        console.error(e);
        setState(s => ({ ...s, loading: false, clickedTenant: null }));
      });
  };
  useClickOutside(showDropdown, `[data-obj=${orgDropdown}]`, onClose);

  return (
    <>
      <Navbar
        fluid={true}
        rounded={true}
        className="header__height drop-shadow-md justify-between sticky top-0 left-0 right-0 z-10">
        <Link className=" flex flex-row" to="/">
          <ThynkWebLogoSvg />
          <span className="self-center ml-3 whitespace-nowrap text-xl font-semibold dark:text-white">
            Thynk Corp
          </span>
        </Link>

        <div className="flex items-center gap-4">
          <div>
            <div
              className=" flex items-center justify-between bottom-[-18px]  gap-[8px] cursor-pointer p-1.5 rounded-[5px] bg-[#eee]"
              onClick={toggleDropdown}
              data-obj={trigger}>
              <div className="flex items-center gap-[2px] [&_p]:text-[16px]">
                <FcOrganization />
                <p>{activeTenant ? activeTenant.name : "Organization"}</p>
              </div>
              <IoIosArrowDown className={`cursor-pointer ${showDropdown ? "rotate-180" : ""}`} />
            </div>
            {showDropdown && (
              <div
                data-obj={orgDropdown}
                className="absolute top-[4rem] ml-[-1.5rem]  w-[142px] p-[5px] rounded-[4px] bg-white"
                style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px" }}>
                {tenants.map((tenant, index) => {
                  const selected = user.currentTenantId === tenant.id;
                  const loader = selected === false && clickedTenant === tenant.id && loading && (
                    <LoadingSvg />
                  );
                  return (
                    <div
                      onClick={switchTenant.bind(this, tenant.id)}
                      key={tenant.id}
                      className={`${
                        selected ? activeClass : ""
                      } hover:bg-[whitesmoke] text-[12px] cursor-pointer flex items-center gap-[8px] p-[5px] rounded-[7px] mb-[5px] font-semibold [&_svg]:!text-[blue]`}>
                      <p className="w-[100%] truncate whitespace-nowrap"> {tenant.name}</p>
                      {selected && <FcApproval className="min-w-[16px] min-h-[16px]" />}
                      {loader}
                    </div>
                  );
                })}
              </div>
            )}
          </div>
          <div>
            <Logout
              svgClassName="fill-white"
              className={`${btnPrimary()} text-white font-semibold rounded-md flex items-center gap-2 px-6 py-2`}
            />
          </div>
        </div>
      </Navbar>
    </>
  );
};

export default RequiredDetailsHeader;
