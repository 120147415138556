import { useState } from "react";

/**
 * Renders the pay slip details for a given user and pay slip data.
 *
 * @param {Object} userData - The user data object.
 * @param {Object} paySlipData - The pay slip data object.
 * @param {string} selectedMonth - The selected month for the pay slip.
 * @return {JSX.Element} The rendered pay slip details.
 */
const PaySlipDetails = ({ pdfData }) => {
  const [pdfString, setPdfString] = useState();

  if ((pdfData.error !== 404 || !pdfData.error) && pdfData.pdfBlob) {
    let reader = new FileReader();
    // pdf limit is 2MB
    reader.readAsDataURL(pdfData.pdfBlob);
    reader.onloadend = () => {
      let base64String = reader.result;
      setPdfString(base64String.substr(base64String.indexOf(",") + 1));
    };
  }

  return (
    <>
      {(pdfData.error !== 404 || !pdfData.error) && (
        <embed
          src={`data:application/pdf;base64,${pdfString}#view=FitH&toolbar=0`}
          width="100%"
          height="100%"
        />
      )}
    </>
  );
};

export default PaySlipDetails;
