import { useEffect, useState } from "react";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { ResetUserPassword } from "../services/UserService";
import { showToast } from "../components/common/Toast";
import { Toaster } from "react-hot-toast";
import ToastNotification from "../components/common/Toast/ToastNotification";
import { cn } from "../utils/common";
import { RESET_PASSWORD_MESSAGES } from "../constants/Messages";
import jwtDecode from "jwt-decode";

const notLoadingClass =
  " bg-thynkwebPrimary-700  dark:focus:ring-thynkwebPrimary-900 hover:bg-thynkwebPrimary-800  focus:ring-thynkwebPrimary-200";

const loadingClass =
  "bg-gray-400 hover:bg-gray-500 focus:bg-gray-500 dark:focus:ring-bg-gray-700 focus:ring-bg-gray-700";

const ResetPassword = () => {
  const navigate = useNavigate();
  //Getting token and mail from the link which is sent to mail
  const { token: pathToken, email: pathEmail } = useParams();

  //State to manage the token expiry
  const [tokenExpired, setTokenExpired] = useState(false);

  //Get token & email from url query params
  const [searchParams] = useSearchParams();
  const queryToken = searchParams.get("token");
  const queryEmail = searchParams.get("email");

  //Determine correct token/email
  const token = queryToken || pathToken;
  const email = queryEmail || pathEmail;

  //State for loading
  const [loading, setLoading] = useState(false);

  //Initial state for the input fields
  const [userInputs, setUserInputs] = useState({
    password: "",
    confirmPassword: "",
  });

  //Checking if token is expired whenever user visits reset password page.
  useEffect(() => {
    if (token && isTokenExpired(token)) {
      showToast("error", RESET_PASSWORD_MESSAGES.EXPIRED_TOKEN);
      setTokenExpired(true);
    } else {
      setTokenExpired(false);
    }
  }, []);

  //Function to check if token is expired or not
  const isTokenExpired = token => {
    const decodedToken = jwtDecode(token);
    if (decodedToken && decodedToken.exp) {
      //Converting expiration time to milliseconds
      const expirationTime = decodedToken.exp * 1000;

      //Getting current time(UTC) in milliseconds
      const currentTime = Date.now();
      return currentTime > expirationTime;
    }
    return true;
  };

  //Getting form values
  const onChangeResetPassword = event => {
    const currentPassword = {
      ...userInputs,
      [event.target.name]: event.target.value,
    };
    setUserInputs(currentPassword);
  };

  //Submitting the user Details with token and email
  const onSubmitResetPassword = async event => {
    event.preventDefault();
    const userDetails = { ...userInputs, token: token, email: email };
    const isPasswordMatch =
      userInputs.password.length !== userInputs.confirmPassword.length ||
      userInputs.password !== userInputs.confirmPassword;

    if (userInputs?.password.length < 4 || userInputs?.confirmPassword.length < 4) {
      return showToast("error", "Password should be at least 4 character long.");
    }

    if (isPasswordMatch) {
      return showToast("error", "Password does not match");
    }
    setLoading(true);

    try {
      const response = await ResetUserPassword(userDetails);
      if (response.status === 200) {
        showToast("success", RESET_PASSWORD_MESSAGES.SUCCESS);
        setUserInputs({
          password: "",
          confirmPassword: "",
        });
        navigate("/login");
      } else {
        showToast("error", RESET_PASSWORD_MESSAGES.EXPIRED_TOKEN);
        setLoading(false);
      }
    } catch (error) {
      if (error) {
        showToast("error", RESET_PASSWORD_MESSAGES.EXPIRED_TOKEN);
        setLoading(false);
      }
    }
  };

  return (
    <section className="bg-gray-50 dark:bg-gray-900">
      <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
        <div className="w-full bg-white rounded-lg shadow dark:border md:mt-4 mt-24 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
          <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
            <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white text-center">
              Reset password
            </h1>
            <form className="space-y-4 md:space-y-6">
              <div>
                <label
                  htmlFor="new-password"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                  Password
                </label>
                <input
                  type="password"
                  name="password"
                  id="new-password"
                  onChange={onChangeResetPassword}
                  placeholder="Enter new password"
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-thynkwebPrimary-600 focus:border-thynkwebPrimary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-thynkwebPrimary-500 dark:focus:border-thynkwebPrimary-500"
                  required
                  disabled={tokenExpired}
                />
              </div>
              <div>
                <label
                  htmlFor="confirm-password"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                  Confirm Password
                </label>
                <input
                  type="password"
                  name="confirmPassword"
                  id="confirm-password"
                  onChange={onChangeResetPassword}
                  placeholder="Confirm password"
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-thynkwebPrimary-600 focus:border-thynkwebPrimary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-thynkwebPrimary-500 dark:focus:border-thynkwebPrimary-500"
                  required
                  disabled={tokenExpired}
                />
              </div>
              <button
                type="submit"
                onClick={onSubmitResetPassword}
                disabled={tokenExpired}
                className={cn(
                  "w-full px-5 !mt-4 py-2.5 text-sm font-medium text-center text-white  rounded-lg focus:ring-4 cursor-pointer",
                  {
                    [loadingClass]: loading,
                    [notLoadingClass]: !loading,
                  }
                )}>
                {loading ? "Resetting Password..." : "Reset Password"}
              </button>
              <div className="flex justify-between">
                <p className="text-sm font-light text-gray-500 dark:text-gray-400 mt-2 text-center">
                  Remember the password?{" "}
                  <Link to={"/login"} className="font-medium text-[#2563eb]">
                    Login here
                  </Link>
                </p>
              </div>
            </form>
          </div>
        </div>
      </div>
      <ToastNotification />
    </section>
  );
};

export default ResetPassword;
