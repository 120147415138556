import React from "react";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import { RiDeleteBin6Line } from "react-icons/ri";
import DeleteModal from "../../../components/common/Modal/DeleteModal";
import { MdOutlineEdit } from "react-icons/md";
import {
  resetStatutoryProfessionalTaxDetails,
  setStatutoryToggleDetails,
} from "../../../store/slices/statutorySlice";
import { disableStatutoryComponent } from "../../../services/StatutoryService";
import { STATUS_CODES } from "../../../constants/Constant";
import { showToast } from "../../../components/common/Toast";
import { STATUTORY_MESSAGE } from "../../../constants/Messages";

const ProfessionalTaxDetails = () => {
  const dispatch = useAppDispatch();
  const statutory = useAppSelector(state => state.statutory);

  const { statutoryToggleDetails, statutoryProfessionalTaxDetails } = statutory;

  // Function to open the delete Professional Tax Modal.
  const onOpenDeleteProfessionalTaxModal = () => {
    dispatch(
      setStatutoryToggleDetails({
        ...statutoryToggleDetails,
        toggleProfessionalTaxDeleteModal: true,
      })
    );
  };

  // Function to close delete Professional Tax Modal.
  const onCloseDeleteProfessionalTaxModal = () => {
    dispatch(
      setStatutoryToggleDetails({
        ...statutoryToggleDetails,
        toggleProfessionalTaxDeleteModal: false,
      })
    );
  };

  const modalConfigs = {
    show: statutoryToggleDetails.toggleProfessionalTaxDeleteModal,
    size: "2xl",
    showFooter: false,
    onClose: onCloseDeleteProfessionalTaxModal,
    bodyClassName: "gap-4 mt-2",
  };

  // Function to open the update Professional Tax Modal.
  const onOpenUpdateProfessionalTaxModal = () => {
    dispatch(
      setStatutoryToggleDetails({
        ...statutoryToggleDetails,
        toggleUpdateProfessionalTaxModal: true,
        isProfessionalTaxEnabled: false,
      })
    );
  };

  // Function to delete or disable Professional Tax.
  const onDeleteProfessionalTaxComponentDetails = async () => {
    if (statutoryProfessionalTaxDetails?.id) {
      const response = await disableStatutoryComponent(statutoryProfessionalTaxDetails?.id);
      if (response?.data?.statusCode === STATUS_CODES.SUCCESS) {
        showToast("success", STATUTORY_MESSAGE.PROFESSIONAL_TAX_DELETE);
        dispatch(
          setStatutoryToggleDetails({
            ...statutoryToggleDetails,
            toggleProfessionalTaxDeleteModal: false,
            isProfessionalTaxEnabled: false,
          })
        );
        dispatch(resetStatutoryProfessionalTaxDetails());
      }
    }
  };
  return (
    <>
      <div className="min-h-[20rem]">
        {/* First heading container */}
        <div className="heading-container mt-4 flex gap-2">
          <h1 className="text-xl font-medium">Professional Tax</h1>
          <button
            className="border w-8 h-8 rounded-[50%] flex justify-center items-center bg-gray-200 cursor-pointer"
            onClick={onOpenUpdateProfessionalTaxModal}>
            <MdOutlineEdit size={23} />
          </button>
        </div>
        {/* Professional Tax Number container */}
        <div className="professional-tax-container mt-4 flex">
          <p className="professional-tax-number-container w-[20%]">Professional Tax Amount</p>
          <p className="professional-tax-number-value">
            {statutoryProfessionalTaxDetails?.employeeContributionRate}
          </p>
        </div>

        <hr className="h-px mt-6 bg-gray-200 border-0 dark:bg-gray-700" />

        {/* Delete or Disable Professional Tax container */}
        <div className="delete-or-disable-professional-tax-container mt-4">
          <button
            className="cursor-pointer flex justify-center items-center text-[#aabee6] hover:text-[#5b7fc7]"
            onClick={onOpenDeleteProfessionalTaxModal}>
            <p className="flex justify-center items-center">
              <RiDeleteBin6Line size={20} />
            </p>
            <p className="flex justify-center items-center">Disable Professional Tax</p>
          </button>
        </div>
      </div>
      <DeleteModal
        modalConfigs={modalConfigs}
        onDelete={onDeleteProfessionalTaxComponentDetails}
        onCancel={onCloseDeleteProfessionalTaxModal}
        showIcon={true}
        message={"Are you sure you want to disable Professional Tax for this organisation?"}
      />
    </>
  );
};

export default ProfessionalTaxDetails;
