import React, { useEffect } from 'react'
import "./InvoicesFilter.css"
import { RxCross2 } from 'react-icons/rx';
import { btnPrimary } from '../../../utils/helper';

const InvoicesFilter = ({
  isOpen,
  openDropdown,
  closeDropdown,
  setInvoicesRange,
  clearFilter,
  customRange,
  Filter,
}) => {

  useEffect(() => {
    const FilterOutside = event => {
      if (
        !document.getElementById("openBtn").contains(event.target) &&
        !document.getElementById("closeBtn").contains(event.target) &&
        !document.getElementById("myDropdown").contains(event.target)
      ) {
        closeDropdown();
      }
    };

    document.addEventListener("click", FilterOutside);

    return () => {
      document.removeEventListener("click", FilterOutside);
    };
  }, []);

  return (
    <div>
      <form
        action="#"
        method="get"
        id="myDropdown"
        className={`InvoicesFilter ${isOpen ? "show" : ""}`}
        onSubmit={setInvoicesRange}
        aria-labelledby="dropdownDefault">
        <div className="flex justify-between">
          <div>
            <h1 className="text-lg font-bold">Filter</h1>
          </div>
          <button id="closeBtn" onClick={closeDropdown}>
            <RxCross2 />
          </button>
        </div>
        <div className="py-2 font-light border-t border-gray-200 dark:border-gray-600"></div>{" "}
        <ul className="h-auto overflow-x-auto space-y-2 text-sm">
          <div>
            {/* Amount Range */}
            <div className="">
              <h6 className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                Amount
              </h6>
              <div className="flex items-center gap-[5px] mx-[10px]">
                <input
                  type="number"
                  id="amount-from"
                  placeholder="From"
                  name="customRangeFrom"
                  value={Filter.customRangeFrom}
                  onChange={customRange}
                  className="block w-[50%] p-2 text-gray-900 border-gray-300 rounded-lg bg-gray-50 sm:text-xs  dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                />

                <input
                  type="number"
                  id="amount-to"
                  name="customRangeTo"
                  placeholder="To"
                  value={Filter.customRangeTo}
                  onChange={customRange}
                  className="block p-2 w-[50%] text-gray-900 border border-gray-300 rounded-lg bg-gray-50 sm:text-xs dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                />
              </div>
            </div>

            {/* Date Range */}
            <div>
              <h6 className="block mt-2 mb-2 text-sm font-medium text-gray-900 dark:text-white">
                Date
              </h6>
              <div className="flex mb-2">
                <div className="mr-5 mb-1">
                  <label htmlFor="fromDatePicker">From:</label>
                  <div className="text-center mt-1 gap-[5px] mx-[10px]">
                    <input
                      type="date"
                      id="fromDatePicker"
                      name="dateFrom"
                      onChange={customRange}
                      value={Filter.dateFrom}
                      className="w-36 ml-1 border border-gray-300 bg-gray-50 rounded-lg px-2 py-1"
                    />
                  </div>
                </div>
                <div className="">
                  <label htmlFor="toDatePicker">To:</label>
                  <div className="text-center mt-1">
                    <input
                      type="date"
                      id="toDatePicker"
                      name="dateTo"
                      onChange={customRange}
                      value={Filter.dateTo}
                      className="border mr-2 border-gray-300 bg-gray-50 rounded-lg py-1"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ul>
        <div className="flex justify-end items-center">
          <div className="m-2">
            <button
              type="button"
              onClick={clearFilter}
              className=" px-3 py-2 text-sm font-medium text-center bg-gray-200 text-black rounded-lg bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none hover:bg-gray-400">
              Clear
            </button>
          </div>
          <div className="m-2">
            <button
              type="submit"
              onClick={closeDropdown}
              className={`${btnPrimary()} !px-4 !py-2 rounded-md text-white tracking-wide font-semibold flex items-center gap-2`}>
              Apply
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default InvoicesFilter;
