import { memo, useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setSideBarState } from "../../../../store/slices/sideBarSlice";
import { AiFillPlusCircle } from "react-icons/ai";
import { selectTenant } from "../../../../services/AccountService";
import { AuthContext } from "../../../../context/AuthContext";
import { FcApproval } from "react-icons/fc";
import { LoadingSvg } from "../../../../media/LoadingSvg";
import { STATUS_CODES } from "../../../../constants/Constant";
import { useNavigate } from "react-router";

const orgDropdown = "organisation-dropdown";

const OrgChange = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {tenants, hasAdminAccess} = useSelector(state => state.settings);

  const [state, setState] = useState({
    loading: false,
    clickedTenant: null,
  });
  const { user } = useContext(AuthContext);

  let { loading, clickedTenant  } = state;

  const toggleOrg = () => {
    dispatch(setSideBarState({ toggle: true }));
  };

  const switchTenant = id => {
    if (user.currentTenantId === id || loading) {
      return;
    }
    setState(s => ({ ...s, loading: true, clickedTenant: id }));
    selectTenant(id)
      .then(res => {
        if (res.status === STATUS_CODES.SUCCESS) {
          let data = res.data.data;
          sessionStorage.setItem("user", JSON.stringify(data));
          navigate('/');
          window.location.reload();
        } else {
          // Handle error when response status is not successful
          console.error(`Failed to Select tenants. Status code: ${res.status}`);
          // You can set an error state or display an error message here.
        }
      })
      .catch(e => {
        console.error(e);
        setState(s => ({ ...s, loading: false, clickedTenant: null }));
      });
  };
  
  return (
    <>
      <div data-obj={orgDropdown} className="bg-white gap-2 relative bg-white">
        <h1 className="pl-1 flex font-bold sticky top-0 bg-white py-4">
          My Organisations{" "}
          {hasAdminAccess && (
            <AiFillPlusCircle
              size={"1.2rem"}
              className="relative left-2 top-1 cursor-pointer bg-black text-white rounded-full h-4 w-4"
              onClick={toggleOrg}
            />
          )}
        </h1>
        {tenants.map((tenant, index) => {
          let selected = user.currentTenantId === tenant.id;
          let loader = selected === false && clickedTenant === tenant.id && loading && (
            <LoadingSvg />
          );
          return (
            <div
              onClick={switchTenant.bind(this, tenant.id)}
              key={tenant.id}
              className={`${
                selected ? "bg-[whitesmoke]" : ""
              } hover:bg-[whitesmoke] text-[0.9rem] cursor-pointer flex items-center gap-[8px] p-2 pl-3 mb-1 rounded-[7px] [&_svg]:!text-[blue]`}>
              <p className="w-[100%] truncate whitespace-nowrap"> {tenant.name}</p>
              {selected && <FcApproval className="min-w-[16px] min-h-[16px]" />}
              {loader}
            </div>
          );
        })}
      </div>
    </>
  );
};

export default memo(OrgChange);
