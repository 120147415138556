import { useStore } from "../../context/createFastContext";

import { formattedDate } from './../../utils/common';

const ApproveModalComponent = () => {
  const [payrollState, setStore] = useStore((s) => s.payrollState);
  const payrollDetails = payrollState?.res?.payrollDetails;
  const utc_paydate = payrollDetails?.payDate;
  const formatted = formattedDate(utc_paydate);
  const split_date = formatted?.split(" ");
  const month = split_date?.[1];
  const year = split_date?.[2];
  const _formattedDate = new Date(utc_paydate).toLocaleDateString("en-GB", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });

  return (
    <div>
      {payrollDetails && (
        <div className="border border-transparent px-3 py-2 mt-3 bg-blue-100 rounded-lg">
          You are about to approve this payroll for{" "}
          <span className="text-md font-bold text-gray-600 dark:text-gray-800">
            {month}, {year}
          </span>
          . Once you approve it, you can make payments for all your employees on
          the paydate{" "}
          <span className="text-md font-bold text-gray-600 dark:text-gray-800">
            {_formattedDate}
          </span>
          .
        </div>
      )}
    </div>
  );
};

export default ApproveModalComponent;
