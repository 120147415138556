import { memo } from "react";

/**
 * Renders a rating bar component.
 *
 * @param {{className: string}} props - The props object.
 * @param {string} props.className - The class name for the rating bar, Main for changing the background Color.
 * @return {JSX.Element} The rating bar component.
 */
const RatingBar = ({className}) => {
  return <div className={`${className} h-2 w-6`}></div>;
};

export default RatingBar;
