import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";

import { addInvoice } from "../../../services/InvoiceService";
import { btnPrimary } from "../../../utils/helper";
import { InvoiceContext } from "../../../context/InvoiceContext";
import {
  createHeaderFieldItems,
  createItemsObj,
  toggleInitialState,
  allAmountInitialState,
  initialFormValues,
  shippingDetailsModal,
} from "../helper";
import { deleteInvoiceItemsById, updateInvoices } from "../../../services/InvoiceService";
import InvoiceSvg from "./../../../media/InvoiceSvg";
import toast from "react-hot-toast";

const SubmitButton = ({
  formValues,
  date,
  billed,
  allAmounts,
  setFormValues,
  setAllAmounts,
  setToggleState,
  toggleStates,
  isUpdating,
  invoiceId,
}) => {
  const navigate = useNavigate();

  const { isUpdateInvoiceList, setIsUpdateInvoiceList } = useContext(InvoiceContext);
  const [deleteInvoiceItemIds, setDeleteInvoiceItemIds] = useState([]);

  //Submitting invoice details
  const handleSubmitInvoiceData = async () => {
    let shippingDetailsObj = {};
    const {
      invoiceItems,
      headerField,
      shippingDetails,
      invoiceNumber,
      radioButtonValue,
      invoiceHeader,
    } = formValues;

    const items = createItemsObj(invoiceItems, isUpdating);

    const headerFieldItems = createHeaderFieldItems(headerField, isUpdating, invoiceId);

    //only send shipping details to backend if show shipping details checkbox is check
    if (toggleStates.showShippingDetails) {
      shippingDetailsObj = { ...shippingDetails };
    } else {
      shippingDetailsObj = shippingDetailsModal;
    }

    //change obj key name as the database
    let invoice = {
      invoiceHeader: invoiceHeader?.fieldName,
      invoiceNumber: invoiceNumber,
      invoiceDate: date.currentDate,
      dueDate: date.futureDate,
      customFields: headerFieldItems,
      billedBy: billed.billedById,
      billedTo: billed.billedToId,
      totalAmount: +allAmounts.amount + +allAmounts.igstAmount.toFixed(2),
      invoiceItems: items,
      isIntegratedTax: radioButtonValue === "igst" ? true : false,
      ...shippingDetailsObj,
      rate: +shippingDetailsObj?.rate,
      challanDate: shippingDetails?.challanDate === "" ? null : shippingDetails?.challanDate,
    };

    //if user is updating the invoice then add the invoiceId in the invoice Object
    if (isUpdating) {
      invoice = {
        id: +invoiceId,
        ...invoice,
      };
    }

    if (!invoice.billedBy || invoice.billedBy === "")
      return toast.error("Business Name can not be Empty!");
    if (!invoice.billedTo || invoice.billedTo === "")
      return toast.error("Client Name can not be Empty!");
    if (invoice.invoiceItems.length === 0) return toast.error("Invoice Item can not be Empty!");
    if (
      (toggleStates.showShippingDetails &&
        shippingDetails.sameAsBusinessAddress == false &&
        !invoice.shippedFrom) ||
      (toggleStates.showShippingDetails &&
        shippingDetails.sameAsClientAddress == false &&
        !invoice.shippedTo)
    )
      return toast.error("Shipping details are incomplete");

    if (invoice.invoiceItems.length > 0) {
      let count = 0;
      for (let i = 0; i < invoice.invoiceItems.length; i++) {
        if (invoice.invoiceItems[i].name !== "") count++;
      }

      if (count !== invoice.invoiceItems.length)
        return toast.error("Invoice Item Name can not be Empty!");
      if (toggleStates.invoiceNumberValidation) return toast.error("Invoice Number Already Exists");

      if (isUpdating) {
        const toastId = toast.loading("Updating Invoice");

        await updateInvoices(invoice);
        setIsUpdateInvoiceList(!isUpdateInvoiceList);
        setAllAmounts(allAmountInitialState);
        setFormValues(initialFormValues);

        const invoiceToDelete = deleteInvoiceItemIds.map(id => deleteInvoiceItemsById(id));
        if (deleteInvoiceItemIds.length === 0) {
          Promise.allSettled(invoiceToDelete);
          setDeleteInvoiceItemIds([]);
          toast.dismiss(toastId);
          toast.success("Invoice updated");
          navigate("/invoices");
          return;
        }
      }

      const toastId = toast.loading("Creating Invoice");
      const res = await addInvoice(invoice);
      if (res.data.statusCode === 200) {
        setIsUpdateInvoiceList(!isUpdateInvoiceList);
        setFormValues(initialFormValues);
        setAllAmounts(allAmountInitialState);
        setToggleState(toggleInitialState);
        toast.dismiss(toastId);
        toast.success("Invoice Created");
        navigate("/invoices");
      }
    }
  };

  return (
    <div className="mb-6 mt-6 flex items-center justify-end">
      <button
        size="xl"
        onClick={handleSubmitInvoiceData}
        className={`${btnPrimary()} px-4 !py-2 rounded-md text-white font-semibold tracking-wide flex items-center gap-2`}>
        <span>
          <InvoiceSvg />
        </span>
        <span>{isUpdating ? "Update" : "Create"} Invoice</span>
      </button>
    </div>
  );
};

export default SubmitButton;
