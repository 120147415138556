import React from "react";
import { format, getDay, isEqual, isToday, isSameMonth } from "date-fns";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import classNames from "classnames";

function Calendar({
  currentMonth,
  previousMonth,
  nextMonth,
  days,
  colStartClasses,
  selectedDay,
  setSelectedDay,
  showAllData,
  events = [],
  setShowAllData,
  others,
  indicatorConditions
}) {
  const firstDayCurrentMonth = currentMonth;

  return (
    <div>
      <div className="flex items-center">
        <h2 className="flex-auto font-semibold text-gray-900">
          {format(currentMonth, "MMMM yyyy")}
        </h2>
        <button
          type="button"
          onClick={previousMonth}
          className="-my-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500">
          <span className="sr-only">Previous month</span>
          <FaChevronLeft className="w-5 h-5" aria-hidden="true" />
        </button>
        <button
          onClick={nextMonth}
          type="button"
          className="-my-1.5 -mr-1.5 ml-2 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500">
          <span className="sr-only">Next month</span>
          <FaChevronRight className="w-5 h-5" aria-hidden="true" />
        </button>
      </div>
      <div className="grid grid-cols-7 mt-5 text-xs leading-6 text-center text-gray-500">
        <div>S</div>
        <div>M</div>
        <div>T</div>
        <div>W</div>
        <div>T</div>
        <div>F</div>
        <div>S</div>
      </div>
      <div className="grid grid-cols-7 text-sm">
        {days.map((day, dayIdx) => (
          <div
            key={day.toString()}
            className={classNames(dayIdx === 0 && colStartClasses[getDay(day)], "py-0.5")}>
            <button
              type="button"
              onClick={() => {
                if (showAllData) setShowAllData(false);
                setSelectedDay(day);
              }}
              className={classNames(
                isEqual(day, selectedDay) && "text-white",
                !isEqual(day, selectedDay) && isToday(day) && "text-red-500",
                !isEqual(day, selectedDay) &&
                  !isToday(day) &&
                  isSameMonth(day, firstDayCurrentMonth) &&
                  "text-gray-900",
                !isEqual(day, selectedDay) &&
                  !isToday(day) &&
                  !isSameMonth(day, firstDayCurrentMonth) &&
                  "text-gray-400",
                isEqual(day, selectedDay) && isToday(day) && "bg-red-500",
                isEqual(day, selectedDay) && !isToday(day) && "bg-gray-900",
                !isEqual(day, selectedDay) && "hover:bg-gray-200",
                (isEqual(day, selectedDay) || isToday(day)) && "font-semibold",
                "mx-auto flex h-8 w-8 items-center justify-center rounded-full"
              )}>
              <time dateTime={format(day, "yyyy-MM-dd")}>{format(day, "d")}</time>
            </button>
            {indicatorConditions(events, day)}
          </div>
        ))}
      </div>
      {others}
    </div>
  );
}

export default Calendar;
