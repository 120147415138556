import React from "react";
import { format } from "date-fns";
import DeleteSvg from "../../../media/DeleteSvg";
import Table from "../../common/Table/Table";
import TableHeader from "../../common/Table/TableHeader";
import { cn } from "../../../utils/common";
import TableRows from "../../common/Table/TableRows";
import TableData from "../../common/Table/TableData";

const TimeLogs = ({
  user,
  TimeLogHeading,
  duration,
  handleDeleteAttendance,
  TimeLogApprovalStatus,
}) => {
  return (
    <>
      <Table parentClass={cn("flex justify-end pl-[15rem]")}>
        <TableHeader
          cols={TimeLogHeading}
          styles={{
            tableHead: cn("text-xs uppercase bg-white text-gray-700"),
            tableRow: "",
            tableCol: "",
          }}
        />
        <tbody>
          {user?.timeLogs
            ?.sort(
              (b, a) =>
                new Date(`2000-01-01T${a.startTime}Z`) - new Date(`2000-01-01T${b.startTime}Z`)
            )
            ?.map(timeLog => (
              <TableRows key={timeLog.id}>
                <TableData className="px-4 py-2 whitespace-nowrap">{timeLog?.job?.name}</TableData>
                <TableData className="px-4 py-2 whitespace-nowrap">
                  {format(new Date(`2000-01-01T${timeLog?.startTime}Z`), "h:mm a")}
                </TableData>
                <TableData className="px-4 py-2 whitespace-nowrap">
                  {timeLog?.endTime == null && "00:00"}
                  {timeLog?.endTime != null &&
                    format(new Date(`2000-01-01T${timeLog?.endTime}Z`), "h:mm a")}
                </TableData>
                <TableData className="px-4 py-2 whitespace-nowrap">{}</TableData>
                <TableData className="px-4 py-2 whitespace-nowrap">
                  {timeLog?.totalHours && timeLog.totalHours.slice(0, 5)}
                </TableData>
                <TableData className="px-4 py-2 whitespace-nowrap">
                  <div
                    className={`flex items-center pl-4 h-7 ${
                      timeLog?.timeLogApprovalStatus === TimeLogApprovalStatus.Approved
                        ? "text-green-800 bg-green-100 rounded-lg -ml-2 mr-2"
                        : timeLog?.timeLogApprovalStatus === TimeLogApprovalStatus.Rejected
                          ? "text-red-800 bg-red-100 rounded-lg -ml-2 mr-2"
                          : timeLog?.timeLogApprovalStatus === TimeLogApprovalStatus.Pending
                            ? "text-thynkwebPrimary-700 bg-thynkwebPrimary-50 rounded-lg -ml-2 mr-2"
                            : null
                    }`}>
                    <div
                      className={`w-3 h-3 mr-2 ${
                        timeLog?.timeLogApprovalStatus === TimeLogApprovalStatus.Approved
                          ? "bg-green-500"
                          : timeLog?.timeLogApprovalStatus === TimeLogApprovalStatus.Rejected
                            ? "bg-red-500"
                            : timeLog?.timeLogApprovalStatus === TimeLogApprovalStatus.Pending
                              ? "bg-thynkwebPrimary-500"
                              : null
                      } border rounded-full`}></div>
                    <div className="flex items-center">
                      {timeLog?.timeLogApprovalStatus === TimeLogApprovalStatus.Approved ? (
                        <span className="text-green-500">Approved</span>
                      ) : timeLog?.timeLogApprovalStatus === TimeLogApprovalStatus.Rejected ? (
                        <span className="text-red-500">Rejected</span>
                      ) : timeLog?.timeLogApprovalStatus === TimeLogApprovalStatus.Pending ? (
                        <span className="text-thynkwebPrimary-500">Pending</span>
                      ) : null}
                    </div>
                  </div>
                </TableData>
                <TableData className="px-4 py-2 dark:text-white">
                  <div className="flex gap-3">
                    <div
                      data-modal-target="popup-modal"
                      data-modal-toggle="popup-modal"
                      className="cursor-pointer hover:text-red-400">
                      <DeleteSvg
                        className={"h-5"}
                        onClick={handleDeleteAttendance.bind(this, timeLog)}
                      />
                    </div>
                  </div>
                </TableData>
              </TableRows>
            ))}
        </tbody>
      </Table>
    </>
  );
};

export default TimeLogs;
