import { axiosInstance } from "../context/AuthContext";
const BaseUrl = process.env.REACT_APP_BASE_URL;
const accountBaseUrl = `${BaseUrl}/Account`;

//Verifying user details
export const verifyUserDetails = async userInfo => {
  const response = await axiosInstance.post(`${accountBaseUrl}/verify-user`, userInfo);
  return response;
};

export const retrieveTenants = async () => {
  const response = await axiosInstance.get(`${accountBaseUrl}/tenants`);
  return response;
};

export const selectTenant = async id => {
  const response = await axiosInstance.post(`${accountBaseUrl}/select-tenant?tenantId=${id}`);
  return response;
};

//Function to handle API call for Resend Verification Email
export const resendVerificationEmail = async userId => {
  const response = await axiosInstance.post(`${accountBaseUrl}/resend-invite?userId=${userId}`);
  return response;
};
