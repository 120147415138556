import { useEffect, useState, memo } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { resetActiveTab, setActiveTab } from "../store/slices/profileSlice";

import { showToast } from "../components/common/Toast";
import { useStore } from "../context/createFastContext";
import { Footer } from "../components/common/Footer";
import Tabs from "../components/Profile/Tabs";
import ProfileCard from "../components/Profile/ProfileCard";

import { getUser } from "../utils/common";
import { PROFILE_TABS_ADMIN, PROFILE_TABS_USER } from "../constants/Constant";
import { GetUserById, addUsersImage, deleteUserImage } from "../services/UserService";
import { capitalizeFirstLetter } from "../components/common/helper";
import { getUserSkills, getAllSkillsOptions } from "../store/slices/employment";
import { Role } from "../enum/Role";

const commonStyle =
  "flex gap-4 items-center px-4 rounded-md py-2 font-semibold !text-left transition ease-in-out duration-[250ms]";
const activeStyle = `${commonStyle} !bg-[#bedbfe] hover:!bg-[#9ec7f7] focus:!bg-[#9ec7f7] !text-[#000] border-none`;
const inActiveStyle = `${commonStyle} border-none bg-transparent`;

export const Profile = memo(() => {
  const profile = useSelector(state => state.profile);
  const { activeTab } = profile.tabs;
  const { password } = profile;
  const dispatch = useDispatch();
  const [user, setUser] = useState();
  const [searchParams, setSearchParams] = useSearchParams();
  const userId = searchParams.get("userId");
  const navigate = useNavigate();

  const [selectedImage, setSelectedImage] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const User = getUser();
  const [_, setStore] = useStore(s => s.userInfoById);

  useEffect(() => {
    fetchUser();
    dispatch(resetActiveTab());
  }, [userId, selectedImage]);

  useEffect(() => {
    dispatch(getUserSkills(userId));
    dispatch(getAllSkillsOptions());
  }, []);

  const fetchUser = () => {
    GetUserById(userId || User?.id).then(response => {
      if (response?.status === 200) {
        if (response?.data?.imageBytes) {
          const imgURL = `data:image/jpeg;base64,${response?.data?.imageBytes}`;
          setSelectedImage(imgURL);
        }
        setUser(response?.data);
      } else {
        navigate("/403-Forbidden");
      }
    });
  };

  const handleImageChange = async (event, user) => {
    // Trigger the progress reset after 3 seconds
    triggerProgressReset();

    const selectedImgFile = event.target.files[0];

    if (
      selectedImgFile &&
      (selectedImgFile.type === "image/png" ||
        selectedImgFile.type === "image/jpeg" ||
        selectedImgFile.type === "image/jpg")
    ) {
      // Clearing the file input value to allow selecting the same file again
      event.target.value = "";

      // Axios request to upload the image with progress tracking
      await addUsersImage(User, selectedImgFile, setUploadProgress)
        .then(response => {
          if (response.message === "Success!" && response.statusCode === 200) {
            GetUserById(User?.id).then(response => {
              const imgURL = `data:image/jpeg;base64,${response?.data?.imageBytes}`;
              setSelectedImage(imgURL);
              setStore({
                userInfoById: response?.data,
              });
            });

            showToast("success", "Image uploaded successfully");
          } else {
            console.error("Empty or undefined response received");
          }
        })
        .catch(error => {
          console.error("Error uploading image", error);
          showToast("failure", "Error uploading image");
        });
    } else {
      setSelectedImage(null);

      showToast("info", "Please select a PNG or JPEG/JPG file.");
    }
  };
  // Function to reset the upload progress
  const resetUploadProgress = () => {
    setUploadProgress(0);
  };

  // Function to trigger progress reset after 3 seconds
  const triggerProgressReset = () => {
    setTimeout(resetUploadProgress, 3000);
  };

  const handleDeleteUserImage = () => {
    deleteUserImage().then(response => {
      const message = response?.data?.message;
      const statusCode = response?.data?.statusCode;
      if (message === "Success!" && statusCode === 200) {
        GetUserById(User?.id).then(response => {
          setSelectedImage(null);
          setStore({
            userInfoById: response?.data,
          });
        });
        showToast("success", "Image deleted successfully");
      }
    });
  };

  return (
    <div>
      {user && (
        <div className="grid grid-cols-1 px-4 pt-6 md:grid-cols-[minmax(16rem,_16rem)_1fr_1fr] md:gap-4 bg-slate-50">
          <div className="col-span-1">
            <ProfileCard
              handleDeleteUserImage={handleDeleteUserImage}
              user={user}
              loggedInUser={User}
              selectedImage={selectedImage}
              uploadProgress={uploadProgress}
              handleImageChange={handleImageChange}
            />

            <div className="flex flex-col gap-2 items-stretch justify-center my-4 md:my-0">
              {user?.roleId === Role.Administrator &&
                PROFILE_TABS_ADMIN.map((tab, i) => (
                  <button
                    key={tab.name}
                    className={
                      tab.name === activeTab
                        ? `${i === PROFILE_TABS_ADMIN.length - 1 && "mb-3"} ${activeStyle}`
                        : `${i === PROFILE_TABS_ADMIN.length - 1 && "mb-3"} ${inActiveStyle}`
                    }
                    onClick={() => dispatch(setActiveTab(tab.name))}>
                    <div>{tab.icon}</div>
                    <span>{capitalizeFirstLetter(tab.name)}</span>
                  </button>
                ))}
              {user.roleId === Role.Member &&
                PROFILE_TABS_USER.map((tab, i) => (
                  <button
                    key={tab.name}
                    className={
                      tab.name === activeTab
                        ? `${i === PROFILE_TABS_USER.length - 1 && "mb-3"} ${activeStyle}`
                        : `${i === PROFILE_TABS_USER.length - 1 && "mb-3"} ${inActiveStyle}`
                    }
                    onClick={() => dispatch(setActiveTab(tab.name))}>
                    <div>{tab.icon}</div>
                    <span>{capitalizeFirstLetter(tab.name)}</span>
                  </button>
                ))}
            </div>
          </div>
          <Tabs user={user} />
        </div>
      )}
      <Footer />
    </div>
  );
});
