// IMPORTANT : DO NOT CHANGE FOLLOWING VALUES
export const ITDeductionSection = {
    Section80C: 0,
    Section80D: 3,
    Section80DD: 5,
    Section80DDB: 6
};

export const ITDeductionSectionLabels = {
    [ITDeductionSection.Section80C]: 'Section 80C',
    [ITDeductionSection.Section80D]: 'Section 80D',
    [ITDeductionSection.Section80DD]: 'Section 80DD',
    [ITDeductionSection.Section80DDB]: 'Section 80DDB',
};