import { useCallback, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  approxTime_Badge,
  backgroundColor,
  currentPage,
  defaultClassName,
  dotClassName,
  isFirstHalf_SecondHalf,
  onPageChange,
  options,
  pagesize,
  setDefaultValue,
  setIsDefaultValue,
  totalPages,
} from "../../pages/LeaveManagement/helper";

import { DEFAULT_PAGE_NO, DEFAULT_PAGE_SIZE, STATUS_CODES } from "../../constants/Constant";
import { TimestampInfo, approx_time_show } from "../../utils/common";
import {
  GetCurrentUserLeaves,
  GetUserLeaves_UnderCurrentUser,
  getLeaveById,
  statusOfLeaves,
} from "../../services/LeaveService";
import { ApplyLeaveModal } from "../../pages/LeaveManagement/ApplyLeaveModal";
import SelectWithDropdown from "../../components/common/SelectWithDropdown/SelectWithDropdown";

import {
  ReportingUsers,
  proTip,
  statusOptions,
  statusOptionsForNonAdmin,
  statusOptionsForUser,
} from "../../pages/LeaveManagement/LeaveCommon";
import { showToast } from "../../components/common/Toast";
import { Tooltip } from "react-tooltip";
import { LeaveStatus } from "../../enum/LeaveStatus";
import { GetReportingUsers, GetUserById } from "../../services/UserService";
import { formatDate } from "../../utils/helper";

import ProTipSvg from "../../media/ProtipSvg";
import { Role } from "../../enum/Role";
import { useSearchParams } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { myLeavesTableHeading } from "./helper";
import CardViewLeaves from "./CardViewLeaves";
import { IoMdLogOut } from "react-icons/io";
import MemoizedNoItemFound from "../NoItemFound/Noitemfound";
import { getAssociatedLeaves } from "../../store/slices/leavesSlice";
import usePagination, { PaginationComponent } from "../../hooks/usePagination";
import PageSection from "../Layout/PageSection/PageSection";
import AddLeaveTypeLists from "./LeaveTypes/AddLeaveTypeLists";
import Table from "../common/Table/Table";
import TableHeader from "../common/Table/TableHeader";
import TableRows from "../common/Table/TableRows";
import TableData from "../common/Table/TableData";

export const MyLeaves = () => {
  const dispatch = useDispatch();
  const [leavesResponse, setLeavesResponse] = useState();
  const [leaves, setLeaves] = useState([]);
  const [leaveIds, setLeaveIds] = useState([]);
  const [renderLeaveManagement, setRenderLeaveManagement] = useState(false);
  const [roleId, setRoleId] = useState();
  const [userId, setUserId] = useState();
  const [radioSelection, setRadioSelection] = useState("myLeaves");
  const [reportingUsers, setReportingUsers] = useState([]);
  const [modifiedReportingUsers, setModifiedReportingUsers] = useState();
  const [selectedUser, setSelectedUser] = useState();
  const [leaveModal, setLeaveModal] = useState(false);
  const [notificationLeave, setNotificationLeave] = useState({});
  const [isChange, setIsChange] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();
  const { user: currentUser } = useContext(AuthContext);

  const paramLeavesId = searchParams.get("leaveId");
  const paramUserId = searchParams.get("userId");

  const { pageSize, pageNo, totalPages } = useSelector(state => state.pagination);
  const [totalRecords, setTotalRecords] = useState(0);
  const { setPageSizeHandler, onPageChange } = usePagination(false, totalRecords);

  const getReportingUsers = async () => {
    await GetReportingUsers("", false, pageSize, pageNo).then(response => {
      setReportingUsers(response?.data?.data);
    });
  };

  useEffect(() => {
    fetchLeaves(pageNo, pageSize);
  }, [pageNo, pageSize, radioSelection, renderLeaveManagement]);

  useEffect(() => {
    getReportingUsers();
    if (currentUser?.id) {
      dispatch(getAssociatedLeaves(currentUser.id));
    }
  }, []);

  useEffect(() => {
    if (reportingUsers?.length > 0) {
      const modified = ReportingUsers(reportingUsers);
      setModifiedReportingUsers(modified);
    }
  }, [reportingUsers]);

  useEffect(() => {
    //if search params exist
    if (paramLeavesId && paramUserId && +paramUserId !== currentUser.id) {
      GetUserById(paramUserId).then(res => {
        if (res?.status === STATUS_CODES.SUCCESS) {
          const { firstName, lastName } = res.data;
          setSelectedUser({ value: +paramUserId, label: `${firstName} ${lastName}` });
          // myApprovalsResponse(+paramUserId);
          handleMyApprovals({ value: +paramUserId, label: `${firstName} ${lastName}` });
        }
      });

      //get leave by id
      getLeaveById(paramLeavesId).then(res => {
        if (res.data.status === LeaveStatus.WaitingForApproval) setLeaveModal(true);
        setNotificationLeave(res.data);
      });
    } else setRadioSelection("myLeaves");
  }, [searchParams.get("leaveId"), searchParams.get("userId")]);

  useEffect(() => {
    // Setting up a new interval
    const newIntervalId = setInterval(() => {
      updateLeavesTimeDifference();
    }, 30000); // 30 seconds difference

    return () => {
      clearInterval(newIntervalId); // Clearing interval on component unmount or when interval is reset
    };
  }, [leaves]);

  useEffect(() => {
    if (leaveIds.length > 0) {
      LeaveStatus_Employee(leaveIds);
    }
  }, [leaveIds]);

  const fetchLeaves = async (pageNo, pageSize) => {
    const loggedInUser = JSON.parse(sessionStorage.getItem("user"));
    setRoleId(loggedInUser.roleId);
    setUserId(loggedInUser.id);

    // Checking for selected radio button and setting data according to it.
    if (radioSelection === "myLeaves") {
      const myLeavesResponse = await GetCurrentUserLeaves(pageNo, pageSize, false);
      setLeavesResponse(myLeavesResponse);
      const myLeaves = myLeavesResponse?.data;
      setLeaves(myLeaves);
      setSelectedUser();
      setTotalRecords(myLeavesResponse?.totalRecords);
    }
  };

  // Function to set the page size and page number
  const setPage = e => {
    if (radioSelection === "myLeaves") {
      setPageSize(e.target.value);
      if (Math.ceil(leavesResponse?.totalRecords / pageSize) > 1) {
        setPageNo(DEFAULT_PAGE_NO);
      }
    }
  };

  const dateTime = (dateTime, month_Date_Year) => {
    const [dateTimeObj, month, date, year] = TimestampInfo(dateTime);

    const dateTimeMonth = (month + 1).toLocaleString("en-US", {
      minimumIntegerDigits: 2,
    });
    const dateTimeMonthInShort = dateTimeObj.toLocaleString("default", {
      month: "short",
    });
    const dateTimeDate = date.toLocaleString("en-US", {
      minimumIntegerDigits: 2,
    });
    const dateTimeYear = year;

    if (month_Date_Year) {
      return dateTimeMonth + "/" + dateTimeDate + "/" + dateTimeYear;
    }
    return dateTimeMonthInShort + " " + dateTimeDate;
  };

  const updateLeavesTimeDifference = () => {
    const updatedLeaves = leaves.map(user => {
      return {
        ...user,
        timeDifference: approx_time_show(user.dateCreated),
      };
    });
    setLeaves(updatedLeaves);
  };

  // Leave status for employee
  const LeaveStatus_Employee = useCallback(
    async leaveIds => {
      await statusOfLeaves(leaveIds)
        .then(response => {
          if (
            response.data?.message === "Success!" &&
            response.data?.statusCode === STATUS_CODES.SUCCESS
          ) {
            showToast("success", `Leave ${leaveIds[0].status}!`);
            setLeaveIds([]);
            return;
          }
        })
        .catch(err => console.error(err));
    },
    [leaveIds]
  );

  //Function to handle changes in select dropdown.
  const handleSelectChange = async (leaveId, selectedOption) => {
    setLeaves(prevLeaves =>
      prevLeaves.map(leave => {
        if (leave.id === leaveId) {
          return {
            ...leave,
            status: selectedOption.value,
          };
        }
        return leave;
      })
    );
    setLeaveIds([{ leaveId: leaveId, status: selectedOption.label }]);
  };

  const handleMyApprovals = async selectedOption => {
    const myApprovalsResponse = await GetUserLeaves_UnderCurrentUser(
      pageNo,
      pageSize,
      true,
      selectedOption.value || paramUserId
    );
    const filterCancelledLeaves = myApprovalsResponse?.data?.filter(
      leave => leave.status !== LeaveStatus.Cancelled
    );
    setLeavesResponse(myApprovalsResponse);
    setSelectedUser(selectedOption);

    const myApprovals = filterCancelledLeaves;
    setLeaves(myApprovals);
    setTotalRecords(myApprovalsResponse?.totalRecords);
  };

  const handleRenderLeaveManagement = () => {
    setRenderLeaveManagement(prevState => !prevState);
  };

  return (
    <>
      <PageSection
        heading="My Leaves"
        icon={<IoMdLogOut size={"1.4rem"} />}
        showIcon={true}
        button={
          <ApplyLeaveModal
            pageNo={pageNo}
            pageSize={pageSize}
            setRenderLeaveManagement={handleRenderLeaveManagement}
          />
        }>
        <div className=" bg-[#eeeeee] min-h-[12rem]">
          <CardViewLeaves />
        </div>
        <div className="w-full">
          <AddLeaveTypeLists
            leaves={leaves}
            dateTime={dateTime}
            roleId={roleId}
            userId={userId}
            radioSelection={radioSelection}
            handleSelectChange={handleSelectChange}
          />

          <div className="flex justify-center">
            <MemoizedNoItemFound
              itemsArr={leaves}
              colSpan={myLeavesTableHeading.length}
              isTable={true}
              message="No Leaves Found"
            />
          </div>

          <div className="flex justify-center">
            {radioSelection === "myLeaves" && leaves.length > 0 && (
              <tr>
                <td
                  colSpan={7}
                  className="tracking-wide text-[#6b7280] py-8 text-[16px] text-center">
                  <span className="inline-block pr-1">
                    <ProTipSvg />
                  </span>
                  <span className="text-gray-900">ProTip!</span>{" "}
                  {proTip(roleId, Role, radioSelection)}
                </td>
              </tr>
            )}
          </div>
        </div>

        {(radioSelection === "myLeaves" || selectedUser) && (
          <PaginationComponent
            onChange={setPageSizeHandler}
            onPageChange={onPageChange}
            showIcons={true}
            totalRecords={totalRecords}
          />
        )}
      </PageSection>
    </>
  );
};
