import { axiosInstance as axios } from "../context/AuthContext";

const BASE_NOTIFICATION_URL = process.env.REACT_APP_BASE_URL;
const NOTIFICATION_URL = `${BASE_NOTIFICATION_URL}/notifications`;

/**
 * Retrieves the count of unread notifications.
 *
 * @return {Promise<number>} The count of unread notifications.
 */
export const getNotificationCount = async function () {
  const res = await axios.get(`${NOTIFICATION_URL}/unread/count`);
  return res.data;
};

/**
 * Retrieves notifications from the server.
 *
 * @param {number} pageNo - The page number to retrieve.
 * @return {Promise} - A promise that resolves with the notifications data.
 */
export const getNotifications = async function (pageNo) {
  const res = await axios.get(`${NOTIFICATION_URL}?pageNo=${pageNo}`);
  return res.data;
};

/**
 * Marks a notification as read.
 *
 * @return {Promise} A promise that resolves once the notification is marked as read.
 */
export const markNotificationAsReadService = async function () {
  const res = await axios.post(`${NOTIFICATION_URL}/read`);
  return res.data;
};
