import { UpdateTenantDetails } from "../../services/OrganizationDetailsService";
import { v4 } from "uuid";
import { trimValues } from "../../utils/helper";

export const updatableFields = ["address", "tan", "pan", "gstNumber", "tenantName"];

export const financialDetailsInputFieldOptions = [
  {
    heading: "Tenant Name",
    options: {
      label: "Tenant Name",
      type: "text",
      name: updatableFields[updatableFields.length - 1],
      id: v4(),
      placeholder: "Enter Name of Tenant",
      required: false,
    },
  },
  {
    heading: "Address",
    options: {
      label: "Address",
      type: "text",
      name: updatableFields[0],
      id: v4(),
      placeholder: "Enter Address",
      required: false,
    },
  },
  {
    heading: "TAN",
    options: {
      type: "text",
      name: updatableFields[1],
      id: v4(),
      placeholder: "Enter TAN number",
      required: false,
    },
  },
  {
    heading: "PAN",
    options: {
      type: "text",
      name: updatableFields[2],
      id: v4(),
      placeholder: "Enter PAN number",
      required: false,
    },
  },
  {
    heading: "GST Number",
    options: {
      type: "text",
      name: updatableFields[3],
      id: v4(),
      placeholder: "Enter GST number",
      required: false,
    },
  },
];

export function updateData(details, organizationState, setStore) {
  const trimmedValues = trimValues(details);

  UpdateTenantDetails(trimmedValues).then(res => {
    setStore({
      organizationState: {
        ...organizationState,
        reFetchTenant: !organizationState.reFetchTenant,
      },
    });
  });
}
