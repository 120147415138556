import { FaRupeeSign } from "react-icons/fa";

/**
 * Renders the total amount section of the component.
 *
 * @param {{allAmounts: object, radioButtonValue: string}} props - The properties of the total amount section.
 * @param {Object} allAmounts - The object containing all the amounts.
 * @param {string} radioButtonValue - The value of the radio button.
 * @return {JSX.Element} The JSX element representing the total amount section.
 */
const Total = ({ allAmounts, radioButtonValue }) => {
  return (
    <div className="mt-5 w-[100%] ml-[auto] md:w-[42%] h-full border">
      {/* amount div */}
      <div className="flex justify-between w-full">
        <div className="text-lg  p-3 pr-0">Amount</div>
        <div className="flex p-3 ">
          <span className="mt-1">
            <FaRupeeSign fontSize={15} />
          </span>
          <span className="font-medium">{allAmounts.amount.toFixed(2)}</span>
        </div>
      </div>

      {/* IGST-div */}
      {radioButtonValue === "igst" && (
        <div className="flex justify-between w-full">
          <p className="text-xl  p-3 pr-0">IGST</p>
          <div className="flex items-center p-3">
            <FaRupeeSign fontSize={15} />

            <span className="font-medium ">{allAmounts.igstAmount.toFixed(2)}</span>
          </div>
        </div>
      )}
      {radioButtonValue !== "igst" && (
        <>
          {/* sgst-div */}
          <div className="flex justify-between w-full">
            <div className="text-xl  p-3 pr-0">CGST</div>
            <div className="flex p-3">
              <span className="mt-1">
                <FaRupeeSign fontSize={15} />
              </span>
              <span className="font-medium ">{allAmounts.cgstAmount.toFixed(2)}</span>
            </div>
          </div>
          {/* cgst-div */}
          <div className="flex justify-between w-full">
            <div className="text-xl  p-3 pr-0">SGST</div>
            <div className="flex p-3">
              <span className="mt-1">
                <FaRupeeSign fontSize={15} />
              </span>
              <span className="font-medium ">{allAmounts.sgstAmount.toFixed(2)}</span>
            </div>
          </div>
        </>
      )}

      <hr className="w-full h-1 mx-auto  bg-gray-300 border-0 rounded md:mt-5 dark:bg-gray-700" />
      {/* total-div */}
      {radioButtonValue === "igst" && (
        <div className="flex justify-between w-full ">
          <div className="text-2xl font-semibold p-3 pr-0">Total (INR)</div>
          <div className="flex p-3">
            <span className=" text-xl mt-2 font-semibold  ">
              <FaRupeeSign />
            </span>
            <span className="text-2xl  font-semibold ">
              {+(allAmounts.amount + +allAmounts.igstAmount).toFixed(2)}
            </span>
          </div>
        </div>
      )}
      {radioButtonValue !== "igst" && (
        <div className="flex justify-between w-full ">
          <div className="text-2xl font-semibold p-3 pr-0">Total (INR)</div>
          <div className="flex items-center p-3">
            <FaRupeeSign fontSize={18} />
            <span className="text-2xl  font-semibold ">
              {allAmounts.amount + +(allAmounts.cgstAmount + allAmounts.sgstAmount).toFixed(2)}
            </span>
          </div>
        </div>
      )}

      <hr className=" mt-0 w-full h-1 mx-auto my-2 bg-gray-300 border-0 rounded md:mb-5 dark:bg-gray-700" />
    </div>
  );
};

export default Total;
