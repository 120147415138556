import Select from "react-select";
import { customStyles } from "../../Settings/helper/helper";

/**
 * Generates a function comment for the given function body.
 *
 * @param {{options: Array, defaultValue: Array, onChange: function, id: string, isMulti: boolean, styles: {valueContainer: object, control: object, dropdownIndicator: object, clearIndicator: object}}} props - The props for the Select component.
 * @param {string} id - The ID of the Select component.
 * @param {Array} defaultValue - The default value of the Select component.
 * @param {{value: string, label: string}} value - The value of the Select component.
 * @param {function} onChange - The change event handler for the Select component an arguments is passed by default `SelectOption`.
 * @param {Array} options - The options for the Select component.
 * @param {boolean} isMulti - Indicates whether the Select component supports multiple selections.
 * @param {boolean} isDisabled - Indicates whether the Select component is disabled.
 * @param {{valueContainer: Object, control: Object, dropdownIndicator: Object, clearIndicator: Object}} styles - The styles for the Select component.
 * @param {object} styles.valueContainer - The style for the value container of the Select component.
 * @param {object} styles.control - The style for the control of the Select component.
 * @param {object} styles.dropdownIndicator - The style for the dropdown indicator of the Select component.
 * @param {object} styles.clearIndicator - The style for the clear indicator of the Select component.
 * @returns {JSX.Element} The rendered Select component.
 */
const MultiSelect = ({ id, defaultValue, value, onChange, options, isDisabled, isMulti, styles }) => {
  return (
    <>
      {options?.length > 0 && (
        <Select
          id={id}
          defaultValue={defaultValue}
          value={value}
          onChange={onChange}
          isSearchable={true}
          options={options}
          isDisabled={isDisabled}
          isMulti={isMulti}
          styles={customStyles(
            styles?.valueContainer,
            styles?.control,
            styles?.dropdownIndicator,
            styles?.clearIndicator
          )}
        />
      )}
      {options?.length === 0 && (
        <Select
          id={id}
          defaultValue={[]}
          onChange={onChange}
          options={options}
          isDisabled={isDisabled}
          isMulti={isMulti}
          styles={customStyles(
            styles?.valueContainer,
            styles?.control,
            styles?.dropdownIndicator,
            styles?.clearIndicator
          )}
        />
      )}
    </>
  );
};

export default MultiSelect;
