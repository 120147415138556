import { useContext, useEffect, useState } from "react";
import { createPortal } from "react-dom";
import AllRoutes from "./Routes/AllRoutes";
import LoadingSpinner from "./utils/LoadingSpinner";
import { AuthContext } from "./context/AuthContext";
import "./App.css";
import "./styles/main.scss";
import MyAttendanceSetting from "./components/Attendance/MyAttendanceSetting/MyAttendanceSetting";
import ControlPanelSettings from "./components/Settings/controlPanelSettings/ControlPanelSettings";
import { useAppDispatch, useAppSelector } from "./store/store";
import { toggleGlobalSettingsModal } from "./store/slices/settingsSlice";
import ConnectionError from "./components/common/ConnectionError";
import {  useSelector } from "react-redux";
import { setOnlineStatus } from "./store/slices/onlineStatusSlice";

function App() {
  const environment = process.env.REACT_APP_ENVIRONMENT;

  const dispatch = useAppDispatch();
  const { loading } = useContext(AuthContext);

  // Getting global settings state from redux
  const settings = useAppSelector(state => state.settings);

  // Function to close global settings modal
  const closeControlPanelSettingsModal = () => {
    dispatch(toggleGlobalSettingsModal(false));
  };

  const updateOnlineStatus = () => {
    dispatch(setOnlineStatus(navigator.onLine));
  };

  useEffect(() => {
    window.addEventListener("online", updateOnlineStatus);
    window.addEventListener("offline", updateOnlineStatus);

    // Dispatch the initial status
    dispatch(setOnlineStatus(navigator.onLine));

    return () => {
      window.removeEventListener("online", updateOnlineStatus);
      window.removeEventListener("offline", updateOnlineStatus);
    };
  }, [dispatch]);

  const isOnline = useSelector(state => state.onlineStatus.isOnline);

  return (
    <div className="App">
      {!isOnline && environment !== "local" && <ConnectionError />}
      {loading && <LoadingSpinner />}
      {(isOnline || environment === "local") && <AllRoutes />}

      {createPortal(<MyAttendanceSetting />, document.body)}

      {createPortal(
        <ControlPanelSettings
          show={settings.showGlobalSettingsModal}
          onClose={closeControlPanelSettingsModal}
        />,
        document.body
      )}
    </div>
  );
}

export default App;
