import React, { useEffect, useState, useContext } from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJs,
  LineElement,
  CategoryScale, // x-axis
  LinearScale, // y-axis
  PointElement,
  Legend,
  Tooltip,
  Filler,
} from "chart.js";
import {
  chartData,
  chartOptions,
  insightsCardTitle,
  payRunInfo,
  payrollStatus,
} from "./PayrollCommon";
import { getPayrollInsights, runPayroll } from "../../services/PayrollService";
import { useStore } from "../../context/createFastContext";
import { PayrollStatus } from "../../enum/PayrollStatus";
import { useNavigate } from "react-router-dom";
import CardComponent from "../common/Card/Card";
import { formattedAmount, formattedDate } from "../../utils/common";
import styles from "./Payroll.module.css";
import { MONTHS } from "../../constants/Constant";
import { AuthContext } from "../../context/AuthContext";
import { showToast } from "../common/Toast";
import ProtipSvg from "../../media/ProtipSvg";
import { MdOutlineScheduleSend } from "react-icons/md";
import PageSection from "../Layout/PageSection/PageSection";

ChartJs.register(LineElement, CategoryScale, LinearScale, PointElement, Legend, Tooltip, Filler);

const PayrollRun = () => {
  const [payrollState, setStore] = useStore(s => s.payrollState);
  const [fetched, setIsFetched] = useState(false);
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();

  const payrollInsights = async () => {
    await getPayrollInsights()?.then(response => {
      const employeeCount = response?.data?.lastPayrollDetails?.employeeCount;
      const paidEmployeeCount = response?.data?.lastPayrollDetails?.paidEmployeeCount;
      const paidProgress = (paidEmployeeCount / employeeCount) * 100;
      const unpaidProgress = 100 - paidProgress;

      const {
        summaryGrossSalaries,
        summaryNetSalaries,
        totalEmployeeCount,
        currentPayrollEmpCount,
        currentPayrollStatus,
        currentPayrollId,
      } = response.data;

      // Round the values to two decimal places
      const formattedPaidValue = paidProgress.toFixed(2);
      const formattedUnpaidValue = unpaidProgress.toFixed(2);

      setStore({
        payrollState: {
          ...payrollState,
          res: response.data,
          summaryGrossSalaries,
          summaryNetSalaries,
          totalEmployeeCount,
          labels: response.data.summaryYaxisLabels,
          currentPayrollStatus,
          currentPayrollEmpCount,
          currentPayrollId,
          paidProgress: formattedPaidValue,
          unpaidProgress: formattedUnpaidValue,
          status: currentPayrollStatus,
        },
      });
    });
  };
  const payRun = async () => {
    if (payrollState.currentPayrollEmpCount !== 0) {
      return await runPayroll().then(res => {
        setStore({
          payrollState: {
            ...payrollState,
            res: res?.data,
            rows: res?.data?.payslips.map(payslip => {
              return [
                [payslip.firstName + " " + payslip.lastName, payslip],
                payslip.paidDays,
                formattedAmount(payslip.grossEarnings, "en-IN", "INR", 2, 2),
                formattedAmount(payslip.deductions, "en-IN", "INR", 2, 2),
                formattedAmount(payslip.taxes, "en-IN", "INR", 2, 2),
                formattedAmount(payslip.totalNetPayable, "en-IN", "INR", 2, 2),
              ];
            }),
            payRollRunId: res?.data?.payrollDetails.id,
            status: res?.data?.payrollDetails.status,
          },
        });
        setIsFetched(true);
      });
    } else if (payrollState.currentPayrollEmpCount === 0) {
      setIsFetched(false);
      showToast("warn", "Unable to Create Payroll, Please Add User");
    }
  };

  useEffect(() => {
    payrollInsights();

    if (fetched) {
      navigate(`/payroll/${payrollState.payRollRunId || payrollState.currentPayrollId}`);
    }
  }, [fetched]);

  const payrollDetails = payrollState?.res;
  const currentPayrollMonth = payrollDetails?.currentPayrollMonth;
  const currentPayrollYear = payrollDetails?.currentPayrollYear;

  const plugins = [
    {
      /**
       * Executes after drawing the chart.
       *
       * @param {object} chart - The chart object.
       */
      afterDraw: function (chart) {
        if (chart.data.datasets[0].data.length < 1) {
          let ctx = chart.ctx;
          let width = chart.width;
          let height = chart.height;
          ctx.textAlign = "center";
          ctx.textBaseline = "middle";
          ctx.font = "20px Arial";
          ctx.fillText("No Payroll data to display", width / 2, height / 2);
          ctx.restore();
        }
      },
    },
  ];

  return (
    <>
      <PageSection
        showIcon={true}
        heading="Payroll"
        icon={<MdOutlineScheduleSend size={"2.2rem"} />}>
        <div className="mx-auto">
          <div className="overflow-hidden bg-white dark:bg-gray-800 sm:rounded-lg">
            <div className="px-4 divide-y dark:divide-gray-700 flex flex-col py-3 space-y-3 md:flex-row md:items-center md:justify-between md:space-y-0 md:space-x-4"></div>
            <div className="overflow-x-auto">
              <CardComponent
                heading={
                  <>
                    Process Pay Run for {MONTHS[currentPayrollMonth]} {currentPayrollYear}
                    <span
                      className={`w-18 text-center ml-2 mt-7 h-4 ${
                        payrollState.currentPayrollStatus === PayrollStatus.Approved
                          ? "bg-red-100 text-red-800 dark:bg-red-900 dark:text-red-300"
                          : payrollState.currentPayrollStatus === PayrollStatus.Draft
                            ? "bg-gray-100 text-gray-800 dark:bg-gray-900 dark:text-gray-300"
                            : payrollState.currentPayrollStatus === null &&
                                payrollState.res?.currentPayrollEmpCount === 0
                              ? "bg-orange-100 text-orange-800 dark:bg-orange-900 dark:text-orange-300"
                              : "bg-indigo-100 text-indigo-800 dark:bg-indigo-900 dark:text-indigo-300"
                      } text-sm font-medium mr-2 px-2.5 rounded`}>
                      {payrollState.currentPayrollStatus === PayrollStatus.Draft
                        ? "DRAFT"
                        : payrollState.currentPayrollStatus === PayrollStatus.Approved
                          ? "PAYMENT DUE"
                          : payrollState.currentPayrollStatus === null &&
                              payrollState.res?.currentPayrollEmpCount === 0
                            ? "NOT READY"
                            : "READY"}
                    </span>
                  </>
                }
                title={payRunInfo(payrollState, formattedDate, payRun)}
                headingClassName="mb-2 px-4 !text-xlg !text-gray-600 dark:!text-gray-800"
                cardClassName={`${styles.borderLeftCard} mx-6 hover:!bg-transparent`}
              />

              <div className="flex flex-col md:flex-row m-6">
                {payrollState.res?.lastPayrollDetails && (
                  <div className="w-full md:w-2/3 min-w-[200px] mr-9">
                    <div className="mb-2 px-4 text-lg font-bold text-gray-500 dark:text-gray-800">
                      Payroll Cost Summary
                    </div>
                    <Line
                      className="pl-[12px] mb-4 sm:mb-8"
                      data={chartData(
                        payrollState.labels,
                        payrollState.summaryGrossSalaries,
                        payrollState.summaryNetSalaries
                      )}
                      options={chartOptions(payrollState.summaryGrossSalaries)}
                      plugins={plugins}
                    />
                  </div>
                )}
                <div className="md:w-1/3 min-w-[200px]">
                  <div className="mb-2 px-4 text-lg font-bold text-gray-500 dark:text-gray-800">
                    Total Employees
                  </div>
                  <div className="px-4 -mt-2 font-medium text-gray-600">
                    {payrollState.res?.totalEmployeeCount}
                  </div>

                  {payrollState.res?.lastPayrollDetails && (
                    <>
                      <CardComponent
                        heading="Last Payroll Details"
                        title={insightsCardTitle(payrollState, payrollStatus, formattedDate)}
                        headingClassName="px-4 !text-xlg !text-gray-600 dark:!text-gray-800"
                        cardClassName="!bg-blue-100 hover:!bg-gray-100 text-center mr-3 mt-4"
                      />
                    </>
                  )}
                </div>
              </div>
            </div>

            {payrollState.res?.lastPayrollDetails === null && (
              <div className="tracking-wide py-4 text-[#6b7280] text-[16px] text-center mx-6">
                <span className="inline-block pr-1">
                  <ProtipSvg />
                </span>
                <span className="text-gray-900">ProTip! </span>
                <span>Create Pay Run to see more features in this page.</span>
              </div>
            )}
          </div>
        </div>
      </PageSection>
    </>
  );
};

export default PayrollRun;
