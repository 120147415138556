import { Pagination } from "flowbite-react";
import { useEffect, useState } from "react";
import { DEFAULT_PAGE_NO, DEFAULT_PAGE_SIZE } from "../../../constants/Constant";
import { getLeaveTypes } from "../../../services/LeaveService";
import { AddLeaveTypeModal } from "./AddLeaveTypeModal";
import CardComponent from "../../../components/common/Card/Card";
import { EditLeaveTypeModal } from "./EditLeaveTypeModal";

export const LeaveTypesTab = () => {
  const [renderLeaveType, setRenderLeaveType] = useState(false);
  const [leaveTypesResponse, setLeaveTypesResponse] = useState();
  const [leaveTypes, setLeaveTypes] = useState();
  const [toggleEditModal, setToggleEditModal] = useState(false);
  const [selectedLeaveType, setSelectedLeaveType] = useState(null);

  // Setting the page no
  const [pageNo, setPageNo] = useState(DEFAULT_PAGE_NO);

  // // Setting the page size
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);

    useEffect(() => {
      fetchLeaveTypes(pageNo, pageSize);
    }, [pageNo, pageSize, renderLeaveType]);

  const fetchLeaveTypes = async (pageNo, pageSize) => {
    const response = await getLeaveTypes(pageNo, pageSize);
    setLeaveTypesResponse(response);

    const allLeaveTypes = response?.data;
    setLeaveTypes(allLeaveTypes);
  };

  // // Function to set the page size and page number
  const setPage = e => {
    setPageSize(e.target.value);
    if (Math.ceil(leaveTypesResponse?.records / pageSize) > 1) {
      setPageNo(DEFAULT_PAGE_NO);
    }
  };

  const handleRenderLeaveType = () => {
    setRenderLeaveType(prevState => !prevState);
  };

  // Function to open the EditEmploymentTypeModal
  const handleEditLeaveTypeModalOpen = leaveType => {
    setSelectedLeaveType(leaveType);
    setToggleEditModal(true);
  };

  // Function to close the EditEmploymentTypeModal
  const handleEditLeaveTypeModalClose = () => {
    setToggleEditModal(false);
    setSelectedLeaveType(null);
  };

  return (
    <>
      <section className="bg-gray-100 dark:bg-gray-900 py-3 sm:py-5">
        <div className="px-4 mx-auto max-w-screen-2xl lg:px-12">
          <div className="flex w-[100%] justify-between">
            <p className="text-4xl text-gray-900 dark:text-white py-4 cursor-pointer">
              Leave Types
            </p>
            <span className="py-4">
              <AddLeaveTypeModal
                pageNo={pageNo}
                pageSize={pageSize}
                setRenderLeaveType={handleRenderLeaveType}
              />
            </span>
          </div>

          <div className="static!important overflow-hidden bg-white shadow-md dark:bg-gray-800 sm:rounded-lg">
            <div className="px-4 divide-y dark:divide-gray-700 flex flex-col py-3 space-y-3 md:flex-row md:items-center md:justify-between md:space-y-0 md:space-x-4"></div>
            <div className="overflow-y-auto grid gap-4 mb-4 md:gap-2 md:grid-cols-2 sm:mb-8">
              {leaveTypes?.map((leaveType, i) => {
                return (
                  <CardComponent
                    heading={leaveType?.name}
                    key={i}
                    onClick={() => handleEditLeaveTypeModalOpen(leaveType)}
                    title={leaveType?.description}
                    cardClassName="ml-4 mr-4 cursor-pointer text-center"
                    dropdownClassName="flex justify-end px-[4px] py-[0.5px]"
                  />
                );
              })}
            </div>

            <nav
              className="flex flex-col items-start justify-between p-4 space-y-3 md:flex-row md:items-center md:space-y-0"
              aria-label="Table navigation"
            >
              <div className="flex items-center space-x-3">
                {leaveTypesResponse?.records > 21 ? (
                  <>
                    <label
                      htmlFor="rows"
                      className="text-xs font-normal text-gray-500 dark:text-gray-400"
                    >
                      Rows per page
                    </label>
                    <select
                      id="rows"
                      onChange={setPage}
                      value={pageSize}
                      className="text-gray-900 text-sm rounded-lg focus:ring-thynkwebPrimary-500 focus:border-thynkwebPrimary-500 block py-1.5 pl-3.5 pr-6 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-thynkwebPrimary-500 dark:focus:border-thynkwebPrimary-500"
                    >
                      <option value={20}>20</option>
                      <option value={50}>50</option>
                      <option value={100}>100</option>
                      <option value={200}>200</option>
                    </select>
                  </>
                ) : (
                  ""
                )}
              </div>
              <div>
                {Math.ceil(leaveTypesResponse?.records / pageSize) > 1 ? (
                  <Pagination
                    currentPage={pageNo}
                    onPageChange={pageNumber => setPageNo(pageNumber)}
                    showIcons={true}
                    totalPages={
                      leaveTypesResponse?.records === undefined
                        ? 1
                        : Math.ceil(leaveTypesResponse?.records / pageSize)
                    }
                  />
                ) : (
                  ""
                )}
              </div>
            </nav>
          </div>
        </div>

        {toggleEditModal && selectedLeaveType && (
          <EditLeaveTypeModal
            leaveTypeId={selectedLeaveType?.id}
            leaveTypeName={selectedLeaveType?.name}
            setRenderLeaveType={setRenderLeaveType}
            toggleEditModal={toggleEditModal}
            onClose={handleEditLeaveTypeModalClose}
          />
        )}
      </section>
    </>
  );
};
