import { axiosInstance as axios } from "../context/AuthContext";

const baseUrl = process.env.REACT_APP_BASE_URL;
const SalaryComponentBaseUrl = `${baseUrl}/Payroll/SalaryComponents`;

export const GetEarningTypes = async () => {
  const response = await axios.get(`${SalaryComponentBaseUrl}/earningTypes`);
  return response;
};

export const GetEarningTypeById = async id => {
  const response = await axios.get(`${SalaryComponentBaseUrl}/earningTypes/${id}`);
  return response;
};

export const AddTenantEarning = async data => {
  const response = await axios.post(`${SalaryComponentBaseUrl}/earnings`, data);
  return response;
};

export const UpdateTenantEarning = async data => {
  const response = await axios.put(`${SalaryComponentBaseUrl}/earnings`, data);
  return response;
};

export const GetEarnings = async isPayrollRun => {
  const response = await axios
    .get(`${SalaryComponentBaseUrl}/earnings?isPayrollRun=${isPayrollRun}`)
    .then(response => {
      return response?.data;
    });
  return response;
};

export const GetEarningById = async id => {
  const response = await axios.get(`${SalaryComponentBaseUrl}/earnings/${id}`).then(response => {
    return response?.data;
  });
  return response;
};

export const AddTenantDeduction = async data => {
  const response = await axios.post(`${SalaryComponentBaseUrl}/deductions`, data);
  return response;
};

export const UpdateTenantDeduction = async data => {
  const response = await axios.put(`${SalaryComponentBaseUrl}/deductions`, data);
  return response;
};

export const GetDeductions = async isPayrollRun => {
  const response = await axios
    .get(`${SalaryComponentBaseUrl}/deductions?isPayrollRun=${isPayrollRun}`)
    .then(response => {
      return response?.data;
    });
  return response;
};

export const GetDeductionById = async id => {
  const response = await axios.get(`${SalaryComponentBaseUrl}/deductions/${id}`).then(response => {
    return response?.data;
  });
  return response;
};

//Function to get salary structure according to given components in 'salaryDetails'
export const getSalaryStructureByComponents = async salaryDetails => {
  const response = await axios.post(`${SalaryComponentBaseUrl}/getSalaryStructure`, salaryDetails);
  return response;
};

export const updateEarningStatus = async (id, status) => {
  const response = await axios.patch(`${SalaryComponentBaseUrl}/earnings/${id}?status=${status}`);
  return response;
};

export const updateDeductionsStatus = async (id, status) => {
  const response = await axios.patch(`${SalaryComponentBaseUrl}/deductions/${id}?status=${status}`);
  return response;
};

export const deleteEarningById = async id => {
  const response = await axios.delete(`${SalaryComponentBaseUrl}/earnings/${id}`);
  return response;
};

export const deleteDeductionById = async id => {
  const response = await axios.delete(`${SalaryComponentBaseUrl}/deductions/${id}`);
  return response;
};