import React from "react";
import { Role } from "../../../enum/Role";
import { FaRegEdit } from "react-icons/fa";
import DeleteSvg from "../../../media/DeleteSvg";
import TableData from "../../common/Table/TableData";
import TableHeader from "../../common/Table/TableHeader";

/**
 * React component for displaying a list of roles.
 *
 * @param {Object} roleDetails - the details of the roles to be displayed
 * @param {function} handleClickUpdateRole - the function to handle the click event for updating a role
 * @param {function} handleClickDeleteRole - the function to handle the click event for deleting a role
 * @return {JSX.Element} the table JSX element with role details
 */
const RolesList = ({ roleDetails, handleClickUpdateRole, handleClickDeleteRole }) => {
  return (
    <table className="w-full text-sm text-left">
      <TableHeader
        cols={["ID", "Role", "Description", "Action"]}
        styles={{
          tableHead:
            "text-xs uppercase",
          tableRow: "",
          tableCol: "",
        }}
      />
      <tbody>
        {roleDetails.map((role, index) => (
          <tr key={role?.id} className="bg-white border-b dark:bg-gray-800">
            <TableData data={index + 1} />
            <TableData data={role?.name} />
            <TableData data={role?.description} />
            <TableData>
              <div className="flex gap-3 cursor-pointer">
                {/* <div>
                  <FaRegEdit onClick={handleClickUpdateRole.bind(null, role?.id)} />
                </div>
                <div>
                  {role?.id !== Role.Administrator &&
                    role?.id !== Role.Moderator &&
                    role?.id !== Role.Member && (
                      <DeleteSvg onClick={handleClickDeleteRole.bind(null, role?.id)} />
                    )}
                </div> */}
              </div>
            </TableData>
          </tr>
        ))}
      </tbody>
    </table>
  );
};
export default RolesList;
