/**
 * Renders a row in the document table.
 *
 * @param {string} name - The name of the document.
 * @param {string} uploadedDate - The date the document was uploaded.
 * @param {string} fileExt - The file extension of the document.
 * @param {number} index - The index of the document row.
 * @return {JSX.Element} The rendered table row.
 */
const DocumentRows = ({ name, uploadedDate, fileExt, index }) => {
  return (
    <tr className="border-b border-gray-200 cursor-pointer" data-doc={index}>
      <td className="pl-4 py-3 !w-[15%]">{name}</td>
      <td className="pl-4 py-3 !w-[15%]">{fileExt}</td>
      <td className="pl-4 py-3 !w-[15%]">{uploadedDate}</td>
    </tr>
  );
};

export default DocumentRows;
