import { useState, useRef, useEffect, memo } from "react";
import { Dropdown } from "flowbite-react";
import { v4 as uuidv4 } from "uuid";

import { _12hours, _60minutes } from "../../../utils/common";

const TimeSelect = ({
  buttonClass = "",
  dropdownClass = "",
  hours = null,
  minutes = null,
  ampm = "am",
  handleDropdown,
}) => {
  if (hours === null) {
    hours = "--";
  }
  if (minutes === null) {
    minutes = "--";
  }
  if (ampm === null) {
    ampm = "--";
  }
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const toggleDropdown = (e) => {
    e.preventDefault();
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event) => {
    let isClickedInOption =
      event.target.closest('[data-option="true"]') ??
      event.target.tagName === "LI";
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target) &&
      !isClickedInOption
    ) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => document.removeEventListener("click", handleClickOutside);
  }, []);

  return (
    <div className="" ref={dropdownRef}>
      <div
        className={`absolute bg-white rounded-md w-[max-content] ${dropdownClass}`}
        data-option="true"
      >
        <div className="flex" data-option="true">
          <Dropdown dismissOnClick={false} className="h-[8.5rem] scrollbar-hide z-100" renderTrigger={() => <button type="button"
            className={`p-2.5 py-[10px] flex items-center gap-[1rem] justify-between bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-thynkwebPrimary-600 focus:border-thynkwebPrimary-600 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-thynkwebPrimary-500 dark:focus:border-thynkwebPrimary-500 ${buttonClass}`}
          >
            {hours + ":" + minutes + " " + ampm}
            <svg
              className="w-5 h-5 text-gray-800 dark:text-white"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 20 20"
            >
              <path
                stroke="currentColor"
                strokeLinejoin="round"
                strokeWidth="1"
                d="M10 6v4l3.276 3.276M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
              />
            </svg>
          </button>}>
            <div className="flex items-center" data-option="true">
              <ul className="h-[7.5rem] scrollbar-hide" data-option="true">
                <Dropdown.Header
                  className="bg-[white] sticky top-[0px]"
                  data-option="true"
                >
                  <a>Hours</a>
                </Dropdown.Header>
                {_12hours.map((item) => {
                  const renderNumber = item < 10 ? `0${item}` : item;
                  const isSelected =
                    renderNumber.toString() === hours ? "bg-gray-200" : "";
                  return (
                    <Dropdown.Item
                      onClick={() => handleDropdown(item, "hours")}
                      className={`w-[55px] ${isSelected}`}
                      key={uuidv4()}
                      data-option="true"
                    >
                      {renderNumber}
                    </Dropdown.Item>
                  );
                })}
              </ul>

              <ul className="h-[7.5rem] scrollbar-hide" data-option="true">
                <Dropdown.Header
                  className="bg-[white] sticky top-[0px]"
                  data-option="true"
                >
                  <a>Minutes</a>
                </Dropdown.Header>
                {_60minutes.map((item) => {
                  const renderNumber = item < 10 ? `0${item}` : item;
                  const isSelected =
                    renderNumber.toString() === minutes ? "bg-gray-200" : "";
                  return (
                    <Dropdown.Item
                      data-option="true"
                      onClick={() => handleDropdown(item, "minutes")}
                      className={`w-[55px] ${isSelected}`}
                      key={uuidv4()}
                    >
                      {renderNumber}
                    </Dropdown.Item>
                  );
                })}
              </ul>
              <ul className="h-[7.5rem] scrollbar-hide" data-option="true">
                <Dropdown.Header
                  className="bg-[white] sticky top-[0px]"
                  data-option="true"
                >
                  <a>AM/PM</a>
                </Dropdown.Header>
                {["AM", "PM"].map((item) => {
                  return (
                    <>
                      <Dropdown.Item
                        data-option="true"
                        className={`w-[55px] ${item === ampm ? "bg-gray-200" : ""
                          }`}
                        onClick={() => handleDropdown(item, "ampm")}
                        key={uuidv4()}
                      >
                        {item}
                      </Dropdown.Item>
                    </>
                  );
                })}
              </ul>
            </div>
          </Dropdown>
        </div>
      </div>
    </div>
  );
};

export default memo(TimeSelect);
