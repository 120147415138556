import React, { useContext } from "react";
import { Footer } from "../../components/common/Footer";
import { PaymentResponseContext } from "../../context/PaymentResponseContext";
import { VerifyPayment } from "../../services/PaymentService";

const Checkout = () => {
  const { paymentResponse } = useContext(PaymentResponseContext);
  //Getting the current user details
  const userDetails = JSON.parse(sessionStorage.getItem("user"));

  const HandlePaymentSuccess = async () => {
    const options = {
      key: paymentResponse?.keyId,
      amount: paymentResponse?.amountinPaise,
      name: paymentResponse?.businessName,
      description: "Pro Membership",
      order_id: paymentResponse?.externalOrderId,
      handler: async response => {
        await VerifyPayment(response)
          .then(() => {
            //TODO: Need to rethink the logic
          })
          .catch(() => {
            console.error("Payment verification failed. Please contact support.");
          });
      },
      prefill: {
        name: `${userDetails?.firstName} ${userDetails?.lastName}`,
        email: userDetails?.email,
      },
      theme: {
        color: "thynkwebPrimary-700",
      },
    };

    const razorPay = new window.Razorpay(options);
    razorPay.open();
  };

  return (
    <div>
      <div className="checkout-content">
        <section className="text-center mt-28 w-[20%] m-auto">
          <button
            onClick={HandlePaymentSuccess}
            type="submit"
            className="w-full px-5 py-2.5 text-sm font-medium text-center text-white bg-thynkwebPrimary-700 rounded-lg focus:ring-4 focus:ring-thynkwebPrimary-200 dark:focus:ring-thynkwebPrimary-900 hover:bg-thynkwebPrimary-800 !mt-[2.5rem] cursor-pointer">
            Pay now
          </button>
        </section>
      </div>
      <div className="footer-content">
        <Footer />
      </div>
    </div>
  );
};

export default Checkout;
