import { FaFileInvoice } from "react-icons/fa";

const InvoiceSvg = ({ size, className, color }) => {
  return (
    <FaFileInvoice
      size={size}
      className={className}
      color={color}
    />
  );
};

export default InvoiceSvg;
