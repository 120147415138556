import React from "react";
import TableHeader from "../../common/Table/TableHeader";
import Table from "../../common/Table/Table";
import TableData from "../../common/Table/TableData";
import { BiBookAdd } from "react-icons/bi";
import { FaCheckCircle, FaRegEdit, FaRegTrashAlt } from "react-icons/fa";
import EditIconSvg from "../../../media/EditIconSvg";
import DeleteSvg from "../../../media/DeleteSvg";
import { Role } from "../../../enum/Role";
import usePagination, { PaginationComponent } from "../../../hooks/usePagination";
import { useSelector } from "react-redux";
import TableRows from "../../common/Table/TableRows";
import { ProjectsHeading } from "../helper";
import MemoizedNoItemFound from "../../NoItemFound/Noitemfound";
import { ProjectRole } from "../../../enum/Project";

/**
 * Function for rendering a list of projects.
 *
 * @param {object} projectDetails - the details of the projects to be displayed
 * @param {function} handleClickDeleteProject - the function to handle deleting a project
 * @param {function} handleClickUpdateProject - the function to handle updating a project
 * @return {JSX.Element} the table component with project details
 */

const ProjectsList = ({ projectDetails, handleClickDeleteProject, handleClickUpdateProject }) => {
  return (
    <>
      <Table className="">
        <TableHeader
          cols={ProjectsHeading}
          styles={{
            tableHead: "text-xs uppercase",
            tableRow: "",
            tableCol: "",
          }}
        />
        <tbody className={`${projectDetails.length === 0 ? "h-[8rem]" : "auto"}`}>
          {projectDetails?.length === 0 && (
            <MemoizedNoItemFound
              isTable={true}
              itemsArr={projectDetails}
              colSpan={ProjectsHeading?.length}
              message={"No projects added"}
            />
          )}
          {projectDetails.length > 0 &&
            projectDetails?.map((project, index) => (
              <TableRows
                key={project?.id}
                className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                <TableData data={project?.name} />
                <TableData data={project?.businessName ? project?.businessName : "N/A"} />
                {project?.projectAssociations.length !== 0 && (
                  <>
                    {project?.projectAssociations.some(
                      item => item?.projectRole === ProjectRole.PROJECT_HEAD
                    ) ? (
                      project?.projectAssociations.map((item, index) => {
                        if (item?.projectRole === ProjectRole.PROJECT_HEAD) {
                          return (
                            <TableData
                              key={index}
                              data={`${item.employee?.firstName} ${item.employee?.lastName}`}
                            />
                          );
                        }
                      })
                    ) : (
                      <TableData data="N/A" />
                    )}
                  </>
                )}
                {project?.projectAssociations.length === 0 && <TableData data="N/A" />}
                <TableData>
                  <FaCheckCircle
                    size="1.1rem"
                    style={{ color: project.isActive ? "#d7d25c" : "inherit" }}
                  />
                </TableData>
                <TableData className="px-8 py-4">
                  <BiBookAdd size="1.1rem" />
                </TableData>
                <TableData>
                  <div className="flex gap-2">
                    <div
                      onClick={handleClickUpdateProject.bind(null, project?.id)}
                      className="cursor-pointer p-2 hover:text-thynkwebPrimary-800">
                      <FaRegEdit />
                    </div>
                    <div
                      className="cursor-pointer p-2 hover:text-red-400"
                      onClick={handleClickDeleteProject.bind(null, project?.id)}>
                      <FaRegTrashAlt />
                    </div>
                  </div>
                </TableData>
              </TableRows>
            ))}
        </tbody>
      </Table>
    </>
  );
};

export default ProjectsList;
