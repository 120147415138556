import { createSlice } from "@reduxjs/toolkit";
import { RegimeType } from "../../enum/RegimeType";
import { GetDeductions, GetEarnings, getSalaryStructureByComponents } from "../../services/SalaryComponentService";
import { DeductionType } from "../../enum/DeductionType";
import { CalculationType } from "../../enum/CalculationType";
import { EarningCategory } from "../../enum/EarningCategory";
import { getAllStatutoryComponents } from "../../services/StatutoryService";
import { StatutoryComponentType } from "../../enum/StatutoryComponentType";

const initialState = {
    id: "salaryStructure",

    tenantEarnings: [],
    tenantPreTaxDeductions: [],
    tenantStatutoryComponents: [],

    annualCTC: 0,
    regimeType: RegimeType.New.toString(),

    salaryStructureResponse: {
        isAvailable: false,
        annualCTC: 0,
        monthlyCTC: 0,
        monthlyGross: 0,
        annualGross: 0,
        monthlyTDS: 0,
        annualTDS: 0,
        earnings: [],
        statutoryComponents: []
    },

    // User entered values with relevant data
    earningAssociationValues: [],
    deductionAssociationValues: [],
}

const salaryStructureSlice = createSlice({
    name: "salaryStructure",
    initialState,
    reducers: {
        setTenantEarnings: (state, action) => {
            state.tenantEarnings = action.payload
        },
        setTenantPreTaxDeductions: (state, action) => {
            state.tenantPreTaxDeductions = action.payload
        },
        setTenantStatutoryComponents: (state, action) => {
            state.tenantStatutoryComponents = action.payload
        },
        setSalaryStructureResponse: (state, action) => {
            state.salaryStructureResponse = action.payload
        },
        setSalaryStructure: (state, action) => {
            state = action.payload
        },
        setAnnualCTC: (state, action) => {
            state.annualCTC = action.payload
        },
        setRegimeType: (state, action) => {
            state.regimeType = action.payload
        },
        setEarningAssociationValues: (state, action) => {
            state.earningAssociationValues = action.payload
        },
        setDeductionAssociationValues: (state, action) => {
            state.deductionAssociationValues = action.payload
        },
        resetSalaryStructure: () => initialState
    }
})

export const {
    setTenantEarnings,
    setTenantPreTaxDeductions,
    setSalaryStructureResponse,
    setSalaryStructure,
    setAnnualCTC,
    setRegimeType,
    setEarningAssociationValues,
    setDeductionAssociationValues,
    resetSalaryStructure
} = salaryStructureSlice.actions

/**
 * Get tenant earnings and pre-tax deductions.
 *
 * @param {boolean} isEditing - Flag indicating if the user is in editing mode.
 * @param {Object} user - User object containing details such as annual CTC and regime type.
 * @return {Function} - A thunk function that dispatches actions to update tenant earnings and pre-tax deductions.
 */
const getComponents = function (isEditing, user) {
    return async dispatch => {
        const tenantEarnings = await GetEarnings(false);
        const filteredEarnings = tenantEarnings?.data?.filter(item => item?.isFixedPay);
        dispatch(salaryStructureSlice.actions.setTenantEarnings(filteredEarnings));

        const tenantPreTaxDeductions = await GetDeductions(false);
        const filteredDeductions = tenantPreTaxDeductions?.data?.filter(item => item?.deductionType === DeductionType.PreTax && item.isRecurring && item.status === true);
        dispatch(salaryStructureSlice.actions.setTenantPreTaxDeductions(filteredDeductions));

        const tenantStatutoryComponents = await getAllStatutoryComponents();
        dispatch(salaryStructureSlice.actions.setTenantStatutoryComponents(tenantStatutoryComponents.data.data));

        // Create earning associaton values array from filtered earnings with id = 0 (to add new assocations)
        let earningAssociationValues = filteredEarnings.map((tenantEarning) => {
            let monthlyAmount = 0;
            switch (tenantEarning.calculationType) {
                case CalculationType.FlatAmount:
                    monthlyAmount = tenantEarning.calculationValue
                default:
                    monthlyAmount = 0;
            }
            if (tenantEarning.earningCategory === EarningCategory.FixedAllowance) {
                monthlyAmount = 0;
            }
            return {
                associationId: 0,
                tenantEarningId: tenantEarning.id,
                tenantEarningName: tenantEarning.name,
                calculationValue: tenantEarning.calculationValue,
                calculationType: tenantEarning.calculationType,
                earningCategory: tenantEarning.earningCategory,
                monthlyAmount,
                annualAmount: monthlyAmount * 12
            }
        });

        if (isEditing) {
            dispatch(salaryStructureSlice.actions.setAnnualCTC(user.annualCTC));
            dispatch(salaryStructureSlice.actions.setRegimeType(user.regimeType.toString()));

            // Create user deduction association values
            const deductionAssociationValues = user.deductionAssociations.map(da => {
                return {
                    ...da,
                    id: da.id,
                    calculationValue: da.calculationValue,
                    tenantDeductionId: da.tenantDeductionId,
                    tenantDeductionName: filteredDeductions.find(fd => fd.id === da.tenantDeductionId)?.name ?? ""
                }
            })
            dispatch(salaryStructureSlice.actions.setDeductionAssociationValues(deductionAssociationValues));

            // If any earning associations found in user, map the ids and new values.
            earningAssociationValues = earningAssociationValues.map(eav => {
                const existingAssociation = user.earningAssociations.find(uea => uea.tenantEarningId === eav.tenantEarningId);
                if (existingAssociation) {
                    let monthlyAmount = 0;
                    switch (eav.calculationType) {
                        case CalculationType.FlatAmount:
                            monthlyAmount = existingAssociation.calculationValue
                        default:
                            monthlyAmount = 0;
                    }
                    if (eav.earningCategory === EarningCategory.FixedAllowance) {
                        monthlyAmount = 0;
                    }
                    return {
                        ...eav,
                        associationId: existingAssociation?.id ?? 0,
                        calculationValue: existingAssociation.calculationValue,
                        monthlyAmount: monthlyAmount
                    }
                }
                return eav;
            })
        }
        
        dispatch(salaryStructureSlice.actions.setEarningAssociationValues(earningAssociationValues));
    }
}

/**
 * Gets salary structure
 *
 * @param {boolean} isEditing - Flag indicating if the user is in editing mode.
 * @param {Object} user - User object containing details such as annual CTC and regime type.
 * @param {boolean} isFirstRun - Flag indicating if this is the first run of the function.
 * @return {Function} - A thunk function that dispatches actions to update the salary structure.
 */
const getSalaryStrucuture = function (isEditing, isFirstRun) {
    return async (dispatch, getState) => {
        
        let state = getState();
        
        if(isFirstRun){
            await dispatch(getComponents(isEditing, state.userManagement.user));
        }

        state = getState();
        // Create salary structure api request
        // tenantEarning containing the configurations
        // calculationValues from user input fields
        const earnings = state.salaryStructure.earningAssociationValues.map(eav => {
            return {
                calculationValue: eav.calculationValue,
                tenantEarning: state.salaryStructure.tenantEarnings.find(te => te.id === eav.tenantEarningId)
            }
        })

        const preTaxDeductions = state.salaryStructure.deductionAssociationValues.map(dav => {
            return {
                calculationValue: dav.calculationValue,
                tenantDeduction: state.salaryStructure.tenantPreTaxDeductions.find(td => td.id === dav.tenantDeductionId)
            }
        })

        const salaryStructureRequest = {
            annualCTC: state.salaryStructure.annualCTC,
            regimeType: +state.salaryStructure.regimeType,
            earnings: earnings,
            deductions: preTaxDeductions,
            epf: state.salaryStructure.tenantStatutoryComponents.find(tsc => tsc.statutoryComponentType === StatutoryComponentType.EPF) || null,
            esi: { ...state.salaryStructure.tenantStatutoryComponents.find(tsc => tsc.statutoryComponentType === StatutoryComponentType.ESI) || null },
            professionalTax: state.salaryStructure.tenantStatutoryComponents.find(tsc => tsc.statutoryComponentType === StatutoryComponentType.ProfessionalTax) || null,
        };

        // Get updated state after dispatch
        state = getState();

        if (salaryStructureRequest.esi) salaryStructureRequest.esi.forceESICalc = state.userManagement.user.forceESICalc;

        const res = await getSalaryStructureByComponents(salaryStructureRequest);

        const salaryStructureRes = res?.data?.data;
        dispatch(salaryStructureSlice.actions.setSalaryStructureResponse(salaryStructureRes));

        // Get updated state after dispatch
        state = getState();

        // Update monthly and annual ammount from api response for respective tenant earnings
        const updatedEarningAssociationValues = state.salaryStructure.earningAssociationValues.map(eav => {

            const earning = salaryStructureRes?.earnings?.find(er => er.tenantEarningId === eav.tenantEarningId)
            return {
                ...eav,
                monthlyAmount: earning?.monthlyAmount,
                annualAmount: earning?.annualAmount
            }
        })

        // No need to update deductionAssociationValues, the updated values are the values entered by user

        dispatch(salaryStructureSlice.actions.setEarningAssociationValues(updatedEarningAssociationValues));
    }
}

export { getComponents, getSalaryStrucuture }
export default salaryStructureSlice.reducer
