import { CiStethoscope } from "react-icons/ci";
import { IoCalendarOutline } from "react-icons/io5";
import { BsCalendar3Week, BsClockHistory } from "react-icons/bs";
import { RiCalendar2Line } from "react-icons/ri";
import { v4 as uuidv4 } from "uuid";
import { GiPalmTree } from "react-icons/gi";
import { GiPackedPlanks } from "react-icons/gi";
import { MdOutlineHealthAndSafety } from "react-icons/md";
import { GiAirplaneDeparture } from "react-icons/gi";
import { GrGamepad } from "react-icons/gr";
import { GiVanillaFlower } from "react-icons/gi";
import { FaLaptopHouse } from "react-icons/fa";
import { GiMusicalKeyboard } from "react-icons/gi";
import { CiMedicalCase } from "react-icons/ci";
import { IoMdLogOut } from "react-icons/io";
import { GiThreeLeaves } from "react-icons/gi";
import { MdUpdate } from "react-icons/md";

import {
  AccrualIntervalDetails,
  CarryForwardTypeDetails,
  EffectiveAfterUnitDetails,
  ResetIntervalDetails,
} from "../../enum/LeaveTypePolicy";
import { BsShieldCheck } from "react-icons/bs";
import { proTip } from "../../pages/LeaveManagement/LeaveCommon";
import ProtipSvg from "../../media/ProtipSvg";
import MemoizedNoItemFound from "../NoItemFound/Noitemfound";
import { LeaveStatus } from "../../enum/LeaveStatus";
import { PolicyEffectiveAfterDateType } from "../../enum/PolicyEffectiveAfterDateType";

//My Leave options
export const myLeaveAdminOptions = [
  {
    txt: "Leaves",
    url: "my-leaves",
    icon: <IoMdLogOut size={"1.4rem"} />,
  },
  {
    txt: "Approvals",
    url: "my-approvals",
    icon: <BsShieldCheck style={{ stroke: "black", strokeWidth: "0.5" }} size={"1.25rem"} />,
  },
  {
    txt: "Leave Types",
    url: "leave-types",
    icon: <GiThreeLeaves size={"1.25rem"} />,
  },
  {
    txt: "Update Leaves",
    url: "customize-balance",
    icon: <MdUpdate size={"1.5rem"} />,
  },
];

export const myLeaveOptions = [
  {
    txt: "Leaves",
    url: "my-leaves",
    icon: <IoMdLogOut size={"1.4rem"} />,
  },
  {
    txt: "Approvals",
    url: "my-approvals",
    icon: <BsShieldCheck style={{ stroke: "black", strokeWidth: "0.5" }} size={"1.25rem"} />,
  }
];

export const AddCustomizeBalanceHeading = [
  "Leave Types",
  "Date",
  "Existing Balance",
  "New Balance",
  "Reason",
];

export const LeaveListViewIcon = (icon, color) => {
  switch (icon) {
    case "RiCalendar2Line":
      return <RiCalendar2Line size={80} color={color} />;
    case "CiStethoscope":
      return <CiStethoscope size={80} color={color} />;
    case "IoCalendarOutline":
      return <IoCalendarOutline size={80} color={color} />;
    case "BsClockHistory":
      return <BsClockHistory size={80} color={color} />;
    case "BsCalendar3Week":
      return <BsCalendar3Week size={80} color={color} />;
    default:
      return null;
  }
};

export const LeaveListViewResponsive = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  initialSlide: 0,
  responsive: [
    {
      breakpoint: 2561,
      settings: {
        slidesToShow: 8,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 2092,
      settings: {
        slidesToShow: 7,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 1871,
      settings: {
        slidesToShow: 6,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 1648,
      settings: {
        slidesToShow: 5,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 1441,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 1201,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 981,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 764,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 500,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 1,
      },
    },
  ],
};

export const leaveDataListView = [
  {
    type: "Casual Leave",
    icon: "RiCalendar2Line",
    iconColor: "#5895ff",
    availability: {
      available: 10,
      booked: 0,
    },
  },
  {
    type: "Sick Leave",
    icon: "CiStethoscope",
    iconColor: "#ab4afc",
    availability: {
      available: 10,
      booked: 0,
    },
  },
  {
    type: "Sick Leave",
    icon: "CiStethoscope",
    iconColor: "#ab4afc",
    availability: {
      available: 10,
      booked: 0,
    },
  },
  {
    type: "Earned Leave",
    icon: "IoCalendarOutline",
    iconColor: "#96bc42",
    availability: {
      available: 10,
      booked: 0,
    },
  },
  {
    type: "Sabbatical Leave",
    icon: "BsClockHistory",
    iconColor: "#f2d613",
    availability: {
      available: 10,
      booked: 0,
    },
  },
  {
    type: "Compensatory Off",
    icon: "BsCalendar3Week",
    iconColor: "#64d5fd",
    availability: {
      available: 10,
      booked: 0,
    },
  },
];

export const newLeaveTypeTabDetails = [
  {
    name: "Entitlement",
    tab: "entitlement",
  },
  {
    name: "Applicable",
    tab: "applicable",
  },
];

// Days in Leave type
export const days = [
  {
    label: "1st",
    value: 1,
  },
  {
    label: "2nd",
    value: 2,
  },
  {
    label: "3rd",
    value: 3,
  },
  {
    label: "4th",
    value: 4,
  },
  {
    label: "5th",
    value: 5,
  },
  {
    label: "6th",
    value: 6,
  },
  {
    label: "7th",
    value: 7,
  },
  {
    label: "8th",
    value: 8,
  },
  {
    label: "9th",
    value: 9,
  },
  {
    label: "10th",
    value: 10,
  },
  {
    label: "11th",
    value: 11,
  },
  {
    label: "12th",
    value: 12,
  },
  {
    label: "13th",
    value: 13,
  },
  {
    label: "14th",
    value: 14,
  },
  {
    label: "15th",
    value: 15,
  },
  {
    label: "16th",
    value: 16,
  },
  {
    label: "17th",
    value: 17,
  },
  {
    label: "18th",
    value: 18,
  },
  {
    label: "19th",
    value: 19,
  },
  {
    label: "20th",
    value: 20,
  },
  {
    label: "21st",
    value: 21,
  },
  {
    label: "22nd",
    value: 22,
  },
  {
    label: "23rd",
    value: 23,
  },
  {
    label: "24th",
    value: 24,
  },
  {
    label: "25th",
    value: 25,
  },
  {
    label: "26th",
    value: 26,
  },
  {
    label: "27th",
    value: 27,
  },
  {
    label: "28th",
    value: 28,
  },
];

//Months in leave type
export const months = [
  {
    label: "Jan",
    value: 1,
  },
  {
    label: "Feb",
    value: 2,
  },
  {
    label: "Mar",
    value: 3,
  },
  {
    label: "Apr",
    value: 4,
  },
  {
    label: "May",
    value: 5,
  },
  {
    label: "Jun",
    value: 6,
  },
  {
    label: "Jul",
    value: 7,
  },
  {
    label: "Aug",
    value: 8,
  },
  {
    label: "Sep",
    value: 9,
  },
  {
    label: "Oct",
    value: 10,
  },
  {
    label: "Nov",
    value: 11,
  },
  {
    label: "Dec",
    value: 12,
  },
];

// Initial value for entitlement card details
export const initialEntitlementCardDetails = {
  uid: uuidv4(),
  toggleDetails: {
    accrualCheckBox: {
      isChecked: true,
      isMonthlySelected: false,
    },
    resetCheckBox: {
      isChecked: true,
      isMonthlySelected: false,
    },
  },
  formDetails: {
    accrualCheckBox: {
      accrualInterval: AccrualIntervalDetails.YEARLY,
      accrualDay: AccrualIntervalDetails.ONE_TIME,
      accrualMonth: AccrualIntervalDetails.MONTHLY,
      entitlement: AccrualIntervalDetails.NONE,
    },
    resetCheckBox: {
      resetInterval: ResetIntervalDetails.YEARLY,
      resetDay: ResetIntervalDetails.ONE_TIME,
      resetMonth: ResetIntervalDetails.MONTHLY,
      carryForwardVal: CarryForwardTypeDetails.NONE,
      carryForwardType: CarryForwardTypeDetails.PERCENTAGE,
    },
    others: {
      effectiveAfter: EffectiveAfterUnitDetails.NONE,
      effectiveAfterUnit: EffectiveAfterUnitDetails.YEAR,
      isProrateAccrualEnabled: false,
      effectiveAfterDateType: PolicyEffectiveAfterDateType.DateOfJoining
    },
  },
};

//Initial value for leave type details
export const initialLeaveTypeDetails = {
  name: "",
  shortCode: "",
  colorCode: "",
  icon: "",
  isPaid: true,
  description: "",
  validityStart: "",
  validityEnd: "",

  //Restrictions
  allowNegativeLeaves: false
};

export const myLeavesTableHeading = [
  "Name",
  "Date",
  "Type",
  "Applied On",
  "Notes",
  "Approval Status",
];

export const myApprovalsHeading = [
  "Name",
  "Date",
  "Type",
  "Applied On",
  "Notes",
  "Approval Status",
];

export const status = bool => {
  return bool ? "Paid" : "UnPaid";
};

//Common styles for input fields
export const tw_styles =
  "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-thynkwebPrimary-600 focus:border-thynkwebPrimary-600 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-thynkwebPrimary-500 dark:focus:border-thynkwebPrimary-500";

//Leave type image icons
export const leaveTypeImageIcons = [
  {
    id: uuidv4(),
    iconName: "RiCalendar2Line",
    iconComponent: <RiCalendar2Line size={23} />,
  },
  {
    id: uuidv4(),
    iconName: "CiMedicalCase",
    iconComponent: <CiMedicalCase size={23} />,
  },
  {
    id: uuidv4(),
    iconName: "IoCalendarOutline",
    iconComponent: <IoCalendarOutline size={23} />,
  },
  {
    id: uuidv4(),
    iconName: "GiPalmTree",
    iconComponent: <GiPalmTree size={23} />,
  },
  {
    id: uuidv4(),
    iconName: "GrGamepad",
    iconComponent: <GrGamepad size={23} />,
  },
  {
    id: uuidv4(),
    iconName: "GiPackedPlanks",
    iconComponent: <GiPackedPlanks size={23} />,
  },
  {
    id: uuidv4(),
    iconName: "MdOutlineHealthAndSafety",
    iconComponent: <MdOutlineHealthAndSafety size={23} />,
  },
  {
    id: uuidv4(),
    iconName: "FaLaptopHouse",
    iconComponent: <FaLaptopHouse size={23} />,
  },
  {
    id: uuidv4(),
    iconName: "GiAirplaneDeparture",
    iconComponent: <GiAirplaneDeparture size={23} />,
  },
  {
    id: uuidv4(),
    iconName: "GiVanillaFlower",
    iconComponent: <GiVanillaFlower size={23} />,
  },
  {
    id: uuidv4(),
    iconName: "CiStethoscope",
    iconComponent: <CiStethoscope size={23} />,
  },
  {
    id: uuidv4(),
    iconName: "GiMusicalKeyboard",
    iconComponent: <GiMusicalKeyboard size={23} />,
  },
];

//Leave type icon colors
export const leaveTypeColors = [
  {
    id: uuidv4(),
    color: "#a3f241",
  },
  {
    id: uuidv4(),
    color: "#45edcc",
  },
  {
    id: uuidv4(),
    color: "#1381e8",
  },
  {
    id: uuidv4(),
    color: "#0ccef5",
  },
  {
    id: uuidv4(),
    color: "#06a0bf",
  },
  {
    id: uuidv4(),
    color: "#1381e8",
  },
  {
    id: uuidv4(),
    color: "#1381e8",
  },
  {
    id: uuidv4(),
    color: "#0e2fc4",
  },
  {
    id: uuidv4(),
    color: "#826dc7",
  },
  {
    id: uuidv4(),
    color: "#3209b8",
  },
  {
    id: uuidv4(),
    color: "#800acf",
  },
  {
    id: uuidv4(),
    color: "#c662d1",
  },
  {
    id: uuidv4(),
    color: "#d42f92",
  },
  {
    id: uuidv4(),
    color: "#9c171c",
  },
];

// Function for creating new leave type policy card
export const generateEntitlementCard = (leavePolicies, policyId) => {
  return leavePolicies.map(policy => ({
    id: policyId ? policy?.id : 0,
    uid: uuidv4(),
    toggleDetails: {
      accrualCheckBox: {
        isChecked: policy.isAccrualEnabled,
        isMonthlySelected: policy.accrualInterval === 2 ? true : false,
      },
      resetCheckBox: {
        isChecked: policy.isResetEnabled,
        isMonthlySelected: policy.resetInterval === 2 ? true : false,
      },
    },
    formDetails: {
      accrualCheckBox: {
        accrualInterval: policy.accrualInterval,
        accrualDay: policy.accrualDay,
        accrualMonth: policy.accrualMonth,
        entitlement: policy.entitlement,
      },
      resetCheckBox: {
        resetInterval: policy.resetInterval,
        resetDay: policy.resetDay,
        resetMonth: policy.resetMonth,
        carryForwardVal: policy.carryForwardVal,
        carryForwardType: policy.carryForwardType,
      },
      others: {
        effectiveAfter: policy.effectiveAfter,
        effectiveAfterUnit: policy.effectiveAfterUnit,
        isProrateAccrualEnabled: policy.isProrateAccrualEnabled,
        effectiveAfterDateType: policy.effectiveAfterDateType
      },
    },
  }));
};

//Function for handling applicable details
export const storeApplicableDetails = (leaveTypeFieldValues, toggleOption) => {
  return leaveTypeFieldValues.map(item => {
    if (toggleOption === "updateLeaveType") {
      return {
        id: item.id, // This id belongs to each applicable options(Internship, probation and Permanent)object(main object), selected while creating a leave types id.
        optionId: item.optionId,// This id belongs to the options id (Internship, probation and Permanent) which is selected while creating a leave type.
        userFieldId: item.userFieldId,// This id belongs to the userFieldId which is present in the fields API in users.
        name: item.option.optionName, // This is option name for the selected option.
        value: item.optionId, //This is the value required for async select, where optionId is the value of the selected option.
      };
    }

    if (toggleOption === "cloneLeaveType") {
      return {
        optionId: item.optionId,
        userFieldId: item?.userFieldId,
        name: item.option.optionName,
      };
    }

    return item;
  });
};

export const ProTipApprovals = ({ leaves, roleId, Role, radioSelection }) => {
  return (
    <tr>
      <td
        colSpan={7}
        className="tracking-wide text-[#6b7280]
        py-8 h-[2.2rem] text-[16px] text-center">
        {leaves.length === 0 && (
          <p className="tracking-wide text-center border-0 text-[.9rem] text-[#6b7280]`">
            No leaves found for the selected user.
          </p>
        )}
        <span className="inline-block pr-1">
          <ProtipSvg />
        </span>
        <span className="text-gray-900">ProTip!</span> {proTip(roleId, Role, radioSelection)}
      </td>
    </tr>
  );
};

//Leave type tabs
export const leaveTypeTabs = [
  {
    id: 1,
    name: "General Information",
    activeItem: "generalInformation",
  },
  {
    id: 2,
    name: "Policies",
    activeItem: "policyDetails",
  },
  {
    id: 3,
    name: "Applicability",
    activeItem: "applicableDetails",
  },
  {
    id: 4,
    name: "Restrictions",
    activeItem: "restrictions"
  }
];

//Function for checking Mandatory fields while adding and updating leave types.
export const checkMandatoryFields = (leaveTypeFormDetails, mandatoryFields) => {
  let mandatoryObj;

  if (mandatoryFields) {
    mandatoryObj = mandatoryFields;
  } else {
    mandatoryObj = {
      name: leaveTypeFormDetails.name,
      shortCode: leaveTypeFormDetails.shortCode,
      validityStart: leaveTypeFormDetails.validityStart,
    };
  }

  const userValues = Object.values(mandatoryObj);

  const notEmpty = userValues.every(value => {
    //first check if value exist then do empty string check else return false;
    if (value === null || value === undefined) return false;
    else return value.toString().trim() !== "";
  });

  return notEmpty;
};


export const getStatusColorAndRenderLeaveStatus = leaveStatus => {
  let colorClass;
  let statusText;
  switch (leaveStatus) {
    case LeaveStatus.Approved:
      colorClass = "bg-green-100";
      statusText = "been Approved";
      break;
    case LeaveStatus.Rejected:
      colorClass = "bg-red-100";
      statusText = "been Rejected";
      break;
    case LeaveStatus.WaitingForApproval:
      colorClass = "bg-yellow-100";
      statusText = "not yet been Approved";
      break;
    case LeaveStatus.Cancelled:
      colorClass = "bg-gray-100";
      statusText = "been Cancelled";
      break;
    default:
      colorClass = "bg-gray-100";
      statusText = null;
      break;
  }
  return { colorClass, statusText };
};
