import React, { useState } from "react";
import { PROJECTS_INPUT_FIELDS } from "../../../constants/Constant";
import AsyncSelect from "react-select/async";
import InputFields from "../../common/Input/InputFields";
import TextArea from "../../common/Input/TextArea";
import { Select } from "flowbite-react";
import { customStyles } from "../../Settings/helper/helper";
import { getBusinesses } from "../../../services/BusinessService";
import { ProjectRole } from "../../../enum/Project";

const AddProjectInputs = ({
  projectFormDetails,
  onChangeProjectDetails,
  debouncedPromiseOptionsClient,
  handleAssignUserChange,
  handleClientChange,
  handleProjectHeadChange,
  handleProjectManagerChange,
  handleDebouncedPromiseOptionsProject,
}) => {
  return (
    <>
      {PROJECTS_INPUT_FIELDS.map((value, index) => {
        if (value.name === "clientName") {
          return (
            <div key={index} className="">
              <label
                htmlFor="client_name"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                {value.label}
              </label>
              <AsyncSelect
                key={index}
                cacheOptions
                defaultOptions
                isMulti={false}
                value={projectFormDetails?.businessId}
                loadOptions={debouncedPromiseOptionsClient}
                onChange={handleClientChange}
                styles={customStyles()}
                filterOption={() => true}
                closeMenuOnSelect={true}
                isSearchable={true}
                placeholder="Search Client"
              />
            </div>
          );
        }

        if (value.name === "projectHead") {
          return (
            <div key={index}>
              <label
                htmlFor="project_head"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                {value.label}
              </label>
              <AsyncSelect
                key={index}
                id="projectHead-Assign"
                isMulti={false}
                cacheOptions
                defaultOptions
                isClearable
                value={projectFormDetails?.projectHead}
                onChange={handleProjectHeadChange}
                loadOptions={(inputValue, callback) =>
                  handleDebouncedPromiseOptionsProject(
                    inputValue,
                    callback,
                    ProjectRole.PROJECT_HEAD
                  )
                }
                filterOption={() => true}
                closeMenuOnSelect={true}
                isSearchable={true}
                placeholder={value?.placeholder}
                styles={customStyles()}
              />
            </div>
          );
        }

        if (value.name === "projectManager") {
          return (
            <div key={index}>
              <label
                htmlFor="project_manager"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                {value.label}
              </label>
              <AsyncSelect
                key={index}
                id="projectManger-Assign"
                isMulti={false}
                cacheOptions
                defaultOptions
                isClearable
                value={projectFormDetails?.projectManager}
                onChange={handleProjectManagerChange}
                loadOptions={(inputValue, callback) =>
                  handleDebouncedPromiseOptionsProject(
                    inputValue,
                    callback,
                    ProjectRole.PROJECT_MANAGER
                  )
                }
                filterOption={() => true}
                closeMenuOnSelect={true}
                isSearchable={true}
                placeholder={value?.placeholder}
                styles={customStyles()}
              />
            </div>
          );
        }

        if (value.name === "assignees") {
          return (
            <div key={index}>
              <label
                htmlFor="assignees"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                {value.label}
              </label>
              <AsyncSelect
                key={index}
                id="user-Assign"
                isMulti={true}
                isClearable
                cacheOptions
                defaultOptions
                value={projectFormDetails?.assignees}
                onChange={handleAssignUserChange}
                loadOptions={(inputValue, callback) =>
                  handleDebouncedPromiseOptionsProject(
                    inputValue,
                    callback,
                    ProjectRole.PROJECT_USER
                  )
                }
                filterOption={() => true}
                closeMenuOnSelect={true}
                isSearchable={true}
                placeholder={value?.placeholder}
                styles={customStyles()}
              />
            </div>
          );
        }

        if (value.name === "description") {
          return (
            <TextArea
              key={index}
              id={value.id}
              name={value.name}
              label={value.label}
              value={projectFormDetails.description}
              onChange={onChangeProjectDetails}
              placeholder={value.placeholder}
              styles={{
                textAreaContainer: "col-span-2",
              }}
            />
          );
        }

        return (
          <InputFields
            key={index}
            InputField={value}
            value={projectFormDetails[value.name]}
            onChange={onChangeProjectDetails}
            inputClassName={"w-full"}
            parentClassName={"w-full"}
          />
        );
      })}
    </>
  );
};

export default AddProjectInputs;
