import { useContext } from "react";
import { axiosInstance as axios } from "../context/AuthContext";

const businessBaseUrl = `${process.env.REACT_APP_BASE_URL}/Businesses`;
const user = JSON.parse(sessionStorage.getItem("user"));

export const createBusiness = async client => {
  const response = await axios({
    url: businessBaseUrl,
    method: "POST",
    data: JSON.stringify(client),
  });
  return response?.data;
};

/**
 * Asynchronously adds a business logo to a specified ID using FormData and custom Axios instance.
 *
 * @param {string} id - The ID where the logo will be added
 * @param {File} file - The image file to be added as the logo
 * @return {Promise} The response from the server after adding the logo
 */
export const addBusinessLogo = async (id, file) => {
  if (!user?.token) return;
  const formData = new FormData();
  formData.append("ImageFile", file);

  const customAxios = axios.create({
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${user.token}`,
    },
  });

  const response = await customAxios({
    url: `${businessBaseUrl}/${id}/logos`,
    method: "PUT",
    data: formData,
  });

  return response;
};

export const deleteBusinessLogo = async id => {
  const response = await axios.delete(`${businessBaseUrl}/${id}/logos`);
  return response;
};

export const getBusinesses = async (pageNo, pageSize, isClient, searchParam) => {
  let queryParams = `?pageNumber=${pageNo}&pageSize=${pageSize}&isClient=${isClient}`;

  if (isClient !== undefined) {
    queryParams += `&isClient=${isClient}`;
  }

  if (searchParam) {
    queryParams += `&searchParam=${searchParam}`;
  }

  const response = await axios.get(`${businessBaseUrl}${queryParams}`);
  return response.data;
};

export const editBusinesses = async client => {
  const response = await axios({
    url: businessBaseUrl,
    method: "PUT",
    data: JSON.stringify(client),
  });
  return response.data;
};

export const deleteBusiness = async id => {
  const response = await axios.delete(`${businessBaseUrl}/${id}`);
  return response.data;
};

export const getBusinessById = async id => {
  const response = await axios.get(`${businessBaseUrl}/${id}`);
  return response.data;
};
