import { useId, useEffect, useRef, useState, useContext } from "react";
import { changeUserPassword } from "../../services/UserService";
import { createUID } from "./helper";
import { Role } from "../../enum/Role";
import { showToast } from "../common/Toast";
import ModalComponent from "../common/Modal/Modal";
import { UpdatePasswordSvg } from "../../media/UpdatePasswordSvg";
import { btnPrimary } from "../../utils/helper";
import { useDispatch, useSelector } from "react-redux";
import { toggleUpdatePassword } from "../../store/slices/profileSlice";
import { AuthContext } from "../../context/AuthContext";

const UpdatePassword = ({
}) => {
  const profile = useSelector(state => state.profile);
  const dispatch = useDispatch();

  const UID = useId();

  //Getting current user from Auth context
  const formRef = useRef();

  const { user: currentUser } = useContext(AuthContext);

  //Initial state for form inputs
  const [userPassword, setUserPassword] = useState({
    email: "",
    oldPassword: "",
    currentPassword: "",
    newPassword: "",
    confirmNewPassword: "",
  });

  const [password, setPassword] = useState({});

  const [isValid, setIsValid] = useState(false);
  //Getting values from form fields.
  const onChangePassword = event => {
    if (+currentUser?.roleId === +Role.Administrator) {
      const currentPassword = {
        ...userPassword,
        [event.target.name]: event.target.value,
      };
      setUserPassword(currentPassword);
      const isValidForm = validateForm(currentPassword);
      setIsValid(isValidForm);
    } else {
      const currentPassword = {
        ...userPassword,
        [event.target.name]: event.target.value,
      };
      setUserPassword(currentPassword);
      const isValidForm = validateForm(currentPassword);
      setIsValid(isValidForm);
    }
  };

  const validateForm = formValues => {
    if (+currentUser?.roleId === +Role.Administrator) {
      const inputFields = ["newPassword", "confirmNewPassword"];
      const isValid = inputFields.every(ele => {
        return formValues[ele].trim() !== "";
      });
      return isValid;
    } else {
      const inputFields = ["oldPassword", "newPassword", "confirmNewPassword"];
      const isValid = inputFields.every(ele => {
        return formValues[ele].trim() !== "";
      });
      return isValid;
    }
  };

  useEffect(() => {
    if (+currentUser?.roleId === +Role.Administrator) {
      const latestPassword = {
        ...userPassword,
        currentPassword: userPassword?.newPassword,
        newPassword:
          userPassword?.newPassword === userPassword?.confirmNewPassword
            ? userPassword?.newPassword
            : "",
      };
      setPassword(latestPassword);
    } else {
      const latestPassword = {
        ...userPassword,
        currentPassword: userPassword?.oldPassword,
        newPassword:
          userPassword?.newPassword === userPassword?.confirmNewPassword
            ? userPassword?.newPassword
            : "",
      };
      setPassword(latestPassword);
    }
  }, [
    userPassword,
    currentUser?.email,
    currentUser?.roleId,
    profile.password.updatePassword,
  ]);

  //Submitting updated form values
  const onSubmitChangePassword = event => {
    event.preventDefault();

    if (!isValid) {
      showToast("warn", "All fields are Mandatory");
    } else if (+currentUser?.roleId === +Role.Administrator) {
      if (userPassword.newPassword.length < 5 || userPassword.confirmNewPassword.length < 5) {
        showToast("warn", "Password should contain at least 5 characters.");
      } else if (userPassword.newPassword.length !== userPassword.confirmNewPassword.length) {
        showToast("warn", "Password and confirm password does not match");
      } else if (userPassword.newPassword !== userPassword.confirmNewPassword) {
        showToast("warn", "Password and confirm password does not match");
      } else {
        formRef.current.reset();
        changeUserPassword(password).then(response => {
          if (response.data.statusCode === 200) {
            showToast("success", "Password updated successfully");
            dispatch(toggleUpdatePassword());
          } else {
            showToast("error", "Invalid Credentials");
          }
        });
      }
    } else {
      if (userPassword.newPassword.length < 6 || userPassword.confirmNewPassword.length < 6) {
        showToast("warn", "Password should contain at least 6 characters.");
      } else if (userPassword.newPassword.length !== userPassword.confirmNewPassword.length) {
        showToast("warn", "Password and confirm password does not match");
      } else if (userPassword.newPassword !== userPassword.confirmNewPassword) {
        showToast("warn", "Password and confirm password does not match");
      } else {
        formRef.current.reset();
        changeUserPassword(password).then(response => {
          if (response.data.statusCode === 200) {
            showToast("success", "Password updated successfully");
            dispatch(toggleUpdatePassword());
          } else {
            showToast("error", "Invalid Credentials");
          }
        });
      }
    }
  };
  return (
    <>
      <ModalComponent
        show={profile.password.updatePassword}
        size="2xl"
        showFooter={false}
        onClose={() => {
          dispatch(toggleUpdatePassword());
        }}
        heading="Update password">
        <section className="mt-4 bg-white dark:bg-gray-900">
          <form className=" space-y-6 px-6 lg:px-8 " ref={formRef}>
            <div className="mb-4 sm:mb-8">
              {+currentUser?.roleId === Role.Administrator ? (
                <>
                  {/* Password  */}
                  <div className="border border-red-700 border-transparent">
                    <div className="mb-2 block">
                      <label
                        htmlFor={createUID(UID, "new-password-for-admin")}
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                        Password
                      </label>
                    </div>
                    <input
                      type="password"
                      name="newPassword"
                      id={createUID(UID, "new-password-for-admin")}
                      onChange={onChangePassword}
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-thynkwebPrimary-600 focus:border-thynkwebPrimary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-thynkwebPrimary-500 dark:focus:border-thynkwebPrimary-500"
                      placeholder="Enter new password"
                      onPaste={e => e.preventDefault()}
                      onCopy={e => e.preventDefault()}
                    />
                  </div>

                  {/* Confirm Password */}
                  <div className="mt-6 border border-red-700 border-transparent">
                    <div className="mb-2 block">
                      <label
                        htmlFor={createUID(UID, "confirm-password-for-admin")}
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                        Confirm Password
                      </label>
                    </div>
                    <input
                      type="password"
                      name="confirmNewPassword"
                      id={createUID(UID, "confirm-password-for-admin")}
                      onChange={onChangePassword}
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-thynkwebPrimary-600 focus:border-thynkwebPrimary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-thynkwebPrimary-500 dark:focus:border-thynkwebPrimary-500"
                      placeholder="Confirm new Password"
                      onPaste={e => e.preventDefault()}
                      onCopy={e => e.preventDefault()}
                    />
                  </div>
                </>
              ) : (
                <>
                  {/* Old Password */}
                  <div className="mt-6 border border-red-700 border-transparent">
                    <div className="mb-2 block">
                      <label
                        htmlFor={createUID(UID, "oldPassword")}
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                        Old Password
                      </label>
                    </div>
                    <input
                      type="password"
                      name="oldPassword"
                      id={createUID(UID, "oldPassword")}
                      onChange={onChangePassword}
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-thynkwebPrimary-600 focus:border-thynkwebPrimary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-thynkwebPrimary-500 dark:focus:border-thynkwebPrimary-500"
                      placeholder="Enter old password"
                      onPaste={e => e.preventDefault()}
                      onCopy={e => e.preventDefault()}
                    />
                  </div>

                  <hr className="w-full h-1 mx-auto my-5 bg-gray-100 border-0 rounded dark:bg-gray-700" />

                  {/* Password  */}
                  <div className="border border-red-700 border-transparent">
                    <div className="mb-2 block">
                      <label
                        htmlFor={createUID(UID, "newPassword")}
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                        Password
                      </label>
                    </div>
                    <input
                      type="password"
                      name="newPassword"
                      id={createUID(UID, "newPassword")}
                      onChange={onChangePassword}
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-thynkwebPrimary-600 focus:border-thynkwebPrimary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-thynkwebPrimary-500 dark:focus:border-thynkwebPrimary-500"
                      placeholder="Enter new password"
                      onPaste={e => e.preventDefault()}
                      onCopy={e => e.preventDefault()}
                    />
                  </div>

                  {/* Confirm Password */}
                  <div className="mt-6 border border-red-700 border-transparent">
                    <div className="mb-2 block">
                      <label
                        htmlFor={createUID(UID, "confirmNewPassword")}
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                        Confirm Password
                      </label>
                    </div>
                    <input
                      type="password"
                      name="confirmNewPassword"
                      id={createUID(UID, "confirmNewPassword")}
                      onChange={onChangePassword}
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-thynkwebPrimary-600 focus:border-thynkwebPrimary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-thynkwebPrimary-500 dark:focus:border-thynkwebPrimary-500"
                      placeholder="Confirm new Password"
                      onPaste={e => e.preventDefault()}
                      onCopy={e => e.preventDefault()}
                    />
                  </div>
                </>
              )}
            </div>

            {/* Button to update password */}
            <button
              type="submit"
              onClick={onSubmitChangePassword}
              className={`block ml-auto px-5 py-2.5 text-sm font-medium text-center text-white rounded-lg ${btnPrimary()}`}>
              Update Password
            </button>
          </form>
        </section>
      </ModalComponent>
    </>
  );
};

export default UpdatePassword;
