import { useEffect, Fragment } from "react";
import { getLeaveTypeById } from "../../../services/LeaveService";
import { checkboxOptions } from "./options";
import { Checkbox } from "../../../hooks/useCheckbox";

export const EditLeaveTypeBody = ({
  leaveTypeId,
  handleEditLeaveType,
  formRef,
  setCheckboxState,
  checkboxState,
  leaveTypeInfo,
  setLeaveTypeInfo,
}) => {
  const GetLeaveTypeById = async () => {
    const response = await getLeaveTypeById(leaveTypeId);
    setLeaveTypeInfo(response?.data);
    setCheckboxState(prevState => ({
      ...prevState,
      allowNegativeLeaves: response?.data?.allowNegativeLeaves,
      reduceLeaveOnAbsent: response?.data?.reduceLeaveOnAbsent,
    }));
  };

  useEffect(() => {
    GetLeaveTypeById();
  }, []);

  const handleCheckbox = e => {
    setCheckboxState(prevState => ({
      ...prevState,
      [e.target.name]: e.target.checked,
    }));
  };

  return (
    <form ref={formRef}>
      {/* Employment Type */}
      <div>
        <label
          htmlFor="leave-type"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
          Leave Type Name
        </label>

        <input
          type="text"
          name="name"
          defaultValue={leaveTypeInfo?.name}
          id="leave-type"
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-thynkwebPrimary-600 focus:border-thynkwebPrimary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-thynkwebPrimary-500 dark:focus:border-thynkwebPrimary-500"
          placeholder="Leave Type Name"
          onChange={handleEditLeaveType}
        />
      </div>

      {/* Employment Type Description */}
      <div className="mt-4">
        <label
          htmlFor="leave-type-description"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
          Description
        </label>

        <input
          type="text"
          name="description"
          defaultValue={leaveTypeInfo?.description}
          id="leave-type-description"
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-thynkwebPrimary-600 focus:border-thynkwebPrimary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-thynkwebPrimary-500 dark:focus:border-thynkwebPrimary-500"
          placeholder="Leave type description"
          onChange={handleEditLeaveType}
        />
      </div>

      <div className="flex items-center gap-[1.5rem] mt-3 pt-[1rem] flex-wrap">
        {checkboxOptions.map((item, idx) => {
          return (
            <Fragment key={idx}>
              <Checkbox
                id={item.id}
                name={item.name}
                onChange={handleCheckbox}
                checked={checkboxState[item.name]}
                label={item.label}
              />
            </Fragment>
          );
        })}
      </div>
    </form>
  );
};
