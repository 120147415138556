import { Link } from "react-router-dom";

export const PageNotFound = () => {
  return (
    <section className=" dark:bg-gray-900 h-screen flex justify-center items-center">
      <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
        <div className="mx-auto max-w-screen-sm text-center bg-gray-50 drop-shadow-2xl p-10">
          <h1 className="mb-4 text-7xl tracking-tight font-extrabold lg:text-9xl text-thynkwebPrimary-600 dark:text-thynkwebPrimary-500">
            404
          </h1>
          <p className="mb-4 text-3xl tracking-tight font-bold text-gray-900 md:text-4xl dark:text-white">
            Something's missing.
          </p>
          <p className="mb-4 text-lg font-light text-gray-500 dark:text-gray-400">
            Sorry, we can't find that page.
          </p>
          <Link
            to="/"
            className="inline-flex text-white bg-thynkwebPrimary-600 hover:bg-thynkwebPrimary-800 focus:ring-4 focus:outline-none focus:ring-thynkwebPrimary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:focus:ring-thynkwebPrimary-900 my-4">
            Go to Dashboard
          </Link>
        </div>
      </div>
    </section>
  );
};
