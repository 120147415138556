import React from "react";
import { Footer } from "../components/common/Footer";
import { Link } from "react-router-dom";

const TermsAndConditions = () => {
  return (
    <div>
      <div className="mt-12 border border-red-700 min-h-[10rem] border-opacity-0">
        <section className="m-auto text-[2rem] text-center font-semibold">
          Terms and Conditions
        </section>
        <section className="mt-8">
          <div
            id="accordion-color"
            data-accordion="open"
            data-active-classes="bg-blue-100 dark:bg-gray-800 text-blue-600 dark:text-white"
          >
            {/* 1st accordion */}
            <h2 id="accordion-color-heading-1">
              <button
                type="button"
                className="flex items-center justify-between w-full p-5 font-medium text-left text-gray-500 border border-b-0 border-gray-200 rounded-t-xl focus:ring-4 focus:ring-blue-200 dark:focus:ring-blue-800 dark:border-gray-700 dark:text-gray-400 hover:bg-blue-100 dark:hover:bg-gray-800"
                data-accordion-target="#accordion-color-body-1"
                aria-expanded="true"
                aria-controls="accordion-color-body-1"
              >
                <span>Your consent to our Privacy Notice</span>
                <svg
                  data-accordion-icon
                  className="w-6 h-6 rotate-180 shrink-0"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </button>
            </h2>
            <div
              id="accordion-color-body-1"
              className="hidden"
              aria-labelledby="accordion-color-heading-1"
            >
              <div className="p-5 border border-b-0 border-gray-200 dark:border-gray-700 dark:bg-gray-900">
                <p className="mb-2 text-gray-500 dark:text-gray-400">
                  By agreeing to these terms and conditions, you agree to the
                  terms of our{" "}
                  <Link className="text-blue-700" to={"/privacy-policy"}>Privacy Policy</Link>. Before
                  using this website, please carefully review our Privacy
                  Notice. All Personal Information provided to us as a result of
                  your use of this website will be handled in accordance with
                  our Privacy Notice. If you do not agree, please exit this
                  website.
                </p>
              </div>
            </div>
            {/* 2nd accordion */}
            <h2 id="accordion-color-heading-2">
              <button
                type="button"
                className="flex items-center justify-between w-full p-5 font-medium text-left text-gray-500 border border-b-0 border-gray-200 focus:ring-4 focus:ring-blue-200 dark:focus:ring-blue-800 dark:border-gray-700 dark:text-gray-400 hover:bg-blue-100 dark:hover:bg-gray-800"
                data-accordion-target="#accordion-color-body-2"
                aria-expanded="false"
                aria-controls="accordion-color-body-2"
              >
                <span>Ownership of this website and its content</span>
                <svg
                  data-accordion-icon
                  className="w-6 h-6 shrink-0"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </button>
            </h2>
            <div
              id="accordion-color-body-2"
              className="hidden"
              aria-labelledby="accordion-color-heading-2"
            >
              <div className="p-5 border border-b-0 border-gray-200 dark:border-gray-700">
                <p className="mb-2 text-gray-500 dark:text-gray-400">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Ex,
                  suscipit commodi! Eos hic modi cupiditate officiis suscipit
                  consequuntur molestias fugit porro. Veniam minima quaerat
                  possimus harum dolorum inventore cupiditate ipsam dicta amet
                  tempore vero quia commodi omnis illum reprehenderit culpa a,
                  laboriosam adipisci libero repellendus voluptatem distinctio
                  voluptates reiciendis itaque. Debitis incidunt consequatur
                  molestias. Quam saepe debitis amet aut pariatur!
                </p>
              </div>
            </div>
            {/* 3rd accordion */}
            <h2 id="accordion-color-heading-3">
              <button
                type="button"
                className="flex items-center justify-between w-full p-5 font-medium text-left text-gray-500 border border-gray-200 focus:ring-4 focus:ring-blue-200 dark:focus:ring-blue-800 dark:border-gray-700 dark:text-gray-400 hover:bg-blue-100 dark:hover:bg-gray-800"
                data-accordion-target="#accordion-color-body-3"
                aria-expanded="false"
                aria-controls="accordion-color-body-3"
              >
                <span>Trademarks</span>
                <svg
                  data-accordion-icon
                  className="w-6 h-6 shrink-0"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </button>
            </h2>
            <div
              id="accordion-color-body-3"
              className="hidden"
              aria-labelledby="accordion-color-heading-3"
            >
              <div className="p-5 border border-t-0 border-gray-200 dark:border-gray-700">
                <p className="mb-2 text-gray-500 dark:text-gray-400">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Perspiciatis facilis doloribus voluptates odit! Molestias
                  atque recusandae ut qui molestiae nostrum maiores tempora eius
                  quo adipisci ab doloribus voluptates, deleniti minima placeat
                  quis repellat explicabo voluptatibus magnam excepturi.
                  Exercitationem labore minima voluptates magni rerum odio
                  dolore aut fugit quasi, expedita voluptatem ipsa quas earum ut
                  itaque tempora praesentium soluta consectetur laborum!
                </p>
              </div>
            </div>
            {/* 4th accordion */}
            <h2 id="accordion-color-heading-4">
              <button
                type="button"
                className="flex items-center justify-between w-full p-5 font-medium text-left text-gray-500 border border-gray-200 focus:ring-4 focus:ring-blue-200 dark:focus:ring-blue-800 dark:border-gray-700 dark:text-gray-400 hover:bg-blue-100 dark:hover:bg-gray-800"
                data-accordion-target="#accordion-color-body-4"
                aria-expanded="false"
                aria-controls="accordion-color-body-4"
              >
                <span>Disclaimer of Warranties</span>
                <svg
                  data-accordion-icon
                  className="w-6 h-6 shrink-0"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </button>
            </h2>
            <div
              id="accordion-color-body-4"
              className="hidden"
              aria-labelledby="accordion-color-heading-4"
            >
              <div className="p-5 border border-t-0 border-gray-200 dark:border-gray-700">
                <p className="mb-2 text-gray-500 dark:text-gray-400">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Perspiciatis facilis doloribus voluptates odit! Molestias
                  atque recusandae ut qui molestiae nostrum maiores tempora eius
                  quo adipisci ab doloribus voluptates, deleniti minima placeat
                  quis repellat explicabo voluptatibus magnam excepturi.
                  Exercitationem labore minima voluptates magni rerum odio
                  dolore aut fugit quasi, expedita voluptatem ipsa quas earum ut
                  itaque tempora praesentium soluta consectetur laborum!
                </p>
              </div>
            </div>
            {/* 5th accordion */}
            <h2 id="accordion-color-heading-5">
              <button
                type="button"
                className="flex items-center justify-between w-full p-5 font-medium text-left text-gray-500 border border-gray-200 focus:ring-4 focus:ring-blue-200 dark:focus:ring-blue-800 dark:border-gray-700 dark:text-gray-400 hover:bg-blue-100 dark:hover:bg-gray-800"
                data-accordion-target="#accordion-color-body-5"
                aria-expanded="false"
                aria-controls="accordion-color-body-5"
              >
                <span>Limitation of Liability</span>
                <svg
                  data-accordion-icon
                  className="w-6 h-6 shrink-0"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </button>
            </h2>
            <div
              id="accordion-color-body-5"
              className="hidden"
              aria-labelledby="accordion-color-heading-5"
            >
              <div className="p-5 border border-t-0 border-gray-200 dark:border-gray-700">
                <p className="mb-2 text-gray-500 dark:text-gray-400">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Perspiciatis facilis doloribus voluptates odit! Molestias
                  atque recusandae ut qui molestiae nostrum maiores tempora eius
                  quo adipisci ab doloribus voluptates, deleniti minima placeat
                  quis repellat explicabo voluptatibus magnam excepturi.
                  Exercitationem labore minima voluptates magni rerum odio
                  dolore aut fugit quasi, expedita voluptatem ipsa quas earum ut
                  itaque tempora praesentium soluta consectetur laborum!
                </p>
              </div>
            </div>
            {/* 6th accordion */}
            <h2 id="accordion-color-heading-6">
              <button
                type="button"
                className="flex items-center justify-between w-full p-5 font-medium text-left text-gray-500 border border-gray-200 focus:ring-4 focus:ring-blue-200 dark:focus:ring-blue-800 dark:border-gray-700 dark:text-gray-400 hover:bg-blue-100 dark:hover:bg-gray-800"
                data-accordion-target="#accordion-color-body-6"
                aria-expanded="false"
                aria-controls="accordion-color-body-6"
              >
                <span>Links to other sites</span>
                <svg
                  data-accordion-icon
                  className="w-6 h-6 shrink-0"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </button>
            </h2>
            <div
              id="accordion-color-body-6"
              className="hidden"
              aria-labelledby="accordion-color-heading-6"
            >
              <div className="p-5 border border-t-0 border-gray-200 dark:border-gray-700">
                <p className="mb-2 text-gray-500 dark:text-gray-400">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Perspiciatis facilis doloribus voluptates odit! Molestias
                  atque recusandae ut qui molestiae nostrum maiores tempora eius
                  quo adipisci ab doloribus voluptates, deleniti minima placeat
                  quis repellat explicabo voluptatibus magnam excepturi.
                  Exercitationem labore minima voluptates magni rerum odio
                  dolore aut fugit quasi, expedita voluptatem ipsa quas earum ut
                  itaque tempora praesentium soluta consectetur laborum!
                </p>
              </div>
            </div>
            {/* 7th accordion */}
            <h2 id="accordion-color-heading-7">
              <button
                type="button"
                className="flex items-center justify-between w-full p-5 font-medium text-left text-gray-500 border border-gray-200 focus:ring-4 focus:ring-blue-200 dark:focus:ring-blue-800 dark:border-gray-700 dark:text-gray-400 hover:bg-blue-100 dark:hover:bg-gray-800"
                data-accordion-target="#accordion-color-body-7"
                aria-expanded="false"
                aria-controls="accordion-color-body-7"
              >
                <span>Modifications</span>
                <svg
                  data-accordion-icon
                  className="w-6 h-6 shrink-0"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </button>
            </h2>
            <div
              id="accordion-color-body-7"
              className="hidden"
              aria-labelledby="accordion-color-heading-7"
            >
              <div className="p-5 border border-t-0 border-gray-200 dark:border-gray-700">
                <p className="mb-2 text-gray-500 dark:text-gray-400">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Perspiciatis facilis doloribus voluptates odit! Molestias
                  atque recusandae ut qui molestiae nostrum maiores tempora eius
                  quo adipisci ab doloribus voluptates, deleniti minima placeat
                  quis repellat explicabo voluptatibus magnam excepturi.
                  Exercitationem labore minima voluptates magni rerum odio
                  dolore aut fugit quasi, expedita voluptatem ipsa quas earum ut
                  itaque tempora praesentium soluta consectetur laborum!
                </p>
              </div>
            </div>
            {/* 8th accordion*/}
            <h2 id="accordion-color-heading-8">
              <button
                type="button"
                className="flex items-center justify-between w-full p-5 font-medium text-left text-gray-500 border border-gray-200 focus:ring-4 focus:ring-blue-200 dark:focus:ring-blue-800 dark:border-gray-700 dark:text-gray-400 hover:bg-blue-100 dark:hover:bg-gray-800"
                data-accordion-target="#accordion-color-body-8"
                aria-expanded="false"
                aria-controls="accordion-color-body-8"
              >
                <span>Waiver</span>
                <svg
                  data-accordion-icon
                  className="w-6 h-6 shrink-0"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </button>
            </h2>
            <div
              id="accordion-color-body-8"
              className="hidden"
              aria-labelledby="accordion-color-heading-8"
            >
              <div className="p-5 border border-t-0 border-gray-200 dark:border-gray-700">
                <p className="mb-2 text-gray-500 dark:text-gray-400">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Perspiciatis facilis doloribus voluptates odit! Molestias
                  atque recusandae ut qui molestiae nostrum maiores tempora eius
                  quo adipisci ab doloribus voluptates, deleniti minima placeat
                  quis repellat explicabo voluptatibus magnam excepturi.
                  Exercitationem labore minima voluptates magni rerum odio
                  dolore aut fugit quasi, expedita voluptatem ipsa quas earum ut
                  itaque tempora praesentium soluta consectetur laborum!
                </p>
              </div>
            </div>
            {/* 9th accordion */}
            <h2 id="accordion-color-heading-9">
              <button
                type="button"
                className="flex items-center justify-between w-full p-5 font-medium text-left text-gray-500 border border-gray-200 focus:ring-4 focus:ring-blue-200 dark:focus:ring-blue-800 dark:border-gray-700 dark:text-gray-400 hover:bg-blue-100 dark:hover:bg-gray-800"
                data-accordion-target="#accordion-color-body-9"
                aria-expanded="false"
                aria-controls="accordion-color-body-9"
              >
                <span>Law and jurisdiction</span>
                <svg
                  data-accordion-icon
                  className="w-6 h-6 shrink-0"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </button>
            </h2>
            <div
              id="accordion-color-body-9"
              className="hidden"
              aria-labelledby="accordion-color-heading-9"
            >
              <div className="p-5 border border-t-0 border-gray-200 dark:border-gray-700">
                <p className="mb-2 text-gray-500 dark:text-gray-400">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Perspiciatis facilis doloribus voluptates odit! Molestias
                  atque recusandae ut qui molestiae nostrum maiores tempora eius
                  quo adipisci ab doloribus voluptates, deleniti minima placeat
                  quis repellat explicabo voluptatibus magnam excepturi.
                  Exercitationem labore minima voluptates magni rerum odio
                  dolore aut fugit quasi, expedita voluptatem ipsa quas earum ut
                  itaque tempora praesentium soluta consectetur laborum!
                </p>
              </div>
            </div>
            {/* 10th accordion */}
            <h2 id="accordion-color-heading-10">
              <button
                type="button"
                className="flex items-center justify-between w-full p-5 font-medium text-left text-gray-500 border border-gray-200 focus:ring-4 focus:ring-blue-200 dark:focus:ring-blue-800 dark:border-gray-700 dark:text-gray-400 hover:bg-blue-100 dark:hover:bg-gray-800"
                data-accordion-target="#accordion-color-body-10"
                aria-expanded="false"
                aria-controls="accordion-color-body-10"
              >
                <span>Contact</span>
                <svg
                  data-accordion-icon
                  className="w-6 h-6 shrink-0"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </button>
            </h2>
            <div
              id="accordion-color-body-10"
              className="hidden"
              aria-labelledby="accordion-color-heading-10"
            >
              <div className="p-5 border border-t-0 border-gray-200 dark:border-gray-700">
                <p className="mb-2 text-gray-500 dark:text-gray-400">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Perspiciatis facilis doloribus voluptates odit! Molestias
                  atque recusandae ut qui molestiae nostrum maiores tempora eius
                  quo adipisci ab doloribus voluptates, deleniti minima placeat
                  quis repellat explicabo voluptatibus magnam excepturi.
                  Exercitationem labore minima voluptates magni rerum odio
                  dolore aut fugit quasi, expedita voluptatem ipsa quas earum ut
                  itaque tempora praesentium soluta consectetur laborum!
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>
      <footer className="mt-5">
        <Footer />
      </footer>
    </div>
  );
};

export default TermsAndConditions;
