import React from "react";
import ModalComponent from "../../common/Modal/Modal";
import { btnPrimary } from "../../../utils/helper";

/**
 * Function for rendering a modal to add or edit user role details.
 *
 * @param {Object} toggleRoleDetails - object containing details for toggling role modal
 * @param {Function} resetStates - function to reset the component state
 * @param {Object} roleFormDetails - object containing details of the role form
 * @param {Function} onChangeRoleDetails - function to handle changes in role details
 * @param {Function} onSubmitUpdateRoleDetails - function to submit updated role details
 * @param {Function} onSubmitRoleDetails - function to submit new role details
 * @return {JSX.Element} The modal component to add or edit user role details.
 */
let tw_styles =
  "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-thynkwebPrimary-600 focus:border-thynkwebPrimary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-thynkwebPrimary-500 dark:focus:border-thynkwebPrimary-500";
const AddRole = ({
  toggleRoleDetails,
  resetStates,
  roleFormDetails,
  onChangeRoleDetails,
  onSubmitUpdateRoleDetails,
  onSubmitRoleDetails,
}) => {
  return (
    <ModalComponent
      show={toggleRoleDetails.toggleRoleModal}
      size="2xl"
      bodyClassName="gap-4 mt-2"
      heading={toggleRoleDetails.isUpdatingRoleDetails ? "Edit User Role" : "Add User Role"}
      showFooter={false}
      onClose={resetStates}>
      <div className="">
        <div>
          <label
            htmlFor="role"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
            User Role:
          </label>
          <input
            type="text"
            name="name"
            id="name"
            value={roleFormDetails.name}
            onChange={onChangeRoleDetails}
            className={`${tw_styles}`}
            placeholder="Role"
            autoComplete="true"
            required
          />
        </div>

        <div className="mt-4">
          <label
            htmlFor="description"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
            Description:
          </label>
          <textarea
            type="text"
            name="description"
            id="description"
            value={roleFormDetails.description}
            onChange={onChangeRoleDetails}
            className={`${tw_styles}`}
            placeholder="Description"
          />
        </div>

        <div className="mt-6 flex justify-end">
          <div>
            <button
              type="button"
              onClick={
                toggleRoleDetails?.isUpdatingRoleDetails
                  ? onSubmitUpdateRoleDetails
                  : onSubmitRoleDetails
              }
              disabled={toggleRoleDetails.isDisabled}
              className={`${
                toggleRoleDetails.isDisabled
                  ? "bg-gray-300 text-gray-600"
                  : `${btnPrimary()} text-white`
              } !px-4 !py-2 rounded-md tracking-wide font-semibold flex items-center gap-2`}>
              {" "}
              {toggleRoleDetails?.isUpdatingRoleDetails ? "Update" : "Submit"}
            </button>
          </div>

          <div className=" ml-3">
            <button onClick={resetStates} type="button" className={`${tw_styles}`}>
              Cancel
            </button>
          </div>
        </div>
      </div>
    </ModalComponent>
  );
};
export default AddRole;
