import InModalMessage from "../../common/Modal/InModalMessage";
import DragAndDrop from "../../common/FileUpload/DragAndDrop";

/**
 * Generates a function comment for the given function body in a markdown code block with the correct language syntax.
 *
 * @param {{fileDetails: object, onChangeInputHandler: function, onDragOver: function, onDrop: function, acceptTypes: array}} props
 * @param {object} fileDetails - the details of the file being uploaded
 * @param {function} onChangeInputHandler - the function to handle changes in the input
 * @param {function} onDragOver - the function to handle the drag over event
 * @param {function} onDrop - the function to handle the drop event
 * @param {array} acceptTypes - the array of accepted file types
 * @return {JSX.Element} the JSX element representing the view for uploading a CV
 */
const ViewUserUploadCV = ({
  fileDetails,
  onChangeInputHandler,
  onDragOver,
  onDrop,
  acceptTypes,
}) => {
  return (
    <>
      <InModalMessage
        styles={{
          parentClass: "!bg-green-100 !w-[80%] !flex !items-center !justify-center !text-green-700",
        }}
        message="Upload your CV/Resume here"
      />

      <DragAndDrop
        styles={{ parentClass: "!w-[80%] mx-auto" }}
        types={acceptTypes}
        value={fileDetails.fileName}
        boldMessage="Click here to"
        normalMessage="upload or drag and drop your CV/Resume"
        onChange={onChangeInputHandler}
        onDragOver={onDragOver}
        onDrop={onDrop}
      />
    </>
  );
};

export default ViewUserUploadCV;
