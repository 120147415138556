import { organizationDetailsState } from "./OrganizationDetailsContent";
import { settingsState } from "./Settings";

export const multipleFormsState = {
  organizationDetailsState: organizationDetailsState,
  settingsState: settingsState,
  steps: [
    { color: "blue", barColor: "blue", id: 0, orgDetails: false, name: "Organization Details" },
    { color: "gray", barColor: "blue", id: 1, settings: false, name: "Settings" },
  ],
  step: ["orgDetails", "settings"],
  activeStep: 0,
  stepsStatus: {},
  reFetchStatus: false,
};
