import { useEffect, useState } from "react";

const getCountries = async () => {
  try {
    const res = await fetch("https://restcountries.com/v3.1/all");
    const data = await res.json();
    const countries = data.map(country => country.name.common).toSorted();
    return countries;
  } catch (error) {
    console.error(error);
    //return empty error
    return [];
  }
};

const useCountriesAPI = () => {
  const [countries, setCountries] = useState([]);
  useEffect(() => {
    //fetch countries names for dropdown menu
    getCountries().then(res => setCountries(res));
  }, []);
  return {
    countries,
  };
};

export default useCountriesAPI;
