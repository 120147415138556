import { useState } from "react";
import { useSelector } from "react-redux";
import { Avatar } from "flowbite-react";

import ViewUserCVModal from "./ViewUserCVModal";

import FileSvg from "../../../media/FileSvg";

/**
 * Renders the header component for viewing user details.
 *
 * @param {Object} userDetails - The user details object.
 * @return {JSX.Element} The rendered header component.
 */
const ViewUserDetailsHeader = ({ userDetails }) => {
  const [viewCV, setViewCV] = useState(false);
  const { skills } = useSelector(state => state.employment.user);

  const { imageBytes, firstName, lastName, email, address, reportingManager, employmentType } =
    userDetails;
  return (
    <>
      <header className="flex gap-4">
        <div className="w-[45%] bg-gray-200 px-6 py-4 rounded-md">
          {imageBytes && (
            <Avatar
              rounded={true}
              img={imageBytes && `data:image/jpeg;base64,${imageBytes}`}
              size="xl"
            />
          )}

          {!imageBytes && (
            <div className="bg-gray-100 w-[6rem] h-[6rem] rounded-full grid place-items-center">
              <span className="text-2xl font-semibold text-gray-700">
                {`${firstName && firstName[0].toUpperCase()}${
                  lastName && lastName[0].toUpperCase()
                }`}
              </span>
            </div>
          )}

          <h2 className="font-bold text-lg text-gray-900 mt-4">
            {firstName} {lastName}
          </h2>
          <h4 className="font-semibold text-gray-900">{email}</h4>
          <h4 className="font-semibold text-gray-900">{address}</h4>
        </div>
        <div className="grid grid-cols-3 grid-rows-[repeat(2,4rem)] gap-x-8 gap-y-3 w-[100%]">
          <div className="border-r border-gray-200">
            <h5 className="font-semibold text-gray-700">CV/Resume</h5>
            <button
              onClick={setViewCV.bind(null, true)}
              className="text-blue-500 font-semibold flex items-center gap-1 mt-1">
              <FileSvg className="stroke-[1px]" size="18" />
              <span>View CV/Resume</span>
            </button>
          </div>
          <div>
            <h5 className="font-semibold text-gray-700">Reports To</h5>
            {reportingManager && (
              <div className="bg-gray-200 rounded-md p-2 mt-1 w-fit">
                <span className="font-semibold">
                  {`${reportingManager.firstName} ${reportingManager.lastName}`}
                </span>
              </div>
            )}
          </div>
          <div className="col-start-1 col-end-2 row-start-2 row-end-3 border-r border-gray-200">
            <h5 className="font-semibold text-gray-700">Employee Type</h5>
            <div>
              <span className="font-semibold">{employmentType?.name}</span>
            </div>
          </div>
          <div className="col-start-2 col-end-3 row-start-2 row-end-3 border-r border-gray-200">
            <h5 className="font-semibold text-gray-700">Skills</h5>
            <span className="font-semibold">{skills.rawSelectedSkill.length}</span>
          </div>
          <div className="col-start-3 col-end-4 row-start-2 row-end-3">
            <h5 className="font-semibold text-gray-700">
              Total Exp. <span className="text-sm"> (in years)</span>
            </h5>
            <span className="font-semibold">{userDetails.totalExperience}</span>
          </div>
        </div>
      </header>

      <ViewUserCVModal
        viewCV={viewCV}
        setViewCV={setViewCV}
        firstName={userDetails.firstName}
        lastName={userDetails.lastName}
        userId={userDetails.id}
      />
    </>
  );
};

export default ViewUserDetailsHeader;
