import { useNavigate } from "react-router";
import { Avatar } from "flowbite-react";

import { EntityType } from "./../../../enum/EntityType";
import { approx_time_show } from "../../../utils/common";
import { useStore } from "../../../context/createFastContext";
import { forwardRef } from "react";
import { Role } from "../../../enum/Role";

const commonStyle =
  "border-b border-gray-200 w-full hover:bg-gray-200 focus:bg-gray-200 grid grid-cols-[min-content_1fr] gap-5 p-4 cursor-pointer transition-colors duration-300";
const activeStyle = `${commonStyle} bg-blue-100`;
const inactiveStyle = `${commonStyle} bg-white`;

/**
 * Renders a single notification item.
 *
 * @param {Object} notification - The notification object containing the data for the notification item.
 * @return {JSX.Element} - The rendered notification item as a React component.
 */
const NotificationItem = forwardRef(({ notification }, ref) => {
  const [firstName, lastName] = notification.actorName.split(" ");
  const calculatedTime = approx_time_show(notification.dateCreated);
  const [currentUser, _] = useStore(s => s.userInfoById);

  const navigate = useNavigate();

  const onClickNavigate = function (url) {
    navigate(url);
  };

  const date = new Date(notification.entityDate);
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  //since we have only two type of notification yet. I'm using ternary operator
  const URL =
    notification.entityType === EntityType.Leave
        ? `/leaves/my-approvals?leaveId=${notification.entityId}&userId=${notification.fromUserId}`
       : `/payslips?month=${month}&year=${year}`;

  return (
    <li
      ref={ref}
      onClick={onClickNavigate.bind(null, URL)}
      className={calculatedTime.toLowerCase() === "just now" ? activeStyle : inactiveStyle}>
      <div className="w-[3rem] h-[3rem] rounded-full overflow-hidden flex items-center justify-center self-center">
        <Avatar
          rounded={true}
          placeholderInitials={firstName && lastName && `${firstName[0]} ${lastName[0]}`}
          className="object-cover w-full h-full block"
          img={
            (notification.imageBytes ||
              (currentUser?.imageBytes && currentUser.id !== Role.Administrator)) &&
            `data:image/jpeg;base64,${notification.imageBytes || currentUser?.imageBytes}`
          }
        />
      </div>
      <div className="flex flex-col items-start gap-3">
        <p className="text-gray-700 font-semibold tracking-wide">
          {notification.notificationMessage}
        </p>
        <span className="text-blue-700 font-semibold tracking-wide">{calculatedTime}</span>
      </div>
    </li>
  );
});

export default NotificationItem;
