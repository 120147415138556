import { useSelector } from "react-redux";
import Noitemfound from "../NoItemFound/Noitemfound";
import moment from "moment";

const tableDataStyles = {
  display: "-webkit-box",
  WebkitBoxOrient: "vertical",
  WebkitLineClamp: "1",
};

/**
 * Renders a list of exceptions.
 *
 * @return {ReactNode} The rendered list of exceptions or no exceptions found message.
 */
const LogsList = () => {
  const { exceptions, totalRecords } = useSelector(state => state.exceptionLogs);

  return (
    <>
      <Noitemfound
        colSpan="3"
        message="No Logs Found"
        itemsArr={exceptions}
        isTable={true}
      />

      {exceptions.length > 0 &&
        exceptions.map((item, index) => {
          const { id, type, createdAt, stackTrace } = item;
          return (
            <tr
              data-id={id}
              className="border-b dark:border-gray-600 hover:bg-gray-100 dark:hover:bg-gray-700"
              key={index}>
              <td className="p-4 w-[30%]">
                ID: {id} DateTime: {moment(`${createdAt}Z`).format("YYYY-MM-DD HH:mm:ss")}
              </td>
              <td className="p-4 w-[25%]">{type}</td>
              <td
                data-stack="webkit"
                style={tableDataStyles}
                className="mt-4 px-4 break-all h-full overflow-hidden">
                {stackTrace}
              </td>
            </tr>
          );
        })}
    </>
  );
};

export default LogsList;
