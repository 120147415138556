//Accrual enums
export const AccrualIntervalDetails = {
  NONE: 0,
  ONE_TIME: 1,
  MONTHLY: 2,
  YEARLY: 3,
};

export const ResetIntervalDetails = {
  NONE: 0,
  ONE_TIME: 1,
  MONTHLY: 2,
  YEARLY: 3,
};
export const CarryForwardTypeDetails = {
  NONE: 0,
  UNIT: 1,
  PERCENTAGE: 2,
};

export const EffectiveAfterUnitDetails = {
  NONE: 0,
  DAY: 1,
  MONTH: 2,
  YEAR: 3,
};