import { useEffect, useState } from "react";

import ApplyLeaveNormal from "./ApplyLeaveNormal";
import ApplyLeaveFlexi from "./ApplyLeaveFlexi";
import SelectWithDropdown from "../../components/common/SelectWithDropdown/SelectWithDropdown";

import { createDropDownOptions } from "./LeaveCommon";
import ModalButtons from "./../../components/common/Modal/ModalButtons";
import InfoIconSvg from "../../media/InfoIconSvg";
import { useSelector } from "react-redux";

const flexiLeaveInfoMessage = "Flexi leave can only be applied on flexi holidays.";
const normalLeaveInfoMessage = "To apply for a full day leave, don't select the checkboxes.";

export const ApplyLeaveModalBody = ({
  pageNo,
  pageSize,
  handleApplyLeave,
  handleSubmit,
  handleSelectChange,
  setLeavesInfo,
  leavesInfo,
  onCloseModalHandler,
  isFlexi
}) => {
  const [modifiedLeaveTypes, setModifiedLeaveTypes] = useState();
  const { leavesDropdown } = useSelector(state => state.leaves);

  useEffect(() => {
    const modified = createDropDownOptions(leavesDropdown);

    setModifiedLeaveTypes(modified);
  }, [leavesDropdown.length]);
  
  const handleFirst_Second_Checkbox = e => {
    const { name, checked } = e.target;

    //if flexi leave is selected then return
    if (isFlexi) return;

    if (name === "StartFirstHalf" && checked === true) {
      setLeavesInfo(prev => {
        return {
          ...prev,
          startFirstHalf: true,
          startSecondHalf: false,
        };
      });
    } else if (name === "StartFirstHalf" && checked === false) {
      setLeavesInfo(prev => {
        return {
          ...prev,
          startFirstHalf: false,
          startSecondHalf: false,
        };
      });
    } else if (name === "StartSecondHalf" && checked === true) {
      setLeavesInfo(prev => {
        return {
          ...prev,
          startFirstHalf: false,
          startSecondHalf: true,
        };
      });
    } else if (name === "StartSecondHalf" && checked === false) {
      setLeavesInfo(prev => {
        return {
          ...prev,
          startFirstHalf: false,
          startSecondHalf: false,
        };
      });
    } else if (name === "EndFirstHalf" && checked === true) {
      setLeavesInfo(prev => {
        return {
          ...prev,
          endFirstHalf: true,
          endSecondHalf: false,
        };
      });
    } else if (name === "EndFirstHalf" && checked === false) {
      setLeavesInfo(prev => {
        return {
          ...prev,
          endFirstHalf: false,
          endSecondHalf: false,
        };
      });
    } else if (name === "EndSecondHalf" && checked === true) {
      setLeavesInfo(prev => {
        return {
          ...prev,
          endFirstHalf: false,
          endSecondHalf: true,
        };
      });
    } else if (name === "EndSecondHalf" && checked === false) {
      setLeavesInfo(prev => {
        return {
          ...prev,
          endFirstHalf: false,
          endSecondHalf: false,
        };
      });
    }
  };

  const setDefaultValue = () => {
    return !leavesInfo.leaveTypeId ? true : false;
  };

  return (
    <form className="px-4 py-2 mx-auto max-w-2xl overflow-x-hidden overflow-y-auto pt-6">
      <div
        className={`${
          isFlexi ? "flex flex-row" : "grid"
        }  gap-4 md:gap-6 md:grid-cols-2`}>
        {!isFlexi && (
          <ApplyLeaveNormal
            leavesInfo={leavesInfo}
            handleFirst_Second_Checkbox={handleFirst_Second_Checkbox}
            handleApplyLeave={handleApplyLeave}
            handleSelectChange={handleSelectChange}
            modifiedLeaveTypes={modifiedLeaveTypes}
          />
        )}
        {isFlexi && (
          <ApplyLeaveFlexi onChangeHandler={handleApplyLeave} flexLeaveInfo={leavesInfo} />
        )}

        {/* Leave Type */}
        <div className="col-start-1 col-end-2">
          <label
            htmlFor="leave-type"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
            Leave Type <span className="text-red-700">*</span>
          </label>

          {modifiedLeaveTypes?.length > 0 && (
            <div className="tc-dropdown">
              <SelectWithDropdown
                roundedDot={false}
                className="w-[16rem]"
                size="sm"
                dotClassName=""
                options={modifiedLeaveTypes}
                defaultValue="Select Leave Type"
                isDefaultValue={setDefaultValue()}
                handleChange={handleSelectChange}
                backgroundColor="bg-gray-100 h-[2.5rem] rounded-md"
              />
            </div>
          )}
        </div>
      </div>

      {/* Notes */}
      <div>
        <label
          htmlFor="leave-reason"
          className="block mt-6 mb-2 text-sm font-medium text-gray-900 dark:text-white">
          Notes
        </label>
        <textarea
          id="leave-reason"
          rows="4"
          name="reason"
          onChange={handleApplyLeave}
          className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-thynkwebPrimary-600 focus:border-thynkwebPrimary-600 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-thynkwebPrimary-500 dark:focus:border-thynkwebPrimary-500"
          placeholder="Notes"></textarea>
      </div>

      <div className="flex items-center gap-2 text-gray-600 mt-2">
        <InfoIconSvg className="w-4 h-4 !fill-gray-600" />
        <span className="text-sm font-[500]">
          {isFlexi ? flexiLeaveInfoMessage : normalLeaveInfoMessage}
        </span>
      </div>

      {/* Button to Update leave information */}
      <ModalButtons
        isActionDeleteBtn={false}
        onClickAction={handleSubmit}
        onClickCancel={onCloseModalHandler}
        actionBtnText="Submit"
        cancelBtnText="Cancel"
      />
    </form>
  );
};
