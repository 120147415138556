import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Skills from "./../../EmployeeManagement/ViewDetails/ViewDetailsTabs/Skills";
import ModalComponent from "../../common/Modal/Modal";
import SkillsComponent from "../../EmployeeManagement/ViewDetails/SkillsComponent";

import { btnPrimary } from "../../../utils/helper";
import { addSkill } from "../../../store/slices/employment";

const ProfileSkillTab = ({ user }) => {
  const [isAddModalVisible, setIsAddModalVisible] = useState(false);
  const dispatch = useDispatch();
  const { activeTab } = useSelector(state => state.profile.tabs);
  const isActive = activeTab === "skills";

  const toggleAddModal = () => {
    setIsAddModalVisible(!isAddModalVisible);
  };

  const onClickAddSkillHandler = data => {
    dispatch(addSkill(data));
    toggleAddModal();
  };

  const onCloseSkillHandler = () => {
    toggleAddModal();
  };

  return (
    <>
      {isActive && (
        <>
          <div className="col-span-2">
            <div className="mb-4 bg-white rounded-lg shadow sm:p-6 xl:px-6 xl:py-4 px-3 py-2 dark:bg-gray-800">
              <div className="flex items-end justify-between mb-3">
                <h3 className="m-0 p-0 text-xl font-bold dark:text-white">Skills</h3>
                <button
                  className={`${btnPrimary()} px-4 py-2 text-white font-semibold tracking-wide rounded-md`}
                  onClick={toggleAddModal}>
                  Add Skill
                </button>
              </div>

              <Skills isProfile={true} />
            </div>
          </div>

          <ModalComponent
            show={isAddModalVisible}
            onClose={toggleAddModal}
            bodyClassName="px-9 mt-4 pb-8"
            showFooter={false}
            heading="Add Skill">
            <SkillsComponent
              onCancel={onCloseSkillHandler}
              onSubmit={onClickAddSkillHandler}
              user={user}
            />
          </ModalComponent>
        </>
      )}
    </>
  );
};

export default ProfileSkillTab;
