import { Button, Tabs } from "flowbite-react";
import React, { useEffect, useRef, useState } from "react";
import { tabOptions } from "./helper";
import { useAppDispatch, useAppSelector } from "../../store/store";
import {
  resetStatutoryEpfDetails,
  resetStatutoryEsiDetails,
  resetStatutoryProfessionalTaxDetails,
  resetStatutoryState,
  setStatutoryEpfDetails,
  setStatutoryEsiDetails,
  setStatutoryProfessionalTaxDetails,
  setStatutoryToggleDetails,
} from "../../store/slices/statutorySlice";
import ModalComponent from "../../components/common/Modal/Modal";
import EpfComponent from "./StatutoryComponents/EpfComponent";
import EpfDetails from "./StatutoryComponents/EpfDetails";
import InitialEpfContents from "./StatutoryComponents/InitialEpfContents";
import EsiComponent from "./StatutoryComponents/EsiComponent";
import { getStatutoryComponent } from "../../services/StatutoryService";
import InitialEsiComponent from "./StatutoryComponents/InitialEsiComponent";
import EsiDetails from "./StatutoryComponents/EsiDetails";
import { StatutoryComponentType } from "../../enum/StatutoryComponentType";
import InitialProfessionalTaxComponent from "./StatutoryComponents/InitialProfessionalTaxComponent";
import ProfessionalTaxComponent from "./StatutoryComponents/ProfessionalTaxComponent";
import ProfessionalTaxDetails from "./StatutoryComponents/ProfessionalTaxDetails";

const StatutoryComponent = () => {
  const tabsRef = useRef(null);
  const dispatch = useAppDispatch();
  const statutory = useAppSelector(state => state.statutory);

  const {
    statutoryToggleDetails,
    statutoryEpfDetails,
    statutoryEsiDetails,
    statutoryProfessionalTaxDetails,
  } = statutory;

  //State for the tabs (EPF, ESI)
  const [activeTab, setActiveTab] = useState(tabOptions[0]);

  useEffect(() => {
    if (activeTab === "epf") {
      getEpfComponentDetails();
    }
    if (activeTab === "esi") {
      getEsiComponentDetails();
    }
    if (activeTab === "professionalTax") {
      getProfessionalTaxComponentDetails();
    }
  }, [
    activeTab,
    statutoryToggleDetails.isEpfEnabled,
    statutoryToggleDetails.toggleUpdateStatutoryModal,
    statutoryToggleDetails.isEsiEnabled,
    statutoryToggleDetails.toggleUpdateEsiModal,
    statutoryToggleDetails.isProfessionalTaxEnabled,
    statutoryToggleDetails.toggleUpdateProfessionalTaxModal,
  ]);

  const handleTabChange = tab => {
    setActiveTab(tabOptions[tab]);
    dispatch(resetStatutoryState());
  };

  //Function for closing the Epf modal component
  const onCloseEpfModal = () => {
    dispatch(
      setStatutoryToggleDetails({
        ...statutoryToggleDetails,
        toggleStatutoryModal: false,
        toggleUpdateStatutoryModal: false,
      })
    );
    dispatch(resetStatutoryEpfDetails());
  };

  //Function for closing the ESI modal component
  const onCloseEsiModal = () => {
    dispatch(
      setStatutoryToggleDetails({
        ...statutoryToggleDetails,
        toggleEsiModal: false,
        toggleUpdateEsiModal: false,
      })
    );
    dispatch(resetStatutoryEsiDetails());
  };

  //Function for closing the Professional Tax modal component
  const onCloseProfessionalTaxModal = () => {
    dispatch(
      setStatutoryToggleDetails({
        ...statutoryToggleDetails,
        toggleProfessionalTaxModal: false,
        toggleUpdateProfessionalTaxModal: false,
      })
    );
    dispatch(resetStatutoryProfessionalTaxDetails());
  };

  // Function for getting EPF details from the server
  const getEpfComponentDetails = async () => {
    const response = await getStatutoryComponent(StatutoryComponentType.EPF);
    if (response?.data?.data) {
      dispatch(
        setStatutoryToggleDetails({
          ...statutoryToggleDetails,
          isEpfEnabled: true,
        })
      );
      dispatch(
        setStatutoryEpfDetails({
          ...statutoryEpfDetails,
          ...response.data.data,
        })
      );
    } else {
      dispatch(
        setStatutoryToggleDetails({
          ...statutoryToggleDetails,
          isEpfEnabled: false,
        })
      );
    }
  };

  //Function for getting ESI details from the server.
  const getEsiComponentDetails = async () => {
    const response = await getStatutoryComponent(StatutoryComponentType.ESI);
    if (response?.data?.data) {
      dispatch(
        setStatutoryToggleDetails({
          ...statutoryToggleDetails,
          isEsiEnabled: true,
        })
      );
      dispatch(
        setStatutoryEsiDetails({
          ...statutoryEsiDetails,
          ...response.data.data,
        })
      );
    } else {
      dispatch(
        setStatutoryToggleDetails({
          ...statutoryToggleDetails,
          isEsiEnabled: false,
        })
      );
    }
  };

  //Function for getting Professional Tax details from the server.
  const getProfessionalTaxComponentDetails = async () => {
    const response = await getStatutoryComponent(StatutoryComponentType.ProfessionalTax);
    if (response?.data?.data) {
      dispatch(
        setStatutoryToggleDetails({
          ...statutoryToggleDetails,
          isProfessionalTaxEnabled: true,
        })
      );
      dispatch(
        setStatutoryProfessionalTaxDetails({
          ...statutoryProfessionalTaxDetails,
          ...response.data.data,
        })
      );
    } else {
      dispatch(
        setStatutoryToggleDetails({
          ...statutoryToggleDetails,
          isProfessionalTaxEnabled: false,
        })
      );
    }
  };

  //Function for modal closing
  const onCloseModal = () => {
    if (activeTab === "epf") {
      return onCloseEpfModal;
    } else if (activeTab === "esi") {
      return onCloseEsiModal;
    } else {
      return onCloseProfessionalTaxModal;
    }
  };

  //Function for showing modal for different scenarios.
  const showModal = () => {
    return (
      statutoryToggleDetails.toggleUpdateStatutoryModal ||
      statutoryToggleDetails.toggleStatutoryModal ||
      statutoryToggleDetails.toggleEsiModal ||
      statutoryToggleDetails.toggleUpdateEsiModal ||
      statutoryToggleDetails.toggleProfessionalTaxModal ||
      statutoryToggleDetails.toggleUpdateProfessionalTaxModal
    );
  };

  return (
    <>
      <section className="bg-gray-100 min-h-[calc(100vh-61px)] dark:bg-gray-900 py-3 sm:py-5">
        <div className="px-4 mx-auto lg:px-12">
          <div className="border-b-2">
            <p className="text-4xl text-gray-900 dark:text-white py-4 cursor-pointer">
              Statutory Components
            </p>
            <div className="relative text-gray-900 dark:text-white tab-payslip-links ">
              <Tabs.Group
                aria-label="Default tabs"
                ref={tabsRef}
                onActiveTabChange={handleTabChange}>
                <Tabs.Item active title="EPF"></Tabs.Item>
                <Tabs.Item title="ESI"></Tabs.Item>
                <Tabs.Item title="Professional Tax"></Tabs.Item>
              </Tabs.Group>
            </div>
          </div>

          {activeTab === "epf" && (
            <div className="epf-container">
              {!statutoryToggleDetails.isEpfEnabled && <InitialEpfContents />}

              {statutoryToggleDetails.isEpfEnabled && <EpfDetails />}
            </div>
          )}

          {activeTab === "esi" && (
            <div className="esi-container">
              {!statutoryToggleDetails.isEsiEnabled && <InitialEsiComponent />}

              {statutoryToggleDetails.isEsiEnabled && <EsiDetails />}
            </div>
          )}

          {activeTab === "professionalTax" && (
            <div className="professional-tax-container">
              {!statutoryToggleDetails.isProfessionalTaxEnabled && (
                <InitialProfessionalTaxComponent />
              )}
              {statutoryToggleDetails.isProfessionalTaxEnabled && <ProfessionalTaxDetails />}
            </div>
          )}
        </div>
      </section>
      {/* Statutory Modal */}
      <ModalComponent
        show={showModal()}
        heading={
          statutoryToggleDetails.toggleEsiModal || statutoryToggleDetails.toggleUpdateEsiModal
            ? "Employees' State Insurance"
            : statutoryToggleDetails.toggleProfessionalTaxModal ||
                statutoryToggleDetails.toggleUpdateProfessionalTaxModal
              ? "Professional Tax"
              : "Employees' Provident Fund"
        }
        showFooter={true}
        size="3xl"
        onClose={onCloseModal()}>
        {activeTab === "epf" && <EpfComponent onCloseEpfModal={onCloseEpfModal} />}

        {activeTab === "esi" && <EsiComponent onCloseEsiModal={onCloseEsiModal} />}

        {activeTab === "professionalTax" && (
          <ProfessionalTaxComponent onCloseProfessionalTaxModal={onCloseProfessionalTaxModal} />
        )}
      </ModalComponent>
    </>
  );
};

export default StatutoryComponent;
