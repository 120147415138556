import { useDispatch } from "react-redux";
import { RxCross2 } from "react-icons/rx";
import { CiSquarePlus } from "react-icons/ci";

import TCList from "./TCList";

import { setLoadingFirstTC } from "../../../../store/slices/TCSlice";
import { InvoiceCustomField } from "../../../../enum/InvoiceCustomField";
import { deleteCustomFields } from "../../../../services/InvoiceService";

const TermsAndConditions = ({ updateToggleState, TC, setFormValues }) => {
  const dispatch = useDispatch();
  const onClickAddMoreHandler = () => {
    //create T&C field
    const newField = {
      groupId: InvoiceCustomField.TermsAndConditions,
      fieldName: "",
      id: Date.now(),
    };

    setFormValues(prevFV => ({ ...prevFV, TAndC: [...prevFV.TAndC, newField] }));
  };

  const onClickDeleteAllHandler = () => {
    const PROMISES = TC.map(field => {
      //If editing invoice pass the field id and invoiceId
      if (field?.invoiceId) return deleteCustomFields(field.id, field?.invoiceId);
      //If creating invoice pass the field id only and don't send the delete request if the fieldName is empty
      if (field?.id && field?.fieldName !== "") return deleteCustomFields(field.id);
    });

    //send delete request in parallel
    Promise.allSettled(PROMISES).then(() => {
      // hide terms and condition if there's no item in the array
      dispatch(setLoadingFirstTC(true));
      updateToggleState("isTermsAndConditionsVisible", false);
      setFormValues(prevFV => ({ ...prevFV, TAndC: [] }));
    });
  };

  return (
    <section className="bg-[#f7faff] p-2 mb-9 flex flex-col justify-start">
      <div className="w-[95%]">
        <div className="flex items-center justify-between">
          <h6 className="font-medium mb-2 pd-1 text-gray-700 border-dotted border-b-2 border-gray-700">
            Terms and Conditions
          </h6>
          <button
            className="bg-transparent hover:bg-gray-200 focus:bg-gray-200 p-1 rounded transition-colors w-fit outline-none"
            onClick={onClickDeleteAllHandler}>
            <RxCross2 size={25} color="#cdc733" />
          </button>
        </div>

        <ol type="1" className="list-decimal ml-6 mb-8 font-semibold text-gray-900">
          {TC.length > 0 && <TCList setFormValues={setFormValues} TC={TC} />}
        </ol>

        <button className="flex items-end gap-2">
          <div>
            <CiSquarePlus size={24} color="#cdc733" />
          </div>
          <span className="text-[#637485]" onClick={onClickAddMoreHandler}>
            Add More Fields
          </span>
        </button>
      </div>
    </section>
  );
};

export default TermsAndConditions;
