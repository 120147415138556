import React, { useState, useEffect } from "react";

import { updateAnnouncements } from "../../../services/CommonService";
import ModalButtons from "../../../components/common/Modal/ModalButtons";
import ModalComponent from "../../../components/common/Modal/Modal";
import { showToast } from "../../../components/common/Toast";
import { ICON_COLOR_MAPPING, ICON_MAPPING } from "../../../constants/Constant";
import InputFields from "../../../components/common/Input/InputFields";
import { annouceClassName, annouceIconClassName } from "./helper";
import { ANNOUCEMENT_MESSAGES } from "../../../constants/Messages";
import TimeSelect from "../../../components/common/TimeSelect/TimeSelect";
import { TimestampInfo, convertTime_To_24Hour_Format } from "../../../utils/common";
import MandatorySign from "../../../components/common/Input/MandatorySign";

const UpdateAnnouncement = ({
  show,
  onClose,
  renderAnnouncement,
  setLoading,
  announcementInfo,
}) => {
  const [announcementDetails, setAnnouncementDetails] = useState(announcementInfo);
  //State for toggling announcement icon and color
  const [announcementIconAndIconColorToggle, setAnnouncementIconAndIconColorToggle] = useState({
    isIconToggle: false,
    isIconColorToggle: false,
  });

  //State for selecting and storing values for announcement icon and color
  const [selectedAnnouncementIconAndIconColor, setSelectedAnnouncementIconAndIconColor] = useState({
    selectedIcon: {},
    selectedIconColor: {},
  });

  useEffect(() => {
    // Update announcementDetails when announcementInfo changes
    setAnnouncementDetails(announcementInfo);
    const iconDetails = ICON_MAPPING.filter(iconObject => {
      if (iconObject.iconName === announcementInfo.icon) {
        return iconObject;
      }
    });

    setSelectedAnnouncementIconAndIconColor({
      selectedIcon: iconDetails[0],
      selectedIconColor: {
        color: announcementInfo.colorCode,
      },
    });
  }, [announcementInfo]);

  const [, , ...rest] = TimestampInfo(announcementDetails.date);
  const formattedTime = rest.pop();
  const _Hours =
    formattedTime.split(" ")[0].split(":")[0] < 10
      ? "0" + formattedTime.split(" ")[0].split(":")[0]
      : formattedTime.split(" ")[0].split(":")[0];
  const _Minutes = formattedTime.split(" ")[0].split(":")[1];
  const _AmPm = formattedTime.split(" ")[1];

  const onChangeAnnouncement = event => {
    let { name, value } = event.target;

    setAnnouncementDetails(prev => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const onCloseModalHandler = () => {
    onClose();
  };

  const onSubmitUpdateAnnouncement = async event => {
    event.preventDefault();

    const { id, name, date, hours, minutes, ampm } = announcementDetails;

    const selectedTime = `${hours || _Hours}:${minutes || _Minutes} ${ampm || _AmPm}`;

    const [_hours, _minutes] = convertTime_To_24Hour_Format(selectedTime);
    const formattedDateTime = `${date.split("T")[0]}T${_hours}:${_minutes}:00`;
    const { iconName } = selectedAnnouncementIconAndIconColor.selectedIcon;
    const { color } = selectedAnnouncementIconAndIconColor.selectedIconColor;

    const currentAnnouncementDetails = {
      id: id,
      name: name,
      date: formattedDateTime,
      icon: iconName,
      colorCode: color,
    };

    if (name.trim() === "" || date === "") return showToast("warn", ANNOUCEMENT_MESSAGES.WARN);

    const { toast, toastId } = showToast("loading", ANNOUCEMENT_MESSAGES.LOADING_UPDATE);
    //Updating announcement details
    await updateAnnouncements(currentAnnouncementDetails);
    showToast("success", ANNOUCEMENT_MESSAGES.UPDATE);
    toast.dismiss(toastId);

    setLoading(true);
    renderAnnouncement();
    onModalCloseHandler();
    setLoading(false);
  };

  const onModalCloseHandler = () => {
    onClose();
  };

  //Toggling icon and icon color
  const onToggleIconAndIconColor = toggleOption => {
    if (toggleOption === "toggleIcon") {
      setAnnouncementIconAndIconColorToggle(prev => {
        return {
          ...prev,
          isIconToggle: !prev.isIconToggle,
          isIconColorToggle: false,
        };
      });
      return;
    }
    if (toggleOption === "toggleIconColor") {
      setAnnouncementIconAndIconColorToggle(prev => {
        return {
          ...prev,
          isIconColorToggle: !prev.isIconColorToggle,
          isIconToggle: false,
        };
      });
    }
  };

  //Storing icon and iconColors
  const onSubmitIconAndIconColor = (selectedItem, selectedItemId) => {
    if (selectedItem === "icon") {
      const currentIcon = ICON_MAPPING.filter(icon => {
        return selectedItemId === icon.id;
      });
      setSelectedAnnouncementIconAndIconColor(prev => {
        return {
          ...prev,
          selectedIcon: currentIcon[0],
        };
      });
      setAnnouncementIconAndIconColorToggle(prev => {
        return {
          ...prev,
          isIconToggle: false,
        };
      });
      return;
    }

    if (selectedItem === "iconColor") {
      const currentColor = ICON_COLOR_MAPPING.filter(color => {
        return selectedItemId === color.id;
      });

      setSelectedAnnouncementIconAndIconColor(prev => {
        return {
          ...prev,
          selectedIconColor: currentColor[0],
        };
      });

      setAnnouncementIconAndIconColorToggle(prev => {
        return {
          ...prev,
          isIconColorToggle: false,
        };
      });
      return;
    }
  };

  const handleTime = (val, type) => {
    setAnnouncementDetails({
      ...announcementDetails,
      [type]: val < 10 ? "0" + val : val.toString(),
    });
  };

  return (
    <>
      <ModalComponent
        show={show}
        onClose={onCloseModalHandler}
        showFooter={false}
        heading="Update Announcement">
        <form className="mx-auto max-w-2xl p-4 m-auto overflow-x-hidden overflow-y-auto">
          <div className="grid gap-4 mb-4 md:gap-6 md:grid-cols-2 sm:mb-8">
            {/* Announcement name field*/}
            <InputFields
              InputField={{
                id: "title",
                label: "Announcement Name",
                type: "text",
                name: "name",
                placeholder: "Enter Announcement Name",
                required: true,
              }}
              onChange={onChangeAnnouncement}
              value={announcementDetails.name}
              inputClassName={annouceClassName}
            />

            {/* Icons and iconcolor section */}
            <div className=" relative">
              <div>
                <label
                  htmlFor="announcementIcon"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                  Announcement Icon
                  <MandatorySign />
                </label>
              </div>

              <div className="icon-iconColor-container  h-[60%] flex relative">
                {/* icon section*/}
                <div className="icon-container h-full">
                  <div
                    className={`w-[2.5rem] h-full cursor-pointer ${annouceIconClassName} !p-0 !m-0 flex justify-center items-center`}
                    onClick={() => {
                      onToggleIconAndIconColor("toggleIcon");
                    }}>
                    {selectedAnnouncementIconAndIconColor.selectedIcon?.iconComponent}
                  </div>
                  {announcementIconAndIconColorToggle.isIconToggle && (
                    <div className="border w-full min-h-[5.6rem] absolute bg-slate-50 z-10 flex gap-2 flex-wrap justify-between box-border p-2 ">
                      {ICON_MAPPING.map(item => {
                        return (
                          <span
                            key={item?.id}
                            onClick={onSubmitIconAndIconColor.bind(null, "icon", item?.id)}
                            className={`p-[0.4rem] border w-fit h-fit box-border cursor-pointer hover:bg-slate-300 ${
                              selectedAnnouncementIconAndIconColor.selectedIcon.id === item.id &&
                              "bg-slate-300"
                            }`}>
                            {item.iconComponent}
                          </span>
                        );
                      })}
                    </div>
                  )}
                </div>
                {/* iconColor section*/}
                <div className="color-container flex justify-center items-center">
                  <div className=" w-8 h-8 ml-0 flex justify-center items-center relative">
                    <div
                      className={`border w-[57%] h-[57%] rounded-sm`}
                      style={{
                        backgroundColor:
                          selectedAnnouncementIconAndIconColor?.selectedIconColor?.color,
                      }}
                      onClick={onToggleIconAndIconColor.bind(null, "toggleIconColor")}></div>

                    {announcementIconAndIconColorToggle.isIconColorToggle && (
                      <div className="border absolute top-8 left-[0.45rem] w-[9.8rem] min-h-[5rem] flex flex-wrap  gap-2 z-10 p-2 bg-slate-50">
                        {ICON_COLOR_MAPPING.map(item => {
                          return (
                            <div
                              key={item?.id}
                              className={`border w-4 h-4 rounded-sm cursor-pointer`}
                              style={{ backgroundColor: item?.color }}
                              onClick={onSubmitIconAndIconColor.bind(
                                null,
                                "iconColor",
                                item?.id
                              )}></div>
                          );
                        })}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {/* date section */}
            <InputFields
              InputField={{
                id: "date",
                label: "Announcement Date",
                type: "date",
                name: "date",
                placeholder: "Enter Announcement Date",
                required: true,
              }}
              onChange={onChangeAnnouncement}
              value={announcementDetails.date.split("T")[0]}
              inputClassName={annouceClassName}
              minDate={new Date().toISOString().split("T")[0]}
            />

            <div className="relative">
              <div>
                <label
                  htmlFor="time"
                  className="block mb-2 w- text-sm font-medium text-gray-900 dark:text-white">
                  Announcement Time
                  <MandatorySign />
                </label>
              </div>
              <TimeSelect
                buttonClass="w-52"
                dropdownClass="w-40"
                handleDropdown={handleTime}
                hours={announcementDetails.hours || _Hours}
                minutes={announcementDetails.minutes || _Minutes}
                ampm={announcementDetails.ampm || _AmPm}
              />
            </div>
          </div>

          {/* Button to update announcement */}
          <ModalButtons
            onClickAction={onSubmitUpdateAnnouncement}
            onClickCancel={onModalCloseHandler}
            actionBtnText="Update"
            cancelBtnText="Cancel"
          />
        </form>
      </ModalComponent>
    </>
  );
};

export default UpdateAnnouncement;
