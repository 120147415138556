const FormInput = ({ type, value, id, onChange, name, placeholder, className, otherProps }) => {

  return (
    <input
      className={`${className} focus:outline-none`}
      placeholder={placeholder}
      type={type}
      value={value || ''}
      name={name}
      id={id}
      onChange={onChange}
      {...otherProps}
    />
  );
};

export default FormInput;