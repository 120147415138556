import React, { useContext, useEffect, useState } from "react";
import PageSection from "../../components/Layout/PageSection/PageSection";
import { TbFileInvoice } from "react-icons/tb";
import { Button } from "flowbite-react";
import { GoPlus } from "react-icons/go";
import { btnPrimary } from "../../utils/helper";
import { IoFilter } from "react-icons/io5";
import InvoiceSvg from "../../media/InvoiceSvg";
import usePagination, { PaginationComponent } from "../../hooks/usePagination";
import { useSelector } from "react-redux";
import { InvoiceContext } from "../../context/InvoiceContext";
import { Link } from "react-router-dom";
import InvoicesFilter from "../../components/invoices/InvoicesFilter/InvoicesFilter";
import InvoicesList from "../../components/invoices/components/InvoicesList";
import { getInvoices } from "../../services/InvoiceService";
import { TABLE_HEADER } from "../../components/invoices/helper";

const Invoice = () => {
    const [isChange, setIsChange] = useState(false);
    const { isUpdateInvoiceList } = useContext(InvoiceContext);
    const [invoices, setInvoices] = useState();

    const [totalRecords, setTotalRecords] = useState(0);

    const { onPageChange, setPageSizeHandler } = usePagination(false, totalRecords);
    const { pageNo, pageSize } = useSelector(state => state.pagination);

    const [Filter, setFilter] = useState({
      customRangeFrom: "",
      customRangeTo: "",
      customRangeData: [],
      dateFrom: "",
      dateTo: "",
      dateData: [],
    });

    const clearFilter = () => {
      setFilter({
        customRangeFrom: "",
        customRangeTo: "",
        customRangeData: [],
        dateFrom: "",
        dateTo: "",
        dateData: [],
      });
    };

    const customRange = e => {
      const range = e.target.value;
      const rangeName = e.target.name;

      setFilter(pref => {
        return {
          ...pref,
          [rangeName]: range,
        };
      });
    };

    const setInvoicesRange = e => {
      e.preventDefault();

      const filterInvoices = invoices.data.filter(ele => {
        const isAmountInRange =
          ele.totalAmount > Number(Filter.customRangeFrom) &&
          ele.totalAmount < Number(Filter.customRangeTo);
        const isDateInRange = ele.invoiceDate > Filter.dateFrom && ele.invoiceDate < Filter.dateTo;
        if (isAmountInRange) return ele;
        if (isDateInRange) return ele;
        if (isAmountInRange && isDateInRange) return ele;
      });

      setFilter(pref => {
        return {
          ...pref,
          customRangeData: filterInvoices,
        };
      });
    };

    // Fetching all the invoices
    const fetchInvoices = (pageNo, pageSize) => {
      getInvoices(pageNo, pageSize).then(response => {
        setInvoices(response);
        setTotalRecords(response?.totalRecords);
      });
    };

    useEffect(() => {
      fetchInvoices(pageNo, pageSize);
      setInvoices();
    }, [pageSize, pageNo, isChange, isUpdateInvoiceList]);

    const [isOpen, setIsOpen] = useState(false);

    const openDropdown = () => {
      setIsOpen(true);
    };

    const closeDropdown = () => {
      setIsOpen(false);
    };


  return (
    <>
      <section className="tc-page h-[calc(100vh-61px)] py-3 sm:py-5 border border-red-500 border-opacity-0">
        <div className="px-4 mx-auto lg:px-12">
          <div className="flex justify-between">
            <div className="flex items-center gap-3">
              <div>
                <TbFileInvoice size={"2rem"} />
              </div>
              <h1 className="text-4xl text-gray-900 dark:text-white py-4 cursor-default">
                Invoices
              </h1>
            </div>
            <div>
              <div className="flex justify-end items-center">
                <button
                  id="openBtn"
                  className="!px-4 !py-2 rounded-md text-white bg-gray-400 tracking-wide font-semibold flex items-center gap-2 m-4"
                  onClick={openDropdown}>
                  {" "}
                  <span>
                    <IoFilter />
                  </span>
                  Filter
                </button>
                <Link to={"/Invoice/create"}>
                  <button
                    className={`${btnPrimary()} !px-4 !py-2 rounded-md text-white tracking-wide font-semibold flex items-center gap-2`}>
                    <span>
                      <InvoiceSvg />
                    </span>
                    Create Invoice
                  </button>
                </Link>
              </div>
            </div>
          </div>
          <div className="mx-auto flex justify-center items-center">
            <div className="flex items-center justify-end p-2 ">
              <InvoicesFilter
                isOpen={isOpen}
                openDropdown={openDropdown}
                closeDropdown={closeDropdown}
                setInvoicesRange={setInvoicesRange}
                clearFilter={clearFilter}
                customRange={customRange}
                Filter={Filter}
              />
            </div>
          </div>

          <div className="overflow-hidden bg-white shadow-md dark:bg-gray-800 sm:rounded-lg">
            <div className="px-4 divide-y dark:divide-gray-700">
              <div className="flex flex-col py-3 space-y-3 md:flex-row md:items-center md:justify-between md:space-y-0 md:space-x-4">
                <div className="flex items-center flex-1 space-x-4">
                  <h5>
                    <span className="text-gray-500">Invoices: </span>
                    <span className="dark:text-white">{invoices?.records}</span>
                  </h5>
                </div>
              </div>
              <div className="overflow-x-auto">
                <table className="w-full text-sm text-left">
                  <thead className="text-xs uppercase">
                    <tr>
                      {TABLE_HEADER.map(title => (
                        <th key={title} scope="col" className="px-6 py-3">
                          {title}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody className={`${invoices?.data?.length === 0 && "h-[8rem]"}`}>
                    {invoices?.data.length > 0 && (
                      <InvoicesList
                        isChange={isChange}
                        setIsChange={setIsChange}
                        invoices={
                          Filter.customRangeData.length !== 0
                            ? Filter.customRangeData
                            : invoices?.data
                        }
                      />
                    )}
                    {invoices?.data?.length === 0 && (
                      <tr className="border-0 border-opacity-0">
                        <td
                          colSpan={7}
                          className="tracking-wide text-center border-0 text-[#6b7280]">
                          No Invoices Found!
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>

              <PaginationComponent
                onChange={setPageSizeHandler}
                onPageChange={onPageChange}
                showIcons={true}
                totalRecords={totalRecords}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Invoice;
