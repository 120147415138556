import { Label, TextInput } from "flowbite-react";

/**
 * Renders additional information based on the active item.
 *
 * @param {{activeItem: string, details: object, handleAddNewClientData: Function}} props
 * @param {string} activeItem - The active item.
 * @param {object} details - The details object.
 * @param {function} handleAddNewClientData - The handler for adding new client data.
 * @return {JSX.Element} The additional information component.
 */
const AdditionalInfo = ({ activeItem, details, handleAddNewClientData }) => {
  const isActive = activeItem === "additional";
  return (
    <>
      {isActive && (
        <div className="!w-full grid gap-4 mb-7 md:grid-cols-2 md:auto-rows-min sm:grid-cols-1 pt-4">
          <div>
            <div>
              <Label
                htmlFor="business alias (nick name)"
                value="Full Name"
              />
            </div>
            <TextInput
              id="business alias (nick name)"
              type="text"
              name="aliasName"
              placeholder="Full Name"
              shadow={true}
              value={details.aliasName || ""}
              onChange={handleAddNewClientData}
            />
          </div>

          <div>
            <div>
              <Label
                htmlFor="email under additional details section"
                value="Email"
              />
            </div>
            <TextInput
              id="email under additional details section"
              type="email"
              name="email"
              placeholder="Email"
              shadow={true}
              value={details.email || ""}
              onChange={handleAddNewClientData}
            />
          </div>

          <div>
            <div className="mb-2 block">
              <Label
                htmlFor="phone number under additional details section"
                value="Phone Number"
              />
            </div>
            <TextInput
              id="email under additional details section"
              type="number"
              name="phoneNumber"
              placeholder="Phone Number"
              shadow={true}
              value={details.phoneNumber || ""}
              onChange={handleAddNewClientData}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default AdditionalInfo;
