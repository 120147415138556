import { createContext, useState } from "react";

export const PaymentResponseContext = createContext();

export const PaymentResponseContextProvider = ({ children }) => {
  const [paymentResponse, setPaymentResponse] = useState({});
  return (
    <PaymentResponseContext.Provider
      value={{ paymentResponse, setPaymentResponse }}
    >
      {children}
    </PaymentResponseContext.Provider>
  );
};
