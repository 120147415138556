import { memo } from "react";

const Toggle = ({ checked, onChange, name, id, otherProps }) => {
  return (
    <>
      <label
        className="relative inline-flex items-center cursor-pointer"
        data-id="toggle"
      >
        <input
          onChange={onChange}
          type="checkbox"
          value=""
          className="sr-only peer"
          name={name}
          checked={checked}
          id={id}
          {...otherProps}
        />
        <div className="tc-input-toggle w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-thynkwebPrimary-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-thynkwebPrimary-800"></div>
        <span className="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300"></span>
      </label>
    </>
  );
};

export default memo(Toggle);
