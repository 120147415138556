import BankDetails from "./Tabs/BankDetails";
import EmploymentDetails from "./Tabs/EmploymentDetails";
import GeneralInfo from "./Tabs/GeneralInfo";
import SalaryStructure from "./Tabs/SalaryStructure";

/**
 * Renders a set of tabs for displaying different sections.
 *
 * @param {{handleUserChange: function}} props - The properties for rendering the tabs.
 * @param {function} handleUserChange - A function to handle user change.
 * @return {JSX.Element} - The rendered tabs component.
 */
const EmployeeModalTabs = ({
  handleUserChange,
}) => {
  return (
    <>
      <GeneralInfo
        handleUserChange={handleUserChange}
      />
      <EmploymentDetails
        handleUserChange={handleUserChange}
      />
      <SalaryStructure
      />
      <BankDetails
        handleUserChange={handleUserChange}
      />
    </>
  );
};

export default EmployeeModalTabs;
