import React from 'react'
import { cn, formattedDate } from '../../../utils/common';
import { Link } from 'react-router-dom';
import { ICON_COLOR_MAPPING, ICON_MAPPING } from '../../../constants/Constant';
import { GiPalmTree } from "react-icons/gi";

const UpcomingHolidays = ({upcomingHolidays}) => {
  return (
    <div>
      <div className="w-full h-96 bg-white rounded-lg">
        <div className="flex items-center justify-between p-5 pb-3">
          <h5 className="text-xl font-bold leading-none text-gray-700 dark:text-white">
           Holidays
          </h5>
          <Link data-upcoming-holiday="upcoming-holiday" to="/holidays" className="text-sm font-medium text-blue-600 hover:underline dark:text-blue-500">
            View all
          </Link>
        </div>
        <div>
          <hr className="h-px my-2 bg-gray-200 border-0 dark:bg-gray-700"></hr>
        </div>
        {upcomingHolidays.length === 0 && (
        <div className="flex flex-col items-center relative">
          <GiPalmTree className="absolute top-10" size={"10rem"} color="#BFE2D2" />
          <p className="text-center absolute top-60 text-gray-500 dark:text-gray-400">No upcoming holidays</p>
        </div>
        )}
        {upcomingHolidays.length > 0 && (
        <div className="p-5 pt-0 flow-root scrollbar-hide" style={{ maxHeight: "calc(100% - 90px)" }}>
          <ul role="list" className=" divide-gray-200 dark:divide-gray-700">
            {upcomingHolidays.map(holiday => {
            const holidayicon = ICON_MAPPING.find(holidayicon => holidayicon.iconName === holiday.icon)?.iconComponent;
            const color = ICON_COLOR_MAPPING.find(bgcolor => bgcolor.color === holiday.colorCode)?.color;
            return (
              <li key={holiday.name} className="py-2 sm:py-2">
              <div className="flex items-center">
                  <><div className="flex-shrink-0">
                    <p className={"w-10 h-10 p-2 rounded-full bg-orange-300"} style={{backgroundColor: color}}>
                    {holidayicon}
                    </p>
                  </div><div className="flex-1 min-w-0 ms-4 pl-3">
                      <p className="text-sm font-medium text-gray-900 truncate dark:text-white">{holiday.name}</p>
                      <p className="text-sm text-gray-500 truncate dark:text-gray-400">{formattedDate(holiday.date)}</p>
                    </div></>
              </div>
              </li>
            )
            })}
          </ul>
        </div>
        )}
      </div>
    </div>
  );
}

export default UpcomingHolidays