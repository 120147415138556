export const PayrollStatus = {
    Draft: 0,
    Approved: 1,
    PaymentCompleted: 2,
    PaymentSkipped: 3,
    SalaryWithheld: 4,
    SalaryOnWithholdReleased: 5
}

export const PaymentStatusLabels = {
    [PayrollStatus.Draft]: "Yet to Pay",
    [PayrollStatus.Approved]: "Yet to Pay",
    [PayrollStatus.PaymentCompleted]: "Payment Completed",
    [PayrollStatus.PaymentSkipped]: "Payment Skipped",
    [PayrollStatus.SalaryWithheld]: "Salary Withhold",
    [PayrollStatus.SalaryOnWithholdReleased]: "Salary on Withold Released"
}