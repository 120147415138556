import { Dropdown } from "flowbite-react";
import { useSearchParams, useNavigate } from "react-router-dom";

import { RoleEmployee } from "./RoleEmployee";
import { AddEditEmployeeModal } from "./EditEmployee";

import EmailSvg from "../../media/EmailSvg";
import { formatDate } from "../../utils/helper";
import { Role } from "../../enum/Role";
import { cn, getUser } from "../../utils/common";
import { UserStatus } from "../../enum/UserStatus";
import { useState } from "react";
import { resendVerificationEmail } from "../../services/AccountService";
import { STATUS_CODES } from "../../constants/Constant";
import { showToast } from "../common/Toast";
import { EMPLOYEE_LIST_MESSAGES } from "../../constants/Messages";
import DeleteEmployee from "./DeleteEmployee";
import DeActivateEmployee from "./DeActivateEmployee";
import ReActivateEmployee from "./ReActivateEmployee";
import { Avatar } from "flowbite-react";
import { userManagementTabs } from "./helper";
import { useAppDispatch, useAppSelector } from "../../store/store";
import { setIsEditingUser, setRefetchUsers, setToggleDeactivateUserModal, setToggleDeleteUserModal, setToggleReactivateUserModal, setToggleUserModal, setUser } from "../../store/slices/userManagementSlice";
import { GetUserById } from "../../services/UserService";

const EmployeesList = ({
  users,
  // toggleSelectedUserCheckboxChange,
}) => {
  const dispatch = useAppDispatch();
  const umState = useAppSelector(state => state.userManagement);

  const [_, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const currentUser = getUser();

  //State for updating Role
  const [toggleChangeRoleModal, setToggleChangeRoleModal] = useState(false);

  const goToUserProfile = userId => {
    if (currentUser?.roleId === Role.Administrator || currentUser?.roleId === Role.Moderator) {
      navigate(`/profile?userId=${userId}`);
    } else {
      navigate("/403-Forbidden");
    }
  };

  const onClickNavigateHandler = user => setSearchParams({ userId: user?.id });

  //Function to submit resend verification email for the users whose email is not verified
  const onSubmitResendEmailVerification = async (userId, message) => {
    if (userId && message === "resendVerificationEmail") {
      const response = await resendVerificationEmail(userId);
      if (response?.status === STATUS_CODES.SUCCESS) {
        return showToast("success", EMPLOYEE_LIST_MESSAGES.RESEND_VERIFICATION_EMAIL_SUCCESS);
      } else {
        return showToast("error", EMPLOYEE_LIST_MESSAGES.RESEND_VERIFICATION_EMAIL_FAILED);
      }
    }

    if (userId && message === "sendVerificationEmail") {
      const response = await resendVerificationEmail(userId);
      if (response?.status === STATUS_CODES.SUCCESS) {
        return showToast("success", EMPLOYEE_LIST_MESSAGES.SEND_VERIFICATION_EMAIL_SUCCESS);
      } else {
        return showToast("error", EMPLOYEE_LIST_MESSAGES.SEND_VERIFICATION_EMAIL_FAILED);
      }
    }
  };

  // Function for toggling delete and and setting the user id
  const onHandleToggleDeleteUser = user => {
    dispatch(setUser(user));
    dispatch(setToggleDeleteUserModal(true));
  };

  //Function for toggling Deactivate, Reactivate user getting the user id
  const onHandleToggleDeactivateAndReactivateUser = (userAction, user) => {
    dispatch(setUser(user));
    //when user clicks on deactivate button
    if (userAction === "deactivate") {
      dispatch(setToggleDeactivateUserModal(true));
    }

    //when user clicks on reactivate button
    if (userAction === "reactivate") {
      dispatch(setToggleReactivateUserModal(true));
    }
  };

  const getIndividualUserDetails = async userId => {
    const res = await GetUserById(userId);
    const user = res?.data;
    dispatch(setUser(user));
  };

  return (
    <>
      {users?.map((user, i) => {
        return (
          <tr
            className="border-b dark:border-gray-600 hover:bg-gray-100 dark:hover:bg-gray-700"
            key={user?.id}>
            <th
              scope="row"
              className="px-4 py-1.5 font-medium text-gray-900 whitespace-nowrap dark:text-white flex">
              {/* {activeTab === userManagementTabs.restrictedLogin && (
                <div className="flex justify-center items-center">
                  <input
                    id="checkbox-all-search"
                    type="checkbox"
                    checked={user?.selected}
                    onChange={toggleSelectedUserCheckboxChange.bind(null, user?.id)}
                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                </div>
              )} */}

              <div
                className="flex items-center cursor-pointer"
                onClick={onClickNavigateHandler.bind(null, user)}
                role="button">
                <Avatar
                  rounded={true}
                  placeholderInitials={`${user?.firstName && user?.firstName[0].toUpperCase()}${
                    user?.lastName && user?.lastName[0].toUpperCase()
                  }`}
                  className="mx-2 z-auto cursor-pointer tc-small-avatar"
                  img={user?.imageBytes && `data:image/jpeg;base64,${user?.imageBytes}`}
                />
                <span className="text-xs text-gray-400 mr-2">{`${user?.employeeNumber ? user?.employeeNumber : ""}`}</span>
                {`${user?.firstName} ${user?.lastName?.slice(0, 11)}`}
              </div>
            </th>
            <td className="px-4 py-2">
              <div className="inline-flex gap-1 items-center bg-thynkwebPrimary-50 text-thynkwebPrimary-900 text-xs font-medium px-2 py-0.5 rounded dark:bg-thynkwebPrimary-900 dark:text-thynkwebPrimary-300">
                <EmailSvg />
                {user?.email}
              </div>
            </td>
            <td class="px-4 py-2">
              <div class="inline-flex items-center text-xs font-medium py-0.5 rounded">
                {user?.roleId === Role.Administrator && "Administrator"}
                {user?.roleId === Role.Moderator && "Moderator"}
                {user?.roleId === Role.Member && "Member"}
              </div>
            </td>
            <td className="px-4 py-2 whitespace-nowrap">{user?.phoneNumber}</td>
            <td className="px-4 py-2 whitespace-nowrap">{user?.department?.slice(0, 11)}</td>
            <td className="px-4 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white">
              <div className="flex items-center">
                {user?.status === UserStatus.Active ? (
                  <>
                    <div className="w-3 h-3 mr-2 bg-green-700 border rounded-full"></div>
                    Active
                  </>
                ) : user?.status === UserStatus.Deactivated ? (
                  <>
                    <div className="w-3 h-3 mr-2 bg-red-700 border rounded-full"></div>
                    Inactive
                  </>
                ) : user?.status === UserStatus.RestrictedLogin ? (
                  <>
                    <div className="w-3 h-3 mr-2 bg-yellow-400 border rounded-full"></div>
                    Restricted Login
                  </>
                ) : (
                  <>
                    <div className="w-3 h-3 mr-2 bg-yellow-500 border rounded-full"></div>
                    Pending Verification
                  </>
                )}
              </div>
            </td>
            <td className="px-4 py-2 whitespace-nowrap">{formatDate(`${user?.dateOfJoining}Z`)}</td>
            <td
              className="px-4 z-[1] py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white action-toggle-btn"
              >
              <Dropdown className="z-[1] w-[max-content]" label={"•••"} inline={true}>
                <Dropdown.Item
                  onClick={async () => {
                    await getIndividualUserDetails(user.id);
                    dispatch(setIsEditingUser(true));
                    dispatch(setToggleUserModal(true));
                  }}>
                  <button className="w-full !py-2 !px-4 h-full text-left">
                    Edit
                  </button>
                </Dropdown.Item>
                <Dropdown.Item>
                  <button
                    className="w-full !py-2 !px-4 h-full text-left"
                    onClick={goToUserProfile.bind(null, user.id)}>
                    View Profile
                  </button>
                </Dropdown.Item>
                {user?.status === UserStatus.Active && (
                  <Dropdown.Item
                    onClick={() => {
                      setToggleChangeRoleModal(true);
                      dispatch(setUser(user));
                    }}>
                    <button className="w-full !py-2 !px-4 h-full text-left">Set Role</button>
                  </Dropdown.Item>
                )}
                {user?.status === UserStatus.PendingEmailVerification && (
                  <Dropdown.Item
                    onClick={onSubmitResendEmailVerification.bind(
                      null,
                      user?.id,
                      "resendVerificationEmail"
                    )}>
                    <button className="w-full !py-2 !px-4 h-full text-left">
                      Resend Verification Email
                    </button>
                  </Dropdown.Item>
                )}
                {user?.status === UserStatus.Active && (
                  <Dropdown.Item
                    onClick={onHandleToggleDeactivateAndReactivateUser.bind(
                      null,
                      "deactivate",
                      user
                    )}>
                    <button className="w-full !py-2 !px-4 h-full text-left">
                      De-Activate user
                    </button>
                  </Dropdown.Item>
                )}

                {user?.status === UserStatus.Deactivated && (
                  <Dropdown.Item
                    onClick={onHandleToggleDeactivateAndReactivateUser.bind(
                      null,
                      "reactivate",
                      user
                    )}>
                    <button className="w-full !py-2 !px-4 h-full text-left">
                      Re-Activate user
                    </button>
                  </Dropdown.Item>
                )}
                {user?.status === UserStatus.RestrictedLogin && (
                  <Dropdown.Item
                    onClick={onSubmitResendEmailVerification.bind(
                      null,
                      user?.id,
                      "sendVerificationEmail"
                    )}>
                    <button className="w-full !py-2 !px-4 h-full text-left">
                      Send Verification Email
                    </button>
                  </Dropdown.Item>
                )}
                {currentUser.roleId === Role.Moderator && user.roleId === Role.Administrator ? null : (
                  <Dropdown.Item
                    onClick={onHandleToggleDeleteUser.bind(null, user)}
                    className={cn("text-red-500")}>
                    <button className="w-full !py-2 !px-4 h-full text-left">Delete</button>
                  </Dropdown.Item>
                )}
              </Dropdown>
            </td>
          </tr>
        );
      })}

      {/* User Add/Edit modal */}
      <AddEditEmployeeModal />

      <RoleEmployee
        toggleRole={toggleChangeRoleModal}
        setToggleRole={setToggleChangeRoleModal}
      />

      <DeleteEmployee />

      <DeActivateEmployee />

      <ReActivateEmployee />
    </>
  );
};

export default EmployeesList;
