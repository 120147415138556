export const EarningCategory = {
    Basic : 0,
    DearnessAllowance : 1,
    HouseRentAllowance : 2,
    FixedAllowance : 3,
    ConveyanceAllowance : 4,
    Bonus : 5,
    Commission : 6,
    ChildrenEducationAllowance : 7,
    HostelExpenditureAllowance : 8, 
    TransportAllowance : 9, 
    TravellingAllowance : 10, 
    LeaveTravelAllowance : 11,
    OverTimeAllowance : 12,
    ProjectAllowance : 13, 
    HolidayAllowance : 14, 
    TelephoneAllowance : 15, 
    FixedMedicalAllowance : 16, 
    FoodAllowance : 17, 
    EntertainmentAllowance : 18, 
    Coupon : 20, 
    FuelAllowance : 21, 
    TelephoneAndInternetAllowance : 22,
    EarnedLeaveEncashment : 23,
    CustomAllowance : 24,
    Stipend : 25
}