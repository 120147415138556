import React from "react";

/**
 * @namespace ProgressBar creates a ProgressBar
 * @param {object} props
 * @param {string} props.labelName accepts label name as a string
 * @param {boolean} props.labelInside accepts true or false to show progress value inside
 * @param {boolean} props.labelOutside accepts true or false to show progress value outside
 * @param {string} props.labelContainerClass accepts label container class as a string
 * @param {string} props.labelClassName accepts label classname as a string
 * @param {string} props.progressContainerClass accepts progress container's classname as a string
 * @param {number} props.progressValue accepts progress value as a number to be shown in percentage
 * @param {string} props.progressClass accepts progress value's classname as a string
 * @param {string} props.labelInsideClass acceepts label inside's classname as a string
 * @param {string} props.labelOutsideClass acceepts label outside's classname as a string
 * @returns Progress bar layout
 */

const ProgressBar = ({
  labelName,
  labelInside,
  labelOutside,
  labelContainerClass,
  labelClassName,
  progressContainerClass,
  progressValue,
  progressClass,
  labelInsideClass,
  labelOutsideClass,
}) => {
  return (
    <>
      {/* // Label Container */}
      <div className={`${labelContainerClass} flex justify-between mb-1`}>
        {labelName && (
          <span
            className={`${labelClassName} text-base font-medium text-lime-700 dark:text-white`}
          >
            {labelName}
          </span>
        )}
        {labelOutside && progressValue && (
          <span
            className={`${progressClass} text-sm font-medium text-lime-700 dark:text-white`}
          >
            {progressValue}%
          </span>
        )}
      </div>

      {/* // Progress Container */}
      <div
        className={`${progressContainerClass} w-full bg-gray-200 rounded-full dark:bg-gray-700`}
      >
        {/* // With Progress value inside */}
        {labelInside && progressValue && (
          <div
            className={`${
              labelInsideClass || "bg-lime-600"
            } text-xs font-medium text-blue-100 text-center leading-none rounded-full`}
            style={{ width: `${progressValue}%` }}
          >
            {" "}
            {progressValue}%
          </div>
        )}
        {/* // With Progress value outside */}
        {labelOutside && progressValue && (
          <div
            className={`${
              labelOutsideClass || "bg-lime-600"
            } h-2.5 rounded-full`}
            style={{ width: `${progressValue}%` }}
          ></div>
        )}
      </div>
    </>
  );
};

export default ProgressBar;
