import { memo } from "react";
import { v4 as uuidv4 } from "uuid";

//NOTE ----- when passing col, rows and tableClick memoized the function and objects.
const Table = ({
  tableClass,
  parentClass,
  headClass,
  tableConfig,
  col = [],
  thClassName,
  rows = [],
  children,
  tableClick,
  tdClass,
}) => {
  let tableClassName = tableClass
    ? tableClass
    : "w-full text-sm text-left text-gray-500 dark:text-gray-400 bg-white";

  return (
    <div className={`${parentClass} overflow-x-auto`}>
      <table className={tableClassName}>
        <Columns headClass={headClass} thClassName={thClassName} col={col} />

        {rows.length > 0 && (
          <tbody onClick={tableClick}>
            {rows &&
              rows.map(row => {
                return (
                  <Rows
                    key={uuidv4()}
                    tableConfig={tableConfig}
                    row={row}
                    tdClass={tdClass}
                  />
                );
              })}
          </tbody>
        )}

        {!rows.length && children}
      </table>
    </div>
  );
};

export default memo(Table);

const Columns = memo(({ col, headClass, thClassName }) => {
  return (
    <>
      <thead
        className={`${headClass} text-xs uppercase`}>
        <tr>
          {col.map((value, i) => {
            return (
              <th
                key={i}
                scope="col"
                className={`${thClassName} ${value === "items" && "!pl-4"}  ${
                  value === "action" && "!pr-4"
                } px-4 py-3`}>
                <span className="whitespace-nowrap">{value}</span>
              </th>
            );
          })}
        </tr>
      </thead>
    </>
  );
});

const Rows = memo(({ row = [], id, tdClass, tableConfig }) => {
  let dataset = JSON.stringify(row[0]);
  return (
    <>
      <tr
        className="cursor-pointer border-b dark:border-gray-600 hover:bg-gray-100 dark:hover:bg-gray-700"
        data-id={dataset}>
        {row[1]?.map((v, i) => {
          let cellElem = v;
          if (Array.isArray(v)) {
            cellElem = v[1];
          }

          let tdClassName = "";
          if (tableConfig) {
            const columnConfig = tableConfig[i];
            tdClassName = columnConfig ? columnConfig.className : "";
          }

          return (
            <td key={i} className={`${tdClassName} ${tdClass} px-4 py-2 whitespace-nowrap`}>
              {cellElem}
            </td>
          );
        })}
      </tr>
    </>
  );
});
