import { useCallback, useEffect } from "react";
import { MdError } from "react-icons/md";
import { CiSquarePlus } from "react-icons/ci";

import { validateInvoiceNumber } from "../../../services/InvoiceService";
import CustomField from "../CustomField";

import { onFutureDateChange, onDateChange } from "../helper";
import {
  onAddMoreHeaderField,
  getHeaderFieldFormValues,
  onDeleteHeaderField,
} from "../customFieldHelper";

const InvoiceDate = ({
  formValues,
  toggleStates,
  setFormValues,
  date,
  setDate,
  updateToggleState,
  isUpdating,
  invoiceId,
}) => {
  useEffect(() => {
    if (!isUpdating) {
      if (formValues.invoiceNumberDetails?.highestInvoiceNumber === null) {
        const firstInvoiceNumber = "A0001";
        setFormValues(prevFV => ({
          ...prevFV,
          invoiceNumber: firstInvoiceNumber,
        }));
      } else {
        const { invoiceNumberDetails } = formValues;
        setFormValues(prevFV => ({
          ...prevFV,
          invoiceNumber: invoiceNumberDetails?.highestInvoiceNumber,
        }));
      }
    }
  }, [formValues.invoiceNumberDetails]);

  const onInvoiceNumberChange = e => {
    if (!isUpdating) {
      //overwrite maxInvoiceNumber and invoice number on every key strokes
      setFormValues(prevFV => ({
        ...prevFV,
        invoiceNumberDetails: {
          ...prevFV.invoiceNumberDetails,
          highestInvoiceNumber: e.target.value,
        },
        invoiceNumber: e.target.value,
      }));
      updateToggleState("invoiceNumberChanged", true);
      return;
    }

    if (isUpdating) {
      updateToggleState("invoiceNumberChanged", true);
      setFormValues(prevFV => ({ ...prevFV, invoiceNumber: e.target.value }));
      return;
    }
  };

  //onBlur Invoice Number
  const onInvoiceNumberBlur = async () => {
    //return if invoiceNumberChanged is true
    if (!toggleStates.invoiceNumberChanged) return;

    const encodedInvoiceNumber = encodeURIComponent(formValues.invoiceNumber);
    //invoice number validation returns true/false if invoice number exist or not
    const res = await validateInvoiceNumber(encodedInvoiceNumber, invoiceId);
    updateToggleState("invoiceNumberValidation", res?.data);
    updateToggleState("invoiceNumberChanged", false);
  };

  //set invoice number on invoiceInput
  const invoiceNumber = useCallback(() => {
    const { highestInvoiceNumber } = formValues.invoiceNumberDetails;
    //return invoiceNumber when we first invoice is being created
    if (!isUpdating && !highestInvoiceNumber) return formValues.invoiceNumber;
    if (!isUpdating && highestInvoiceNumber) return highestInvoiceNumber;
    if (isUpdating) return formValues.invoiceNumber;
  }, [formValues?.invoiceNumber, formValues?.invoiceNumberDetails?.highestInvoiceNumber]);

  return (
    <section className="min-h-[8.5rem] mt-8 md:flex md:justify-between pb-6">
      <div className="md:hidden ">
        <div className="w-[9rem] h-auto m-auto">
          {formValues.logoUrl && (
            <img width={"100%"} height={"100%"} src={formValues.logoUrl} alt="" />
          )}
        </div>
      </div>
      <div className="md:w-[45%] lg-[44%] w-[100%]">
        {/* invoice number */}
        <div className="flex gap-1">
          <div
            className={`${
              !toggleStates.invoiceNumberValidation ? "text-[#637485]" : "text-red-600"
            } pt-[0.6rem]  underline decoration-dashed decoration-[#637485] underline-offset-[0.4rem] decoration-[0.061rem] w-[45%]`}>
            Invoice No
          </div>

          <div className="w-[45%] relative">
            <input
              type="text"
              id="text"
              aria-describedby="helper-text-explanation"
              className="border-b border-b-gray-400 focus:border-b border-t-0 border-r-0 border-l-0 focus:border-b-gray-900 hover:border-b-gray-900 focus:ring-0 pl-0 w-full"
              //if isUpdating is true then show invoiceNumber if isUpdating is false then show highestInvoiceNumber
              value={invoiceNumber() || ""}
              onChange={onInvoiceNumberChange}
              onBlur={onInvoiceNumberBlur}
            />

            {toggleStates.invoiceNumberValidation && (
              <span className="absolute top-4 right-[0.03rem]">
                <MdError color="red" />
              </span>
            )}

            {toggleStates.invoiceNumberValidation && (
              <p className="text-red-600 mt-1 text-xs">Invoice No already exists</p>
            )}

            {formValues.invoiceNumberDetails?.latestInvoiceNumber !== null && (
              <span
                id="helper-text-explanation"
                className="mt-1 text-xs text-gray-500 dark:text-gray-400">
                Latest Invoice No: {formValues.invoiceNumberDetails?.latestInvoiceNumber}
              </span>
            )}
          </div>
        </div>

        {/* invoice date */}
        <div className="flex gap-1 border border-yellow-500 border-opacity-0">
          <div className="pt-[0.6rem] text-[#637485] underline decoration-dashed decoration-[#637485] underline-offset-[0.4rem] decoration-[0.061rem] border border-red-500 w-[45%] border-opacity-0">
            Invoice Date
          </div>
          <div className="w-[45%]">
            <input
              type="date"
              className="border-b border-t-0 border-r-0 border-l-0 border-b-gray-400 focus:border-b focus:border-b-gray-900 hover:border-b-gray-900 focus:ring-0 pl-0 w-full"
              value={date.currentDate || ""}
              onChange={e => onDateChange(e.target.value, setDate)}
            />
          </div>
        </div>

        {/* Due date */}
        <div className="flex gap-1 border border-[teal] border-opacity-0">
          <div className="pt-[0.6rem] text-[#637485] underline decoration-dashed decoration-[#637485] underline-offset-[0.4rem] decoration-[0.061rem] border border-red-500 w-[45%] border-opacity-0">
            Due Date
          </div>
          <div className="border border-orange-700 w-[45%] border-opacity-0">
            <input
              type="date"
              className="border-b border-t-0 border-r-0 border-l-0 border-b-gray-400 focus:border-b focus:border-b-gray-900 hover:border-b-gray-900 focus:ring-0 pl-0 w-full"
              value={date.futureDate || ""}
              onChange={e => onFutureDateChange(e.target.value, setDate)}
            />
          </div>
        </div>

        {/* Header fields */}
        {formValues.headerField.map((item, index) => {
          return (
            <CustomField
              fieldValueDetails={item}
              key={index}
              onDeleteHeaderField={onDeleteHeaderField.bind(
                null,
                index,
                formValues.headerField,
                setFormValues
              )}
              setFormValues={setFormValues}
              headerField={formValues.headerField}
              isUpdating={isUpdating}
              getHeaderFieldFormValues={getHeaderFieldFormValues}
            />
          );
        })}

        <div
          className="-ml-1 cursor-pointer flex items-end gap-1 w-fit"
          onClick={() => onAddMoreHeaderField(formValues.headerField, setFormValues)}>
          <div className="pt-[0.1rem]">
            <CiSquarePlus size={24} color="#cdc733" />
          </div>
          <span className="pl-[0.1rem] text-[#637485]">Add More Fields</span>
        </div>
        {/* </div> */}
      </div>
      {/* logo */}
      <div className="hidden md:block md:border-[4px] md:border-[#2f2fde] md:border-opacity-0 md:w-[18%] md:h-auto md:mr-[1.7rem] w-[7rem] h-[7rem] ">
        {formValues.logoUrl && (
          <img
            width={"100%"}
            height={"100%"}
            src={formValues.logoUrl}
            alt={formValues.logoUrl ? "Business logo" : ""}
          />
        )}
      </div>
    </section>
  );
};

export default InvoiceDate;
