import { memo, useCallback } from "react";
import { Label, TextInput, Button } from "flowbite-react";
import { fields } from "./helper";
import { useStore } from "../../context/createFastContext";
import { UpdateTenantDetails } from "../../services/OrganizationDetailsService";
import { showToast } from "../../components/common/Toast";
import MandatorySign from "./../../components/common/Input/MandatorySign";

const OrganizationDetailsContent = memo(({}) => {
  const [multipleFormsState, setStore] = useStore(s => s.multipleFormsState);

  const handleChange = useCallback(
    e => {
      const id = e.target.id;
      let value = e.target.value;

      if (id === "tntPan" || id === "tntTan" || id === "tntGst") value = value.toUpperCase();
      setStore({
        multipleFormsState: {
          ...multipleFormsState,
          organizationDetailsState: {
            ...multipleFormsState.organizationDetailsState,
            inputFields: {
              ...multipleFormsState.organizationDetailsState.inputFields,
              [id]: value,
            },
          },
        },
      });
    },
    [
      multipleFormsState.organizationDetailsState.inputFields[fields[0].id],
      multipleFormsState.organizationDetailsState.inputFields[fields[1].id],
      multipleFormsState.organizationDetailsState.inputFields[fields[2].id],
      multipleFormsState.organizationDetailsState.inputFields[fields[3].id],
      multipleFormsState.organizationDetailsState.inputFields[fields[4].id],
      Object.keys(multipleFormsState.organizationDetailsState.tenant).length,
    ]
  );

  const handleSubmit = e => {
    e.preventDefault();
    const { tenant, inputFields } = multipleFormsState.organizationDetailsState;
    const body = {
      id: tenant?.id,
      tenantName: inputFields[fields[0].id],
      address: inputFields[fields[1].id],
      pan: inputFields[fields[2].id],
      tan: inputFields[fields[3].id],
      gstNumber: inputFields[fields[4].id],
    };

    UpdateTenantDetails(body).then(res => {
      if (res.data.statusCode === 200) {
        showToast("success", "Organization Details Added Successfully");
        setStore({
          multipleFormsState: {
            ...multipleFormsState,
            reFetchStatus: !multipleFormsState.reFetchStatus,
            activeStep:
              multipleFormsState.activeStep < multipleFormsState.step.length
                ? multipleFormsState.activeStep + 1
                : multipleFormsState.activeStep,
            steps: multipleFormsState.steps.map((step, i) => {
              if (i === multipleFormsState.activeStep + 1) {
                return {
                  ...step,
                  color: "blue",
                };
              }
              return step;
            }),
          },
        });
      }
    });
  };

  return (
    <form
      className="flex flex-col gap-4 px-5"
      onSubmit={handleSubmit}>
      {fields.map(field => {
        return (
          <div key={field.id}>
            <div className="mb-2 flex">
              <Label
                htmlFor={field.id}
                value={field.label}
              />
              {field.id !== "tntTan" && field.id !== "tntGst" && <MandatorySign />}
            </div>
            <TextInput
              id={field.id}
              placeholder={field.placeholder}
              required={field.required}
              type={field.type}
              value={multipleFormsState.organizationDetailsState.inputFields[field.id] ?? ""}
              onChange={handleChange}
            />
          </div>
        );
      })}
      <Button
        type="submit"
        className="rounded-3xl w-fit px-7 py-1 ml-auto my-2 bg-thynkwebPrimary-800 hover:bg-thynkwebPrimary-900 focus:ring-4 focus:ring-thynkwebPrimary-300 dark:focus:ring-thynkwebPrimary-900">
        Next
      </Button>
    </form>
  );
});

export default OrganizationDetailsContent;

export const organizationDetailsState = {
  inputFields: fields.reduce((acc, curr) => {
    acc[curr.id] = "";
    return acc;
  }, {}),
  tenant: {},
};
