import { Toaster, ToastBar, toast } from "react-hot-toast";

import { RxCross2 } from "react-icons/rx";

const ToastNotification = () => {
  return (
    <Toaster>
      {t => (
        <ToastBar toast={t}>
          {({ icon, message }) => (
            <>
              {icon}
              {message}
              {
                <button
                  className="p-1 hover:bg-gray-200 rounded-md"
                  onClick={() => toast.dismiss(t.id)}>
                  <RxCross2 />
                </button>
              }
            </>
          )}
        </ToastBar>
      )}
    </Toaster>
  );
};

export default ToastNotification;
