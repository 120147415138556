import { useEffect } from "react";
import { getExceptionLogs } from "../../store/slices/exceptionLogsSlice";
import { useSelector, useDispatch } from "react-redux";
import usePagination, { PaginationComponent } from "../../hooks/usePagination";

import Table from "../common/Table/Table";
import PageSection from "../Layout/PageSection/PageSection";
import ExceptionLogsList from "./ExceptionLogsList";

const COLUMNS = ["Info", "Type", "Stack Trace"];

const ExceptionLogsTable = () => {
  const dispatch = useDispatch();

  const { totalRecords } = useSelector(state => state.exceptionLogs);
  const { pageSize, pageNo } = useSelector(state => state.pagination);

  const { setPageSizeHandler, onPageChange } = usePagination(false, totalRecords);

  useEffect(() => {
    //fetch exceptions log
    dispatch(getExceptionLogs(pageSize, pageNo));
  }, [pageNo, pageSize]);

  const tableClick = e => {
    const elem = e.target.closest("[data-id]");
    const td = elem?.querySelector("[data-stack]");

    if (!elem || !td) return;

    const dataset = td.dataset.stack;

    if (dataset === "webkit") {
      td.style.display = "table-cell";
      td.dataset.stack = "table-cell";
    } else {
      td.style.display = "-webkit-box";
      td.dataset.stack = "webkit";
    }
  };

  return (
    <PageSection heading="Exception Logs">
      <div className="flex items-center flex-1 space-x-4 px-3 py-2">
        <h5>
          <span className="text-gray-500 font-[500]">Logs: </span>
          <span className="text-gray-500 dark:text-white font-[500]">{totalRecords}</span>
        </h5>
      </div>
      <Table col={COLUMNS}>
        <tbody
          onClick={tableClick}
          className={totalRecords === 0 ? "h-[8rem]" : "h-auto px-3"}>
          <ExceptionLogsList />
        </tbody>
      </Table>

      <PaginationComponent
        totalRecords={totalRecords}
        onChange={setPageSizeHandler}
        onPageChange={onPageChange}
      />
    </PageSection>
  );
};

export default ExceptionLogsTable;
