import React from "react";
import DeleteModal from "../common/Modal/DeleteModal";
import { handleActivateAndDeactivateUser } from "../../services/UserService";
import { UserStatus } from "../../enum/UserStatus";
import { STATUS_CODES } from "../../constants/Constant";
import { showToast } from "../common/Toast";
import { EMPLOYEE_MESSAGES } from "../../constants/Messages";
import { useAppDispatch, useAppSelector } from "../../store/store";
import { resetUser, setRefetchUsers, setToggleReactivateUserModal } from "../../store/slices/userManagementSlice";

const ReActivateEmployee = ({}) => {

  const umState = useAppSelector(state => state.userManagement);
  const dispatch = useAppDispatch();

  const modalConfigs = {
    show: umState.toggleReactivateUserModal,
    size: "md",
    showFooter: false,
  };
  let message = "Are you sure you want to Re-Activate this user?";
  const styles = {
    icon: "text-green-500",
  };

  //Function to reactivate the user
  const onReactivateUser = async () => {
      const response = await handleActivateAndDeactivateUser(
        umState.user.id,
        UserStatus.Active
      );
      if (response.status === STATUS_CODES.SUCCESS) {
        showToast("success", EMPLOYEE_MESSAGES.REACTIVATE_SUCCESS);
        resetState();
      } else {
        showToast("error", EMPLOYEE_MESSAGES.REACTIVATE_ERROR);
      }
  };

  const resetState = () => {
    dispatch(setToggleReactivateUserModal(false));
    dispatch(resetUser());
    dispatch(setRefetchUsers(!umState.refetchUsers));
  }

  return (
    <div>
      <DeleteModal
        modalConfigs={modalConfigs}
        styles={styles}
        message={message}
        onCancel={resetState}
        onDelete={onReactivateUser}
        showIcon={true}
      />
    </div>
  );
};

export default ReActivateEmployee;
