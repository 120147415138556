import React, { useState } from "react";
import { HiDotsHorizontal, HiOutlineArrowSmRight, HiOutlineTrash, HiPencil } from "react-icons/hi";
import { IoMdAddCircleOutline, IoMdPaper } from "react-icons/io";
import { Link } from "react-router-dom";
import { RiLinksFill } from "react-icons/ri";
import AddQuickLink from "./AddQuickLink";
import LoadingSpinner from "../../../utils/LoadingSpinner";
import DeleteQuickLink from "./DeleteQuickLink";
import UpdateQuickLink from "./UpdateQuickLink";

const QuickLinks = ({ quickLinks, renderQuickLink }) => {
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [showAddQuickLinkModal, setShowAddQuickLinkModal] = useState(false);
  const [showUpdateQuickLinkModal, setShowUpdateQuickLinkModal] = useState(false);
  const [showDeleteQuickLinkModal, setShowDeleteQuickLinkModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedQuickLinkInfo, setSelectedQuickLinkInfo] = useState({});
  const [showEditMenuIndex, setShowEditMenuIndex] = useState(null);
  const [quickLinkDetail, setQuickLinkDetail] = useState({
    id: '',
    name: '',
    url: ''
  });
  const [deleteHover, setDeleteHover] = useState(false);

  const handleAddButtonClick = () => {
    setShowAddQuickLinkModal(true);
  };

  const handleDeleteButtonClick = (quickLinkId, quickLinkName) => {
    setSelectedQuickLinkInfo({ quickLinkId, quickLinkName }); // Sets the selected quick link ID and name
    setShowDeleteQuickLinkModal(true);
  };

  const handleEditMenuClick = (index) => {
    setShowEditMenuIndex(index === showEditMenuIndex ? null : index);
  };

  const handleEditMenuMouseLeave = () => {
    setShowEditMenuIndex(null);
  };

  const handleEditClick = (quickLinkId, quickLinkName, quickLinkUrl) => {
    setQuickLinkDetail({ id: quickLinkId, name: quickLinkName, url: quickLinkUrl });
    setShowUpdateQuickLinkModal(true);
  };

  return (
    <div>
      <div className="w-full h-96 bg-white rounded-lg">
        <div className="flex items-center justify-between p-5 pb-3">
          <h5 className="text-xl font-bold leading-none text-gray-700 dark:text-white">
            Quick Links
          </h5>
          <button
            onClick={handleAddButtonClick}
            className="text-sm font-medium hover:underline dark:text-blue-500">
            <IoMdAddCircleOutline size="1.5rem" />
          </button>
        </div>
        <div>
          <hr className="h-px my-2 bg-gray-200 border-0 dark:bg-gray-700"></hr>
        </div>
        {quickLinks.length === 0 && (
          <div className="flex flex-col items-center relative">
            <RiLinksFill className="absolute top-10" size={"10rem"} color="#BFE2D2" />
            <p className="text-center absolute top-60 text-gray-500 dark:text-gray-400">
              No quick links added
            </p>
          </div>
        )}
        {loading && (
          <div className="flex flex-col items-center relative">
            <LoadingSpinner />
          </div>
        )}
        {!loading && quickLinks.length > 0 && (
          <div
            className="pl-3 pr-5 pb-5 flow-root"
            style={{ maxHeight: "calc(100% - 90px)" }}>
            <ul role="list" className=" divide-gray-200 dark:divide-gray-700">
              {quickLinks.map((quickLink, index) => (
                <li key={index} className="py-2 sm:py-2 pl-2 relative">
                  <div
                    className="flex items-center"
                    onMouseEnter={() => setHoveredIndex(index)}
                    onMouseLeave={() => setHoveredIndex(null)}>
                    <Link className="flex-shrink-0" to={quickLink.url} target="_blank">
                      <p className="w-10 h-10 p-2 rounded-full bg-slate-300">
                        <IoMdPaper size="1.5rem" />
                      </p>
                    </Link>
                    <Link className="flex-1 min-w-0 pl-3" to={quickLink.url} target="_blank">
                      <p className="text-sm font-medium text-gray-900 truncate dark:text-white">
                        {quickLink.name}
                      </p>
                    </Link>
                    {hoveredIndex === index && (
                      <div className="relative">
                        <button
                          onClick={() => handleEditMenuClick(index)}
                          className="absolute text-sm font-medium right-1 top-1/2 transform -translate-y-1/2  ">
                          <HiDotsHorizontal size="1.2rem" />
                        </button>
                        {showEditMenuIndex === index && (
                          <ul onMouseLeave={handleEditMenuMouseLeave} className="absolute right-0 top-full z-10 bg-white border border-gray-200 rounded-lg shadow-md py-1">
                            <li>
                              <button className="flex items-center w-full !py-2 !px-3 h-full text-left text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                                onClick={() => handleEditClick(quickLink.id, quickLink.name, quickLink.url)}
                              >
                                <HiPencil size="1rem" className="mr-2" />
                                Edit
                              </button>
                            </li>
                            <li>
                              <button className="flex items-center w-full !py-2 !px-3 h-full text-left text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                                onMouseEnter={() => setDeleteHover(true)}
                                onMouseLeave={() => setDeleteHover(false)}
                                onClick={() => handleDeleteButtonClick(quickLink.id, quickLink.name)}>
                                <HiOutlineTrash size="1rem" className={`mr-2 ${deleteHover ? 'text-red-500' : ''}`} />
                                Delete
                              </button>
                            </li>
                          </ul>
                        )}
                      </div>
                    )}
                  </div>
                  {hoveredIndex === index && (
                    <div className="absolute left-0 top-1/2 -translate-y-1/2 bottom-0 w-1 bg-blue-400 rounded-sm"></div>
                  )}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
      <AddQuickLink
        show={showAddQuickLinkModal}
        onClose={() => setShowAddQuickLinkModal(false)}
        renderQuickLink={renderQuickLink}
        setLoading={() => setLoading(false)}
      />

      <UpdateQuickLink
        show={showUpdateQuickLinkModal}
        onClose={() => setShowUpdateQuickLinkModal(false)}
        quickLinkInfo={quickLinkDetail}
        renderQuickLink={renderQuickLink}
        setLoading={() => setLoading(false)}
      />

      <DeleteQuickLink
        show={showDeleteQuickLinkModal}
        onClose={() => setShowDeleteQuickLinkModal(false)}
        quickLinkInfo={selectedQuickLinkInfo}
        renderQuickLink={renderQuickLink}
        setLoading={() => setLoading(false)}
        isActionDeleteBtn={true}
      />
    </div>
  );
};

export default QuickLinks;
