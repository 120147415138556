import { Button, Tabs } from "flowbite-react";
import { useEffect, useRef, useState, memo, useDeferredValue } from "react";
import Table from "../../components/common/Table/Table";
import ModalComponent from "../../components/common/Modal/Modal";
import AddSalaryComponent from "./AddSalaryComponent/AddSalaryComponent";
import { showToast } from "../../components/common/Toast";
import Toggle from "../../components/common/Toggle/Toggle";
import AddDeductions from "./AddSalaryComponent/AddDeductions";
import {
  GetDeductionById,
  GetDeductions,
  GetEarningById,
  GetEarningTypeById,
  GetEarnings,
  deleteDeductionById,
  deleteEarningById,
  updateDeductionsStatus,
  updateEarningStatus,
} from "../../services/SalaryComponentService";
import { CalculationType } from "../../enum/CalculationType";
import {
  setModalValues,
  setShowEarningModal,
  setShowDeductionModal,
  setIsEditEarningModal,
  setIsEditDeductionModal,
  setEarningTypesList,
  setTenantEarnings,
  setTenantDeductions,
  setEarningDetails,
  setDeductionDetails,
} from "../../store/slices/salarySlice";
import { useAppDispatch, useAppSelector } from "../../store/store";
import { EarningCategory } from "../../enum/EarningCategory";
import { DeductionType, DeductionTypeLabels } from "../../enum/DeductionType";
import { FaRegTrashAlt } from "react-icons/fa";
import DeleteModal from "../../components/common/Modal/DeleteModal";
import { DELETE_MESSAGE_SALARY } from "../../constants/Messages";

const Columns = ["Name", "Earning Type", "Description", "Amount", "Status", "Delete"];
const DeductionColumns = ["Name", "Deduction Type", "Description", "Frequency", "Status", "Delete"];

const SalaryComponents = () => {
  const dispatch = useAppDispatch();
  const salary = useAppSelector(state => state.salary);
  const [activeTab, setActiveTab] = useState(0);

  const [confirmationModal, setConfirmationModal] = useState({
    show: false,
    type: "", // 'earning' or 'deduction'
    id: undefined,
    name: "",
  });

  const tabsRef = useRef(null);

  const getEarningRows = () => {
    const filteredEarnings = salary?.tenantEarnings.map(earning => {
      var amount = "Variable; Flat Amount";
      if (earning.isFixedPay) {
        if (earning.calculationType === CalculationType.PercentageOfBasic) {
          amount = `Fixed; ${earning.calculationValue}% of Basic`;
        } else if (earning.calculationType === CalculationType.PercentageOfCTC) {
          amount = `Fixed; ${earning.calculationValue}% of CTC`;
        } else if (earning.calculationType === CalculationType.PercentageOfGross) {
          amount = `Fixed; ${earning.calculationValue}% of Gross`;
        } else if (earning.calculationType === CalculationType.CustomFormula) {
          amount = `Fixed; Custom Formula`;
        } else if (earning.calculationType === CalculationType.FlatAmount) {
          amount = `Fixed; Flat amount of ${earning.calculationValue}`;
        }

        if (earning.earningCategory == EarningCategory.FixedAllowance) {
          amount = `Gross - Sum of other fixed earnings`;
        }
      }

      const filteredEarning = [
        earning,
        [earning.name,
        earning.earningTypeName,
        earning.description,
        amount,
        <Toggle
          onChange={changeEarningStatus}
          name={earning.name}
          checked={earning.status}
          id={earning.id}
        />,

        <div
          data-modal-target="popup-modal"
          data-modal-toggle="popup-modal"
          onClick={e => {
            e.stopPropagation();
            handleOpenModal("earning", earning.id, earning.name);
          }}
          className="cursor-pointer hover:text-red-400">
          <FaRegTrashAlt />
        </div>,]
      ];
      return filteredEarning;
    });

    return filteredEarnings ?? [];
  };

  const getDeductionRows = () => {
    const filteredDeductions = salary?.tenantDeductions.map(deduction => {
      return [
        deduction,
        [deduction.name,
        DeductionTypeLabels[deduction.deductionType],
        deduction.description,
        deduction.isRecurring ? "Recurring" : "One-Time",
        <Toggle
          onChange={changeDeductionStatus}
          name={deduction.name}
          checked={deduction.status}
          id={deduction.id}
        />,

        <div
          data-modal-target="popup-modal"
          data-modal-toggle="popup-modal"
          onClick={e => {
            e.stopPropagation();
            handleOpenModal("deduction", deduction.id, deduction.name);
          }}
          className="cursor-pointer hover:text-red-400">
          <FaRegTrashAlt />
        </div>, ]
      ];
    });

    return filteredDeductions ?? [];
  };

  useEffect(() => {
    if (activeTab === 0) {
      GetEarnings(false).then(res => {
        dispatch(setTenantEarnings(res.data));
      });
    } else {
      GetDeductions(false).then(res => {
        dispatch(setTenantDeductions(res.data));
      });
    }
  }, [activeTab]);

  const changeEarningStatus = async e => {
    let id = e?.target?.id;
    let EarningStatusChecked = e?.target?.checked;

    await updateEarningStatus(id, EarningStatusChecked);

    GetEarnings(false).then(res => {
      dispatch(setTenantEarnings(res.data));
    });
  };

  const changeDeductionStatus = async e => {
    let id = e?.target?.id;
    let DeductionStatusChecked = e?.target?.checked;

    await updateDeductionsStatus(id, DeductionStatusChecked);

    GetDeductions(false).then(res => {
      dispatch(setTenantDeductions(res.data));
    });
  };

  const handleAddModal = () => {
    if (Object.is(activeTab, 0)) {
      dispatch(setShowEarningModal(true));
    }
    if (Object.is(activeTab, 1)) {
      dispatch(setShowDeductionModal(true));
    }
  };

  const onCloseEarningModal = () => {
    dispatch(setShowEarningModal(false));
    dispatch(setIsEditEarningModal(false));
    dispatch(
      setEarningDetails({
        ...salary.earningDetails,
        type: null,
        selectedEarning: null,
        lockOptions: {
          lockIsFixedPay: false,
          lockIsPartOfSalaryStructure: false,
          lockIsTaxableEarning: false,
          lockIsProRateBasis: false,
          lockEPFContributionType: false,
          lockIsConsideredForESI: false,
        },
      })
    );
  };

  const onCloseDeductionModal = () => {
    dispatch(setShowDeductionModal(false));
    dispatch(setIsEditDeductionModal(false));
    dispatch(
      setDeductionDetails({
        selectedDeduction: null,
        values: {
          deductionName: "",
          isRecurring: false,
        },
      })
    );
  };

  const getTableElement = e => {
    const elem = e.target?.closest("[data-id]");

    if (!elem) return;

    let obj = elem.dataset?.id;

    if (obj === "toggle") return;

    obj = JSON.parse(obj);

    return obj;
  };

  const handleEditEarning = async e => {
    let earning = getTableElement(e);
    if (earning === null || earning === undefined) {
      return;
    }

    dispatch(setIsEditEarningModal(true));
    dispatch(setShowEarningModal(true));

    const res = await GetEarningById(earning?.id);
    const selectedEarning = res?.data;
    const earningTypeRes = await GetEarningTypeById(res.data.earningTypeId);
    const selectedEarningType = earningTypeRes.data.data;

    dispatch(
      setEarningDetails({
        ...salary.earningDetails,
        selectedEarning: selectedEarning,
        type: selectedEarningType,
        values: {
          earningName: selectedEarning.name,
          nameInPayslip: selectedEarning.name,
          amount: selectedEarning.calculationValue,
          isFixedPay: selectedEarning.isFixedPay,
          calculationType: selectedEarning.calculationType,
          isPartOfSalaryStructure: selectedEarning.isPartOfSalaryStructure,
          isTaxableEarning: selectedEarning.isTaxableEarning,
          isProRateBasis: selectedEarning.isProRateBasis,
          epfContributionType: selectedEarning.epfContributionType,
          isConsideredForESI: selectedEarning.isConsideredForESI,
        },
        lockOptions: {
          lockIsFixedPay: selectedEarningType.lockIsFixedPay,
          lockIsPartOfSalaryStructure: selectedEarningType.lockIsPartOfSalaryStructure,
          lockIsTaxableEarning: selectedEarningType.lockIsTaxableEarning,
          lockIsProRateBasis: selectedEarningType.lockIsProRateBasis,
          lockEPFContributionType: selectedEarningType.lockEPFContributionType,
          lockIsConsideredForESI: selectedEarningType.lockIsConsideredForESI,
        },
      })
    );
  };

  const handleEditDeduction = async e => {
    let deduction = getTableElement(e);
    if (deduction === null || deduction === undefined) {
      return;
    }

    dispatch(setIsEditDeductionModal(true));
    dispatch(setShowDeductionModal(true));

    const res = await GetDeductionById(deduction?.id);
    const selectedDeduction = res.data;

    dispatch(
      setDeductionDetails({
        ...salary.deductionDetails,
        selectedDeduction: selectedDeduction,
        values: {
          deductionName: selectedDeduction.name,
          description: selectedDeduction.description,
          isRecurring: selectedDeduction.isRecurring,
          deductionType: selectedDeduction.deductionType,
          preTaxDeductionSection: selectedDeduction.preTaxDeductionSection?.toString() ?? "",
        },
      })
    );
  };

  const handleTabChange = tab => {
    setActiveTab(tab);
  };

  const handleOpenModal = (type, id, name) => {
    setConfirmationModal({
      show: true,
      type,
      id,
      name,
    });
  };

  const handleCloseModal = () => {
    setConfirmationModal({
      show: false,
      type: "",
      id: undefined,
      name: "",
    });
  };

  const deleteEarning = async () => {
    await deleteEarningById(+confirmationModal.id);
    showToast("success", DELETE_MESSAGE_SALARY.DEL_EARNING);

    GetEarnings(false).then(res => {
      dispatch(setTenantEarnings(res.data));
    });
  };

  const deleteDeduction = async () => {
    await deleteDeductionById(+confirmationModal.id);
    showToast("success", DELETE_MESSAGE_SALARY.DEL_DEDUCTION);

    GetDeductions(false).then(res => {
      dispatch(setTenantDeductions(res.data));
    });
  };

  const handleDelete = () => {
    if (confirmationModal.type === "earning") {
      deleteEarning();
    } else if (confirmationModal.type === "deduction") {
      deleteDeduction();
    }
    handleCloseModal();
  };

  return (
    <section className="bg-gray-100 h-[calc(100vh-61px)] dark:bg-gray-900 py-3 sm:py-5">
      <div className="px-4 mx-auto lg:px-12">
        <p className="text-4xl text-gray-900 dark:text-white py-4 cursor-pointer">
          Salary Components
        </p>
        <div className="relative text-gray-900 dark:text-white tab-payslip-links">
          <Tabs.Group aria-label="Default tabs" ref={tabsRef} onActiveTabChange={handleTabChange}>
            <Tabs.Item active title="Earnings">
              <div className="mx-auto">
                <div className="overflow-hidden bg-white shadow-md dark:bg-gray-800 sm:rounded-lg">
                  <Table col={Columns} rows={getEarningRows()} tableClick={handleEditEarning} />
                </div>
              </div>
            </Tabs.Item>
            <Tabs.Item title="Deductions">
              <div className="mx-auto">
                <div className="overflow-hidden bg-white shadow-md dark:bg-gray-800 sm:rounded-lg">
                  <Table
                    col={DeductionColumns}
                    rows={getDeductionRows()}
                    tableClick={handleEditDeduction}
                  />
                </div>
              </div>
            </Tabs.Item>
          </Tabs.Group>
          <Button
            size="sm"
            onClick={handleAddModal}
            className="absolute top-[-10px] right-[0] bg-thynkwebPrimary-800 hover:bg-thynkwebPrimary-900 focus:ring-4 focus:ring-thynkwebPrimary-300 dark:focus:ring-thynkwebPrimary-900 mt-4">
            Add {activeTab === 0 ? "Earning" : "Deduction"}
          </Button>
        </div>
      </div>
      <ModalComponent
        heading={salary?.modalValues.isEditEarningModal ? "Edit Earning" : "New Earning"}
        show={salary?.modalValues.showEarningModal}
        onClose={onCloseEarningModal}
        size="5xl"
        bodyClassName="min-h-[20rem]"
        showFooter={false}>
        <AddSalaryComponent onCloseEarningModal={onCloseEarningModal} />
      </ModalComponent>
      <ModalComponent
        heading={salary?.modalValues.isEditDeductionModal ? "Edit Deduction" : "New Deduction"}
        show={salary?.modalValues.showDeductionModal}
        onClose={onCloseDeductionModal}
        showFooter={false}>
        <AddDeductions onCloseDeductionModal={onCloseDeductionModal} />
      </ModalComponent>

      <DeleteModal
        modalConfigs={{
          show: confirmationModal.show,
          size: "md",
          onClose: handleCloseModal,
          showFooter: false,
          otherProps: {
            popup: true,
          },
        }}
        message={`Are you sure you want to delete ${confirmationModal.name}?`}
        onDelete={handleDelete}
        onCancel={handleCloseModal}
        showIcon={true}
      />
    </section>
  );
};

export default memo(SalaryComponents);

/**
 * Generates a JSX element that represents a value.
 *
 * @return {JSX.Element} The Markup for Flat amount types.
 */
const Value = function () {
  return (
    <div>
      <span className="w-[50%] whitespace-nowrap">
        {" "}
        Flat Amount (Monthly CTC - Sum of All other components)
      </span>
    </div>
  );
};
