import { useState, useEffect } from "react";
import { Button, Label } from "flowbite-react";
import { showToast } from "../../common/Toast";
import SelectComponent from "./SelectComponent/SelectComponent";
import WeekDayOffSelect from "./SelectComponent/WeekDayOffSelect";
import { getSettings, updateSettings } from "../../../services/CommonService";
import { PAY_DAYS, PayDayOptions, PayPeriodOptions, WORKING_HOURS } from "./../helper/helper";
import SelectWithDropdown from "../../common/SelectWithDropdown/SelectWithDropdown";
import { PayPeriodType } from "../../../enum/PayPeriodType";
import { GrCircleInformation } from "react-icons/gr";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { PayDayType } from "../../../enum/PayDayType";
import { ToolTipContent } from "../../../pages/MultiStepForm/helper";
import { DEFAULT_MONTH } from "../../../constants/Constant";

/**
 * @param {Object} props
 * @param {function} props.onClose To close modal
 * @returns Dashboard settings form
 */

export default function SettingsForm({ onClose }) {
  const [selectedOptions, setSelectedOptions] = useState([
    {
      workingHours: "",
      payStartDay: PAY_DAYS[0] + 1,
      payEndDay: PAY_DAYS[0],
      weekDaysOff: [],
      payDay: "",
      lastPayrollRunMonth: 1,
      lastPayrollYear: 2000,
    },
  ]);

  const [state, setState] = useState({
    payPeriodTypeOptions: PayPeriodOptions,
    selectedPayPeriodType: PayPeriodOptions[0],
    payDayOptions: PayDayOptions,
    selectedPayDay: PayDayOptions[0],
  });

  useEffect(() => {
    getSettings().then(res => {
      const { data } = res?.data;
      const obj = [{ ...data, weekDaysOff: data.weekDaysOff.split(",") }];

      setSelectedOptions(obj);
      setState(prevS => ({
        ...prevS,
        selectedPayPeriodType: PayPeriodOptions[data.payPeriodType - 1],
        selectedPayDay: PayDayOptions[data.payDayType],
      }));
    });
  }, []);

  const OnSubmitHandler = async function (event) {
    event.preventDefault();
    onClose();

    const { payEndDay, weekDaysOff } = selectedOptions[0];
    let payStartDay = 0;
    if(payEndDay)
    {
      payStartDay = +payEndDay + 1
    }
    const data = {
      ...selectedOptions[0],
      payStartDay: payStartDay,
      payPeriodType: state.selectedPayPeriodType.value,
      payDayType: state.selectedPayDay.value,
      weekDaysOff: weekDaysOff.toString(),
    };

    showToast("success", "Please wait, updating the new settings.");
    //Call UpdateSettings
    const res = await updateSettings(data);

    if (res.data.statusCode !== 200) {
      //clear the toast before showing the new onw
      showToast("warn", "Something went wrong!");
      return;
    }

    //Show Toast on success
    if (res.data.statusCode === 200) {
      //clear the toast before showing the new onw
      showToast("success", "Settings updated!");
    }

    //no need to clear the state since we are storing the latest state
    //and on refresh latest changes will be visible.
  };

  const onChangeHandler = e => {
    const { name, value } = e.target;

    if (name === "workingHours" && (+value > 14 || +value < 6))
      return showToast("warn", "Working hours should be greater than 6 and less than 15");

    setSelectedOptions(prevSO => {
      return [
        {
          ...prevSO[0],
          [name]: +value,
          payStartDay: Number(value) + 1,
        },
      ];
    });
  };

  const handleOptionsChange = (_, selectedOption) => {
    if (selectedOption.value === 2) {
      setSelectedOptions(prevO => [
        {
          ...prevO[0],
          payStartDay: +PAY_DAYS[21] + 1,
          payEndDay: +PAY_DAYS[21],
        },
      ]);
    }
    setState(s => ({
      ...s,
      selectedPayPeriodType: selectedOption,
    }));
  };

  const handlePayDayOptionsChange = (_, selectedOption) => {
    setState(s => ({
      ...s,
      selectedPayDay: selectedOption,
    }));

    //Set payDay zero if last working day is selected else set payDay 1
    setSelectedOptions(prevSO => {
      return [
        {
          ...prevSO[0],
          payDay: selectedOption.value === 0 ? 0 : 1,
        },
      ];
    });
  };

  const handlePayDay = e => {
    const { name, value } = e.target;
    setSelectedOptions(prevSO => {
      return [
        {
          ...prevSO[0],
          [name]: +value,
        },
      ];
    });
  };

  const lastPayrollMonth = DEFAULT_MONTH[selectedOptions[0].lastPayrollRunMonth - 1]?.label;

  return (
    <form
      className="grid grid-cols-2 items-center justify-between mt-5 gap-x-[3rem] gap-y-6 ml-4 mr-4"
      onSubmit={OnSubmitHandler}>
      {/* working hours */}
      <SelectComponent
        name="Working Hours"
        onChangeHandler={onChangeHandler}
        optionsArr={WORKING_HOURS}
        defaultValue={selectedOptions[0].workingHours}
        nameAttr="workingHours"
        parentClass="self-start"
      />

      {/* Week Day off */}
      <WeekDayOffSelect
        weekDaysOff={selectedOptions[0].weekDaysOff}
        setSelectedOptions={setSelectedOptions}
      />
      {/* Pay Period Type */}
      <div>
        <div className="flex items-center">
          <Label className={`text-gray-600 font-medium self-start capitalize`}>
            Select pay period
          </Label>
          <div
            className="ml-3"
            data-tooltip-id="payPeriod"
            data-tooltip-html={ToolTipContent.payPeriod}
            data-tooltip-place="bottom"
            data-tooltip-content={ToolTipContent.payPeriod}>
            <GrCircleInformation />
          </div>

          <ReactTooltip className="z-[5]" id="payPeriod" />
        </div>
        <div className="mt-[4px]">
          <SelectWithDropdown
            roundedDot={false}
            size="sm"
            options={
              state.payPeriodTypeOptions.map(o => ({
                ...o,
                itemClassName: "w-[19rem]",
              })) ?? []
            }
            defaultValue={state.selectedPayPeriodType?.label ?? "Select pay period"}
            handleChange={handleOptionsChange}
            className="-pl-4 w-[19rem]"
            backgroundColor="bg-gray-50 border border-gray-200 rounded-md h-[2.5rem] w-[19rem]"
            isDefaultValue
          />
        </div>
      </div>

      <div className="flex gap-4 items-center justify-items-stretch">
        <SelectComponent
          name="Last Payroll Month"
          optionsArr={[lastPayrollMonth]}
          defaultValue={lastPayrollMonth}
          nameAttr="lastPayrollRunMonth"
          parentClass="!w-full"
          disabled
        />
        <SelectComponent
          name="Last Payroll Year"
          optionsArr={[selectedOptions[0].lastPayrollYear]}
          defaultValue={selectedOptions[0].lastPayrollYear}
          nameAttr="lastPayRollYear"
          parentClass="!w-full"
          disabled
        />
      </div>

      {/* Pay Start Day */}
      {state.selectedPayPeriodType.value === PayPeriodType.FixedRange && (
        <>
          <SelectComponent
            name="Pay Start Day"
            optionsArr={PAY_DAYS}
            defaultValue={Number(selectedOptions[0].payEndDay + 1)}
            nameAttr="payStartDay"
            disabled
          />
          {/* Pay End  Day */}
          <SelectComponent
            name="Pay End Day"
            onChangeHandler={onChangeHandler}
            optionsArr={PAY_DAYS}
            defaultValue={selectedOptions[0].payEndDay}
            nameAttr="payEndDay"
            disabled={
              state?.selectedPayPeriodType?.value === PayPeriodType?.DaysInMonth ? true : false
            }
          />
        </>
      )}

      {/* Pay Day Type */}
      <div className="">
        <Label className={`text-gray-600 font-medium self-start capitalize mb-[10px]`}>
          Select pay day
        </Label>
        <div className="mt-[4px]">
          <SelectWithDropdown
            roundedDot={false}
            size="sm"
            dotClassName=""
            options={
              state.payDayOptions.map(o => ({
                ...o,
                itemClassName: "w-[19rem]",
              })) ?? ""
            }
            defaultValue={state.selectedPayDay?.label ?? "Select Pay Day"}
            handleChange={handlePayDayOptionsChange}
            className="-pl-4 w-[19rem]"
            backgroundColor="bg-gray-50 border border-gray-200 rounded-md h-[2.5rem] w-[19rem]"
            isDefaultValue
          />
        </div>
      </div>
      {/* Pay Day */}
      {state?.selectedPayDay?.value === PayDayType?.DayOfMonth && (
        <SelectComponent
          name="Pay Day"
          onChangeHandler={handlePayDay}
          optionsArr={PAY_DAYS}
          defaultValue={
            Number(selectedOptions[0].payDay) === 0 ? 1 : Number(selectedOptions[0].payDay)
          }
          nameAttr="payDay"
        />
      )}

      <Button
        type="submit"
        className='ml-auto mt-10 col-span-full justify-self-end bg-thynkwebPrimary-800 hover:bg-thynkwebPrimary-900 focus:ring-4 focus:ring-thynkwebPrimary-300 dark:focus:ring-thynkwebPrimary-900"'>
        Apply Changes
      </Button>
    </form>
  );
}
