import React, { useEffect, useState } from "react";
import { CLIENTS_HEADING } from "../../../constants/Constant";
import { Button } from "flowbite-react";

import AddBusiness from "../../invoices/components/businesses/AddBusiness";
import { showToast } from "../../common/Toast";
import DeleteModal from "../../common/Modal/DeleteModal";
import TableHeader from "../../common/Table/TableHeader";
import ClientLists from "./ClientLists";
import PageSection from "../../Layout/PageSection/PageSection";

import { DEFAULT_PAGE_NO, DEFAULT_PAGE_SIZE, STATUS_CODES } from "../../../constants/Constant";
import { deleteBusiness, getBusinesses } from "../../../services/BusinessService";
import { toggleInitialState } from "../../invoices/helper";
import { btnPrimary } from "../../../utils/helper";
import { GoPlus } from "react-icons/go";
import { HiOutlineUsers } from "react-icons/hi";
import usePagination, { PaginationComponent } from "../../../hooks/usePagination";
import { useSelector } from "react-redux";

//temp variable for api call
const isClient = true;

const Clients = () => {
  const [toggleStates, setToggleStates] = useState(toggleInitialState);
  const [clients, setClients] = useState([]);
  const [conformationDeleteModal, setConformationDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState({
    id: undefined,
    name: "",
  });
  const [clientUpdateId, setClientUpdateId] = useState(null);
  const { pageSize, pageNo } = useSelector(state => state.pagination);
  const [totalRecords, setTotalRecords] = useState(0);
  const { setPageSizeHandler, onPageChange } = usePagination(false, totalRecords);

  useEffect(() => {
    fetchClients();
  }, [toggleStates.isChange, pageSize, pageNo]);

  const fetchClients = async () => {
    // const isClient = true;
    const response = await getBusinesses(pageNo, pageSize, isClient);
    if (response?.statusCode === STATUS_CODES.SUCCESS) {
      setClients(response?.data);
      setTotalRecords(response?.totalRecords);
    }
  };

  const deleteClients = async () => {
    const response = await deleteBusiness(+deleteId.id);
    setClients(prevState => prevState.filter(cl => cl.id !== +deleteId.id));
    showToast("success", "Deleted Client successfully");

    setToggleStates(prevTS => ({ ...prevTS, isChange: !prevTS.isChange }));
    setConformationDeleteModal(false);

    // reset
    setDeleteId({
      id: undefined,
      name: "",
    });
  };

  const openDeleteModal = function (id, name) {
    setDeleteId({
      id: id,
      name: name,
    });
    setConformationDeleteModal(true);
  };

  const setClientUpdate = async id => {
    setClientUpdateId(id),
      setToggleStates(prevTS => {
        return {
          ...prevTS,
          isClientUpdate: true,
          billedToModal: true,
        };
      });
  };

  const resetClientUpdateId = () => {
    setClientUpdateId(null);
  };

  const openBilledToModal = () => {
    setToggleStates(prevTS => ({
      ...prevTS,
      billedToModal: true,
      isClient: true,
    }));
  };

  return (
    <PageSection
      button={
        <Button onClick={openBilledToModal} className={`${btnPrimary()}`}>
          <GoPlus size={"1rem"} />
          &nbsp;&nbsp;Add Client
        </Button>
      }
      icon={<HiOutlineUsers size={"2rem"} />}
      showIcon={true}
      heading="Clients">
      <table className="w-full text-sm text-left">
        <TableHeader cols={CLIENTS_HEADING} />

        <tbody>
          <ClientLists
            clients={clients}
            setClientUpdate={setClientUpdate}
            openDeleteModal={openDeleteModal}
          />
        </tbody>
      </table>

      <PaginationComponent
        onChange={setPageSizeHandler}
        onPageChange={onPageChange}
        showIcons={true}
        totalRecords={totalRecords}
      />

      <AddBusiness
        show={toggleStates.billedToModal}
        modal="billedToModal"
        setToggleStates={setToggleStates}
        isClientUpdate={toggleStates.isClientUpdate}
        isClient={toggleStates.isClient}
        clientUpdateId={clientUpdateId}
        resetClientUpdateId={resetClientUpdateId}
        billedToModal={toggleStates.billedToModal}
      />
      <DeleteModal
        modalConfigs={{
          show: conformationDeleteModal,
          size: "md",
          onClose: () => setConformationDeleteModal(false),
          showFooter: false,
          otherProps: {
            popup: true,
          },
        }}
        message={`Are you sure you want to delete ${deleteId.name}`}
        onDelete={deleteClients}
        onCancel={() => setConformationDeleteModal(false)}
        showIcon={true}></DeleteModal>
    </PageSection>
  );
};

export default Clients;
