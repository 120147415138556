import React from "react";
import InputFields from "../../../components/common/Input/InputFields";
import { BsInfoCircleFill } from "react-icons/bs";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { Button } from "flowbite-react";
import { btnPrimary } from "../../../utils/helper";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import {
  resetStatutoryEsiDetails,
  setStatutoryEsiDetails,
  setStatutoryToggleDetails,
} from "../../../store/slices/statutorySlice";
import { StatutoryComponentType } from "../../../enum/StatutoryComponentType";
import {
  updateStatutoryComponent,
} from "../../../services/StatutoryService";
import { STATUS_CODES } from "../../../constants/Constant";
import { showToast } from "../../../components/common/Toast";
import { STATUTORY_MESSAGE } from "../../../constants/Messages";

const EsiComponent = ({ onCloseEsiModal }) => {
  const dispatch = useAppDispatch();
  const statutory = useAppSelector(state => state.statutory);

  const { statutoryToggleDetails, statutoryEsiDetails } = statutory;

  //Function to get ESI form values.
  const handleChangeEsiDetails = e => {
    const { name, value, type, checked } = e.target;
    dispatch(
      setStatutoryEsiDetails({
        ...statutoryEsiDetails,
        [name]: type === "checkbox" ? checked : value,
      })
    );
  };

  //Function to submit ESI details to server.
  const handleSubmitEsiDetails = async () => {
    const formattedEsiDetails = {
      statutoryComponentType: StatutoryComponentType.ESI,
      number: statutoryEsiDetails.number,
      employeeContributionRate: +statutoryEsiDetails.employeeContributionRate,
      employerContributionRate: +statutoryEsiDetails.employerContributionRate,
      includeEmployersContributionInCtc: statutoryEsiDetails.includeEmployersContributionInCtc,
    };

    const response = await updateStatutoryComponent(formattedEsiDetails);
    if (response?.data?.statusCode === STATUS_CODES.SUCCESS) {
      showToast("success", STATUTORY_MESSAGE.ESI_SUCCESS);
      dispatch(
        setStatutoryToggleDetails({
          ...statutoryToggleDetails,
          toggleEsiModal: false,
          isEsiEnabled: true,
        })
      );
      dispatch(resetStatutoryEsiDetails());
    }
  };

  //Function to update ESI details.
  const handleUpdateEsiComponent = async () => {
    const formattedEsiDetails = {
      ...statutoryEsiDetails,
      number: statutoryEsiDetails.number,
      employeeContributionRate: +statutoryEsiDetails.employeeContributionRate,
      employerContributionRate: +statutoryEsiDetails.employerContributionRate,
      includeEmployersContributionInCtc: statutoryEsiDetails.includeEmployersContributionInCtc,
    };

    if (formattedEsiDetails?.statutoryComponentType === StatutoryComponentType.ESI) {
      const response = await updateStatutoryComponent(formattedEsiDetails);
      if (response?.data?.statusCode === STATUS_CODES.SUCCESS) {
        showToast("success", STATUTORY_MESSAGE.ESI_SUCCESS);
        dispatch(
          setStatutoryToggleDetails({
            ...statutoryToggleDetails,
            toggleUpdateEsiModal: false,
            isEsiEnabled: true,
          })
        );
      }
    }
  };
  return (
    <div className="">
      {/* first container */}
      <div className="esi-number-container flex gap-8 mt-4">
        <div className="min-h-8 w-[45%]">
          <p className="mb-2">ESI Number</p>
          <InputFields
            InputField={{
              type: "text",
              placeholder: "ESI Number",
              name: "number",
            }}
            inputClassName="w-full"
            onChange={handleChangeEsiDetails}
            value={statutoryEsiDetails.number}
          />
        </div>
        <div className=" min-h-8 w-[25%]">
          <div className="flex">
            <p className="mb-2">Deduction Cycle</p>
            <BsInfoCircleFill
              size={13}
              color="#c0c1c4"
              className="cursor-pointer ml-1 mt-[0.4rem] "
              data-tooltip-id="deduction cycle"
              data-tooltip-html={`<div>
                    Employee State Insurance</br>(ESI) contributions for each</br>month should be deposited to</br>the Employee State Insurance</br>Corporation (ESIC) within the</br> 21st of the following month.</div>`}
              data-tooltip-place="top-middle"
            />
            <ReactTooltip id="deduction cycle" />
          </div>
          <InputFields
            InputField={{
              type: "text",
              placeholder: "Monthly",
              name: "monthly",
            }}
            disabled={true}
            inputClassName="w-full"
          />
        </div>
      </div>
      {/* second container */}
      <div className="employees-contribution-rate-container mt-8 flex gap-2">
        <div className="">
          <p>Employees' Contribution</p>
          <div className="flex gap-3">
            <InputFields
              InputField={{
                type: "number",
                name: "employeeContributionRate",
              }}
              inputClassName="w-full mt-2"
              value={statutoryEsiDetails.employeeContributionRate}
              onChange={handleChangeEsiDetails}
            />
            <p className="flex items-center">of Gross Pay</p>
          </div>
        </div>
      </div>

      {/* Third container */}
      <div className="employer-contribution-rate-container mt-8  flex gap-2">
        <div className="">
          <p>Employer's Contribution</p>
          <div className="flex gap-3">
            <InputFields
              InputField={{
                type: "number",
                name: "employerContributionRate",
              }}
              inputClassName="w-full mt-2"
              value={statutoryEsiDetails.employerContributionRate}
              onChange={handleChangeEsiDetails}
            />
            <p className="flex items-center">of Gross Pay</p>
          </div>
        </div>
      </div>

      {/* Fourth container */}
      <div className="includeEmployersContributionInCtc-checkbox-container mt-8  w-full">
        <div className="flex items-center">
          <input
            checked={statutoryEsiDetails.includeEmployersContributionInCtc}
            id="includeEmployersContributionInCtc-checkbox"
            type="checkbox"
            name="includeEmployersContributionInCtc"
            onChange={handleChangeEsiDetails}
            className="w-[1rem] h-[1rem] text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
          />
          <label
            for="includeEmployersContributionInCtc-checkbox"
            className="ms-2 text-sm text-gray-900 dark:text-gray-300 -mt-[0.2rem]">
            Include employer's contribution in the CTC
          </label>
        </div>
      </div>

      {/* Fifth container   */}
      <div className="notes-container mt-8  w-full">
        <p className="bg-[#fff5e3] p-2 text-sm">
          <span className="font-medium">Note:</span> ESI deductions will be made only if the
          employee’s monthly salary is less than or equal to ₹21,000. If the employee gets a salary
          revision which increases their monthly salary above ₹21,000, they would have to continue
          making ESI contributions till the end of the contribution period in which the salary was
          revised (April-September or October-March).
        </p>
      </div>

      <hr className="h-px mt-6 bg-gray-200 border-0 dark:bg-gray-700" />
      <div className=" mt-3">
        <div className=" w-[100%]  flex gap-4">
          <Button
            type="submit"
            className={btnPrimary()}
            form="add-modal"
            onClick={
              statutoryToggleDetails.toggleUpdateEsiModal
                ? handleUpdateEsiComponent
                : handleSubmitEsiDetails
            }>
            {statutoryToggleDetails.toggleUpdateEsiModal ? "Save" : "Enable"}
          </Button>
          <Button
            type="submit"
            className={btnPrimary()}
            form="add-statutory-modal"
            onClick={() => onCloseEsiModal()}>
            Cancel
          </Button>
        </div>
      </div>
    </div>
  );
};

export default EsiComponent;
