import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import InputFields from "../../../common/Input/InputFields";

import { addSkillOption } from "../../helper";
import { btnPrimary } from "../../../../utils/helper";
import { addSkills } from "../../../../store/slices/addSkillSlice";
import { showToast } from "../../../common/Toast";

/**
 * Renders a SkillInput component.
 *
 * @return {JSX.Element} The rendered SkillInput component.
 */
const SkillInput = () => {
  const [inputValue, setInputValue] = useState("");
  const [isDisable, setIsDisable] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (!inputValue) {
        setIsDisable(true);
      } else {
        setIsDisable(false);
      }
    }, 300);

    return () => clearTimeout(timeout);
  }, [inputValue]);

  const onChangeHandler = e => {
    const value = e.target.value;
    setInputValue(value);
  };

  const addSkill = e => {
    e.preventDefault();
    dispatch(addSkills(inputValue));
    setInputValue("");
  };

  return (
    <form onSubmit={addSkill} className="gap-4 w-[80%] flex items-end p-3">
      <InputFields
        parentClassName="flex w-[60%] flex-col justify-center"
        onChange={onChangeHandler}
        InputField={addSkillOption}
        inputClassName="w-full"
        value={inputValue}
        otherProps={{
          autoComplete: "false",
        }}
        helperText="For multiple skills, separate the skills with the comma(,)"
      />

      <button
        type="submit"
        disabled={isDisable}
        className={`${
          isDisable ? "bg-gray-400 text-white" : btnPrimary()
        } -mt-[1rem] lg:mt-0 self-center rounded-md text-white font-semibold px-4 py-[10px] tracking-wide`}>
        Add Skills
      </button>
    </form>
  );
};

export default SkillInput;
