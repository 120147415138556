import DeleteModal from "../../../components/common/Modal/DeleteModal";
import { showToast } from "../../../components/common/Toast";
import { QUICKLINK_MESSAGES } from "../../../constants/Messages";
import { deleteQuickLinkById } from "../../../services/CommonService";

const DeleteQuickLink = ({
  show,
  onClose,
  quickLinkInfo,
  renderQuickLink,
  isActionDeleteBtn,
  setLoading,
}) => {
  const { quickLinkId, quickLinkName } = quickLinkInfo;
  const onDeleteQuickLink = async () => {
    const { toast, toastId } = showToast("loading", QUICKLINK_MESSAGES.LOADING_DELETE);
    await deleteQuickLinkById(quickLinkId);
    showToast("success", `${quickLinkName} deleted successfully`);
    toast.dismiss(toastId);

    setLoading(true);
    renderQuickLink();
    onClose();
    setLoading(false);
  };

  const modalConfigs = { show, onClose, showFooter: false };
  const modalButtonConfigs = { isActionDeleteBtn };
  const message = `Are you sure you want to delete ${quickLinkName} ?`;

  return (
    <DeleteModal
      modalConfigs={modalConfigs}
      heading="Delete Quick Link"
      onDelete={onDeleteQuickLink}
      onCancel={onClose}
      modalButtonConfigs={modalButtonConfigs}
      message={message}
    />
  );
};

export default DeleteQuickLink;
