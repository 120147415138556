import React, {useState} from 'react'
import { TimestampInfo, formattedDate } from '../../../utils/common';
import { ICON_COLOR_MAPPING, ICON_MAPPING } from '../../../constants/Constant';
import { TfiAnnouncement } from "react-icons/tfi";
import { IoMdAddCircleOutline } from 'react-icons/io';
import { Role } from '../../../enum/Role';
import CreateAnnouncement from './CreateAnnouncement';
import LoadingSpinner from '../../../utils/LoadingSpinner';
import { HiDotsHorizontal, HiOutlineTrash, HiPencil } from 'react-icons/hi';
import DeleteAnnouncement from './DeleteAnnouncement';
import UpdateAnnouncement from './UpdateAnnouncement';

const Announcements = ({announcements, user, renderAnnouncement}) => {
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [showCreateAnnouncementModal, setShowCreateAnnouncementModal] = useState(false);
  const [showUpdateAnnouncementModal, setShowUpdateAnnouncementModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [deleteHover, setDeleteHover] = useState(false);
  const [showEditMenuIndex, setShowEditMenuIndex] = useState(null);
  const [showDeleteAnnouncementModal, setShowDeleteAnnouncementModal] = useState(false);
  const [selectedAnnouncementInfo, setSelectedAnnouncementInfo] = useState({});
  const [announcementDetail, setAnnouncementDetail] = useState({
    id:'',
    name:'',
    date:'',
    icon:'',
    colorCode:''
  });

  const handleAddButtonClick = () => {
    setShowCreateAnnouncementModal(true);
  };

  const handleDeleteButtonClick = (announcementId, announcementName) => {
    setSelectedAnnouncementInfo({ announcementId, announcementName }); // Sets the selected announcement ID and name
    setShowDeleteAnnouncementModal(true);
  };

  const handleEditClick = (id, name, date, icon, colorCode) => {
    setAnnouncementDetail({id, name, date, icon, colorCode});
    setShowUpdateAnnouncementModal(true);
  };

  const handleEditMenuClick = (index) => {
    setShowEditMenuIndex(index === showEditMenuIndex ? null : index);
  };

  const handleEditMenuMouseLeave = () => {
    setShowEditMenuIndex(null);
  };

  return (
    <div>
      <div className="w-full h-96 bg-white rounded-lg">
        <div className="flex items-center justify-between p-5 pb-3">
          <h5 className="text-xl font-bold leading-none text-gray-700 dark:text-white">
            Announcements
          </h5>
          {user.roleId === Role.Administrator && (
            <button
              onClick={handleAddButtonClick}
              className="text-sm font-medium hover:underline dark:text-blue-500">
              <IoMdAddCircleOutline size="1.5rem" />
            </button>
          )}
        </div>
        <div>
          <hr className="h-px my-2 bg-gray-200 border-0 dark:bg-gray-700"></hr>
        </div>
        {announcements.length === 0 && (
          <div className="flex flex-col items-center relative">
            <TfiAnnouncement className="absolute top-7" size={"9rem"} color="#BFE2D2" />
            <p className="text-center absolute top-60 text-gray-500 dark:text-gray-400">
              No future announcements
            </p>
          </div>
        )}
        {loading && (
          <div className="flex flex-col items-center relative">
            <LoadingSpinner />
          </div>
        )}
        {!loading && announcements.length > 0 && (
          <div
            className="pl-3 pr-5 pb-5 flow-root scrollbar-hide h-[300px]">
            <ul role="list" className=" divide-gray-200 dark:divide-gray-700">
              {announcements.map((announcement, index) => {
                const [, , date, ...rest] = TimestampInfo(announcement.date);

                const month = formattedDate(announcement.date).split(" ")[1];
                const formattedTime = rest.pop();
                const icon = ICON_MAPPING.find(
                  icon => icon.iconName === announcement.icon
                )?.iconComponent;
                const color = ICON_COLOR_MAPPING.find(
                  bgcolor => bgcolor.color === announcement.colorCode
                )?.color;
                return (
                  <li key={announcement.id} className="py-2 sm:py-2 relative">
                    <div className="flex items-center"
                    onMouseEnter={() => setHoveredIndex(index)}
                    onMouseLeave={() => setHoveredIndex(null)}
                    >
                      <div className="flex-shrink-0">
                        <p
                          className={`w-10 h-10 p-2 rounded-full bg-red-300`}
                          style={{ backgroundColor: color }}>
                          {icon}
                        </p>
                      </div>
                      <div className="flex-1 min-w-0 ms-4 pl-3">
                        <p className="text-sm font-medium text-gray-900 truncate dark:text-white">
                          {announcement.name}
                        </p>
                        <p className="text-sm text-gray-500 truncate dark:text-gray-400">{`${month} ${date}, ${formattedTime}`}</p>
                      </div>
                      {hoveredIndex === index && (
                      <div className="relative">
                       {user.roleId === Role.Administrator && ( 
                        <button
                          onClick={() => handleEditMenuClick(index)}
                          className="absolute text-sm font-medium right-1 top-1/2 transform -translate-y-1/2">
                          <HiDotsHorizontal size="1.2rem" />
                        </button>
                       )}
                      {showEditMenuIndex === index && (
                        <ul onMouseLeave={handleEditMenuMouseLeave} className="absolute right-0 top-full z-10 bg-white border border-gray-200 rounded-lg shadow-md py-1">
                          <li>
                            <button className="flex items-center w-full !py-2 !px-3 h-full text-left text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                              onClick={() => handleEditClick(announcement.id, announcement.name, announcement.date, announcement.icon, announcement.colorCode)}
                              >
                              <HiPencil size="1rem" className="mr-2" />
                              Edit
                            </button>
                          </li>
                          <li>
                            <button className="flex items-center w-full !py-2 !px-3 h-full text-left text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                              onMouseEnter={() => setDeleteHover(true)}
                              onMouseLeave={() => setDeleteHover(false)}
                              onClick={() => handleDeleteButtonClick(announcement.id, announcement.name)}>
                              <HiOutlineTrash size="1rem" className={`mr-2 ${deleteHover ? 'text-red-500' : ''}`} />  
                              Delete
                            </button>
                          </li>
                        </ul>
                      )}
                    </div>
                    )}
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        )}
      </div>
      <CreateAnnouncement
        show={showCreateAnnouncementModal}
        onClose={() => setShowCreateAnnouncementModal(false)}
        renderAnnouncement={renderAnnouncement}
        setLoading={() => setLoading(false)}
      />

      <UpdateAnnouncement
        show={showUpdateAnnouncementModal}
        onClose={() => setShowUpdateAnnouncementModal(false)}
        announcementInfo={announcementDetail}
        renderAnnouncement={renderAnnouncement}
        setLoading={() => setLoading(false)}
      />

      <DeleteAnnouncement
        show={showDeleteAnnouncementModal}
        onClose={() => setShowDeleteAnnouncementModal(false)}
        announcementInfo={selectedAnnouncementInfo}
        renderAnnouncement={renderAnnouncement}
        setLoading={() => setLoading(false)}
        isActionDeleteBtn={true}
      />
    </div>
  );
}

export default Announcements