import { useState } from "react";
import { Button } from "flowbite-react";
import { showToast } from "./Toast";
import { useNavigate } from "react-router";
import ModalComponent from "./Modal/Modal";

export const DeleteEntity = ({
  id,
  setIsChange,
  isChange,
  closeOtherModal,
  onchange,
  buttonName,
  name,
  className,
  renderComponent,
  renderMessage,
  successToastMessage,
  infoToastMessage,
  deleteMessage,
  _navigate,
  urlToNavigate,
  heading,
  otherProps,
}) => {
  // To handle toggle delete model
  const [toggleDelete, setToggleDelete] = useState(false);
  const navigate = useNavigate();

  const Delete = async () => {
    // function to delete from the parent component
    onchange(id)
      .then(res => {
        if (res?.data?.statusCode === 200) {
          successToastMessage && showToast("success", successToastMessage);
          closeOtherModal && closeOtherModal();
          renderComponent && renderMessage;
          otherProps && otherProps.map(prop => ({ ...prop }));
          _navigate && navigate(urlToNavigate);
        }

        if (res?.data?.statusCode === 403) showToast("info", infoToastMessage);

        setToggleDelete(false);
        setIsChange(!isChange);
      })
      .catch(() => {
        console.error(`There was an error deleting ${name}`);
        !infoToastMessage && showToast("error", `There was an error deleting ${name}`);
      });
  };

  return (
    <div>
      <button onClick={() => setToggleDelete(true)} className={className}>
        {buttonName ? buttonName : "Delete"}
      </button>
      <ModalComponent
        size="md"
        showFooter={false}
        onClose={setToggleDelete.bind(null, false)}
        heading={heading && heading}
        show={toggleDelete}>
        <div className="text-center mt-3">
          <h3 className="mb-10 text-lg font-normal text-gray-500 dark:text-gray-400">
            {name ? `Delete ${name}?` : deleteMessage}
          </h3>
          <div className="flex justify-end w-full gap-4">
            <Button color="failure" onClick={() => Delete()}>
              Yes, I'm sure
            </Button>
            <Button color="gray" onClick={() => setToggleDelete(false)}>
              No, cancel
            </Button>
          </div>
        </div>
      </ModalComponent>
    </div>
  );
};
