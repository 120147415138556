import { useState } from "react";
import { Button } from "flowbite-react";

import ModalComponent from "./../common/Modal/Modal";
import { showToast } from "../common/Toast";

import { deleteInvoice } from "../../services/InvoiceService";

export const DeleteInvoices = ({ id, setIsChange, isChange, invoiceNo }) => {
  const [toggleDelete, setToggleDelete] = useState(false);

  const Delete = () => {
    deleteInvoice(id)
      .then(() => {
        setToggleDelete(false);
        setIsChange(!isChange);
        showToast("success", `Invoice deleted ${invoiceNo}`);
      })
      .catch(() => {
        showToast(`There was an error deleting ${invoiceNo}`);
      });
  };

  return (
    <>
      <button
        className="text-red-500 px-6 py-2"
        onClick={() => setToggleDelete(true)}>
        Delete
      </button>
      <ModalComponent
        show={toggleDelete}
        showFooter={false}
        heading="Delete Invoice"
        onClose={() => setToggleDelete(false)}>
        <div className="text-center">
          <h3 className="mt-5 text-lg font-normal text-gray-500 dark:text-gray-400">
            {`Delete Invoice No ${invoiceNo}?`}
          </h3>
          <div className="mt-10 flex justify-end gap-4">
            <Button
              color="failure"
              onClick={Delete}>
              Yes, I'm sure
            </Button>
            <Button
              color="gray"
              onClick={() => setToggleDelete(false)}>
              No, cancel
            </Button>
          </div>
        </div>
      </ModalComponent>
    </>
  );
};
