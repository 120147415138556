import React from "react";
import InputFields from "../common/Input/InputFields";
import { useStore } from "../../context/createFastContext";
import { convert_Date } from "../../utils/helper";

const RecordpaymentModal = () => {
  const [payrollState, setStore] = useStore(s => s.payrollState);
  const paydate = payrollState?.paydate;

  const _paydate = convert_Date(
    true,
    false,
    paydate,
    "en-US",
    "2-digit",
    "2-digit",
    "numeric",
    "/"
  );

  const payDate = `${_paydate[2]}-${_paydate[0]}-${_paydate[1]}`;

  const handleRecordPayment_Confirmation = e => {
    const { name, value } = e.target;

    setStore({
      payrollState: {
        ...payrollState,
        recordPaymentBody: {
          ...payrollState.recordPaymentBody,
          paymentDate: value,
        },
      },
    });
  };

  return (
    <div className="h-30 border border-1 rounded-lg mt-4 bg-gray-50 dark:bg-gray-600">
      <div className="text-md text-gray-600 mt-4 pl-4">
        You are about to record payment for this pay run.
      </div>

      <InputFields
        InputField={{
          type: "date",
          label: "Employee(s) paid on",
          name: "Employee(s) paid on",
          id: "1",
        }}
        parentClassName="flex justify-around gap-12 mb-3 w-96 pt-2"
        onChange={handleRecordPayment_Confirmation}
        value={payrollState?.recordPaymentBody?.paymentDate || payDate}
      />
    </div>
  );
};

export default RecordpaymentModal;
