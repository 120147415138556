import React from "react";
import { Card } from "flowbite-react";

const CardComponent = ({
  heading,
  title,
  titleClassName,
  dropdown,
  cardClassName,
  dropdownClassName,
  headingClassName,
  onClick
}) => {
  return (
    <Card
      className={`${cardClassName} hover:bg-gray-100`}
      onClick={onClick}>
      {dropdown && <div className={dropdownClassName}>{dropdown}</div>}
      <h5
        className={`${headingClassName} text-xl -mt-2 -mb-2 font-bold tracking-tight text-gray-700 dark:text-gray-900`}>
        {heading}
      </h5>
      <div className={`${titleClassName} font-normal -mb-2 text-gray-500 dark:text-gray-700`}>
        {title}
      </div>
    </Card>
  );
};

export default CardComponent;
