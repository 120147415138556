import { Role } from "../../enum/Role";
import { UserSvg } from "../../media/UserSvg";

export const initialLeaveInfo = {
  leaveTypeId: undefined,
  leaveTypeName: "",
  startDate: "",
  endDate: "",
  reason: "",
  startFirstHalf: false,
  startSecondHalf: false,
  endFirstHalf: false,
  endSecondHalf: false,
  approve: false,
  date: "",
};

export const setIsDefaultValue = (user, LeaveStatus) => {
  if (
    user?.status === LeaveStatus.WaitingForApproval ||
    user?.status === LeaveStatus.Approved ||
    user?.status === LeaveStatus.Rejected ||
    user?.status === LeaveStatus.Cancelled
  )
    return true;
  else false;
};

export const setDefaultValue = (user, LeaveStatus) => {
  return user?.status === LeaveStatus.WaitingForApproval
    ? "Pending"
    : user?.status === LeaveStatus.Approved
      ? "Approved"
      : user?.status === LeaveStatus.Rejected
        ? "Rejected"
        : "Cancelled";
};

export const isLeavesFound = selectedUser => {
  return selectedUser ? `No leaves found for ${selectedUser.label}` : "No leaves applied";
};

export const backgroundColor = (user, LeaveStatus) => {
  return user?.status === LeaveStatus.Approved
    ? "bg-green-100 w-[150px]"
    : user?.status === LeaveStatus.Rejected
      ? "bg-red-100 w-[150px]"
      : user?.status === LeaveStatus.Cancelled
        ? "bg-red-100 w-[150px]"
        : "bg-thynkwebPrimary-50 w-[150px]";
};

export const defaultClassName = (user, LeaveStatus) => {
  return user?.status === LeaveStatus.Approved
    ? "text-green-800 rounded-lg -ml-2 mr-2"
    : user?.status === LeaveStatus.Rejected
      ? "text-red-800 rounded-lg -ml-3 mr-2"
      : user?.status === LeaveStatus.Cancelled
        ? "text-red-800 rounded-lg -ml-2 mr-2"
        : "text-thynkwebPrimary-900 rounded-lg -ml-5 mr-2";
};

export const dotClassName = (user, LeaveStatus) => {
  return `w-3 h-3 ml-0.5 ${user?.status === LeaveStatus.Approved
    ? "bg-green-500"
    : user?.status === LeaveStatus.Rejected
      ? "bg-red-500"
      : user?.status === LeaveStatus.Cancelled
        ? "bg-red-500"
        : "bg-thynkwebPrimary-900"
    } border rounded-full`;
};

export const options = (
  roleId,
  userId,
  user,
  statusOptions,
  statusOptionsForNonAdmin,
  statusOptionsForUser
) => {
  return roleId === Role.Administrator && userId === user?.user?.id
    ? statusOptions
    : userId !== user?.user?.id
      ? statusOptionsForNonAdmin
      : statusOptionsForUser;
};

export const pagesize = (radioSelection, myApprovalsPageSize, pageSize) => {
  return radioSelection === "myApprovals" ? myApprovalsPageSize : pageSize;
};

export const currentPage = (radioSelection, myApprovalsPageNo, pageNo) => {
  return radioSelection === "myApprovals" ? myApprovalsPageNo : pageNo;
};

export const onPageChange = (radioSelection, pageNumber, setMyapprovalsPageNo, setPageNo) => {
  return radioSelection === "myApprovals"
    ? setMyapprovalsPageNo(pageNumber)
    : setPageNo(pageNumber);
};

export const totalPages = (leavesResponse, radioSelection, myApprovalsPageSize, pageSize) => {
  return leavesResponse?.totalRecords === undefined
    ? 1
    : Math.ceil(
      leavesResponse?.totalRecords /
      (radioSelection === "myApprovals" ? myApprovalsPageSize : pageSize)
    );
};

// To show first half or second half under date column
export const isFirstHalf_SecondHalf = user => {
  return user.isLeaveFirstHalf ? (
    <span className="w-18 text-center ml-2 mt-7 h-6 rounded-full bg-indigo-100 text-indigo-800 text-md font-medium mr-2 px-2.5 dark:bg-indigo-900 dark:text-indigo-300">
      First Half
    </span>
  ) : user.isLeaveSecondHalf ? (
    <span className="w-18 text-center ml-2 mt-7 h-6 rounded-full bg-green-100 text-green-800 text-md font-medium mr-2 px-2.5 dark:bg-green-900 dark:text-green-300">
      Second Half
    </span>
  ) : (
    ""
  );
};

// Badge for approx time difference under applied on column
export const approxTime_Badge = updatedUser => {
  let badge;

  switch (updatedUser?.timeDifference) {
    case "Just now":
      badge = (
        <span className="w-18 text-center mt-7 h-6 rounded-full bg-pink-100 text-pink-800 text-md font-medium mr-2 px-2.5 dark:bg-pink-900 dark:text-pink-300">
          {updatedUser?.timeDifference}
        </span>
      );

      break;

    case "30 seconds ago":
      badge = (
        <span className="w-18 text-center mt-7 h-6 rounded-full bg-gray-100 text-gray-800 text-md font-medium mr-2 px-2.5 dark:bg-gray-900 dark:text-gray-300">
          {updatedUser?.timeDifference}
        </span>
      );

      break;

    case "1 minute ago":
      badge = (
        <span className="w-18 text-center mt-7 h-6 rounded-full bg-indigo-100 text-indigo-800 text-md font-medium mr-2 px-2.5 dark:bg-indigo-900 dark:text-indigo-300">
          {updatedUser?.timeDifference}
        </span>
      );

      break;

    default:
      badge = (
        <span className="w-18 text-center mt-7 h-6 rounded-full bg-green-100 text-green-800 text-md font-medium mr-2 px-2.5 dark:bg-green-900 dark:text-green-300">
          {updatedUser?.timeDifference}
        </span>
      );
  }
  return badge;
};

