import { MONTHS } from "../../constants/Constant";
import { formattedAmount, formattedDate } from "../../utils/common";
import payRollClip from "../../media/payrollClipart.png";
import { PayrollStatus } from "../../enum/PayrollStatus";
import ProgressBar from "../common/Progress Bar/ProgressBar";
import { Button } from "flowbite-react";
import { Link } from "react-router-dom";
import styles from "./Payroll.module.css";
import InfoIconSvg from "../../media/InfoIconSvg";
import { convert_Date } from "../../utils/helper";

export const chartData = (labels, grossSalary, netSalary) => ({
  labels: labels,
  datasets: [
    {
      id: 1,
      label: "Gross Salary",
      data: grossSalary,
      backgroundColor: "rgba(75, 192, 192, 0.4)",
      borderColor: "rgba(75, 192, 192, 1)",
      borderWidth: 4,
      pointBorderColor: "rgba(75, 192, 192, 1)",
      tension: 1,
      fill: true,
    },
    {
      id: 2,
      label: "Net Salary",
      data: netSalary,
      backgroundColor: "rgba(255, 205, 86, 0.4)",
      borderColor: "rgba(255, 205, 86, 1)",
      borderWidth: 4,
      pointBackgroundColor: "rgba(255, 205, 86, 1)",
      tension: 1,
      fill: true,
    },
  ],
});

export const chartOptions = grossSalary => {
  const maxGrossSalary = Math.max(...grossSalary);
  const margin = 60000;

  return {
    responsive: true,
    animation: {
      tension: {
        duration: 1000,
        easing: "easeInOutCubic",
        from: 1,
        to: 0,
        loop: true,
      },
    },
    plugins: {
      legend: {
        labels: {
          font: {
            size: 14,
            weight: "bold",
          },
        },
      },
      tooltip: {
        callbacks: {
          label: context => {
            const datasetLabel = context.dataset.label || "";
            const value = formattedAmount(context.parsed.y, "en-IN", "INR", 2, 2); // Append '₹'
            return `${datasetLabel}: ${value}`;
          },
        },
        boxPadding: 4,
      },
    },
    scales: {
      y: {
        min: 0,
        max: maxGrossSalary + margin,
        title: {
          display: true,
          text: "Salary (₹)",
          font: { size: 15, weight: "bold" },
        },
        grid: {
          borderWidth: 0,
        },
        ticks: {
          color: "rgba(0, 0, 0, 0.6)",
          callback: value => {
            if (value >= 1000) {
              return Math.round(value / 1000) + "k"; // Formatting as "Xk" for values >= 1000
            }
            return value.toString(); // Return the original value for values < 1000
          },
        },
      },
      x: {
        grid: {
          display: false,
        },
      },
    },
  };
};

const cardTitle = (index, payrollState) => {
  let jsx;
  const payrollDetails = payrollState.res?.payrollDetails;

  const payrollCost = payrollDetails?.payrollCost;
  const employeesNetPay = payrollDetails?.employeesNetPay;
  const utc_paydate = payrollDetails?.payDate;
  const formatted = formattedDate(utc_paydate);
  const split_date = formatted?.split(" ");
  const date = split_date?.[0];
  const month = split_date?.[1];
  const year = split_date?.[2];
  const employeeCount = payrollDetails?.employeeCount;
  const taxes = payrollDetails?.taxes;

  switch (index) {
    case 0:
      jsx = (
        <div className="flex justify-between">
          <div>
            <div>{formattedAmount(payrollCost, "en-IN", "INR", 2, 2)}</div>
            <div className="text-sm">PAYROLL COST</div>
          </div>
          <div>
            <div>{formattedAmount(employeesNetPay, "en-IN", "INR", 2, 2)}</div>
            <div className="text-sm">EMPLOYEES' NET PAY</div>
          </div>
        </div>
      );

      break;

    case 1:
      jsx = (
        <div>
          <div className="text-xl font-medium">{date}</div>
          <div>
            {month}, {year}
          </div>
          <hr className="h-px mt-2 bg-gray-200 border-0 dark:bg-gray-700" />
          <div className="mt-4">{employeeCount} Employee</div>
        </div>
      );

      break;

    case 2:
      jsx = (
        <div>
          <div className="flex justify-between pb-2">
            <span>Taxes</span>
            <span>{formattedAmount(taxes, "en-IN", "INR", 2, 2)}</span>
          </div>
          <div className="flex justify-between pb-2">
            <span>Pre-Tax Deductions</span>
            <span>{formattedAmount(0, "en-IN", "INR", 2, 2)}</span>
          </div>
          <div className="flex justify-between">
            <span>Post-Tax Deductions</span>
            <span>{formattedAmount(0, "en-IN", "INR", 2, 2)}</span>
          </div>
        </div>
      );
  }

  return jsx;
};

const heading = (payrollState, index) => {
  let heading;
  const payrollDetails = payrollState?.res?.payrollDetails;
  const effectiveMonth = payrollDetails?.effectiveMonth;
  const effectiveYear = payrollDetails?.effectiveYear;
  const baseDays = payrollDetails?.baseDays;

  switch (index) {
    case 0:
      heading = `Period: ${MONTHS[effectiveMonth]} ${effectiveYear} | ${baseDays} Base Days`;
      break;

    case 1:
      heading = "Pay Day";
      break;

    case 2:
      heading = "Taxes & Deductions";
      break;
  }
  return heading;
};

export const cardComponents = payrollState => {
  return [
    {
      heading: heading(payrollState, 0),
      title: cardTitle(0, payrollState),
      titleClassName: "pt-8 !font-bold mb-6",
      cardClassName: "!bg-blue-100 hover:!bg-gray-100 text-center",
    },
    {
      heading: heading(payrollState, 1),
      title: cardTitle(1, payrollState),
      titleClassName: "pt-3 !font-bold",
      cardClassName: "text-center",
    },
    {
      title: <img className="w-[50%] mx-16" src={payRollClip} alt="payroll-clip" />,
      titleClassName: "pt-3 !font-bold",
      cardClassName: "hover:bg-transparent border-0 shadow-none text-center",
    },
    {
      heading: heading(payrollState, 2),
      title: cardTitle(2, payrollState),
      titleClassName: "pt-3 !font-bold mb-6",
      cardClassName: "text-center",
    },
  ];
};

export const tableColumns = (view_details, checkBox) => {
  if (view_details) {
    return ["Employee Name", "Paid Days", "Gross Pay", "Deductions", "Taxes", "Net Pay", "Actions"];
  } else {
    return [checkBox, "Paid Days", "Net Pay", "Payslip", "Payment Status"];
  }
};

export const payslipsTableColumns = (prStatus, checkBox) => {
  switch(prStatus)
  {
    case PayrollStatus.Draft:
      return ["Employee Name", "Paid Days", "Gross Pay", "Deductions", "Taxes", "Net Pay", "Actions"];
    case PayrollStatus.Approved:
    case PayrollStatus.PaymentCompleted:
      return [checkBox, "Paid Days", "Net Pay", "Payslip", "Payment Status"];
    default:
      return null;
  }
}

export const _earning_Deduction_DropdownOptions = options => {
  return options?.map(option => {
    return {
      value: option.id,
      label: option.name,
      className: "-pl-8 w-[120px] text-blue-600",
      dotClassName: "",
      backgroundColor: "bg-gray-100",
      itemClassName: "w-[170px]",
    };
  });
};

export const payrollStatus = status => {
  let _payrollStatus;

  switch (status) {
    case PayrollStatus.Draft:
      _payrollStatus = "Draft";
      break;

    case PayrollStatus.Approved:
      _payrollStatus = "Approved";
      break;

    case PayrollStatus.PaymentCompleted:
      _payrollStatus = "Paid";
      break;
  }

  return _payrollStatus;
};

export const insightsCardTitle = (payrollState, payrollStatus, formattedDate) => {
  return (
    <div>
      <div className="flex justify-between pb-2">
        <span className="text-md font-bold text-gray-500 dark:text-gray-800">No. of Employees</span>
        <span>{payrollState.res?.lastPayrollDetails?.employeeCount}</span>
      </div>
      <div className="flex justify-between pb-2">
        <span className="text-md font-bold text-gray-500 dark:text-gray-800">
          Employees Net Pay
        </span>
        <span>
          {formattedAmount(
            payrollState.res?.lastPayrollDetails?.employeesNetPay,
            "en-IN",
            "INR",
            2,
            2
          )}
        </span>
      </div>
      <div className="flex justify-between pb-2">
        <span className="text-md font-bold text-gray-500 dark:text-gray-800">Payroll Cost</span>
        <span>
          {formattedAmount(payrollState.res?.lastPayrollDetails?.payrollCost, "en-IN", "INR", 2, 2)}
        </span>
      </div>
      <div className="flex justify-between pb-2">
        <span className="text-md font-bold text-gray-500 dark:text-gray-800">Payroll Status</span>
        <span>{payrollStatus(payrollState.res?.lastPayrollDetails?.status)}</span>
      </div>

      <div className="flex justify-between pb-2">
        <span className="text-md font-bold text-gray-500 dark:text-gray-800">Approved Date</span>
        {payrollState.currentPayrollStatus !== PayrollStatus.Draft ||
        payrollState.currentPayrollStatus === null ? (
          <span>{formattedDate(payrollState.res?.lastPayrollDetails?.approvalDate)}</span>
        ) : (
          <span>Yet to be approved</span>
        )}
      </div>

      <div className="flex justify-between pb-2">
        <span className="text-md font-bold text-gray-500 dark:text-gray-800">Pay Date</span>
        <span>{formattedDate(payrollState.res?.lastPayrollDetails?.payDate)}</span>
      </div>

      <hr className="h-px my-4 bg-gray-400 border-0 dark:bg-gray-700" />
      <div>Paid & Unpaid Details</div>
      <div className="px-4 pt-2">
        {/* // Paid progress bar */}
        <ProgressBar
          labelName="Paid"
          labelOutside={true}
          progressValue={payrollState.paidProgress}
        />

        {/* // Unpaid progress bar */}
        <ProgressBar
          labelName="Unpaid"
          labelOutside={true}
          labelClassName="text-orange-700"
          progressClass="text-orange-700"
          progressValue={payrollState.unpaidProgress}
          labelOutsideClass="bg-orange-400"
        />
      </div>
    </div>
  );
};

export const payRunInfo = (payrollState, formattedDate, payRun) => {
  const payrollDetails = payrollState?.res;
  const currentPayrollPayDate = payrollDetails?.currentPayrollPayDate;
  const currentPayrollEmpCount = payrollDetails?.currentPayrollEmpCount;

  const currentPayrollStatus = payrollState.currentPayrollStatus;
  const draft = PayrollStatus.Draft;
  const approved = PayrollStatus.Approved;

  return (
    <div>
      <div className="flex justify-between pb-2">
        <div className="flex flex-wrap -mr-15 -ml-15">
          <div className="flex flex-start pb-0 pt-0 gap-6">
            <div className="pl-3 pr-3">
              <p className="text-md font-semibold text-gray-500 dark:text-gray-800">
                EMPLOYEE'S NET PAY
              </p>
              <p
                className={`text-gray-900 font-medium pt-2 ${
                  currentPayrollStatus === null && styles.yetToProcessPattern
                }`}>
                {currentPayrollStatus === null
                  ? "YET TO PROCESS"
                  : formattedAmount(
                      payrollState.res?.lastPayrollDetails?.employeesNetPay,
                      "en-IN",
                      "INR",
                      2,
                      2
                    )}
              </p>
            </div>

            <div className="w-[2.5px] h-12 bg-gray-200 m-auto"></div>

            <div className="pl-3 pr-3">
              <p className="text-md font-semibold text-gray-500 dark:text-gray-800">PAYMENT DATE</p>
              <p className="text-gray-900 font-medium pt-2">
                {formattedDate(currentPayrollPayDate)}
              </p>
            </div>

            <div className="w-[2.5px] h-12 bg-gray-200 m-auto"></div>

            <div className="pl-3 pr-3">
              <p className="text-md font-semibold text-gray-500 dark:text-gray-800">
                NO. OF EMPLOYEES
              </p>
              <p className="text-gray-900 font-medium pt-2">{currentPayrollEmpCount}</p>
            </div>
          </div>
        </div>

        {currentPayrollStatus === null ? (
          <span className="py-4">
            <Button
              onClick={payRun}
              className="cursor-pointer bg-thynkwebPrimary-800 hover:bg-thynkwebPrimary-900 focus:ring-4 focus:ring-thynkwebPrimary-300 dark:focus:ring-thynkwebPrimary-900">
              Create Pay Run
            </Button>
          </span>
        ) : (
          <span>
            <Link to={`/payroll/${payrollState.payRollRunId || payrollState.currentPayrollId}`}>
              <Button className="cursor-pointer bg-thynkwebPrimary-800 hover:bg-thynkwebPrimary-900 focus:ring-4 focus:ring-thynkwebPrimary-300 dark:focus:ring-thynkwebPrimary-900">
                {currentPayrollStatus === draft
                  ? "View Details"
                  : currentPayrollStatus === approved && "View Details & Pay"}
              </Button>
            </Link>
          </span>
        )}
      </div>

      <div className="">
        <div className="flex gap-2">
          <InfoIconSvg className="ml-15 w-4 h-4 mt-1 fill-gray-500" />
          <span>
            {currentPayrollStatus === null && payrollState.res?.currentPayrollEmpCount === 0
              ? `Please Add User/Employee to create pay run for date ${convert_Date(
                  false,
                  true,
                  currentPayrollPayDate,
                  "en-US",
                  "2-digit",
                  "2-digit",
                  "numeric",
                  "/"
                )}.`
              : currentPayrollStatus === null
              ? `Please process and approve this pay run before ${convert_Date(
                  false,
                  true,
                  currentPayrollPayDate,
                  "en-US",
                  "2-digit",
                  "2-digit",
                  "numeric",
                  "/"
                )}.`
              : currentPayrollStatus === draft
              ? `Please approve this payroll before ${convert_Date(
                  false,
                  true,
                  currentPayrollPayDate,
                  "en-US",
                  "2-digit",
                  "2-digit",
                  "numeric",
                  "/"
                )}.`
              : currentPayrollStatus === approved &&
                `Pay your employees on ${convert_Date(
                  false,
                  true,
                  currentPayrollPayDate,
                  "en-US",
                  "2-digit",
                  "2-digit",
                  "numeric",
                  "/"
                )}. Record it here once you have made the payment.`}
          </span>
        </div>
      </div>
    </div>
  );
};
