import React from "react";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import {
  setStatutoryEpfDetails,
  setStatutoryToggleDetails,
} from "../../../store/slices/statutorySlice";
import { Button } from "flowbite-react";

const InitialEpfContents = () => {
  const dispatch = useAppDispatch();
  const statutory = useAppSelector(state => state.statutory);

  const { statutoryToggleDetails, statutoryEpfDetails } = statutory;

  //Function for enabling epf
  const onClickEnableEpf = () => {
    dispatch(
      setStatutoryToggleDetails({
        ...statutoryToggleDetails,
        toggleStatutoryModal: true,
      })
    );
    dispatch(
      setStatutoryEpfDetails({
        ...statutoryEpfDetails,
        number: "",
        employeeContributionLimit: "",
        employerContributionLimit: "",
      })
    );
  };
  return (
    <div className="mx-auto  w-[50%]">
      <div className=" h-40 w-44 mx-auto">
        <img
          style={{ width: "100%", height: "100%" }}
          src="https://css.zohostatic.com/zfpayroll/zpayroll//assets/images/empty-states/ind/epf-8407689e158e652f5d84875ac47b87bc.svg"
          alt=""
        />
      </div>
      <h1 className="text-center text-xl font-medium">Are you registered for EPF?</h1>
      <p className="text-center mt-4">
        Any organisation with 20 or more employees must register for the Employee Provident Fund
        (EPF) scheme, a retirement benefit plan for all salaried employees.
      </p>
      <Button className="mx-auto mt-6" onClick={onClickEnableEpf}>
        Enable EPF
      </Button>
    </div>
  );
};

export default InitialEpfContents;
