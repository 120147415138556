import { PayDayType } from "../../../enum/PayDayType";
import { PayPeriodType } from "../../../enum/PayPeriodType";
import "../../../styles/variables.css";

const root = document.documentElement;
const secondaryColor = getComputedStyle(root).getPropertyValue('--secondary-color');

export const options = [
  {
    value: "Monday",
    label: "Monday",
  },
  {
    value: "Tuesday",
    label: "Tuesday",
  },
  {
    value: "Wednesday",
    label: "Wednesday",
  },
  {
    value: "Thursday",
    label: "Thursday",
  },
  {
    value: "Friday",
    label: "Friday",
  },
  {
    value: "Saturday",
    label: "Saturday",
  },
  {
    value: "Sunday",
    label: "Sunday",
  },
];

/**
 * @namespace createWeekDaysOffObj create an array of objects for react-select
 * @param {Object} weekdays an Object of numbers from 0 to 6 `0:Sunday` `6:Saturday`
 * @param {string[]} weekdaysoff an array of string integers `0 = Sunday`, `6 = Saturday`
 * @returns Array of Objects `{value, label}`
 */
export const createWeekDaysOffObj = function (weekdays, weekdaysoff) {
  if (!weekdaysoff.length) return [];
  else
    return weekdaysoff.map(value => ({
      value: weekdays[value],
      label: weekdays[value],
    }));
};

export const WORKING_HOURS = [
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
];

export const PAY_DAYS = [
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
  "21",
  "22",
  "23",
  "24",
  "25",
  "26",
  "27",
  "28",
];

export const PayPeriodOptions = [
  {
    value: PayPeriodType.DaysInMonth,
    label: "Days in month",
    dotClassName: "",
    itemClassName: "w-[14.2rem]",
  },
  {
    value: PayPeriodType.FixedRange,
    label: "Fixed range",
    dotClassName: "",
    itemClassName: "w-[14.2rem]",
  },
];

export const PayDayOptions = [
  {
    value: PayDayType.LastWorkingDay,
    label: "Last working day",
    dotClassName: "",
    itemClassName: "w-[14.2rem]",
  },
  {
    value: PayDayType.DayOfMonth,
    label: "Day of month",
    dotClassName: "",
    itemClassName: "w-[14.2rem]",
  },
];

//Custom Styles for react-select multi dropdown
/**
 * @namespace customStyles
 * @param {Object} valueContainer css styles for valueContainer in key: value pair
 * @returns customStyles for react-select npm package
 */
export const customStyles = function (valueContainer, control, dropdownIndicator, clearIndicator) {
  return {
    control: baseStyles => ({
      ...baseStyles,
      cursor: "pointer",
      marginTop: ".25rem",
      borderRadius: ".5rem",
      minHeight: "2.68rem",
      boxShadow: "none",
      border: "1px solid rgb(209, 213, 219)",
      "&:hover": {
        border: "1px solid " + secondaryColor,
      },
      "&:focus-within": {
        boxShadow: "0 0 0 1px " + secondaryColor,
        borderOpacity: "0",
      },
      "&:focus": {
        boxShadow: "0 0 0 1px " + secondaryColor,
        borderOpacity: "0",
      },
      ...control,
    }),
    dropdownIndicator: baseStyles => ({
      ...baseStyles,
      paddingInline: ".3rem",
      width: "1.5rem",
      ...dropdownIndicator,
    }),
    valueContainer: baseStyles => ({
      ...baseStyles,
      textTransform: "capitalize",
      paddingInline: ".2rem",
      margin: "0",
      ...valueContainer,
    }),
    clearIndicator: baseStyles => ({
      ...baseStyles,
      padding: "0",
      ...clearIndicator,
    }),
  };
};
