import React, { useContext, useEffect, useState } from "react";
import { btnPrimary } from "../../utils/helper";
import Radio from "../../components/common/Radio/Radio";
import PageSection from "../../components/Layout/PageSection/PageSection";
import InputFields from "../../components/common/Input/InputFields";
import { useSelector, useDispatch } from "react-redux";
import { setBrandDetails, setLogoDetails } from "../../store/slices/logoBrandingSlice";
import {
  GetTenants,
  UploadOrganizationLogo,
  deleteTenantLogo,
  submitBackgroundAndFontColor,
} from "../../services/OrganizationDetailsService";
import { AuthContext } from "../../context/AuthContext";
import { STATUS_CODES } from "../../constants/Constant";
import { showToast } from "../../components/common/Toast";
import { LOGO_MESSAGE } from "../../constants/Messages";
/**
 * LogoBranding component for customizing logo, header color, and favicon.
 *
 * @return {JSX.Element} The logo branding section JSX
 */

const LogoBranding = () => {
  const logoBranding = useSelector(state => state.logoBranding);

  const dispatch = useDispatch();
  const { user } = useContext(AuthContext);

  //Function to get logoUrl which
  const getLogoUrl = async () => {
    const response = await GetTenants();
    if (response?.data?.data?.logoBase64) {
      const base64Details = response?.data?.data?.logoBase64;
      const imageSrc = `data:image/jpeg;base64,${base64Details}`;
      dispatch(
        setLogoDetails({
          logoUrl: imageSrc,
        })
      );
    } else {
      dispatch(
        setLogoDetails({
          logoUrl: null,
        })
      );
    }
  };

  // Function to handle the radio button change for header font color
  const handleRadioChange = event => {
    dispatch(
      setBrandDetails({
        isHeaderFontLight: event.target.value === "true" ? true : false,
      })
    );
  };

  //Function to handle the header background color change for header background color
  const handleColorChange = event => {
    const newColor = event.target.value;
    dispatch(setBrandDetails({ headerBgColor: newColor }));
  };

  //Function to save and change logo to show in header
  const handleFileChange = async event => {
    const file = event.target.files[0];

    if (file) {
      const allowedFormats = ["image/gif", "image/jpeg", "image/png", "image/jpg"];
      if (!allowedFormats.includes(file.type)) {
        showToast("error", LOGO_MESSAGE.FORMAT_ERROR);
        return;
      }

      const formData = new FormData();
      formData.append("logo", file);
      const response = await UploadOrganizationLogo(formData, user);
      if (response?.data?.statusCode === STATUS_CODES.SUCCESS) {
        await getLogoUrl();
        showToast("success", LOGO_MESSAGE.SUCCESS);
      }
    }
  };

  //Function to delete logo
  const handleDeleteCurrentLogo = async () => {
    if (logoBranding?.logoDetails?.logoUrl) {
      const response = await deleteTenantLogo();
      if (response?.data?.statusCode === STATUS_CODES.SUCCESS) {
        await getLogoUrl();
        showToast("success", LOGO_MESSAGE.DELETE);
      }
    }
  };

  //Function to save header background color and header font color
  const onSaveFontAndBackgroundColor = async () => {
    const bgColor = logoBranding?.brandDetails?.headerBgColor;
    const fontColor = logoBranding?.brandDetails?.isHeaderFontLight;
    const response = await submitBackgroundAndFontColor({
      headerBgColor: bgColor,
      isHeaderFontLight: fontColor,
    });
    if (response?.data?.statusCode === STATUS_CODES.SUCCESS) {
      showToast("success", LOGO_MESSAGE.HEADER_FONT_AND_BACKGROUND_COLOR_SUCCESS);
    } else {
      showToast("error", LOGO_MESSAGE.HEADER_FONT_AND_BACKGROUND_COLOR_ERROR);
    }
  };

  return (
    <>
      <PageSection heading="Logo and Branding">
        <div className="">
          <div className="mx-[auto] px-4 xl:grid-cols-[minmax(16rem,_16rem)_1fr_1fr] xl:gap-4 bg-slate-50">
            <div>
              <p className="pt-4">
                Customize the appearance of your platform by adding your logo, choosing the header
                color, and uploading a favicon.
              </p>
              <hr className="my-4" />
              <div>
                <h2 className="text-xl font-medium">Logo</h2>
                <p className="pt-2">
                  The logo is displayed in the header of your platform. To upload a new logo, click
                  the browse button, select your logo, then click Save.
                </p>
              </div>

              <div className="flex flex-wrap gap-3 mb-4 mt-4">
                <div className="sm:flex xl:block sm:space-x-4 xl:space-x-0 mt-3">
                  <span>Current logo</span>
                  <div className="flex w-60 h-44 mt-2 p-2 items-center justify-center border-dashed border-2 border-gray-500">
                    {logoBranding?.logoDetails?.logoUrl ? (
                      <img
                        src={logoBranding?.logoDetails?.logoUrl}
                        alt="Logo"
                        className="w-55"
                      />
                    ) : (
                      <span>No current logo</span>
                    )}
                  </div>
                  <div className="flex justify-center items-center">
                    {logoBranding?.logoDetails?.logoUrl && (
                      <button
                        className="text-md cursor-pointer text-gray-600 hover:text-red-700"
                        onClick={handleDeleteCurrentLogo}>
                        Delete current logo
                      </button>
                    )}
                  </div>
                </div>
                <div className="mt-2">
                  <h5>Upload a new logo</h5>
                  <div className="mt-3 p-3 border-2 rounded-md bg-gray-100">
                    <div className="flex flex-wrap gap-2">
                      <InputFields
                        InputField={{
                          label: "Image Upload",
                          type: "file",
                          id: "img",
                          name: "img",
                          accept: "image/*",
                        }}
                        onChange={handleFileChange}
                      />
                      {/* TODO: If needed then we will add this */}
                      {/* <div className="flex flex-wrap items-center justify-center gap-3">
                        <button
                          className={`${btnPrimary()}  text-white !px-4 !py-2 rounded-md tracking-wide font-semibold flex items-center gap-2 mt-6`}>
                          Save
                        </button>
                      </div> */}
                    </div>
                    <p className="pt-1 pb-2 text-gray-400">
                      Supported image types: GIF, JPEG, PNG, JPG
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <hr className="my-6" />

            <div>
              <div>
                <h1 className="text-2xl font-semibold">Header Background Color</h1>
                <p className="pt-2">
                  To change the background color behind your logo or site name in the header, enter
                  the HEX code or use the picker to choose your color. You can choose either a light
                  or dark colored font for the navigation text. Click Save to update your settings.
                </p>
                <div className="flex flex-wrap md:w-max bg-gray-200 justify-between rounded-md mt-4">
                  <div className=" flex m-4">
                    <div>
                      <p className="font-medium">Header background color</p>
                      <div className="flex items-center">
                        <div
                          className="color-preview w-16 h-8"
                          style={{
                            backgroundColor: logoBranding?.brandDetails?.headerBgColor
                              ? logoBranding?.brandDetails?.headerBgColor
                              : "#ffffff",
                          }}></div>
                        <div className="w-4 h-4">
                          <input
                            type="color"
                            id="colorPicker"
                            name="colorPicker"
                            value={logoBranding?.brandDetails?.headerBgColor}
                            onChange={handleColorChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="border-r-2 mt-4 mb-4 border-gray-300"></div>

                  <div className=" flex flex-col justify-center p-4">
                    <h1 className="font-medium">Header font color</h1>
                    <div className="flex mt-2 gap-2">
                      <div className="flex items-center  gap-3">
                        <Radio
                          id="radioOption1"
                          name="radioGroup"
                          value={true}
                          label="Light"
                          onChange={handleRadioChange}
                          checked={logoBranding?.brandDetails?.isHeaderFontLight === true}
                          parentClass="your-custom-parent-class"
                          labelClass="your-custom-label-class"
                          radioClass="your-custom-radio-class"
                        />

                        <Radio
                          id="radioOption2"
                          name="radioGroup"
                          value={false}
                          label="Dark"
                          onChange={handleRadioChange}
                          checked={logoBranding?.brandDetails?.isHeaderFontLight === false}
                          parentClass="your-custom-parent-class"
                          labelClass="your-custom-label-class"
                          radioClass="your-custom-radio-class"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="border-r-2 mt-4 mb-4 border-gray-300"></div>
                  <div className="flex items-center justify-center p-4">
                    <button
                      className={`${btnPrimary()}  text-white !px-4 !py-2 rounded-md tracking-wide font-semibold flex items-center gap-2`}
                      onClick={onSaveFontAndBackgroundColor}>
                      Save
                    </button>
                  </div>
                </div>
                <p className="mt-4">
                  Note: We recommend using the Light font color option if your background is a dark
                  color. Alternatively, if your background is a light color, use the Dark navigation
                  color.
                </p>
              </div>
              <hr className="my-6" />
            </div>
            {/* TODO: Will Implement this later */}
            {/* 
            <div>
              <h1 className="text-xl font-medium">Favicon</h1>
              <p className="pt-2">
                The favicon is displayed in the address bar and tabs of the internet browser. To
                upload a new favicon, click the browse button, select your favicon file, then click
                Save. (Note: We recommend clearing your browser cache and restarting your browser to
                see the favicon update. Browsers can be very persistent in holding on to cached
                favicons.)
              </p>
              <div className="flex flex-wrap gap-3 mb-4 mt-4">
                <div className="sm:flex xl:block sm:space-x-4 xl:space-x-0 mt-3">
                  <span className="font-medium">Current favicon</span>
                  <div className="flex w-48 h-24 mt-2 p-2 items-center justify-center border-dashed border-2 border-gray-500">
                    {currentFavicon ? (
                      <img className="w-11 h-11" src={currentFavicon} alt="Current Favicon" />
                    ) : (
                      <span>No current favicon</span>
                    )}
                  </div>
                </div>
                <div className="mt-2">
                  <span className="font-medium">Upload a new favicon</span>
                  <div className="mt-3 p-3 border-2 rounded-md bg-gray-100">
                    <div className="flex justify-between gap-2">
                      <div>
                        <InputFields
                          InputField={{
                            label: "Image Upload",
                            type: "file",
                            id: "img",
                            name: "img",
                            accept: "image/*",
                            onChange: { handleFaviconUpload },
                          }}
                        />
                      </div>
                      <div className="flex flex-wrap items-center justify-center gap-3">
                        <div
                          className={`${btnPrimary()}  text-white !px-4 !py-2 rounded-md tracking-wide font-semibold flex items-center gap-2 mt-6`}>
                          Save
                        </div>
                      </div>
                    </div>

                    <div className="pt-1 pb-2 text-gray-400">Supported file type: ICO</div>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </PageSection>
    </>
  );
};

export default LogoBranding;
